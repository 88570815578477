import React from 'react';
import { useSelector } from 'react-redux';
import UpdateDriver from './UpdateDriver';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function RegisterEmployees() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_DRIVER,
			namePage: 'Motoristas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atualiza Motorista',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return <UpdateDriver breadCrumbItems={breadCrumbItems} />
}
