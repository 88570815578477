import React, { useState } from 'react';
import { FaTrash, FaEdit, FaCheck } from 'react-icons/fa';

import api from '../../../services/api';

import './styles.css';

function CardEvaluation({ title, content, editQuestion, id }) {
	const [questoes, setQuestoes] = useState(content.questoes);

	const handleEditQuestion = (question, index) => {
		questoes[index] = question;
		setQuestoes([...questoes]);
	};

	const ListItem = ({ content, id, editQuestion }) => {
		const [titulo, setTitulo] = useState(content.titulo);
		const [descricao, setDescricao] = useState(content.descricao);
		const [pesoQuestao, setPeso] = useState(content.peso);

		const id_questao = content.id_questao;

		const [edit, setEdit] = useState(false);
		const [alternativas, setAlternativas] = useState(
			content.alternativa
				? content.alternativa.map(item => ({
						id_alternativa: item.id_alternativa,
						alternativa: item.alternativa,
						descricao: item.descricao,
						resposta_correta: item.resposta_correta,
				  }))
				: null
		);

		const handleChangeInput = (event, index) => {
			const values = [...alternativas];
			values[index][event.target.name] = event.target.value;
			setAlternativas(values);
		};

		const handleSubitmit = async () => {
			setEdit(false);
			let data = {};
			content.alternativa
				? (data = {
						numero_questao: content.numero_questao,
						titulo: titulo,
						descricao: descricao,
						peso: pesoQuestao,
						alternativa: alternativas,
				  })
				: (data = {
						numero_questao: content.numero_questao,
						titulo: titulo,
						descricao: descricao,
						peso: pesoQuestao,
				  });
			editQuestion(data, id);

			console.log(data);

			try {
				await api.patch(
					`/avaliacao/editar-questao/${id_questao}`,
					data
				);
			} catch (error) {
				throw error;
			}
		};
		return (
			<div>
				{edit ? (
					<li className="list-group-item">
						<div className="Alternatives">
							{content.numero_questao})
							<div className="header-alternativas">
								<label htmlFor="avaliacao">
									<h5>Título</h5>
									<input
										className="form-control"
										type="text"
										value={titulo}
										onChange={event =>
											setTitulo(event.target.value)
										}
										required
									/>
								</label>

								<label htmlFor="avaliacao">
									<h5>Descrição</h5>
									<input
										className="form-control"
										type="text"
										value={descricao}
										onChange={event =>
											setDescricao(event.target.value)
										}
										required
									/>
								</label>
								<label htmlFor="avaliacao">
									<h5>Peso</h5>
									<input
										className="form-control"
										type="text"
										name="peso"
										value={pesoQuestao}
										onChange={event =>
											setPeso(event.target.value)
										}
										required
									/>
								</label>
							</div>
							<ul>
								{alternativas
									? alternativas.map((item, index) => (
											// {item.titulo}
											<div className="alternativasGroup">
												<li key={index}>
													{item.alternativa})
												</li>
												<div className="alternativasInput">
													<input
														className="form-control"
														type="text"
														name="descricao"
														value={item.descricao}
														onChange={event =>
															handleChangeInput(
																event,
																index
															)
														}
														required
													/>

													<select
														id="selectCerta"
														className="form-control"
														name="resposta_correta"
														value={
															item.resposta_correta
														}
														onChange={event =>
															handleChangeInput(
																event,
																index
															)
														}
													>
														<option value="00">
															Resposta certa
														</option>
														<option value="1">
															Sim
														</option>
														<option value="0">
															Não
														</option>
													</select>
												</div>

												{/* {item.certa == '1' ? <FaCheck className="icon" color="green" /> : null} */}
											</div>
									  ))
									: null}
							</ul>
							<div className="buttonSubmit">
								<button
									type="button"
									onClick={handleSubitmit}
									className="btn btn-primary"
								>
									Salvar
								</button>
							</div>
						</div>
					</li>
				) : (
					<li className="list-group-item">
						<div className="Alternatives">
							<h5>
								{content.numero_questao}) {content.titulo}
							</h5>

							<h6>{content.descricao}</h6>
							<ul>
								{content.alternativa
									? content.alternativa.map(
											(value, index) => {
												return (
													<li key={index}>
														{value.alternativa}){' '}
														{value.descricao}
														{value.resposta_correta ==
														1 ? (
															<FaCheck />
														) : null}
													</li>
												);
											}
									  )
									: null}
							</ul>
						</div>
						<div className="list-group-button">
							<button onClick={() => setEdit(!!true)}>
								<FaEdit />
							</button>
						</div>
					</li>
				)}
			</div>
		);
	};

	return (
		<>
			<div id="accordion" className="evaluationCard">
				<div className="card">
					<div className="card-header" id="headingOne">
						<button
							className="btn btn-link"
							data-toggle="collapse"
							data-target={`#collapse${content.id_avaliacao}`}
							aria-expanded="true"
							aria-controls="collapseOne"
						>
							{title}
						</button>
					</div>

					<div
						id={`collapse${content.id_avaliacao}`}
						className="collapse"
						aria-labelledby="headingOne"
						data-parent="#accordion"
					>
						<div className="card-body">
							<ul className="list-group">
								{questoes.map((questao, index) => {
									return (
										<ListItem
											key={index}
											id={index}
											editQuestion={handleEditQuestion}
											content={questao}
										/>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CardEvaluation;
