import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { FaTimesCircle, FaPencilAlt } from 'react-icons/fa';
import Footer from '../../components/Footer';
import Panel from '../../components/Panel';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import './style.css';
import LabelByPermission from '../../routes/data/LabelByPermission';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import RouteParams from '../../routes/data/RouteParams';
import Table from '../../components/Table';
import Bookloading from '../../components/Loading/Bookloading';

$.DataTable = require('datatables.net-dt');

function CheckboxElement({
	provider,
	providersToRemove,
	setProvidersToRemove,
}) {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checked)
			return setProvidersToRemove([...providersToRemove, provider]);
		setProvidersToRemove(
			providersToRemove.filter(providerItem => providerItem !== provider)
		);
	}, [checked]);

	return <input type="checkbox" onChange={() => setChecked(!checked)} />;
}

function EditButton({ provider }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	function editNavigation() {
		const params = {};

		params[RouteParams.LEVEL_07_ROUTES.EDIT_PROVIDERS.PROVIDER] = provider;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.EDIT_PROVIDERS,
			{ params }
		);
	}

	return (
		<button
			type="button"
			className="providersEditButtonElement"
			onClick={() => editNavigation()}
			aria-hidden
			data-toggle="tooltip"
			title="Editar Fornecedor"
		>
			<FaPencilAlt fill="#ffffff" />
		</button>
	);
}

export default function Providers() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [providers, setProviders] = useState([]);
	const [providersToRemove, setProvidersToRemove] = useState([]);
	const [loading, setLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Fornecedores',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getProviders() {
		const response = await api.get('/cozinha-piloto/fornecedores');

		if (!response.data.error) {
			setProviders(response.data.data);
			setLoading(false);
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function removeProvider() {
		try {
			const response = await api.post(
				'/cozinha-piloto/fornecedores/remover',
				{
					fornecedores: providersToRemove,
				}
			);

			if (!response.data.error) {
				swal('SUCESSO!', 'Fornecedor removido com sucesso', 'success');
				setProvidersToRemove([]);
				getProviders();
			} else swal('ERRO!', `${response.data.message}`, 'error');
		} catch (error) {
			swal('ERRO!', 'Houve um erro ao remover o fornecedor', 'error');
		}
	}

	useEffect(() => {
		getProviders();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{loading ? (
				<Bookloading
					isLoading={loading}
					className="providersBookloading"
				/>
			) : (
				<>
					<div className="providersButtons">
						<Link
							to={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).ADD_PROVIDERS
							}
							className="providersButton providersAddButton"
						>
							Adicionar
						</Link>
						<button
							type="button"
							className="providersButton providersRemoveButton"
							onClick={() =>
								providersToRemove.length !== 0
									? removeProvider()
									: swal(
											'ATENÇÃO',
											'Selecione um fornecedor para remover',
											'warning'
									  )
							}
						>
							Remover
						</button>
					</div>

					{providers ? (
						<div className="table-responsive card providersCardTable">
							<Table
								header={[
									'',
									'Nome',
									'Categoria',
									'Telefone',
									'',
								]}
								body={providers.map(providerItem => {
									return [
										<CheckboxElement
											provider={providerItem}
											providersToRemove={
												providersToRemove
											}
											setProvidersToRemove={
												setProvidersToRemove
											}
										/>,
										providerItem.nome,
										providerItem.categoria,
										`(${providerItem.ddd_numero}) ${providerItem.tel_numero}`,
										<EditButton provider={providerItem} />,
									];
								})}
							/>
						</div>
					) : (
						<div className="providersNotFound">
							<FaTimesCircle size={30} />
							<p className="mt-2">Nenhum fornecedor encontrado</p>
						</div>
					)}
				</>
			)}
		</>
	);
}
