import React from 'react';
import { useSelector } from 'react-redux';
import HeaderDash from '../HeaderDash/newHeader';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Routes from '../../routes/data/Routes';

export default function CardsInfo({ card1, card2, card3, card4 }) {
	const userActualType = useSelector(state => state.userType.actualType);

	const headerItems = [
		{
			headerColSize: 'col-xl-3 col-lg-6 col-md-6 col-12 p-2',
			headerLabel: `${card1.title}`,
			headerIcon: card1.icon,
			headerNumber: `${card1.number}`,
			headerLinkName: `${card1.link}`,
		},
		{
			headerColSize: 'col-xl-3 col-lg-6 col-md-6 col-12 p-2',
			headerLabel: `${card2.title}`,
			headerIcon: card2.icon,
			headerNumber: `${card2.number}`,
			headerLinkName:  `${card2.link}`,
		},
		{
			headerColSize: 'col-xl-3 col-lg-6 col-md-6 col-12 p-2',
			headerLabel: `${card3.title}`,
			headerIcon: card3.icon,
			headerNumber: `${card3.number}`,
			headerLinkName:  `${card3.link}`,
		},
		{
			headerColSize: 'col-xl-3 col-lg-6 col-md-6 col-12 p-2',
			headerLabel: `${card4.title}`,
			headerIcon: card4.icon,
			headerNumber: `${card4.number}`,
			headerLinkName:  `${card4.link}`,
		},
	];
	return <HeaderDash headerItems={headerItems} />;
}
