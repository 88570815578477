import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	FaHome,
	FaSchool,
	FaListAlt,
	FaUserGraduate,
	FaChalkboardTeacher,
	FaUserFriends,
	FaClipboardList,
	FaRoute,
	FaUsersCog,
	FaInbox,
	FaTicketAlt,
	FaBoxOpen,
	FaPercent,
	FaUserLock,
	FaBookOpen,
	FaBullhorn,
	FaExclamationTriangle,
	FaShoppingBasket,
	FaShippingFast,
	FaFileAlt,
	FaFolderOpen,
	FaReceipt,
	FaHashtag,
	FaConciergeBell,
	FaWpforms,
	FaAtlas,
	FaClipboardCheck,
} from 'react-icons/fa';
import { MdDescription } from 'react-icons/md';
import { useFunctionalities } from '../Contenxt/Functionalities';
import Menu from '../Menu';
import Sidebar from '../Sidebar';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Routes from '../../routes/data/Routes';

export default function Index() {
	const {
		functionalities: { functionalities },
	} = useFunctionalities();
	const [sideBar, setSideBar] = useState([]);
	const userActualType = useSelector(state => state.userType.actualType);

	const sidebaritems = [
		{
			id_funcionalidade: 1,
			icon: <FaHome />,
			sidebarname: 'home',
			sidebarlabel: 'Home',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
		},
		{
			id_funcionalidade: 171,
			icon: <FaClipboardCheck />,
			sidebarname: 'vacancies-in-Nurseries',
			sidebarlabel: 'Vagas',
			linkname: 'Vacancies-in-Nurseries',
			subItems: [
				{
					id_funcionalidade: 172,
					sidebarname: 'lista',
					sidebarlabel: 'Lista creches',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).NURSERY_VACANCIES,
				},
				{
					id_funcionalidade: 172,
					sidebarname: 'lista',
					sidebarlabel: 'Lista escolas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).CHILDHOOD_EDUCATION_VACANCIES,
				},
				{
					id_funcionalidade: 173,
					sidebarname: 'report',
					sidebarlabel: 'Relatório',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).NURSERY_VACANCIES_REPORT,
				},
			],
		},
		{
			id_funcionalidade: 165,
			icon: <FaListAlt />,
			sidebarname: 'Avaliação',
			sidebarlabel: 'Avaliação',
			subItems: [
				{
					id_funcionalidade: 166,
					sidebarname: 'Listar avaliações',
					sidebarlabel: 'Listar avaliações',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).EVALUATION_LIST,
				},
				{
					id_funcionalidade: 167,
					sidebarname: 'Criar avaliação',
					sidebarlabel: 'Criar avaliação',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).CREATE_EVALUATION,
				},
				{
					id_funcionalidade: 168,
					sidebarname: 'Corrigir avaliações',
					sidebarlabel: 'Corrigir avaliações',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).REVISE_EVALUATION,
				},
			],
		},
		{
			id_funcionalidade: 2,
			icon: <FaUserGraduate />,
			sidebarname: 'students',
			sidebarlabel: 'Alunos',
			linkname: 'Students',
			subItems: [
				{
					id_funcionalidade: 21,
					sidebarname: 'students-list',
					sidebarlabel: 'Lista de Alunos',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).STUDENTS,
				},
				{
					id_funcionalidade: 146,
					sidebarname: 'students-list',
					sidebarlabel: 'Lista de Alunos',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).STUDENTS,
				},
				{
					id_funcionalidade: 24,
					sidebarname: 'students-claim-death',
					sidebarlabel: 'Falecimento do Aluno',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).CLAIM_DEATH_STUDENTS,
				},
				{
					id_funcionalidade: 25,
					sidebarname: 'students-register',
					sidebarlabel: 'Matricular Aluno na Escola',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).SUBSCRIPTION_STUDENTS,
				},
				{
					id_funcionalidade: 26,
					sidebarname: 'students-write',
					sidebarlabel: 'Inscrever Aluno na Escola',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).INSCRIBER_STUDENT,
				},
				{
					id_funcionalidade: 27,
					sidebarname: 'students-note-low',
					sidebarlabel: 'Alunos com notas baixas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).RECLASSIFY_STUDENT,
				},
				{
					id_funcionalidade: 28,
					sidebarname: 'students-rearrange',
					sidebarlabel: 'Remanejar Aluno',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).REARRANGE_STUDENT,
				},
				{
					id_funcionalidade: 29,
					sidebarname: 'students-transfer',
					sidebarlabel: 'Transferir Aluno entre Escolas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).TRANSFER_STUDENT,
				},
				{
					id_funcionalidade: 30,
					sidebarname: 'students-correct-attendance',
					sidebarlabel: 'Corrigir falta',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).CORRECT_ATTENDANCE,
				},
				{
					id_funcionalidade: 31,
					sidebarname: 'students-attendance',
					sidebarlabel: 'Chamada',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).STUDENT_ATTENDANCE,
				},
				{
					id_funcionalidade: 32,
					sidebarname: 'student-fail',
					sidebarlabel: 'Reprovados',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).REPROVED_LIST,
				},
				{
					id_funcionalidade: 33,
					sidebarname: 'subject-grade',
					sidebarlabel: 'Notas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).GRADE,
				},
				{
					id_funcionalidade: 170,
					sidebarname: 'throw-notes',
					sidebarlabel: 'Lançar Notas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).POSTNOTES,
				},
			],
		},
		{
			id_funcionalidade: 3,
			icon: <FaClipboardList />,
			sidebarname: 'attribution',
			sidebarlabel: 'Atribuição',
			linkname: 'Attribution',
			subItems: [
				{
					id_funcionalidade: 148,
					sidebarname: 'assignment-lists',
					sidebarlabel: 'Listas de Atribuições',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_ATTRIBUTION,
				},
				{
					id_funcionalidade: 149,
					sidebarname: 'assignment-lists-user',
					sidebarlabel: 'Listas de Atribuições',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).ATTRIBUTION,
				},
			],
		},
		{
			id_funcionalidade: 4,
			icon: <FaPercent />,
			sidebarname: 'attendance',
			sidebarlabel: 'Aulas',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ATTENDANCE,
		},
		{
			id_funcionalidade: 5,
			icon: <FaAtlas />,
			sidebarname: 'library',
			sidebarlabel: 'Biblioteca',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIBRARY,
		},
		{
			id_funcionalidade: 6,
			icon: <MdDescription />,
			sidebarname: 'reportcard',
			sidebarlabel: 'Boletim',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.REPORT_CARD,
		},
		{
			id_funcionalidade: 7,
			icon: <FaTicketAlt />,
			sidebarname: 'tickets',
			sidebarlabel: 'Chamados',
			linkname: 'Tickets',
			subItems: [
				{
					id_funcionalidade: 44,
					sidebarname: 'tickets-list',
					sidebarlabel: 'Lista de Chamados',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_TICKETS,
				},
				{
					id_funcionalidade: 46,
					sidebarname: 'tickets-create',
					sidebarlabel: 'Cadastro de Chamado',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).CREATE_TICKET,
				},
			],
		},
		{
			id_funcionalidade: 8,
			icon: <FaInbox />,
			sidebarname: 'communicated',
			sidebarlabel: 'Comunicados',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COMMUNICATED,
		},
		{
			id_funcionalidade: 9,
			icon: <FaUserLock />,
			sidebarname: 'Coordenators',
			sidebarlabel: 'Coordenadores',
			linkname: 'Coordenators',
			subItems: [
				{
					id_funcionalidade: 52,
					sidebarname: 'list-coordenators',
					sidebarlabel: 'Lista Coordenadores',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).COORDINATOR,
				},
				{
					id_funcionalidade: 55,
					sidebarname: 'coordinator-registration',
					sidebarlabel: 'Cadastro de Coordenador',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).ADD_COORDINATOR,
				},
			],
		},
		{
			id_funcionalidade: 10,
			icon: <FaBookOpen />,
			sidebarname: 'content',
			sidebarlabel: 'Disciplinas',
			linkname: 'Content',
			subItems: [
				{
					id_funcionalidade: 56,
					sidebarname: 'subject-files',
					sidebarlabel: 'Arquivos das Aulas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).FILES_ATTRIBUTION,
				},
				{
					id_funcionalidade: 57,
					sidebarname: 'program-content',
					sidebarlabel: 'Contéudos Programáticos',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).PROGRAMMATIC_CONTENT,
				},
				{
					id_funcionalidade: 58,
					sidebarname: 'Discipline-duties',
					sidebarlabel: 'Atribuições da Disciplina',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).ATTRIBUTION,
				},
				{
					id_funcionalidade: 59,
					sidebarname: 'teacher-discipline',
					sidebarlabel: 'Disciplina do Professor',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).COURSES,
				},
				{
					id_funcionalidade: 60,
					sidebarname: 'contents-lessons',
					sidebarlabel: 'Conteúdos Aulas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).PROGRAMMATIC_CONTENT_CLASSES,
				},
			],
		},
		{
			id_funcionalidade: 11,
			icon: <FaSchool />,
			sidebarname: 'schools',
			sidebarlabel: 'Escolas',
			linkname: 'Schools',
			subItems: [
				{
					id_funcionalidade: 61,
					sidebarname: 'school-list',
					sidebarlabel: 'Lista de Escolas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).SCHOOLS,
				},
				{
					id_funcionalidade: 147,
					sidebarname: 'school-list',
					sidebarlabel: 'Lista de Escolas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).SCHOOLS,
				},
				{
					id_funcionalidade: 64,
					sidebarname: 'school-classes',
					sidebarlabel: 'Classes da Escola',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_CLASSES,
				},
				{
					id_funcionalidade: 65,
					sidebarname: 'school-series',
					sidebarlabel: 'Séries da Escola',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_SERIES,
				},
			],
		},
		{
			id_funcionalidade: 12,
			icon: <FaUsersCog />,
			sidebarname: 'employees',
			sidebarlabel: 'Funcionários',
			linkname: 'Employees',
			subItems: [
				{
					id_funcionalidade: 68,
					sidebarname: 'employees-list',
					sidebarlabel: 'Lista de Funcionários',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_EMPLOYEE,
				},
				{
					id_funcionalidade: 71,
					sidebarname: 'employees-register',
					sidebarlabel: 'Registro de Funcionários',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).REGISTER_EMPLOYEE,
				},
			],
		},
		{
			id_funcionalidade: 13,
			icon: <FaBoxOpen />,
			sidebarname: 'input-material',
			sidebarlabel: 'Material de Insumo',
			linkname: 'Material',
			subItems: [
				{
					id_funcionalidade: 74,
					sidebarname: 'material-create',
					sidebarlabel: 'Cadastro de Materiais de Insumo',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).CREATE_SUPPLIES,
				},
				{
					id_funcionalidade: 72,
					sidebarname: 'material-list',
					sidebarlabel: 'Lista de Material',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_SUPPLIES,
				},
			],
		},
		{
			id_funcionalidade: 14,
			icon: <FaBullhorn />,
			sidebarname: 'notices',
			sidebarlabel: 'Notícias',
			linkname: 'Notices',
			subItems: [
				{
					id_funcionalidade: 76,
					sidebarname: 'notices-list',
					sidebarlabel: 'Listar Notícias',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_NOTICES,
				},
				{
					id_funcionalidade: 77,
					sidebarname: 'notices-register',
					sidebarlabel: 'Cadastro de Notícia',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).NOTICES,
				},
			],
		},
		{
			id_funcionalidade: 16,
			icon: <FaChalkboardTeacher />,
			sidebarname: 'teachers',
			sidebarlabel: 'Professores',
			linkname: 'Teachers',
			subItems: [
				{
					id_funcionalidade: 81,
					sidebarname: 'students-list',
					sidebarlabel: 'Lista de Professores',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_TEACHERS,
				},
			],
		},
		{
			id_funcionalidade: 17,
			icon: <FaUserFriends />,
			sidebarname: 'parents',
			sidebarlabel: 'Responsáveis',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_PARENTS,
		},
		{
			id_funcionalidade: 18,
			icon: <FaRoute />,
			sidebarname: 'transport',
			sidebarlabel: 'Transporte',
			linkname: 'Transport',
			subItems: [
				{
					id_funcionalidade: 89,
					sidebarname: 'monitor',
					sidebarlabel: 'Monitores',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_MONITOR,
				},
				{
					id_funcionalidade: 90,
					sidebarname: 'driver',
					sidebarlabel: 'Motoristas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_DRIVER,
				},
				{
					id_funcionalidade: 91,
					sidebarname: 'student-itinerary',
					sidebarlabel: 'Itinerário do Aluno',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).STUDENT_ITINERARY,
				},
				{
					id_funcionalidade: 88,
					sidebarname: 'transport-only-going',
					sidebarlabel: 'Transporte Escolar - ida',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).TRANSPORT_ONLY_GOING,
				},
				{
					id_funcionalidade: 92,
					sidebarname: 'transport-going-back',
					sidebarlabel: 'Transporte Escolar - Ida e Volta',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).TRANSPORT_GOING_BACK,
				},
				{
					id_funcionalidade: 140,
					sidebarname: 'list-of-vehicles',
					sidebarlabel: 'Lista de Veículos',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).LIST_VEHICLE,
				},
			],
		},

		// {
		//  id_funcionalidade: 19,
		// 	icon: <FaUserFriends />,
		// 	sidebarname: 'clocks',
		// 	sidebarlabel: 'Relogio de Ponto',
		// 	linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
		// 		.CLOCK_POINTS,
		// },
		{
			id_funcionalidade: 20,
			icon: <FaExclamationTriangle />,
			sidebarname: 'ocurrences',
			sidebarlabel: 'Ocorrências',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.OCCURRENCES,
		},
		{
			id_funcionalidade: 99,
			icon: <FaShoppingBasket />,
			sidebarname: 'products',
			sidebarlabel: 'Produtos',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PRODUCTS,
		},
		{
			id_funcionalidade: 104,
			icon: <FaShippingFast />,
			sidebarname: 'providers',
			sidebarlabel: 'Fornecedores',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PROVIDERS,
		},
		{
			id_funcionalidade: 109,
			icon: <FaFileAlt />,
			sidebarname: 'records',
			sidebarlabel: 'Registros',
			linkname: 'Records',
			subItems: [
				{
					id_funcionalidade: 114,
					sidebarname: 'records-inputs',
					sidebarlabel: 'Entradas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).RECORDS_INPUTS,
				},
				{
					id_funcionalidade: 115,
					sidebarname: 'records-outputs',
					sidebarlabel: 'Saídas',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).RECORDS_OUTPUTS,
				},
			],
		},
		{
			id_funcionalidade: 116,
			icon: <FaClipboardList />,
			sidebarname: 'shopping-list',
			sidebarlabel: 'Lista de Compras',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.SHOPPING_LIST,
		},
		{
			id_funcionalidade: 120,
			icon: <FaFolderOpen />,
			sidebarname: 'ticket',
			sidebarlabel: 'Fichas',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.TICKETS,
		},
		{
			id_funcionalidade: 125,
			icon: <FaReceipt />,
			sidebarname: 'commitments',
			sidebarlabel: 'Empenhos',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COMMITMENTS,
		},
		{
			id_funcionalidade: 130,
			icon: <FaHashtag />,
			sidebarname: 'numeration',
			sidebarlabel: 'Numeração',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.NUMERATION,
		},
		{
			id_funcionalidade: 135,
			icon: <FaConciergeBell />,
			sidebarname: 'menu',
			sidebarlabel: 'Cardápio',
			linkname: 'Menu',
			subItems: [
				{
					id_funcionalidade: 136,
					sidebarname: 'menu-find',
					sidebarlabel: 'Visualizar Cardápio',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).MENU,
				},
				{
					id_funcionalidade: 137,
					sidebarname: 'menu-add',
					sidebarlabel: 'Cadastro de Cardápio',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).MENU_ADD,
				},
				{
					id_funcionalidade: 138,
					sidebarname: 'menu-edit',
					sidebarlabel: 'Editar',
					linkname: Routes.LOGGED_ROUTES(
						RouteByPermission[userActualType]
					).MENU_EDIT,
				},
			],
		},
		{
			id_funcionalidade: 160,
			icon: <FaWpforms />,
			sidebarname: 'routes-transport',
			sidebarlabel: 'Rotas de Trasnporte',
			linkname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_ROUTE_TRANSPORT,
		},
	];

	function comparer(otherArray) {
		return function(current, index) {
			return (
				otherArray.filter(function(other) {
					if (current.subItems) {
						const subItemsNew = current.subItems.filter(
							comparer(functionalities)
						);
						current.subItems = subItemsNew;
					}
					return other.id_funcionalidade == current.id_funcionalidade;
				}).length == 1
			);
		};
	}

	useEffect(() => {
		if (functionalities) {
			const result = sidebaritems.filter(comparer(functionalities));
			setSideBar(result);
		}
	}, [functionalities]);

	return (
		<nav className="navbar navbar-expand-lg navbar-light">
			<button
				className="navbar-toggler ml-auto mb-2 bg-light"
				type="button"
				data-toggle="collapse"
				data-target="#myNavbar"
			>
				<span className="navbar-toggler-icon" />
			</button>
			<div className="collapse navbar-collapse" id="myNavbar">
				<div className="container-fluid">
					<div className="row">
						<Menu />
						<Sidebar items={sideBar} />
					</div>
				</div>
			</div>
		</nav>
	);
}
