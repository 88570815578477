import React from 'react';
import { Line } from 'react-chartjs-2';
import './styleCharts.css';

export default function Charts({ title, quantities, labelsArray }) {
	const chart = {
		data: {
			datasets: [
				{
					label: title,
					data: quantities,
					fill: false,
					borderColor: 'rgb(75, 192, 192)',
					tension: 0.1,
				},
			],
			labels: labelsArray,
		},
	};
	return (
		<div className="card">
			<div className="card-body">
				<span className="tittle-graphics">{title}</span>
				<Line data={chart.data} options={chart.options} />
			</div>
		</div>
	);
}
