import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectCorRaca({ setCorRaca, valueSelect, disabled }) {
	const [corRacas, setCorRacas] = useState([]);

	useEffect(() => {
		async function loadCorRaca() {
			const response = await api.get('/tabelas/cor-raca');
			setCorRacas(response.data.data);
		}
		loadCorRaca();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(corRacas) {
		setCorRaca(corRacas);
	}

	const corRacaList =
		corRacas &&
		corRacas.map(cor => (
			<option value={cor.id_cor_raca} key={cor.id_cor_raca}>
				{cor.desc_cor_raca}
			</option>
		));
	return (
		<>
			<select
				disabled={disabled}
				required="required"
				className="form-control"
				name="lblIdCorRaca"
				id="lblIdCorRaca"
				value={valueSelect}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Escolha uma Cor/Raça</option>
				{corRacaList}
			</select>
		</>
	);
}
