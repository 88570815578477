import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Alternativas from '../Questions/Alternative';
import Dissertativa from '../Questions/Essay';
import CardQuestions from '../Questions/CardQuestions';
import parseJwt from '../../../helpers/parseJwt';
import './styles.css';

export default function Evaluation() {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	const [avaliacao, setAvaliacao] = useState([]);

	const [tituloProva, SetTituloProva] = useState('');
	const [descricaoProva, SetDescricaoProva] = useState('');
	const [horario, setHorario] = useState(0);
	const [radioValue, SetradioValue] = useState(0);
	const [peso, SetPeso] = useState(0);

	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Avalicações',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const handleSubmit = async e => {
		e.preventDefault();

		const data = {
			professor_id: idUser,
			titulo: tituloProva,
			descricao: descricaoProva,
			duracao: horario,
			peso,
			questoes: avaliacao,
		};
		console.log('dados', data);

		const response = await api.post('/avaliacao/criar', data);

		if (response.data.error === true)
			swal('Ops!', `${response.data.message}`, 'warning');
		else {
			swal('Sucesso!', 'Avaliação cadastrada com sucesso', 'success');
		}
	};

	const saveQuestion = item => {
		setAvaliacao([...avaliacao, item]);
	};

	const handleDeleteQuestion = index => {
		avaliacao.splice(index, 1);
		setAvaliacao([...avaliacao]);
	};

	const handleEditQuestion = (question, index) => {
		avaliacao[index] = question;
		setAvaliacao([...avaliacao]);
	};

	const handleSumPeso = item => {
		const result = item + peso;
		SetPeso(result);
	};

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header">Avaliação</div>

				<div className="card-body">
					<div className="row">
						<div className="inputForm input-group-md">
							<form onSubmit={handleSubmit}>
								<div className="avaliacaoData">
									<label htmlFor="avaliacao">
										<h5>Título</h5>
										<input
											className="form-control"
											type="text"
											name="titulo"
											onChange={event =>
												SetTituloProva(
													event.target.value
												)
											}
											required
										/>
									</label>

									<label htmlFor="Video">
										<h5>Descrição</h5>
										<textarea
											className="form-control"
											type="text"
											name="descricao"
											onChange={event =>
												SetDescricaoProva(
													event.target.value
												)
											}
										/>
									</label>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="card mt-3" id="contentQuestoes">
				<div className="card-header" />
				<div className="checkGroup">
					<div className="custom-control custom-radio custom-control-inline">
						<input
							type="radio"
							id="customRadioInline1"
							name="customRadioInline"
							className="custom-control-input"
							value={1}
							onChange={event =>
								SetradioValue(event.target.value)
							}
						/>
						<label
							className="custom-control-label"
							htmlFor="customRadioInline1"
						>
							Dissertativa
						</label>
					</div>
					<div className="custom-control custom-radio custom-control-inline">
						<input
							type="radio"
							id="customRadioInline2"
							name="customRadioInline"
							className="custom-control-input"
							value={2}
							onChange={event =>
								SetradioValue(event.target.value)
							}
						/>
						<label
							className="custom-control-label"
							htmlFor="customRadioInline2"
						>
							Alternativa
						</label>
					</div>
				</div>
				{/* questionsGroup */}
				<div className="card-body ">
					{radioValue == 0 ? (
						<div className="badges-datas-eventos">
							<b> Selecione um dos tipos de questões</b>
						</div>
					) : radioValue == 1 ? (
						<Dissertativa saveQuestion={saveQuestion} />
					) : (
						<Alternativas saveQuestion={saveQuestion} />
					)}
				</div>
			</div>

			<div className="card mt-3">
				<div className="card-header">Questões</div>

				<div className="card-body questions">
					{avaliacao.length ? (
						avaliacao.map((questions, index) => (
							<CardQuestions
								key={index}
								id={index}
								questions={questions}
								peso={handleSumPeso}
								editQuestion={handleEditQuestion}
								deleteQuestion={handleDeleteQuestion}
							/>
						))
					) : (
						<div className="badges-datas-eventos">
							<b>Nenhum questão criada</b>
						</div>
					)}
				</div>

				<div className="card-footer text-muted">
					<button
						type="submit"
						onClick={handleSubmit}
						className="btn btn-primary"
					>
						Salvar prova
					</button>
				</div>
			</div>
		</>
	);
}
