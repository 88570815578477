import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import './style.css';
import { useSelector } from 'react-redux';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import cep from 'cep-promise';
import regex from '../../../helpers/regex';

export default function Add() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [fetching, setFetching] = useState(false);
	const [blockInputAddress, setBlockInputAddress] = useState(true);
	const formElement = useRef(null);
	const [name, setName] = useState(null);
	const [CPF, setCPF] = useState('');
	const [DAPNumber, setDAPNumber] = useState(null);
	const [CNPJ, setCNPJ] = useState('');
	const [providerCategory, setProviderCategory] = useState(null);
	const [telephone, setTelephone] = useState(null);
	const [telephoneDDD, setTelephoneDDD] = useState(null);
	const [bank, setBank] = useState(null);
	const [account, setAccount] = useState(null);
	const [agency, setAgency] = useState(null);
	const [CEP, setCEP] = useState('');
	const [street, setStreet] = useState(null);
	const [complement, setComplement] = useState(null);
	const [state, setState] = useState(null);
	const [city, setCity] = useState(null);
	const [neighborhood, setNeighborhood] = useState(null);
	const [homeNumber, setHomeNumber] = useState(null);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PROVIDERS,
			namePage: 'Fornecedores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	function clearForm() {
		formElement.current.reset();
		setName(null);
		setCPF('');
		setCEP('');
		setCNPJ('');
		setDAPNumber(null);
		setTelephone(null);
		setTelephoneDDD(null);
		setBank(null);
		setAccount(null);
		setAgency(null);
		setStreet(null);
		setComplement(null);
		setState(null);
		setCity(null);
		setNeighborhood(null);
		setHomeNumber(null);
	}

	async function getCategoryOptions() {
		const response = await api.get(
			'/cozinha-piloto/fornecedores/categorias'
		);

		!response.data.error
			? setCategoryOptions(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function addProvider(event) {
		event.preventDefault();

		try {
			const response = await api.post(
				'/cozinha-piloto/fornecedores/adicionar',
				{
					nome: name,
					cpf: CPF,
					numero_dap: DAPNumber,
					cnpj: CNPJ,
					id_categoria: providerCategory,
					tel_numero: telephone,
					ddd_numero: telephoneDDD,
					banco: bank,
					conta: account,
					agencia: agency,
					cep: CEP,
					logradouro: street,
					complemento: complement,
					estado: state,
					cidade: city,
					bairro: neighborhood,
					numero_casa: homeNumber,
				}
			);

			if (!response.data.error) {
				swal(
					'SUCESSO!',
					'Fornecedor adicionado com sucesso',
					'success'
				);
				clearForm();
			} else swal('ERRO!', `${response.data.message}`, 'error');
		} catch (error) {
			swal('ERRO!', 'Houve um erro ao adicionar o fornecedor', 'error');
		}
	}

	async function fetchCEP(event) {
		event.preventDefault();

		setFetching(true);

		try {
			const response = await cep(CEP);

			setCity(response.city);
			setNeighborhood(response.neighborhood);
			setStreet(response.street);
			setState(response.state);
		} catch (error) {
			swal('', 'CEP não encontrado', 'warning');
			setBlockInputAddress(false);
		}

		setFetching(false);
	}

	useEffect(() => {
		getCategoryOptions();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={addProvider} ref={formElement}>
				<div className="form-group">
					<label htmlFor="nameInputKitchenProviderAdd">Nome</label>
					<input
						id="nameInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_LETTERS}
						value={name}
						onChange={event => setName(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="cpfInputKitchenProviderAdd">CPF</label>
					<InputMask
						id="cpfInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						pattern={regex.CPF}
						mask="999.999.999-99"
						value={CPF}
						onChange={event => setCPF(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="dapNumberInputKitchenProviderAdd">
						Número DAP
					</label>
					<input
						id="dapNumberInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_NUMBERS}
						value={DAPNumber}
						onChange={event => setDAPNumber(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="cnpjInputKitchenProviderAdd">CNPJ</label>
					<InputMask
						id="cnpjInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						mask="99.999.999/9999-99"
						pattern={regex.CNPJ}
						value={CNPJ}
						onChange={event => setCNPJ(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="categorySelectProviderAdd">
						Selecione a categoria
					</label>
					<select
						id="categorySelectProviderAdd"
						className="form-control addProviderFormSelect"
						required
						value={providerCategory}
						onChange={item =>
							setProviderCategory(+item.target.value)
						}
					>
						<option selected disabled />
						{categoryOptions
							? categoryOptions.map(item => (
									<option value={item.id_categoria}>
										{item.nome}
									</option>
							  ))
							: ''}
					</select>
				</div>
				<div className="form-row d-flex align-items-end">
					<div className="col-4">
						<label htmlFor="telephoneDDDInputKitchenProviderAdd">
							DDD do Telefone
						</label>
						<input
							id="telephoneDDDInputKitchenProviderAdd"
							type="text"
							className="form-control"
							required
							pattern={regex.TELEPHONE_DDD}
							value={telephoneDDD}
							onChange={event =>
								setTelephoneDDD(event.target.value)
							}
						/>
					</div>
					<div className="col-8">
						<label htmlFor="telephoneInputKitchenProviderAdd">
							Telefone
						</label>
						<input
							id="telephoneInputKitchenProviderAdd"
							type="text"
							className="form-control"
							required
							pattern={regex.TELEPHONE}
							value={telephone}
							onChange={event => setTelephone(event.target.value)}
						/>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="bankInputKitchenProviderAdd">Banco</label>
					<input
						id="bankInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_LETTERS}
						value={bank}
						onChange={event => setBank(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="accountInputKitchenAdd">Conta</label>
					<input
						id="accountInputKitchenAdd"
						type="text"
						className="form-control"
						required
						pattern={regex.BANK_ACCOUNT}
						value={account}
						onChange={event => setAccount(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="agencyInputKitchenProviderAdd">
						Agência
					</label>
					<input
						id="agencyInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_NUMBERS}
						value={agency}
						onChange={event => setAgency(event.target.value)}
					/>
				</div>
				<div className="form-row align-items-end my-2">
					<div className="col-12 col-md-10">
						<label htmlFor="cepInputKitchenProviderAdd">CEP</label>
						<InputMask
							id="cepInputKitchenProviderAdd"
							type="text"
							className="form-control"
							pattern={regex.CEP}
							required
							mask="99.999-999"
							value={CEP}
							onChange={event => setCEP(event.target.value)}
						/>
					</div>
					{!fetching ? (
						<div className="col-12 col-md-2 mt-4 d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-primary"
								onClick={fetchCEP}
							>
								Preencher Campos
							</button>
						</div>
					) : (
						<div className="col-12 col-md-2 mt-4 d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-primary"
								disabled
							>
								Carregando...
							</button>
						</div>
					)}
				</div>
				<div className="form-row my-2">
					<div className="col-8">
						<label htmlFor="complementInputKitchenProviderAdd">
							Complemento
						</label>
						<input
							id="complementInputKitchenProviderAdd"
							type="text"
							className="form-control"
							required
							pattern={regex.LETTERS_AND_NUMBERS}
							value={complement}
							onChange={event =>
								setComplement(event.target.value)
							}
						/>
					</div>
					<div className="col-4">
						<label htmlFor="homeNumberInputKitchenProviderAdd">
							Número
						</label>
						<input
							id="homeNumberInputKitchenProviderAdd"
							type="text"
							className="form-control"
							required
							pattern={regex.HOUSE_NUMBER}
							value={homeNumber}
							onChange={event =>
								setHomeNumber(event.target.value)
							}
						/>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="streetInputKitchenProviderAdd">
						Logradouro
					</label>
					<input
						id="streetInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						disabled={blockInputAddress}
						pattern={regex.LETTERS_AND_NUMBERS}
						value={street}
						onChange={event => setStreet(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="neighborhoodInputKitchenProviderAdd">
						Bairro
					</label>
					<input
						id="neighborhoodInputKitchenProviderAdd"
						type="text"
						className="form-control"
						required
						disabled={blockInputAddress}
						pattern={regex.ALL}
						value={neighborhood}
						onChange={event => setNeighborhood(event.target.value)}
					/>
				</div>
				<div className="form-row my-3">
					<div className="col-6 col-sm-10">
						<label htmlFor="homeNumberInputKitchenProvidersAdd">
							Cidade
						</label>
						<input
							id="homeNumberInputKitchenProvidersAdd"
							type="text"
							className="form-control"
							required
							disabled={blockInputAddress}
							pattern={regex.ONLY_LETTERS}
							value={city}
							onChange={event => setCity(event.target.value)}
						/>
					</div>
					<div className="col-6 col-sm-2">
						<label htmlFor="stateInputKitchenProvidersAdd">
							Estado
						</label>
						<input
							id="stateInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							disabled={blockInputAddress}
							pattern={regex.STATE}
							value={state}
							onChange={event => setState(event.target.value)}
						/>
					</div>
				</div>
				<button type="submit" className="btn btn-primary">
					Adicionar
				</button>
			</form>
		</>
	);
}
