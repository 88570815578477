import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import semecLogo from '../../../assets/img/semec_prefeitura.png';
import api from '../../../services/api';
import Routes from '../../../routes/data/Routes';

export default function ForgotPassword({ history }) {
	const [emailUser, setEmailUser] = useState('');
	const [errorEmail, setErrorEmail] = useState('');

	async function handleEmailForgot(event) {
		event.preventDefault();
		const response = await api.post('/esqueceusenha', {
			login: emailUser,
		});
		if (!response.data.error) {
			setErrorEmail(response.data.message);
			setTimeout(function() {
				history.push(Routes.VERIFY_CODE);
			}, 3000);
		} else setErrorEmail(response.data.message);
	}
	return (
		<>
			<div className="background_page_form">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-lg-7 col-md-9 mt-5">
							<div className="card" id="login-card">
								<div className="card-body">
									<div className="row justify-content-center text-center">
										<div className="col-12">
											<img
												src={semecLogo}
												className="logoSchool"
												alt="Logo from school"
											/>
										</div>
									</div>
									<form
										className="login_page_form"
										onSubmit={handleEmailForgot}
									>
										<div className="row justify-content-center">
											<div className="form-group col-12 col-lg-10">
												<label htmlFor="loginEmail">
													Login
												</label>
												<input
													type="email"
													name="loginEmail"
													id="loginEmail"
													placeholder="Digite seu login"
													className="form-control"
													onChange={event =>
														setEmailUser(
															event.target.value
														)
													}
													required
												/>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="col-10 form-group">
												<span className="errorLogin">
													{errorEmail}
												</span>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="form-group col-12 col-lg-10">
												<small>
													<b>OBS:</b> Se você for um responsável
													ou um aluno essa área não
													funcionará, sendo assim você
													deverá solicitar a alteração
													de senha para a secretaria
													da escola.
												</small>
											</div>
										</div>
										<div className="row justify-content-center text-center">
											<div className="col-12 col-lg-10 form-group">
												<button
													type="submit"
													className="btn btn-primary btn-block"
													id="loginBtn"
												>
													Enviar
												</button>
											</div>
										</div>
									</form>
									<div className="row justify-content-center text-center">
										<div className="col-10 form-group">
											<Link
												to={Routes.LOGIN}
												id="forgotpassword"
											>
												Login
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
