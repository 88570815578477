import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useLocation, useHistory } from 'react-router-dom';
import { FaTimesCircle, FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../components/Breadcrumb';
import InfoCardVehicle from './InfoCardVehicle';
import RouteParams from '../../../routes/data/RouteParams';
import Table from '../../../components/Table';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';

export default function InfoDriver({ breadCrumbItems }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const location = useLocation();

	const [Email, setEmail] = useState('');
	const [descSexo, setDescSexo] = useState('');
	const [CpfFuncionario, setCpfFuncionario] = useState('');
	const [NumDocumentoCivil, setNumDocumentoCivil] = useState('');
	const [DigitoDocumento, setDigitoDocumento] = useState('');
	const [DataEmissao, setDataEmissao] = useState('');
	const [SiglaUF, setSiglaUF] = useState('');
	const [Cep, setCep] = useState('');
	const [NumCasa, setNumCasa] = useState('');
	const [ComplementoEndereco, setComplementoEndereco] = useState('');
	const [Logradouro, setLogradouro] = useState('');
	const [Bairro, setBairro] = useState('');
	const [NomeCidade, setNomeCidade] = useState('');
	const [SiglaUfCidade, setSiglaUfCidade] = useState('');
	const [descAreaLogradouro, setDescAreaLogradouro] = useState(1);
	const [descLocalizacao, setDescLocalizacao] = useState(4);
	const [Contato, setContato] = useState([]);
	const [Disable, setDisable] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [NomeMotorista, setNomeMotorista] = useState('');
	const [hoursWorked, setHoursWorked] = useState([]);
	const [DataNascimentoFuncionario, setDataNascimentoFuncionario] = useState(
		''
	);
	const [ListVehicles, setListVehicles] = useState([]);
	const [routes, setRoutes] = useState([]);

	async function loadHoursDriver(e) {
		const { data } = await api.get(
			'/transporte/motorista/horas-motorista',
			{
				params: {
					idDriver: e,
				},
			}
		);
		if (data.error === false) setHoursWorked(data.data);
		else setHoursWorked([]);
	}

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			if (location.state) {
				const infoUser =
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_DRIVER.DRIVER
					];
				setEmail(infoUser.users.email);
				setDescSexo(infoUser.users.desc_sexo);
				setCpfFuncionario(infoUser.users.cpf);
				setNumDocumentoCivil(infoUser.users.rg);
				setDigitoDocumento(infoUser.users.digito);
				setDataEmissao(infoUser.users.data_emissao_documento_civil);
				setSiglaUF(infoUser.users.sigla_uf);
				setCep(infoUser.users.cep);
				setNumCasa(infoUser.users.numero);
				setComplementoEndereco(infoUser.users.complemento_endereco);
				setLogradouro(infoUser.users.logradouro);
				setBairro(infoUser.users.bairro);
				setNomeCidade(infoUser.users.cidade);
				setSiglaUfCidade(infoUser.users.estado);
				setDescAreaLogradouro(infoUser.users.desc_area);
				setDescLocalizacao(infoUser.users.desc_localizacao);
				setNomeMotorista(infoUser.users.nome_usuario);
				setDataNascimentoFuncionario(infoUser.users.nascimento);
				setContato(infoUser.contacts);
				setListVehicles(infoUser.vehicles);
				setRoutes(infoUser.routes);
				loadHoursDriver(infoUser.users.id_usuario);
			} else
				swal({ icon: 'error', text: 'Escolha um Motorista' }).then(
					event => {
						history.goBack();
					}
				);
			setIsLoading(false);
		})();
	}, []);

	function OptionsButton({ idTransporte }) {
		function viewNavigation() {
			const params = {};

			params[
				RouteParams.LEVEL_08_ROUTES.EDIT_TRANSPORT.ID_TRANSPORT
			] = idTransporte;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.INFO_ROUTE_TRANSPORT,
				{ params }
			);
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Rota"
					className="buttonViewColor"
					onClick={() => viewNavigation(idTransporte)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading === true && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<>
					<div className="card mt-3">
						<div className="card-header">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<h4>Dados do Motorista</h4>
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Nome do Funcionário:</b>
									</p>
									<div className="input-group mb-4">
										{NomeMotorista}
									</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Data de aniversário: </b>
									</p>
									<div className="input-group mb-4">
										{DataNascimentoFuncionario}
									</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Gênero</b>
									</p>
									{descSexo}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>E-mail</b>
									</p>
									{Email}
								</div>
							</div>
						</div>
					</div>
					<InfoCardVehicle listVehicle={ListVehicles} disabled />
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Horas Trabalhada</h4>
						</div>
						<div className="card-body">
							{hoursWorked ? (
								<Table
									header={['Mês', 'Hora trabalhada']}
									body={hoursWorked.map(hourWorked => {
										return [
											hourWorked.month,
											hourWorked.hour,
										];
									})}
								/>
							) : (
								<div className="productsNotFound">
									<FaTimesCircle size={30} />
									<p className="mt-2">
										Nenhuma rota de transporte encontrada
									</p>
								</div>
							)}
						</div>
					</div>
					<div>
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Documentos</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Número de Documento Civil</b>
										</p>
										{NumDocumentoCivil ? (
											<div className="row">
												<div className="col-12 col-md-9 col-lg-9 form-group">
													{NumDocumentoCivil}-
													{DigitoDocumento}
												</div>
											</div>
										) : (
											<p>
												Usuário não tem essa informação
											</p>
										)}
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>CPF do Funcionário</b>
										</p>
										<div className="input-group mb-4">
											{CpfFuncionario || (
												<p>
													Usuário não tem essa
													informação
												</p>
											)}
										</div>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Estado do Documento</b>
										</p>
										{SiglaUF || (
											<p>
												Usuário não tem essa informação
											</p>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Data da Emissão do Documento</b>
										</p>
										{DataEmissao || (
											<p>
												Usuário não tem essa informação
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Contato</h4>
						</div>
						<div className="card-body">
							{Contato.length > 0 ? (
								Contato.map(contact => (
									<>
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Telefone: </b>(
													{contact.ddd_numero}){' '}
													{contact.tel_numero}
												</p>
											</div>
											{contact.complemento ? (
												<div className="col-12 col-md-4 col-lg-4 form-group">
													<p>
														<b>Complemento: </b>
														{contact.complemento}
													</p>
												</div>
											) : null}
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Deixar Recados: </b>
													{contact.sms}
												</p>
											</div>
										</div>
										<hr />
									</>
								))
							) : (
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>Não há registro no Sistema</p>
								</div>
							)}
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Rotas</h4>
						</div>
						<div className="card-body">
							{routes.length ? (
								<Table
									header={[
										'Data',
										'Hora trabalhada',
										'Placa',
										'Modelo',
										'',
									]}
									body={routes.map(route => {
										return [
											route.dia_transporte,
											route.horas_total,
											route.placa,
											route.modelo,
											<OptionsButton
												idTransporte={
													route.id_transporte
												}
											/>,
										];
									})}
								/>
							) : (
								<div className="productsNotFound">
									<FaTimesCircle size={30} />
									<p className="mt-2">
										Nenhuma rota de transporte encontrada
									</p>
								</div>
							)}
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Endereço</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>CEP</b>
									</p>
									<div className="input-group mb-3">
										{Cep}
									</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Logradouro</b>
									</p>
									{Logradouro}
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p>
										<b>Número</b>
									</p>
									{NumCasa}
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p>
										<b>Complemento</b>
									</p>
									{ComplementoEndereco}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Bairro</b>
									</p>
									{Bairro}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Cidade</b>
									</p>
									{NomeCidade}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>UF</b>
									</p>
									{SiglaUfCidade}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Área do logradouro</b>
									</p>
									{descAreaLogradouro}
								</div>
								<div className="col-12 col-md-8 col-lg-8 form-group">
									<p>
										<b>Localização Diferenciada</b>
									</p>
									{descLocalizacao}
								</div>
							</div>
						</div>
						{Disable === false && (
							<div className="card-footer">
								<div className="row justify-content-start">
									<div className="col-lg-4 col-md-5 col-7 form-group">
										<button
											className="btn btn-primary btn-block"
											type="submit"
										>
											Atualizar
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
}
