import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectClasses({
	setClasse,
	valueSelect,
	disabled,
	schoolId,
	required,
}) {
	const [classes, setClasses] = useState([]);
	useEffect(() => {
		(async function() {
			const response = await api.get('/classes/lista', {
				params: {
					schoolId,
				},
			});
			if (!response.data.error) setClasses(response.data.data);
		})();
	}, [schoolId]);
	// eslint-disable-next-line no-shadow
	async function handleSubmit(classes) {
		setClasse(classes);
	}
	const classeList = classes.map(classe => (
		<option value={classe.id_classe} key={classe.id_classe}>
			{classe.desc_serie_ano
				? classe.desc_serie_ano
				: classe.desc_tipo_ensino}{' '}
			- {classe.turma}
		</option>
	));
	return (
		<>
			<select
				disabled={disabled}
				required={required}
				className="form-control"
				name="classeId"
				id="classeId"
				value={valueSelect}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha uma Classe</option>
				{classeList}
			</select>
		</>
	);
}
