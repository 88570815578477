import React from 'react';

// eslint-disable-next-line react/prop-types
export default function SelectKindSchool({ setTipoUsuario }) {
	// eslint-disable-next-line no-shadow
	async function handleSubmit(tipoUsuarios) {
		setTipoUsuario(tipoUsuarios);
	}

	return (
		<>
			<select
				required
				className="form-control"
				id="lblTipoUsuario"
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Tipo de Usuário</option>
				<option value={1}>Todos</option>
				<option value={2}>Alunos</option>
				<option value={3}>Professores</option>
				<option value={4}>Responsáveis</option>
				<option value={5}>Coordenador</option>
			</select>
		</>
	);
}
