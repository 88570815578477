const Status = [
	{
		id: 0,
		name: 'Aguardando vaga',
	},
	{
		id: 1,
		name: 'Matriculado',
	},
	{
		id: 2,
		name: 'Matriculado por decisão judicial',
	},
	{
		id: 3,
		name: 'Desistência',
	},
	{
		id: 4,
		name: 'Idade não permitida',
	},
];

export default Status;