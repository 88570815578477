import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Routes from '../../../routes/data/Routes';
import api from '../../../services/api';
import env from '../../../env';
import './style.css';
import { useFunctionalities } from '../../../components/Contenxt/Functionalities';

export default function Ticket({ ticketId, breadCrumbItems }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const [ticket, setTicket] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [status, setStatus] = useState(null);
	const [messageForm, setMessageForm] = useState('');

	const [updateTicket, setUpdateTicker] = useState(false);
	const {
		functionalities: { functionalities },
	} = useFunctionalities();

	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 48
			);
			if (existe) setUpdateTicker(true);
			else setUpdateTicker(false);
		}
	}, [functionalities]);

	async function loadTicket() {
		setIsLoading(true);
		if (ticketId) {
			const { data } = await api.get('/ticket/ver-ticket', {
				params: {
					idTicket: ticketId,
				},
			});
			if (data.error === false) {
				setTicket(data.data);
				setStatus(data.data.ticket.status);
			} else {
				setStatus(null);
				setTicket([]);
				swal({
					icon: 'warning',
					text: data.message,
				});
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_TICKETS
				);
			}
			setIsLoading(false);
		}
	}

	useEffect(() => {
		loadTicket();
	}, [ticketId]);

	async function handleUpdateStatus(event) {
		event.preventDefault();
		const dataPost = {
			idTicket: ticketId,
			status,
		};
		const { data } = await api.post('/ticket/atualizar-ticket', dataPost);
		if (data.error === false) {
			swal({ icon: 'success', text: data.message });
			setMessageForm('');
			loadTicket();
		} else swal({ icon: 'warning', text: data.message });
	}

	async function handleSendMessage(event) {
		event.preventDefault();
		const dataPost = {
			idTicket: ticketId,
			message: messageForm,
		};
		const { data } = await api.post('/ticket/responder-ticket', dataPost);
		if (data.error === false) {
			swal({ icon: 'success', text: data.message });
			setMessageForm('');
			loadTicket();
		} else swal({ icon: 'warning', text: data.message });
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-header tittle-ticket-card">
						#{ticket.ticket.id_ticket} - {ticket.ticket.titulo}
					</div>
					<div className="card-body body-ticket-card">
						<form onSubmit={handleUpdateStatus}>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<div className="border-body-card">
										{updateTicket ? (
											<>
												<p>
													<b>Status</b>
												</p>
												<select
													id="class"
													className="form-control"
													value={status}
													onChange={event =>
														setStatus(
															event.target.value
														)
													}
												>
													<option value="">
														Escolha uma opção
													</option>
													<option value="ABERTO">
														ABERTO
													</option>
													<option value="FAZENDO">
														FAZENDO
													</option>
													<option value="FECHADO">
														FECHADO
													</option>
												</select>
											</>
										) : (
											<>
												<p>
													<b>Status</b>
												</p>
												<p>{ticket.ticket.status}</p>
											</>
										)}
									</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Autor</b>
									</p>
									<p>{ticket.ticket.nome_usuario}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Escola</b>
									</p>
									<p>{ticket.ticket.desc_nome_escola}</p>
								</div>
							</div>
							<div>
								<p>
									<b>Arquivos</b>
								</p>
								{ticket.files.length ? (
									<ul>
										{ticket.files.map(arquivo => (
											<li className="arquivos">
												<a
													href={`${
														env.SITE_URL
													}/file/ticket-arquivo/download?file=${
														arquivo.nome_completo
													}&token=${localStorage.getItem(
														'@token'
													)}`}
													rel="noreferrer"
													target="_blank"
													className="arquivo-link"
												>
													Download
												</a>
												&nbsp;-&nbsp;
												<a
													href={`${
														env.SITE_URL
													}/file/ticket-arquivo?file=${
														arquivo.nome_completo
													}&token=${localStorage.getItem(
														'@token'
													)}`}
													rel="noreferrer"
													target="_blank"
													className="arquivo-link"
												>
													{arquivo.nome}.
													{arquivo.extensao}
												</a>
											</li>
										))}
									</ul>
								) : (
									<p>Sem arquivos</p>
								)}
							</div>
							<div className="form-group">
								<p>
									<b>Descrição</b>
								</p>
								<textarea
									className="form-control"
									rows="6"
									value={ticket.ticket.descricao}
									disabled
								/>
							</div>
							{updateTicket && (
								<button
									type="submit"
									className="btn btn-warning"
								>
									Atualizar
								</button>
							)}
						</form>

						<hr />
						{ticket.messages.length ? (
							<>
								{ticket.messages.map(message => (
									<div className="card mt-2">
										<div className="card-header card-header-text-messages">
											<div className="row">
												<div className="col-12 col-md-6 col-lg-6">
													{message.respondente_nome}
												</div>
												<div className="col-12 col-md-6 col-lg-6 data-create-card">
													{message.created_at}
												</div>
											</div>
										</div>
										<div className="card-body">
											{message.mensagem}
										</div>
									</div>
								))}
							</>
						) : (
							<p>Sem mensagens</p>
						)}
						{ticket.ticket.status !== 'FECHADO' ? (
							<>
								<hr />
								<form onSubmit={handleSendMessage}>
									<div className="form-group">
										<label htmlFor="exampleFormControlTextarea1">
											<b>Escrever mensagem</b>
										</label>
										<textarea
											className="form-control"
											id="exampleFormControlTextarea1"
											rows="6"
											value={messageForm}
											required
											onChange={event =>
												setMessageForm(
													event.target.value
												)
											}
										/>
									</div>
									<button
										type="submit"
										className="btn btn-primary"
									>
										Enviar
									</button>
								</form>
							</>
						) : null}
					</div>
				</div>
			)}
		</>
	);
}
