const Sexo = [
	{
		id: 1,
		name: 'Masculino',
	},
	{
		id: 2,
		name: 'Feminino',
	},
];

export default Sexo;