import React, { useState } from 'react';
import swal from 'sweetalert';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { UserTypeActions } from '../redux/ducks/userTypeReducer';
import { useFunctionalities } from '../components/Contenxt/Functionalities';

import RoutesStudents from './RoutesStudents';
import RoutesResponsible from './RoutesResponsible';
import RoutesTeacher from './RoutesTeacher';
import RoutesCoordinator from './RoutesCoordinator';
import RoutesSchoolPrincipalSecretary from './RoutesSchoolPrincipalSecretary';
import RoutesAdministrative from './RoutesAdministrative';
import RoutesNutritionist from './RoutesNutritionist';
import RoutesTransportSupervisor from './RoutesTransportSupervisor';
import Level_10_Routes from './RoutesTransportMonitor';
import UsersHomeOnlyAccess from './UsersHomeOnlyAccess';

import LoginRoutes from './LoginRoutes';
import RouteByPermission from './data/RouteByPermission';
import Routes from './data/Routes';
import Page404 from '../pages/404/NotFound';

export default function Navigation({ userTypeId }) {
	const [token, setToken] = useState(localStorage.getItem('@token'));
	const [refreshToken, setRefreshToken] = useState(
		localStorage.getItem('@refreshToken')
	);
	const dispatch = useDispatch();
	const { setFunctionalities } = useFunctionalities();
	const history = useHistory();
	const { decodedToken } = useJwt(token);
	const refreshtoken = useJwt(refreshToken);

	async function expiredToken() {
		setToken(null);
		setRefreshToken(null);

		await swal('O token está expirado.').then(value => {
			setFunctionalities([]);
			dispatch(UserTypeActions.updateActualTypeId(null));
			dispatch(UserTypeActions.updateUserTypeIds([]));
			localStorage.clear();
			history.push(Routes.LOGIN);
			return <LoginRoutes />;
		});
	}

	if (
		refreshtoken.decodedToken &&
		refreshtoken.decodedToken.exp < Date.now() / 1000 &&
		decodedToken &&
		token &&
		decodedToken.exp < Date.now() / 1000
	) {
		expiredToken();
	}


	function buildRoutes(path, render) {
		return (
			<Switch>
				<Route
					exact
					path={Routes.LOGGED_ROUTES().INDEX}
					render={() => (
						<Redirect
							to={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userTypeId]
								).HOME
							}
						/>
					)}
				/>
				<Route path={path} render={render} />
				<Route path="*" component={Page404} />
			</Switch>
		);
	}
	const routes = [
		null,
		buildRoutes(RouteByPermission[1], RoutesAdministrative),
		buildRoutes(RouteByPermission[2], RoutesAdministrative),
		buildRoutes(RouteByPermission[3], RoutesSchoolPrincipalSecretary),
		buildRoutes(RouteByPermission[4], RoutesSchoolPrincipalSecretary),
		buildRoutes(RouteByPermission[5], RoutesSchoolPrincipalSecretary),
		buildRoutes(RouteByPermission[6], RoutesCoordinator),
		buildRoutes(RouteByPermission[7], RoutesTeacher),
		buildRoutes(RouteByPermission[8], RoutesResponsible),
		buildRoutes(RouteByPermission[9], RoutesStudents),
		buildRoutes(RouteByPermission[10], RoutesNutritionist),
		buildRoutes(RouteByPermission[11], RoutesNutritionist),
		buildRoutes(RouteByPermission[12], RoutesTransportSupervisor),
		buildRoutes(RouteByPermission[13], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[14], Level_10_Routes),
		buildRoutes(RouteByPermission[15], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[16], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[17], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[18], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[19], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[20], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[21], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[22], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[23], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[24], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[25], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[26], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[27], UsersHomeOnlyAccess),
		buildRoutes(RouteByPermission[28], RoutesAdministrative),
	];
	return routes[userTypeId];
}
