import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useJwt } from "react-jwt";

import { useHistory } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteParams from '../../routes/data/RouteParams';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../Table';

export default function TblNotices() {
	const history = useHistory();
	const [noticias, setlblNoticias] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);
	const { isExpired } = useJwt(localStorage.getItem('@token'));


	useEffect(() => {
		setIsLoading(true);
		(async function () {
			const message = await api.get('/noticias/lista-noticias');
			if (message.data.error === false) setlblNoticias(message.data.data);
			setIsLoading(false);
		})();
	}, []);

	function OptionsButton({ idNotice }) {
		function viewNavigation() {
			const params = {};
			params[RouteParams.COMMON_LEVELS.EDIT_NOTICES.NOTICE_ID] = idNotice;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.EDIT_NOTICES,
				params
			);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(idNotice)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<div className="card mt-3">
				<div className="card-body">
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							{noticias.length ? (
								<Table
									header={['Título', 'Autor', 'Data', '']}
									body={noticias.map(noticia => {
										return [
											noticia.titulo_noticia,
											noticia.desc_nome_escola,
											noticia.updated_at,
											<OptionsButton
												idNotice={noticia.id_noticia}
											/>,
										];
									})}
								/>
							) : (
								<h1>Sem Notícias</h1>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}
