import React from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import Routes from "../../../routes/data/Routes";
import RouteByPermission from "../../../routes/data/RouteByPermission";

export default function Breadcrumb() {
	const userActualType = useSelector(state => state.userType.actualType);

	return (
		<nav aria-label="breadcrumb" id="navBreadCrumb">
			<ol className="breadcrumb">
				<li className="breadcrumb-item">
					<Link to={Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).HOME}>Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to={Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).OCCURRENCES}>Ocorrências</Link>
				</li>
				<li className="breadcrumb-item active" aria-current="page">
					Mensagens
				</li>
			</ol>
		</nav>
	);
}
