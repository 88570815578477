import React from 'react';

import CardsTeacher from './CardsTeacher';
import ListNotices from '../../../components/Notices/ListNotices';
import VideoLesson from '../../../components/VideoLesson';

export default function Dash() {




	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<CardsTeacher />
						
						<ListNotices />
						<VideoLesson />
					</div>
				</div>
			</div>
		</>
	);
}
