import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectMotivo({ setMotivo, valueSelect, disabled }) {
	const [motivos, setMotivos] = useState([]);

	useEffect(() => {
		async function loadMotivoTransferencia() {
			const response = await api.get('/tabelas/motivos-transferencia');
			setMotivos(response.data.data);
		}
		loadMotivoTransferencia();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(motivos) {
		setMotivo(motivos);
	}
	const MotivosTransferencia = motivos.map(t => (
		<option
			value={t.id_motivo_transferencia}
			key={t.id_motivo_transferencia}
		>
			{t.desc_motivo}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblMotivosTansferencia"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Motivos transferencia</option>
				{MotivosTransferencia}
			</select>
		</>
	);
}
