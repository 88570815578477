import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectCidade({
	setCidade,
	valueSelect,
	disabled,
	ufCidade,
}) {
	const [cidade, setlblCidade] = useState([]);
	async function loadUf() {
		const response = await api.get('/tabelas/cidades', {
			params: {
				lblIdUf: ufCidade,
			},
		});
		setlblCidade(response.data.data);
	}
	useEffect(() => {
		loadUf();
	}, [ufCidade]);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(cidade) {
		setCidade(cidade);
	}
	const ListCidade =
		cidade &&
		cidade.map(U => (
			<option value={U.id_cidade} key={U.id_cidade}>
				{U.nome_cidade}
			</option>
		));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblIdUf"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha uma Cidade</option>
				{ListCidade}
			</select>
		</>
	);
}
