import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { FaTimesCircle, FaEye, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteParams from '../../../routes/data/RouteParams';
import Table from '../../../components/Table';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function InfoVehicle() {
	const history = useHistory();
	const location = useLocation();
	const userActualType = useSelector(state => state.userType.actualType);
	const [vehicles, setVehicles] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const [maintenances, setMaintenances] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_VEHICLE,
			namePage: 'Veículos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação do Veículo',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	async function loadVehicle() {
		setIsLoading(true);
		if (location.state) {
			const idVehicle =
				location.state.params[
					RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE
				];
			const { data } = await api.get('/transporte/veiculo/info-veiculo', {
				params: {
					idVeiculo: idVehicle,
				},
			});
			if (data.error === false) {
				setVehicles(data.data.infoVehicle);
				setDrivers(data.data.drivers);
				setMaintenances(data.data.maintenance);
			} else {
				setVehicles([]);
				setDrivers([]);
				setMaintenances([]);
			}
		} else
			swal({ icon: 'error', text: 'Escolha um veículo' }).then(event => {
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_VEHICLE
				);
			});
		setIsLoading(false);
	}
	useEffect(() => {
		loadVehicle();
	}, []);

	function OptionsButton({ maintenanceInfo }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_08_ROUTES.MAINTENANCE_INFO.INFO
			] = maintenanceInfo;
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.INFO_MAINTENANCE,
				{ params }
			);
		}

		function editNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_08_ROUTES.MAINTENANCE_INFO.INFO
			] = maintenanceInfo;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.UPDATE_MAINTENANCE,
				{ params }
			);
		}

		async function handleDeactivated(e) {
			swal({
				title: 'Você tem certeza?',
				text: 'Você está para desativar a informação da manutenção',
				icon: 'warning',
				buttons: ['Cancelar', 'Confirmar'],
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const data = { idMaintenace: e.id_manutencao_veiculo };
						const {
							data: { message },
						} = await api.post(
							'/transporte/veiculo/manutencao/desativa-manutencao',
							data
						);
						swal('SUCESSO!', `${message}`, 'success');
						loadVehicle();
					})();
				else swal('Ação cancelada');
			});
		}

		return (
			<>
				<button
					disabled={!vehicles.ativo}
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Veículo"
					className="buttonViewColor"
					onClick={() => viewNavigation(maintenanceInfo)}
				>
					<FaEye fill="#ffffff" />
				</button>
				<button
					disabled={!vehicles.ativo}
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Editar Veículo"
					className="buttonAddColor"
					onClick={() => editNavigation(maintenanceInfo)}
				>
					<FaPencilAlt fill="#ffffff" />
				</button>

				<button
					disabled={!vehicles.ativo}
					type="button"
					className="buttonDelete"
					aria-hidden
					data-toggle="tooltip"
					title="Desativar Veículo"
					onClick={() => handleDeactivated(maintenanceInfo)}
				>
					<FaTrashAlt fill="#ffffff" />
				</button>
			</>
		);
	}

	async function handleAddMaintenance(e) {
		const params = {};
		params[RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE] = e;
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ADD_MAINTENANCE,
			{ params }
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Cadastro de Veículos</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group text-left">
									<p>
										<b>Cod. Renavam</b>
									</p>
									{vehicles.cod_renavam
										? vehicles.cod_renavam
										: 'Não informado'}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group text-left">
									<p>
										<b>Placa</b>
									</p>
									{vehicles.placa
										? vehicles.placa
										: 'Não informado'}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group text-left">
									<p>
										<b>Modelo</b>
									</p>
									{vehicles.modelo
										? vehicles.modelo
										: 'Não informado'}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group text-left">
									<p>
										<b>Proprietário</b>
									</p>
									{vehicles.proprietario
										? vehicles.proprietario
										: 'Não informado'}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group text-left">
									<p>
										<b>CPF Proprietário</b>
									</p>
									{vehicles.cpf_proprietario
										? vehicles.cpf_proprietario
										: 'Não informado'}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group text-left">
									<p>
										<b>Chassi</b>
									</p>
									{vehicles.chassi
										? vehicles.chassi
										: 'Não informado'}
								</div>
							</div>
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Motoristas</h4>
						</div>
						<div className="card-body">
							{drivers.length ? (
								<Table
									header={['Motorista', 'CPF']}
									body={drivers.map(driver => {
										return [
											driver.nome_usuario,
											driver.cpf,
										];
									})}
								/>
							) : (
								<div className="productsNotFound">
									<FaTimesCircle size={30} />
									<p className="mt-2">
										Nenhum motorista encontrado
									</p>
								</div>
							)}
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Manutenções</h4>
						</div>
						<div className="card-body">
							<div className="d-flex flex-row-reverse">
								{vehicles.ativo ? (
									<button
										className="btn buttonCustomSemec"
										type="button"
										id="button"
										onClick={() =>
											handleAddMaintenance(
												vehicles.id_veiculo
											)
										}
									>
										Inserir Manutenção
									</button>
								) : null}
							</div>
							{maintenances.length ? (
								<Table
									header={[
										'Responsável',
										'Entrada',
										'Saída',
										'Serviço',
										'',
									]}
									body={maintenances.map(maintenance => {
										return [
											maintenance.responsavel,
											maintenance.entrada_manutencao,
											maintenance.saida_manutencao
												? maintenance.saida_manutencao
												: 'Em manutenção',
											maintenance.servico_mecanico,
											<OptionsButton
												maintenanceInfo={maintenance}
											/>,
										];
									})}
								/>
							) : (
								<div className="productsNotFound">
									<FaTimesCircle size={30} />
									<p className="mt-2">
										Nenhuma manutenção encontrada
									</p>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
}
