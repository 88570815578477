const Values = [
	{
		id: 0,
		name: 'Não',
	},
	{
		id: 1,
		name: 'Sim',
	},
];

export default Values;