import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaClipboardList, FaPercent, FaRegBell } from 'react-icons/fa';
import Profile from '../Profile/ProfileStudent/Profile';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import HeaderDash from '../../components/HeaderDash';
import ListSubjects from '../Subjects/ListSubjects';
import ListNotices from '../../components/Notices/ListNotices';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import ScrollToTop from '../../components/buttom/ScrollToTop';
import './styleStudents.css';
import CardAttribution from '../../components/Cards/CardAttribution';
import VideoLesson from '../../components/VideoLesson';

export default function Level_01() {
	const [attendance, setAttendance] = useState('');
	const [notice, setNotice] = useState('');
	const [subjects, setSubjects] = useState('');
	const userActualType = useSelector(state => state.userType.actualType);

	async function loadFrequencia() {
		const response = await api.get('/aluno/frequenciatotal');
		setAttendance(response.data.data.porcentagem);
	}

	async function loadQuantidade() {
		const response = await api.get('/comunicados/quantidade');
		setNotice(response.data.data.count);
	}

	async function loadAulas() {
		const response = await api.get('/aluno/quantidade-aulas');
		setSubjects(response.data.data);
	}

	useEffect(() => {
		loadFrequencia();
		loadQuantidade();
		loadAulas();
	}, []);

	const cardsTeacher = [
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Frequência(Geral)',
			headerIcon: <FaPercent />,
			headerNumber: `${attendance}%`,
			iconBackGroundColor: 'bg-light-yellow',
			iconTextColor: 'text-orange',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).REPORT_CARD,
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Comunicados',
			headerIcon: <FaRegBell />,
			headerNumber: `${notice}`,
			iconBackGroundColor: 'bg-light-blue',
			iconTextColor: 'text-blue',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).COMMUNICATED,
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Aulas',
			headerIcon: <FaClipboardList />,
			headerNumber: `${subjects}`,
			iconBackGroundColor: 'bg-light-purple',
			iconTextColor: 'text-purple',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).ATTENDANCE,
		},
	];
	return (
		<>
			<HeaderDash headerItems={cardsTeacher} />
			<div className="col-xl-11 col-lg-11 col-md-10">
				<Profile />
			</div>
			<div className="col-xl-11 col-lg-11 col-md-10 mt-3">
				<ListSubjects />
				<ListNotices />
				<CardAttribution />
				<VideoLesson/>
			</div>
		</>
	);
}
