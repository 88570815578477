import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { FaEye } from 'react-icons/fa';
import api from '../../../../services/api';
import ChartBar from './ChartBar';
import Breadcrumb from '../../../../components/Breadcrumb';
import RouteParams from '../../../../routes/data/RouteParams';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import MediaClasse from './MediaClasse';
import './style.css';
import Table from '../../../../components/Table';

export default function InfoClass() {
	const history = useHistory();
	const { state } = useLocation();
	const userActualType = useSelector(user => user.userType.actualType);
	const [classe, setClasse] = useState({
		classe: {},
		students: [],
		count: [],
	});
	const [mediaNote, setMediaNote] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_CLASSES,
			namePage: 'Lista Classes',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação da Classe',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	function loadClasse() {
		if (!state) {
			swal('Escolha uma Classe');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_CLASSES
			);
		} else
			(async function() {
				const { data } = await api.get(
					`/classes/lista/info/${
						state[
							RouteParams.LEVEL_05_ROUTES.INFO_CLASS.CLASS_NUMBER
						]
					}`
				);
				if (data.error === false) {
					setIsLoading(false);
					setClasse(prevState => ({
						...prevState,
						classe: data.data.classe,
						students: data.data.alunos,
						count: data.data.count,
					}));
				} else {
					swal('ERRO!', `${data.message}`, 'error');
					history.goBack();
				}
			})();
	}
	function loadMediaDisciplina() {
		if (state)
			(async function() {
				const { data } = await api.get(
					`/classes/media-classe/${
						state[
							RouteParams.LEVEL_05_ROUTES.INFO_CLASS.CLASS_NUMBER
						]
					}`
				);
				if (data.error === false) setMediaNote(data.data);
			})();
	}

	function OptionsButton({ student }) {
		function viewNavigation() {
			const state = {};
			state[RouteParams.LEVEL_05_ROUTES.PROFILE_STUDENT.USER_ID] =
				student.id_usuario;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).PROFILE_STUDENT,
				state,
			});
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(student)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	useEffect(() => {
		loadClasse();
		loadMediaDisciplina();
	}, []);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div>
					<div className="card mt-3">
						<div className="card-header">
							<h5>
								<b>Sobre a Classe</b>
							</h5>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<p>
										<b>Série:</b>{' '}
										{classe.classe.desc_serie_ano}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<p>
										<b>Tipo de Ensino:</b>{' '}
										{classe.classe.desc_tipo_ensino}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Nome Turma:</b> {classe.classe.turma}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Turno:</b> {classe.classe.desc_turno}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Sala:</b> {classe.classe.num_sala}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-8 col-lg-8 form-group">
									<p>
										<b>Horário Inicial:</b>{' '}
										{classe.classe.horario_inicio}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4  form-group">
									<p>
										<b>Horário Final:</b>{' '}
										{classe.classe.horario_fim}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-8 col-lg-8 form-group">
									<p>
										<b>Capacidade Máxima:</b>{' '}
										{classe.classe.capacidade_fisica_max}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4  form-group">
									<p>
										<b>Quantidade Atual:</b>{' '}
										{classe.classe.qtd_atual}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 card-width">
							<div className="card mt-3">
								<div className="card-header form-group">
									<h5>
										<b>Media por Disciplina:</b>
									</h5>
								</div>
								<div className="card-body form-group">
									<MediaClasse count={mediaNote} />
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-6 card-width">
							<div className="card mt-3">
								<div className="card-header form-group">
									<h5>
										<b>Total de Alunos:</b>
									</h5>
								</div>
								<div className="card-body form-group">
									<ChartBar count={classe.count} />
								</div>
							</div>
						</div>
					</div>

					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h5>
								<b>Lista de Alunos:</b>
							</h5>
						</div>
						<div className="card-body">
							<div className="col-12 col-md-12 col-lg-12 form-group">
								<Table
									header={['RA', 'Nome', '']}
									body={classe.students.map(student => {
										return [
											student.num_ra,
											student.nome_usuario,
											<OptionsButton student={student} />,
										];
									})}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
