import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import Panel from '../../components/Panel';
import ScrollToTop from '../../components/buttom/ScrollToTop';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import api from '../../services/api';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';
import { useFunctionalities } from '../../components/Contenxt/Functionalities';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const [createTicket, setCreateTicker] = useState(false);
	const {
		functionalities: { functionalities },
	} = useFunctionalities();

	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 46
			);
			if (existe) setCreateTicker(true);
			else setCreateTicker(false);
			setIsLoading(false);
		} else setIsLoading(true);
	}, [functionalities]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista de Chamados',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [isLoading, setIsLoading] = useState(true);
	const [tickets, setTickets] = useState([]);
	const [status, setStatus] = useState();
	const [month, setMonth] = useState();
	const [school, setSchool] = useState([]);
	const [idSchool, setIdSchool] = useState(null);

	async function loadEscola() {
		const { data } = await api.get('/escola/rede-ensino/lista-escolas');
		if (data.error) setSchool([]);
		else setSchool(data.data);
	}

	async function loadTickets() {
		setIsLoading(true);
		const dataFilter = {
			idSchool,
			status,
			month,
		};
		const { data } = await api.post('/ticket/', dataFilter);
		if (data.error === false) setTickets(data.data);
		else {
			setTickets([]);
			swal({
				icon: 'warning',
				text: data.message,
			});
		}
		setIsLoading(false);
	}
	useEffect(() => {
		loadEscola();
		loadTickets();
	}, []);

	function OptionsButton({ ticket }) {
		function editNavigation() {
			const idTicket = ticket.id_ticket;
			const state = {};

			state[RouteParams.LEVEL_05_ROUTES.VIEW_TICKET.TICKET_ID] = idTicket;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).VIEW_TICKET,
				state,
			});
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Editar monitor"
					className="buttonViewColor"
					onClick={() => editNavigation(ticket)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	function newTicket() {
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CREATE_TICKET
		);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header">Lista de Chamados</div>
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setIdSchool(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Mês</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={month}
								onChange={event => setMonth(event.target.value)}
							>
								<option value="">Escolha uma opção</option>
								<option value="1">Janeiro</option>
								<option value="2">Fevereiro</option>
								<option value="3">Março</option>
								<option value="4">Abril</option>
								<option value="5">Maio</option>
								<option value="6">Junho</option>
								<option value="7">Julho</option>
								<option value="8">Agosto</option>
								<option value="9">Setempro</option>
								<option value="10">Outubro</option>
								<option value="11">Novembro</option>
								<option value="12">Dezembro</option>
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Status</b>
							</label>
							<select
								id="school"
								value={status}
								className="form-control"
								onChange={event =>
									setStatus(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="1">Aberto</option>
								<option value="2">Fazendo</option>
								<option value="3">Fechado</option>
							</select>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<button
							onClick={loadTickets}
							className="btn btn-primary"
							type="button"
							id="button-addon2"
						>
							Filtrar
						</button>
						{createTicket && (
							<button
								onClick={newTicket}
								className="btn btn-primary"
								type="button"
								id="button-addon2"
							>
								Criar chamado
							</button>
						)}
					</div>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<Table
							header={[
								'#',
								'Titulo',
								'Status',
								'Usuário',
								'Escola',
								'Criado em',
								'',
							]}
							body={tickets.map(ticket => {
								return [
									ticket.id_ticket,
									ticket.titulo,
									ticket.status,
									ticket.nome_usuario,
									ticket.desc_nome_escola,
									ticket.created_at,
									<OptionsButton ticket={ticket} />,
								];
							})}
						/>
					)}
				</div>
			</div>
		</>
	);
}
