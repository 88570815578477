import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

export default function SelectKindTeacher({ setAlunos }) {
	const [subjectsTeacher, setSubjectsTeacher] = useState([]);
	const [schoolTeacher, setSchoolTeacher] = useState([]);
	const [schoolIDTeacher, setSchoolIDTeacher] = useState(null);
	const [subjectIDTeacher, setSubjectIDTeacher] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [classTeacher, setClassTeacher] = useState([]);
	const [disabledClass, setDisabledClass] = useState(true);

	useEffect(() => {
		async function loadSchool() {
			const response = await api.get('/professor/listarescolas');
			if (!response.data.error)
				setSchoolTeacher(response.data.data);
			else setSchoolTeacher([]);
		}
		loadSchool();
	}, []);
	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			if (schoolIDTeacher) {
				const response = await api.get('/professor/listardisciplinas', {
					params: {
						escolaID: schoolIDTeacher,
					},
				});
				if (response.data.error) {
					setSubjectsTeacher([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjectsTeacher(response.data.data);
				}
			}
		})();
	}, [schoolIDTeacher]);
	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			if (schoolIDTeacher && subjectIDTeacher) {
				const response = await api.get('/professor/listarturmas', {
					params: {
						escolaID: schoolIDTeacher,
						disciplinaID: subjectIDTeacher,
					},
				});
				if (response.data.error) {
					setClassTeacher([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClassTeacher(response.data.data);
				}
			}
		})();
	}, [subjectIDTeacher]);
	async function handleSerie(e) {
		setAlunos(e);
	}
	return (
		<>
			<div className="row">
				<div className="col-12 col-md-12 col-lg-5">
					<div className="form-group">
						<label htmlFor="schoolTeacher">Escola</label>
						<select
							className="form-control"
							name="schoolTeacher"
							id="schoolTeacher"
							onChange={event =>
								setSchoolIDTeacher(event.target.value)
							}
						>
							<option value="0">Escolha a Escola</option>
							{schoolTeacher.map(schools => (
								<option
									key={schools.id_escola}
									value={schools.id_escola}
								>
									{schools.desc_nome_escola}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-md-12 col-lg-4">
					<div className="form-group">
						<label htmlFor="subjectTeacher">Disciplina</label>
						<select
							className="form-control"
							name="subjectTeacher"
							id="subjectTeacher"
							onChange={event =>
								setSubjectIDTeacher(event.target.value)
							}
							disabled={disabledSubjects}
						>
							<option value="0">Escolha a disciplina</option>
							{subjectsTeacher.map(subjects => (
								<option
									key={subjects.id_disciplina}
									value={subjects.id_disciplina}
								>
									{subjects.nome_disciplina}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-md-12 col-lg-3">
					<div className="form-group">
						<label htmlFor="classTeacher">Série/Turma</label>
						<select
							id="classTeacher"
							className="form-control"
							onChange={event => handleSerie(event.target.value)}
							disabled={disabledClass}
						>
							<option value="0">Escolha a série</option>
							{classTeacher.map(classes => (
								<option
									key={classes.id_classe}
									value={classes.id_classe}
								>
									{classes.desc_serie_ano} - {classes.turma}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</>
	);
}
