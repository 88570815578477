import React, { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { RiSubtractFill } from 'react-icons/ri'

import './styles.css';

function Essay({ saveQuestion }) {
    const [tituloQuestao, SetTituloQuestao] = useState('');
    const [descricaoQuestao, SetDescricaoQuestao] = useState('');
    const [pesoQuestao, SetPesoQuestao] = useState('');

    const handleSubitmit = (e) => {
        e.preventDefault();

        const data = { title: tituloQuestao, descricao: descricaoQuestao, peso: pesoQuestao };
        saveQuestion(data);
        SetTituloQuestao('');
        SetDescricaoQuestao('');
        SetPesoQuestao('');

    }


    return (
        <>
            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={(event) => handleSubitmit(event)}>
                        <div className="DissertQuestao">

                            <label htmlFor="avalicao">
                                Título
                            <input
                                    className="form-control"
                                    type="text"
                                    name="titulo"
                                    value={tituloQuestao}
                                    required
                                    onChange={event =>
                                        SetTituloQuestao(event.target.value)
                                    }
                                />
                            </label>
                            <label htmlFor="avalicao">
                                Descrição
                            <input
                                    className="form-control"
                                    type="text"
                                    required
                                    value={descricaoQuestao}
                                    name="Descrição"
                                    onChange={event =>
                                        SetDescricaoQuestao(event.target.value)
                                    }
                                />
                            </label>

                            <label htmlFor="avalicao">
                                Peso
                            <input
                                    className="form-control"
                                    type="number"
                                    name="Peso"
                                    required
                                    value={pesoQuestao}
                                    onChange={event =>
                                        SetPesoQuestao(event.target.value)
                                    }
                                />
                            </label>

                            <button type="submit"className="btn btn-primary">Adicionar questão</button>

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Essay;