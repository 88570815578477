import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import formatDate from '../../../helpers/formatDate';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Breadcrumb from '../../../components/Breadcrumb';
import SelectComponent from '../../../components/Selects/SelectComponent';
import Table from '../../../components/Table';
import env from '../../../env';

export default function Protocols() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [listProtocols, setListProtocols] = useState([]);
	const [schools, setSchools] = useState([]);
	const [idSchool, setIdSchool] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.NURSERY_VACANCIES,
			namePage: 'Vagas em Creches',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Relatórios',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	function getValidation(value, valueObservation, alert) {
		if (value == '0')
			return (
				<div>
					<div>Pendente</div>
					<small>
						{valueObservation.length > 0
							? 'Contém observações'
							: null}
					</small>
				</div>
			);
		if (value == '1')
			return (
				<div>
					<div>Aprovado</div>
					<small>
						{valueObservation.length > 0
							? 'Contém observações'
							: null}
					</small>
				</div>
			);

		return (
			<div>
				<div>Recusado</div>
				<small>
					{valueObservation.length > 0 ? 'Contém observações' : null}
				</small>
			</div>
		);
	}

	function getStatus(value) {
		if (value == '0')
			return <span style={{ color: '#FF6700' }}>Aguardando vaga</span>;
		if (value == '1')
			return <span style={{ color: '#5EB65F' }}>Matriculado</span>;
		if (value == '2')
			return (
				<span style={{ color: '#101ddd' }}>
					Matriculado por decisão judicial
				</span>
			);
		if (value == '3')
			return (
				<span style={{ color: 'rgb(175, 0, 255)' }}>Desistência</span>
			);

		return (
			<span style={{ color: 'rgb(175, 0, 255)' }}>
				Idade não permitida
			</span>
		);
	}

	async function loadProtocols() {
		setIsLoading(true);
		const { data } = await api.get('/vagas-em-creches', {
			params: {
				idSchool,
				validation: 1,
				order: true,
			},
		});
		if (data.error) {
			setListProtocols([]);
			swal({ icon: 'warning', text: data.message, title: 'Ops!' });
		} else setListProtocols(data.data);
		setIsLoading(false);
	}

	async function getSchool() {
		const response = await api.get('/escolas/nursery');
		const schoolsArray = response.data.data.map(e => {
			return { ...e, name: e.name.replace('CRECHE MUNICIPAL', '') };
		});
		setSchools(schoolsArray);
	}

	useEffect(() => {
		loadProtocols();
		getSchool();
	}, [idSchool]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12 form-group">
							<SelectComponent
								setValue={setIdSchool}
								valueSelcted={idSchool}
								array={schools}
								label="Creches:"
								theSameLine
							/>
						</div>
					</div>
					<a
						type="button"
						className="btn btn-warning"
						href={`${env.API_WEB}/pdf/${idSchool}`}
						target="_blank"
					>
						Exportar PDF
					</a>
				</div>
				{isLoading && <div className="lds-dual-ring-small" />}
			</div>
			<div className="mt-3 ">
				<Table
					header={[
						'Protocolo',
						'Nome da Criança',
						'Nome do Responsável',
						'Data de Solicitação',
						'Creche Municipal',
						'Turma/Ano',
						'Situação Atual',
					]}
					body={listProtocols.map(protocol => {
						return [
							protocol.protocolo,
							protocol.nome_aluno,
							protocol.nome_responsavel,
							formatDate(protocol.created_at, true),
							protocol.desc_nome_escola.replace(
								'CRECHE MUNICIPAL',
								''
							),
							protocol.desc_serie_ano,
							getStatus(protocol.status_pedido),
						];
					})}
				/>
			</div>
		</>
	);
}
