import React from 'react';
import Panel from '../../../components/Panel';
import UpdateContact from '../../../components/Contact/UpdateContact';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import Footer from '../../../components/Footer';

export default function ChangeContact() {
	return (
		<>
			<Panel />
			<UpdateContact />
			<ScrollToTop />
			<Footer />
		</>
	);
}
