import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import {
	FaTrashAlt,
	FaArrowAltCircleLeft,
	FaTrash,
	FaComment,
} from 'react-icons/fa';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import TextAreaRead from '../../TextArea/TesteAreaRead';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import env from '../../../env';
import parseJwt from '../../../helpers/parseJwt';

export default function ReadSendCommunicated() {
	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone: 'UTC',
	};
	const history = useHistory();
	const { pathname } = useLocation();
	const [communicatedMessage, setCommunicatedMessage] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [messages, setMessages] = useState([]);
	const location = useLocation();
	const userActualType = useSelector(state => state.userType.actualType);
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const userID = tokenUsuario.sub;

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			if (location.state) {
				setCommunicatedMessage(
					location.state[
						RouteParams.COMMON_LEVELS.READ_COMMUNICATED_SEND.MESSAGE
					]
				);
				setIsLoading(false);
			} else {
				swal('ERRO!', 'Erro ao encontrar mensagem', 'error');
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.COMMUNICATED
				);
			}
		})();
	}, []);

	async function handleDelete(event) {
		event.preventDefault();
		try {
			const data = new FormData();
			data.append('idCommunicated', communicatedMessage.id_comunicado);
			await api
				.post('/comunicados/deleta/enviado', data)
				.then(response => {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					const home = pathname.split('/');
					history.push(`/${home[1]}/${home[2]}`);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	async function handleBack() {
		history.goBack();
	}

	async function updateCommunicatedMessages() {
		const response = await api.get('/comunicados/atualizar-mensagens', {
			params: { communicatedId: communicatedMessage.id_comunicado },
		});

		setMessages(response.data.data);

		if (response.error) swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function removeCommunicatedMessage(idMessageCommunicated) {
		const response = await api.post('/comunicados/deletar-mensagem', {
			idMessageCommunicated,
		});

		if (response.data.error)
			return swal('ERRO!', `${response.data.message}`, 'error');

		swal('SUCESSO!', `${response.data.message}`, 'success');

		updateCommunicatedMessages();
	}

	function navigateToAddCommunitedComment() {
		const state = {};

		state[
			RouteParams.COMMON_LEVELS.ADD_COMMUNICATED_MESSAGE.COMMUNICATED
		] = communicatedMessage;

		history.push({
			pathname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ADD_COMMUNICATED_MESSAGE,
			state,
		});
	}

	useEffect(() => {
		if (communicatedMessage.length !== 0) updateCommunicatedMessages();
	}, [communicatedMessage]);

	return (
		<>
			{isLoading === true && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<>
					<div className="card">
						<div className="card-body">
							<div className="titulo-comunicado">
								<div className="row">
									<div className="col-12 col-md-11 col-lg-11 form-group">
										<p>
											Assunto:{' '}
											{communicatedMessage.assunto}
										</p>
									</div>
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-8">
									<TextAreaRead
										lblComents={
											communicatedMessage.comunicado
										}
									/>
								</div>
								<div className="col-md-4">
									<p>Arquivos:</p>
									<ul>
										{communicatedMessage.arquivos.map(
											arquivo => (
												<li className="arquivos">
													<a
														href={`${
															env.SITE_URL
														}/file/comunicado-arquivo/download?file=${
															arquivo.nome_completo
														}&token=${localStorage.getItem(
															'@token'
														)}`}
														rel="noreferrer"
														target="_blank"
														className="arquivo-link"
													>
														Download
													</a>
													&nbsp;-&nbsp;
													<a
														href={`${
															env.SITE_URL
														}/file/comunicado-arquivo?file=${
															arquivo.nome_completo
														}&token=${localStorage.getItem(
															'@token'
														)}`}
														rel="noreferrer"
														target="_blank"
														className="arquivo-link"
													>
														{arquivo.nome}.
														{arquivo.extensao}
													</a>
												</li>
											)
										)}
									</ul>
								</div>
							</div>
							<div className="row d-flex justify-content-center mb-5">
								<div className="communicatedComments">
									{messages.length !== 0 ? (
										<>
											<h3>Comentários</h3>
											{messages.map(message => (
												<div
													key={message.id_comunicado}
													className="communicatedMessageItem"
												>
													<div className="communicatedMessageItemHeader">
														<span>
															{
																message.nome_usuario
															}
														</span>

														{message.autor_comunicado ===
															userID ||
														message.autor_mensagem ===
															userID ? (
															<FaTrash
																className="communicatedMessageTrashIcon"
																onClick={() =>
																	removeCommunicatedMessage(
																		message.id_mensagem_comunicado
																	)
																}
															/>
														) : null}
													</div>
													<div className="communicatedMessageItemBody">
														<p>
															{message.mensagem}
														</p>
													</div>
												</div>
											))}
										</>
									) : null}
								</div>
							</div>
							<div className="row d-flex align-items-center">
								<div className="col-6 col-md-6 col-lg-6">
									<div className="data-comunicado">
										<p>
											Publicado em:{' '}
											{new Date(
												communicatedMessage.updated_at
											).toLocaleDateString(
												'pt-BR',
												options
											)}
										</p>
									</div>
								</div>
								<div className="col-2 col-md-2 col-lg-2 d-flex justify-content-end">
									<button
										className="buttonEdit"
										type="button"
										onClick={handleBack}
									>
										<FaArrowAltCircleLeft />
										<p>Voltar</p>
									</button>
								</div>
								<div className="col-2 col-md-2 col-lg-2 d-flex justify-content-end">
									<button
										onClick={handleDelete}
										className="buttonEdit"
										type="button"
									>
										<FaTrashAlt />
										<p>Apagar</p>
									</button>
								</div>
								<div className="col-2 col-md-2 col-lg-2 d-flex justify-content-end">
									<button
										onClick={navigateToAddCommunitedComment}
										className="addCommunicatedCommnetButton"
										type="button"
									>
										<FaComment />
										<p>Comentar</p>
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
