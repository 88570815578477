import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import VideoCard from '../../components/VideoCard';
// import parseJwt from '../../../helpers/parseJwt';
import parseJwt from '../../helpers/parseJwt';
import './styles.css';

export default function ListTeacher() {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Biblioteca',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [video, setVideo] = useState('');
	const [videos, setVideos] = useState([]);
	const [atualiza, setAtualiza] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	async function handleUploadVideo() {
		if ((title == '' && description == '', video == '')) {
			swal(
				'Algum campo vazio!',
				'É necessário informar o nome, descrição e o video',
				'warning'
			);
		} else {
			setIsLoading(true);
			const data = new FormData();
			data.append('nome', title);
			data.append('descricao', description);
			data.append('video', video);
			data.append('id_professor', idUser);

			try {
				const response = await api.post(
					'/biblioteca/inserir-vimeo',
					data
				);
				if (response.status == 200) {
					setAtualiza(true);
					setIsLoading(false);
					swal(
						'Sucesso',
						'Videos cadastrado com sucesso!',
						'success'
					);
				}
			} catch (error) {
				swal('ERRO!', 'Não foi possível salvar o video', 'warning');
				setIsLoading(false);
				throw error;
			}
		}
	}

	async function handleGetVideos() {
		const response = await api.get(`/biblioteca/buscar-videos/${idUser}`);
		setVideos(response.data);
		if (atualiza) {
			setAtualiza(false);
		}
	}

	useEffect(() => {
		handleGetVideos();
	}, [atualiza]);

	function handleSelectVideo(event) {
		let selectedVideo = event.target.files[0];

		if (selectedVideo && selectedVideo.size <= 50000000) {
			setVideo(event.target.files[0]);
		} else {
			swal(
				'Video grande, selecione outro!',
				'Video deve ter o tamanho menor que 50 MB!',
				'warning'
			);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header">
					Adicione um novo video a sua biblioteca
				</div>
				{isLoading === true ? (
					<div className="lds-dual-ring" />
				) : (
					<div className="card-body">
						<div className="row">
							<div className="inputForm  input-group-sm">
								<div className="inputGroup">
									<label htmlFor="Video">
										<h5>Título</h5>
										<input
											className="form-control"
											type="text"
											name="arquivo"
											onChange={event =>
												setTitle(event.target.value)
											}
										/>
									</label>

									<label htmlFor="Video">
										<h5>Descrição</h5>
										<textarea
											className="form-control"
											type="text"
											name="arquivo"
											onChange={event =>
												setDescription(
													event.target.value
												)
											}
										/>
									</label>

									<label htmlFor="Video">
										<h5>Selecione um video</h5>
										<input
											type="file"
											name="arquivo"
											accept="video/mp4,video/x-m4v,video/*"
											onChange={handleSelectVideo}
										/>
									</label>
								</div>

								<button
									type="button"
									className="btn btn-primary"
									onClick={handleUploadVideo}
								>
									Enviar
								</button>
							</div>
						</div>
						<span className="warninUploadVideo">
							Os vídeos podem demorar algumas horas para ficarem
							disponíveis para visualização.
						</span>
					</div>
				)}
			</div>
			<div className="card mt-3" id="content">
				<div className="card-body videoGroup">
					{videos.length == 0 ? (
						<h1>Nenhum video foi adicionado</h1>
					) : (
						videos.map(value => {
							return (
								<VideoCard
									key={value.id_video}
									content={value}
									atualiza={() => setAtualiza(true)}
									library
								/>
							);
						})
					)}
				</div>
			</div>
		</>
	);
}
