import React from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import homeCozinhaPiloto from '../../assets/img/home_cozinha_piloto.png';

export default function Kitchen() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="menuImage">
				<img src={homeCozinhaPiloto} alt="home cozinha piloto" />
			</div>
		</>
	);
}
