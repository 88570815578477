import React from 'react';
import Panel from '../Panel';
import ScrollToTop from '../buttom/ScrollToTop';

export default function index({ children }) {
	return (
		<>
			<Panel />
			<main className="mt-2  backgorund-home-image">
				<div className="container-fluid mb-5">
					<div className="row painel justify-content-center">
						<div className="col-xl-11 col-lg-11 col-md-12">
							{children}
						</div>
					</div>
				</div>
			</main>
			<ScrollToTop />
		</>
	);
}
