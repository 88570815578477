import React from 'react';
import PrivateRouter from './PrivateRouter';
import Dash from '../pages/Home/HomeLevel02';
import Faults from '../pages/Faults';
import Grade from '../pages/Grade';
import Communicated from '../pages/Communicated';
import ReadCommunicatedInbox from '../pages/Communicated/ReadCommunicatedInbox';
import AddCommunicatedMessage from '../pages/Communicated/AddCommunicatedMessage';
import ReadNotices from '../pages/Notices';
import SubjectsContents from '../pages/Subjects';
import SubjectsClass from '../pages/Subjects/SubjectsClass';
import InfoProfile from '../pages/Profile/InfoProfile';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Profile/ChangeContact';
import InfoAttribution from '../pages/Attribution/FilesAttribution/EditFilesAttribution'

import Routes from './data/Routes';

export default function RoutesResponsible({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Dash}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.LACK}
				exact
				component={Faults}
			/>
			<PrivateRouter
				userTypes={[8]}
				exact
				path={LOGGED_ROUTES.GRADE}
				component={Grade}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.COMMUNICATED}
				exact
				component={Communicated}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_RECEIVE}
				exact
				component={ReadCommunicatedInbox}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.ADD_COMMUNICATED_MESSAGE}
				exact
				component={AddCommunicatedMessage}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.READ_NOTICES}
				exact
				component={ReadNotices}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT}
				exact
				component={SubjectsContents}
			/>
			<PrivateRouter
				userTypes={[8]}
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT_CLASSES}
				exact
				component={SubjectsClass}
			/>
			<PrivateRouter
				userTypes={[8]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[8]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[8]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[8]}
				exact
				path={LOGGED_ROUTES.ATTRIBUTION}
				component={InfoAttribution}
			/>
		</>
	);
}
