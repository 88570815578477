import React from 'react';
import Routes from './data/Routes';
import PrivateRouter from './PrivateRouter';
import Level_07 from '../pages/Home/HomeLevel07';
import MenuAdd from '../pages/Menu/Add';
import MenuEdit from '../pages/Menu/Edit';
import Menu from '../pages/Menu';
import Products from '../pages/Products';
import AddProducts from '../pages/Products/Add';
import EditProducts from '../pages/Products/Edit';
import EditProductsAdd from '../pages/Products/Edit/Add';
import EditProductsRemove from '../pages/Products/Edit/Remove';
import Providers from '../pages/Providers';
import AddProviders from '../pages/Providers/Add';
import EditProviders from '../pages/Providers/Edit';
import RecordsInputs from '../pages/Records/Inputs';
import RecordsOutputs from '../pages/Records/Outputs';
import ShoppingList from '../pages/ShoppingList';
import ShoppingListAdd from '../pages/ShoppingList/Add';
import Tickets from '../pages/TicketsCook';
import Commitments from '../pages/Commitments';
import Numeration from '../pages/Numeration';
import InfoProfile from '../pages/Profile/InfoProfile';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Profile/ChangeContact';

export default function RoutesNutritionist({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Level_07}
			/>
			<PrivateRouter
				userTypes={[11]}
				path={LOGGED_ROUTES.MENU}
				exact
				component={Menu}
			/>
			<PrivateRouter
				userTypes={[11]}
				path={LOGGED_ROUTES.MENU_ADD}
				exact
				component={MenuAdd}
			/>
			<PrivateRouter
				userTypes={[11]}
				path={LOGGED_ROUTES.MENU_EDIT}
				exact
				component={MenuEdit}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.PRODUCTS}
				exact
				component={Products}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.ADD_PRODUCTS}
				exact
				component={AddProducts}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.EDIT_PRODUCTS}
				exact
				component={EditProducts}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.EDIT_PRODUCTS_ADD}
				exact
				component={EditProductsAdd}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.EDIT_PRODUCTS_REMOVE}
				exact
				component={EditProductsRemove}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.PROVIDERS}
				exact
				component={Providers}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.ADD_PROVIDERS}
				exact
				component={AddProviders}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.EDIT_PROVIDERS}
				exact
				component={EditProviders}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.RECORDS_INPUTS}
				exact
				component={RecordsInputs}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.RECORDS_OUTPUTS}
				exact
				component={RecordsOutputs}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.SHOPPING_LIST}
				exact
				component={ShoppingList}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.SHOPPING_LIST_ADD}
				exact
				component={ShoppingListAdd}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.TICKETS}
				exact
				component={Tickets}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.COMMITMENTS}
				exact
				component={Commitments}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				path={LOGGED_ROUTES.NUMERATION}
				exact
				component={Numeration}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[10, 11]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
		</>
	);
}
