import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import cep from 'cep-promise';
import InputMask from 'react-input-mask';
import { FaSearch, FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import SelectUf from '../../../components/Selects/SelectUf';
import SelectSexo from '../../../components/Selects/SelectSexo';
import SelectCorRaca from '../../../components/Selects/SelectCorRaca';
import SelectTipoTelefone from '../../../components/Selects/SelectTipoTelefone';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function UpdateMonitor() {
	const userActualType = useSelector(state => state.userType.actualType);

	const history = useHistory();
	const location = useLocation();
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_MONITOR,
			namePage: 'Monitores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atualiza Monitor',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [IdUsuario, setIdUsuario] = useState('');
	const [Email, setEmail] = useState('');
	const [IdCorRaca, setIdCorRaca] = useState('');
	const [IdSexo, setSexo] = useState('');
	const [IdNacionalidade, setIdNacionalidade] = useState('1');
	const [CodPaisOrigem, setCodPaisOrigem] = useState(76);
	const [DataEntradaPais, setDataEntradaPais] = useState('');
	const [IdCidadeNascido, setIdCidadeNascido] = useState('');
	const [IdUFMunNascto, setIdUFMunNascto] = useState('');
	const [CpfFuncionario, setCpfFuncionario] = useState('');
	const [NumDocumentoCivil, setNumDocumentoCivil] = useState('');
	const [DigitoDocumento, setDigitoDocumento] = useState('');
	const [DataEmissao, setDataEmissao] = useState('');
	const [NumVistoConfere, setNumVistoConfere] = useState('');
	const [IdUfDocumento, setIdUfDocumento] = useState('');
	const [Cep, setCep] = useState('');
	const [NumCasa, setNumCasa] = useState('');
	const [ComplementoEndereco, setComplementoEndereco] = useState('');
	const [Logradouro, setLogradouro] = useState('');
	const [Bairro, setBairro] = useState('');
	const [NomeCidade, setNomeCidade] = useState('');
	const [SiglaUfCidade, setSiglaUfCidade] = useState('');
	const [IdAreaLogradouro, setIdAreaLogradouro] = useState(1);
	const [IdLocalizacao, setIdLocalizacao] = useState(4);
	const [Contato, setContato] = useState([]);
	const [Disable, setDisable] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [NomeFuncionario, setNomeFuncionario] = useState('');
	const [DataNascimentoFuncionario, setDataNascimentoFuncionario] = useState(
		''
	);

	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			if (location.state) {
				const infoUser =
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR
					];
				setIdUsuario(infoUser.users.id_usuario);
				setEmail(infoUser.users.email);
				setIdCorRaca(infoUser.users.id_cor_raca);
				setSexo(infoUser.users.id_sexo);
				setIdNacionalidade(infoUser.users.id_nacionalidade);
				setCodPaisOrigem(infoUser.users.cod_pais);
				setDataEntradaPais(infoUser.users.data_entrada_pais);
				setIdCidadeNascido(infoUser.users.cidade_natal);
				setIdUFMunNascto(infoUser.users.estado_natal);
				setCpfFuncionario(infoUser.users.cpf);
				setNumDocumentoCivil(infoUser.users.rg);
				setDigitoDocumento(infoUser.users.digito);
				setDataEmissao(infoUser.users.data_emissao_documento_civil);
				setNumVistoConfere(infoUser.users.num_visto_confere);
				setIdUfDocumento(infoUser.users.documento_uf);
				setCep(infoUser.users.cep);
				setNumCasa(infoUser.users.numero);
				setComplementoEndereco(infoUser.users.complemento_endereco);
				setLogradouro(infoUser.users.logradouro);
				setBairro(infoUser.users.bairro);
				setNomeCidade(infoUser.users.cidade);
				setSiglaUfCidade(infoUser.users.estado);
				setIdAreaLogradouro(
					infoUser.users.area_logradouro_id_area_logradouro
				);
				setIdLocalizacao(infoUser.users.localizacao_id_localizacao);
				setNomeFuncionario(infoUser.users.nome_usuario);
				setDataNascimentoFuncionario(infoUser.users.nascimento);
				setContato(infoUser.contacts);
			}
			setIsLoading(false);
		})();
	}, []);

	function handleCEP(event) {
		event.preventDefault();
		cep(Cep)
			.then(dataPostCode => {
				setBairro(dataPostCode.neighborhood);
				setNomeCidade(dataPostCode.city);
				setLogradouro(dataPostCode.street);
				setSiglaUfCidade(dataPostCode.state);
			})
			.catch(error => {
				console.log(`error: ${error}`);
				swal('Cep não encontrado');
				setBairro('');
				setNomeCidade('');
				setLogradouro('');
				setSiglaUfCidade('');
			});
	}
	async function handleAtualizar() {
		setDisable(Disable !== true);
	}
	async function handleDeactivated(e) {
		const data = { idMonitor: e };

		const {
			data: { message },
		} = await api.post('/transporte/monitor/desativa-monitor', data);
		swal('SUCESSO!', `${message}`, 'success').then(value => {
			if (value) history.goBack();
		});
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = {
				IdUsuario,
				NomeFuncionario,
				DataNascimentoFuncionario,
				IdCorRaca,
				IdSexo,
				Email,
				NumVistoConfere,
				IdNacionalidade,
				DataEntradaPais,
				CodPaisOrigem,
				IdCidadeNascido,
				IdUFMunNascto,
				CpfFuncionario,
				NumDocumentoCivil,
				DigitoDocumento,
				IdUfDocumento,
				DataEmissao,
				Logradouro,
				NumCasa,
				Bairro,
				NomeCidade,
				SiglaUfCidade,
				ComplementoEndereco,
				Cep,
				IdAreaLogradouro,
				IdLocalizacao,
				TipoUsuario: 14,
			};
			await api
				.post('/transporte/monitor/atualizar-monitor', data)
				.then(response => {
					if (!response.data.error) {
						swal(response.data.message);
						history.goBack();
					} else swal(response.data.message);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	const [lblIdTipoTelefone, setlblIdTipoTelefone] = useState('');
	const [lblDDDTel, setlblDDDTel] = useState('');
	const [lblNumTelefone, setlblNumTelefone] = useState('');
	const [lblComplementoTel, setlblComplementoTel] = useState('');
	const [lblSms, setlblSms] = useState('');

	async function handleDeletNumber(e) {
		const contact = new FormData();
		contact.append('idContatoHasUsuario', e);
		const { data } = await api.post('/contato/desativar', contact);
		swal('SUCESSO!', `${data.message}`, 'success');
		const updateClasse = Contato.filter(
			c => c.id_contato_has_usuario !== e
		);
		setContato(updateClasse);
		Contato.filter(c => c.id_contato_has_usuario !== e);
	}

	async function handleNewNumber(event) {
		event.preventDefault();
		try {
			const data = new FormData();
			data.append('idUser', IdUsuario);
			data.append('lblIdTipoTelefone', lblIdTipoTelefone);
			data.append('lblDDDTel', lblDDDTel);
			data.append('lblNumTelefone', lblNumTelefone);
			data.append('lblComplementoTel', lblComplementoTel);
			data.append('lblSms', lblSms);
			await api.post('/contato/cadastrar', data).then(response => {
				if (!response.data.error) {
					swal('Novo número cadastrado');
					setContato(response.data.data);
				} else swal('ERRO!', `${response.data.message}`, 'error');
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={handleSubmit}>
				{isLoading ? (
					<div className="lds-dual-ring" />
				) : (
					<>
						<div className="card mt-3">
							<div className="card-header">
								<div className="row">
									<div className="col-12 col-md-11 col-lg-11 form-group">
										<h4>Dados do Monitor</h4>
									</div>
									<div className="col-12 col-md-1 col-lg-1 form-group">
										<button
											className="buttonEdit"
											type="button"
										>
											<FaEdit onClick={handleAtualizar} />
										</button>
									</div>
								</div>
								{Disable === false && (
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<button
												className="btn btn-danger btn-block"
												type="button"
												onClick={() =>
													handleDeactivated(IdUsuario)
												}
											>
												Apagar
											</button>
										</div>
									</div>
								)}
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome do Funcionário:</b>
										</p>
										<div className="input-group mb-4">
											<input
												onInput={toInputUppercase}
												disabled={Disable}
												required
												type="text"
												name="NomeFuncionario"
												id="NomeFuncionario"
												className="form-control"
												placeholder="Nome do Funcionário"
												value={NomeFuncionario}
												onChange={event =>
													setNomeFuncionario(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Data de aniversário: </b>
										</p>
										<div className="input-group mb-4">
											<InputMask
												mask="99/99/9999"
												disabled={Disable}
												required
												size={8}
												type="text"
												name="DataNascimentoFuncionario"
												id="DataNascimentoFuncionario"
												className="form-control"
												placeholder="Data Nascimento"
												value={
													DataNascimentoFuncionario
												}
												onChange={event =>
													setDataNascimentoFuncionario(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>E-mail</b>
										</p>
										<input
											disabled={Disable}
											type="text"
											name="Email"
											id="Email"
											className="form-control"
											placeholder="Digite o E-mail"
											value={Email}
											onChange={event =>
												setEmail(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Cor/Raça</b>
										</p>
										<SelectCorRaca
											disabled={Disable}
											valueSelect={IdCorRaca}
											setCorRaca={setIdCorRaca}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Gênero</b>
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<SelectSexo
											valueSelect={IdSexo}
											disabled={Disable}
											setSexo={setSexo}
										/>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Nacionalidade</b>
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<select
											required
											disabled={Disable}
											className="form-control"
											name="IdNacionalidade"
											id="IdNacionalidade"
											onChange={event =>
												setIdNacionalidade(
													event.target.value
												)
											}
										>
											<option value="1">
												Brasileiro
											</option>
											<option value="2">
												Estrangeiro
											</option>
											<option value="3">
												Brasileiro Nascido no Exterior
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Documentos</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Número de Documento Civil</b>
											{NumDocumentoCivil ? (
												<div className="row">
													<div className="col-12 col-md-9 col-lg-9 form-group">
														<InputMask
															disabled={Disable}
															mask="99.999.999"
															type="text"
															name="NumDocumentoCivil"
															id="NumDocumentoCivil"
															className="form-control"
															placeholder="RG ou RN"
															value={
																NumDocumentoCivil
															}
															onChange={event =>
																setNumDocumentoCivil(
																	event.target
																		.value
																)
															}
														/>
													</div>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<input
															disabled={Disable}
															maxLength="2"
															minLength="1"
															type="text"
															name="DigitoDocumento"
															id="DigitoDocumento"
															className="form-control"
															placeholder="XX"
															value={
																DigitoDocumento
															}
															onChange={event =>
																setDigitoDocumento(
																	event.target
																		.value
																)
															}
														/>
													</div>
												</div>
											) : (
												<p>
													Usuário não tem essa
													informação
												</p>
											)}
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>CPF do Funcionário</b>
											<div className="input-group mb-4">
												{CpfFuncionario ? (
													<InputMask
														mask="999.999.999-99"
														disabled={Disable}
														size={16}
														type="text"
														name="CpfFuncionario"
														id="CpfFuncionario"
														className="form-control"
														placeholder="CPF do Responsável"
														value={CpfFuncionario}
														onChange={event =>
															setCpfFuncionario(
																event.target
																	.value
															)
														}
													/>
												) : (
													<p>
														Usuário não tem essa
														informação
													</p>
												)}
											</div>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Estado do Documento</b>
											{IdUfDocumento ? (
												<SelectUf
													disabled={Disable}
													valueSelect={IdUfDocumento}
													setUf={setIdUfDocumento}
												/>
											) : (
												<p>
													Usuário não tem essa
													informação
												</p>
											)}
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Data da Emissão do Documento</b>
											{DataEmissao ? (
												<InputMask
													disabled={Disable}
													mask="99/99/9999"
													name="DataEmissao"
													id="DataEmissao"
													className="form-control"
													placeholder="Data da mEissão Documento"
													value={DataEmissao}
													onChange={event =>
														setDataEmissao(
															event.target.value
														)
													}
												/>
											) : (
												<p>
													Usuário não tem essa
													informação
												</p>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Contato</h4>
							</div>
							<div className="card-body">
								{Contato.length > 0 ? (
									Contato.map(contact => (
										<>
											<div className="row">
												<div className="col-12 col-md-4 col-lg-4 form-group">
													<p>
														<b>Telefone: </b>(
														{contact.ddd_numero}){' '}
														{contact.tel_numero}
													</p>
												</div>
												{contact.complemento ? (
													<div className="col-12 col-md-4 col-lg-4 form-group">
														<p>
															<b>Complemento: </b>
															{
																contact.complemento
															}
														</p>
													</div>
												) : null}
												<div className="col-12 col-md-4 col-lg-4 form-group">
													<p>
														<b>Deixar Recados: </b>
														{contact.sms}
													</p>
												</div>
											</div>
											{Disable === false ? (
												<div className="row">
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<button
															className="btn btn-danger btn-block"
															type="button"
															onClick={() =>
																handleDeletNumber(
																	contact.id_contato_has_usuario
																)
															}
														>
															Apagar
														</button>
													</div>
												</div>
											) : null}
											<hr />
										</>
									))
								) : (
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Não há registro no Sistema</p>
									</div>
								)}
								{Disable === false ? (
									<form onSubmit={handleNewNumber}>
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>Tipos de Telefone</p>
												<SelectTipoTelefone
													disabled={Disable}
													valueSelect={
														lblIdTipoTelefone
													}
													setTipoTelefone={
														setlblIdTipoTelefone
													}
												/>
											</div>
											<div className="col-12 col-md-1 col-lg-1 form-group">
												<p>DDD</p>
												<input
													disabled={Disable}
													required
													type="text"
													minLength="2"
													maxLength="2"
													name="lblDDDTel"
													id="lblDDDTel"
													className="form-control"
													placeholder="00"
													value={lblDDDTel}
													onChange={event =>
														setlblDDDTel(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>Telefone</p>
												<input
													disabled={Disable}
													required
													minLength="8"
													maxLength="9"
													type="text"
													name="lblNumTelefone"
													id="lblNumTelefone"
													className="form-control"
													placeholder="11111111"
													value={lblNumTelefone}
													onChange={event =>
														setlblNumTelefone(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>Deixar Recados?</p>
												<select
													disabled={Disable}
													className="form-control"
													name="lblSms"
													id="lblSms"
													value={lblSms}
													onChange={event =>
														setlblSms(
															event.target.value
														)
													}
												>
													<option>Recados</option>
													<option value="NÃO">
														Não
													</option>
													<option value="SIM">
														Sim
													</option>
												</select>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>Complemento</p>
												<input
													onInput={toInputUppercase}
													disabled={Disable}
													type="text"
													name="lblComplementoTel"
													id="lblComplementoTel"
													className="form-control"
													placeholder="Digite o outro número"
													value={lblComplementoTel}
													onChange={event =>
														setlblComplementoTel(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<button
													className="btn btn-primary btn-block"
													type="button"
													onClick={event =>
														handleNewNumber(event)
													}
												>
													Adicionar Novo
												</button>
											</div>
										</div>
									</form>
								) : null}
							</div>
						</div>
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Endereço</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-7 col-lg-5 form-group">
										<p>
											<b>CEP</b>
										</p>
										<div className="input-group mb-3">
											<InputMask
												disabled={Disable}
												mask="99999-999"
												required
												type="text"
												name="Cep"
												id="Cep"
												className="form-control"
												placeholder="XXXXX-XXX"
												value={Cep}
												onChange={event =>
													setCep(event.target.value)
												}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-block btn-success"
													type="button"
													onClick={handleCEP}
												>
													<FaSearch />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 form-group">
										<p>
											<b>Logradouro</b>
										</p>
										<input
											onInput={toInputUppercase}
											required
											disabled
											type="text"
											name="Logradouro"
											id="Logradouro"
											className="form-control"
											placeholder="Digite o Logradouro"
											value={Logradouro}
											onChange={event =>
												setLogradouro(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Número</b>
										</p>
										<input
											disabled={Disable}
											required
											type="text"
											name="NumCasa"
											id="NumCasa"
											className="form-control"
											placeholder="Ex: SN, 123"
											value={NumCasa}
											onChange={event =>
												setNumCasa(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Complemento</b>
										</p>
										<input
											disabled={Disable}
											required
											onInput={toInputUppercase}
											type="text"
											name="ComplementoEndereco"
											id="ComplementoEndereco"
											className="form-control"
											placeholder="Ex: Casa, Apto..."
											value={ComplementoEndereco}
											onChange={event =>
												setComplementoEndereco(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Bairro</b>
										</p>
										<input
											required
											disabled
											onInput={toInputUppercase}
											type="text"
											name="Bairro"
											id="Bairro"
											className="form-control"
											placeholder="Digite o Bairro"
											value={Bairro}
											onChange={event =>
												setBairro(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-8 col-lg-8 form-group">
										<p>
											<b>Cidade</b>
										</p>
										<input
											required
											disabled
											onInput={toInputUppercase}
											type="text"
											name="NomeCidade"
											id="NomeCidade"
											className="form-control"
											placeholder="Digite a Cidade"
											value={NomeCidade}
											onChange={event =>
												setNomeCidade(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>UF</b>
										</p>
										<input
											required
											disabled
											maxLength="2"
											type="text"
											name="SiglaUfCidade"
											id="SiglaUfCidade"
											className="form-control"
											placeholder="Digite o UF"
											value={SiglaUfCidade}
											onChange={event =>
												setSiglaUfCidade(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Área do logradouro</b>
										</p>
										<select
											disabled={Disable}
											className="form-control"
											name="IdAreaLogradouro"
											id="IdAreaLogradouro"
											value={IdAreaLogradouro}
											onChange={event =>
												setIdAreaLogradouro(
													event.target.value
												)
											}
										>
											<option value="1">Urbana</option>
											<option value="2">Rural</option>
										</select>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Localização Diferenciada</b>
										</p>
										<select
											disabled={Disable}
											required
											value={IdLocalizacao}
											className="form-control"
											name="IdLocalizacao"
											id="IdLocalizacao"
											onChange={event =>
												setIdLocalizacao(
													event.target.value
												)
											}
										>
											<option value="1">
												Área de assentamento
											</option>
											<option value="2">
												Terra indígena
											</option>
											<option value="3">
												Área onde se localizada em
												Comunidade remanescente de
												Quilombos
											</option>
											<option value="4">
												Não está localizado em área de
												localização diferenciada
											</option>
										</select>
									</div>
								</div>
							</div>
							{Disable === false && (
								<div className="card-footer">
									<div className="row justify-content-start">
										<div className="col-lg-4 col-md-5 col-7 form-group">
											<button
												className="btn btn-primary btn-block"
												type="submit"
											>
												Atualizar
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</>
				)}
			</form>
		</>
	);
}
