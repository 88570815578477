import React, { useState, useEffect } from 'react';
import { FaRegArrowAltCircleUp } from 'react-icons/fa';
import './ButtonScroll.css';

export default function ScrollToTop() {
	const [isVisible, setVisible] = useState(false);

	async function toggleVisibility() {
		if (window.pageYOffset >= 150) setVisible(true);
		else setVisible(false);
	}
	useEffect(() => {
		// eslint-disable-next-line func-names
		document.addEventListener('scroll', function() {
			// eslint-disable-next-line no-use-before-define
			toggleVisibility();
		});
	});
	async function handleScrollToStats() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}
	return (
		<>
			{isVisible === true && (
				<button
					type="button"
					className="goTopBtn"
					onClick={handleScrollToStats}
				>
					<FaRegArrowAltCircleUp className="iconTopBtn" />
				</button>
			)}
		</>
	);
}
