/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import swal from 'sweetalert';
import { FaEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import SelectUf from '../Selects/SelectUf';
import SelectSexo from '../Selects/SelectSexo';
import SelectCorRaca from '../Selects/SelectCorRaca';
import SelectCidade from '../Selects/SelectCidade';
import Routes from '../../routes/data/Routes';
import SelectPais from '../Selects/SelectPais';
import RouteByPermission from '../../routes/data/RouteByPermission';
import parseJwt from '../../helpers/parseJwt';
// eslint-disable-next-line import/no-unresolved
import './style.css';

export default function BasicData({
	idUsuario = null,
	tittle = '',
	editable = true,
}) {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = idUsuario || tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);

	const [lblNomeUsuario, setlblNomeUsuario] = useState('');
	const [lblDataNascimento, setlblDataNascimento] = useState('');
	const [lblIdCorRaca, setlblIdCorRaca] = useState('');
	const [lblIdSexo, setlblSexo] = useState('');
	const [lblIdNacionalidade, setlblIdNacionalidade] = useState(null);
	const [lblCodPaisOrigem, setlblCodPaisOrigem] = useState(null);
	const [lblDataEntradaPais, setlblDataEntradaPais] = useState('');
	const [lblNumVistoConfere, setlblNumVistoConfere] = useState('');
	const [lblIdCidadeNascido, setlblIdCidadeNascido] = useState('');
	const [lblDescCorRaca, setlnlDescCorRaca] = useState('');
	const [lblDescSexo, setlblDescSexo] = useState('');
	const [lblCidadeNatal, setlblCidadeNatal] = useState('');
	const [lblEstadoNatal, setlblEstadoNatal] = useState('');
	const [lblDescNacionalidade, setlblDescNacionalidade] = useState('');
	const [lblIdUFMunNascto, setlblIdUFMunNascto] = useState('');
	const [lblNomePaisOrigem, setlblNomePaisOrigem] = useState('');
	const [
		lblHabilitarAtualizacaoDadosBasicos,
		setlblHabilitarAtualizacaoDadosBasicos,
	] = useState(true);
	const history = useHistory();
	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	async function loadUserBasic() {
		const {
			data: { error, message, data },
		} = await api.get('/usuario/informacao', {
			params: {
				idUser: idUsuario,
			},
		});
		if (error === false) {
			setlblNomeUsuario(data.usuario.nome_usuario);
			setlblDataNascimento(data.usuario.data_nascimento);
			setlblIdCorRaca(data.usuario.id_cor_raca);
			setlnlDescCorRaca(data.usuario.desc_cor_raca);
			setlblSexo(data.usuario.id_sexo);
			setlblDescSexo(data.usuario.sexo);
			setlblCodPaisOrigem(data.usuario.cod_pais);
			setlblNomePaisOrigem(data.usuario.nome_pais);
			setlblIdNacionalidade(data.usuario.id_nacionalidade);
			setlblDescNacionalidade(data.usuario.desc_nacionalidade);
			setlblDataEntradaPais(data.usuario.data_entrada_pais);
			setlblIdCidadeNascido(data.usuario.cidade_natal);
			setlblIdUFMunNascto(data.usuario.estado_natal);
			setlblCidadeNatal(data.usuario.nome_cidade_natal);
			setlblEstadoNatal(data.usuario.sigla_uf_natal);
		} else {
			swal('ERRO!', `${message}`, 'error');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}

	useEffect(() => {
		loadUserBasic();
	}, []);

	async function handleAtualizarDadosBasicos() {
		setlblHabilitarAtualizacaoDadosBasicos(
			lblHabilitarAtualizacaoDadosBasicos !== true
		);
	}
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			const data = {
				idUsuario: idUser,
				lblNomeUsuario,
				lblDataNascimento,
				lblIdCorRaca,
				lblIdSexo,
				lblIdNacionalidade,
				lblNumVistoConfere,
				lblDataEntradaPais,
				lblCodPaisOrigem,
				lblIdCidadeNascido,
				lblIdUFMunNascto,
			};

			await api
				.post('/usuario/atualiza/dados-basico', data)
				.then(response => {
					if (response.data.error)
						swal('Ops!', `${response.data.message}`, 'warning');
					else {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						setlblHabilitarAtualizacaoDadosBasicos(
							lblHabilitarAtualizacaoDadosBasicos !== true
						);
						loadUserBasic();
					}
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="card mt-3">
				<div className="card-header ">
					<h4>{tittle}</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>
								<b>Nome Usuário</b>
							</p>
							{editable ? (
								<input
									onInput={toInputUppercase}
									disabled={
										lblHabilitarAtualizacaoDadosBasicos
									}
									required
									name="lblNomeUsuario"
									id="lblNomeUsuario"
									className="form-control"
									placeholder="Digite o nome do usuário"
									value={lblNomeUsuario}
									onChange={event =>
										setlblNomeUsuario(event.target.value)
									}
								/>
							) : (
								<p>{lblNomeUsuario}</p>
							)}
						</div>
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>
								<b>Data Nascimento</b>
							</p>
							{editable ? (
								<InputMask
									mask="99/99/9999"
									disabled={
										lblHabilitarAtualizacaoDadosBasicos
									}
									name="lblDataNascimento"
									id="lblDataNascimento"
									className="form-control"
									placeholder="Data Nascimento"
									required
									value={lblDataNascimento}
									onChange={event =>
										setlblDataNascimento(event.target.value)
									}
								/>
							) : (
								<p>{lblDataNascimento || 'Não Informado'}</p>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>
								<b>Cor/Raça</b>
							</p>
							{editable ? (
								<SelectCorRaca
									setCorRaca={setlblIdCorRaca}
									valueSelect={lblIdCorRaca}
									disabled={
										lblHabilitarAtualizacaoDadosBasicos
									}
								/>
							) : (
								<p>{lblDescCorRaca || 'Não Informado'}</p>
							)}
						</div>
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>
								<b>Gênero</b>
							</p>
							{editable ? (
								<SelectSexo
									setSexo={setlblSexo}
									valueSelect={lblIdSexo}
									disabled={
										lblHabilitarAtualizacaoDadosBasicos
									}
								/>
							) : (
								<p>{lblDescSexo || 'Não Informado'}</p>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>
								<b>Nacionalidade</b>
							</p>
							{editable ? (
								<select
									required
									disabled={
										lblHabilitarAtualizacaoDadosBasicos
									}
									className="form-control"
									name="lblIdNacionalidade"
									id="lblIdNacionalidade"
									value={lblIdNacionalidade}
									onChange={event =>
										setlblIdNacionalidade(
											event.target.value
										)
									}
								>
									<option value="1">Brasileiro</option>
									<option value="2">Estrangeiro</option>
									<option value="3">
										Brasileiro Nascido no Exterior
									</option>
								</select>
							) : (
								<p>{lblDescNacionalidade || 'Não Informado'}</p>
							)}
						</div>
					</div>
					{parseInt(lblIdNacionalidade) === 1 ? (
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Estado Natal</b>
								</p>
								{editable ? (
									<SelectUf
										disabled={
											lblHabilitarAtualizacaoDadosBasicos
										}
										valueSelect={lblIdUFMunNascto}
										setUf={setlblIdUFMunNascto}
									/>
								) : (
									<p>{lblEstadoNatal || 'Não Informado'}</p>
								)}
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Município de Nascimento</b>
								</p>
								{editable ? (
									<SelectCidade
										disabled={
											lblHabilitarAtualizacaoDadosBasicos
										}
										ufCidade={lblIdUFMunNascto}
										valueSelect={lblIdCidadeNascido}
										setCidade={setlblIdCidadeNascido}
									/>
								) : (
									<p>{lblCidadeNatal || 'Não Informado'}</p>
								)}
							</div>
						</div>
					) : null}
					{parseInt(lblIdNacionalidade) !== 1 ? (
						<>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>País</b>
									</p>
									{editable ? (
										<SelectPais
											disabled={
												lblHabilitarAtualizacaoDadosBasicos
											}
											valueSelect={lblCodPaisOrigem}
											setCodPais={setlblCodPaisOrigem}
										/>
									) : (
										<p>
											{lblNomePaisOrigem ||
												'Não Informado'}
										</p>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Data de Entrada no País</b>
									</p>
									{editable ? (
										<InputMask
											disabled={
												lblHabilitarAtualizacaoDadosBasicos
											}
											mask="99/99/9999"
											required
											type="text"
											name="lblDataEntradaPais"
											id="lblDataEntradaPais"
											className="form-control"
											placeholder="Data de Entrada no País"
											value={lblDataEntradaPais}
											onChange={event =>
												setlblDataEntradaPais(
													event.target.value
												)
											}
										/>
									) : (
										<p>
											{lblDataEntradaPais ||
												'Não Informado'}
										</p>
									)}
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Número do Visto</b>
									</p>
									{editable ? (
										<input
											disabled={
												lblHabilitarAtualizacaoDadosBasicos
											}
											maxLength="11"
											minLength="11"
											type="text"
											name="lblNumVistoConfere"
											id="lblNumVistoConfere"
											className="form-control"
											placeholder="Número do Visto"
											value={lblNumVistoConfere}
											onChange={event =>
												setlblNumVistoConfere(
													event.target.value
												)
											}
										/>
									) : (
										<p>
											{lblNumVistoConfere ||
												'Não Informado'}
										</p>
									)}
								</div>
							</div>
						</>
					) : null}
				</div>
				{editable && userActualType !== 9 ? (
					<div className="card-footer">
						<div className="row d-flex align-items-center">
							<div className="col-12 col-md-11 col-lg-11 d-flex justify-content-end">
								<Tooltip
									title="Alterar Dados do Usuário."
									placement="top-start"
								>
									<button
										className="buttonEdit button-profile-edit"
										type="button"
										onClick={handleAtualizarDadosBasicos}
									>
										<FaEdit /> Editar
									</button>
								</Tooltip>
							</div>
							<div className="col-12 col-md-1 col-lg-1 d-flex justify-content-end">
								<button
									className="btn btn-primary"
									type="submit"
									disabled={
										lblHabilitarAtualizacaoDadosBasicos
									}
								>
									Salvar
								</button>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</form>
	);
}
