import React from 'react';
import { useSelector } from 'react-redux';
import ListSubjects from '../../components/Subjects/ListSubjects';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function Employee() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Disciplinas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<ListSubjects />
		</>
	);
}
