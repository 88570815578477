const cor = [
	{
		id: 1,
		name: 'Branca',
	},
	{
		id: 2,
		name: 'Negra',
	},
	{
		id: 3,
		name: 'Parda',
	},
	{
		id: 4,
		name: 'Amarela',
	},
	{
		id: 5,
		name: 'Indígena',
	},
	{
		id: 6,
		name: 'Não Declarada',
	},
];

export default cor;