import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectServicosMecanicos({
	setServiceMecanico,
	valueSelect,
	disabled,
}) {
	const [servicesMecanicos, setServicesMecanicos] = useState([]);

	useEffect(() => {
		async function loadTiposTelefones() {
			const response = await api.get('/tabelas/servico-mecanico');
			setServicesMecanicos(response.data.data);
		}
		loadTiposTelefones();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(e) {
		setServiceMecanico(e);
	}
	const listServicesMecanicos = servicesMecanicos.map(t => (
		<option value={t.id_servico_mecanico} key={t.id_servico_mecanico}>
			{t.servico_mecanico}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblTipoTelefone"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Tipo de Serviços</option>
				{listServicesMecanicos}
			</select>
		</>
	);
}
