import React, { useState, Fragment, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import api from '../../../services/api';

export default function CardMonitor({ setMonitorsIds }) {
	const [listMonitors, setListMonitor] = useState([]);
	const [arrayTeste, setArrayVeiculo] = useState([]);
	const [inputFields, setInputFields] = useState([{ idMonitor: '' }]);

	function loadMonitors() {
		api.get('/transporte/monitor/lista-monitor')
			.then(response => response.data.data)
			.then(setListMonitor)
			.catch(() => setListMonitor([]));
	}
	useEffect(() => {
		loadMonitors();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ idMonitor: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		const arrayValues = [...arrayTeste];
		values.splice(index, 1);
		arrayValues.splice(index, 1);
		setArrayVeiculo(arrayValues);
		setInputFields(values);
		setMonitorsIds(arrayValues);
	};

	const handleInputChange = (index, event) => {
		const data = [...arrayTeste];
		data[index] = event.target.value;
		setArrayVeiculo(data);
		setMonitorsIds(data);
		const values = [...inputFields];
		if (event.target.name === 'idMonitor')
			values[index].idMonitor = event.target.value;
		setInputFields(values);
	};

	return (
		<div className="row">
			{inputFields.map((inputField, index) => (
				<Fragment
					// eslint-disable-next-line react/no-array-index-key
					key={`${inputField}~${index}`}
				>
					<div className="form-group col-10 col-lg-10 col-md-10">
						<select
							name="idMonitor"
							className="form-control"
							id="idMonitor"
							onChange={event => handleInputChange(index, event)}
						>
							<option value="">Escolha um monitor</option>
							{listMonitors
								? listMonitors.map(monitor => (
										<option
											key={monitor.users.id_usuario}
											value={monitor.users.id_usuario}
										>
											Monitor:{' '}
											{monitor.users.nome_usuario}
										</option>
								  ))
								: null}
						</select>
					</div>
					<div className="form-group col-1 col-lg-1 col-md-1">
						{inputFields.length > 1 && (
							<button
								className="btn btn-danger formRouteStudentsAddRemoveButtons"
								type="button"
								onClick={() => handleRemoveFields(index)}
							>
								<FaMinus fill="#ffffff" />
							</button>
						)}
					</div>
					<div className="form-group col-1 col-lg-1 col-md-1">
						<button
							className="btn btn-success formRouteStudentsAddRemoveButtons"
							type="button"
							onClick={() => handleAddFields()}
						>
							<FaPlus fill="#ffffff" />
						</button>
					</div>
				</Fragment>
			))}
		</div>
	);
}
