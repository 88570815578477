import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb';
import regex from '../../../helpers/regex';
import { CalendarActions } from '../../../redux/ducks/calendarReducer';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Routes from '../../../routes/data/Routes';
import api from '../../../services/api';
import Calendar from '../components/Calendar';

export default function Menu() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [schools, setSchools] = useState([]);
	const [school, setSchool] = useState(null);
	const [periods, setPeriods] = useState([]);
	const [period, setPeriod] = useState(null);
	const [date, setDate] = useState(null);
	const options = useSelector(state => state.calendar);
	const dispatch = useDispatch();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cardápio',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getPeriods() {
		const response = await api.get('/nutricionista/periodo-refeicoes');

		response.data
			? setPeriods(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function getSchools() {
		const response = await api.get('/tabelas/lista-escolas');

		response.data
			? setSchools(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function addMenu() {
		if (!date || !school)
			return swal('ATENÇÃO!', 'Preencha todas as informações', 'warning');

		if (options.length === 0)
			return swal('ATENÇÃO', 'Cardápio vazio', 'warning');

		const response = await api.post('/nutricionista/cardapios/adicionar', {
			opcoes: options,
			data: date
				.split('/')
				.reverse()
				.join('-'),
			periodo: period,
			escola: school,
		});

		if (!response.data.error) {
			swal('SUCESSO!', 'Cardápio adicionado com sucesso', 'success');
			dispatch(CalendarActions.clearCalendar());
			setPeriod(null);
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	useEffect(() => {
		getSchools();
		getPeriods();
	}, []);

	useEffect(() => () => dispatch(CalendarActions.clearCalendar()), []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="form-group">
				<label htmlFor="menuAddSelectSchool">Selecione a escola</label>
				<select
					id="menuAddSelectSchool"
					className="form-control"
					value={school}
					onChange={item => setSchool(item.target.value)}
				>
					<option selected disabled />
					{schools.map(item => (
						<option value={item.id}>{item.name}</option>
					))}
				</select>
			</div>
			<div className="form-group">
				<label htmlFor="menuAddSelectPeriod">Selecione o período</label>
				<select
					id="menuAddSelectPeriod"
					className="form-control"
					value={period}
					onChange={item => setPeriod(+item.target.value)}
				>
					<option selected disabled />
					{periods.map(item => (
						<option value={item.id_periodo_refeicao}>
							{item.periodo}
						</option>
					))}
				</select>
			</div>
			<div className="form-group">
				<label htmlFor="dateInputMenuAdd">Data</label>

				<InputMask
					id="dateInputMenuAdd"
					className="form-control"
					type="dateInputMenuAdd"
					mask="99/99/9999"
					value={date}
					pattern={regex.DATE}
					onChange={item => setDate(item.target.value)}
				/>
			</div>

			{period ? (
				<>
					<Calendar period={period} isEditable isAddMenu />

					<div className="d-flex justify-content-end">
						<button
							type="button"
							className="btn btn-primary"
							onClick={addMenu}
						>
							Salvar
						</button>
					</div>
				</>
			) : null}
		</>
	);
}
