import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import iconCar from '../../assets/img/icons/icon-car.png';
import iconDriver from '../../assets/img/icons/icon-driver.png';
import iconMonitor from '../../assets/img/icons/icon-monitor.png';
import iconRoutes from '../../assets/img/icons/icon-routes.png';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import ScrollToTop from '../../components/buttom/ScrollToTop';
import CardsInfo from '../../components/Cards/CardsInfo';

export default function Level_08() {
	const [attendance, setAttendance] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		setIsLoading(true);
		(async function() {
			const response = await api.get('/transporte');
			setAttendance(response.data.data);
		})();
		setIsLoading(false);
	}, []);

	const chartExample2 = {
		options: {
			scales: {
				yAxes: [
					{
						ticks: {
							callback(value) {
								if (!(value % 10))
									// return '$' + value + 'k'
									return value;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					label(item, data) {
						const label =
							data.datasets[item.datasetIndex].label || '';
						const { yLabel } = item;
						let content = '';
						if (data.datasets.length > 1) content += label;

						content += yLabel;
						return content;
					},
				},
			},
		},
		data: {
			labels: [
				'jan',
				'fev',
				'mar',
				'abr',
				'mai',
				'jun',
				'jul',
				'ago',
				'set',
				'out',
				'nov',
				'dez',
			],
			datasets: [
				{
					label: 'Transporte',
					data: [
						attendance.countTransportMonth
							? attendance.countTransportMonth[0].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[1].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[2].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[3].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[4].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[5].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[6].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[7].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[8].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[9].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[10].count
							: 0,
						attendance.countTransportMonth
							? attendance.countTransportMonth[11].count
							: 0,
					],
					backgroundColor: [
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
						'#ff0000',
					],
				},
			],
		},
	};
	const chartExample3 = {
		data: {
			datasets: [
				{
					data: [
						attendance.countDriver ? attendance.countDriver : 0,
						attendance.countMonitor ? attendance.countMonitor : 0,
						attendance.countTransport
							? attendance.countTransport
							: 0,
					],
					backgroundColor: [
						'rgba(255,0,0,0.7)',
						'rgba(255,255,0,0.7)',
						'rgba(0,0,255,0.7)',
					],
				},
			],
			// These labels appear in the legend and in the tooltips when hovering different arcs
			labels: ['Motoristas', 'Monitores', 'Rotas de Transporte'],
		},
	};

	return (
		<>
			{isLoading ? (
				<div className="row pt-md-5 mt-md-3 mb-5">
					<div className="lds-dual-ring" />
				</div>
			) : (
				<>
					<CardsInfo
						card1={{
							title: 'Motoristas',
							icon: iconDriver,
							number: `${
								attendance.countDriver
									? attendance.countDriver
									: 0
							}`,
							link: Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_DRIVER,
						}}
						card2={{
							title: 'Monitores',
							icon: iconMonitor,
							number: `${
								attendance.countMonitor
									? attendance.countMonitor
									: 0
							}`,
							link: Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_MONITOR,
						}}
						card3={{
							title: 'Rotas',
							icon: iconRoutes,
							number: `${
								attendance.countTransport
									? attendance.countTransport
									: 0
							}`,
							link: Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_ROUTE_TRANSPORT,
						}}
						card4={{
							title: 'Veículos',
							icon: iconCar,
							number: `${
								attendance.countVehicle
									? attendance.countVehicle
									: 0
							}`,
							link: Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_VEHICLE,
						}}
					/>
					<div className="row pt-md-5 mt-md-3 mb-5">
						<div className="col-xl-6 col-lg-6 col-md-12 pb-3">
							<div className="card">
								<div className="card-body">
									<Bar
										data={chartExample2.data}
										options={chartExample2.options}
									/>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 pb-3">
							<div className="card">
								<div className="card-body">
									<Doughnut
										data={chartExample3.data}
										options={chartExample3.options}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
