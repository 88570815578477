import React from 'react';
import Routes from './data/Routes';
import PrivateRouter from './PrivateRouter';
import Level_08 from '../pages/Home/HomeLevel08';
import ListDriver from '../pages/Driver';
import AddDriver from '../pages/Driver/Add';
import UpdateDriver from '../pages/Driver/UpdateDriver';
import InfoProfile from '../pages/Profile/InfoProfile';
import ListMonitor from '../pages/Monitor';
import AddMonitor from '../pages/Monitor/Add';
import UpdateMonitor from '../pages/Monitor/UpdateMonitor';
import ListMonitorHasStudent from '../pages/Monitor/ListMonitorHasStudent';
import FormMonitorHasStudents from '../pages/Monitor/ListMonitorHasStudent/Add';
import UpdateMonitorHasStudents from '../pages/Monitor/ListMonitorHasStudent/Update';
import ListTransport from '../pages/RouteDriver';
import AddTransport from '../pages/RouteDriver/Add';
import InfoRouteTransport from '../pages/RouteDriver/InfoRouteTransport';
import UpdateRouteTransport from '../pages/RouteDriver/UpdateRouteTransport';
import OnlyGoing from '../pages/Transport/OnlyGoing';
import GoingBack from '../pages/Transport/GoingBack';
import StudentItinerary from '../pages/Transport/StudentItinerary';
import Vehicles from '../pages/Vehicles';
import AddVehicles from '../pages/Vehicles/Add';
import InfoVehicle from '../pages/Vehicles/InfoVehicle';
import UpdateVehicle from '../pages/Vehicles/UpdateVehicle';
import AddMaintenance from '../pages/Vehicles/InfoVehicle/AddMaintenance';
import InfoMaintenance from '../pages/Vehicles/InfoVehicle/InfoMaintenance';
import EditMaintenace from '../pages/Vehicles/InfoVehicle/EditMaintenace';
import InfoDriver from '../pages/Driver/InfoDriver';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Driver/UpdateDriver/ChangeContact';

export default function RoutesTransportSupervisor({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Level_08}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.LIST_DRIVER}
				component={ListDriver}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.ADD_DRIVER}
				component={AddDriver}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.UPDATE_DRIVER}
				component={UpdateDriver}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.INFO_DRIVER}
				component={InfoDriver}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.LIST_MONITOR}
				component={ListMonitor}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.ADD_MONITOR}
				component={AddMonitor}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.UPDATE_MONITOR}
				component={UpdateMonitor}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.LIST_ROUTE_TRANSPORT}
				component={ListTransport}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.ADD_ROUTE_TRANSPORT}
				component={AddTransport}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.INFO_ROUTE_TRANSPORT}
				component={InfoRouteTransport}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.UPDATE_ROUTE_TRANSPORT}
				component={UpdateRouteTransport}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.TRANSPORT_ONLY_GOING}
				exact
				component={OnlyGoing}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.TRANSPORT_GOING_BACK}
				exact
				component={GoingBack}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.STUDENT_ITINERARY}
				exact
				component={StudentItinerary}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.LIST_VEHICLE}
				exact
				component={Vehicles}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.ADD_VEHICLE}
				exact
				component={AddVehicles}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.INFO_VEHICLE}
				exact
				component={InfoVehicle}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.UPDATE_VEHICLE}
				exact
				component={UpdateVehicle}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.ADD_MAINTENANCE}
				exact
				component={AddMaintenance}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.INFO_MAINTENANCE}
				exact
				component={InfoMaintenance}
			/>
			<PrivateRouter
				userTypes={[12]}
				path={LOGGED_ROUTES.UPDATE_MAINTENANCE}
				exact
				component={EditMaintenace}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.LIST_MONITOR_HAS_STUDENTS}
				component={ListMonitorHasStudent}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.CREATE_LIST_MONITOR_HAS_STUDENTS}
				component={FormMonitorHasStudents}
			/>
			<PrivateRouter
				userTypes={[12]}
				exact
				path={LOGGED_ROUTES.UPDATE_LIST_MONITOR_HAS_STUDENTS}
				component={UpdateMonitorHasStudents}
			/>
		</>
	);
}
