export default [
	null,
	'Prefeito(a)',
	'Secretaria da Educação',
	'Diretor(a) de Ensino',
	'Diretor(a)',
	'Secretaria',
	'Coordenador(a)',
	'Professor(a)',
	'Responsável',
	'Aluno(a)',
	'Cozinha Piloto',
	'Nutricionista',
	'Supervisor(a) do Transporte',
	'Motorista',
	'Monitor(a)',
	'Diretoria Administrativa',
	'Divisão de Gestão Administrativa',
	'Divisão de Planejamento e Orçamento',
	'Divisão de TI',
	'Diretoria de Supervisão Pedagógica',
	'Divisão de Educação Infantil',
	'Divisão de Educação Fundamental',
	'Divisão de Educação Especial',
	'Diretoria de Suprimentos',
	'Divisão de Alimentação Escolar',
	'Divisão de Transporte Escolar',
	'Divisão de Patrimônio',
	'Divisão de Suprimentos',
	'Administrador(a) das Creches'
];
