import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

export default function Breadcrumb({ breadCrumbItems }) {
	return (
		<>
			<nav aria-label="breadcrumb" className="navBreadCrumb">
				<ol className="breadcrumb">
					{breadCrumbItems.map(
						({
							linkPage,
							namePage,
							breadCrumbClass,
							statePage,
						}) => (
							<li key={namePage} className={breadCrumbClass}>
								<Link to={linkPage} state={statePage}>
									{namePage}
								</Link>
							</li>
						)
					)}
				</ol>
			</nav>
		</>
	);
}
