import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function FormVehicle() {
	const userActualType = useSelector(state => state.userType.actualType);

	const [codRenavam, setCodRenavam] = useState('');
	const [placa, setPlaca] = useState('');
	const [modelo, setModelo] = useState('');
	const [proprietario, setProprietario] = useState('');
	const [cpfProprietario, setCpfProprietario] = useState('');
	const [chassi, setChassi] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_VEHICLE,
			namePage: 'Veículos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cadastro do Veículo ',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleAddVehicle() {
		setIsLoading(true);
		try {
			const { data } = await api.post(
				'/transporte/veiculo/criar-veiculo',
				{
					codRenavam,
					placa,
					modelo,
					proprietario,
					cpfProprietario,
					chassi,
				}
			);
			if (data.error === false) {
				setCodRenavam('');
				setPlaca('');
				setModelo('');
				setProprietario('');
				setCpfProprietario('');
				setChassi('');
				swal({
					icon: 'success',
					text: 'Veículo cadastrado corretamente.',
				});
			} else swal({ icon: 'error', text: data.message });
		} catch (error) {
			swal('Houve um erro ao adicionar a veículo.');
		}
		setIsLoading(false);
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<Breadcrumb breadCrumbItems={breadCrumbItems} />
						{isLoading === true ? (
							<div className="lds-dual-ring" />
						) : (
							<form onSubmit={handleAddVehicle}>
								<div className="card mt-3">
									<div className="card-header form-group">
										<h4>Cadastro de Veículos</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group text-left">
												<label htmlFor="addVehicle_renavam">
													<b>Cod. Renavam</b>
												</label>
												<input
													type="text"
													className="form-control"
													required
													id="addVehicle_renavam"
													placeholder="Renavam do veículo"
													value={codRenavam}
													onChange={event =>
														setCodRenavam(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group text-left">
												<label htmlFor="addVehicle_placa">
													<b>Placa</b>
												</label>
												<input
													type="text"
													required
													className="form-control"
													id="addVehicle_placa"
													placeholder="Placa do veículo"
													value={placa}
													onChange={event =>
														setPlaca(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group text-left">
												<label htmlFor="addVehicle_modelo">
													<b>Modelo</b>
												</label>
												<input
													type="text"
													required
													className="form-control"
													id="addVehicle_modelo"
													placeholder="Ex: Gol 1.8, 4 portas"
													value={modelo}
													onChange={event =>
														setModelo(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group text-left">
												<label htmlFor="addVehicle_proprietario">
													<b>Proprietário</b>
												</label>
												<input
													type="text"
													className="form-control"
													id="addVehicle_proprietario"
													placeholder="Nome do proprietário"
													value={proprietario}
													onChange={event =>
														setProprietario(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group text-left">
												<label htmlFor="addVehicle_cpf">
													<b>CPF Proprietário</b>
												</label>
												<InputMask
													mask="999.999.999-99"
													size={16}
													type="text"
													name="cpfProprietario"
													id="addVehicle_cpf"
													className="form-control"
													placeholder="CPF do Usuário"
													value={cpfProprietario}
													onChange={event =>
														setCpfProprietario(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group text-left">
												<label htmlFor="addVehicle_chassi">
													<b>Chassi</b>
												</label>
												<input
													type="text"
													className="form-control"
													id="addVehicle_chassi"
													placeholder="Chassi"
													value={chassi}
													onChange={event =>
														setChassi(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-4 col-md-4 col-12 form-group">
												<button
													className="btn buttonCustomSemec"
													type="submit"
												>
													Cadastrar
												</button>
											</div>
											<div className="col-lg-4 col-md-4 col-12 form-group">
												<button
													className="btn buttonCustomCancelSemec"
													type="submit"
												>
													Cancelar
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
