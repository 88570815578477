import React from 'react';

export default function index({ array = [] }) {
	function getNameInListById(data, value) {
		const name = data.map(d => {
			if (d.id == value) return d.name;
		});
		return name;
	}

	const list = array.map((U, index) => (
		<div className={`col-12 col-md-${U.sizeColumn} form-group`} key={index}>
			<label style={{ display: 'inline' }}>{U.name}:</label>{' '}
			<small>{getNameInListById(U.array, U.value)}</small>
		</div>
	));

	return (
		<div className="mt-4">
			<div className="row">{list}</div>
		</div>
	);
}
