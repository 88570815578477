import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	FaTimesCircle,
	FaPencilAlt,
	FaTrashAlt,
	FaFileContract,
} from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';

export default function ListMonitor() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Monitores',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [monitors, setMonitors] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	async function loadMonitor() {
		setIsLoading(true);
		const response = await api.get('/transporte/monitor/lista-monitor');
		if (!response.data.error) setMonitors(response.data.data);
		else {
			setMonitors([]);
			swal({ icon: 'warning', text: response.data.message });
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadMonitor();
	}, []);

	function OptionsButton({ monitor }) {
		function editNavigation() {
			const params = {};

			params[RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR] = monitor;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.UPDATE_MONITOR,
				{ params }
			);
		}

		async function handleDeactivated(e) {
			swal({
				title: 'Você tem certeza?',
				text: `Você está para desativar o monitor ${e.users.nome_usuario}`,
				icon: 'warning',
				buttons: ['Cancelar', 'Confirmar'],
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const data = { idMonitor: e.users.id_usuario };
						const {
							data: { message },
						} = await api.post(
							'/transporte/monitor/desativa-monitor',
							data
						);
						swal('SUCESSO!', `${message}`, 'success').then(
							value => {
								if (value) loadMonitor();
							}
						);
					})();
				else swal('Ação cancelada');
			});
		}

		async function handleCreateMonitor(e) {
			const params = {};
			params[RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR] = e.users;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_MONITOR_HAS_STUDENTS,
				{ params }
			);
		}
		return (
			<>
				<div className="d-flex justify-content-center">
					<button
						type="button"
						aria-hidden
						data-toggle="tooltip"
						title="Editar monitor"
						className="buttonAddColor"
						onClick={() => editNavigation(monitor)}
					>
						<FaPencilAlt fill="#ffffff" />
					</button>

					<button
						type="button"
						className="buttonDelete"
						onClick={() => handleDeactivated(monitor)}
					>
						<FaTrashAlt
							aria-hidden
							data-toggle="tooltip"
							title="Desativar monitor"
							fill="#ffffff"
						/>
					</button>
					<button
						type="button"
						className="buttonSuccess"
						onClick={() => handleCreateMonitor(monitor)}
					>
						<FaFileContract
							aria-hidden
							data-toggle="tooltip"
							title="Lista de alunos do monitor"
							fill="#ffffff"
						/>
					</button>
				</div>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<div className="d-flex flex-row-reverse">
							<Link
								to={
									Routes.LOGGED_ROUTES(
										RouteByPermission[userActualType]
									).ADD_MONITOR
								}
							>
								<button
									className="btn buttonCustomSemec"
									type="button"
									id="button"
								>
									Cadastrar Novo Monitor
								</button>
							</Link>
						</div>
						{monitors ? (
							<Table
								header={['Nome', '']}
								body={monitors.map(monitor => {
									return [
										monitor.users.nome_usuario,
										<OptionsButton monitor={monitor} />,
									];
								})}
							/>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhum monitor encontrado
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
