import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import Panel from '../../../components/Panel';
import Footer from '../../../components/Footer';
import Breadcrumb from '../../../components/Breadcrumb';
import Table from '../../../components/Table';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import api from '../../../services/api';
import regex from '../../../helpers/regex';
import './style.css';

function CheckboxElement({ product, itemsToRemove, setItemsToRemove }) {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checked) return setItemsToRemove([...itemsToRemove, product]);
		setItemsToRemove(itemsToRemove.filter(item => item !== product));
	}, [checked]);

	return <input type="checkbox" onChange={() => setChecked(!checked)} />;
}

export default function ShoppingListAdd() {
	const [products, setProducts] = useState([]);
	const [shoppingList, setShoppingList] = useState([]);
	const [itemsToRemove, setItemsToRemove] = useState([]);
	// const [shoppingListQuantity, setShoppingListQuantity] = useState([]);
	const [product, setProduct] = useState({});
	const [option, setOption] = useState('');
	const [date, setDate] = useState(null);
	// const [quantity, setQuantity] = useState(null);
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.SHOPPING_LIST,
			namePage: 'Lista de Compras',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getProducts() {
		const response = await api.get('/cozinha-piloto/produtos');

		!response.data.error
			? setProducts(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	function addProductToList() {
		// setProduct(product);
		// setShoppingListQuantity([...shoppingListQuantity, quantity]);
		setShoppingList([...shoppingList, product]);
	}

	async function saveShoppingList() {
		if (!date)
			return swal(
				'ATENÇÃO!',
				'Selecione a data da lista de compra',
				'warning'
			);

		const response = await api.post(
			'cozinha-piloto/lista-de-compras/adicionar',
			{
				lista_de_compras: shoppingList,
				data: date
					.split('/')
					.reverse()
					.join('-'),
			}
		);

		if (!response.data.error) {
			swal(
				'SUCESSO!',
				'Lista de compras adicionado com sucesso',
				'success'
			);
			setShoppingList([]);
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	function removeShoppingListItem() {
		if (itemsToRemove.length === 0)
			return swal(
				'ATENÇÃO',
				'Selecione um produto para remover',
				'warning'
			);

		setShoppingList(
			shoppingList.filter(
				shoppingListItem => !itemsToRemove.includes(shoppingListItem)
			)
		);
	}

	useEffect(() => {
		getProducts();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div>
				<div className="addItem">
					<form onSubmit={saveShoppingList}>
						<div className="form-group">
							<label htmlFor="dateInputShoppingListAdd">
								Data da lista de compras
							</label>
							<InputMask
								id="dateInputShoppingListAdd"
								className="form-control"
								mask="99/99/9999"
								pattern={regex.DATE}
								type="text"
								onChange={event => setDate(event.target.value)}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="productSelectShoppingListAdd">
								Produto
							</label>
							<select
								id="productSelectShoppingListiAdd"
								className="form-control"
								value={option}
								onChange={item => {
									const selectedProduct = products.find(
										element =>
											element.id_produto ==
											item.target.value
									);

									setProduct(selectedProduct);
									setOption(item.target.value);
								}}
							>
								<option selected disabled />
								{products.map(item => (
									<option value={item.id_produto}>
										{item.nome}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="quantityInputShoppingListAdd">
								Quantidade
							</label>
							<input
								id="quantityInputShoppingListAdd"
								className="form-control"
								pattern={regex.ONLY_NUMBERS}
								type="text"
								onChange={event =>
									setProduct({
										...product,
										shoppingListQuantity: +event.target
											.value,
									})
								}
							/>
						</div>
						<div className="form-row justify-content-end">
							<button
								type="button"
								className="btn btn-success mx-1"
								onClick={addProductToList}
							>
								Adicionar Produto
							</button>
						</div>
					</form>
				</div>

				{shoppingList.length !== 0 && (
					<>
						<div className="card shoppingListAddCardTable">
							<p className="shoppingListAddTableTitle">
								Lista de Compras
							</p>

							<Table
								header={['', 'Produto', 'Quantidade']}
								body={shoppingList.map(item => {
									return [
										<CheckboxElement
											product={item}
											itemsToRemove={itemsToRemove}
											setItemsToRemove={setItemsToRemove}
										/>,
										item.nome,
										item.shoppingListQuantity,
									];
								})}
							/>
							{/* <table className="table shoppingListAddTable">
								<thead>
									<th>Produto</th>
									<th>Quantidade</th>
								</thead>
								<tbody>
									{shoppingList.map(
										(item, index) => (
											<tr
												id={`shoppingListAddItem${index}`}
												className="shoppingListAddItems"
												onClick={() => {
													setIndexToRemove(
														index
													);

													if (
														document.querySelector(
															'.shoppingListAddTable > tbody > tr.remove'
														)
													) {
														const previousElement = document.querySelector(
															'.shoppingListAddTable > tbody > tr.remove'
														);

														const clickedElement = document.querySelector(
															`#shoppingListAddItem${index}`
														);

														if (
															previousElement ===
															clickedElement
														) {
															clickedElement.classList.remove(
																'remove'
															);

															setIndexToRemove(
																null
															);
														} else {
															previousElement.classList.remove(
																'remove'
															);

															clickedElement.classList.add(
																'remove'
															);
														}
													} else {
														const clickedElement = document.querySelector(
															`#shoppingListAddItem${index}`
														);

														clickedElement.classList.add(
															'remove'
														);
													}
												}}
											>
												<th>
													{item.nome}
												</th>
												<th>
													{
														shoppingListQuantity[
															index
														]
													}
												</th>
											</tr>
										)
									)}
								</tbody>
							</table> */}
						</div>

						<div className="d-flex justify-content-end align-items-end">
							<button
								type="button"
								className="btn btn-primary mx-3 buttonShoppingListAddSaveShoppingList"
								onClick={saveShoppingList}
							>
								Salvar Lista de Compras
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={removeShoppingListItem}
							>
								Remover Item
							</button>
						</div>
					</>
				)}
			</div>
		</>
	);
}
