/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css';

const MapStudents = ({ coordenadas }) => {
	mapboxgl.accessToken =
		'pk.eyJ1IjoibWJ4c29sdXRpb25zIiwiYSI6ImNqeWtiM250dzBjcGMzbnQ1NXpkZml6YjUifQ.EiJnPlDOMMlG5wr93SxVYQ';

	useEffect(() => {
		const filterGroup = document.getElementById('filter-group');
		while (filterGroup.firstChild)
			filterGroup.removeChild(filterGroup.lastChild);

		const stores = {
			type: 'FeatureCollection',
			features: coordenadas,
		};
		const map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [-44.9695124, -22.5839855],
			zoom: 13,
		});

		map.on('load', () => {
			map.resize();
			map.addSource('stores', {
				type: 'geojson',
				data: stores,
			});

			stores.features.forEach(function(feature) {
				const symbol = feature.properties.icon;
				const name = feature.properties.label;
				const layerID = `poi-${name}`;

				if (!map.getLayer(layerID)) {
					map.addLayer({
						id: layerID,
						type: 'symbol',
						source: 'stores',
						layout: {
							'icon-image': `${symbol}-15`,
							'icon-allow-overlap': true,
						},
						filter: ['==', 'icon', symbol],
					});

					const input = document.createElement('input');
					input.type = 'checkbox';
					input.id = layerID;
					input.checked = true;
					filterGroup.appendChild(input);

					const label = document.createElement('label');
					label.setAttribute('for', layerID);
					label.textContent = name;
					filterGroup.appendChild(label);

					input.addEventListener('change', function(e) {
						map.setLayoutProperty(
							layerID,
							'visibility',
							e.target.checked ? 'visible' : 'none'
						);
					});
				}
				map.on('click', layerID, function(e) {
					const coordinates = e.features[0].geometry.coordinates.slice();
					const nameUser = e.features[0].properties.name;

					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180)
						coordinates[0] +=
							e.lngLat.lng > coordinates[0] ? 360 : -360;

					new mapboxgl.Popup()
						.setLngLat(coordinates)
						.setHTML(nameUser)
						.addTo(map);
				});

				map.on('mouseenter', layerID, function() {
					map.getCanvas().style.cursor = 'pointer';
				});

				map.on('mouseleave', layerID, function() {
					map.getCanvas().style.cursor = '';
				});
			});
		});
	}, [coordenadas]);

	return (
		<>
			<div className="card mt-3 mb-3" id="card-mapa-escolas">
				<div className="card-header">
					<h4>Mapa dos Alunos</h4>
				</div>
				<div id="map" />
				<nav id="filter-group" className="filter-group" />
			</div>
		</>
	);
};

export default MapStudents;
