import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Panel from '../../../components/Panel';
import Breadcrumb from '../../../components/Breadcrumb';
import api from '../../../services/api';
import Footer from '../../../components/Footer';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function Index() {
	const location = useLocation();
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumb = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.STUDENTS,
			namePage: 'Listagem de alunos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.REPORT_CARD,
			namePage: 'Boletim',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [classStudent, setClassStudent] = useState([]);
	const [attendanceStudent, setAttendanceStudent] = useState([]);
	const [isLoanding, setIsLoanding] = useState(true);

	useEffect(() => {
		const state = location.state;
		if (state) {
			const alunoID =
				state[RouteParams.LEVEL_03_ROUTES.REPORT_CARD.STUDENT_ID];
			const disciplinaID =
				state[
					RouteParams.LEVEL_03_ROUTES.REPORT_CARD.SUBJECT_ID_TEACHER
				];
			(async function() {
				setIsLoanding(true);
				const response = await api.get('/aluno/boletim', {
					params: {
						alunoID,
						disciplinaID,
					},
				});
				if (!response.data.error) {
					setClassStudent(response.data.data.nota);
					setAttendanceStudent(response.data.data.falta);
				} else swal('Ops!', `${response.data.message}`, 'warning');
				setIsLoanding(false);
			})();
		} else {
			swal('Ops!', 'Escolha um aluno', 'warning');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}, []);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumb} />
			{isLoanding ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<div className="row">
							{classStudent.map(student => (
								<div className="col-12">
									Aluno(a): {student.nome_usuario}
								</div>
							))}
						</div>
						<br />
						<div className="table-responsive">
							{classStudent.length ? (
								<table className="table table-hover">
									<thead>
										<tr>
											<th scope="col">Disciplina</th>
											<th scope="col">1º Bimestre</th>
											<th scope="col">2º Bimestre</th>
											<th scope="col">3º Bimestre</th>
											<th scope="col">4º Bimestre</th>
											<th scope="col">Média</th>
										</tr>
									</thead>
									<tbody>
										{classStudent.map(student => (
											<tr key={student.id_usuario}>
												<th>
													{student.nome_disciplina}
												</th>
												<th>
													{student.nota_bimestral1}
												</th>
												<th>
													{student.nota_bimestral2}
												</th>
												<th>
													{student.nota_bimestral3}
												</th>
												<th>
													{student.nota_bimestral4}
												</th>
												<th>
													{student.nota_media_final}
												</th>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<div>
									<p>
										<b>Aluno não tem notas no sistema</b>
									</p>
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-6">
								<p>
									<b>Faltas Aluno:</b> {attendanceStudent}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
