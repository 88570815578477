import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { FaInbox } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { FiSend } from 'react-icons/fi';

import ReviseQuestions from './ReviseQuestions';
import DoneQuestions from './DoneQuestions';
import Routes from '../../../routes/data/Routes';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Panel from '../../../components/Panel';

import './styles.css';

function ReviseEvaluention() {
	const [isLoading, setIsLoading] = useState(true);

	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Avalicações',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading === false ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item">
								<Tooltip
									title="Caixa de Comunicados."
									placement="top-start"
								>
									<a
										className="nav-link"
										id="home-tab"
										data-toggle="tab"
										href="#home"
										onClick={() => {}}
										role="tab"
										aria-controls="home"
										aria-selected="true"
									>
										<FaInbox id="FaInbox" />{' '}
										<small>Pendentes </small>
									</a>
								</Tooltip>
							</li>
							<li className="nav-item ">
								<Tooltip
									title="Comunicados Enviados."
									placement="top-start"
								>
									<a
										className="nav-link"
										id="profile-tab"
										data-toggle="tab"
										href="#perfil"
										role="tab"
										aria-controls="profile"
										aria-selected="false"
										onClick={() => {}}
									>
										<MdEmail id="MdEmail" />{' '}
										<small>Corrigidas</small>
									</a>
								</Tooltip>
							</li>
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="home"
								role="tabpanel"
								aria-labelledby="home-tab"
							>
								<ReviseQuestions />
							</div>
							<div
								className="tab-pane fade"
								id="perfil"
								role="tabpanel"
								aria-labelledby="profile-tab"
							>
								<DoneQuestions />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ReviseEvaluention;
