import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import Panel from '../../../components/Panel';
import Footer from '../../../components/Footer';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';
import './styles.css';

const AddCommunicatedMessage = () => {
	const location = useLocation();
	const [communicated, setCommunicated] = useState({});
	const [message, setMessage] = useState('');
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COMMUNICATED,
			namePage: 'Comunicados',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar comentário',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleAddCommunicatedMessage(event) {
		event.preventDefault();

		const response = await api.post('/comunicados/adicionar-mensagem', {
			communicationId: communicated.id_comunicado,
			message,
		});

		if (response.data.error)
			return swal('ERRO!', `${response.data.message}`, 'error');

		swal('SUCESSO!', `${response.data.message}`, 'success');
		history.goBack();
	}

	useEffect(() => {
		if (location.state) setCommunicated(location.state.communicatedMessage);
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			<form
				onSubmit={handleAddCommunicatedMessage}
				className="addCommunicatedMessageForm"
			>
				<textarea
					placeholder="Mensagem"
					onChange={event => setMessage(event.target.value)}
				/>
				<button type="submit">Comentar</button>
			</form>
		</>
	);
};

export default AddCommunicatedMessage;
