import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function MediaClasse({ count }) {
	const disciplinas = [];
	count.map(item =>
		item.disciplinas.map(d => disciplinas.push(d.nome_disciplina))
	);
	const disciplinasArray = disciplinas.filter(function(item, pos) {
		return disciplinas.indexOf(item) === pos;
	});
	const colors = [
		'#6B93FF',
		'#5DB6DE',
		'#72F5E5',
		'#5DDE95',
		'#FF70EE',
		'#B062DE',
		'#9179F5',
		'#627EDE',
		'#68C7FC',
		'#685EA8',
		'#ACA1F5',
		'#90F589',
		'#6BA867',
	];

	const chartDynamic = {
		labels: disciplinasArray,
		datasets: count.map((item, index) => {
			return {
				label: item.ano,
				data: item.disciplinas.map(d => d.medias),
				backgroundColor: colors[index],
				order: index,
			};
		}),
	};

	return (
		<>
			<Bar data={chartDynamic} />
		</>
	);
}
