import React from 'react';
import { useSelector } from 'react-redux';
import InfoDriver from './InfoDriver';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function RegisterEmployees() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_DRIVER,
			namePage: 'Motoristas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação do Motorista',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return <InfoDriver breadCrumbItems={breadCrumbItems} />;
}
