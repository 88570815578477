import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaTimesCircle } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';

export default function ListMonitor() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Monitores',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [monitors, setMonitors] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	async function loadMonitor() {
		setIsLoading(true);
		const response = await api.get('/transporte/monitor/lista-monitor');
		if (!response.data.error) setMonitors(response.data.data);
		else {
			setMonitors([]);
			swal({ icon: 'warning', text: response.data.message });
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadMonitor();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						{monitors ? (
							<Table
								header={['Nome']}
								body={monitors.map(monitor => {
									return [monitor.users.nome_usuario];
								})}
							/>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhum monitor encontrado
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
