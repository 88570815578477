import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import ReactInputMask from 'react-input-mask';
import Footer from '../../../../components/Footer';
import Panel from '../../../../components/Panel';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import Breadcrumb from '../../../../components/Breadcrumb';
import SelectServicosMecanico from '../../../../components/Selects/SelectServicosMecanicos';
import regex from '../../../../helpers/regex';
import api from '../../../../services/api';
import RouteParams from '../../../../routes/data/RouteParams';
import ScrollToTop from '../../../../components/buttom/ScrollToTop';

export default function Index() {
	const history = useHistory();
	const location = useLocation();

	const [descricao, setDescricao] = useState('');
	const [entradaManutencao, setEntradaManutencao] = useState('');
	const [saidaManutencao, setSaidaManutencao] = useState('');
	const [localManutencao, setLocalManutencao] = useState('');
	const [responsavel, setResponsavel] = useState('');
	const [quilometragemEntrada, setQuilometragemEntrada] = useState('');
	const [quilometragemSaida, setQuilometragemSaida] = useState('');
	const [pecaNova, setPecaNova] = useState('0');
	const [valorManutencao, setValorManutencao] = useState('');
	const [idVeiculo, setIdVeiculo] = useState('');
	const [idServicoMecanico, setIdServicoMecanico] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_VEHICLE,
			namePage: 'Veículos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cadastro de Manutenção',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	useEffect(() => {
		(async function() {
			setIsLoading(true);
			if (location.state)
				setIdVeiculo(
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE
					]
				);
			else
				swal({ icon: 'error', text: 'Escolha um Veículo' }).then(
					event => {
						history.push(
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_VEHICLE
						);
					}
				);
			setIsLoading(false);
		})();
	}, []);

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const dataRegister = {
				descricao,
				entradaManutencao,
				saidaManutencao,
				localManutencao,
				responsavel,
				quilometragemEntrada,
				quilometragemSaida,
				pecaNova,
				valorManutencao,
				idVeiculo,
				idServicoMecanico,
			};
			const { data } = await api.post(
				'/transporte/veiculo/manutencao/criar-manutencao',
				dataRegister
			);
			if (data.error === false) {
				setDescricao('');
				setEntradaManutencao('');
				setSaidaManutencao('');
				setLocalManutencao('');
				setResponsavel('');
				setQuilometragemEntrada('');
				setQuilometragemSaida('');
				setPecaNova('');
				setValorManutencao('');
				setIdServicoMecanico('');
				swal({
					icon: 'success',
					text: 'Manutenção cadastrada corretamente.',
				}).then(() => {
					history.goBack();
				});
			} else swal({ icon: 'error', text: data.message });
		} catch (error) {
			swal('Houve um erro ao adicionar a manutenção.');
		}
		setIsLoading(false);
	}

	async function handleGoBack(event) {
		event.preventDefault();
		history.goBack();
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<form onSubmit={handleSubmit}>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Cadastro de Manutenção</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="entradaManutencao">
										<b>Entrada na Manutenção</b>
									</label>
									<ReactInputMask
										mask="99/99/9999"
										type="text"
										name="entradaManutencao"
										id="entradaManutencao"
										className="form-control"
										placeholder="DD/MM/YYYY"
										value={entradaManutencao}
										onChange={event =>
											setEntradaManutencao(
												event.target.value
											)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="saidaManutencao">
										<b>Saída da Manutenção</b>
									</label>
									<ReactInputMask
										mask="99/99/9999"
										type="text"
										name="saidaManutencao"
										id="saidaManutencao"
										className="form-control"
										placeholder="DD/MM/YYYY"
										value={saidaManutencao}
										onChange={event =>
											setSaidaManutencao(
												event.target.value
											)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="localManutencao">
										<b>Local da Manutenção</b>
									</label>
									<input
										type="text"
										className="form-control"
										id="localManutencao"
										placeholder="Ex: Oficina X"
										value={localManutencao}
										onChange={event =>
											setLocalManutencao(
												event.target.value
											)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="responsavel">
										<b>Responsável</b>
									</label>
									<input
										type="text"
										className="form-control"
										id="responsavel"
										placeholder="Nome do Responsável que levou o veículo"
										value={responsavel}
										onChange={event =>
											setResponsavel(event.target.value)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="quilometragemEntrada">
										<b>Quilometragem entrada</b>
									</label>
									<ReactInputMask
										mask="999999"
										size={6}
										type="text"
										name="quilometragemEntrada"
										id="quilometragemEntrada"
										className="form-control"
										placeholder="999999"
										value={quilometragemEntrada}
										onChange={event =>
											setQuilometragemEntrada(
												event.target.value
											)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="quilometragemSaida">
										<b>Quilometragem Saída</b>
									</label>
									<ReactInputMask
										mask="999999"
										size={6}
										type="text"
										name="quilometragemSaida"
										id="quilometragemSaida"
										className="form-control"
										placeholder="999999"
										value={quilometragemSaida}
										onChange={event =>
											setQuilometragemSaida(
												event.target.value
											)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-3 col-lg-3 form-group text-left">
									<label htmlFor="pecaNova">
										<b>Peça nova</b>
									</label>
									<select
										required
										className="form-control"
										id="pecaNova"
										value={pecaNova}
										onChange={event =>
											setPecaNova(event.target.value)
										}
									>
										<option value="0">Não</option>
										<option value="1">Sim</option>
									</select>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group text-left">
									<label htmlFor="valorManutencao">
										<b>Valor Manutenção</b>
									</label>
									<input
										id="valorManutencao"
										type="text"
										className="form-control"
										required
										placeholder="1000.00"
										pattern={regex.PRICE}
										value={valorManutencao}
										onChange={event =>
											setValorManutencao(
												event.target.value.replace(
													',',
													'.'
												)
											)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="tipoServico">
										<b>Tipo de Serviço</b>
									</label>
									<SelectServicosMecanico
										valueSelect={idServicoMecanico}
										setServiceMecanico={
											setIdServicoMecanico
										}
									/>
								</div>
							</div>
							<div className="form-group">
								<label htmlFor="descricao">
									<b>Descrição</b>
								</label>
								<textarea
									type="text"
									className="form-control"
									required
									id="descricao"
									placeholder="Descrição"
									value={descricao}
									onChange={event =>
										setDescricao(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-lg-4 col-md-4 col-12 form-group">
									<button
										className="btn buttonCustomSemec"
										type="submit"
									>
										Cadastrar
									</button>
								</div>
								<div className="col-lg-4 col-md-4 col-12 form-group">
									<button
										className="btn buttonCustomCancelSemec"
										type="button"
										onClick={handleGoBack}
									>
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</>
	);
}
