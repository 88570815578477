import React, { useState, Fragment, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function CardStudents({
	groupStudents,
	students,
	setStudents,
	setStudentsToRemove,
	setStudentsToAdd,
}) {
	const [listStudents, setListStudents] = useState([]);
	const [arrayToRemoveStudents, setArrayToRemoveStudents] = useState([]);
	const [newGroupStudents, setGroupStudents] = useState(groupStudents);
	const [inputFields, setInputFields] = useState([
		{ idStudents: '', embarque: '', destino: '' },
	]);

	function loadStudents() {
		api.get('/aluno/lista')
			.then(response => response.data.data)
			.then(setListStudents)
			.catch(() => setListStudents([]));
	}

	useEffect(() => {
		loadStudents();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ idStudents: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
		setStudentsToAdd(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'idStudents')
			values[index].idStudents = event.target.value;
		if (event.target.name === 'embarque')
			values[index].embarque = event.target.value;
		if (event.target.name === 'destino')
			values[index].destino = event.target.value;
		setInputFields(values);
		setStudentsToAdd(values);
	};

	async function removeStudent(e) {
		const updateEmployees = newGroupStudents.filter(
			s => s.user.id_usuario !== e.id_usuario
		);
		const updateStudents = students.filter(s => s !== e.id_usuario);
		if (!updateEmployees.length)
			swal(
				'ATENÇÃO',
				'Rota de Transporte não pode ficar sem aluno.',
				'warning'
			);
		setArrayToRemoveStudents([...arrayToRemoveStudents, e.id_usuario]);
		setStudentsToRemove([...arrayToRemoveStudents, e.id_usuario]);
		setGroupStudents(updateEmployees);
		setStudents(updateStudents);
	}

	return (
		<>
			{newGroupStudents.map(student => (
				<>
					<div className="row">
						<div className="mt-2 form-group col-12 col-lg-10 col-md-10">
							<p>
								<b>Aluno: </b>
								{student.user.nome_usuario} {' - '}
								<b>RA: </b>
								{student.user.num_ra}
							</p>
						</div>
						<div className="form-group col-12 col-lg-2 col-md-2">
							<button
								className="btn btn-warning"
								type="button"
								onClick={() => removeStudent(student.user)}
							>
								Remover
							</button>
						</div>
					</div>
					<hr />
				</>
			))}
			<div className="row">
				{inputFields.map((inputField, index) => (
					<Fragment
						// eslint-disable-next-line react/no-array-index-key
						key={`${inputField}~${index}`}
					>
						<div className="form-group col-12 col-lg-4 col-md-4">
							<select
								name="idStudents"
								className="form-control"
								id="idStudents"
								onChange={event =>
									handleInputChange(index, event)
								}
							>
								<option value="">Escolha um Aluno</option>
								{listStudents
									? listStudents.map(student => (
											<option
												key={student.id_usuario}
												value={student.id_usuario}
											>
												{student.nome_usuario} -{' '}
												{student.num_ra}
											</option>
									  ))
									: null}
							</select>
						</div>
						<div className="form-group col-12 col-lg-3 col-md-3">
							<input
								type="text"
								name="embarque"
								id="embarque"
								className="form-control"
								placeholder="Embarque"
								onChange={event =>
									handleInputChange(index, event)
								}
							/>
						</div>
						<div className="form-group col-12 col-lg-3 col-md-3">
							<input
								type="text"
								name="destino"
								id="destino"
								className="form-control"
								placeholder="Destino"
								onChange={event =>
									handleInputChange(index, event)
								}
							/>
						</div>
						<div className="form-group col-1 col-lg-1 col-md-1">
							{inputFields.length > 1 && (
								<button
									className="btn btn-danger formRouteStudentsAddRemoveButtons"
									type="button"
									onClick={() => handleRemoveFields(index)}
								>
									<FaMinus fill="#ffffff" />
								</button>
							)}
						</div>
						<div className="form-group col-1 col-lg-1 col-md-1">
							<button
								className="btn btn-success formRouteStudentsAddRemoveButtons"
								type="button"
								onClick={() => handleAddFields()}
							>
								<FaPlus fill="#ffffff" />
							</button>
						</div>
					</Fragment>
				))}
			</div>
		</>
	);
}
