/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import Breadcrumb from '../../../components/Breadcrumb';
import Day from '../../../components/Day';
import Bookloading from '../../../components/Loading/Bookloading';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import ScrollToTop from '../../../components/buttom/ScrollToTop';

export default function Index({ history }) {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumb = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.STUDENT_ATTENDANCE,
			namePage: 'Lista de Presença',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [schools, setSchools] = useState([]);
	const [schoolId, setSchoolId] = useState(null);
	const [subjects, setSubjects] = useState([]);
	const [subjectId, setSubjectId] = useState(null);
	const [students, setStudents] = useState([]);
	const [studentNotAttendance, setStudentNotAttendance] = useState([]);
	const [classes, setClasses] = useState([]);
	const [classId, setClassId] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledContent, setDisabledContent] = useState(true);
	const [disabledBtnStudents, setDisabledBtnStudents] = useState(true);
	const [isVisible, setIsVisible] = useState(false);
	const [disabledClass, setDisabledClass] = useState(true);
	const [searchStudent, setSearchStudent] = useState(false);
	const [lblConteudo, setlblConteudo] = useState('');

	useEffect(() => {
		(async function() {
			const dataPost = {
				typeUser: userActualType,
			};
			const response = await api.post('/usuario/escola', dataPost);
			if (!response.data.error) setSchools(response.data.data);
			else setSchools([]);
		})();
	}, []);

	useEffect(() => {
		if (schoolId)
			(async function() {
				const dataPost = {
					schoolId,
					typeUser: userActualType,
				};
				const response = await api.post(
					'/usuario/escola/classe',
					dataPost
				);
				if (response.data.error) {
					setClasses([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClasses(response.data.data);
				}
			})();
	}, [schoolId]);

	useEffect(() => {
		(async function() {
			if (classId) {
				const dataPost = {
					classId,
					schoolId,
					typeUser: userActualType,
				};
				const response = await api.post(
					'/usuario/escola/classe/disciplinas',
					dataPost
				);
				if (response.data.error) {
					setSubjects([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjects(response.data.data);
				}
			}
		})();
	}, [classId]);

	useEffect(() => {
		(async function() {
			if (classId > 0) setDisabledContent(false);
			else if (classId === 0 && subjectId > 0) setDisabledContent(true);
		})();
	}, [classId]);

	async function searchStudents(event) {
		event.preventDefault();
		setTimeout(setIsVisible(true), 3000);
		(async function() {
			const dataPost = {
				classId,
				schoolId,
				subjectId,
				typeUser: userActualType,
			};
			const response = await api.post(
				'/usuario/escola/classe/disciplinas/alunos',
				dataPost
			);
			if (!response.data.error) {
				setSearchStudent(true);
				setIsVisible(false);
				setDisabledBtnStudents(false);
				setStudents(response.data.data);
			} else {
				setStudents([]);
				setSearchStudent(false);
				setIsVisible(false);
				setDisabledBtnStudents(true);
				setStudents([]);
			}
		})();
	}

	async function checkBox(e) {
		if (studentNotAttendance.indexOf(e) > -1) {
			const index = studentNotAttendance.indexOf(e);
			if (index !== -1) {
				studentNotAttendance.splice(index, 1);
				setStudentNotAttendance(studentNotAttendance);
			}
		} else studentNotAttendance.push(e);
	}

	async function confirmNotAttendance(event) {
		event.preventDefault();
		const data = {
			alunoIDs: studentNotAttendance,
			conteudoAula: lblConteudo,
			disciplinaID: subjectId,
			classeID: classId,
		};
		const response = await api.post('/professor/alunos-presentes', data);
		if (!response.data.error) {
			swal('SUCESSO!', `${response.data.message}`, 'success');
			setTimeout(
				() =>
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.HOME
					),
				500
			);
		} else swal('Ops!', `${response.data.message}`, 'warning');
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumb} />
			<div className="card mb-4">
				<div className="card-body">
					<div className="row mb-3">
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="schools">
								<b>Escola</b>
							</label>
							<select
								className="form-control"
								name="schools"
								id="schools"
								onChange={event =>
									setSchoolId(event.target.value)
								}
							>
								<option value="0">Escolha a Escola</option>
								{schools.map(schools => (
									<option
										key={schools.id_escola}
										value={schools.id_escola}
									>
										{schools.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="classes">
								<b>Série/Turma</b>
							</label>
							<select
								id="classes"
								className="form-control"
								onChange={event =>
									setClassId(event.target.value)
								}
								disabled={disabledClass}
							>
								<option value="0">Escolha a série</option>
								{classes.map(classes => (
									<option
										key={classes.id_classe}
										value={classes.id_classe}
									>
										{classes.desc_serie_ano} -{' '}
										{classes.turma}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="subjectTeacher">
								<b>Disciplina</b>
							</label>
							<select
								className="form-control"
								name="subjectTeacher"
								id="subjectTeacher"
								onChange={event =>
									setSubjectId(event.target.value)
								}
								disabled={disabledSubjects}
							>
								<option value="0">Escolha a disciplina</option>
								{subjects.map(subjects => (
									<option
										key={subjects.id_disciplina}
										value={subjects.id_disciplina}
									>
										{subjects.nome_disciplina}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col-lg-6 col-md-10 col-12 form-group">
							<button
								type="button"
								className="btn btn-block btn-success"
								onClick={searchStudents}
								disabled={disabledContent}
							>
								<FaSearch className="searchIcon" />
								Buscar Alunos
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={confirmNotAttendance}>
						<div className="table-responsive">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">Aluno</th>
										<th scope="col" className="text-center">
											<Day />
										</th>
									</tr>
								</thead>
								{isVisible === true && (
									<Bookloading
										isLoading
										style={{
											marginTop: '-250px',
										}}
									/>
								)}
								{isVisible === false && (
									<tbody className="tableListStudent">
										{students.map(student => (
											<tr key={student.id_usuario}>
												<th>{student.nome_usuario}</th>
												<th>
													<input
														type="checkbox"
														name="attendanceStudent"
														id="attendanceStudent"
														className="form-control checkAttendance"
														value={
															student.id_usuario
														}
														onChange={event =>
															checkBox(
																event.target
																	.value
															)
														}
													/>
												</th>
											</tr>
										))}
									</tbody>
								)}
							</table>
							<hr />
						</div>
						{searchStudent === true ? (
							<div className="form-group">
								<p>
									<b>Conteúdo dado em sala de Aula:</b>
								</p>
								<textarea
									required
									className="form-control"
									id="text-conteudo"
									rows="3"
									onChange={event =>
										setlblConteudo(event.target.value)
									}
								/>
							</div>
						) : null}
						<div className="row justify-content-center text-center">
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-block btn-primary"
									disabled={disabledBtnStudents}
								>
									Confirmar
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
