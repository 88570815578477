export default {
	LOGIN: '/',
	FORGOT_PASSWORD: '/esqueceusenha',
	UPDATE_PASSWORD: '/atualizarsenha',
	VERIFY_CODE: '/verificacodigo',
	LOGGED_ROUTES: (baseUrl = null) => ({
		INDEX: '/',
		HOME: baseUrl,
		DASH: `${baseUrl}/dash`,
		REPORT_CARD: `${baseUrl}/boletim`,
		LIBRARY: `${baseUrl}/biblioteca`,
		COMMUNICATED: `${baseUrl}/comunicados`,
		READ_COMMUNICATED_RECEIVE: `${baseUrl}/comunicados/visualizar/recebido`,
		READ_NOTICES: `${baseUrl}/noticias/visualizar`,
		PROGRAMMATIC_CONTENT: `${baseUrl}/conteudos`,
		// TODO essa route não está sendo usado atualmente
		// PROGRAMMATIC_CONTENT_INFO: `${baseUrl}/conteudos/informacoes`,
		PROGRAMMATIC_CONTENT_CLASSES: `${baseUrl}/conteudos/aulas`,
		STUDENTS: `${baseUrl}/alunos`,
		SCHOOLS: `${baseUrl}/escolas`,
		ATTENDANCE: `${baseUrl}/frequencia`,
		COURSE_CONTENT: `${baseUrl}/conteudodisciplina`,
		LACK: `${baseUrl}/faltas`,
		STUDENT_ATTENDANCE: `${baseUrl}/chamada`,
		CORRECT_ATTENDANCE: `${baseUrl}/corrigir-falta`,
		COURSES: `${baseUrl}/disciplinas`,
		GRADE: `${baseUrl}/notas`,
		POSTNOTES: `${baseUrl}/lancar-notas`,
		WRITE_COMMUNICATED: `${baseUrl}/comunicados/escrever`,
		READ_COMMUNICATED_SEND: `${baseUrl}/comunicados/visualizar/enviado`,
		REPROVED_LIST: `${baseUrl}/alunos-reprovados`,
		REPROVE: `${baseUrl}/reprovar-aluno`,
		INFO_STUDENTS: `${baseUrl}/alunos/informacao`,
		PROFILE_STUDENT: `${baseUrl}/alunos/editar`,
		PROFILE_TEACHER: `${baseUrl}/professores/perfil`,
		TRANSFER_STUDENT: `${baseUrl}/alunos/transferencia`,
		INFO_CLASS_TRANSFER_STUDENT: `${baseUrl}/alunos/transferencia-classe`,
		REGISTER_STUDENT: `${baseUrl}/alunos/cadastro`,
		INSCRIBER_STUDENT: `${baseUrl}/alunos/inscrever`,
		SUBSCRIPTION_STUDENTS: `${baseUrl}/alunos/matricula`,
		CLAIM_DEATH_STUDENTS: `${baseUrl}/alunos/obito`,
		INFO_SCHOOL: `${baseUrl}/escola`,
		LIST_CLASSES: `${baseUrl}/escola/classes`,
		INFO_CLASS: `${baseUrl}/escola/classes/info`,
		LIST_SERIES: `${baseUrl}/escola/series`,
		INFO_SERIES: `${baseUrl}/escola/series/info`,
		OCCURRENCES: `${baseUrl}/ocorrencias`,
		MESSAGES: `${baseUrl}/ocorrencias/mensagens`,
		LIST_TEACHERS: `${baseUrl}/professores`,
		LIST_PARENTS: `${baseUrl}/responsaveis`,
		REGISTER_PARENTS: `${baseUrl}/responsaveis/cadastro`,
		DISASSOCIATE_PARENT: `${baseUrl}/responsaveis/desassociar`,
		NOTICES: `${baseUrl}/noticias`,
		LIST_NOTICES: `${baseUrl}/noticias/lista`,
		EDIT_NOTICES: `${baseUrl}/noticias/lista/corrigir`,
		COORDINATOR: `${baseUrl}/coordenadores`,
		ADD_COORDINATOR: `${baseUrl}/coordenadores/adicionar`,
		INFO_COORDINATOR: `${baseUrl}/coordenadores/informacao`,
		SCHOOL_INFO: `${baseUrl}/escolas/informacao`,
		STUDENT_INFO: `${baseUrl}/alunos/informacao`,
		TEACHERS: `${baseUrl}/professores`,
		PARENTS: `${baseUrl}/responsaveis`,
		TRANSPORT_ONLY_GOING: `${baseUrl}/transporte/somente-ida`,
		TRANSPORT_GOING_BACK: `${baseUrl}/transporte/ida-e-volta`,
		LIST_EMPLOYEE: `${baseUrl}/funcionarios`,
		REGISTER_EMPLOYEE: `${baseUrl}/funcionarios/registro`,
		INFO_EMPLOYEE: `${baseUrl}/funcionarios/informacao`,
		INFO_EMPLOYEE_PASSWORD: `${baseUrl}/funcionarios/informacao/senha`,
		MENU: `${baseUrl}/cardapio`,
		MENU_ADD: `${baseUrl}/cardapio/adicionar`,
		MENU_EDIT: `${baseUrl}/cardapio/editar`,
		PRODUCTS: `${baseUrl}/produtos`,
		ADD_PRODUCTS: `${baseUrl}/produtos/adicionar`,
		EDIT_PRODUCTS: `${baseUrl}/produtos/editar`,
		EDIT_PRODUCTS_ADD: `${baseUrl}/produtos/editar/adicionar`,
		EDIT_PRODUCTS_REMOVE: `${baseUrl}/produtos/editar/remover`,
		PROVIDERS: `${baseUrl}/fornecedores`,
		ADD_PROVIDERS: `${baseUrl}/fornecedores/adicionar`,
		EDIT_PROVIDERS: `${baseUrl}/fornecedores/editar`,
		RECORDS_INPUTS: `${baseUrl}/registros/entradas`,
		RECORDS_OUTPUTS: `${baseUrl}/registros/saidas`,
		SHOPPING_LIST: `${baseUrl}/lista-de-compras`,
		SHOPPING_LIST_ADD: `${baseUrl}/lista-de-compras/adicionar`,
		TICKETS: `${baseUrl}/fichas`,
		COMMITMENTS: `${baseUrl}/empenhos`,
		NUMERATION: `${baseUrl}/numeracao`,
		INFO_PROFILE: `${baseUrl}/perfil`,
		CHANGE_PASSWORD: `${baseUrl}/perfil/mudar-senha`,
		CHANGE_CONTACT: `${baseUrl}/perfil/mudar-contato`,
		LIST_CLASSES_COORDINATOR: `${baseUrl}/classes`,
		LIST_DRIVER: `${baseUrl}/motorista`,
		ADD_DRIVER: `${baseUrl}/motorista/cadastro`,
		UPDATE_DRIVER: `${baseUrl}/motorista/atualiza`,
		INFO_DRIVER: `${baseUrl}/motorista/informacao`,
		RECLASSIFY_STUDENT: `${baseUrl}/alunos/notas-baixas`,
		LIST_RECLASSIFY_STUDENT: `${baseUrl}/alunos/reclassificar`,
		REARRANGE_STUDENT: `${baseUrl}/alunos/perfil/remanejar`,
		LIST_MONITOR: `${baseUrl}/monitor`,
		ADD_MONITOR: `${baseUrl}/monitor/cadastrar`,
		UPDATE_MONITOR: `${baseUrl}/monitor/atualizar`,
		LIST_STUTENTS_HAS_MONITOR: `${baseUrl}/monitor/lista-alunos`,
		LIST_MONITOR_HAS_STUDENTS: `${baseUrl}/monitor/lista-alunos`,
		CREATE_LIST_MONITOR_HAS_STUDENTS: `${baseUrl}/monitor/lista-alunos/criar`,
		UPDATE_LIST_MONITOR_HAS_STUDENTS: `${baseUrl}/monitor/lista-alunos/atualizar`,
		LIST_ROUTE_TRANSPORT: `${baseUrl}/transporte`,
		ADD_ROUTE_TRANSPORT: `${baseUrl}/transporte/cadastro`,
		INFO_ROUTE_TRANSPORT: `${baseUrl}/transporte/informacao`,
		UPDATE_ROUTE_TRANSPORT: `${baseUrl}/transporte/atualizacao`,
		STUDENT_ITINERARY: `${baseUrl}/transporte/itinerario-aluno`,
		LIST_VEHICLE: `${baseUrl}/transporte/veiculos`,
		ADD_VEHICLE: `${baseUrl}/transporte/veiculos/cadastro`,
		INFO_VEHICLE: `${baseUrl}/transporte/veiculos/informacao`,
		ADD_MAINTENANCE: `${baseUrl}/transporte/veiculos/informacao/manutencao`,
		INFO_MAINTENANCE: `${baseUrl}/transporte/veiculos/informacao/manutencao/informacao`,
		UPDATE_MAINTENANCE: `${baseUrl}/transporte/veiculos/informacao/manutencao/editar`,
		UPDATE_VEHICLE: `${baseUrl}/transporte/veiculos/atualizar`,
		ATTRIBUTION: `${baseUrl}/atribuicao`,
		LIST_ATTRIBUTION: `${baseUrl}/atribuicao/lista`,
		INFO_ATTRIBUTION: `${baseUrl}/atribuicao/informacao`,
		FILES_ATTRIBUTION: `${baseUrl}/atribuicao/arquivos`,
		ADD_FILES_ATTRIBUTION: `${baseUrl}/atribuicao/arquivos/cadastrar`,
		ADD_COMMUNICATED_MESSAGE: `${baseUrl}/comunicados/adicionar/comentario`,
		LIST_TICKETS: `${baseUrl}/chamados`,
		VIEW_TICKET: `${baseUrl}/chamados/ver-chamado`,
		CREATE_TICKET: `${baseUrl}/chamados/novo-chamado`,
		LIST_SUPPLIES: `${baseUrl}/materiais`,
		VIEW_SUPPLIES: `${baseUrl}/materiais/informacao`,
		CREATE_SUPPLIES: `${baseUrl}/materiais/adicionar`,
		CLOCK_POINTS: `${baseUrl}/relogio-ponto`,
		CREATE_EVALUATION: `${baseUrl}/create-avaliacoes`,
		EVALUATION_LIST: `${baseUrl}/lista-avaliacoes`,
		REVISE_EVALUATION: `${baseUrl}/revisar-avaliacao`,
		EVALUATION: `${baseUrl}/avaliacao`,
		NURSERY_VACANCIES: `${baseUrl}/vagas-em-creches`,
		NURSERY_VACANCIES_DETAILS: `${baseUrl}/vagas-em-creches/`,
		NURSERY_VACANCIES_REPORT: `${baseUrl}/relatorio`,
		CHILDHOOD_EDUCATION_VACANCIES: `${baseUrl}/vagas-em-escolas`,
	}),
};
