import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import './style.css';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import regex from '../../../helpers/regex';

export default function Add() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [providers, setProviders] = useState([]);
	const [name, setName] = useState('');
	const [price, setPrice] = useState(null);
	const [percapita, setPercapita] = useState(null);
	const [percapitaUnit, setPercapitaUnit] = useState('');
	const [allUnitOfMeasurement, setAllUnitOfMeasurement] = useState([]);
	const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
	const [quantity, setQuantity] = useState(null);
	const [providerID, setProviderID] = useState('');
	const formElement = useRef(null);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PRODUCTS,
			namePage: 'Produtos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	function clearForm() {
		formElement.current.reset();
		setName('');
		setPrice(null);
		setPercapita(null);
		setPercapitaUnit('');
		setQuantity(null);
		setUnitOfMeasurement('');
		setProviderID('');
	}

	async function getProviders() {
		const response = await api.get('/cozinha-piloto/fornecedores');

		!response.data.error
			? setProviders(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function getAllUnitOfMeasurement() {
		const response = await api.get('/cozinha-piloto/unidades-de-medida');

		!response.data.error
			? setAllUnitOfMeasurement(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function addProduct(event) {
		event.preventDefault();
		if (providerID === 0 || providerID === '')
			return swal('ERROR!', 'Selecione um Fornecedor', 'error');
		const response = await api.post('/cozinha-piloto/produtos/adicionar', {
			nome: name,
			preco: price,
			unidade_medida: unitOfMeasurement,
			per_capita: percapita,
			per_capita_unidade: percapitaUnit,
			quantidade: quantity,
			id_fornecedor: providerID,
		});

		if (!response.data.error) {
			swal('SUCESSO!', 'Produto adicionado com sucesso', 'success');
			clearForm();
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	useEffect(() => {
		getProviders();
		getAllUnitOfMeasurement();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={addProduct} ref={formElement}>
				<div className="form-group">
					<label
						htmlFor="nameInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Nome
					</label>
					<input
						id="nameInputKitchenAddProduct"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_LETTERS}
						value={name}
						onChange={event => setName(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="unitOfMeasurementInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Unidade de Medida
					</label>
					<select
						className="form-control"
						id="unitOfMeasurementInputKitchenAddProduct"
						required
						value={unitOfMeasurement}
						onChange={item =>
							setUnitOfMeasurement(item.target.value)
						}
					>
						<option value="">Escolha um Unidade</option>
						{allUnitOfMeasurement.map(item => (
							<option value={item.id_unidade_medida}>
								{item.nome}
							</option>
						))}
					</select>
				</div>
				<div className="form-group">
					<label
						htmlFor="priceInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Preço
					</label>
					<input
						id="priceInputKitchenAddProduct"
						type="text"
						className="form-control"
						required
						pattern={regex.PRICE}
						value={price}
						onChange={event =>
							setPrice(event.target.value.replace(',', '.'))
						}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="percapitaInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Per Capita
					</label>
					<input
						id="percapitaInputKitchenAddProduct"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_NUMBERS}
						value={percapita}
						onChange={event => setPercapita(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="percapitaUnitInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Per Capita Unidade
					</label>
					<select
						className="form-control"
						id="percapitaUnitInputKitchenAddProduct"
						required
						value={percapitaUnit}
						onChange={item => setPercapitaUnit(item.target.value)}
					>
						<option value="">Escolha um Unidade</option>
						{allUnitOfMeasurement.map(item => (
							<option value={item.id_unidade_medida}>
								{item.nome}
							</option>
						))}
					</select>
				</div>
				<div className="form-group">
					<label
						htmlFor="quantityInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Quantidade
					</label>
					<input
						id="quantityInputKitchenAddProduct"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_NUMBERS}
						value={quantity}
						onChange={event => setQuantity(event.target.value)}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="providersInputKitchenAddProduct"
						className="labelInputTitle"
					>
						Selecione o fornecedor:
					</label>
					<select
						className="form-control"
						id="providersInputKitchenAddProduct"
						required
						value={providerID}
						onChange={item => setProviderID(item.target.value)}
					>
						<option value="">Escolha um Fornecedor</option>
						{providers
							? providers.map(item => (
									<option value={item.id_fornecedor}>
										{item.nome}
									</option>
							  ))
							: ''}
					</select>
				</div>
				<button type="submit" className="btn btn-primary">
					Adicionar
				</button>
			</form>
		</>
	);
}
