import React, { useState } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function AddMessage({ occurrenceId, loadMessages }) {
	const [message, setMessage] = useState('');
	const [disableButton, setDisalbeButton] = useState(false);
	const [textButton, setTextButton] = useState('Adicionar');

	async function sendNewMessage() {
		setTextButton('Carregando...');
		setDisalbeButton(true);
		try {
			await api.post('/ocorrencia/adicionar-mensagem', {
				ocorrencia_id: occurrenceId,
				mensagem: message,
			});
			loadMessages();
			setMessage('');
			swal('Mensagem salva corretamente.');
		} catch (error) {
			swal('Houve um erro ao adicionar a mensagem.');
		}
		setTextButton('Adicionar');
		setDisalbeButton(false);
	}

	return (
		<>
			<button
				type="button"
				className="btn btn-primary"
				data-toggle="modal"
				data-target="#addMessageModal"
			>
				Adicionar Mensagem
			</button>

			<div className="modal fade" id="addMessageModal">
				<div
					className="modal-dialog modal-dialog-scrollable"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Adicionar Mensagem</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body">
							<div className="form-group text-left">
								<label htmlFor="addMessageModal_message">
									Mensagem:
								</label>
								<textarea
									className="form-control"
									id="addMessageModal_message"
									rows="8"
									value={message}
									onChange={event =>
										setMessage(event.target.value)
									}
								/>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
							{message === '' ? (
								<button
									type="button"
									className="btn btn-disabled"
									disabled
								>
									Adicionar
								</button>
							) : (
								<button
									type="button"
									disabled={disableButton}
									className="btn btn-primary"
									onClick={sendNewMessage}
								>
									{textButton}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
