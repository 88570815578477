import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import ListEmployees from '../../components/CardsEmployees/ListEmployees';
import Panel from '../../components/Panel';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function ListEmployee() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista Funcionários ',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return <ListEmployees breadCrumbItems={breadCrumbItems} />
}
