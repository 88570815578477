import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

export default function NewHeader({ headerItems }) {
	return (
		<div className="pt-5 mt-3 justify-content-center">
			<div className="label-cidade-home">
				<p>
					<b>Sistema de Gestão Escolar |</b> Cruzeiro/SP
				</p>
			</div>
			<div className="row">
				{headerItems.map(
					({
						headerName,
						headerLabel,
						headerIcon,
						headerNumber,
						headerColSize,
						headerLinkName,
						iconBackGroundColor,
						iconTextColor,
					}) => (
						<div className={headerColSize} key={headerName}>
							<div className="card">
								<div className="card-body">
									<Link to={headerLinkName}>
										<div className="row">
											<div className="col-lg-3 col-5 headerDashCards">
												<div
													className={
														iconBackGroundColor
													}
												>
													<div
														className={
															iconTextColor
														}
													>
														<img
															src={headerIcon}
															className="logoSchool"
															alt="Logo from school"
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-9 col-7 justify-content-start">
												<div className="content">
													<p className="item-number">
														{headerNumber}
													</p>
													<p className="item-title">
														{headerLabel}
													</p>
												</div>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
					)
				)}
			</div>
		</div>
	);
}
