import React, { useState, useEffect } from 'react';
import { FaUserFriends, FaGraduationCap } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import './style.css';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import CardAttribution from '../../../components/Cards/CardAttribution';

export default function Card() {
	const [dependents, setDependents] = useState([]);
	const [grade, setGrade] = useState([]);
	const [faults, setFaults] = useState([]);
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		api.get('/responsavel')
			.then(response => {
				setDependents(response.data.data);
			})
			.catch(error => {
				console.log(`error: ${error}`);
			});

		api.get('/responsavel/faltas')
			.then(response => {
				let total = 0;
				if (!response.data.error)
					response.data.data.map(() => {
						total++;
					});

				setFaults(total);
			})
			.catch(error => {
				console.log(`error: ${error}`);
			});

		api.get('/responsavel/notas')
			.then(response => {
				if (!response.data.error) setGrade(response.data.data);
				else setGrade([]);
			})
			.catch(error => {
				console.log(`error: ${error}`);
			});
	}, []);
	return (
		<>
			<div className="container-fluid pt-3">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<div className="mt-5">
							{dependents.map(dependent => (
								<>
									<div className="card mt-5">
										<div className="card-body row">
											<div className="col-12 col-md-8 col-lg-8">
												<div className="d-flex flex-column">
													<p>
														<b>Série: </b>
														<span>
															{dependent.serie}
														</span>
													</p>
													<p>
														<b>RA: </b>
														<span>
															{dependent.ra}
														</span>
													</p>
													<p>
														<b>Nome: </b>
														<span>
															{dependent.nome}
														</span>
													</p>
													<p>
														<b>Escola: </b>
														<span>
															{dependent.escola}
														</span>
													</p>
												</div>
											</div>
											<div className="col-12 col-md-4 col-lg-4">
												<div className="row ">
													<div className="col-6 col-md-6 col-lg-6 d-flex flex-column">
														<div className="faults">
															<p>
																<b>Faltas</b>
															</p>
															<span>
																<FaUserFriends />
															</span>
															<p>{faults}</p>
															<Link
																to="/responsavel/faltas"
																className="details"
															>
																Detalhes
															</Link>
														</div>
													</div>
													<div className="col-6 col-md-6 col-lg-6 d-flex flex-column">
														<div className="faults">
															<p>
																<b>Notas</b>
															</p>
															<span>
																<FaGraduationCap />
															</span>
															<p>
																{grade
																	? grade.length
																	: 0}
															</p>
															<Link
																to={
																	Routes.LOGGED_ROUTES(
																		RouteByPermission[
																			userActualType
																		]
																	).GRADE
																}
																className="details"
															>
																Detalhes
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<CardAttribution idStudent={dependent.id} tittle={`Disciplinas do(a) ${dependent.nome}`} />
								</>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
