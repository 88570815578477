import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import TextAreaRead from '../../TextArea/TesteAreaRead';
import Breadcrumb from '../../Breadcrumb';
import api from '../../../services/api';
import './style.css';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function ReadNotice() {
	const history = useHistory();
	const { state } = useLocation();
	const [lblNotice, setlblNotice] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Ler Notícia',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone: 'UTC',
	};
	useEffect(() => {
		(async function() {
			const response = await api.get('/noticias/visualizar', {
				params: {
					idNotice:
						state &&
						state[RouteParams.COMMON_LEVELS.READ_NOTICES.NOTICE_ID],
				},
			});
			if (!response.data.error) {
				setlblNotice(response.data.data);
				setIsLoading(false);
			} else {
				swal('ATENÇÃO', 'Notícia Não Encontrada', 'warning');
				history.goBack();
			}
		})();
	}, []);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<div className="card read-notice-card">
						<div className="card-body">
							<div className="titulo-notice">
								<p>{lblNotice.titulo_noticia}</p>
							</div>
							<div className="data-notice">
								<p>
									Publicado em:{' '}
									{new Date(
										lblNotice.updated_at
									).toLocaleDateString('pt-BR', options)}
								</p>
							</div>
							<hr />
							<div className="descricao-noticia">
								<TextAreaRead
									lblComents={lblNotice.descricao_noticia}
								/>
							</div>
							<div className="data-notice">
								<p>
									Publicado por: {lblNotice.desc_nome_escola}
								</p>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
