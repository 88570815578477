import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../pages/Login';
import ForgotPassword from '../pages/Login/ForgotPassword';
import UpdatePassword from '../pages/Login/UpdatePassword';
import VerifyCode from '../pages/Login/VerifyCode';
import Routes from './data/Routes';
import Page404 from '../pages/404/NotFound';


export default function LoginRoutes() {
	return (
		<>
			<Switch>
				<Route exact path={Routes.LOGIN} component={Login} />
				<Route
					exact
					path={Routes.FORGOT_PASSWORD}
					component={ForgotPassword}
				/>
				<Route
					exact
					path={Routes.UPDATE_PASSWORD}
					component={UpdatePassword}
				/>
				<Route exact path={Routes.VERIFY_CODE} component={VerifyCode} />
				<Route path="*" component={Page404} />
			</Switch>
		</>
	);
}
