import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectOrgaoExpedidor({ setUf, valueSelect, disabled }) {
	const [uf, setlblUfMunNascto] = useState([]);

	useEffect(() => {
		async function loadUf() {
			const response = await api.get('/tabelas/uf');
			setlblUfMunNascto(response.data.data);
		}
		loadUf();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(uf) {
		setUf(uf);
	}
	const ufList = uf.map(U => (
		<option value={U.id_uf} key={U.id_uf}>
			{`SSP/${U.sigla_uf}`}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblIdUf"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Escolha um UF</option>
				{ufList}
			</select>
		</>
	);
}
