import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import ProfileEmployees from '../../components/CardsEmployees/ProfileEmployees';
import Panel from '../../components/Panel';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import ScrollToTop from '../../components/buttom/ScrollToTop';

export default function InfoEmployees() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_EMPLOYEE,
			namePage: 'Lista de Funcionarios',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informações do Funcionário',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return <ProfileEmployees breadCrumbItems={breadCrumbItems} />
}
