import React, { useState, useEffect } from 'react';
import Panel from '../../components/Panel';
import Grade from './GradeTable';
import api from '../../services/api';
import Footer from '../../components/Footer';
import './style.css';

export default function Dash() {
	const [grade, setGrade] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		api.get('/responsavel/notas')
			.then(response => {
				if (!response.data.error) {
					setGrade(response.data.data);
					setIsLoading(false);
				} else {
					setGrade([]);
					setIsLoading(false);
				}
			})
			.catch(error => {
				console.log(`error: ${error}`);
			});
	}, []);

	return <Grade grade={grade} isLoading={isLoading} />
}
