import React, { useState, Fragment, useEffect } from 'react';
import api from '../../services/api';

export default function CardSchool({ setInputField }) {
	const [school, setSchool] = useState([]);
	const [inputFields, setInputFields] = useState([
		{ escola: '', tipoUsuarios: '' },
	]);

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if(data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ escola: '', tipoUsuarios: '' });
		setInputFields(values);
		setInputField(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
		setInputField(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'escola')
			values[index].escola = event.target.value;
		else values[index].tipoUsuarios = event.target.value;
		setInputFields(values);
		setInputField(values);
	};

	return (
		<div className="card mt-3">
			<div className="card-header form-group">
				<h4>Escola(s)</h4>
			</div>
			<div className="card-body">
				<div className="row">
					{inputFields.map((inputField, index) => (
						<Fragment
							// eslint-disable-next-line react/no-array-index-key
							key={`${inputField}~${index}`}
						>
							<div className="form-group col-12 col-lg-4 col-md-4">
								<label htmlFor="escola">Escolas</label>
								<select
									name="escola"
									className="form-control"
									id="schoolID"
									onChange={event =>
										handleInputChange(index, event)
									}
								>
									<option>Escolha uma Escola</option>
									{school.map(schoolItem => (
										<option
											key={schoolItem.id_escola}
											value={schoolItem.id_escola}
										>
											{schoolItem.desc_nome_escola}
										</option>
									))}
								</select>
							</div>
							<div className="form-group col-12 col-lg-6 col-md-6">
								<label htmlFor="tipoUsuario">
									Tipo Usuario
								</label>
								<select
									name="tipoUsuario"
									className="form-control"
									id="tipoUsuario"
									onChange={event =>
										handleInputChange(index, event)
									}
								>
									<option>Escolha uma Escola</option>
									<option value={6}>Coordenador</option>
									<option value={7}>Professor</option>
								</select>
							</div>
							<div className="form-group col-sm-2">
								{inputFields.length > 1 && (
									<button
										className="btn btn-link"
										type="button"
										onClick={() =>
											handleRemoveFields(index)
										}
									>
										-
									</button>
								)}
								<button
									className="btn btn-link"
									type="button"
									onClick={() => handleAddFields()}
								>
									+
								</button>
							</div>
						</Fragment>
					))}
				</div>
			</div>
		</div>
	);
}
