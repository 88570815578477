import React, { useState } from 'react';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import SelectDrivers from '../../../components/Selects/SelectDrivers';
import Breadcrumb from '../../../components/Breadcrumb';
import CardMonitor from './CardMonitor';
import CardStudents from './CardStudents';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function FormRouteTransport() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_ROUTE_TRANSPORT,
			namePage: 'Rotas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cadastro Rota',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [isLoading, setIsLoading] = useState(false);
	const [alunosIds, setAlunoIds] = useState([]);
	const [motoristaId, setMotoristaId] = useState();
	const [monitoresIds, setMonitoresIds] = useState([]);
	const [kmIda, setKmIda] = useState();
	const [kmVolta, setKmVolta] = useState();
	const [horaIniIda, setHoraIniIda] = useState('');
	const [horaFimIda, setHoraFimIda] = useState('');
	const [horaIniVolta, setHoraIniVolta] = useState('');
	const [horaFimVolta, setHoraFimVolta] = useState('');
	const [dataTransporte, setDataTransporte] = useState('');
	const [ida, setIda] = useState(false);
	const [volta, setVolta] = useState(false);
	const [idTurno, setIdTurno] = useState();

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = {
				alunosIds,
				motoristaId,
				monitoresIds,
				kmIda,
				kmVolta,
				horaIniIda,
				horaFimIda,
				horaIniVolta,
				horaFimVolta,
				dataTransporte,
				ida,
				volta,
				idTurno,
			};
			await api
				.post('/transporte/rota/criar-rota', data)
				.then(response => {
					if (!response.data.error) {
						swal(response.data.message);
						history.goBack();
					} else swal(response.data.message);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<form onSubmit={handleSubmit}>
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Motorista e Monitor</h4>
						</div>
						<div className="card-body">
							<div className="form-group">
								<p>
									<b>Motorista</b>
								</p>
								<SelectDrivers
									disabled={false}
									valueSelect={motoristaId}
									setDriver={setMotoristaId}
								/>
							</div>
							<div className="row">
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>
										<b>Quilometragem Ida</b>
									</p>
									<input
										type="text"
										name="kmVolta"
										id="kmVolta"
										className="form-control"
										placeholder="Ex: 100.00"
										value={kmVolta}
										onChange={event =>
											setKmVolta(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>
										<b>Quilometragem Volta</b>
									</p>
									<input
										type="text"
										name="kmIda"
										id="kmIda"
										className="form-control"
										placeholder="Ex: 100.00"
										value={kmIda}
										onChange={event =>
											setKmIda(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-3 col-lg-3">
									<p>
										<b>Ida/Volta</b>
									</p>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<b>Ida: </b>
											<input
												checked={ida}
												type="checkbox"
												onChange={() => setIda(!ida)}
											/>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<b>Volta: </b>
											<input
												checked={volta}
												type="checkbox"
												onChange={() =>
													setVolta(!volta)
												}
											/>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-3 col-lg-3">
									<p>
										<b>Turno</b>
									</p>
									<select
										required
										className="form-control"
										name="idTurno"
										id="idTurno"
										value={idTurno}
										onChange={event =>
											setIdTurno(event.target.value)
										}
									>
										<option value="">
											Escolha um Turno
										</option>
										<option value="1">Manhã</option>
										<option value="2">Intermediário</option>
										<option value="3">Tarde</option>
										<option value="4">Vespertino</option>
										<option value="5">Noite</option>
										<option value="6">Integral</option>
									</select>
								</div>
							</div>
							<div className="row">
								{ida === true ? (
									<>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Hora inicio ida</b>
											</p>
											<InputMask
												mask="99/99/9999 99:99"
												size={12}
												type="text"
												name="horaIniIda"
												id="horaIniIda"
												className="form-control"
												placeholder="Data Transporte"
												value={horaIniIda}
												onChange={event =>
													setHoraIniIda(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Hora fim ida</b>
											</p>
											<InputMask
												mask="99/99/9999 99:99"
												size={12}
												type="text"
												name="horaFimIda"
												id="horaFimIda"
												className="form-control"
												placeholder="Data Transporte"
												value={horaFimIda}
												onChange={event =>
													setHoraFimIda(
														event.target.value
													)
												}
											/>
										</div>
									</>
								) : null}
								{volta === true ? (
									<>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Hora inicio volta</b>
											</p>
											<InputMask
												mask="99/99/9999 99:99"
												size={12}
												type="text"
												name="horaIniVolta"
												id="horaIniVolta"
												className="form-control"
												placeholder="Data Transporte"
												value={horaIniVolta}
												onChange={event =>
													setHoraIniVolta(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Hora fim volta</b>
											</p>
											<InputMask
												mask="99/99/9999 99:99"
												size={12}
												type="text"
												name="horaFimVolta"
												id="horaFimVolta"
												className="form-control"
												placeholder="Data Transporte"
												value={horaFimVolta}
												onChange={event =>
													setHoraFimVolta(
														event.target.value
													)
												}
											/>
										</div>
									</>
								) : null}
							</div>
							<div className="row form-group">
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>
										<b>Data para do Tansporte</b>
									</p>
									<InputMask
										mask="99/99/9999"
										required
										size={8}
										type="text"
										name="dataTransporte"
										id="dataTransporte"
										className="form-control"
										placeholder="Data Transporte"
										value={dataTransporte}
										onChange={event =>
											setDataTransporte(
												event.target.value
											)
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Monitor</h4>
						</div>
						<div className="card-body">
							<CardMonitor setMonitorsIds={setMonitoresIds} />
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Alunos</h4>
						</div>
						<div className="card-body">
							<CardStudents setStudents={setAlunoIds} />
						</div>
						<div className="card-footer">
							<div className="row justify-content-start">
								<div className="col-lg-4 col-md-5 col-7 form-group">
									<button
										className="btn btn-primary btn-block"
										type="submit"
									>
										Cadastrar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</>
	);
}
