import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import store from './redux/store';
import Routes from './routes';
import FunctionalitiesProvider from './components/Contenxt/Functionalities';
import './styles/colors.css';
import './styles/main.css';
import './styles/buttons.css';
import './styles/selects.css';
import './styles/inputsRadios.css';
import './styles/table.css';
import env from './env';

const history = createBrowserHistory();
history.listen(location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

function App() {
	return (
		<FunctionalitiesProvider>
			<Provider store={store}>
				<BrowserRouter basename={env.BASE_NAME}>
					<Routes history={history} />
				</BrowserRouter>
			</Provider>
		</FunctionalitiesProvider>
	);
}
export default App;
