/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../services/api';
import SelectDisciplinas from '../Selects/SelectDisciplinas';
import Table from '../Table';

export default function ListSubjects() {
	const [contents, setContents] = useState([]);
	const [typeTeaching, setTypeTeaching] = useState([]);
	const [disciplineIdSchool, setDisciplineIdSchool] = useState(null);
	const [serieId, setSerieId] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [message, setMessage] = useState('Escolha uma Disciplina.');
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		(async function() {
			if (disciplineIdSchool) {
				const response = await api.get(
					'/disciplinas/escolas/serie-ano',
					{
						params: {
							disciplineId: disciplineIdSchool,
						},
					}
				);
				setSerieId(null);
				setTypeTeaching([]);
				if (!response.data.error) {
					setSerieId(null);
					setDisabledSubjects(false);
					setTypeTeaching(response.data.data);
					setMessage('Escolha uma Série/Ano');
				} else {
					setSerieId();
					setTypeTeaching([]);
					setDisabledSubjects(true);
					swal({
						icon: 'warning',
						text: 'Não há conteúdos com essa disciplina no sistema',
					});
				}
			}
		})();
	}, [disciplineIdSchool]);

	useEffect(() => {
		(async function() {
			if (serieId) {
				const response = await api.get(
					'/disciplinas/escolas/conteudos',
					{
						params: {
							disciplineId: disciplineIdSchool,
							serieId,
						},
					}
				);
				if (!response.data.error) {
					const conteudo = await Promise.all(
						response.data.data.conteudo.map(async item => {
							if (userActualType === 7)
								item.youtubeButton = `${item.conteudo}`;
							else item.youtubeButton = '';
							return item;
						})
					);
					setContents(conteudo);
					setMessage(null);
				} else {
					setContents([]);
					swal({
						icon: 'warning',
						text: 'Sem conteúdos',
					});
				}
			}
		})();
	}, [disciplineIdSchool, serieId]);

	function ButtonYoutuber({ link }) {
		return (
			<a
				href={`https://www.youtube.com/results?search_query=${link}`}
				target="_blank"
				rel="noreferrer"
			>
				<i className="fa fa-youtube-play fa-2x" />
			</a>
		);
	}
	return (
		<>
			<>
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-12  col-md-6 col-lg-6">
								<div className="form-group">
									<label htmlFor="subjectsSchool">
										<b>Disciplina</b>
									</label>
									<SelectDisciplinas
										setDisciplina={setDisciplineIdSchool}
										valueSelect={disciplineIdSchool}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-6">
								<div className="form-group">
									<label htmlFor="subjectTeacher">
										<b>Série/Ano</b>
									</label>
									<select
										className="form-control"
										name="subjectTeacher"
										id="subjectTeacher"
										onChange={event =>
											setSerieId(event.target.value)
										}
										disabled={disabledSubjects}
									>
										<option value="">
											Escolha a Série/Ano
										</option>
										{typeTeaching.map(subjects => (
											<option
												key={subjects.id_serie_ano}
												value={subjects.id_serie_ano}
											>
												{subjects.desc_serie_ano}{' '}
												{subjects.desc_tipo_ensino}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<hr />
						{message ? (
							<span>{message}</span>
						) : (
							<>
								<Table
									header={[
										'Contéudo',
										'Descrição',
										userActualType === 7 && 'Recomendação',
									]}
									body={contents.map(content => {
										return [
											content.conteudo,
											content.descricao_conteudo,
											userActualType === 7 && (
												<ButtonYoutuber
													link={content.youtubeButton}
												/>
											),
										];
									})}
								/>
							</>
						)}
					</div>
				</div>
			</>
		</>
	);
}
