import React from 'react';
import Lottie from 'react-lottie';
import bookloading from '../../assets/lottiefiles/loading-book.json';

export default function Bookloading({ isLoading, children, ...props }) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: bookloading,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div {...props}>
			{isLoading ? <Lottie options={defaultOptions} /> : children}
		</div>
	);
}
