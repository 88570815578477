/*
	ActionTypes
*/
const ActionTypes = {
	UPDATE_CALENDAR_ITEM: 'calendarReducer/UPDATE_CALENDAR_ITEM',
	CLEAR_CALENDAR: 'calendarReducer/CLEAR_CALENDAR',
};

/*
	Reducer
*/
const initialState = [];

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.UPDATE_CALENDAR_ITEM:
			const matrix = [...state];

			if (!matrix[action.payload.line]) matrix[action.payload.line] = [];

			matrix[action.payload.line][action.payload.column] =
				action.payload.item;

			return matrix;
		case ActionTypes.CLEAR_CALENDAR:
			return initialState;
		default:
			return state;
	}
}

/*
	Actions
*/
export const CalendarActions = {
	/**
	 * @params
	 * item: number
	 * meal: string
	 * day: string
	 */
	updateCalendarItem: (item, line, column) => ({
		type: ActionTypes.UPDATE_CALENDAR_ITEM,
		payload: { item, line, column },
	}),

	clearCalendar: () => ({
		type: ActionTypes.CLEAR_CALENDAR,
		payload: null,
	}),
};
