import React, { useState, useEffect } from 'react';
import { FaCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import parseJwt from '../../../../helpers/parseJwt';
import api from '../../../../services/api';
import Breadcrumb from '../../../../components/Breadcrumb';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import Question from '../../Question';

import './styles.css';

function Main({ avaliacaoId, fim, moduloId }) {
	const [titulo, setTitulo] = useState('');
	const [descricao, setDescricao] = useState('');
	const [reloadPage, setReloadPage] = useState(false);

	const [questionIndex, setQuestionIndex] = useState(0);
	const [questoes, setQuestoes] = useState([]);
	const [respostas, setRespostas] = useState({});
	const [avaliacaoFeita, setAvaliacaoFeita] = useState([]);

	const [pontuacaoMaxima, setPontuacaoMaxima] = useState(0);
	const [pontuacaoTotal, setPontuacaoTotal] = useState(0);
	const [questoesCerta, setQuestoesCerta] = useState(0);
	const [totalQuestoes, setTotalQuestoes] = useState(0);

	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Avaliação',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const getDoneEvaluation = async () => {
		const { data } = await api.get(
			`/avaliacao/exibir-questoes-feita/${idUser}/${avaliacaoId}/${moduloId}`
		);

		if (data.data != null && data.data[0].length > 0) {
			setPontuacaoMaxima(data.data[1]['pontuacaoMaxima']);
			setPontuacaoTotal(data.data[1]['pontuacaoTotal']);
			setQuestoesCerta(data.data[1]['questoesCerta']);
			setTotalQuestoes(data.data[1]['totalQuestoes']);

			if (data.data[0].length > 0) setQuestoes(data.data[0]);

			setAvaliacaoFeita(data.data);
		} else {
			return;
		}
	};

	const getEvaluation = async () => {
		const { data } = await api.get(`/avaliacao/buscar/${avaliacaoId}`);

		setQuestoes(data.data.questoes.map(item => item));
		setTitulo(data.data.titulo);
		setDescricao(data.data.descricao);
	};

	const handleSubmit = async () => {
		const result = Object.values(respostas).map(item => item === '');
		const values = result.filter(item => item === false);

		const respostasProva = {
			modulo_id: moduloId,
			avaliacao_id: avaliacaoId,
			aluno_id: idUser,
			respostas: respostas,
		};

		if (questoes.length === values.length) {
			const value = await swal(
				'Todas questões respondidas',
				'Deseja modificar alguama resposta?',
				'success',
				{
					buttons: {
						cancelar: {
							text: 'Cancelar',
							value: false,
						},
						aceitar: {
							text: 'Enviar',
							value: true,
						},
					},
				}
			);

			if (value) {
				const { data } = await api.post(
					'/avaliacao/responder',
					respostasProva
				);
				if (data.error == true) {
					swal(
						'Aconteceu algo de errado',
						'Tente novamente',
						'error'
					);
					return;
				}
				setReloadPage(!reloadPage);
			} else {
				return;
			}
		} else {
			const value = await swal(
				'Alguma questao ficou sem resposta',
				'Responda todas questões!',
				'warning',
				{
					buttons: {
						cancelar: {
							text: 'Cancelar',
							value: false,
						},
						aceitar: {
							text: 'Aceitar',
							value: true,
						},
					},
				}
			);
			if (value) {
				return;
			} else {
				return;
			}
		}
	};

	useEffect(() => {
		getEvaluation();
		getDoneEvaluation();
	}, [reloadPage]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			<div className="card pl-3 pr-3">
				<div className="card-header row d-flex justify-content-between">
					<section>
						{titulo}
						<h6>{descricao}</h6>
					</section>
				</div>
			</div>

			{new Date() > new Date(fim) && avaliacaoFeita.length == 0 ? (
				<div className="card">
					<div className="card-body">Prova encerrada</div>
				</div>
			) : (
				<div className="RowBodyEvaluation">
					<div className="card mt-3" id="wrappQuestionsNumber">
						<div className="card-header">
							Navegação do questionário
						</div>

						<div id="questionsNumberContainer">
							{questoes &&
								questoes.map((item, index) => (
									<div
										key={index}
										className="questionsNumber"
										onClick={() => setQuestionIndex(index)}
									>
										<div className="cardNumber">
											<strong>
												{item.numero_questao}
											</strong>
											<div
												className="cardNumberBottom"
												style={{
													background:
														avaliacaoFeita.length >
														0
															? item.resultado ===
															  null
																? 'yellow'
																: item.resultado >
																  0
																? 'green'
																: 'red'
															: 'yellow',
												}}
											>
												<FaCircle
													size={10}
													color="white"
												/>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
					<div className="card mt-3" id="wrapperBodyEvaluation">
						{avaliacaoFeita.length > 0 ? (
							<table className="table table-bordered table-sm table-responsive-lg">
								<tbody>
									<tr>
										<th scope="row ">
											<strong>Estado</strong>
										</th>
										<td>Finalizada</td>
									</tr>

									<tr>
										<th scope="row">
											<strong>Concluída em</strong>
										</th>
										<td className="active">
											{avaliacaoFeita[0][0].created_at}
										</td>
									</tr>

									<tr>
										<th scope="row">
											<strong>Acertos</strong>
										</th>
										<td className="active">
											{questoesCerta} de {totalQuestoes}
										</td>
									</tr>

									<tr>
										<th scope="row">
											<strong>Pontuação</strong>
										</th>
										<td className="active">
											{pontuacaoTotal} de{' '}
											{pontuacaoMaxima}
										</td>
									</tr>
								</tbody>
							</table>
						) : null}
						{questoes && questoes[questionIndex] && (
							<Question
								questao={questoes[questionIndex]}
								respostaDefault={
									respostas[
										questoes[questionIndex].id_questao
									]
								}
								salvarResposta={resposta => {
									const newRespostas = {
										...respostas,
										[questoes[questionIndex]
											.id_questao]: resposta,
									};
									setRespostas(newRespostas);
								}}
							/>
						)}
						<div className="BottomButton">
							{avaliacaoFeita.length > 0
								? null
								: questoes.length - 1 === questionIndex && (
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => handleSubmit()}
										>
											Concluir prova
										</button>
								  )}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Main;
