import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function ProfileStudent() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Falecimento do Aluno',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblNomeAluno, setlblNomeAluno] = useState('');
	const [lblDataNascimentoAluno, setlblDataNascimentoAluno] = useState('');
	const [lblNomeMae, setlblNomeMae] = useState('');
	const [lblRaAluno, setlblRaAluno] = useState('');
	const [lblDigitoRA, setlblDigitoRA] = useState('');
	const [lblIdUfRA, setlblIdUfRA] = useState('');
	const [errorVerify, setErrorVerify] = useState('');
	const [lblDisableButton, setlblDisableButton] = useState(true);
	const [lblDataFalecimento, setlblDataFalecimento] = useState('');
	const [school, setSchool] = useState([]);
	const [schoolID, setSchoolID] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			setlblDisableButton(true);
		})();
	}, [lblNomeMae, lblNomeAluno, lblDataNascimentoAluno]);
	async function verifyStudent(event) {
		event.preventDefault();
		const { data } = await api.get('/aluno/verifica-aluno', {
			params: {
				escolaID: schoolID,
				lblNomeAluno,
				lblDataNascimentoAluno,
				lblNomeMae,
			},
		});
		if (data.error === false) {
			setlblRaAluno(data.data.aluno.num_ra);
			setlblDigitoRA(data.data.aluno.digito_ra);
			setlblIdUfRA(data.data.aluno.uf_id_uf);
			setlblDisableButton(false);
			setErrorVerify('');
		} else setErrorVerify(data.message);
	}
	async function handleSubmit(event) {
		setIsLoading(true);
		try {
			event.preventDefault();
			const data = new FormData();
			data.append('schoolId', schoolID);
			data.append('lblDataFalecimento', lblDataFalecimento);
			data.append('lblNomeMae', lblNomeMae);
			data.append('lblNomeAluno', lblNomeAluno);
			data.append('lblDataNascimentoAluno', lblDataNascimentoAluno);
			data.append('lblRaAluno', lblRaAluno);
			data.append('lblDigitoRA', lblDigitoRA);
			data.append('lblIdUfRA', lblIdUfRA);
			await api
				.post('/aluno/perfil/atualiza/falecimento', data)
				.then(response => {
					if (!response.data.error) {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						history.push(
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).HOME
						);
					} else swal('Ops!', `${response.data.message}`, 'warning');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={handleSubmit}>
				<div className="card mt-3">
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Dados Aluno</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-4 col-lg-4 col-12 form-group">
										<label htmlFor="school">
											<b>Escola</b>
										</label>
										<select
											id="school"
											className="form-control"
											onChange={event =>
												setSchoolID(event.target.value)
											}
										>
											<option value="">
												Escolha a escola
											</option>
											{school.map(schoolItem => (
												<option
													key={schoolItem.id_escola}
													value={schoolItem.id_escola}
												>
													{
														schoolItem.desc_nome_escola
													}
												</option>
											))}
										</select>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<label htmlFor="NomeAluno">
											<b>Nome Aluno</b>
										</label>
										<input
											onInput={toInputUppercase}
											required
											type="text"
											name="lblNomeAluno"
											id="lblNomeAluno"
											className="form-control"
											placeholder="Digite o nome do Aluno"
											value={lblNomeAluno}
											onChange={event =>
												setlblNomeAluno(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<label htmlFor="DataNascimento">
											<b>Data Nascimento Aluno</b>
										</label>
										<InputMask
											mask="99/99/9999"
											required
											type="text"
											name="lblDataNascimentoAluno"
											id="lblDataNascimentoAluno"
											className="form-control"
											placeholder="Data Nascimento do Aluno"
											value={lblDataNascimentoAluno}
											onChange={event =>
												setlblDataNascimentoAluno(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<label htmlFor="Mae">
											<b>Nome Mãe do Aluno</b>
										</label>
										<input
											onInput={toInputUppercase}
											type="text"
											name="lblNomeMae"
											id="lblNomeMae"
											className="form-control"
											placeholder="Nome da Mãe do Aluno"
											value={lblNomeMae}
											onChange={event =>
												setlblNomeMae(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<label htmlFor="Ra">
											<b>Registro do Aluno</b>
										</label>
										<input
											disabled
											type="text"
											name="lblRaAluno"
											id="lblRaAluno"
											className="form-control"
											placeholder="Registro Aluno"
											value={lblRaAluno}
											onChange={event =>
												setlblRaAluno(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<label htmlFor="DataFalecimento">
											<b>Data Falecimento</b>
										</label>
										<InputMask
											mask="99/99/9999"
											required
											type="text"
											name="lblDataFalecimento"
											id="lblDataFalecimento"
											className="form-control"
											placeholder="Data Falecimento"
											value={lblDataFalecimento}
											onChange={event =>
												setlblDataFalecimento(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-12">
										<span className="errorLogin">
											<small>{errorVerify}</small>
										</span>
									</div>
								</div>
							</div>
							<div className="card-footer d-flex justify-content-between">
								<div className="row">
									<div className="form-group col-12 col-md-6 col-lg-6">
										<button
											onClick={verifyStudent}
											className="btn btn-primary"
											type="button"
										>
											Verificar
										</button>
									</div>
									<div className="form-group col-12 col-md-6 col-lg-6">
										<button
											disabled={lblDisableButton}
											className="btn btn-primary"
											type="submit"
										>
											Confirmar
										</button>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</form>
		</>
	);
}
