import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
// MultiSelect
const useStyles = makeStyles(theme => ({
	formControl: {
		textAlign: 'center',
		margin: theme.spacing(1),
		minWidth: '95%',
		maxWidth: '95%',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const names = [
	'MULTIPLA',
	'CEGUEIRA',
	'BAIXA VISAO',
	'SURDEZ SEVERA OU PROFUNDA',
	'SURDEZ LEVE OU MODERADA',
	'SURDOCEGUEIRA',
	'FISICA - PARALISIA CEREBRAL',
	'FISICA - CADEIRANTE',
	'FISICA - OUTROS',
	'SINDROME DE DOWN',
	'INTELECTUAL',
	'AUTISTA INFANTIL',
	'SINDROME DE ASPERGER',
	'SINDROME DE RETT',
	'TRANSTORNO DESINTEGRATIVO DA INFANCIA',
	'ALTAS HABILIDADES/SUPERDOTACAO',
];

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function FormControlNecessidades({
	habilitar,
	setlblNomeNecessidade,
	lblNomeNecessidade,
}) {
	const classes = useStyles();
	const theme = useTheme();
	const [personName] = React.useState([]);

	async function handleSubmit(e) {
		setlblNomeNecessidade(e);
	}
	return (
		<>
			<FormControl className={classes.formControl}>
				<InputLabel id="demo-mutiple-chip-label">
					Necessidades Especiais
				</InputLabel>
				<Select
					disabled={habilitar}
					labelId="demo-mutiple-chip-label"
					id="demo-mutiple-chip"
					multiple
					value={lblNomeNecessidade}
					onChange={event => handleSubmit(event.target.value)}
					input={<Input id="select-multiple-chip" />}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected.map(value => (
								<Chip
									key={value}
									label={value}
									className={classes.chip}
								/>
							))}
						</div>
					)}
					MenuProps={MenuProps}
				>
					{names.map(name => (
						<MenuItem
							key={name}
							value={name}
							style={getStyles(name, personName, theme)}
						>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
}
