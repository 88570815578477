import React, { useState, Fragment, useEffect } from 'react';
import api from '../../../services/api';
import ButtonAddVehicle from './ButtonAddVehicle';

export default function CardSchool({ setIdVehicles }) {
	const [listVehicle, setListVehicle] = useState([]);
	const [arrayTeste, setArrayVeiculo] = useState([]);
	const [inputFields, setInputFields] = useState([{ idVeiculo: '' }]);

	function loadVehicle() {
		api.get('/transporte/veiculo/lista-veiculo')
			.then(response => response.data.data)
			.then(setListVehicle)
			.catch(() => setListVehicle([]));
	}
	useEffect(() => {
		loadVehicle();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ idVeiculo: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		const arrayValues = [...arrayTeste];
		values.splice(index, 1);
		arrayValues.splice(index, 1);
		setArrayVeiculo(arrayValues);
		setInputFields(values);
		setIdVehicles(arrayValues);
	};

	const handleInputChange = (index, event) => {
		setArrayVeiculo([...arrayTeste, event.target.value]);
		setIdVehicles([...arrayTeste, event.target.value]);
		const values = [...inputFields];
		if (event.target.name === 'idVeiculo')
			values[index].idVeiculo = event.target.value;
		setInputFields(values);
	};

	return (
		<div className="card mt-3">
			<div className="card-header form-group">
				<h4>Associar um veículo</h4>
			</div>
			<div className="card-body">
				<div className="row">
					{inputFields.map((inputField, index) => (
						<Fragment
							// eslint-disable-next-line react/no-array-index-key
							key={`${inputField}~${index}`}
						>
							<div className="form-group col-12 col-lg-10 col-md-10">
								<label htmlFor="idVeiculo">
									Placa do Veículo
								</label>
								<select
									name="idVeiculo"
									className="form-control"
									id="idVeiculo"
									onChange={event =>
										handleInputChange(index, event)
									}
								>
									<option>Escolha um Veículo</option>
									{listVehicle
										? listVehicle.map(vehicle => (
												<option
													key={vehicle.id_veiculo}
													value={vehicle.id_veiculo}
												>
													Placa: {vehicle.placa} -
													Modelo: {vehicle.modelo}
												</option>
										  ))
										: null}
								</select>
							</div>
							<div className="form-group col-sm-2">
								{inputFields.length > 1 && (
									<button
										className="btn btn-link"
										type="button"
										onClick={() =>
											handleRemoveFields(index)
										}
									>
										-
									</button>
								)}
								<button
									className="btn btn-link"
									type="button"
									onClick={() => handleAddFields()}
								>
									+
								</button>
							</div>
						</Fragment>
					))}
				</div>
				<ButtonAddVehicle loadVehicle={loadVehicle} />
			</div>
		</div>
	);
}
