import React from 'react';
import { FaChalkboardTeacher, FaGraduationCap } from 'react-icons/fa';

import './styles.css';

export default function Birthdays({ students, employers }) {
	const mothes = [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	];

	return (
		<>
			<div className="card" id="card-birthdays">
				<div className="card-header form-group">
					<h5 className="form-group">
						<b>
							Aniversariantes do Mês de:{' '}
							{mothes[new Date().getMonth()]}
						</b>
					</h5>
				</div>
				<div className="card-body" id="card-birthdays-body">
					{students.map(student => {
						return (
							<div className="text-card-birthday">
								<b>
									<span className="name-user-birthday">
										<FaGraduationCap />{' '}
										{student.nome_usuario}
									</span>
									{' - '}
									<span className="date-birthday">
										{student.data_nascimento}
									</span>
								</b>
							</div>
						);
					})}
					{employers.map(employer => {
						return (
							<div className="text-card-birthday">
								<b>
									<span className="name-user-birthday">
										<FaChalkboardTeacher />{' '}
										{employer.nome_usuario}
									</span>
									{' - '}
									<span className="date-birthday">
										{employer.data_nascimento}
									</span>
								</b>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}
