import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import Breadcrumb from '../../components/Breadcrumb';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Routes from '../../routes/data/Routes';
import api from '../../services/api';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';

export default function ListSupplies() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [materiaisOptions, setMateriaisOptions] = useState([]);
	const [valoresOptions, setValoresOptions] = useState([]);
	const [school, setSchool] = useState([]);
	const [schoolId, setSchoolId] = useState(null);
	const [material, setMaterial] = useState('');
	const [defeito, setDefeito] = useState(null);
	const [valor, setValor] = useState(null);
	const [quantidade, setQuantidade] = useState(null);
	const [listMaterial, setListMaterial] = useState([]);
	const [isLoading, setIsLoading] = useState([]);
	const history = useHistory();

	async function handleSeachSupplies() {
		setIsLoading(true);
		const dataPost = {
			schoolId,
			nomeMaterial: material,
			quantidade,
			valor,
			defeito,
		};
		const { data } = await api.post('/material-de-escritorio', dataPost);
		if (data.error === true) swal({ icon: 'warning', text: data.message });
		else setListMaterial(data.data);
		setIsLoading(false);
	}

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista de Materiais',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();

		(async function() {
			const { data } = await api.get('/tabelas/lista-meteriais');
			if (data.error) {
				setMateriaisOptions([]);
				setValoresOptions([]);
			} else {
				console.log(data.data);
				setMateriaisOptions(
					data.data.materiais == null
						? ['Nenhum material']
						: data.data.materiais
				);
				setValoresOptions(
					data.data.valores == null
						? ['Nenhum valor']
						: data.data.valores
				);
			}
		})();

		(async function() {
			const { data } = await api.post('/material-de-escritorio');
			if (data.error === true)
				swal({ icon: 'warning', text: data.message });
			else setListMaterial(data.data);
			setIsLoading(false);
		})();
	}, []);

	function OptionsButton({ Idmaterial }) {
		function viewNavigation() {
			const routeState = {};
			routeState[
				RouteParams.LEVEL_06_ROUTES.INFO_SUPPLICES.SUPPLICES_ID
			] = Idmaterial;
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.VIEW_SUPPLIES,
				routeState
			);
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Material"
					className="buttonViewColor"
					onClick={() => viewNavigation(Idmaterial)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setSchoolId(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Material</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setMaterial(event.target.value)
								}
							>
								<option value="">Escolha o material</option>
								{materiaisOptions.map(element => (
									<option value={element}>{element}</option>
								))}
								;
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Valor</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event => setValor(event.target.value)}
							>
								<option value="">Escolha o valor</option>
								{valoresOptions.map(element => (
									<option value={element}>{element}</option>
								))}
								;
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Quantidade</b>
							</label>
							<input
								type="number"
								name="quantidade"
								id="quantidade"
								placeholder="Quantidade"
								className="form-control"
								value={quantidade}
								onChange={event =>
									setQuantidade(event.target.value)
								}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Defeito</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={defeito}
								onChange={event =>
									setDefeito(event.target.value)
								}
							>
								<option value="">Todos</option>
								<option value="1">Sim</option>
								<option value="0">Não</option>
							</select>
						</div>
					</div>
					<button
						onClick={handleSeachSupplies}
						className="btn btn-primary"
						type="button"
						id="button-addon2"
					>
						Filtrar
					</button>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							{listMaterial.length ? (
								<div className="text-size-table">
									<Table
										header={[
											'Material',
											'Escola',
											'Quantidade',
											'valor',
											'Total',
											'Defeito',
											'Descrição',
											'Etiqueta Patrimonial',
											'',
										]}
										body={listMaterial.map(
											materialOffice => {
												return [
													materialOffice.nome_material,
													materialOffice.desc_nome_escola,
													materialOffice.quantidade,
													materialOffice.valor,
													`${materialOffice.quantidade *
														materialOffice.valor}`,
													materialOffice.defeito,
													materialOffice.descricao,
													materialOffice.etiqueta_patrimonial,
													<OptionsButton
														Idmaterial={
															materialOffice.id_material_insumo
														}
													/>,
												];
											}
										)}
									/>
								</div>
							) : (
								<p>Nenhum material encontrado</p>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}
