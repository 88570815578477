const Observation = [
	{
		id: 1,
		name: 'Com obsevações',
	},
	{
		id: 0,
		name: 'Sem obsevações',
	},
];

export default Observation;