/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import swal from 'sweetalert';
import { FaEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import parseJwt from '../../helpers/parseJwt';
import './style.css';

export default function DataAccessSystem({
	idUsuario = null,
	tiitle = 'Dados de acesso no Sistema',
	editable = true,
}) {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = idUsuario || tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);

	const [lblEmail, setlblEmail] = useState('');
	const [lblCPF, setlblCPF] = useState('');
	const [lblLogin, setlblLogin] = useState('');
	const [
		lblHabilitarAtualizacaoDadosSistema,
		setlblHabilitarAtualizacaoDadosSistema,
	] = useState(true);
	const history = useHistory();

	async function loadUser() {
		const {
			data: { error, message, data },
		} = await api.get('/usuario/informacao', {
			params: {
				idUser: idUsuario,
			},
		});
		if (error === false) {
			setlblLogin(data.usuario.login);
			setlblEmail(data.usuario.email);
			setlblCPF(data.usuario.cpf);
		} else {
			swal('ERRO!', `${message}`, 'error');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}

	useEffect(() => {
		loadUser();
	}, []);

	async function handleAtualizarDadosSistema() {
		setlblHabilitarAtualizacaoDadosSistema(
			lblHabilitarAtualizacaoDadosSistema !== true
		);
	}
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			const data = {
				idUsuario: idUser,
				email: lblEmail,
			};
			await api.post('/contato/atualizar/email', data).then(response => {
				if (response.data.error)
					swal('Ops!', `${response.data.message}`, 'warning');
				else {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					setlblHabilitarAtualizacaoDadosSistema(true);
					loadUser();
				}
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	async function handleChangePassWord(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CHANGE_PASSWORD,
			idUsuario
		);
	}
	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className="card mt-3">
					<div className="card-header col-12 col-md-12 col-lg-12 form-group">
						<p>{tiitle}</p>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Login</b>
								</p>
								{userActualType === 8 ? (
									<>
										{editable ? (
											<input
												type="text"
												name="lblLogin"
												id="lblLogin"
												className="form-control"
												placeholder="Digite o Login"
												value={lblCPF}
												disabled
											/>
										) : (
											<p>{lblCPF}</p>
										)}
									</>
								) : (
									<>
										{editable ? (
											<input
												type="text"
												name="lblLogin"
												id="lblLogin"
												className="form-control"
												placeholder="Digite o Login"
												value={lblLogin}
												disabled
											/>
										) : (
											<p>{lblLogin}</p>
										)}
									</>
								)}
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>E-mail</b>
								</p>
								{editable ? (
									<input
										type="email"
										name="lblEmail"
										id="lblEmail"
										className="form-control"
										placeholder="Digite o E-mail"
										value={lblEmail}
										disabled={
											lblHabilitarAtualizacaoDadosSistema
										}
										onChange={event =>
											setlblEmail(event.target.value)
										}
									/>
								) : (
									<p>{lblEmail}</p>
								)}
							</div>
						</div>
					</div>
					{editable ? (
						<div className="card-footer">
							<div className="row d-flex align-items-center">
								<div className="col-12 col-md-6 col-lg-6">
									<button
										className="btn btn-warning"
										type="button"
										onClick={handleChangePassWord}
									>
										Mudar Senha
									</button>
								</div>
								{userActualType !== 9 ? (
									<>
										<div className="col-12 col-md-5 col-lg-5 d-flex justify-content-end">
											<Tooltip
												title="Alterar Dados do Usuário."
												placement="top-start"
											>
												<button
													className="buttonEdit button-profile-edit"
													type="button"
													onClick={
														handleAtualizarDadosSistema
													}
												>
													<FaEdit /> Editar
												</button>
											</Tooltip>
										</div>
										<div className="col-12 col-md-1 col-lg-1 d-flex justify-content-end">
											<button
												className="btn btn-primary"
												type="submit"
												disabled={
													lblHabilitarAtualizacaoDadosSistema
												}
											>
												Salvar
											</button>
										</div>
									</>
								) : null}
							</div>
						</div>
					) : null}
				</div>
			</form>
		</>
	);
}
