import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaTimesCircle, FaEye, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';

export default function ListTransport() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Rotas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [routeTransports, setRouteTransports] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	async function loadMonitor() {
		setIsLoading(true);
		const response = await api.get('/transporte/rota/listar-rotas');
		if (!response.data.error) setRouteTransports(response.data.data);
		else {
			setRouteTransports([]);
			swal(response.data.message);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadMonitor();
	}, []);

	function OptionsButton({ idTransporte }) {
		function viewNavigation() {
			const params = {};

			params[
				RouteParams.LEVEL_08_ROUTES.EDIT_TRANSPORT.ID_TRANSPORT
			] = idTransporte;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.INFO_ROUTE_TRANSPORT,
				{ params }
			);
		}

		function editNavigation() {
			const params = {};

			params[
				RouteParams.LEVEL_08_ROUTES.EDIT_TRANSPORT.ID_TRANSPORT
			] = idTransporte;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.UPDATE_ROUTE_TRANSPORT,
				{ params }
			);
		}

		async function handleDeactivated(e) {
			swal({
				title: 'Você tem certeza?',
				text: 'Você está para desativar a Rota de Transporte',
				icon: 'warning',
				buttons: ['Cancelar', 'Confirmar'],
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const data = { idTransporte };
						const {
							data: { message },
						} = await api.post(
							'/transporte/rota/desativar-rota-transporte',
							data
						);
						swal('SUCESSO!', `${message}`, 'success').then(
							value => {
								if (value) loadMonitor();
							}
						);
					})();
				else swal('Ação cancelada');
			});
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Rota"
					className="buttonViewColor"
					onClick={() => viewNavigation(idTransporte)}
				>
					<FaEye fill="#ffffff" />
				</button>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Editar Rota"
					className="buttonAddColor"
					onClick={() => editNavigation(idTransporte)}
				>
					<FaPencilAlt fill="#ffffff" />
				</button>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Deletar Rota"
					className="buttonDelete"
					onClick={() => handleDeactivated(idTransporte)}
				>
					<FaTrashAlt fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<div className="d-flex flex-row-reverse">
							<Link
								to={
									Routes.LOGGED_ROUTES(
										RouteByPermission[userActualType]
									).ADD_ROUTE_TRANSPORT
								}
							>
								<button
									className="btn buttonCustomSemec"
									type="button"
									id="button"
								>
									Inserir Rota de Transporte
								</button>
							</Link>
						</div>
						{routeTransports ? (
							<Table
								header={['Data', 'Motorista', 'Placa', '']}
								body={routeTransports.map(routeTransport => {
									return [
										routeTransport.dia_transporte,
										routeTransport.nome_usuario,
										routeTransport.placa,
										<OptionsButton
											idTransporte={
												routeTransport.id_transporte
											}
										/>,
									];
								})}
							/>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhuma rota de transporte encontrada
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
