import React, { useEffect, useState } from 'react';
import InsertStudentGeneric from './InsertStudentGeneric';
import InsertStudentSchool from './InsertStudentSchool';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import { useFunctionalities } from '../../../components/Contenxt/Functionalities';

export default function Index() {
	const [isLoading, setIsLoading] = useState(true);
	const [isGeneric, setIsGeneric] = useState(true);

	const {
		functionalities: { functionalities },
	} = useFunctionalities();

	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 146
			);
			if (existe) setIsGeneric(true);
			else setIsGeneric(false);
			setIsLoading(false);
		} else setIsLoading(true);
	}, [functionalities]);
	return (
		<>
			{isLoading === true ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					{isGeneric ? (
						<InsertStudentGeneric />
					) : (
						<InsertStudentSchool />
					)}
				</>
			)}
		</>
	);
}
