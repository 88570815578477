import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';
import { RiArrowGoBackLine } from 'react-icons/ri';
import swal from 'sweetalert';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Table from '../../../components/Table';
import SelectDisciplinas from '../../../components/Selects/SelectDisciplinas';
import SelectSerieAno from '../../../components/Selects/SelectSerieAno';
import SelectClasses from '../../../components/Selects/SelectClasses';

export default function ConfirmReclassifyStudents() {
	const breadCrumbItems = [
		{
			linkPage: '/secretaria',
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '/secretaria/alunos/notas-baixas',
			namePage: 'Alunos com notas baixas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Alunos Reprovados',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblclasseIdsDestino, setlblclasseIdsDestino] = useState(null);
	const [classesDestinos, setClassesDestinos] = useState([]);
	const [disabledButtom, setDisabledButtom] = useState(true);
	const [listStudents, setListStudents] = useState([]);
	const [disciplinaId, setDisciplinaId] = useState(null);
	const [classeId, setClasseId] = useState(null);
	const [serieId, setSerieId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	async function handleSeachStudents() {
		setIsLoading(true);
		const { data } = await api.get('/aluno/alunosreprovados', {
			params: {
				serieId,
				classeID: classeId,
				disciplinaID: disciplinaId,
			},
		});
		if (data.error === false) setListStudents(data.data);
		else {
			swal({ icon: 'warning', text: data.message });
			setListStudents([]);
		}
		setIsLoading(false);
	}

	async function handleChangeClasse(e) {
		if (e > 0) {
			setlblclasseIdsDestino(e);
			setDisabledButtom(false);
		} else setDisabledButtom(true);
	}

	useEffect(() => {
		(async function() {
			if (serieId) {
				const { data } = await api.get('/classes/lista');
				if (data.error === false) setClassesDestinos(data.data);
				else swal('ERRO!', `${data.message}`, 'warning');
			}
		})();
	}, [serieId]);

	useEffect(() => {
		handleSeachStudents();
	}, []);

	function OptionsButton({ student }) {
		const [idUser] = useState(student.id_usuario);
		const [notes] = useState(student.disciplinas);
		const [name] = useState(student.nome_usuario);

		const listClasses = classesDestinos.map(t => (
			<option value={t.id_classe} key={t.id_classe}>
				{t.desc_serie_ano}
				{' - turma: '}
				{t.turma}
			</option>
		));

		const selectClasses = listClasses.filter(
			c => c.props.value !== student.id_classe
		);

		async function handleSubmit(event) {
			event.preventDefault();
			try {
				event.preventDefault();
				const data = new FormData();
				data.append('idAluno', idUser);
				data.append('lblserieId', serieId);
				data.append('lblclasseIdsDestino', lblclasseIdsDestino);
				await api
					.post('/aluno/perfil/atualiza/reclassifica', data)
					.then(response => {
						if (response.data.error)
							swal(
								'Erro!',
								`${response.data.message}`,
								'warning'
							);
						else
							swal(
								'SUCESSO!',
								`${response.data.message}`,
								'success'
							);
					});
			} catch (error) {
				console.log(`error: ${error}`);
			}
		}

		function handleCancelReclassify(event) {
			event.preventDefault();
			swal({
				title: 'Você tem certeza?',
				text: 'Você está para tirar o aluno(a) da lista de reprovados',
				icon: 'warning',
				buttons: true,
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const { data } = await api.post(
							'/aluno/reprovaraluno/cancela',
							{
								idsAlunoReprovado: notes.map(
									n => n.id_aluno_reprovado
								),
							}
						);
						if (data.error === false)
							swal('SUCESSO!', `${data.message}`, 'success').then(
								() => {
									handleSeachStudents();
								}
							);
						else
							swal('ERRO!', `${data.message}`, 'error').then(
								() => {
									handleSeachStudents();
								}
							);
					})();
				else swal('Aluno(a) continua na lista de reprovados');
			});
		}

		return (
			<>
				<button
					title="Ver notas"
					type="button"
					id="button"
					className="btn buttonViewColor"
					data-toggle="modal"
					data-target={`#notasModal${idUser}`}
				>
					<FaEye fill="#ffffff" />
				</button>

				<button
					title="Deixar de ser reprovado"
					type="button"
					id="button"
					className="btn buttonAddColor"
					onClick={handleCancelReclassify}
				>
					<RiArrowGoBackLine fill="#ffffff" />
				</button>

				<button
					title="Reclassificar aluno"
					type="button"
					id="button"
					className="btn buttonDelete"
					data-toggle="modal"
					data-target={`#reclassifyStudent${idUser}`}
				>
					<MdReportProblem fill="#ffffff" />
				</button>

				<div
					className="modal fade bd-example-modal-xl"
					id={`notasModal${idUser}`}
					tabIndex="-1"
					role="dialog"
					aria-labelledby="notasModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-xl" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="notasModalLabel"
								>
									Notas do Aluno(a): {name}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{notes.length ? (
									<Table
										header={[
											'Disciplina',
											'Nota',
											'Frequência (%)',
										]}
										body={notes.map(note => {
											return [
												note.nome_disciplina,
												note.nota_media_final,
												`${note.frequencia}%`,
											];
										})}
										fileName={name}
									/>
								) : (
									<p>Aluno não tem notas no sistema ainda.</p>
								)}
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade bd-example-modal-xl"
					id={`reclassifyStudent${idUser}`}
					tabIndex="-1"
					role="dialog"
					aria-labelledby="reclassifyStudentLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-xl" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="reclassifyStudentLabel"
								>
									Reclassificar Aluno(a): {name}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form onSubmit={handleSubmit}>
								<div className="modal-body">
									<div className="row">
										<div className="col-12 col-md-2 col-lg-2 form-group">
											<b>Classe Atual:</b>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											{student.desc_serie_ano}
											{' - '}
											{student.desc_tipo_ensino}
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<b>Turma:</b>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											{student.turma}
										</div>

										<div className="col-12 col-md-2 col-lg-2 form-group">
											<b>Turno:</b>
										</div>
										<div className="col-12 col-md-2 col-lg-2 form-group">
											{student.desc_turno}
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-2 col-lg-2 form-group">
											<b>Classe Destino:</b>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											{selectClasses.length ? (
												<select
													required
													className="form-control"
													id="lblTipoUsuario"
													onChange={event =>
														handleChangeClasse(
															event.target.value
														)
													}
												>
													<option value={0}>
														Escolha uma Classe
													</option>
													{selectClasses}
												</select>
											) : (
												<b>
													Não há classe com a mesma
													série para alocar o aluno
												</b>
											)}
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="submit"
										className="btn btn-danger"
										data-dismiss="modal"
									>
										Cancelar
									</button>
									<button
										disabled={disabledButtom}
										type="submit"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#modalConfirmar"
									>
										Confirmar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">
					<b>Alunos Reprovados</b>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<SelectSerieAno
								setIdSerieAno={setSerieId}
								valueSelect={serieId}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Classe</b>
							</label>
							<SelectClasses
								setClasse={setClasseId}
								valueSelect={classeId}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Disciplinas</b>
							</label>
							<SelectDisciplinas
								setDisciplina={setDisciplinaId}
								valueSelect={disciplinaId}
							/>
						</div>
					</div>
					<button
						onClick={handleSeachStudents}
						className="btn btn-primary"
						type="button"
						id="button-addon2"
					>
						Filtrar
					</button>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<Table
							header={['RA', 'Nome Aluno', 'Série/Classe', '']}
							body={listStudents.map(student => {
								return [
									student.num_ra,
									student.nome_usuario,
									`${student.desc_serie_ano} ${student.desc_tipo_ensino} - ${student.turma}`,
									<OptionsButton student={student} />,
								];
							})}
						/>
					)}
				</div>
			</div>
		</>
	);
}
