import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import './style.css';
import { useSelector } from 'react-redux';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import { FaEye } from 'react-icons/fa';
import RouteParams from '../../routes/data/RouteParams';

export default function ListCoordinator() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista Coordenadores',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [coordinators, setCoordinator] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			const response = await api.get('/coordenador/lista');
			if (!response.data.error) setCoordinator(response.data.data);
			else swal('', `${response.data.message}`, 'warning');
			setIsLoading(false);
		})();
	}, []);
	async function handleAddCoordinator(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ADD_COORDINATOR
		);
	}
	function OptionsButton({ coordinator }) {
		function viewNavigation() {
			const params = {};
			params[RouteParams.LEVEL_05_ROUTES.INFO_COORDINATOR.INFO_USER] =
				coordinator.users;
			params[RouteParams.LEVEL_05_ROUTES.INFO_COORDINATOR.LIST_CLASSES] =
				coordinator.classes;
			params[RouteParams.LEVEL_05_ROUTES.INFO_COORDINATOR.LIST_CONTATOS] =
				coordinator.contato;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_COORDINATOR,
				state: params,
			});
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(coordinator)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="row">
				<div className="col-12 form-group d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-primary"
						onClick={handleAddCoordinator}
					>
						Adicionar
					</button>
				</div>
			</div>
			{isLoading === <div className="lds-dual-ring" />}
			{isLoading === false && (
				<div className="card">
					<div className="card-body">
						<Table
							header={['Disciplina', '']}
							body={coordinators.map(coordinator => {
								return [
									coordinator.users.nome_usuario,
									<OptionsButton coordinator={coordinator} />,
								];
							})}
						/>
					</div>
				</div>
			)}
		</>
	);
}
