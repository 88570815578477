import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function Sidebar({ items }) {
	const [link, setLink] = useState('');

	async function scroolToBottom(linkname) {
		setLink(linkname);
		if (link !== linkname)
			setTimeout(() => {
				window.location.href = `#${linkname}`;
			}, 200);
		else setLink('');
	}
	return (
		<>
			<div
				id="mySidebar"
				className="col-xl-2 col-lg-12 col-md-12 sidebar fixed-top"
			>
				<div className="container">
					<ul className="navbar-nav flex-column mt-4">
						{items.map(
							(
								{
									icon,
									sidebarlabel,
									sidebarname,
									linkname,
									subItems,
								},
								index
							) => (
								<li className="mt-3 nav-item" key={sidebarname}>
									{subItems ? (
										<a
											data-toggle="collapse"
											href={`#Collapse${linkname}`}
											role="button"
											id={linkname}
											aria-expanded="false"
											aria-controls={`Collapse${linkname}`}
											className="nav-link sidebar-link text-light p-2"
											onClick={() =>
												scroolToBottom(linkname)
											}
										>
											<span className="mr-3 sidebar-icons">
												{icon}
											</span>
											{sidebarlabel}
										</a>
									) : (
										<Link
											to={`${linkname}`}
											className="nav-link sidebar-link text-light p-2"
										>
											<span className="mr-3 sidebar-icons">
												{icon}
											</span>
											{sidebarlabel}
										</Link>
									)}
									<div
										className="collapse multi-collapse"
										id={`Collapse${linkname}`}
									>
										<div className="nav-item ">
											{Array.isArray(subItems) ? (
												<ul
													key={sidebarname}
													className="navbar-nav flex-column submenusidebar"
												>
													{subItems.map(subItem => (
														<li
															className="nav-item sub-item"
															key={
																subItem.sidebarname
															}
														>
															<Link
																to={
																	subItem.linkname
																}
																className="nav-link text-light"
															>
																{
																	subItem.sidebarlabel
																}
															</Link>
														</li>
													))}
												</ul>
											) : null}
										</div>
									</div>
								</li>
							)
						)}
					</ul>

					<div className="sidebar-footer">
						<div className="footer-text">
							&copy; Next Tecnologia - {new Date().getFullYear()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
