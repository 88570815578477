/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import api from '../../../../services/api';
import Breadcrumb from '../../../../components/Breadcrumb';
import RouteParams from '../../../../routes/data/RouteParams';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import './style.css';
import CardModules from './CardModules';
import ModuleCard from '../../../../components/module/CardModule';
import parseJwt from '../../../../helpers/parseJwt';

export default function InfoAttribution() {
	const history = useHistory();
	const { state } = useLocation();
	const [idAttribution, setIdAttribution] = useState(null);
	const [activeAboutClasse, setActiveAboutClasse] = useState(false);
	const [videos, setVideos] = useState([]);
	const [avaliacoes, setAvaliacoes] = useState([]);

	const [attribution, setAttribution] = useState([]);
	const [modules, setModules] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	async function handleGetVideos() {
		const response = await api.get(`/biblioteca/buscar-videos/${idUser}`);
		setVideos(response.data);
	}

	const handleGetAvaliacoes = async () => {
		const { data } = await api.get(`/avaliacao/exibir/${idUser}`);
		setAvaliacoes(data.data);
	};

	const files = useRef([React.createRef(), React.createRef()]);

	const userActualType = useSelector(user => user.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ATTRIBUTION,
			namePage: 'Atribuições',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação da Atribuição',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	function loadAttibution() {
		if (!state) {
			swal('Escolha uma Atribuição');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.ATTRIBUTION
			);
		} else
			(async function() {
				const { data } = await api.get('/atribuicao/info-atribuicao', {
					params: {
						idAttribution:
							state[
								RouteParams.LEVEL_03_ROUTES.ATTRIBUTION
									.ATTRIBUTION_ID
							],
					},
				});
				setIdAttribution(
					state[
						RouteParams.LEVEL_03_ROUTES.ATTRIBUTION.ATTRIBUTION_ID
					]
				);
				if (data.error === false) {
					setIsLoading(false);
					setAttribution(data.data.atribuicao);
					setModules(data.data.modules);
				} else {
					swal('ERRO!', `${data.message}`, 'error');
					history.goBack();
				}
			})();
	}

	useEffect(() => {
		loadAttibution();
		handleGetVideos();
		handleGetAvaliacoes();
	}, [state]);

	async function changeActive() {
		await setActiveAboutClasse(!activeAboutClasse);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading === true && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<div>
					<div className="card mt-3">
						<div className="card-header">
							<div className="row">
								<div className="col-11 col-md-11 col-lg-11">
									<h5>
										<b>Sobre a Classe</b>
									</h5>
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<p>
										<b>Série:</b>{' '}
										{attribution.desc_serie_ano}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<p>
										<b>Tipo de Ensino:</b>{' '}
										{attribution.desc_tipo_ensino}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Nome Turma:</b> {attribution.turma}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Turno:</b> {attribution.desc_turno}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Sala:</b> {attribution.num_sala}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-8 col-lg-8 form-group">
									<p>
										<b>Horário Inicial:</b>{' '}
										{attribution.horario_inicio}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4  form-group">
									<p>
										<b>Horário Final:</b>{' '}
										{attribution.horario_fim}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-8 col-lg-8 form-group">
									<p>
										<b>Capacidade Máxima:</b>{' '}
										{attribution.capacidade_fisica_max}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4  form-group">
									<p>
										<b>Quantidade Atual:</b>{' '}
										{attribution.qtd_atual}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h5>
								<b>Conteúdos das Aulas</b>
							</h5>
						</div>
						<ModuleCard
							modules={modules}
							videos={videos}
							avaliacoes={avaliacoes}
							loadAttibution={() => loadAttibution()}
						/>
						<div className="card-footer">
							<div className="row">
								<div className="col-12 col-lg-6 col-md-6">
									<CardModules
										loadAttibution={loadAttibution}
										idAttribution={idAttribution}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
