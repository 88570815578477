/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';
import Panel from '../../../components/Panel';
import Footer from '../../../components/Footer';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.GRADE,
			namePage: 'Lançar Notas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [students, setStudents] = useState([]);
	const [subjectsTeacher, setSubjectsTeacher] = useState([]);
	const [schoolTeacher, setSchoolTeacher] = useState([]);
	const [schoolIDTeacher, setSchoolIDTeacher] = useState(null);
	const [subjectIDTeacher, setSubjectIDTeacher] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [classTeacher, setClassTeacher] = useState([]);
	const [classIDTeacher, setClassIDTeacher] = useState(null);
	const [disabledClass, setDisabledClass] = useState(true);
	const [disabledGrades, setDisabledGrades] = useState(true);
	const [gradeAverage, setGradeAverage] = useState({});
	const [grades, setGrades] = useState({});

	useEffect(() => {
		(async function() {
			const response = await api.get('/professor/listarescolas');
			if (!response.data.error) setSchoolTeacher(response.data.data);
			else setSchoolTeacher([]);
		})();
	}, []);

	useEffect(() => {
		if (schoolIDTeacher)
			(async function() {
				const response = await api.get('/professor/listardisciplinas', {
					params: {
						escolaID: schoolIDTeacher,
					},
				});
				if (response.data.error) {
					setSubjectsTeacher([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjectsTeacher(response.data.data);
				}
			})();
		else {
			setSubjectIDTeacher(null);
			setSubjectsTeacher([]);
			setDisabledSubjects(true);
		}
	}, [schoolIDTeacher]);

	useEffect(() => {
		(async function() {
			const response = await api.get('/professor/listarturmas', {
				params: {
					escolaID: schoolIDTeacher,
					disciplinaID: subjectIDTeacher,
				},
			});
			if (response.data.error) {
				setClassTeacher([]);
				setDisabledClass(true);
			} else {
				setDisabledClass(false);
				setClassTeacher(response.data.data);
			}
		})();
	}, [subjectIDTeacher]);

	useEffect(() => {
		if (schoolIDTeacher && subjectIDTeacher && classIDTeacher)
			setDisabledBtn(false);
		else {
			setDisabledBtn(true);
			setDisabledGrades(true);
			setGradeAverage({});
			setGrades({});
			setStudents([]);
		}
	}, [schoolIDTeacher, subjectIDTeacher, classIDTeacher]);

	async function searchStudents(event) {
		event.preventDefault();
		(async function() {
			const response = await api.get('/professor/listaralunos', {
				params: {
					escolaID: schoolIDTeacher,
					disciplinaID: subjectIDTeacher,
					classeID: classIDTeacher,
				},
			});
			if (response.data.error) {
				setDisabledGrades(true);
				setStudents([]);
			} else {
				setStudents(response.data.data);
				setDisabledGrades(false);
			}
		})();
		(async function() {
			const response = await api.get('/professor/consultarnotas', {
				params: {
					escolaID: schoolIDTeacher,
					disciplinaID: subjectIDTeacher,
					classeID: classIDTeacher,
				},
			});
			const actualGrades = {};
			const actualGradeAvareges = {};
			for (const aluno of response.data.data) {
				actualGrades[aluno.id_usuario.toString()] = {};

				actualGrades[aluno.id_usuario.toString()]['1'] =
					aluno.nota_bimestral1;
				actualGrades[aluno.id_usuario.toString()]['2'] =
					aluno.nota_bimestral2;
				actualGrades[aluno.id_usuario.toString()]['3'] =
					aluno.nota_bimestral3;
				actualGrades[aluno.id_usuario.toString()]['4'] =
					aluno.nota_bimestral4;
				actualGrades[aluno.id_usuario.toString()]['5'] =
					aluno.editar_nota_bimestral1;
				actualGrades[aluno.id_usuario.toString()]['6'] =
					aluno.editar_nota_bimestral2;
				actualGrades[aluno.id_usuario.toString()]['7'] =
					aluno.editar_nota_bimestral3;
				actualGrades[aluno.id_usuario.toString()]['8'] =
					aluno.editar_nota_bimestral4;

				actualGradeAvareges[aluno.id_usuario.toString()] =
					aluno.nota_media_final;
			}
			setGrades({ ...actualGrades });
			setGradeAverage({ ...actualGradeAvareges });
		})();
	}
	function updateGrade(studentId, grade, bimester) {
		const studentGrade = grades[studentId] || {
			'1': null,
			'2': null,
			'3': null,
			'4': null,
		};
		studentGrade[bimester] = isNaN(parseFloat(grade))
			? 0
			: parseFloat(grade);

		grades[studentId] = studentGrade;
		setGrades({ ...grades });

		gradeAverage[studentId] =
			((isNaN(parseFloat(grades[studentId]['1']))
				? 0
				: parseFloat(grades[studentId]['1'])) +
				(isNaN(parseFloat(grades[studentId]['2']))
					? 0
					: parseFloat(grades[studentId]['2'])) +
				(isNaN(parseFloat(grades[studentId]['3']))
					? 0
					: parseFloat(grades[studentId]['3'])) +
				(isNaN(parseFloat(grades[studentId]['4']))
					? 0
					: parseFloat(grades[studentId]['4']))) /
			4.0;

		setGradeAverage({ ...gradeAverage });
	}
	async function handleGrades() {
		await api.post('/professor/lancarnotas', {
			disciplinaID: subjectIDTeacher,
			classeID: classIDTeacher,
			alunos: grades,
		});
		swal('Notas lançadas com sucesso!');
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mb-4">
				<div className="card-body">
					<div className="row mb-3">
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="schoolTeacher">
								<b>Escola</b>
							</label>
							<select
								className="form-control"
								name="schoolTeacher"
								id="schoolTeacher"
								onChange={event =>
									setSchoolIDTeacher(event.target.value)
								}
							>
								<option value="">Escolha a Escola</option>
								{schoolTeacher.map(schools => (
									<option
										key={schools.id_escola}
										value={schools.id_escola}
									>
										{schools.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="subjectTeacher">
								<b>Disciplina</b>
							</label>
							<select
								className="form-control"
								name="subjectTeacher"
								id="subjectTeacher"
								onChange={event =>
									setSubjectIDTeacher(event.target.value)
								}
								disabled={disabledSubjects}
							>
								<option value="">Escolha a disciplina</option>
								{subjectsTeacher.map(subjects => (
									<option
										key={subjects.id_disciplina}
										value={subjects.id_disciplina}
									>
										{subjects.nome_disciplina}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="classTeacher">
								<b>Série/Turma</b>
							</label>
							<select
								id="classTeacher"
								className="form-control"
								onChange={event =>
									setClassIDTeacher(event.target.value)
								}
								disabled={disabledClass}
							>
								<option value="">Escolha a Classe</option>
								{classTeacher.map(classes => (
									<option
										key={classes.id_classe}
										value={classes.id_classe}
									>
										{classes.desc_serie_ano} -{' '}
										{classes.turma}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col-6 form-group">
							<button
								type="button"
								className="btn btn-block btn-success"
								onClick={searchStudents}
								disabled={disabledBtn}
							>
								<FaSearch className="searchIcon" />
								Buscar
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="text-center">
										Aluno
									</th>
									<th scope="col" className="text-center">
										1º Bimestre
									</th>
									<th scope="col" className="text-center">
										2º Bimestre
									</th>
									<th scope="col" className="text-center">
										3º Bimestre
									</th>
									<th scope="col" className="text-center">
										4º Bimestre
									</th>
									<th scope="col" className="text-center">
										Média
									</th>
								</tr>
							</thead>
							<tbody>
								{students.map(student => (
									<tr key={student.id_usuario}>
										<th>{student.nome_usuario}</th>

										<th className="text-center">
											<input
												type="number"
												step="0.01"
												disabled={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['5'] === 0
														: null
												}
												className="form-control input-grade"
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['1'] || null
														: null
												}
												onChange={event =>
													updateGrade(
														student.id_usuario,
														event.target.value,
														'1'
													)
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="number"
												step="0.01"
												className="form-control input-grade"
												disabled={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['6'] === 0
														: null
												}
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['2'] || null
														: null
												}
												onChange={event =>
													updateGrade(
														student.id_usuario,
														event.target.value,
														'2'
													)
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="number"
												step="0.01"
												disabled={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['7'] === 0
														: null
												}
												className="form-control input-grade"
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['3'] || null
														: null
												}
												onChange={event =>
													updateGrade(
														student.id_usuario,
														event.target.value,
														'3'
													)
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="number"
												step="0.01"
												disabled={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['8'] === 0
														: null
												}
												className="form-control input-grade"
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['4'] || null
														: null
												}
												onChange={event =>
													updateGrade(
														student.id_usuario,
														event.target.value,
														'4'
													)
												}
											/>
										</th>

										<th className="text-center">
											<input
												type="text"
												className="form-control input-grade"
												value={
													gradeAverage[
														student.id_usuario
													]
												}
												disabled
											/>
										</th>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="row text-center justify-content-center">
						<div className="col-6 form-group">
							<button
								type="button"
								className="btn btn-primary btn-block"
								onClick={handleGrades}
								disabled={disabledGrades}
							>
								Lançar notas
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
