import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import iconSchool from '../../../assets/img/icons/icone-escolas-3.png';
import iconTeacher from '../../../assets/img/icons/icone-professores-2.png';
import iconStudents from '../../../assets/img/icons/icones-alunos-semec.png';
import iconParents from '../../../assets/img/icons/icones-semec-reponsaveis.png';
import CardsInfo from '../../../components/Cards/CardsInfo';
import ListNotices from '../../../components/Notices/ListNotices';
import GraphicPerformanceClasse from './GraphicPerformanceClasse';
import api from '../../../services/api';
import Birthdays from './Birthday/index';
import ReleaseNotes from './ReleaseNotes';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import VideoLesson from '../../../components/VideoLesson';

export default function Dash() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [teachers, setTeachers] = useState('');
	const [classes, setClasses] = useState('');
	const [students, setStudents] = useState('');
	const [parents, setParents] = useState('');
	const [enableEditing, setEnableEditing] = useState('0');
	const [period, setPeriod] = useState('0');
	const [studentsBirthDay, setStudentsBirthDay] = useState([]);
	const [employersBirthDay, setEmployersBirthDay] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	async function loadDash() {
		const
			data = await api.get('/secretaria/dash-secretaria');
		
		data.data.data && setClasses(data.data.data.classes);
		data.data.data && setTeachers(data.data.data.professores);
		data.data.data && setStudents(data.data.data.alunos);
		data.data.data && setParents(data.data.data.parentes);
	}

	async function loadRelaseNotes() {
		const {
			data: { error, data },
		} = await api.get('/escola');
		if (error === false) {
			setEnableEditing(`${data.editar_notas}`);
			setPeriod(`${data.bimestre}`);
		}
	}

	async function loadBirthdays() {
		const { data } = await api.post('/secretaria/aniversariantes');
		if (data.error === false) {
			setStudentsBirthDay(data.data.students);
			setEmployersBirthDay(data.data.employer);
		} else {
			setStudentsBirthDay([]);
			setEmployersBirthDay([]);
		}
	}

	useEffect(() => {
		// eslint-disable-next-line func-names
		setIsLoading(true);
		loadDash();
		loadRelaseNotes();
		loadBirthdays();
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);
	return (
		<>
			<main>
				<div className="container-fluid">
					<div className="row painel justify-content-center">
						<div className="col-xl-11 col-lg-11 col-md-12">
							{isLoading === true ? (
								<div className="row pt-md-5 mt-md-3 mb-5">
									<div className="lds-dual-ring" />
								</div>
							) : (
								<>
									<CardsInfo
										card1={{
											title: 'Classes',
											icon: iconSchool,
											number: classes,
											link: Routes.LOGGED_ROUTES(
												RouteByPermission[
												userActualType
												]
											).LIST_CLASSES,
										}}
										card2={{
											title: 'Professores',
											icon: iconTeacher,
											number: teachers,
											link: Routes.LOGGED_ROUTES(
												RouteByPermission[
												userActualType
												]
											).LIST_TEACHERS,
										}}
										card3={{
											title: 'Alunos',
											icon: iconStudents,
											number: students,
											link: Routes.LOGGED_ROUTES(
												RouteByPermission[
												userActualType
												]
											).STUDENTS,
										}}
										card4={{
											title: 'Responsáveis',
											icon: iconParents,
											number: parents,
											link: Routes.LOGGED_ROUTES(
												RouteByPermission[
												userActualType
												]
											).LIST_PARENTS,
										}}
									/>
									<GraphicPerformanceClasse />
									<div className="row mt-3">
										<div className="col-12 col-md-6 col-lg-6 w-100">
											<Birthdays
												students={studentsBirthDay}
												employers={employersBirthDay}
											/>
										</div>
										<div className="col-12 col-md-6 col-lg-6 flex-column align-self-stretch">
											<ReleaseNotes
												enableEditing={enableEditing}
												period={period}
												setEnableEditing={
													setEnableEditing
												}
												setPeriod={setPeriod}
											/>
										</div>
									</div>
									<ListNotices />
									<VideoLesson/>
								</>
							)}
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

