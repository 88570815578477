/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

export default function TesteAreaRead({ lblComents }) {
	return (
		<div>
			<ReactQuill theme="bubble" readOnly value={lblComents} />
		</div>
	);
}
