import React, { useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import SelectCursoNovoTec from '../../../components/Selects/SelectCursoNovoTec';
import SelectMotivo from '../../../components/Selects/SelectMotivo';
import SelectClasses from '../../../components/Selects/SelectClasses';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_CLASSES,
			namePage: 'Lista Classes',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.INFO_CLASS,
			namePage: 'Informação da Classe',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Matricular Aluno na Escola',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblNomeAluno, setlblNomeAluno] = useState('');
	const [lblNomeMae, setlblNomeMae] = useState('');
	const [lblDataNasimento, setlblDataNasimento] = useState('');
	const [lblFase, setlblFase] = useState('');
	const [lblInteresseIntegral, setlblInteresseIntegral] = useState('');
	const [lblInteresseEspanhol, setlblInteresseEspanhol] = useState('');
	const [lblNecesAtendNoturno, setlblNecesAtendNoturno] = useState(null);
	const [lblOpcaoNoturno, setlblOpcaoNoturno] = useState('');
	const [lblInteresseNovoTec, setlblInteresseNovoTec] = useState('');
	const [lblEixoNovotecOpcaoUm, setlblEixoNovotecOpcaoUm] = useState('');
	const [lblEixoNovotecOpcaoDois, setlblEixoNovotecOpcaoDois] = useState('');
	const [lblEixoNovotecOpcaoTres, setlblEixoNovotecOpcaoTres] = useState('');
	const [lblDataInicioMatricula, setlblDataInicioMatricula] = useState('');
	const [lblIdClasse, setlblIdClasse] = useState('');
	const [idMotivo, setIdMotivo] = useState('');

	async function handleCancel(event) {
		event.preventDefault();
		history.goBack();
	}
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			const data = new FormData();
			data.append('lblNomeAluno', lblNomeAluno);
			data.append('lblNomeMae', lblNomeMae);
			data.append('lblDataNasimento', lblDataNasimento);
			data.append('lblFase', lblFase);
			data.append('lblInteresseIntegral', lblInteresseIntegral);
			data.append('lblInteresseEspanhol', lblInteresseEspanhol);
			data.append('lblNecesAtendNoturno', lblNecesAtendNoturno);
			data.append('lblOpcaoNoturno', lblOpcaoNoturno);
			data.append('lblInteresseNovoTec', lblInteresseNovoTec);
			data.append('lblEixoNovotecOpcaoUm', lblEixoNovotecOpcaoUm);
			data.append('lblEixoNovotecOpcaoDois', lblEixoNovotecOpcaoDois);
			data.append('lblEixoNovotecOpcaoTres', lblEixoNovotecOpcaoTres);
			data.append('lblDataInicioMatricula', lblDataInicioMatricula);
			data.append('lblIdClasse', lblIdClasse);
			data.append('idMotivo', idMotivo);

			await api
				.post('/aluno/perfil/atualiza/matricular/escola', data)
				.then(response => {
					if (!response.data.error)
						swal('SUCESSO!', `${response.data.message}`, 'success');
					else swal('Cuidado', `${response.data.message}`, 'warning');
					// window.history.back();
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={handleSubmit}>
				<div className="card">
					<div className="card-header">
						<p>
							<b>Inscrever Aluno por Transferência de Escola</b>
						</p>
						<p>
							<small>
								A matricula só funciona com alunos que já tem
								cadastro no Sistema do Estado de São Paulo.
							</small>
						</p>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Nome Aluno:</p>
								<input
									type="text"
									name="lblNomeAluno"
									id="lblNomeAluno"
									className="form-control"
									placeholder="Nome Aluno"
									required
									onChange={event =>
										setlblNomeAluno(event.target.value)
									}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Nome Mãe:</p>
								<input
									type="text"
									name="lblNomeMae"
									id="lblNomeMae"
									className="form-control"
									placeholder="Nome Mãe"
									required
									onChange={event =>
										setlblNomeMae(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Data Nascimento</p>
								<InputMask
									mask="99/99/9999"
									required
									type="text"
									name="lblDataNasimento"
									id="lblDataNasimento"
									className="form-control"
									placeholder="Data Nascimento"
									value={lblDataNasimento}
									onChange={event =>
										setlblDataNasimento(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Tipo de Transferência:</p>
								<select
									required
									className="form-control"
									name="lblFase"
									id="lblFase"
									value={lblFase}
									onChange={event =>
										setlblFase(event.target.value)
									}
								>
									<option> Escolha uma Opção</option>
									<option value="0">
										Inscrição de Alunos por Transferência
									</option>
									<option value="8">
										Inscrição de Alunos por Deslocamento
									</option>
									<option value="9">
										Inscrição por Intenção de Transferência
									</option>
								</select>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Motivo Transferência:</p>
								<SelectMotivo setMotivo={setIdMotivo} />
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<p>Interesse Integral: </p>
							</div>
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<select
									className="form-control"
									name="lblInteresseIntegral"
									id="lblInteresseIntegral"
									value={lblInteresseIntegral}
									onChange={event =>
										setlblInteresseIntegral(
											event.target.value
										)
									}
								>
									<option> Não</option>
									<option value="1">Sim</option>
								</select>
							</div>
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<p>Interesse Espanhol: </p>
							</div>
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<select
									className="form-control"
									name="lblInteresseEspanhol"
									id="lblInteresseEspanhol"
									value={lblInteresseEspanhol}
									onChange={event =>
										setlblInteresseEspanhol(
											event.target.value
										)
									}
								>
									<option> Não</option>
									<option value="1">Sim</option>
								</select>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<p>Necessidade de Matrícula Noturna: </p>
							</div>
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<select
									className="form-control"
									name="lblNecesAtendNoturno"
									id="lblNecesAtendNoturno"
									onChange={event =>
										setlblNecesAtendNoturno(
											event.target.value
										)
									}
								>
									<option> Não</option>
									<option value="1">Sim</option>
								</select>
							</div>
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<p>Opção Noturna: </p>
							</div>
							<div className="col-12 col-md-3 col-lg-3  form-group">
								<select
									className="form-control"
									disabled={lblNecesAtendNoturno !== '1'}
									name="lblOpcaoNoturno"
									id="lblOpcaoNoturno"
									onChange={event =>
										setlblOpcaoNoturno(event.target.value)
									}
								>
									<option value="1"> Trabalho</option>
									<option value="2">
										Cursos em período diurno
									</option>
									<option value="3">Opção do aluno</option>
									<option value="4">Outros</option>
								</select>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-3 col-lg-3 form-group">
								<p>Interesse no programa Novotec: </p>
							</div>
							<div className="col-12 col-md-3 col-lg-3 form-group">
								<select
									required
									className="form-control"
									name="lblMotivo"
									id="lblMotivo"
									value={lblInteresseNovoTec}
									onChange={event =>
										setlblInteresseNovoTec(
											event.target.value
										)
									}
								>
									<option>Não</option>
									<option value="1">Sim</option>
								</select>
							</div>
						</div>
						{lblInteresseNovoTec === '1' && (
							<div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Opção Um: </p>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<SelectCursoNovoTec
											setCursoNovoTec={
												setlblEixoNovotecOpcaoUm
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Opção Dois: </p>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<SelectCursoNovoTec
											setCursoNovoTec={
												setlblEixoNovotecOpcaoDois
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Opção Três: </p>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<SelectCursoNovoTec
											setCursoNovoTec={
												setlblEixoNovotecOpcaoTres
											}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<br />
				<div className="card">
					<div className="card-header col-12 col-md-12 col-lg-12 form-group">
						<h4>Turma</h4>
					</div>
					<div className="card-body ">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Classe</p>
								<SelectClasses setClasse={setlblIdClasse} />
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>Data Matricula</p>
								<InputMask
									mask="99/99/9999"
									required
									type="text"
									name="lblDataInicioMatricula"
									id="lblDataInicioMatricula"
									className="form-control"
									placeholder="Data Matricula"
									value={lblDataInicioMatricula}
									onChange={event =>
										setlblDataInicioMatricula(
											event.target.value
										)
									}
								/>
							</div>
						</div>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-12 col-md-10 col-lg-10">
								<button
									type="submit"
									className="btn btn-danger"
									onClick={handleCancel}
								>
									Cancelar
								</button>
							</div>
							<div className="col-12 col-md-2 col-lg-2">
								<button
									type="submit"
									className="btn btn-primary"
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	);
}
