import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectTipoTelefone({
	setTipoTelefone,
	valueSelect,
	disabled,
}) {
	const [tiposTelefones, setTiposTelefones] = useState([]);

	useEffect(() => {
		async function loadTiposTelefones() {
			const response = await api.get('/tabelas/tipos-telefones');
			setTiposTelefones(response.data.data);
		}
		loadTiposTelefones();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(tiposTelefones) {
		setTipoTelefone(tiposTelefones);
	}
	const TiposTelefoneList = tiposTelefones.map(t => (
		<option value={t.id_tipo_telefone} key={t.id_tipo_telefone}>
			{t.desc_tipo_telefone}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblTipoTelefone"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Tipo de Telefone</option>
				{TiposTelefoneList}
			</select>
		</>
	);
}
