import React, { useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { FaEraser } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function FormDisassociate() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_PARENTS,
			namePage: 'Responsáveis',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Desassociar Responsável',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblCpfResponsavel, setlblCpfResponsavel] = useState('');
	const [lblNomeResponsavel, setlblNomeResponsavel] = useState('');
	const [lblFilhos, setlblFilhos] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setisVisible] = useState(false);
	const [changeCPF, setChangeCPF] = useState(false);
	const [editableCpf, setEditableCpf] = useState(false);

	async function handleChangeCPF(event) {
		event.preventDefault();
		setIsLoading(false);
		setisVisible(false);
		setChangeCPF(false);
		setEditableCpf(false);
		setlblCpfResponsavel('');
		setlblNomeResponsavel('');
	}
	async function handleExistCPF(event) {
		event.preventDefault();
		const lengthCpf = lblCpfResponsavel.replace(/[-_.+()\s]/g, '');
		if (lblCpfResponsavel && lengthCpf.length >= 11) {
			const { data } = await api.get('/usuario/verifica-cpf-usuario', {
				params: {
					lblCpf: lblCpfResponsavel,
				},
			});
			if (data.error === false) {
				setIsLoading(true);
				setisVisible(true);
				setEditableCpf(true);
				setChangeCPF(true);
				setlblNomeResponsavel(data.data.usuario.responsavel);
				setlblFilhos(data.data.filhos);
			} else swal('ERRO!', `${data.message}`, 'error');
			setIsLoading(false);
		} else swal('ATENÇÃO', 'CPF inválido.', 'warning');
	}
	async function handleDesassociate(idPaiHasFilho) {
		try {
			const data = new FormData();
			data.append('lblNomeResponsavel', lblNomeResponsavel);
			data.append('lblCpfResponsavel', lblCpfResponsavel);
			data.append('lblIdPaiHasFilho', idPaiHasFilho);
			await api
				.post('/responsavel/desassociar/responsavel', data)
				.then(response => {
					if (!response.data.error) {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						history.push(
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_PARENTS
						);
					} else swal('ERRO!', `${response.data.message}`, 'error');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Consulte o CPF</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-3 col-lg-3 mt-2 form-group">
							<span>CPF do Responsável : </span>
						</div>
						<div className="col-12 col-md-7 col-lg-7 form-group">
							<div className="input-group mb-4">
								<InputMask
									mask="999.999.999-99"
									required
									disabled={changeCPF}
									size={16}
									type="text"
									name="lblCpfResponsavel"
									id="lblCpfResponsavel"
									className="form-control"
									placeholder="CPF do Responsável"
									value={lblCpfResponsavel}
									onChange={event =>
										setlblCpfResponsavel(event.target.value)
									}
								/>
								<div className="input-group-append">
									{editableCpf === false ? (
										<button
											onClick={handleExistCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Consultar
										</button>
									) : (
										<button
											onClick={handleChangeCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Mudar
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-3 col-lg-3">
							<span>Nome do Responsável : </span>
						</div>
						<div className="col-12 col-md-4 col-lg-4">
							<span>{lblNomeResponsavel}</span>
						</div>
					</div>
				</div>
			</div>
			{isLoading && isVisible && <div className="lds-dual-ring" />}
			{isLoading === false && isVisible && (
				<>
					{lblFilhos.map(element => (
						<div key={element.id}>
							<div className="card mt-3">
								<div className="card-header">
									<div className="row">
										<div className="col-12 col-md-11 col-lg-11 form-group">
											<h4>Aluno</h4>
										</div>
										<Tooltip
											title="Desassociar Responsável."
											placement="top-start"
										>
											<div className="col-12 col-md-1 col-lg-1 form-group">
												<a
													href="#"
													onClick={() =>
														handleDesassociate(
															element.idPaiHasFilho
														)
													}
												>
													<FaEraser />
												</a>
											</div>
										</Tooltip>
									</div>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Nome Aluno</b>
											</p>
											<p>{element.nome}</p>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Data Nascimento Aluno</b>
											</p>
											<p>{element.aniversario}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Nome Mãe do Aluno</b>
											</p>
											<p>{element.mae}</p>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Registro do Aluno</b>
											</p>
											<p>{element.ra}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</>
			)}
		</>
	);
}
