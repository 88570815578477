import React, { useRef, useState } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function ModalDelete({ userActualType, schoolInfo }) {
	const [communicated, setCommunicated] = useState('');
	const [subject, setSubject] = useState('');
	const files = useRef(null);
	const [isLoadingCommunicated, setSendLoading] = useState(false);

	async function handleComunicar(event) {
		event.preventDefault();
		setSendLoading(true);
		try {
			const data = new FormData();
			data.append('subject', subject);
			data.append('communicated', communicated);
			data.append('tipoUsuarioLogado', userActualType);
			data.append('target', schoolInfo.id_escola);
			Object.values(files.current.files).map(file =>
				data.append(Math.random(), file)
			);

			const response = await api.post('/comunicados/enviar/escola', data);

			if (!('data' in response)) throw response;
			if (response.data.error) throw response.data.message;

			swal('SUCESSO!', response.data.message, 'success');
			setSendLoading(false);
			const dataTransfer = new DataTransfer();
			files.current.files = dataTransfer.files;
			setCommunicated('');
			setSubject('');
		} catch (error) {
			setSendLoading(false);
			swal('Ops!', error, 'warning');
		}
	}

	return (
		<div
			className="modal fade"
			id="modalComunicated"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<form onSubmit={handleComunicar}>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						{isLoadingCommunicated === true && (
							<div className="lds-dual-ring" />
						)}
						{isLoadingCommunicated === false && (
							<>
								<div className="modal-header">
									<h5
										className="modal-title"
										id="exampleModalLabel"
									>
										Comunicar Escola
									</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Fechar"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-12 form-group">
											<p>Assunto</p>
											<input
												type="text"
												max="60"
												name="subject"
												id="subject"
												className="form-control"
												placeholder="Assunto"
												value={subject}
												required
												onChange={event =>
													setSubject(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-12 col-lg-12 form-group">
											<p>Comunicado</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-12 col-lg-12 form-group">
											<textarea
												required
												className="form-control"
												value={communicated}
												onChange={event =>
													setCommunicated(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 form-group">
											<p>
												<p>Imagens</p>
											</p>
											<input
												type="file"
												className="form-control-file"
												ref={files}
												multiple
											/>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-dismiss="modal"
									>
										Fechar
									</button>
									<button
										type="submit"
										className="btn btn-warning"
									>
										Comunicar
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
