import React from 'react';
import Routes from './data/Routes';
import PrivateRouter from './PrivateRouter';
import Level10 from '../pages/Level_10';
import ListStudents from '../pages/Students/MonitorStudents';

export default function RoutesTransportMonitor({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[14]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Level10}
			/>
			<PrivateRouter
				userTypes={[14]}
				path={LOGGED_ROUTES.LIST_STUTENTS_HAS_MONITOR}
				exact
				component={ListStudents}
			/>
		</>
	);
}
