import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import SelectSerieAno from '../../../components/Selects/SelectSerieAno';
import SelectDisciplinas from '../../../components/Selects/SelectDisciplinas';
import api from '../../../services/api';
import Table from '../../../components/Table';
import RouteParams from '../../../routes/data/RouteParams';

export default function() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const [idSerieAno, setIdSerieAno] = useState(null);
	const [idDisciplina, setIdDisciplina] = useState(null);
	const [turma, setTurma] = useState(null);
	const [listAttribution, setListAttribution] = useState([]);
	const [listTurma] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atribuições',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleSearch() {
		const dataPost = {
			turma,
			idSerieAno,
			idDisciplina,
		};
		const { data } = await api.post(
			'/atribuicao/lista-atribuicao',
			dataPost
		);
		if (data.error === false) setListAttribution(data.data.atribuicao);
		else swal({ icon: 'error', text: data.message });
	}

	useEffect(() => {
		handleSearch();
	}, []);

	const listTurmas = listTurma.map(t => (
		<option value={t} key={t}>
			{t}
		</option>
	));

	function OptionsButton({ idAttribution }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_06_ROUTES.ATTRIBUTION.ATTRIBUTION_ID
			] = idAttribution;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_ATTRIBUTION,
				state: params,
			});
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(idAttribution)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header">
					<h4>Atribuições</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Disciplina</b>
							</label>
							<SelectDisciplinas
								setDisciplina={setIdDisciplina}
								valueSelect={idDisciplina}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<SelectSerieAno
								setIdSerieAno={setIdSerieAno}
								valueSelect={idSerieAno}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Turma</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={turma}
								onChange={event => setTurma(event.target.value)}
							>
								<option value="">Escolha uma opção</option>
								{listTurmas}
							</select>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<button
							onClick={handleSearch}
							className="btn btn-primary"
							type="button"
							id="button-addon2"
						>
							Filtrar
						</button>
						<Link
							to={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).ADD_FILES_ATTRIBUTION
							}
						>
							<button
								className="btn btn-primary"
								type="button"
								id="button-addon2"
							>
								Novo Arquivo
							</button>
						</Link>
					</div>
					<hr />
					<div className="text-size-table">
						<Table
							header={[
								'Disciplina',
								'Classe',
								'Carga Horária',
								'Professor',
								'Professor substituto',
								'',
							]}
							body={listAttribution.map(attribution => {
								return [
									attribution.nome_disciplina,
									`${attribution.desc_serie_ano} - ${attribution.desc_tipo_ensino} - ${attribution.turma}`,
									attribution.carga_horaria_semanal,
									attribution.nome_usuario
										? attribution.nome_usuario
										: 'Não há professor atribuido',
									attribution.nome_professor_substituto
										? attribution.nome_professor_substituto
										: 'Não há professor atribuido',
									<OptionsButton
										idAttribution={
											attribution.id_atribuicao
										}
									/>,
								];
							})}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
