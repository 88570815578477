import React from 'react';
import PrivateRouter from './PrivateRouter';
import Level_05 from '../pages/Home/HomeLevel05';
import Student from '../pages/Students';
import UpdateStudents from '../pages/Students/UpdateStudents';
import ProfileStudent from '../pages/Students/Info';
import TransferSchool from '../pages/Students/UpdateStudents/transferStudent';
import SubscriptionStudents from '../pages/Students/SubscriptionStudentsInSchool';//TODO esse cara prescisar sofrer ajustes
import ClaimDeathStudents from '../pages/Students/ClaimDeathStudents';
import EditFilesAttribution from '../pages/Attribution/FilesAttribution/EditFilesAttribution';
import TransferStudentClass from '../pages/Students/TransferStudent';
import ReclassifyStudent from '../pages/Students/ReclassifyStudent';
import ConfirmReclassifyStudents from '../pages/Students/ReclassifyStudent/ConfirmReclassifyStudents';
import RearrangeStudent from '../pages/Students/RearrangeStudent';
import Communicated from '../pages/Communicated';
import WriteCommunicated from '../pages/Communicated/WriteCommunicated';
import ReadCommunicatedInbox from '../pages/Communicated/ReadCommunicatedInbox';
import ReadCommunicatedSendBox from '../pages/Communicated/ReadCommunicatedSendBox';
import AddCommunicatedMessage from '../pages/Communicated/AddCommunicatedMessage';
import Schools from '../pages/School';
import InfoSchool from '../pages/School/Info';
import ListClass from '../pages/School/Classe';
import InfoClass from '../pages/School/Classe/InfoClass';
import ListSeries from '../pages/School/Series';
import InfoSeries from '../pages/School/Series/InfoSeries';
import Occurrences from '../pages/Occurrences/Occurrences';
import Messages from '../pages/Occurrences/Messages';
import ListTeacher from '../pages/Teachers';
import ProfileTeacher from '../pages/Teachers/InfoTeacher/ProfileTeacher';
import ListParents from '../pages/Parents';
import RegisterParents from '../pages/Parents/RegisterParent';
import DisassociateParent from '../pages/Parents/DisassociateParent';
import SubjectsContents from '../pages/Subjects';
import Attribution from '../pages/Subjects/Attribution';
import AddAttribution from '../pages/Subjects/Attribution/AddFilesAttribution';
import Notices from '../pages/Notices';
import ListNotices from '../pages/Notices/ListNotices';
import ReadNotices from '../pages/Notices/ReadNotices';
import EditNotices from '../pages/Notices/EditNotices';
import Coordinator from '../pages/Coordinator';
import AddCoordinator from '../pages/Coordinator/AddCoordinator';
import InfoCoordinator from '../pages/Coordinator/InfoCoordinator';
import InfoProfile from '../pages/Profile/InfoProfile';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Profile/ChangeContact';
import Ticket from '../pages/Ticket';
import CreateTicket from '../pages/Ticket/Create';
import ListEmployee from '../pages/Employees/ListEmployee'
import ViewTicket from '../pages/Ticket/View';
import ListSupplies from '../pages/OfficeSupplies';
import ViewSupplies from '../pages/OfficeSupplies/View';
import CreateSupplies from '../pages/OfficeSupplies/Create';
import ClockPoints from '../pages/ClockPoints';
import InfoTeacher from '../pages/Teachers/InfoTeacher';

import Routes from './data/Routes';

export default function RoutesSchoolPrincipalSecretary({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Level_05}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.STUDENTS}
				exact
				component={Student}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.PROFILE_STUDENT}
				exact
				component={UpdateStudents}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.STUDENT_INFO}
				exact
				component={ProfileStudent}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.TRANSFER_STUDENT}
				exact
				component={TransferSchool}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.SUBSCRIPTION_STUDENTS}
				exact
				component={SubscriptionStudents}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.CLAIM_DEATH_STUDENTS}
				exact
				component={ClaimDeathStudents}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.SCHOOLS}
				exact
				component={Schools}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.SCHOOL_INFO}
				exact
				component={InfoSchool}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.COMMUNICATED}
				exact
				component={Communicated}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.WRITE_COMMUNICATED}
				exact
				component={WriteCommunicated}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_RECEIVE}
				exact
				component={ReadCommunicatedInbox}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_SEND}
				exact
				component={ReadCommunicatedSendBox}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.ADD_COMMUNICATED_MESSAGE}
				exact
				component={AddCommunicatedMessage}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.LIST_CLASSES}
				exact
				component={ListClass}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.INFO_CLASS}
				exact
				component={InfoClass}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.INFO_CLASS_TRANSFER_STUDENT}
				exact
				component={TransferStudentClass}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.LIST_SERIES}
				exact
				component={ListSeries}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.INFO_SERIES}
				exact
				component={InfoSeries}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.OCCURRENCES}
				exact
				component={Occurrences}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.MESSAGES}
				exact
				component={Messages}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.LIST_TEACHERS}
				exact
				component={ListTeacher}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.LIST_PARENTS}
				exact
				component={ListParents}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.REGISTER_PARENTS}
				exact
				component={RegisterParents}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.DISASSOCIATE_PARENT}
				exact
				component={DisassociateParent}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT}
				exact
				component={SubjectsContents}
			/>
			{/* TODO Essa página não está sendo utilizada atualmente */}
			{/* <PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT_INFO}
				exact
				component={InfoContent}
			/> */}
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.NOTICES}
				exact
				component={Notices}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.LIST_NOTICES}
				exact
				component={ListNotices}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.READ_NOTICES}
				exact
				component={ReadNotices}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.EDIT_NOTICES}
				exact
				component={EditNotices}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.COORDINATOR}
				exact
				component={Coordinator}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.ADD_COORDINATOR}
				exact
				component={AddCoordinator}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.INFO_ATTRIBUTION}
				exact
				component={EditFilesAttribution}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.INFO_COORDINATOR}
				exact
				component={InfoCoordinator}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.RECLASSIFY_STUDENT}
				component={ReclassifyStudent}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.LIST_RECLASSIFY_STUDENT}
				component={ConfirmReclassifyStudents}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.REARRANGE_STUDENT}
				component={RearrangeStudent}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.ATTRIBUTION}
				component={Attribution}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.ADD_FILES_ATTRIBUTION}
				component={AddAttribution}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.LIST_TICKETS}
				component={Ticket}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.CREATE_TICKET}
				component={CreateTicket}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.VIEW_TICKET}
				component={ViewTicket}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.LIST_SUPPLIES}
				component={ListSupplies}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.VIEW_SUPPLIES}
				component={ViewSupplies}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.CREATE_SUPPLIES}
				component={CreateSupplies}
			/>

			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.CLOCK_POINTS}
				component={ClockPoints}
			/>

			<PrivateRouter
				userTypes={[3, 4, 5]}
				exact
				path={LOGGED_ROUTES.PROFILE_TEACHER}
				component={ProfileTeacher}
			/>
			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.INFO_EMPLOYEE}
				exact
				component={InfoTeacher}
			/>

			<PrivateRouter
				userTypes={[3, 4, 5]}
				path={LOGGED_ROUTES.LIST_EMPLOYEE}
				exact
				component={ListEmployee}
			/>
		</>
	);
}
