import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';

export default function() {
	const [classes, setClasses] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			const response = await api.get('/coordenador/lista/todas-turmas');
			setIsLoading(false);
			if (!response.data.error) setClasses(response.data.data);
		})();
	}, []);
	return (
		<>
			<nav aria-label="breadcrumb" id="navBreadCrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link
							to={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).HOME
							}
						>
							Home
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						Lista de Classes
					</li>
				</ol>
			</nav>
			{isLoading === true && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<div className="card">
					<div className="card-body">
						<Table
							header={['Classe', 'Turma', 'Ano', 'Escola', '']}
							body={classes.map(classe => {
								return [
									classe.desc_serie_ano,
									classe.turma,
									classe.desc_tipo_ensino,
									classe.desc_nome_escola,
								];
							})}
						/>
					</div>
				</div>
			)}
		</>
	);
}
