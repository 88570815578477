import React, { useRef, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import DatePicker, { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR';
import Select from 'react-select';
import swal from 'sweetalert';
import parseJwt from '../../../../helpers/parseJwt';
import api from '../../../../services/api';
import 'react-datepicker/dist/react-datepicker.css';

export default function CardModules({ loadAttibution, idAttribution }) {
	registerLocale('br', br);

	const [tittle, setTittle] = useState('');
	const [description, setDescription] = useState('');
	const [videos, setVideos] = useState([]);
	const files = useRef(null);
	const [libaryOptions, setLibraryOptions] = useState({});

	const [disableButton, setDisableButton] = useState(false);
	const [buttonMessage, setButtonMessage] = useState('Adicionar');
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	async function handleGetVideos() {
		const response = await api.get(`/biblioteca/buscar-videos/${idUser}`);
		setVideos(response.data);
	}

	const options = [
		videos
			? videos.map(video => {
					return {
						value: video.id_biblioteca_video,
						label: video.nome,
					};
			  })
			: null,
	];

	async function handleAddModules() {
		setButtonMessage('Carregando...');
		setDisableButton(true);
		try {
			const data = new FormData();
			data.append('tittle', tittle);
			data.append('description', description);
			data.append('idAttribution', idAttribution);
			// data.append('videos', libaryOptions);
			if (Object.keys(libaryOptions).length) {
				const value = libaryOptions.map(item => item.value);
				data.append('videos', value);
			}
			// libaryOptions.map(item=>data.append(item.value,item.value));
			Object.values(files.current.files).map(file =>
				data.append(Math.random(), file)
			);

			const response = await api.post('/atribuicao/criar-modulos', data);

			if (!('data' in response)) throw response.data.message;
			if (response.data.error) throw response.data.message;

			swal('SUCESSO!', response.data.message, 'success');
			setTittle('');
			setDescription('');
			const dataTransfer = new DataTransfer();
			files.current.files = dataTransfer.files;
		} catch (error) {
			swal('Ops!', error, 'warning');
			console.log(`error: ${error}`);
		}
		loadAttibution();
		setButtonMessage('Adicionar');
		setDisableButton(false);
	}

	useEffect(() => {
		handleGetVideos();
	}, []);

	return (
		<>
			<button
				className="btn buttonCustomSemec"
				type="button"
				id="button"
				data-toggle="modal"
				data-target="#addVehicle"
			>
				Adicionar nova Aula
			</button>
			<div className="modal fade" id="addVehicle">
				<div
					className="modal-dialog modal-dialog-scrollable"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Adicionar Aula</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group text-left">
								<label htmlFor="add_tittle">Assunto:</label>
								<input
									type="text"
									className="form-control"
									id="add_tittle"
									placeholder="Assunto"
									value={tittle}
									onChange={event =>
										setTittle(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="add_description">
									Descrição:
								</label>
								<input
									type="text"
									className="form-control"
									id="add_description"
									placeholder="Descrição"
									value={description}
									onChange={event =>
										setDescription(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="add_description">
									Biblioteca:
								</label>
								<Select
									onChange={setLibraryOptions}
									isMulti
									options={options[0]}
								/>
							</div>

							<div className="form-group text-left">
								<p>
									<b>Arquivos</b>
								</p>
								<input
									type="file"
									className="form-control-file"
									ref={files}
									multiple
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
							{tittle === '' || description === '' ? (
								<button
									type="button"
									className="btn btn-disabled btn-block"
									disabled
								>
									{buttonMessage}
								</button>
							) : (
								<button
									type="button"
									onClick={handleAddModules}
									disabled={disableButton}
									className="btn btn-primary btn-block"
								>
									{buttonMessage}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
