import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InfoUser from '../../../components/InfoUser/InfoUser';

export default function ProfileTeacher() {
	const location = useLocation();
	const [idUsuario, setIdUsuario] = useState(null);

	useEffect(() => {
		setIdUsuario(location.state.idUsuario);
	}, []);

	return <InfoUser idUsuario={idUsuario} />;
}
