import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Table from '../../../components/Table';
import api from '../../../services/api';

export default function ReportCard({ idStudent, classeID }) {
	const [notes, setNotes] = useState([]);
	const [faults, setFaults] = useState([]);

	useEffect(() => {
		(async function() {
			if (idStudent && classeID) {
				const response = await api.get('/aluno/boletim-novo', {
					params: {
						alunoID: idStudent,
						classeID,
					},
				});
				if (!response.data.error) {
					setNotes(response.data.data.notas);
					setFaults(response.data.data.faltas);
				} else swal('Boletim do aluno não está no sistema');
			}
		})();
	}, [idStudent, classeID]);

	return (
		<>
			<div className="card mt-3">
				<div className="card-body">
					<div className="row">
						<div className="col-12">
							<b>Aluno(a): </b>
						</div>
					</div>
					<br />
					<Table
						header={[
							'Disciplina',
							'1º Bimestre',
							'2º Bimestre',
							'3º Bimestre',
							'4º Bimestre',
							'Média',
						]}
						body={notes.map(student => {
							return [
								student.nome_disciplina,
								student.nota_bimestral1,
								student.nota_bimestral2,
								student.nota_bimestral3,
								student.nota_bimestral4,
								student.nota_media_final,
							];
						})}
					/>
				</div>
			</div>
			<div className="card mt-3">
				<div className="card-body">
					<div className="row">
						<div className="col-12">
							<b>Faltas Aluno: </b>
						</div>
						<div className="col-12">
							<Table
								header={[
									'Disciplina',
									'Jan',
									'Fev',
									'Mar',
									'Abr',
									'Mai',
									'Jun',
									'Jul',
									'Ago',
									'Set',
									'Out',
									'Nov',
									'Dez',
								]}
								body={faults.map(attendance => {
									return [
										attendance.disciplina,
										attendance.jan,
										attendance.fev,
										attendance.mar,
										attendance.abr,
										attendance.mai,
										attendance.jun,
										attendance.jul,
										attendance.ago,
										attendance.set,
										attendance.out,
										attendance.nov,
										attendance.dez,
									];
								})}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
