import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../components/Breadcrumb';
import api from '../../../services/api';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function ContentSubjects() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COURSE_CONTENT,
			namePage: 'Conteúdos Programáticos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [subject, setSubject] = useState([]);
	const [subjectID, setIDSubject] = useState([]);
	const [content, setContent] = useState([]);
	const [btnDisabled, setBtnDisabled] = useState(true);

	useEffect(() => {
		(async function() {
			const response = await api.get('/aluno/disciplinas');
			if (!response.data.error) setSubject(response.data.data);
			else setSubject([]);
		})();
	}, []);

	useEffect(() => {
		if (subjectID) setBtnDisabled(false);
	}, [subjectID]);

	function searchContent(event) {
		event.preventDefault();
		(async function() {
			const { data } = await api.get('/disciplinas/escolas/conteudos', {
				params: {
					disciplineId: subjectID,
				},
			});
			if (!data.error) setContent(data.data.conteudo);
			else swal('Ops!', `${data.message}`, 'warning');
		})();
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			<div className="row justify-content-center">
				<div className="col-lg-6 col-12 form-group">
					<select
						className="form-control"
						id="selectSubject"
						onChange={event => setIDSubject(event.target.value)}
					>
						<option value="0">Escolha a disciplina</option>
						{subject.map(subjects => (
							<option
								key={subjects.id_disciplina}
								value={subjects.id_disciplina}
							>
								{subjects.nome_disciplina}
							</option>
						))}
					</select>
				</div>
				<div className="col-lg-6 col-12 form-group">
					<button
						type="submit"
						className="btn btn-primary btn-block"
						onClick={searchContent}
						disabled={btnDisabled}
					>
						Buscar
					</button>
				</div>
			</div>

			<div className="row justify-content-center">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive">
								<table className="table table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="contentSubject"
											>
												Conteúdo da disciplina
											</th>
											<th
												scope="col"
												className="contentSubject"
											>
												Descrição Conteúdo
											</th>
										</tr>
									</thead>
									<tbody>
										{content.map(listContent => (
											<tr key={listContent.id_conteudo}>
												<td>{listContent.conteudo}</td>
												<td>
													{
														listContent.descricao_conteudo
													}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
