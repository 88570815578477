import React, { useEffect, useState } from 'react';
import { FaSchool, FaUserGraduate, FaClipboard } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import HeaderDash from '../../../components/HeaderDash';
import api from '../../../services/api';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function CardsCoordenator() {
	const [countClass, setCountClass] = useState('');
	const [countStudents, setCountStudents] = useState('');
	const [countSchools, setCountSchools] = useState('');

	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		async function loadTeacherDetails() {
			const response = await api.get('/coordenador');
			if (response.data.error)
				swal('ERRO!', `${response.data.data.message}`, 'error');
			setCountSchools(response.data.data.countSchools);
			setCountClass(response.data.data.countClass);
			setCountStudents(response.data.data.countStudents);
		}
		loadTeacherDetails();
	});
	const cardsCoordinator = [
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Escolas',
			headerIcon: <FaSchool />,
			headerNumber: `${countSchools}`,
			iconBackGroundColor: 'bg-light-blue',
			iconTextColor: 'text-blue',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).SCHOOLS,
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Estudantes',
			headerIcon: <FaUserGraduate />,
			headerNumber: `${countStudents}`,
			iconBackGroundColor: 'bg-light-yellow',
			iconTextColor: 'text-orange',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).STUDENTS,
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Classes/Turmas',
			headerIcon: <FaClipboard />,
			headerNumber: `${countClass}`,
			iconBackGroundColor: 'bg-light-red',
			iconTextColor: 'text-red',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).LIST_CLASSES_COORDINATOR,
		},
	];
	return <HeaderDash headerItems={cardsCoordinator} />;
}
