import React from 'react';
import { Route } from 'react-router-dom';
import parseJwt from '../helpers/parseJwt';
import Main from '../components/Main';

const PrivateRouter = ({ userTypes, component: Component, ...rest }) => {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);

	const isAuthenticated =
		tokenUsuario !== null &&
		tokenUsuario.tipo_usuario.findIndex(
			item =>
				userTypes
					.map(userType => item === userType)
					.findIndex(idx => idx === true) !== -1
		) !== -1;

	return (
		isAuthenticated && (
			<Route
				{...rest}
				render={props => (
					<Main>
						<Component {...props} />
					</Main>
				)}
			/>
		)
	);
};

export default PrivateRouter;
