import React, { useState, Fragment, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import api from '../../../services/api';

export default function CardStudents({ setStudents }) {
	const [listStudents, setListStudents] = useState([]);
	const [inputFields, setInputFields] = useState([
		{ idStudents: '', embarque: '', destino: '' },
	]);

	function loadMonitors() {
		api.get('/aluno/lista')
			.then(response => response.data.data)
			.then(setListStudents)
			.catch(() => setListStudents([]));
	}
	useEffect(() => {
		loadMonitors();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ idStudents: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
		setStudents(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'idStudents')
			values[index].idStudents = event.target.value;
		if (event.target.name === 'embarque')
			values[index].embarque = event.target.value;
		if (event.target.name === 'destino')
			values[index].destino = event.target.value;
		setStudents(values);
		setInputFields(values);
	};

	return (
		<div className="row">
			{inputFields.map((inputField, index) => (
				<Fragment
					// eslint-disable-next-line react/no-array-index-key
					key={`${inputField}~${index}`}
				>
					<div className="form-group col-12 col-lg-4 col-md-4">
						<select
							name="idStudents"
							className="form-control"
							id="idStudents"
							onChange={event => handleInputChange(index, event)}
						>
							<option value="">Escolha um Aluno</option>
							{listStudents
								? listStudents.map(student => (
										<option
											key={student.id_usuario}
											value={student.id_usuario}
										>
											{student.nome_usuario} -{' '}
											{student.num_ra}
										</option>
								  ))
								: null}
						</select>
					</div>
					<div className="form-group col-12 col-lg-3 col-md-3">
						<input
							type="text"
							name="embarque"
							id="embarque"
							required
							className="form-control"
							placeholder="Embarque"
							onChange={event => handleInputChange(index, event)}
						/>
					</div>
					<div className="form-group col-12 col-lg-3 col-md-3">
						<input
							type="text"
							name="destino"
							id="destino"
							required
							className="form-control"
							placeholder="Destino"
							onChange={event => handleInputChange(index, event)}
						/>
					</div>
					<div className="form-group col-1 col-lg-1 col-md-1">
						{inputFields.length > 1 && (
							<button
								className="btn btn-danger formRouteStudentsAddRemoveButtons"
								type="button"
								onClick={() => handleRemoveFields(index)}
							>
								<FaMinus fill="#ffffff" />
							</button>
						)}
					</div>
					<div className="form-group col-1 col-lg-1 col-md-1">
						<button
							className="btn btn-success formRouteStudentsAddRemoveButtons"
							type="button"
							onClick={() => handleAddFields()}
						>
							<FaPlus fill="#ffffff" />
						</button>
					</div>
				</Fragment>
			))}
		</div>
	);
}
