import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../../services/api';
import Panel from '../../../components/Panel';
import Breadcrumb from '../../../components/Breadcrumb';
import Footer from '../../../components/Footer';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);

	const [studentList, setStudentList] = useState([]);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [subjects, setSubjects] = useState([]);
	const [subjectIDTeacher, setSubjectIDTeacher] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledClass, setDisabledClass] = useState(true);
	const [schools, setSchools] = useState([]);
	const [schoolId, setSchoolId] = useState(null);
	const [classes, setClasses] = useState([]);
	const [classId, setClassId] = useState(null);

	const breadCrumb = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.REPROVED_LIST,
			namePage: 'Reprovados',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			const dataPost = {
				typeUser: userActualType,
			};
			const response = await api.post('/usuario/escola', dataPost);
			if (!response.data.error) setSchools(response.data.data);
			else setSchools([]);
		})();
	}, []);

	useEffect(() => {
		if (schoolId)
			(async function() {
				const dataPost = {
					schoolId,
					typeUser: localStorage.getItem('@actualTypeId'),
				};
				const response = await api.post(
					'/usuario/escola/classe',
					dataPost
				);
				if (response.data.error) {
					setClasses([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClasses(response.data.data);
				}
			})();
	}, [schoolId]);

	useEffect(() => {
		(async function() {
			if (classId) {
				const dataPost = {
					classId,
					schoolId,
					typeUser: localStorage.getItem('@actualTypeId'),
				};
				const response = await api.post(
					'/usuario/escola/classe/disciplinas',
					dataPost
				);
				if (response.data.error) {
					setSubjects([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjects(response.data.data);
				}
			}
		})();
	}, [classId]);

	useEffect(() => {
		if (classId) setDisabledBtn(false);
	}, [classId]);

	async function searchStudents() {
		(async function() {
			const response = await api.get('/aluno/alunosreprovados', {
				params: {
					escolaID: schoolId,
					classeID: classId,
					disciplinaID: subjectIDTeacher,
				},
			});
			if (!response.data.error) setStudentList(response.data.data);
			else {
				setStudentList([]);
				swal('ATENÇÃO', `${response.data.message}`, 'warning');
			}
		})();
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumb} />
			<div className="card mb-4">
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="schools">
								<b>Escola</b>
							</label>
							<select
								className="form-control"
								name="schools"
								id="schools"
								onChange={event =>
									setSchoolId(event.target.value)
								}
							>
								<option value="">Escolha a Escola</option>
								{schools.map(schools => (
									<option
										key={schools.id_escola}
										value={schools.id_escola}
									>
										{schools.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="classes">
								<b>Série/Turma</b>
							</label>
							<select
								id="classes"
								className="form-control"
								onChange={event =>
									setClassId(event.target.value)
								}
								disabled={disabledClass}
							>
								<option value="">Escolha a série</option>
								{classes.map(classes => (
									<option
										key={classes.id_classe}
										value={classes.id_classe}
									>
										{classes.desc_serie_ano} -{' '}
										{classes.turma}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="subjectTeacher">
								<b>Disciplina</b>
							</label>
							<select
								className="form-control"
								name="subjectTeacher"
								id="subjectTeacher"
								onChange={event =>
									setSubjectIDTeacher(event.target.value)
								}
								disabled={disabledSubjects}
							>
								<option value="">Escolha a disciplina</option>
								{subjects.map(subjects => (
									<option
										key={subjects.id_disciplina}
										value={subjects.id_disciplina}
									>
										{subjects.nome_disciplina}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col-lg-6 col-12 form-group">
							<button
								type="button"
								className="btn btn-block btn-success"
								onClick={searchStudents}
								disabled={disabledBtn}
							>
								<FaSearch className="searchIcon" />
								Buscar Alunos
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<div className="text-size-table">
						<Table
							header={[
								'RA',
								'Nome Aluno',
								'Justificativa',
								'Nota',
								'Frequência',
							]}
							body={studentList.map(student => {
								return [
									student.num_ra,
									student.nome_usuario,
									student.disciplinas[0].justificativa,
									student.disciplinas[0].nota_media_final,
									student.disciplinas[0].frequencia,
								];
							})}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
