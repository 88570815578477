import React, { useEffect, useState } from 'react';
import { FaCheck, FaClock } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../services/api';
import Table from '../../components/Table';
import parseJwt from '../../helpers/parseJwt';
import HeaderDash from '../../components/HeaderDash';

function ButtonStudentDeliveredSchool({ student, setStatus }) {
	function editNavigation(e) {
		setStatus([student, e]);
	}

	return (
		<>
			<select
				className="form-control"
				onChange={event => editNavigation(event.target.value)}
			>
				<option value="">Escolha uma opção</option>
				<option value="Embarcou no local">Embarcou no local</option>
				<option value="Desembarcou na escola">
					Desembarcou na escola
				</option>
				<option value="Embarcou na escola">Embarcou na escola</option>
				<option value="Desembarcou no local">
					Desembarcou no local
				</option>
				<option value="Não embarcou no local">
					Não compareceu no local
				</option>
				<option value="Não embarcou na escola">
					Não compareceu na escola
				</option>
			</select>
		</>
	);
}

function ButtonStudentAttendedSchool({ student, status }) {
	async function editNavigation(e) {
		if (status[1] !== '') {
			const dataPost = {
				idRaStudent: student,
				typeTransport: status[1],
			};
			const { data } = await api.post(
				'/transporte/monitor/chamada/criar',
				dataPost
			);
			if (data.error === false)
				swal({ icon: 'sucess', text: data.message });
			else
				swal({
					icon: 'error',
					text:
						'Houve um erro para notificar os parentes desse aluno',
				});
		} else
			swal({ icon: 'warning', text: 'Escolha uma opção para o aluno' });
	}

	return (
		<>
			<button
				type="button"
				aria-hidden
				disabled={!(status.indexOf(student) > -1)}
				data-toggle="tooltip"
				title="Editar Motorista"
				className="buttonAddColor"
				onClick={() => editNavigation(student)}
			>
				<FaCheck fill="#ffffff" />
			</button>
		</>
	);
}

export default function ListMonitorHasStudents() {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const { name } = tokenUsuario;
	const [isLoading, setIsLoading] = useState(true);
	const [listStudents, setListStudents] = useState([]);
	const [turnos, setTurnos] = useState([]);
	const [status, setStatus] = useState([]);
	const date = new Date();
	const day = `${date.getDate()}/${date.getMonth() +
		1}/${date.getFullYear()}`;

	async function loadStudents() {
		setIsLoading(true);
		const { data } = await api.get('/transporte/monitor/chamada');
		if (data.error === false) {
			setListStudents(data.data.listMonitorHasStudents);
			setTurnos(data.data);
		} else {
			setListStudents([]);
			swal({ icon: 'warning', text: data.message });
		}
		setIsLoading(false);
	}
	useEffect(() => {
		loadStudents();
	}, []);

	const turnosQuantity = [
		{
			headerColSize: 'col-lg-3 col-md-10 col-12 pb-2',
			headerLabel: 'Manhã',
			headerIcon: <FaClock />,
			headerNumber: turnos.manha,
			iconBackGroundColor: 'bg-light-yellow',
			iconTextColor: 'text-orange',
		},
		{
			headerColSize: 'col-lg-3 col-md-10 col-12 pb-2',
			headerLabel: 'Tarde',
			headerIcon: <FaClock />,
			headerNumber: turnos.tarde,
			iconBackGroundColor: 'bg-light-blue',
			iconTextColor: 'text-blue',
		},
		{
			headerColSize: 'col-lg-3 col-md-10 col-12 pb-2',
			headerLabel: 'Integral',
			headerIcon: <FaClock />,
			headerNumber: turnos.integral,
			iconBackGroundColor: 'bg-light-red',
			iconTextColor: 'text-red',
		},
		{
			headerColSize: 'col-lg-3 col-md-10 col-12 pb-2',
			headerLabel: 'Noite',
			headerIcon: <FaClock />,
			headerNumber: turnos.noite,
			iconBackGroundColor: 'bg-light-purple',
			iconTextColor: 'text-purple',
		},
	];

	return (
		<div className="mt-6">
			{isLoading ? (
				<div className="row pt-md-5 mt-md-3 mb-5">
					<div className="lds-dual-ring" />
				</div>
			) : (
				<>
					<HeaderDash headerItems={turnosQuantity} />
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Chamada dos Alunos</h4>
						</div>
						{listStudents.length ? (
							<>
								<div className="card-body">
									<div className="d-flex justify-content-between">
										<div className="form-group">
											<b>Monitor:</b> {name}
										</div>
										<div>
											<b>Dia: </b>
											{day}
										</div>
									</div>
									<Table
										header={[
											'Nome',
											'RA',
											'Status',
											'Confirmar',
										]}
										body={listStudents.map(student => {
											return [
												student.nome_aluno,
												student.num_ra,

												<ButtonStudentDeliveredSchool
													student={
														student.id_registro_aluno
													}
													setStatus={setStatus}
												/>,
												<ButtonStudentAttendedSchool
													student={
														student.id_registro_aluno
													}
													status={status}
												/>,
											];
										})}
									/>
								</div>
							</>
						) : (
							<>
								<div className="card-body">
									<p>
										<b>Monitor:</b> {name}
									</p>
									Monitor não tem alunos para serem
									monitorados durante o transporte.
								</div>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
}
