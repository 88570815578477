import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router';
import TextAreaRead from '../../TextArea/TesteAreaRead';
import Breadcrumb from '../../Breadcrumb';
import api from '../../../services/api';
import '../style.css';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function EditNotices() {
	const history = useHistory();
	const { state } = useLocation();
	const [lblTituloNoticia, setlblTituloNoticia] = useState('');
	const [lblAutor, setlblAutor] = useState('');
	const [lblDataPublicacao, setlblDataPublicacao] = useState('');
	const [lblDescricao, setlblDescricao] = useState('');
	const [lblIdNoticia, setlblIdNoticia] = useState('');
	const [lblIdEscola, setlblIdEscola] = useState('');
	const [lblNewDescricao, setlblNewDescricao] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_NOTICES,
			namePage: 'Lista de Notícias',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Corrigir Notícia',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const publicacao = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	};
	async function handleDeactivate(event) {
		try {
			event.preventDefault();
			await api
				.post('/noticias/desativar', {
					lblIdNoticia,
					lblIdEscola,
				})
				.then(response => {
					if (!response.data.error) {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						history.goBack();
					} else swal('ERRO!', `${response.data.message}`, 'error');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	async function handleSubmit(event) {
		event.preventDefault();

		try {
			const response = await api.post('/noticias/atualizar', {
				lblTituloNoticia,
				lblDescricao: lblNewDescricao,
				lblIdNoticia,
				lblIdEscola,
			});

			if (!response.data.error) {
				swal('SUCESSO!', `${response.data.message}`, 'success');
				history.goBack();
			} else swal('ERRO!', `${response.data.message}`, 'error');
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	useEffect(() => {
		if (state)
			(async function() {
				const { data } = await api.get('/noticias/visualizar', {
					params: {
						idNotice:
							state[
								RouteParams.COMMON_LEVELS.EDIT_NOTICES.NOTICE_ID
							],
					},
				});
				if (data.error === false) {
					setlblIdNoticia(data.data.id_noticia);
					setlblTituloNoticia(data.data.titulo_noticia);
					setlblNewDescricao(data.data.descricao_noticia);
					setlblAutor(data.data.desc_nome_escola);
					setlblDataPublicacao(data.data.updated_at);
					setlblIdEscola(data.data.id_escola);
					setIsLoading(false);
				} else {
					swal('Notícia Não Encontrada');
					history.goBack();
				}
			})();
		else {
			swal('Escolha uma Notícia');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_NOTICES
			);
		}
	}, []);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card bg-white rounded mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Corrigir Notícia</h4>
				</div>
				<div className="card-body">
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Titulo Notícia</b>
								</p>
								<input
									required
									maxLength={100}
									type="text"
									name="lblTituloNoticia"
									id="lblTituloNoticia"
									className="form-control"
									placeholder="Titulo Notícia"
									value={lblTituloNoticia}
									onChange={event =>
										setlblTituloNoticia(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="form-group write-notices">
							<p>
								<b>Descrição Notícia</b>
							</p>
							<textarea
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="6"
								value={lblNewDescricao}
								onChange={event =>
									setlblNewDescricao(event.target.value)
								}
							/>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleSubmit}
								>
									Confirmar
								</button>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group d-flex justify-content-end">
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleDeactivate}
								>
									Apagar Notícia
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isLoading === true && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<>
					<div className="card read-notice-card mt-3">
						<div className="card-body">
							<div className="titulo-notice">
								<p>{lblTituloNoticia}</p>
							</div>
							<div className="data-notice">
								<p>
									Publicado em:{' '}
									{new Date(
										lblDataPublicacao
									).toLocaleDateString('pt-BR', publicacao)}
								</p>
							</div>
							<hr />
							<div className="descricao-noticia">
								{lblNewDescricao ? (
									<TextAreaRead
										lblComents={lblNewDescricao}
									/>
								) : (
									<TextAreaRead lblComents={lblDescricao} />
								)}
							</div>
							<div className="data-notice">
								<p>Publicado por: {lblAutor}</p>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
