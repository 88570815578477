import React from 'react';
import PrivateRouter from './PrivateRouter';
import Home from '../pages/Home/index';
import Routes from './data/Routes';

export default function UsersHomeOnlyAccess({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[
					13,
					15,
					16,
					17,
					18,
					19,
					20,
					21,
					22,
					23,
					24,
					25,
					26,
					27,
				]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Home}
			/>
		</>
	);
}
