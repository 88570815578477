import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import swal from 'sweetalert';
import Breadcrumb from '../Breadcrumb';
import api from '../../services/api';
import RouteParams from '../../routes/data/RouteParams';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../Table';

export default function ListEmployees({ breadCrumbItems }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const [employees, setEmployees] = useState([]);
	const [school, setSchool] = useState([]);
	const [schoolID, setSchoolID] = useState(null);
	const [cargoId, setCargoId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		setIsLoading(true);
		(async function() {
			const message = await api.post('/funcionarios');
			if (message.data.error === false) setEmployees(message.data.data);
			else swal('ERRO!', `${message.data.message}`, 'error');
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	async function handleSeachStudents() {
		setIsLoading(true);
		const data = {
			idSchool: schoolID,
			idTipoUsuario: cargoId,
		};
		const response = await api.post('/funcionarios/', data);
		if (!response.data.error) setEmployees(response.data.data);
		else setEmployees([]);

		setIsLoading(false);
	}

	function OptionsButton({ idEmployer }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_06_ROUTES.INFO_EMPLOYEE.USER_ID
			] = idEmployer;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_EMPLOYEE,
				state: params,
			});
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(idEmployer)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setSchoolID(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Cargo</b>
							</label>
							<select
								className="form-control"
								name="lblBolsaFamilia"
								id="lblBolsaFamilia"
								value={cargoId}
								onChange={event =>
									setCargoId(event.target.value)
								}
							>
								<option value="">Escolha um cargo</option>
								<option value="3">Diretor Ensino</option>
								<option value="4">Diretor Unidade</option>
								<option value="5">Secretaria</option>
								<option value="6">Coordenador</option>
								<option value="7">Professor</option>
								<option value="10">Cozinha Piloto</option>
								<option value="11">Nutricionista</option>
								<option value="12">
									Supervisor do Transporte
								</option>
								<option value="13">Motorista</option>
								<option value="14">Monitor</option>
							</select>
						</div>
					</div>
					<button
						onClick={handleSeachStudents}
						className="btn btn-primary"
						type="button"
						id="button-addon2"
					>
						Filtrar
					</button>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							{employees.length ? (
								<Table
									header={['Nome', 'Cargo', 'Escola', '']}
									body={employees.map(employee => {
										return [
											employee.nome_usuario,
											employee.tipo_usuario,
											employee.desc_nome_escola,
											<OptionsButton
												idEmployer={employee.id_usuario}
											/>,
										];
									})}
								/>
							) : null}
						</>
					)}
				</div>
			</div>
		</>
	);
}
