import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectDrivers({ setDriver, valueSelect, disabled }) {
	const [drivers, setDrivers] = useState([]);
	async function loadUf() {
		const response = await api.get('/tabelas/motoristas');
		setDrivers(response.data.data);
	}
	useEffect(() => {
		loadUf();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(IdDriver) {
		setDriver(IdDriver);
	}
	const ListDrivers = drivers.map(U => (
		<option value={U.id_usuario_has_veiculo} key={U.id_usuario_has_veiculo}>
			Motorista: {U.nome_usuario} - Placa: {U.placa}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="idMotorista"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha um Motorista</option>
				{ListDrivers}
			</select>
		</>
	);
}
