import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';
import CardEvaluation from '../CardEvaluation';
import parseJwt from '../../../helpers/parseJwt';
// import { Container } from './styles';

function ListEvaluation() {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;
	const userActualType = useSelector(state => state.userType.actualType);

	const [avaliacoes, setAvaliacoes] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista das Avalicações',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const getData = async () => {
		const { data } = await api.get(`/avaliacao/exibir-completa/${idUser}`);
		setAvaliacoes(data.data);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header">Avaliações</div>
				<div className="card-body">
					{avaliacoes ? (
						avaliacoes.map((avalicao, index) => {
							return (
								<CardEvaluation
									key={index}
									id={index}
									title={avalicao.titulo}
									content={avalicao}
								/>
							);
						})
					) : (
						<div className="badges-datas-eventos">
							<b>Nenhuma avaliação encontrada</b>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default ListEvaluation;
