import React from 'react';
import PrivateRouter from './PrivateRouter';
import Level_01 from '../pages/Home/HomeLevel01';
import InfoAttribution from '../pages/Attribution/InfoAttribution';
import ReportCard from '../pages/ReportCard';
import Attendance from '../pages/ReportCard/Attendance';
import CommunicatedStudent from '../pages/Communicated';
import ReadCommunicatedInbox from '../pages/Communicated/ReadCommunicatedInbox';
import AddCommunicatedMessage from '../pages/Communicated/AddCommunicatedMessage';
import ContentSubjects from '../pages/Subjects/ListSubjects/ContentSubjects';
import ReadNotices from '../pages/Notices';
import InfoProfile from '../pages/Profile/ProfileStudent';
import ChangePassWord from '../pages/Profile/ProfileStudent/ChangePassWord';
import ChangeContact from '../pages/Profile/ProfileStudent/ChangeContact';
import Routes from './data/Routes';
import SendAnswer from '../pages/Evaluation/SendAnswer';

export default function RoutesStudents({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Level_01}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.REPORT_CARD}
				exact
				component={ReportCard}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.ATTENDANCE}
				exact
				component={Attendance}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.COMMUNICATED}
				exact
				component={CommunicatedStudent}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_RECEIVE}
				exact
				component={ReadCommunicatedInbox}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.ADD_COMMUNICATED_MESSAGE}
				exact
				component={AddCommunicatedMessage}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT}
				exact
				component={ContentSubjects}
			/>
			<PrivateRouter
				userTypes={[9]}
				path={LOGGED_ROUTES.READ_NOTICES}
				exact
				component={ReadNotices}
			/>
			<PrivateRouter
				userTypes={[9]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[9]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[9]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[9]}
				exact
				path={LOGGED_ROUTES.ATTRIBUTION}
				component={InfoAttribution}
			/>

			<PrivateRouter
				userTypes={[9]}
				exact
				path={LOGGED_ROUTES.EVALUATION}
				component={SendAnswer}
			/>
			
		</>
	);
}
