import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteParams from '../../../routes/data/RouteParams';
import Table from '../../../components/Table';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function ListMonitorHasStudents() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const location = useLocation();
	const [monitor, setMonitor] = useState([]);
	const [listStudents, setListStudents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_MONITOR,
			namePage: 'Monitores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista de alunos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function loadStudents() {
		setIsLoading(true);
		if (location.state) {
			const infoUser =
				location.state.params[
					RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR
				];
			setMonitor(infoUser);
			const { data } = await api.get('/transporte/monitor/lista-alunos', {
				params: {
					monitorId: infoUser.id_usuario,
				},
			});
			if (data.error === false) setListStudents(data.data);
			else {
				setListStudents([]);
				swal({ icon: 'warning', text: data.message });
			}
		} else swal({ icon: 'warning', text: 'Escolha um monitor' });
		setIsLoading(false);
	}
	useEffect(() => {
		loadStudents();
	}, []);

	function handleCreateList() {
		const params = {};
		params[RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR] = monitor;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CREATE_LIST_MONITOR_HAS_STUDENTS,
			{ params }
		);
	}

	function handleGoBack() {
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).LIST_MONITOR
		);
	}
	function handleUpdate() {
		const params = {};
		params[RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR] = monitor;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.UPDATE_LIST_MONITOR_HAS_STUDENTS,
			{ params }
		);
	}
	function handleDeleteAll() {
		(async function() {
			const dataPost = {
				monitorId: monitor.id_usuario,
			};
			const { data } = await api.post(
				'/transporte/monitor/apagar-lista',
				dataPost
			);
			if (data.error === false)
				swal({ icon: 'success', text: data.message }).then(value => {
					loadStudents();
				});
			else swal({ icon: 'error', text: data.message });
		})();
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card mt-3">
					<div className="card-header col-12 col-md-12 col-lg-12 form-group">
						<h4>Lista de alunos monitorados</h4>
					</div>
					{listStudents.length ? (
						<>
							<div className="card-body">
								<div className="d-flex justify-content-between">
									<div className="form-group">
										<b>Monitor:</b> {monitor.nome_usuario}
									</div>
									<div className="form-group">
										<button
											className="btn btn-danger btn-block"
											type="button"
											onClick={handleDeleteAll}
										>
											Apagar lista
										</button>
									</div>
								</div>
								<Table
									header={['Nome', 'RA']}
									body={listStudents.map(student => {
										return [
											student.nome_aluno,
											student.num_ra,
										];
									})}
								/>
							</div>
							<div className="card-footer">
								<div className="d-flex justify-content-between">
									<div className="form-group">
										<button
											className="btn btn-primary btn-block"
											type="button"
											onClick={handleUpdate}
										>
											Atualizar
										</button>
									</div>
									<div className="form-group">
										<button
											className="btn btn-primary btn-block"
											type="button"
											onClick={handleGoBack}
										>
											voltar
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="card-body">
								<p>
									<b>Monitor:</b> {monitor.nome_usuario}
								</p>
								Monitor não tem alunos para serem monitorados
								durante o transporte.
							</div>
							<div className="card-footer">
								<div className="d-flex justify-content-between">
									<div className="form-group">
										<button
											className="btn btn-primary btn-block"
											type="button"
											onClick={handleCreateList}
										>
											Criar
										</button>
									</div>
									<div className="form-group">
										<button
											className="btn btn-primary btn-block"
											type="button"
											onClick={handleGoBack}
										>
											voltar
										</button>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
}
