import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Table from '../../components/Table';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Routes from '../../routes/data/Routes';
import api from '../../services/api';
import Panel from '../../components/Panel';
import SelectSerieAno from '../../components/Selects/SelectSerieAno';
import SelectDisciplinas from '../../components/Selects/SelectDisciplinas';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [listAttribution, setListAttribution] = useState([]);
	const [listTeachers, setListTeachers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [idTeacher, setIdTeacher] = useState(null);
	const [attributionToAdd, setAttributionToAdd] = useState([]);
	const [nameTeacher, setNameTeacher] = useState('');
	const [hoursWeek, setHoursWeek] = useState(0);
	const [listAttributionTeacher, setListAttributionTeacher] = useState([]);
	const [idTeacherDisable, setIdTeacherDisable] = useState(null);
	const [idsAttributionRemove, setAttributionRemove] = useState([]);
	const [school, setSchool] = useState([]);
	const [escolaID, setEscolaId] = useState(null);
	const [idSerieAno, setIdSerieAno] = useState(null);
	const [turma, setTurma] = useState(null);
	const [idDisciplina, setIdDisciplina] = useState(null);
	const [cargaHoraria, setCargaHoraria] = useState(null);
	const [listTurma] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']);
	const [listHours] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atribuição',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function loadEscola() {
		const { data } = await api.get('/escola/rede-ensino/lista-escolas');
		if (data.error) setSchool([]);
		else setSchool(data.data);
	}

	async function removeAll() {
		setIdTeacher(null);
		setIdTeacherDisable(null);
		setAttributionToAdd([]);
		setAttributionRemove([]);
		setHoursWeek(0);
	}

	async function loadAttributionAndTeachers() {
		setIsLoading(true);
		const { data } = await api.post('/atribuicao/lista-atribuicao');
		if (data.error === false) {
			setListAttribution(data.data.atribuicao);
			setListTeachers(data.data.professores);
		} else {
			setListAttribution([]);
			setListTeachers([]);
		}
		setIsLoading(false);
	}

	async function handleCreateAttribution() {
		const dataPost = {
			userId: idTeacher,
			attributionIds: attributionToAdd,
		};
		const { data } = await api.post(
			'/atribuicao/criar-atribuicao',
			dataPost
		);
		if (data.error === false) {
			swal({ icon: 'success', title: 'Sucesso!', text: data.message });
			removeAll();
			loadAttributionAndTeachers();
		} else swal({ icon: 'warning', title: 'Ops!', text: data.message });
	}

	async function handleSearch() {
		const dataPost = {
			turma,
			idSerieAno,
			idDisciplina,
			escolaID,
			cargaHoraria,
		};
		const { data } = await api.post(
			'/atribuicao/lista-atribuicao',
			dataPost
		);
		if (data.error === false) {
			setListAttribution(data.data.atribuicao);
			setListTeachers(data.data.professores);
		} else swal({ icon: 'error', text: data.message });
	}

	async function handleRemoveAttribution() {
		const dataPost = {
			userId: idTeacherDisable,
			attributionIds: idsAttributionRemove,
		};
		const { data } = await api.post(
			'/atribuicao/professor/desativar-atribuicao',
			dataPost
		);
		if (data.error === false) {
			swal({ icon: 'success', title: 'Sucesso!', text: data.message });
			removeAll();
			loadAttributionAndTeachers();
		} else swal({ icon: 'warning', title: 'Ops!', text: data.message });
	}

	useEffect(() => {
		loadAttributionAndTeachers();
		loadEscola();
	}, []);

	function OptionsButtonTeacher({ teacher, hour, name }) {
		function viewNavigation() {
			setHoursWeek(parseInt(hour, 10));
			setIdTeacher(teacher);
			setNameTeacher(name);
			document
				.getElementById('atribuicao-card')
				.scrollIntoView({ behavior: 'smooth' });
		}
		return (
			<>
				<button
					type="button"
					disabled={!!idTeacher || !!idTeacherDisable}
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-primary"
					onClick={() => viewNavigation(teacher)}
				>
					Selecionar
				</button>
			</>
		);
	}

	function OptionsButtonTeacherDisable({ teacher }) {
		function changeTeacher() {
			if (attributionToAdd.length)
				swal({
					title: 'Você tem certeza?',
					text:
						'Há atribuições para esse professor, deseja salvar mudanças ?',
					icon: 'warning',
					buttons: ['Não salvar', 'Salvar alterações'],
					dangerMode: false,
				}).then(willDelete => {
					if (willDelete) handleCreateAttribution();
					else removeAll();
				});
			else removeAll();
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-warning"
					onClick={() => changeTeacher(teacher)}
				>
					Escolher outro
				</button>
			</>
		);
	}

	function OptionsRemoveTeacherDisable({ teacher }) {
		function changeTeacher() {
			if (idsAttributionRemove.length)
				swal({
					title: 'Você tem certeza?',
					text:
						'Há atribuições para esse professor, deseja salvar mudanças ?',
					icon: 'warning',
					buttons: ['Não salvar', 'Salvar alterações'],
					dangerMode: false,
				}).then(willDelete => {
					if (willDelete) handleRemoveAttribution();
					else removeAll();
				});
			else removeAll();
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-warning"
					onClick={() => changeTeacher(teacher)}
				>
					Escolher outro
				</button>
			</>
		);
	}

	function OptionsButtonAttribution({ attribution, hasTeacher, hour }) {
		function addAttribution() {
			if (hoursWeek + hour > 40)
				swal({
					icon: 'warning',
					text: 'Limite de carga horária atingida',
				});
			else {
				setAttributionToAdd([...attributionToAdd, attribution]);
				setHoursWeek(hoursWeek + hour);
			}
		}
		return (
			<>
				<button
					type="button"
					disabled={!!hasTeacher || hoursWeek === 40}
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className={
						hasTeacher ? 'btn btn-success' : 'btn btn-primary'
					}
					onClick={() => addAttribution(attribution)}
				>
					{hasTeacher ? 'Atribuído' : 'Disponível'}
				</button>
			</>
		);
	}
	// Remove Attribution in attributionToAdd
	function OptionsButtonAttributionOnHold({ attribution, hour }) {
		function markOffAttribution() {
			const filteredAry = attributionToAdd.filter(e => e !== attribution);
			setAttributionToAdd(filteredAry);
			setHoursWeek(hoursWeek - hour);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-warning"
					onClick={() => markOffAttribution(attribution)}
				>
					Desmarcar
				</button>
			</>
		);
	}

	// Remover Atribuicao
	function OptionsSearchAttribution({ idUser, name }) {
		async function searchAttributionTeacher() {
			const dataPost = {
				idUser,
			};
			const { data } = await api.post(
				'/atribuicao/professor/lista-atribuicao',
				dataPost
			);
			removeAll();
			setNameTeacher(name);
			setIdTeacherDisable(idUser);
			setListAttributionTeacher(data.data);
			document
				.getElementById('atribuicao-card')
				.scrollIntoView({ behavior: 'smooth' });
		}
		return (
			<>
				<button
					type="button"
					disabled={!!idTeacher || !!idTeacherDisable}
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-danger"
					onClick={() => searchAttributionTeacher(idUser)}
				>
					Alterar
				</button>
			</>
		);
	}

	function OptionsButtonCancelAttributionOnHold({ attribution }) {
		function markOff() {
			const filteredAry = idsAttributionRemove.filter(
				e => e !== attribution
			);
			setAttributionRemove(filteredAry);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Dermarcar"
					className="btn btn-warning"
					onClick={() => markOff(attribution)}
				>
					Desmarcar
				</button>
			</>
		);
	}

	function OptionsButtonDisableAttribution({ attribution }) {
		function navigationToRemoveAttribution() {
			setAttributionRemove([...idsAttributionRemove, attribution]);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-danger"
					onClick={() => navigationToRemoveAttribution(attribution)}
				>
					Remover
				</button>
			</>
		);
	}

	const listHour = listHours.map(h => (
		<option value={h} key={h}>
			{h}
		</option>
	));
	const listTurmas = listTurma.map(t => (
		<option value={t} key={t}>
			{t}
		</option>
	));

	return (
		<div className="col-xl-11 col-lg-11 col-md-12">
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<>
					<div className="card">
						<div className="card-header">
							<h4>Professores</h4>
						</div>
						<div className="card-body">
							<Table
								header={[
									'Nome',
									'Data Nascimento',
									'Horas Atribuídas',
									'Atribuír',
									'Desativar',
								]}
								body={listTeachers.map(teacher => {
									return [
										teacher.nome_usuario,
										teacher.data_nascimento,
										teacher.carga_horaria_semanal,
										teacher.id_usuario === idTeacher ? (
											<OptionsButtonTeacherDisable />
										) : (
											<OptionsButtonTeacher
												teacher={teacher.id_usuario}
												hour={
													teacher.carga_horaria_semanal
												}
												name={teacher.nome_usuario}
											/>
										),
										teacher.id_usuario ===
										idTeacherDisable ? (
											<OptionsRemoveTeacherDisable />
										) : (
											<OptionsSearchAttribution
												idUser={teacher.id_usuario}
												name={teacher.nome_usuario}
											/>
										),
									];
								})}
							/>
						</div>
					</div>
					<div id="atribuicao-card">
						<>
							{idTeacher ? (
								<div className="card mt-3">
									<div className="card-header">
										<h4>Atribuições</h4>
									</div>
									<div className="card-body">
										<label>
											<b>Professor:</b> {nameTeacher}
										</label>
										<div className="row">
											<div className="col-md-4 col-lg-4 col-12 form-group">
												<label htmlFor="school">
													<b>Escola</b>
												</label>
												<select
													id="school"
													className="form-control"
													onChange={event =>
														setEscolaId(
															event.target.value
														)
													}
												>
													<option value="">
														Escolha a escola
													</option>
													{school.map(schoolItem => (
														<option
															key={
																schoolItem.id_escola
															}
															value={
																schoolItem.id_escola
															}
														>
															{
																schoolItem.desc_nome_escola
															}
														</option>
													))}
												</select>
											</div>
											<div className="col-md-4 col-lg-4 col-12 form-group">
												<label htmlFor="school">
													<b>Disciplina</b>
												</label>
												<SelectDisciplinas
													setDisciplina={
														setIdDisciplina
													}
													valueSelect={idDisciplina}
												/>
											</div>
											<div className="col-md-4 col-lg-4 col-12 form-group">
												<label htmlFor="class">
													<b>Série</b>
												</label>
												<SelectSerieAno
													setIdSerieAno={
														setIdSerieAno
													}
													valueSelect={idSerieAno}
												/>
											</div>
											<div className="col-md-4 col-lg-4 col-12 form-group">
												<label htmlFor="class">
													<b>Turma</b>
												</label>
												<select
													id="class"
													className="form-control"
													value={turma}
													onChange={event =>
														setTurma(
															event.target.value
														)
													}
												>
													<option value="">
														Escolha uma opção
													</option>
													{listTurmas}
												</select>
											</div>
											<div className="col-md-4 col-lg-4 col-12 form-group">
												<label htmlFor="class">
													<b>Carga horária</b>
												</label>
												<select
													id="class"
													className="form-control"
													value={cargaHoraria}
													onChange={event =>
														setCargaHoraria(
															event.target.value
														)
													}
												>
													<option value="">
														Escolha uma opção
													</option>
													{listHour}
												</select>
											</div>
										</div>
										<button
											onClick={handleSearch}
											className="btn btn-primary"
											type="button"
											id="button-addon2"
										>
											Filtrar
										</button>
										<hr />
										<Table
											header={[
												'Disciplina',
												'Classe',
												'Escola',
												'Carga Horária',
												'Status',
											]}
											body={listAttribution.map(
												attribution => {
													return [
														attribution.nome_disciplina,
														`${attribution.desc_serie_ano} - ${attribution.desc_tipo_ensino} - ${attribution.turma}`,
														attribution.desc_nome_escola,
														attribution.carga_horaria_semanal,
														attributionToAdd.indexOf(
															attribution.id_atribuicao
														) > -1 ? (
															<OptionsButtonAttributionOnHold
																attribution={
																	attribution.id_atribuicao
																}
																hour={
																	attribution.carga_horaria_semanal
																}
															/>
														) : (
															<OptionsButtonAttribution
																attribution={
																	attribution.id_atribuicao
																}
																hour={
																	attribution.carga_horaria_semanal
																}
																hasTeacher={
																	attribution.usuario_id_usuario
																}
															/>
														),
													];
												}
											)}
										/>
									</div>
									<div className="card-footer">
										<button
											type="button"
											onClick={handleCreateAttribution}
											disabled={hoursWeek > 40}
											className="btn btn-primary"
										>
											Atribuir professor
										</button>
									</div>
								</div>
							) : null}
							{idTeacherDisable ? (
								<div className="card mt-3">
									<div className="card-header">
										<h4>Atribuições</h4>
									</div>
									<div className="card-body">
										<label>
											<b>Professor:</b> {nameTeacher}
										</label>
										<Table
											header={[
												'Disciplina',
												'Classe',
												'Escola',
												'Carga Horária',
												'Status',
											]}
											body={listAttributionTeacher.map(
												attribution => {
													return [
														attribution.nome_disciplina,
														`${attribution.desc_serie_ano} - ${attribution.desc_tipo_ensino} - ${attribution.turma}`,
														attribution.desc_nome_escola,
														attribution.carga_horaria_semanal,
														idsAttributionRemove.indexOf(
															attribution.id_atribuicao
														) > -1 ? (
															<OptionsButtonCancelAttributionOnHold
																attribution={
																	attribution.id_atribuicao
																}
																hour={
																	attribution.carga_horaria_semanal
																}
															/>
														) : (
															<OptionsButtonDisableAttribution
																attribution={
																	attribution.id_atribuicao
																}
																hour={
																	attribution.carga_horaria_semanal
																}
																hasTeacher={
																	attribution.usuario_id_usuario
																}
															/>
														),
													];
												}
											)}
										/>
									</div>
									<div className="card-footer">
										<button
											type="button"
											onClick={handleRemoveAttribution}
											disabled={hoursWeek > 40}
											className="btn btn-primary"
										>
											Remover Atribuições
										</button>
									</div>
								</div>
							) : null}
						</>
					</div>
				</>
			)}
		</div>
	);
}
