import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaRegUserCircle, FaRegArrowAltCircleRight } from 'react-icons/fa';
import { UserTypeActions } from '../../../redux/ducks/userTypeReducer';
import UserTypeArray from '../../../data/UserTypeArray';
import LinkUser from '../../../data/LinkUser';
import './style.css';

export default function DropdownUser({ userTypes }) {
	const dispatch = useDispatch();

	function chooseUserType(name) {
		const id = UserTypeArray.findIndex(item => item === name);
		localStorage.setItem('@actualTypeId', id);
		dispatch(UserTypeActions.updateActualTypeId(id));
	}
	return (
		<>
			<div id="i-am">
				<FaRegUserCircle /> Eu sou...
			</div>
			{userTypes.map(userType => (
				<div className="dropdown-item" key={UserTypeArray[userType]}>
					<Link
						className="userType-dropDown-custom"
						to={LinkUser[userType]}
						onClick={() => chooseUserType(UserTypeArray[userType])}
					>
						<FaRegArrowAltCircleRight /> {UserTypeArray[userType]}
					</Link>
				</div>
			))}
			<div className="dropdown-divider" />
		</>
	);
}
