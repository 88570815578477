/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FaTrashAlt, FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import api from '../../services/api';
import inBoxEmpty from '../../assets/img/empty.png';
import './style.css';
import Routes from '../../routes/data/Routes';
import RouteParams from '../../routes/data/RouteParams';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function SendBox({ sendBox }) {
	const userActualType = useSelector(state => state.userType.actualType);

	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone: 'UTC',
	};
	const [communicateds, setCommunicateds] = useState([]);
	const [totalCommunicateds, setTotalCommunicateds] = useState(0);
	const [lblLimit, setlblLimit] = useState(10);
	const [lblSearch, setlblSearch] = useState('');
	const [lblPagination, setlblPagination] = useState(1);
	const [noCommunicateds, setNoCommunicateds] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	async function loadCommunicateds() {
		const response = await api.get('/comunicados/enviados', {
			params: {
				lblSearch,
			},
		});
		if (!response.data.error) {
			setCommunicateds(response.data.data.comunicados);
			setTotalCommunicateds(response.data.data.count);
			setIsLoading(false);
		} else {
			setIsLoading(false);
			swal('ERRO!', `${response.data.message}`, 'error');
		}
	}
	async function handleDelete(event) {
		try {
			const data = new FormData();
			data.append('idCommunicated', event);
			await api
				.post('/comunicados/deleta/enviado', data)
				.then(response => {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					loadCommunicateds();
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	async function handleRow(e) {
		setlblLimit(e);
		const response = await api.get('/comunicados/enviados', {
			params: {
				lblSearch,
			},
		});
		if (!response.data.error) {
			setCommunicateds(response.data.data.comunicados);
			setTotalCommunicateds(response.data.data.count);
			setlblPagination(1);
			setIsLoading(false);
		} else {
			setIsLoading(false);
			swal('ERRO!', `${response.data.message}`, 'error');
		}
	}
	async function handleSearch(event) {
		event.preventDefault();
		const response = await api.get('/comunicados/enviados', {
			params: {
				lblSearch,
			},
		});
		if (!response.data.error) {
			setCommunicateds(response.data.data.comunicados);
			setTotalCommunicateds(response.data.data.count);
			setlblPagination(1);
			setIsLoading(false);
		} else {
			setIsLoading(false);
			swal('ERRO!', `${response.data.message}`, 'error');
		}
	}
	useEffect(() => {
		(async function() {
			communicateds.length > 0
				? setNoCommunicateds(false)
				: setNoCommunicateds(true);
		})();
	}, [communicateds]);

	useEffect(() => {
		loadCommunicateds();
	}, [sendBox]);
	
	// Get current posts
	const indexOfLastPost = lblPagination * lblLimit;
	const indexOfFirstPost = indexOfLastPost - lblLimit;
	const displayCommunicateds = communicateds.slice(
		indexOfFirstPost,
		indexOfLastPost
	);
	// Change page
	const paginate = pageNumber => setlblPagination(pageNumber);
	return (
		<>
			{isLoading === true ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card-body">
					<div className="row d-flex justify-content-between">
						<div className="col-lg-8 col-md-8 col-12">
							<form onSubmit={handleSearch}>
								<div className="input-group mb-3">
									<input
										type="text"
										className="form-control"
										placeholder="Assunto"
										aria-label="Assunto"
										aria-describedby="basic-addon2"
										onChange={event =>
											setlblSearch(event.target.value)
										}
									/>
									<div className="input-group-append">
										<button
											className="btn btn-outline-secondary"
											type="submit"
										>
											Procurar
										</button>
									</div>
								</div>
							</form>
						</div>
						<div className="col-lg-3 col-md-4 col-12">
							<div className="input-group mb-3">
								<select
									className="custom-select"
									id="inputGroupSelect02"
									onChange={event =>
										handleRow(event.target.value)
									}
								>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
								</select>
								<div className="input-group-append">
									<label
										className="input-group-text"
										htmlFor="inputGroupSelect02"
									>
										Resultados
									</label>
								</div>
							</div>
						</div>
					</div>
					{isLoading === false && noCommunicateds === true && (
						<div className="card-body d-flex justify-content-center">
							<img
								src={inBoxEmpty}
								className="img-fluid rounded mx-auto d-block"
								alt="Logo"
							/>
						</div>
					)}
					{isLoading === false && noCommunicateds === false && (
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12">
								<table className="table">
									<thead>
										<tr>
											<th scope="col">Comunicados</th>
										</tr>
									</thead>
									<tbody>
										{displayCommunicateds.map(element => (
											<tr key={element.id_comunicado}>
												<td>
													<div className="row">
														<div className="col-8 col-md-10 col-lg-10">
															<h6>
																{
																	element.assunto
																}
															</h6>
														</div>
														<div className="col-2 col-md-1 col-lg-1">
															<a
																href="#"
																onClick={() =>
																	handleDelete(
																		element.id_comunicado
																	)
																}
															>
																<FaTrashAlt />
															</a>
														</div>
														<div className="col-2 col-md-1 col-lg-1">
															<Link
																to={() => {
																	const params = {};
																	params[
																		RouteParams.COMMON_LEVELS.READ_COMMUNICATED_SEND.MESSAGE
																	] = element;

																	return {
																		pathname: Routes.LOGGED_ROUTES(
																			RouteByPermission[
																				userActualType
																			]
																		)
																			.READ_COMMUNICATED_SEND,
																		state: params,
																	};
																}}
															>
																<FaEye />{' '}
															</Link>
														</div>
													</div>
													<div className="row">
														<div className="col-12">
															<p>
																{new Date(
																	element.created_at
																).toLocaleDateString(
																	'pt-BR',
																	options
																)}
															</p>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					)}
					<div className="row">
						<div className="co-12 col-md-12 col-lg-12">
							<Pagination
								postsPerPage={lblLimit}
								totalPosts={totalCommunicateds}
								paginate={paginate}
								page={lblPagination}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
