import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import cep from 'cep-promise';
import InputMask from 'react-input-mask';
import { FaSearch } from 'react-icons/fa';
import api from '../../services/api';
import SelectPais from '../Selects/SelectPais';
import SelectCidade from '../Selects/SelectCidade';
import SelectUf from '../Selects/SelectUf';
import SelectSexo from '../Selects/SelectSexo';
import SelectCorRaca from '../Selects/SelectCorRaca';
import SelectTipoTelefone from '../Selects/SelectTipoTelefone';
import Breadcrumb from '../Breadcrumb';
import CardSchool from './CardSchool';
import CardUserContacts from '../Contact/CardUserContacts';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function FormEmployees({ breadCrumbItems }) {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const [lblEmail, setlblEmail] = useState('');
	const [lblIdCorRaca, setlblIdCorRaca] = useState('');
	const [lblIdSexo, setlblSexo] = useState('');
	const [lblIdNacionalidade, setlblIdNacionalidade] = useState('1');
	const [lblCodPaisOrigem, setlblCodPaisOrigem] = useState(76);
	const [lblDataEntradaPais, setlblDataEntradaPais] = useState('');
	const [lblIdCidadeNascido, setlblIdCidadeNascido] = useState('');
	const [lblIdUFMunNascto, setlblIdUFMunNascto] = useState('');
	const [lblCpfFuncionario, setlblCpfFuncionario] = useState('');
	const [lblNumDocumentoCivil, setlblNumDocumentoCivil] = useState('');
	const [lblDigitoDocumento, setlblDigitoDocumento] = useState('');
	const [lblDataEmissao, setlblDataEmissao] = useState('');
	const [lblNumVistoConfere, setlblNumVistoConfere] = useState('');
	const [lblIdUfDocumento, setlblIdUfDocumento] = useState('');
	const [lblCep, setlblCep] = useState('');
	const [lblNumCasa, setlblNumCasa] = useState('');
	const [lblComplementoEndereco, setlblComplementoEndereco] = useState('');
	const [lblLogradouro, setlblLogradouro] = useState('');
	const [lblBairro, setlblBairro] = useState('');
	const [lblNomeCidade, setlblNomeCidade] = useState('');
	const [lblSiglaUfCidade, setlblSiglaUfCidade] = useState('');
	const [lblIdAreaLogradouro, setlblIdAreaLogradouro] = useState(1);
	const [lblIdLocalizacao, setlblIdLocalizacao] = useState(4);
	const [lblIdTipoTelefone, setlblIdTipoTelefone] = useState('');
	const [lblDDDTel, setlblDDDTel] = useState('');
	const [lblNumTelefone, setlblNumTelefone] = useState('');
	const [lblComplementoTel, setlblComplementoTel] = useState('');
	const [lblSms, setlblSms] = useState('');
	const [lblContato, setlblContato] = useState([]);
	const [lblDisable, setlblDisable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setisVisible] = useState(false);
	const [lblNomeFuncionario, setlblNomeFuncionario] = useState('');
	const [
		lblDataNascimentoFuncionario,
		setlblDataNascimentoFuncionario,
	] = useState('');
	const [changeCPF, setChangeCPF] = useState(false);
	const [editableCpf, setEditableCpf] = useState(false);
	const [inputFields, setInputFields] = useState([
		{ escola: '', tipoUsuarios: '' },
	]);
	const [tipoUsuario, setTipoUsuario] = useState('0');

	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};
	const [cepNotFound, setCepNotFound] = useState(true);

	async function handleChangeCPF(event) {
		event.preventDefault();
		setIsLoading(false);
		setisVisible(false);
		setChangeCPF(false);
		setEditableCpf(false);
		setlblCpfFuncionario('');
	}
	async function handleExistCPF(event) {
		event.preventDefault();
		const lengthCpf = lblCpfFuncionario.replace(/[-_.+()\s]/g, '');
		if (lblCpfFuncionario && lengthCpf.length >= 11) {
			setIsLoading(true);
			setisVisible(true);
			setEditableCpf(true);
			setChangeCPF(true);
			const { data } = await api.get('/usuario/verifica-cpf-usuario', {
				params: {
					lblCpf: lblCpfFuncionario,
				},
			});
			if (data.error === false) {
				setlblDisable(true);
				swal(
					'SUCESSO!',
					'Informações do Usuário com esse CPF foram encontrados.',
					'success'
				);
				setlblNomeFuncionario(data.data.usuario.responsavel);
				setlblDataNascimentoFuncionario(data.data.usuario.nascimento);
				setlblEmail(data.data.usuario.email);
				setlblIdCorRaca(data.data.usuario.id_cor_raca);
				setlblSexo(data.data.usuario.id_sexo);
				setlblIdNacionalidade(data.data.usuario.id_nacionalidade);
				setlblCodPaisOrigem(data.data.usuario.cod_pais);
				setlblDataEntradaPais(data.data.usuario.data_entrada_pais);
				setlblIdCidadeNascido(data.data.usuario.cidade_natal);
				setlblIdUFMunNascto(data.data.usuario.estado_natal);
				setlblNumDocumentoCivil(data.data.usuario.rg);
				setlblDigitoDocumento(data.data.usuario.digito);
				setlblDataEmissao(
					data.data.usuario.data_emissao_documento_civil
				);
				setlblNumVistoConfere(data.data.usuario.num_visto_confere);
				setlblIdUfDocumento(data.data.usuario.documento_uf);
				setlblCep(data.data.usuario.cep);
				setlblNumCasa(data.data.usuario.numero);
				setlblComplementoEndereco(
					data.data.usuario.complemento_endereco
				);
				setlblLogradouro(data.data.usuario.logradouro);
				setlblBairro(data.data.usuario.bairro);
				setlblNomeCidade(data.data.usuario.cidade);
				setlblSiglaUfCidade(data.data.usuario.estado);
				setlblIdAreaLogradouro(
					data.data.usuario.area_logradouro_id_area_logradouro
				);
				setlblIdLocalizacao(
					data.data.usuario.localizacao_id_localizacao
				);
				setlblContato(data.data.contato);
			} else {
				setlblDisable(false);
				swal('ATENÇÃO', `${data.message}`, 'warning');
				setlblNomeFuncionario('');
				setlblDataNascimentoFuncionario('');
				setlblEmail('');
				setlblIdCorRaca('');
				setlblSexo('');
				setlblIdNacionalidade('1');
				setlblCodPaisOrigem(76);
				setlblDataEntradaPais('');
				setlblIdCidadeNascido('');
				setlblIdUFMunNascto('');
				setlblNumDocumentoCivil('');
				setlblDigitoDocumento('');
				setlblDataEmissao('');
				setlblNumVistoConfere('');
				setlblIdUfDocumento('');
				setlblCep('');
				setlblNumCasa('');
				setlblComplementoEndereco('');
				setlblLogradouro('');
				setlblBairro('');
				setlblNomeCidade('');
				setlblSiglaUfCidade();
				setlblIdAreaLogradouro(1);
				setlblIdLocalizacao(4);
				setlblIdTipoTelefone('');
				setlblDDDTel('');
				setlblNumTelefone('');
				setlblComplementoTel('');
				setlblSms('');
				setlblContato([]);
			}
			setIsLoading(false);
		} else swal('ATENÇÃO', 'CPF inválido.', 'warning');
	}

	function handleCEP(event) {
		event.preventDefault();
		cep(lblCep)
			.then(dataPostCode => {
				if (
					!dataPostCode.neighborhood ||
					!dataPostCode.city ||
					!dataPostCode.street ||
					!dataPostCode.state
				)
					setCepNotFound(false);
				else setCepNotFound(true);
				setlblBairro(dataPostCode.neighborhood);
				setlblNomeCidade(dataPostCode.city);
				setlblLogradouro(dataPostCode.street);
				setlblSiglaUfCidade(dataPostCode.state);
			})
			.catch(error => {
				console.log(`error: ${error}`);
				setCepNotFound(false);
				swal('ATENÇÃO', 'CEP não encontrado', 'warning');
				setlblBairro('');
				setlblNomeCidade('');
				setlblLogradouro('');
				setlblSiglaUfCidade('');
			});
	}

	async function handleSubmit(event) {
		setIsLoading(true);
		try {
			event.preventDefault();
			const data = new FormData();
			data.append('lblNomeFuncionario', lblNomeFuncionario);
			data.append(
				'lblDataNascimentoFuncionario',
				lblDataNascimentoFuncionario
			);
			data.append('lblIdCorRaca', lblIdCorRaca);
			data.append('lblIdSexo', lblIdSexo);
			data.append('lblEmail', lblEmail);
			data.append('lblNumVistoConfere', lblNumVistoConfere);
			data.append('lblIdNacionalidade', lblIdNacionalidade);
			data.append('lblDataEntradaPais', lblDataEntradaPais);
			data.append('lblCodPaisOrigem', lblCodPaisOrigem);
			data.append('lblIdCidadeNascido', lblIdCidadeNascido);
			data.append('lblIdUFMunNascto', lblIdUFMunNascto);
			data.append('lblCpfFuncionario', lblCpfFuncionario);
			data.append('lblNumDocumentoCivil', lblNumDocumentoCivil);
			data.append('lblDigitoDocumento', lblDigitoDocumento);
			data.append('lblIdUfDocumento', lblIdUfDocumento);
			data.append('lblDataEmissao', lblDataEmissao);
			data.append('lblLogradouro', lblLogradouro);
			data.append('lblNumCasa', lblNumCasa);
			data.append('lblBairro', lblBairro);
			data.append('lblNomeCidade', lblNomeCidade);
			data.append('lblSiglaUfCidade', lblSiglaUfCidade);
			data.append('lblComplementoEndereco', lblComplementoEndereco);
			data.append('lblCep', lblCep);
			data.append('lblIdAreaLogradouro', lblIdAreaLogradouro);
			data.append('lblIdLocalizacao', lblIdLocalizacao);
			data.append('lblIdTipoTelefone', lblIdTipoTelefone);
			data.append('lblDDDTel', lblDDDTel);
			data.append('lblNumTelefone', lblNumTelefone);
			data.append('lblComplementoTel', lblComplementoTel);
			data.append('lblSms', lblSms);
			data.append('lblTipoUsuario', tipoUsuario);
			data.append('inputFields', JSON.stringify(inputFields));
			await api.post('/funcionarios/cadastro', data).then(response => {
				if (!response.data.error) {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.LIST_EMPLOYEE
					);
				} else swal('ERRO!', `${response.data.message}`, 'error');
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}
	async function selectTipoUsuario(e) {
		setTipoUsuario(e);
		if (e !== '0') setInputFields([{ escola: '', tipoUsuarios: '' }]);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			<div className="card mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Consulte o CPF</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>
								<b>CPF do Usuário: </b>
							</p>
							<div className="input-group">
								<InputMask
									mask="999.999.999-99"
									required
									disabled={changeCPF}
									size={16}
									type="text"
									name="lblCpfFuncionario"
									id="lblCpfFuncionario"
									className="form-control"
									placeholder="CPF do Responsável"
									value={lblCpfFuncionario}
									onChange={event =>
										setlblCpfFuncionario(event.target.value)
									}
								/>
								<div className="input-group-append">
									{editableCpf === false ? (
										<button
											onClick={handleExistCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Consultar
										</button>
									) : (
										<button
											onClick={handleChangeCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Mudar
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				{isLoading && isVisible && <div className="lds-dual-ring" />}
				{isLoading === false && isVisible === true && (
					<>
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Dados do Funcionário</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome do Funcionário</b>
										</p>
										<div className="input-group mb-4">
											<input
												onInput={toInputUppercase}
												disabled={lblDisable}
												required
												type="text"
												name="lblNomeFuncionario"
												id="lblNomeFuncionario"
												className="form-control"
												placeholder="Nome do Funcionário"
												value={lblNomeFuncionario}
												onChange={event =>
													setlblNomeFuncionario(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Data de nascimento</b>
										</p>
										<div className="input-group mb-4">
											<InputMask
												mask="99/99/9999"
												disabled={lblDisable}
												required
												size={8}
												type="text"
												name="lblDataNascimentoFuncionario"
												id="lblDataNascimentoFuncionario"
												className="form-control"
												placeholder="Data Nascimento"
												value={
													lblDataNascimentoFuncionario
												}
												onChange={event =>
													setlblDataNascimentoFuncionario(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>E-mail</b>
										</p>
										<input
											disabled={lblDisable}
											type="text"
											name="lblEmail"
											id="lblEmail"
											required
											className="form-control"
											placeholder="Digite o E-mail"
											value={lblEmail}
											onChange={event =>
												setlblEmail(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Cor/Raça</b>
										</p>
										<SelectCorRaca
											disabled={lblDisable}
											valueSelect={lblIdCorRaca}
											setCorRaca={setlblIdCorRaca}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Gênero</b>
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<SelectSexo
											valueSelect={lblIdSexo}
											disabled={lblDisable}
											setSexo={setlblSexo}
										/>
									</div>

									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Nacionalidade</b>
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<select
											required
											disabled={lblDisable}
											className="form-control"
											name="lblIdNacionalidade"
											id="lblIdNacionalidade"
											onChange={event =>
												setlblIdNacionalidade(
													event.target.value
												)
											}
										>
											<option value="1">
												Brasileiro
											</option>
											<option value="2">
												Estrangeiro
											</option>
											<option value="3">
												Brasileiro Nascido no Exterior
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Tipo de Funcionário</h4>
							</div>
							<div className="card-body">
								<div className="form-group col-12 col-lg-6 col-md-6">
									<label htmlFor="tipoUsuario">
										<b>Tipo Usuario</b>
									</label>
									<select
										name="tipoUsuario"
										className="form-control"
										required
										value={tipoUsuario}
										id="tipoUsuario"
										onChange={event =>
											selectTipoUsuario(
												event.target.value
											)
										}
									>
										<option value={0}>
											Funcionario de uma Escola
										</option>
										<option value={12}>
											Supervisor de Transporte
										</option>
										<option value={13}>Motorista</option>
										<option value={14}>
											Monitor de Transporte
										</option>
										<option value={10}>
											Nutricionista
										</option>
										<option value={11}>
											Cozinha Piloto
										</option>
									</select>
								</div>
							</div>
						</div>
						{tipoUsuario === '0' ? (
							<CardSchool setInputField={setInputFields} />
						) : null}

						{lblIdNacionalidade !== '1' ? (
							<div>
								<div className="card mt-3">
									<div className="card-header form-group">
										<h4>Nacionalidade</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													<b>País</b>
												</p>
												<SelectPais
													disabled={lblDisable}
													valueSelect={
														lblCodPaisOrigem
													}
													setCodPais={
														setlblCodPaisOrigem
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													<b>
														Data de Entrada no País
													</b>
												</p>
												<InputMask
													disabled={lblDisable}
													mask="99/99/9999"
													required
													type="text"
													name="lblDataEntradaPais"
													id="lblDataEntradaPais"
													className="form-control"
													placeholder="Data Nascimento do Responsável"
													value={lblDataEntradaPais}
													onChange={event =>
														setlblDataEntradaPais(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													<b>Número do Visto</b>
												</p>
												<input
													disabled={lblDisable}
													maxLength="11"
													minLength="11"
													type="text"
													name="lblNumVistoConfere"
													id="lblNumVistoConfere"
													className="form-control"
													placeholder="Visto do Responsável"
													value={lblNumVistoConfere}
													onChange={event =>
														setlblNumVistoConfere(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : null}

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Município de Nascimento</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Estado</b>
										</p>
										<SelectUf
											required
											disabled={lblDisable}
											valueSelect={lblIdUFMunNascto}
											setUf={setlblIdUFMunNascto}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Cidade</b>
										</p>
										<SelectCidade
											required
											disabled={lblDisable}
											ufCidade={lblIdUFMunNascto}
											valueSelect={lblIdCidadeNascido}
											setCidade={setlblIdCidadeNascido}
										/>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Documentos</h4>
								</div>
								<div className="card-body">
									<p>
										<b>Número de Documento Civil</b>
									</p>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<InputMask
												disabled={lblDisable}
												mask="99.999.999"
												required
												type="text"
												name="lblNumDocumentoCivil"
												id="lblNumDocumentoCivil"
												className="form-control"
												placeholder="RG ou RN"
												value={lblNumDocumentoCivil}
												onChange={event =>
													setlblNumDocumentoCivil(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<input
												disabled={lblDisable}
												maxLength="2"
												minLength="1"
												type="text"
												name="lblDigitoDocumento"
												id="lblDigitoDocumento"
												className="form-control"
												placeholder="XX"
												value={lblDigitoDocumento}
												onChange={event =>
													setlblDigitoDocumento(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Estado do Documento</b>
											</p>
											<SelectUf
												disabled={lblDisable}
												valueSelect={lblIdUfDocumento}
												setUf={setlblIdUfDocumento}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>
													Data da Emissão do Documento
												</b>
											</p>
											<InputMask
												disabled={lblDisable}
												mask="99/99/9999"
												name="lblDataEmissao"
												id="lblDataEmissao"
												className="form-control"
												placeholder="Data da Emissão
												do Documento"
												value={lblDataEmissao}
												onChange={event =>
													setlblDataEmissao(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{lblContato.length ? (
							<CardUserContacts
								userContacts={lblContato}
								lblDisable
							/>
						) : (
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Contato</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Tipos de Telefone</b>
											</p>
											<SelectTipoTelefone
												disabled={lblDisable}
												valueSelect={lblIdTipoTelefone}
												setTipoTelefone={
													setlblIdTipoTelefone
												}
											/>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<p>
												<b>DDD</b>
											</p>
											<input
												disabled={lblDisable}
												required
												type="text"
												minLength="2"
												maxLength="2"
												name="lblDDDTel"
												id="lblDDDTel"
												className="form-control"
												placeholder="00"
												value={lblDDDTel}
												onChange={event =>
													setlblDDDTel(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Telefone</b>
											</p>
											<input
												disabled={lblDisable}
												required
												minLength="8"
												maxLength="9"
												type="text"
												name="lblNumTelefone"
												id="lblNumTelefone"
												className="form-control"
												placeholder="11111111"
												value={lblNumTelefone}
												onChange={event =>
													setlblNumTelefone(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Deixar Recados?</b>
											</p>
											<select
												disabled={lblDisable}
												className="form-control"
												name="lblSms"
												id="lblSms"
												value={lblSms}
												onChange={event =>
													setlblSms(
														event.target.value
													)
												}
											>
												<option>Recados</option>
												<option value="NÃO">Não</option>
												<option value="SIM">Sim</option>
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Complemento</b>
											</p>
											<input
												onInput={toInputUppercase}
												disabled={lblDisable}
												type="text"
												name="lblComplementoTel"
												id="lblComplementoTel"
												className="form-control"
												placeholder="Digite o outro número"
												value={lblComplementoTel}
												onChange={event =>
													setlblComplementoTel(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Endereço</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-7 col-lg-5 form-group">
										<p>
											<b>CEP</b>
										</p>
										<div className="input-group mb-3">
											<InputMask
												disabled={lblDisable}
												mask="99999-999"
												required
												type="text"
												name="lblCep"
												id="lblCep"
												className="form-control"
												placeholder="XXXXX-XXX"
												value={lblCep}
												onChange={event =>
													setlblCep(
														event.target.value
													)
												}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-block btn-success"
													type="button"
													onClick={handleCEP}
												>
													<FaSearch />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 form-group">
										<p>
											<b>Logradouro</b>
										</p>
										<input
											onInput={toInputUppercase}
											required
											disabled={cepNotFound}
											type="text"
											name="lblLogradouro"
											id="lblLogradouro"
											className="form-control"
											placeholder="Digite o Logradouro"
											value={lblLogradouro}
											onChange={event =>
												setlblLogradouro(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Número</b>
										</p>
										<input
											disabled={lblDisable}
											required
											type="text"
											name="lblNumCasa"
											id="lblNumCasa"
											className="form-control"
											placeholder="Ex: SN, 123"
											value={lblNumCasa}
											onChange={event =>
												setlblNumCasa(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Complemento</b>
										</p>
										<input
											disabled={lblDisable}
											required
											onInput={toInputUppercase}
											type="text"
											name="lblComplementoEndereco"
											id="lblComplementoEndereco"
											className="form-control"
											placeholder="Ex: Casa, Apto..."
											value={lblComplementoEndereco}
											onChange={event =>
												setlblComplementoEndereco(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Bairro</b>
										</p>
										<input
											required
											disabled={cepNotFound}
											onInput={toInputUppercase}
											type="text"
											name="lblBairro"
											id="lblBairro"
											className="form-control"
											placeholder="Digite o Bairro"
											value={lblBairro}
											onChange={event =>
												setlblBairro(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-8 col-lg-8 form-group">
										<p>
											<b>Cidade</b>
										</p>
										<input
											required
											disabled={cepNotFound}
											onInput={toInputUppercase}
											type="text"
											name="lblNomeCidade"
											id="lblNomeCidade"
											className="form-control"
											placeholder="Digite a Cidade"
											value={lblNomeCidade}
											onChange={event =>
												setlblNomeCidade(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>UF</b>
										</p>
										<input
											required
											disabled={cepNotFound}
											maxLength="2"
											type="text"
											name="lblSiglaUfCidade"
											id="lblSiglaUfCidade"
											className="form-control"
											placeholder="Digite o UF"
											value={lblSiglaUfCidade}
											onChange={event =>
												setlblSiglaUfCidade(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Área do logradouro</b>
										</p>
										<select
											disabled={lblDisable}
											className="form-control"
											name="lblIdAreaLogradouro"
											id="lblIdAreaLogradouro"
											value={lblIdAreaLogradouro}
											onChange={event =>
												setlblIdAreaLogradouro(
													event.target.value
												)
											}
										>
											<option value="1">Urbana</option>
											<option value="2">Rural</option>
										</select>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Localização Diferenciada</b>
										</p>
										<select
											disabled={lblDisable}
											required
											value={lblIdLocalizacao}
											className="form-control"
											name="lblIdLocalizacao"
											id="lblIdLocalizacao"
											onChange={event =>
												setlblIdLocalizacao(
													event.target.value
												)
											}
										>
											<option value="1">
												Área de assentamento
											</option>
											<option value="2">
												Terra indígena
											</option>
											<option value="3">
												Área onde se localizada em
												Comunidade remanescente de
												Quilombos
											</option>
											<option value="4">
												Não está localizado em área de
												localização diferenciada
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="row justify-content-start">
									<div className="col-lg-4 col-md-5 col-7 form-group">
										<button
											className="btn btn-primary btn-block"
											type="submit"
										>
											Cadastrar
										</button>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</form>
		</>
	);
}
