import React from 'react';

export default function Index({
	setValue,
	valueSelcted,
	array = [],
	label = 'Selecionar',
	fistOption = 'Todas',
	theSameLine = false,
	optionAll = true,
}) {
	async function handleSubmit(value) {
		setValue(value);
	}

	const list = array.map(U => (
		<div className="form-check" key={U.id}>
			<input
				className="form-check-input"
				type="radio"
				value={U.id}
				checked={`${valueSelcted}` === `${U.id}`}
				onClick={event => handleSubmit(event.target.value)}
				readOnly
			/>
			<p className="form-check-label">{U.name}</p>
		</div>
	));
	return (
		<div className={`input-radio ${theSameLine ? 'row' : ''}`}>
			<div className="col-md-4 col-lg-4 col-12">
				<label htmlFor="school" style={{ margin: '0px' }}>
					{label}
				</label>
			</div>
			<div className="col-md-8 col-lg-8 col-12">
				{optionAll && (
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							value=""
							checked={valueSelcted === ''}
							onClick={event => handleSubmit(event.target.value)}
							readOnly
						/>
						<p className="form-check-label">{fistOption}</p>
					</div>
				)}

				{list}
			</div>
		</div>
	);
}
