import React from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function ReleaseNotes({
	enableEditing,
	period,
	setEnableEditing,
	setPeriod,
}) {
	async function handleSubmit(event) {
		event.preventDefault();
		const dataEditing = {
			enableEditing,
			period,
		};
		const { data } = await api.post(
			'/secretaria/liberar-notas',
			dataEditing
		);
		if (data.error === false)
			return swal({ icon: 'success', text: data.message });
		swal({ icon: 'warning', text: data.message });
	}

	function changePeriod(value) {
		if (value != '0') setEnableEditing('1');
		setPeriod(value);
	}

	function changeEditing(value) {
		if (value === '0') setPeriod(0);
		setEnableEditing(value);
	}

	return (
		<>
			<div className="card justify-content-center">
				<div className="card-header">
					<h5>
						<b>Permitir lançar notas ?</b>
					</h5>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<div className="form-check form-group">
								<input
									checked={enableEditing === '0'}
									className="form-check-input"
									type="radio"
									name="inlineRadioOptions"
									id="inlineRadio1"
									value={0}
									onClick={event =>
										changeEditing(event.target.value)
									}
								/>
								<label
									className="form-check-label"
									htmlFor="inlineRadio1"
								>
									<h6>Não</h6>
								</label>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-6 form">
							<div className="form-check form-group">
								<input
									checked={enableEditing === '1'}
									className="form-check-input"
									type="radio"
									name="inlineRadioOptions"
									id="inlineRadio1"
									value={1}
									onClick={event =>
										changeEditing(event.target.value)
									}
								/>
								<label
									className="form-check-label"
									htmlFor="inlineRadio1"
								>
									<h6>Sim</h6>
								</label>
							</div>
						</div>
					</div>
					<hr />
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6">
								<div className="form-check form-group">
									<input
										className="form-check-input"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio1"
										onClick={event =>
											changePeriod(event.target.value)
										}
										checked={period === '1'}
										value="1"
									/>
									<label
										className="form-check-label"
										htmlFor="inlineRadio1"
									>
										1º Bimestre
									</label>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-6">
								<div className="form-check form-group">
									<input
										className="form-check-input"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio2"
										onClick={event =>
											changePeriod(event.target.value)
										}
										checked={period === '2'}
										value="2"
									/>
									<label
										className="form-check-label"
										htmlFor="inlineRadio2"
									>
										2º Bimestre
									</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6">
								<div className="form-check form-group">
									<input
										className="form-check-input"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio3"
										checked={period === '3'}
										value="3"
										onClick={event =>
											changePeriod(event.target.value)
										}
									/>
									<label
										className="form-check-label"
										htmlFor="inlineRadio3"
									>
										3º Bimestre
									</label>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-6">
								<div className="form-check form-group">
									<input
										className="form-check-input"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio4"
										value="4"
										checked={period === '4'}
										onClick={event =>
											changePeriod(event.target.value)
										}
									/>
									<label
										className="form-check-label"
										htmlFor="inlineRadio4"
									>
										4º Bimestre
									</label>
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary mt-2">
							Confirmar
						</button>
					</form>
				</div>
			</div>
		</>
	);
}
