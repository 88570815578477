/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css';
import axios from 'axios';

const MapStudentsDirection = ({ coordStudents, endPoint, startPoint }) => {
	const [map, setMap] = useState(null);
	const mapContainer = useRef(null);
	const nothing = turf.featureCollection([]);
	const studentsCoords = [];
	const start = [startPoint[1], startPoint[0]];
	coordStudents.forEach(function(d) {
		studentsCoords.push(`${d.geometry.coordinates};`);
	});
	function assembleQueryURL() {
		const endLocation = [endPoint.logintude, endPoint.latitude];
		const stringData = studentsCoords.reduce((result, item) => {
			return `${result}${item}`;
		}, '');
		return `https://api.mapbox.com/directions/v5/mapbox/driving/${start};${stringData}${endLocation}?language=pt-br&geometries=geojson&overview=full&steps=true&access_token=${mapboxgl.accessToken}`;
	}
	async function updateDropoffs(geojson, map) {
		const { data } = await axios.get(assembleQueryURL());
		if (data) {
			const routeGeoJSON = turf.featureCollection([
				turf.feature(data.routes[0].geometry),
			]);
			map.getSource('route').setData(routeGeoJSON);
			const instructions = document.querySelector('#instructions-body');
			const instructionsHeader = document.querySelector(
				'#instructions-footer'
			);
			const steps = data.routes[0].legs;
			const newSteps = [];
			const tripInstructions = [];
			for (let i = 0; i < steps.length; i += 1)
				for (let j = 0; j < steps[i].steps.length; j += 1)
					tripInstructions.push(
						steps[i].steps[j].maneuver.instruction
					);

			for (let k = 0; k < tripInstructions.length; k += 1)
				newSteps.push(`<br><li>${tripInstructions[k]}</li>`);
			instructions.innerHTML = newSteps.reduce((result, item) => {
				return `${result}${item}`;
			}, '');
			instructionsHeader.innerHTML = `<br><span className="duration">Tempo de Viagem: ${Math.floor(
				data.routes[0].duration / 60
			)} min </span>`;
		}
	}

	useEffect(() => {
		const students = {
			type: 'FeatureCollection',
			features: coordStudents,
		};
		const school = turf.featureCollection([
			turf.point([endPoint.logintude, endPoint.latitude]),
		]);
		const startMarker = turf.featureCollection([turf.point(start)]);
		const initializeMap = ({ setMap, mapContainer }) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/streets-v11',
				center: [endPoint.logintude, endPoint.latitude],
				zoom: 13,
			});
			map.on('load', () => {
				map.addLayer({
					id: 'warehouse',
					type: 'circle',
					source: {
						data: school,
						type: 'geojson',
					},
					paint: {
						'circle-radius': 20,
						'circle-color': 'white',
						'circle-stroke-color': '#3887be',
						'circle-stroke-width': 3,
					},
				});
				map.addLayer({
					id: 'warehouse-symbol',
					type: 'symbol',
					source: {
						data: school,
						type: 'geojson',
					},
					layout: {
						'icon-image': 'school-15',
						'icon-size': 1,
					},
					paint: {
						'text-color': '#3887be',
					},
				});
				map.addLayer({
					id: 'start-symbol',
					type: 'symbol',
					source: {
						data: startMarker,
						type: 'geojson',
					},
					layout: {
						'icon-image': 'car-15',
						'icon-size': 1,
					},
					paint: {
						'text-color': '#3887be',
					},
				});
				// eslint-disable-next-line no-plusplus
				let num = 0;
				students.features.map(marker => {
					const studentMarker = turf.featureCollection([
						turf.point(marker.geometry.coordinates),
					]);
					map.addLayer({
						id: `bus-symbol${num}`,
						type: 'symbol',
						source: {
							data: studentMarker,
							type: 'geojson',
						},
						layout: {
							'icon-image': 'bus',
							'icon-size': 1,
						},
						paint: {
							'text-color': '#3887be',
						},
					});
					num++;
				});

				map.addSource('route', {
					type: 'geojson',
					data: nothing,
				});
				map.addLayer(
					{
						id: 'routeline-active',
						type: 'line',
						source: 'route',
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': '#3887be',
							'line-width': [
								'interpolate',
								['linear'],
								['zoom'],
								12,
								3,
								22,
								12,
							],
						},
					},
					'waterway-label'
				);
				map.addLayer(
					{
						id: 'routearrows',
						type: 'symbol',
						source: 'route',
						layout: {
							'symbol-placement': 'line',
							'text-field': '▶',
							'text-size': [
								'interpolate',
								['linear'],
								['zoom'],
								12,
								60,
								48,
								120,
							],
							'symbol-spacing': [
								'interpolate',
								['linear'],
								['zoom'],
								12,
								30,
								22,
								160,
							],
							'text-keep-upright': false,
						},
						paint: {
							'text-color': '#3887be',
							'text-halo-color': 'hsl(55, 11%, 96%)',
							'text-halo-width': 3,
						},
					},
					'waterway-label'
				);
				setMap(map);
				map.resize();
				updateDropoffs(students, map);
			});
		};
		if (!map) initializeMap({ setMap, mapContainer });
	}, [map]);
	return (
		<>
			<div className="card" id="card-mapa-students">
				<div className="card-body">
					<div className="row">
						<div className="col-3">
							<div className="card">
								<div className="card-header color-header-rota">
									<span className="titulo-rota-mapa">
										Rota dos Alunos
									</span>
								</div>
								<div
									className="card-body"
									id="instructions-body"
								/>
								<div
									className="card-footer"
									id="instructions-footer"
								/>
							</div>
						</div>
						<div
							className="col-9 card-mapa"
							ref={el => (mapContainer.current = el)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default MapStudentsDirection;
