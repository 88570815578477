/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Breadcrumb from '../Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../Table';

export default function ListStudent() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Itinerário do Aluno',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [students, setStudents] = useState([]);
	const [studentId, setStudentId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [school, setSchool] = useState([]);
	const [schoolID, setSchoolID] = useState(null);
	const [classSchool, setClassSchool] = useState([]);
	const [disable, setDisable] = useState(true);
	const [disableSerie, setDisableSerie] = useState(true);
	const [serieID, setSerieID] = useState(null);
	const [routeTransports, setRouteTransports] = useState([]);

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	useEffect(() => {
		setIsLoading(true);
		setClassSchool([]);
		setStudents([]);
		setSerieID(null);
		setStudentId(null);
		setDisable(true);
		setDisableSerie(true);
		(async function() {
			if (schoolID) {
				const response = await api.get('/series/lista/escola', {
					params: {
						escolaID: schoolID,
					},
				});
				if (!response.data.error) {
					setDisableSerie(false);
					setClassSchool(response.data.data);
				} else {
					setClassSchool([]);
					setDisableSerie(true);
				}
			}
		})();
	}, [schoolID]);

	useEffect(() => {
		(async function() {
			setStudents([]);
			setStudentId(null);
			setDisable(true);
			setIsLoading(true);
			if (schoolID && serieID)
				(async function() {
					const response = await api.get('/aluno/escola/lista', {
						params: {
							schoolID,
							serieID,
						},
					});
					if (!response.data.error) {
						setStudents(response.data.data);
						setDisable(false);
					} else {
						setStudents([]);
						setDisable(true);
					}
				})();
			setIsLoading(false);
		})();
	}, [schoolID, serieID]);

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			if (schoolID && serieID && studentId)
				(async function() {
					const response = await api.get(
						'/transporte/itinerario-aluno',
						{
							params: {
								IdAluno: studentId,
							},
						}
					);
					if (!response.data.error) {
						setDisable(false);
						setRouteTransports(response.data.data);
					} else {
						setDisable(false);
						setRouteTransports([]);
					}
				})();
			else setRouteTransports([]);
			setIsLoading(false);
		})();
	}, [studentId]);

	function ListMonitors({ monitors }) {
		return monitors.map(U => `${U.nome_usuario}, `);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setSchoolID(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<select
								id="class"
								disabled={disableSerie}
								className="form-control"
								onChange={event =>
									setSerieID(event.target.value)
								}
							>
								<option value="">Escolha uma série</option>
								{classSchool.map(classSchoolItem => (
									<option
										key={classSchoolItem.id_serie_ano}
										value={classSchoolItem.id_serie_ano}
									>
										{classSchoolItem.desc_serie_ano} -{' '}
										{classSchoolItem.desc_tipo_ensino}
									</option>
								))}
							</select>
						</div>
						<div className="col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Aluno</b>
							</label>
							<select
								id="class"
								disabled={disable}
								className="form-control"
								onChange={event =>
									setStudentId(event.target.value)
								}
							>
								<option value="">Escolha uma Aluno</option>
								{students.map(student => (
									<option
										key={student.id_usuario}
										value={student.id_usuario}
									>
										{student.nome_usuario}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="card mt-3">
				<div className="card-body">
					{isLoading === true ? (
						<p>
							<b>Por favor, escolha uma escola e uma série</b>
						</p>
					) : routeTransports.length === 0 ? (
						<p>
							<b>Aluno não tem rota cadastrada</b>
						</p>
					) : (
						<Table
							header={[
								'Data',
								'Embarque',
								'Destino',
								'Motorista',
								'Placa',
								'Monitor(es)',
							]}
							body={routeTransports.map(routeTransport => {
								return [
									routeTransport.dia_transporte,
									routeTransport.embarque,
									routeTransport.destino,
									routeTransport.motorista,
									routeTransport.placa,
									<ListMonitors
										monitors={routeTransport.monitores}
									/>,
								];
							})}
						/>
					)}
				</div>
			</div>
		</>
	);
}
