import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import ReviseEvaluetionCollapse from '../ReviseEvaluetionCollapse';
import br from 'date-fns/locale/pt-BR';
import { FaSearch } from 'react-icons/fa';
import parseJwt from '../../../../helpers/parseJwt';
import api from '../../../../services/api';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

function DoneQuestions() {
	registerLocale('br', br);

	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	const [evaluations, setEvaluations] = useState([]);
	const [startDate, setStartDate] = useState(new Date());

	const [disabledBtn, setDisabledBtn] = useState(true);
	const [subjectsTeacher, setSubjectsTeacher] = useState([]);
	const [subjectIDTeacher, setSubjectIDTeacher] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledClass, setDisabledClass] = useState(true);
	const [schoolTeacher, setSchoolTeacher] = useState([]);
	const [schoolIDTeacher, setSchoolIDTeacher] = useState(null);
	const [classTeacher, setClassTeacher] = useState([]);
	const [classIDTeacher, setClassIDTeacher] = useState(null);
	const [atualizarTela, setAtualizarTela] = useState(false);

	useEffect(() => {
		(async function() {
			const response = await api.get('/professor/listarescolas');
			if (response.data.error === false)
				setSchoolTeacher(response.data.data);
			else setSchoolTeacher([]);
		})();
		searchStudents();
	}, [atualizarTela]);

	useEffect(() => {
		(async function() {
			if (schoolIDTeacher) {
				const response = await api.get('/professor/listardisciplinas', {
					params: {
						escolaID: schoolIDTeacher,
					},
				});
				if (response.data.error === true) {
					setSubjectsTeacher([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjectsTeacher(response.data.data);
				}
			}
		})();
	}, [schoolIDTeacher]);

	useEffect(() => {
		(async function() {
			if (schoolIDTeacher && subjectIDTeacher) {
				const response = await api.get('/professor/listarturmas', {
					params: {
						escolaID: schoolIDTeacher,
						disciplinaID: subjectIDTeacher,
					},
				});
				if (response.data.error === true) {
					setClassTeacher([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClassTeacher(response.data.data);
				}
			}
		})();
	}, [subjectIDTeacher]);

	useEffect(() => {
		if (classIDTeacher) setDisabledBtn(false);
	}, [classIDTeacher]);

	async function searchStudents(event = null) {
		event && event.preventDefault();

		(async function() {
			const response = await api.get(
				'/avaliacao/exibir-provas-corrigidas-filtros',
				{
					params: {
						professorId: idUser && idUser,
						escolaID: schoolIDTeacher && schoolIDTeacher,
						disciplinaID: subjectIDTeacher && subjectIDTeacher,
						classeID: classIDTeacher && classIDTeacher,
						data: disabledClass ? null : startDate,
					},
				}
			);

			if (response.data.error === false) {
				setEvaluations(response.data.data);
				setAtualizarTela(false);
			} else setEvaluations([]);
		})();
	}

	const getAvaliacoesCorrigidas = async () => {
		const response = await api.get(
			'/avaliacao/exibir-provas-corrigidas-filtros',
			{
				params: {
					professorId: idUser && idUser,
				},
			}
		);

		if (response.data.error === false) setEvaluations(response.data.data);
	};

	useEffect(() => {
		getAvaliacoesCorrigidas();
	}, []);
	return (
		<div>
			<div className="card-header" id="reviseHeader">
				Correções Feitas
			</div>
			<div className="row mt-4 headerFiltersQuestionsDone">
				<div className="col-12 col-lg-3">
					<div className="form-group">
						<label htmlFor="schoolTeacher">
							<b>Escola</b>
						</label>
						<select
							className="form-control"
							name="schoolTeacher"
							id="schoolTeacher"
							onChange={event =>
								setSchoolIDTeacher(event.target.value)
							}
						>
							<option value="0">Escolha a Escola</option>
							{schoolTeacher.map(schools => (
								<option
									key={schools.id_escola}
									value={schools.id_escola}
								>
									{schools.desc_nome_escola}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-lg-3">
					<div className="form-group">
						<label htmlFor="subjectTeacher">
							<b>Disciplina</b>
						</label>
						<select
							className="form-control"
							name="subjectTeacher"
							id="subjectTeacher"
							onChange={event =>
								setSubjectIDTeacher(event.target.value)
							}
							disabled={disabledSubjects}
						>
							<option value="">Escolha a disciplina</option>
							{subjectsTeacher.map(subjects => (
								<option
									key={subjects.id_disciplina}
									value={subjects.id_disciplina}
								>
									{subjects.nome_disciplina}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-lg-3">
					<div className="form-group">
						<label htmlFor="classTeacher">
							<b>Série/Turma</b>
						</label>
						<select
							id="classTeacher"
							className="form-control"
							onChange={event =>
								setClassIDTeacher(event.target.value)
							}
							disabled={disabledClass}
						>
							<option value="0">Escolha a série</option>
							{classTeacher.map(classes => (
								<option
									key={classes.id_classe}
									value={classes.id_classe}
								>
									{classes.desc_serie_ano} - {classes.turma}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-lg-3">
					<div className="form-group">
						<label htmlFor="classTeacher">
							<b>Data</b>
						</label>
						<DatePicker
							disabled={disabledClass}
							className="form-control"
							selected={startDate}
							dateFormat={'d/MM/yyyy'}
							locale="br"
							onChange={date => setStartDate(date)}
						/>
					</div>
				</div>
				<button
					type="button"
					className="btn btn-block btn-success"
					id="btnBuscarProvarFeitaFiltros"
					onClick={searchStudents}
					disabled={disabledBtn}
				>
					<FaSearch className="searchIcon" />
					Buscar Provas
				</button>
			</div>

			{evaluations.map((evaluation, index) =>
				evaluation.alunos.length > 0 ? (
					<ReviseEvaluetionCollapse
						DoneQuestions
						atualizarTela={setAtualizarTela}
						key={index}
						prova={evaluation}
					/>
				) : null
			)}
		</div>
	);
}

export default DoneQuestions;
