import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../services/api';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Routes from '../../routes/data/Routes';
import formatDate from '../../helpers/formatDate';

import Breadcrumb from '../Breadcrumb';
import Table from '../Table';
import SelectComponent from '../Selects/SelectComponent';
import CardUsedFilters from '../CardUsedFilters';
import InputRadio from '../InputRadio';
import BtnUpdate from '../buttom/buttonUpdateProtocol';

import ValidationArray from '../../data/Validation';
import ObservationArray from '../../data/Observation';

export default function ListChildhood() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [listChildhood, setListChildhood] = useState([]);
	const [schools, setSchools] = useState([]);
	const [idSchool, setIdSchool] = useState('');
	const [typesOfTeachings, setTypesOfTeachings] = useState([]);
	const [idTypeOfTeaching, setIdTypeOfTeaching] = useState('');
	const [validation, setValidation] = useState('');
	const [observation, setObservation] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Vagas em Escolas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const filterUsed = [
		{
			name: 'Escola',
			array: schools,
			value: idSchool,
			sizeColumn: 12,
		},
		{
			name: 'Ano/Serie',
			array: typesOfTeachings,
			value: idTypeOfTeaching,
			sizeColumn: 6,
		},
	];

	function getValidation(value, valueObservation, alert) {
		if (value == '0')
			return (
				<div>
					<div>Pendente</div>
					<small>
						{valueObservation.length > 0
							? 'Contém observações'
							: null}
					</small>
				</div>
			);
		if (value == '1')
			return (
				<div>
					<div>Aprovado</div>
					<small>
						{valueObservation.length > 0
							? 'Contém observações'
							: null}
					</small>
				</div>
			);

		return (
			<div>
				<div>Recusado</div>
				<small>
					{valueObservation.length > 0 ? 'Contém observações' : null}
				</small>
			</div>
		);
	}

	async function loadChildhood() {
		setIsLoading(true);
		const { data } = await api.get('/vagas-em-escolas', {
			params: {
				idSchool,
				validation,
				idTypeOfTeaching,
				observation,
			},
		});
		if (data.error) {
			setListChildhood([]);
			swal({ icon: 'warning', text: data.message, title: 'Ops!' });
		} else setListChildhood(data.data);
		setIsLoading(false);
	}

	async function getSchool() {
		const response = await api.get('/escolas/ElementarySchool');
		const schoolsArray = response.data.data.map(e => {
			return { ...e, name: e.name.replace('ESCOLA MUNICIPAL', '') };
		});
		setSchools(schoolsArray);
	}

	async function getTypesOfTeachings() {
		const response = await api.get('/tabelas/tipos-ensino', {
			params: {
				idSchool,
				typesOfTeaching: 'elementarySchool',
			},
		});
		setTypesOfTeachings(response.data.data);
	}

	useEffect(() => {
		loadChildhood();
		getSchool();
		getTypesOfTeachings();
	}, [validation, idSchool, idTypeOfTeaching, observation]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12 form-group">
							<SelectComponent
								setValue={setIdSchool}
								valueSelcted={idSchool}
								array={schools}
								label="Escolas Municipais:"
								theSameLine
							/>
						</div>
						<div className="col-md-6 col-lg-6 col-12 form-group">
							<SelectComponent
								setValue={setIdTypeOfTeaching}
								valueSelcted={idTypeOfTeaching}
								array={typesOfTeachings}
								label="Ano/Serie:"
								theSameLine
							/>
						</div>
						<div className="col-md-6 col-lg-6 col-12 form-group">
							<SelectComponent
								setValue={setValidation}
								valueSelcted={validation}
								array={ValidationArray}
								label="Validação:"
								theSameLine
							/>
						</div>
						<div className="col-md-6 col-lg-6 col-12 form-group">
							<InputRadio
								setValue={setObservation}
								valueSelcted={observation}
								array={ObservationArray}
								label="Filtrar por observações:"
								theSameLine
							/>
						</div>
					</div>
				</div>
				{isLoading && <div className="lds-dual-ring-small" />}
			</div>
			<CardUsedFilters array={filterUsed} />
			<div className="mt-3 ">
				<Table
					header={[
						'Protocolo',
						'Nome da Criança',
						'Nome do Responsável',
						'Data de Solicitação',
						'Escola Municipal',
						'Turma/Ano',
						'Validação',
						'Editar',
					]}
					body={listChildhood.map(protocol => [
						protocol.pcd
							? `${protocol.protocolo} *`
							: protocol.protocolo,
						protocol.nome_aluno,
						protocol.nome_responsavel,
						formatDate(protocol.created_at, true),
						protocol.desc_nome_escola.replace(
							'ESCOLA MUNICIPAL',
							''
						),
						protocol.desc_serie_ano,
						getValidation(
							protocol.validado,
							protocol.observacoes_vagas_creches,
							protocol.alert
						),
						<BtnUpdate
							protocol={protocol.protocolo}
							link={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).CHILDHOOD_EDUCATION_VACANCIES
							}
							setChildhood={setListChildhood}
						/>,
					])}
				/>
			</div>
		</>
	);
}
