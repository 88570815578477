const validation = [
	{
		id: 0,
		name: 'Pendente'
	},
	{
		id: 1,
		name: 'Aprovado'
	},
	{
		id: 2,
		name: 'Recusado',
	},
];

export default validation;