import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { FaTimesCircle, FaPencilAlt, FaMinus, FaPlus } from 'react-icons/fa';
import Breadcrumb from '../../components/Breadcrumb';
import api from '../../services/api';
import './style.css';
import LabelByPermission from '../../routes/data/LabelByPermission';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import RouteParams from '../../routes/data/RouteParams';
import Table from '../../components/Table';
import Bookloading from '../../components/Loading/Bookloading';

$.DataTable = require('datatables.net-dt');

function CheckboxElement({ product, productsToRemove, setProductsToRemove }) {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checked) return setProductsToRemove([...productsToRemove, product]);
		setProductsToRemove(
			productsToRemove.filter(productItem => productItem !== product)
		);
	}, [checked]);

	return <input type="checkbox" onChange={() => setChecked(!checked)} />;
}

function OptionsButton({ product }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	function editNavigation() {
		const params = {};

		params[RouteParams.LEVEL_07_ROUTES.EDIT_PRODUCTS.PRODUCT] = product;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.EDIT_PRODUCTS,
			{ params }
		);
	}

	function editAddNavigation() {
		const params = {};

		params[RouteParams.LEVEL_07_ROUTES.EDIT_PRODUCTS_ADD.PRODUCT] = product;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.EDIT_PRODUCTS_ADD,
			{ params }
		);
	}

	function editRemoveNavigation() {
		const params = {};

		params[
			RouteParams.LEVEL_07_ROUTES.EDIT_PRODUCTS_REMOVE.PRODUCT
		] = product;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.EDIT_PRODUCTS_REMOVE,
			{ params }
		);
	}

	return (
		<>
			<button
				type="button"
				aria-hidden
				data-toggle="tooltip"
				title="Editar produto"
				className="productsEditButtonElement"
				onClick={() => editNavigation(product)}
			>
				<FaPencilAlt fill="#ffffff" />
			</button>
			<button
				type="button"
				className="productsEditAddButtonElement"
				onClick={() => editAddNavigation(product)}
			>
				<FaPlus
					aria-hidden
					data-toggle="tooltip"
					title="Aumentar quantidade de produtos"
					fill="#ffffff"
				/>
			</button>
			<button
				type="button"
				className="productsEditRemoveButtonElement"
				onClick={() => editRemoveNavigation(product)}
			>
				<FaMinus
					aria-hidden
					data-toggle="tooltip"
					title="Diminuir quantidade de produtos"
					fill="#ffffff"
				/>
			</button>
		</>
	);
}

export default function Products() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [products, setProducts] = useState([]);
	const [productsToRemove, setProductsToRemove] = useState([]);
	const [loading, setLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Produtos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getProducts() {
		const response = await api.post('/cozinha-piloto/produtos');

		if (!response.data.error) {
			setProducts(response.data.data);
			setLoading(false);
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function removeProduct() {
		try {
			const response = await api.post(
				'/cozinha-piloto/produtos/remover',
				{
					produtos: productsToRemove.reduce(
						(result, productItem) =>
							result.concat(productItem.id_produto),
						[]
					),
				}
			);

			if (!response.data.error) {
				swal(
					'SUCESSO!',
					'O produto foi removido com sucesso',
					'success'
				);
				getProducts();
			} else swal('ERRO!', `${response.data.message}`, 'error');
		} catch (error) {
			swal('ERRO!', 'Houve um erro ao remover o produto', 'error');
		}
	}

	useEffect(() => {
		getProducts();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{loading ? (
				<Bookloading
					isLoading={loading}
					className="productsBookloading"
				/>
			) : (
				<>
					<div className="productsButtons">
						<Link
							to={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).ADD_PRODUCTS
							}
							className="productsButton productsAddButton"
						>
							Adicionar
						</Link>
						<button
							type="button"
							className="productsButton productsRemoveButton"
							onClick={() =>
								productsToRemove.length !== 0
									? removeProduct()
									: swal(
											'ATENÇÃO',
											'Selecione um produto para remover',
											'warning'
									  )
							}
						>
							Remover
						</button>
					</div>

					{products ? (
						<div className="table-responsive card productsCardTable">
							<Table
								header={[
									'',
									'Nome',
									'Preço/Und',
									'Quantidade',
									'Preço/Total',
									'',
								]}
								body={products.map(productItem => {
									return [
										<CheckboxElement
											product={productItem}
											productsToRemove={productsToRemove}
											setProductsToRemove={
												setProductsToRemove
											}
										/>,
										productItem.nome,
										productItem.preco,
										productItem.quantidade,
										productItem.preco_total,
										<OptionsButton product={productItem} />,
									];
								})}
							/>
						</div>
					) : (
						<div className="productsNotFound">
							<FaTimesCircle size={30} />
							<p className="mt-2">Nenhum produto encontrado</p>
						</div>
					)}
				</>
			)}
		</>
	);
}
