import React from 'react';
import PrivateRouter from './PrivateRouter';
import Level_03 from '../pages/Home/HomeLevel03';
import ListStudent from '../pages/Students';
import ClassRegister from '../pages/Students/ClassRegister';
import CorrectAttendance from '../pages/Students/CorrectAttendance';
import School from '../pages/School';
import SchoolInfo from '../pages/School/Info';
import Attribution from '../pages/Attribution/AttributionUser';
import InfoAttribution from '../pages/Attribution/FilesAttribution/EditFilesAttribution';
import Grades from '../pages/Grade/Notes';
import PostNotes from '../pages/Grade/PostNotes';
import Communicated from '../pages/Communicated';
import ReadCommunicatedSendBox from '../pages/Communicated/ReadCommunicatedSendBox';
import ReadCommunicatedInbox from '../pages/Communicated/ReadCommunicatedInbox';
import AddCommunicatedMessage from '../pages/Communicated/AddCommunicatedMessage';
import WriteCommunicated from '../pages/Communicated/WriteCommunicated';
import ReportCard from '../pages/Students/ReportCard';
import FailList from '../pages/Students/FailList';
import SubjectsContents from '../pages/Subjects';
import Subjects from '../pages/Subjects/SubjectsTeacher';
import ReadNotices from '../pages/Notices';
import InfoProfile from '../pages/Profile/InfoProfile';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Profile/ChangeContact';
import Library from '../pages/Library';
import Evaluation from '../pages/Evaluation';
import ListEvaluation from '../pages/Evaluation/ListEvaluation';
import ReviseEvaluation from '../pages/Evaluation/ReviseEvaluention';
import Routes from './data/Routes';

export default function RoutesTeacher({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.HOME}
				component={Level_03}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.STUDENTS}
				component={ListStudent}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.STUDENT_ATTENDANCE}
				component={ClassRegister}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.CORRECT_ATTENDANCE}
				component={CorrectAttendance}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.SCHOOLS}
				component={School}
			/>
			<PrivateRouter
				userTypes={[7]}
				path={LOGGED_ROUTES.SCHOOL_INFO}
				exact
				component={SchoolInfo}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.ATTRIBUTION}
				component={Attribution}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.INFO_ATTRIBUTION}
				component={InfoAttribution}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.GRADE}
				component={Grades}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.POSTNOTES}
				component={PostNotes}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.COMMUNICATED}
				component={Communicated}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.REPORT_CARD}
				component={ReportCard}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.WRITE_COMMUNICATED}
				component={WriteCommunicated}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.READ_COMMUNICATED_SEND}
				component={ReadCommunicatedSendBox}
			/>
			<PrivateRouter
				userTypes={[7]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_RECEIVE}
				exact
				component={ReadCommunicatedInbox}
			/>
			<PrivateRouter
				userTypes={[7]}
				path={LOGGED_ROUTES.ADD_COMMUNICATED_MESSAGE}
				exact
				component={AddCommunicatedMessage}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.REPROVED_LIST}
				component={FailList}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT}
				component={SubjectsContents}
			/>
			<PrivateRouter
				userTypes={[7]}
				path={LOGGED_ROUTES.COURSES}
				exact
				component={Subjects}
			/>
			<PrivateRouter
				userTypes={[7]}
				path={LOGGED_ROUTES.READ_NOTICES}
				exact
				component={ReadNotices}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.LIBRARY}
				component={Library}
			/>

			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.CREATE_EVALUATION}
				component={Evaluation}
			/>

			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.EVALUATION_LIST}
				component={ListEvaluation}
			/>

			<PrivateRouter
				userTypes={[7]}
				exact
				path={LOGGED_ROUTES.REVISE_EVALUATION}
				component={ReviseEvaluation}
			/>
		</>
	);
}
