import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import swal from 'sweetalert';
import Panel from '../../../components/Panel';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Ticket from './Ticket';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import Footer from '../../../components/Footer';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);
	const location = useLocation();
	const history = useHistory();
	const [ticketId, setTicketId] = useState(null);

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			if (location.state) setTicketId(location.state.ticket);
			else {
				swal('ERRO!', 'Mensagem Não Encontrada', 'error');
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_TICKETS
				);
			}
		})();
	}, [location.state]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_TICKETS,
			namePage: 'Lista de Chamadas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Chamada',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	return <Ticket ticketId={ticketId} breadCrumbItems={breadCrumbItems} />;
}
