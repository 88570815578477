import React, { useEffect } from 'react';
import { BiHelpCircle } from 'react-icons/bi';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LabelByPermission from '../../routes/data/LabelByPermission';
import PerfilUser from './PerfilUser';
import MessageDropDown from './MessageDropDown';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import logoSemec from '../../assets/img/semec.png';
import parseJwt from '../../helpers/parseJwt';
import api from '../../services/api';
import { useFunctionalities } from '../Contenxt/Functionalities';

import './style.css';

export default function Menu() {
	const { setFunctionalities } = useFunctionalities();
	const userType = useSelector(state => state.userType);
	const userActualType = useSelector(state => state.userType.actualType);
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;

	async function loadUser() {
		if ((idUser, userActualType)) {
			const {
				data: { error, message, data },
			} = await api.get('/tipo-usuario', {
				params: {
					idUser,
					idTypeUser: userActualType,
				},
			});
			if (!error) setFunctionalities(data);
		}
	}

	useEffect(() => {
		loadUser();
	}, [idUser, userActualType]);

	return (
		<div className="col-xl-12 col-lg-12 col-md-12 ml-auto bg-white fixed-top py-2 top-navbar">
			<div className="row margin-itens-navbar">
				<div className="col-12 col-md-6 col-lg-6">
					<Link
						to={
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).HOME
						}
					>
						<img
							src={logoSemec}
							alt="Logo da secretaria de educação"
							className="img-fluid img-logo"
						/>
					</Link>
				</div>
				<div className="col-12 col-md-6 col-lg-6">
					<div className="itens-navbar">
						{userType.actualType ? (
							<>
								<span className="text-muted">
									OLÁ,{' '}
									{LabelByPermission[userType.actualType]}
								</span>
								<div className="icons-navbar">
									<PerfilUser />
									{userType.actualType > 1 &&
										userType.actualType <= 9 && (
											<MessageDropDown />
										)}
								</div>
								<a
									href="https://forms.office.com/Pages/ResponsePage.aspx?id=w4nnxLXjGEK0JZ041Pls9qN4_gz41-NDlFcVdhHfgEFUQks3R09HSEpYRkZHOEhTMTE4Tkc1SFpKUS4u"
									target="_blank"
								>
									<BiHelpCircle color="#007bff" size="25px" />
								</a>
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
