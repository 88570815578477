export default {
	ALL: '^.*+$',
	BANK_ACCOUNT: '^[-0-9]+$',
	CEP: '^[0-9]{2}.[0-9]{3}-[0-9]{3}$',
	CNPJ: '[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}',
	CPF: '^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$',
	DATE: '^[0-9]{2}/[0-9]{2}/[0-9]{4}$',
	HOUSE_NUMBER: '^([0-9]+[a-eA-E]?|s/n|S/N)$',
	LETTERS_AND_NUMBERS: '^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$',
	ONLY_LETTERS: '^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$',
	ONLY_NUMBERS: '^[0-9]+$',
	PRICE: '^[0-9]+.[0-9]{2}$',
	STATE: '^[a-zA-Z]{2}$',
	TELEPHONE_DDD: '^[0-9]{2}$',
	TELEPHONE: '^[0-9]{8,9}$',
};
