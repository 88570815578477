import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import FormRegister from '../../components/CardsEmployees/FormEmployees';
import Panel from '../../components/Panel';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function RegisterEmployees() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_EMPLOYEE,
			namePage: 'Lista de Funcionarios',
			breadCrumbClass: 'breadcrumb-item active',
		},
		{
			linkPage: '#',
			namePage: 'Registro ',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return <FormRegister breadCrumbItems={breadCrumbItems} />
}
