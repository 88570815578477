import React, { useState } from 'react';
import ModalReviseQuestions from '../ModalReviseQuestions';
import ModalDoneQuestions from '../ModalDoneQuestions';

const ReviseEvaluetionCollapse = ({
	prova,
	atualizarTela = null,
	DoneQuestions = null,
}) => {
	const [students, setStudents] = useState(prova.alunos);
	return (
		<>
			<div id="accordion">
				<div className="card mt-3">
					<div className="card-header" id="headingOne">
						<button
							style={{ fontSize: "20px" }}
							className="btn btn-link"
							data-toggle="collapse"
							data-target={`#collapse${prova.id}`}
							aria-expanded="true"
							aria-controls="collapseOne"
						>
							{prova.titulo}
						</button>
						<span style={{ fontSize: "15px", color: "dark-grey" }}>{prova.nome_disciplina} - Turma: {prova.turma} - {prova.desc_serie_ano}</span>
					</div>

					<div
						id={`collapse${prova.id}`}
						className="collapse"
						aria-labelledby="headingOne"
						data-parent="#accordion"
					>

						{students.map(
							(student, index) =>
								student.respostas.length > 0 &&
								(DoneQuestions ? (
									<ModalDoneQuestions
										key={index}
										index={prova.id}
										student={student}
									/>
								) : (
										<ModalReviseQuestions
											atualiza={atualizarTela}
											key={index}
											index={prova.id}
											idAvaliacao={prova.id_avaliacao}
											student={student}
										/>
									))
						)}

					</div>
				</div>
			</div>
		</>
	);
};

export default ReviseEvaluetionCollapse;
