/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

export default function ListSubjects() {
	const [subjectTeacher, setSubjectTeacher] = useState([]);

	useEffect(() => {
		(async function() {
			const response = await api.get('/aluno/disciplinaprofessor');
			setSubjectTeacher(response.data.data);
		})();
	}, []);
	return (
		<div className="card">
			<div className="card-body">
				<h5>Boletim</h5>
				<div className="table-responsive">
					<table className="table table-hover">
						<thead>
							<tr>
								<th scope="col">Disciplina</th>
								<th scope="col">Professor(a)</th>
								<th scope="col">Média</th>
							</tr>
						</thead>
						<tbody>
							{subjectTeacher.map(student => (
								<tr key={student.id_disciplina}>
									<th scope="row">
										{student.nome_disciplina}
									</th>
									<td>{student.nome_professor}</td>
									<td className="averageGrade">
										{student.nota_media_final === null
											? 0
											: student.nota_media_final}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
