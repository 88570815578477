import React, { useState } from 'react';
import swal from 'sweetalert';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Breadcrumb from '../Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function FormStudents() {
	const userActualType = useSelector(user => user.userType.actualType);
	const location = useLocation();

	const [senhaAntiga, setSenhaAntiga] = useState('');
	const [novaSenha, setNovaSenha] = useState('');
	const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.INFO_PROFILE,
			namePage: 'Informação do Usuário',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Mudar Senha',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const history = useHistory();

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = new FormData();
			if (location.state) data.append('idUsuario', location.state);
			data.append('senhaAntiga', senhaAntiga);
			data.append('novaSenha', novaSenha);
			data.append('confirmacaoNovaSenha', confirmacaoNovaSenha);
			await api.post('/usuario/mudarsenha', data).then(response => {
				if (!response.data.error) {
					swal('SUCESSO!', `${response.data.message}`, 'success');

					history.goBack();
				} else swal('ERRO!', `${response.data.message}`, 'error');
				setIsLoading(false);
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div>
					<form onSubmit={handleSubmit}>
						<div className="card mt-3">
							<div className="card-header">
								<div className="row">
									<div className="col-12 col-md-11 col-lg-11 form-group">
										<h4>Mudar Senha</h4>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Senha Atual</b>
										</p>
										<input
											type="password"
											required
											name="senhaAtual"
											id="senhaAtual"
											className="form-control"
											placeholder="Senha Atual"
											value={senhaAntiga}
											onChange={event =>
												setSenhaAntiga(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nova Senha</b>
										</p>
										<input
											type="password"
											required
											name="novaSenha"
											id="novaSenha"
											className="form-control"
											placeholder="Nova Senha"
											value={novaSenha}
											onChange={event =>
												setNovaSenha(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Confirma Nova Senha</b>
										</p>
										<input
											type="password"
											required
											name="confirmacaoNovaSenha"
											id="confirmacaoNovaSenha"
											className="form-control"
											placeholder="Confirmar Nova Senha"
											value={confirmacaoNovaSenha}
											onChange={event =>
												setConfirmacaoNovaSenha(
													event.target.value
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<button
									className="btn btn-primary"
									type="submit"
								>
									Atualizar Senha
								</button>
							</div>
						</div>
					</form>
				</div>
			)}
		</>
	);
}
