import React, { useEffect, useState } from 'react';
import { useFunctionalities } from '../../components/Contenxt/Functionalities';
import ListStudentsGeneric from '../../components/Lists/ListStudentsGeneric';
import ListStudents from '../../components/Lists/ListStudents';
import Footer from '../../components/Footer';
import Panel from '../../components/Panel';
import ScrollToTop from '../../components/buttom/ScrollToTop';
import Main from '../../components/Main';

export default function Student() {
	const [isLoading, setIsLoading] = useState(true);
	const [isGeneric, setIsGeneric] = useState(true);

	const {
		functionalities: { functionalities },
	} = useFunctionalities();

	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 146
			);
			if (existe) setIsGeneric(true);
			else setIsGeneric(false);
			setIsLoading(false);
		} else setIsLoading(true);
	}, [functionalities]);

	return (
		<>
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : isGeneric ? (
				<ListStudentsGeneric />
			) : (
				<ListStudents />
			)}
		</>
	);
}
