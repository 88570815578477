import React, { useState, useEffect } from 'react';
import { FaTimesCircle, FaEye } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteParams from '../../../routes/data/RouteParams';
import Table from '../../../components/Table';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function InfoRouteTransport() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [driver, setDriver] = useState([]);
	const [monitors, setMonitors] = useState([]);
	const [students, setStudents] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_ROUTE_TRANSPORT,
			namePage: 'Rotas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação Rota',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			setIsLoading(true);

			if (location.state) {
				const idRoute =
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_TRANSPORT.ID_TRANSPORT
					];
				const { data } = await api.get(
					'/transporte/rota/informacao-rota',
					{
						params: {
							idRoute,
						},
					}
				);
				if (data.error === false) {
					setDriver(data.data.driver);
					setMonitors(data.data.monitor);
					setStudents(data.data.students);
				}
			} else {
				swal({
					icon: 'Warning',
					text: 'Escolha uma rota para acessar essa página.',
				});
				history.goBack();
			}
			setIsLoading(false);
		})();
	}, []);

	function viewNavigation(e) {
		const params = {};

		params[RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE] = e;

		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.INFO_VEHICLE,
			{ params }
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading === true ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Rota de Transporte</h4>
						</div>
						<div className="card-body">
							<div className="form-group">
								<p>
									<b>Motorista</b>
									<hr />
									<b>Nome: </b>
									{driver.user.nome_usuario
										? driver.user.nome_usuario
										: null}
								</p>
								{driver.contacts.length > 0
									? driver.contacts.map(contact => (
											<div className="row">
												<div className="col-12 col-md-4 col-lg-4 form-group">
													<p>
														<b>Telefone: </b>(
														{contact.ddd_numero}){' '}
														{contact.tel_numero}
													</p>
												</div>
												{contact.complemento ? (
													<div className="col-12 col-md-4 col-lg-4 form-group">
														<p>
															<b>Complemento: </b>
															{
																contact.complemento
															}
														</p>
													</div>
												) : null}
												<div className="col-12 col-md-4 col-lg-4 form-group">
													<p>
														<b>Deixar Recados: </b>
														{contact.sms}
													</p>
												</div>
											</div>
									  ))
									: null}
							</div>
							<div className="form-group">
								<p>
									<b>Monitor(es)</b>
									<hr />
									{monitors.map(monitor => (
										<>
											<p>
												<b>Nome: </b>
												{monitor.user.nome_usuario}
											</p>
											{monitor.contacts.length > 0
												? monitor.contacts.map(
														contact => (
															<div className="row">
																<div className="col-12 col-md-4 col-lg-4 form-group">
																	<p>
																		<b>
																			Telefone:{' '}
																		</b>
																		(
																		{
																			contact.ddd_numero
																		}
																		){' '}
																		{
																			contact.tel_numero
																		}
																	</p>
																</div>
																{contact.complemento ? (
																	<div className="col-12 col-md-4 col-lg-4 form-group">
																		<p>
																			<b>
																				Complemento:{' '}
																			</b>
																			{
																				contact.complemento
																			}
																		</p>
																	</div>
																) : null}
																<div className="col-12 col-md-4 col-lg-4 form-group">
																	<p>
																		<b>
																			Deixar
																			Recados:{' '}
																		</b>
																		{
																			contact.sms
																		}
																	</p>
																</div>
															</div>
														)
												  )
												: null}
										</>
									))}
								</p>
							</div>
							<div className="form-group">
								<p>
									<b>Informações do Transporte</b>
									<hr />
								</p>
								<div className="row">
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Quilometragem Ida: </b>
											{driver.user.quilometragem_ida
												? driver.user.quilometragem_ida
												: null}
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Quilometragem Volta: </b>
											{driver.user.quilometragem_volta
												? driver.user
														.quilometragem_volta
												: null}
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Quilometragem Total: </b>
											{driver.user.quilometragem_total
												? driver.user
														.quilometragem_total
												: null}
										</p>
									</div>
									<div className="col-12 col-md-1 col-lg-1 form-group">
										<b>Ida: </b>
										{driver.user.ida === 1 ? 'SIM' : 'NÃO'}
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<b>Volta: </b>
										{driver.user.volta === 1
											? 'SIM'
											: 'NÃO'}
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Turno: </b>
											{driver.user.desc_turno
												? driver.user.desc_turno
												: null}
										</p>
									</div>
								</div>
							</div>
							<div className="form-group">
								<p>
									<b>Informações do veículo</b>
									<hr />
								</p>
								<div className="row">
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Placa: </b>
											{driver.user.placa
												? driver.user.placa
												: null}
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Modelo: </b>
											{driver.user.modelo
												? driver.user.modelo
												: null}
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<button
											type="button"
											aria-hidden
											data-toggle="tooltip"
											title="Ver Veículo"
											className="buttonViewColor"
											onClick={() =>
												viewNavigation(
													driver.user.id_veiculo
												)
											}
										>
											<FaEye fill="#ffffff" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Rota de Transporte</h4>
						</div>
						{students ? (
							<div className="table-responsive container">
								<Table
									header={[
										'Nome',
										'Contato',
										'Telefone',
										'Embarque',
										'Destino(Escola)',
									]}
									body={students.map(student => {
										return [
											student.user.nome_usuario,
											student.user.num_ra,
											student.contacts.tel_numero
												? student.contacts.tel_numero
												: 'Sem Telefone',
											student.user.embarque,
											student.user.destino,
										];
									})}
								/>
							</div>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhum produto encontrado
								</p>
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
}
