/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import swal from 'sweetalert';
import cep from 'cep-promise';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import parseJwt from '../../helpers/parseJwt';
import './style.css';

export default function Address({
	idUsuario = null,
	editable = true,
	tittle = 'Endereço',
}) {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = idUsuario || tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);

	const [lblCep, setlblCep] = useState('');
	const [lblLogradouro, setlblLogradouro] = useState('');
	const [lblNumero, setlblNumero] = useState('');
	const [lblBairro, setlblBairro] = useState('');
	const [lblNomeCidade, setlblNomeCidade] = useState('');
	const [lblUFCidade, setlblUFCidade] = useState('');
	const [lblComplemento, setlblComplemento] = useState('');
	const [lblIdAreaLogradouro, setlblIdAreaLogradouro] = useState(1);
	const [lblDescArea, setlblDescArea] = useState('');
	const [lblDescLocalizacao, setlblDescLocalizacao] = useState('');
	const [
		lblIdLocalizacaoDiferenciada,
		setlblIdLocalizacaoDiferenciada,
	] = useState(4);
	const [
		lblHabilitarAtualizacaoEndereco,
		setlblHabilitarAtualizacaoEndereco,
	] = useState(true);
	const history = useHistory();
	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	const [cepNotFound, setCepNotFound] = useState(true);

	async function handleCEP(e) {
		e.preventDefault();
		cep(lblCep)
			.then(dataPostCode => {
				setlblBairro(dataPostCode.neighborhood);
				setlblUFCidade(dataPostCode.state);
				setlblLogradouro(dataPostCode.street);
				setlblNomeCidade(dataPostCode.city);
				setCepNotFound(true);
			})
			.catch(error => {
				console.log(`error: ${error}`);
				setCepNotFound(false);
				swal('ATENÇÃO', 'Cep não encontrado', 'warning');
			});
	}
	async function loadUser() {
		const {
			data: { error, message, data },
		} = await api.get('/usuario/informacao', {
			params: {
				idUser: idUsuario,
			},
		});
		if (error === false) {
			setlblCep(data.usuario.cep);
			setlblLogradouro(data.usuario.logradouro);
			setlblNumero(data.usuario.numero);
			setlblComplemento(data.usuario.complemento_endereco);
			setlblBairro(data.usuario.bairro);
			setlblNomeCidade(data.usuario.cidade);
			setlblUFCidade(data.usuario.estado);
			setlblDescArea(data.usuario.desc_area);
			setlblDescArea(data.usuario.desc_area);
			setlblDescLocalizacao(data.usuario.desc_localizacao);
			setlblIdAreaLogradouro(
				data.usuario.area_logradouro_id_area_logradouro
					? data.usuario.area_logradouro_id_area_logradouro
					: 1
			);
			setlblIdLocalizacaoDiferenciada(
				data.usuario.localizacao_id_localizacao
					? data.usuario.localizacao_id_localizacao
					: 1
			);
		} else {
			swal('ERRO!', `${message}`, 'error');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}

	useEffect(() => {
		loadUser();
	}, []);

	async function handleAtualizarEndereco() {
		setlblHabilitarAtualizacaoEndereco(
			lblHabilitarAtualizacaoEndereco !== true
		);
	}
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			const data = {
				idUsuario: idUser,
				lblLogradouro,
				lblNumCasa: lblNumero,
				lblBairro,
				lblNomeCidade,
				lblSiglaUfCidade: lblUFCidade,
				lblComplementoEndereco: lblComplemento,
				lblCep,
				lblIdAreaLogradouro,
				lblIdLocalizacao: lblIdLocalizacaoDiferenciada,
			};
			await api
				.post('/usuario/atualiza/endereco', data)
				.then(response => {
					if (response.data.error)
						swal('Ops!', `${response.data.message}`, 'warning');
					else {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						loadUser();
						handleAtualizarEndereco();
					}
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className="card mt-3">
					<div className="card-header col-12 col-md-12 col-lg-12 form-group">
						<h4>{tittle}</h4>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Cep</b>
								</p>
								{editable ? (
									<div className="input-group mb-3">
										<InputMask
											mask="99999-999"
											required
											name="lblCep"
											id="lblCep"
											className="form-control"
											placeholder=""
											value={lblCep}
											disabled={
												lblHabilitarAtualizacaoEndereco
											}
											onChange={event =>
												setlblCep(event.target.value)
											}
										/>
										<div className="input-group-append">
											<button
												className="btn btn-success btn-block"
												type="button"
												onClick={handleCEP}
												disabled={
													lblHabilitarAtualizacaoEndereco
												}
											>
												<FaSearch />
											</button>
										</div>
									</div>
								) : (
									<p>{lblCep}</p>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Logradouro</b>
								</p>
								{editable ? (
									<input
										onInput={toInputUppercase}
										required
										disabled={cepNotFound}
										name="lblLogradouro"
										id="lblLogradouro"
										className="form-control"
										placeholder="Digite o Logradouro"
										value={lblLogradouro}
										onChange={event =>
											setlblLogradouro(event.target.value)
										}
									/>
								) : (
									<p>{lblLogradouro}</p>
								)}
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Número</b>
								</p>
								{editable ? (
									<input
										required
										name="lblNumero"
										id="lblNumero"
										className="form-control"
										placeholder="Ex: SN, 123"
										value={lblNumero}
										disabled={
											lblHabilitarAtualizacaoEndereco
										}
										onChange={event =>
											setlblNumero(event.target.value)
										}
									/>
								) : (
									<p>{lblNumero}</p>
								)}
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Complemento</b>
								</p>
								{editable ? (
									<input
										required
										disabled={
											lblHabilitarAtualizacaoEndereco
										}
										onInput={toInputUppercase}
										name="lblComplemento"
										id="lblComplemento"
										className="form-control"
										placeholder="Ex: Casa, Apto..."
										value={lblComplemento}
										onChange={event =>
											setlblComplemento(
												event.target.value
											)
										}
									/>
								) : (
									<p>{lblComplemento}</p>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Bairro</b>
								</p>
								{editable ? (
									<input
										required
										disabled={cepNotFound}
										onInput={toInputUppercase}
										name="lblBairro"
										id="lblBairro"
										className="form-control"
										placeholder="Digite o Bairro"
										value={lblBairro}
										onChange={event =>
											setlblBairro(event.target.value)
										}
									/>
								) : (
									<p>{lblBairro}</p>
								)}
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Cidade</b>
								</p>
								{editable ? (
									<input
										required
										disabled={cepNotFound}
										onInput={toInputUppercase}
										name="lblNomeCidade"
										id="lblNomeCidade"
										className="form-control"
										placeholder="Digite a Cidade"
										value={lblNomeCidade}
										onChange={event =>
											setlblNomeCidade(event.target.value)
										}
									/>
								) : (
									<p>{lblNomeCidade}</p>
								)}
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>UF</b>
								</p>
								{editable ? (
									<input
										required
										disabled={cepNotFound}
										maxLength="2"
										name="lblUFCidade"
										id="lblUFCidade"
										className="form-control"
										placeholder="Digite o UF"
										value={lblUFCidade}
										onChange={event =>
											setlblUFCidade(event.target.value)
										}
									/>
								) : (
									<p>{lblUFCidade}</p>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Área do logradouro</b>
								</p>
								{editable ? (
									<select
										className="form-control"
										name="lblIdAreaLogradouro"
										id="lblIdAreaLogradouro"
										disabled={
											lblHabilitarAtualizacaoEndereco
										}
										value={lblIdAreaLogradouro}
										onChange={event =>
											setlblIdAreaLogradouro(
												event.target.value
											)
										}
									>
										<option value="1">Urbana</option>
										<option value="2">Rural</option>
									</select>
								) : (
									<p>{lblDescArea}</p>
								)}
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>
									<b>Localização Diferenciada</b>
								</p>
								{editable ? (
									<select
										required
										disabled={
											lblHabilitarAtualizacaoEndereco
										}
										value={lblIdLocalizacaoDiferenciada}
										className="form-control"
										name="lblIdLocalizacaoDiferenciada"
										id="lblIdLocalizacaoDiferenciada"
										onChange={event =>
											setlblIdLocalizacaoDiferenciada(
												event.target.value
											)
										}
									>
										<option value="1">
											Área de assentamento
										</option>
										<option value="2">
											Terra indígena
										</option>
										<option value="3">
											Área onde se localizada em
											Comunidade remanescente de Quilombos
										</option>
										<option value="4">
											Não está localizado em área de
											localização diferenciada
										</option>
									</select>
								) : (
									<p>{lblDescLocalizacao}</p>
								)}
							</div>
						</div>
					</div>
					{editable && userActualType !== 9 ? (
						<div className="card-footer">
							<div className="row d-flex align-items-center">
								<div className="col-12 col-md-11 col-lg-11 d-flex justify-content-end">
									<Tooltip
										title="Alterar Dados do Usuário."
										placement="top-start"
									>
										<button
											className="buttonEdit button-profile-edit"
											type="button"
											onClick={handleAtualizarEndereco}
										>
											<FaEdit /> Editar
										</button>
									</Tooltip>
								</div>
								<div className="col-12 col-md-1 col-lg-1 d-flex justify-content-end">
									<button
										className="btn btn-primary"
										type="button"
										onClick={handleSubmit}
										disabled={
											lblHabilitarAtualizacaoEndereco
										}
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</form>
		</>
	);
}
