import React, { useState, Fragment, useEffect } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function InfoCardVehicle({
	listVehicle,
	setIdVehicles,
	disabled,
}) {
	const [vehicles, setVehicles] = useState(listVehicle);
	const [inputFields, setInputFields] = useState([{ idVeiculo: '' }]);
	const [listVehicles, setListVehicles] = useState([]);
	const [arrayTeste, setArrayVeiculo] = useState([]);

	function loadVehicle() {
		api.get('/transporte/veiculo/lista-veiculo')
			.then(response => response.data.data)
			.then(setListVehicles)
			.catch(() => setListVehicles([]));
	}
	useEffect(() => {
		loadVehicle();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ idVeiculo: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		const arrayValues = [...arrayTeste];
		values.splice(index, 1);
		arrayValues.splice(index, 1);
		setArrayVeiculo(arrayValues);
		setInputFields(values);
		setIdVehicles(arrayValues);
	};

	const handleInputChange = (index, event) => {
		setArrayVeiculo([...arrayTeste, event.target.value]);
		setIdVehicles([...arrayTeste, event.target.value]);
		const values = [...inputFields];
		if (event.target.name === 'idVeiculo')
			values[index].idVeiculo = event.target.value;
		setInputFields(values);
	};

	async function removeVehicles(e) {
		const removeVehicle = vehicles.filter(
			s => s.id_usuario_has_veiculo !== e
		);
		setVehicles(removeVehicle);
		if (!removeVehicle.length)
			swal('ATENÇÃO', 'Usuário sem veiculo', 'warning');
		try {
			await api
				.post('/transporte/motorista/desativa-motorista-veiculo', {
					idDriverHasVehicle: e,
				})
				.then(response => {
					swal('ATENÇÃO', `${response.data.message}`, 'warning');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<div className="card mt-3">
			<div className="card-header">Veículo(s)</div>
			<div className="card-body">
				{vehicles.map(vehicle => (
					<>
						<div className="row">
							<div className="mt-2 form-group col-12 col-lg-10 col-md-10">
								<p>
									<b>Modelo: </b>
									{vehicle.modelo} {' - '}
									<b>Placa: </b>
									{vehicle.placa}
								</p>
							</div>
							{disabled === false && (
								<div className="form-group col-12 col-lg-2 col-md-2">
									<button
										className="btn btn-warning"
										type="button"
										onClick={() =>
											removeVehicles(
												vehicle.id_usuario_has_veiculo
											)
										}
									>
										Remover
									</button>
								</div>
							)}
						</div>
						<hr />
					</>
				))}
				{disabled === false ? (
					<>
						<div className="row">
							{inputFields.map((inputField, index) => (
								<Fragment
									// eslint-disable-next-line react/no-array-index-key
									key={`${inputField}~${index}`}
								>
									<div className="form-group col-12 col-lg-10 col-md-10">
										<label htmlFor="idVeiculo">
											Placa do Veículo
										</label>
										<select
											name="idVeiculo"
											className="form-control"
											id="idVeiculo"
											onChange={event =>
												handleInputChange(index, event)
											}
										>
											<option>Escolha um Veículo</option>
											{listVehicles
												? listVehicles.map(vehicle => (
														<option
															key={
																vehicle.id_veiculo
															}
															value={
																vehicle.id_veiculo
															}
														>
															Placa:{' '}
															{vehicle.placa} -
															Modelo:{' '}
															{vehicle.modelo}
														</option>
												  ))
												: null}
										</select>
									</div>
									<div className="form-group col-sm-2">
										{inputFields.length > 1 && (
											<button
												className="btn btn-link"
												type="button"
												onClick={() =>
													handleRemoveFields(index)
												}
											>
												-
											</button>
										)}
										<button
											className="btn btn-link"
											type="button"
											onClick={() => handleAddFields()}
										>
											+
										</button>
									</div>
								</Fragment>
							))}
						</div>
					</>
				) : null}
			</div>
		</div>
	);
}
