import React from 'react';
import { Link } from 'react-router-dom';
import bus404 from '../../assets/img/schoolbus.gif';
import './404.css';

export default function NotFound() {
	return (
		<div className="error-404">
			<div className="container">
				<div className="row bus-404">
					<div className="col-12 col-md-12 col-lg-12">
						<img src={bus404} alt="404" />
					</div>
				</div>
				<Link to="/">
					<button
						type="button"
						className="btn btn-primary btn-block button1"
					>
						Voltar para página principal
					</button>
				</Link>
			</div>
		</div>
	);
}
