/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import swal from 'sweetalert';
import { FaEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parseJwt from '../../../helpers/parseJwt';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import SelectUf from '../../../components/Selects/SelectUf';
import InfoCardSchool from '../../../components/CardsEmployees/InfoCardSchool';
import CardUserContacts from '../../../components/Contact/CardUserContacts';
import CardStudentSupervisor from '../../../components/InfoUser/CardStudentSupervisor';

export default function FormStudents() {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação do Usuário',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblHabilitarAtualizacao, setlblHabilitarAtualizacao] = useState(
		true
	);
	const [lblNomeUsuario, setlblNomeUsuario] = useState('');
	const [lblDataNascimento, setlblDataNascimento] = useState('');
	const [lblCorRaca, setlblCorRaca] = useState('');
	const [lblSexo, setlblSexo] = useState('');
	const [lblEmail, setlblEmail] = useState('');
	const [lblNumDoctoCivil, setlblNumDoctoCivil] = useState('');
	const [lblDigitoDoctoCivil, setlblDigitoDoctoCivil] = useState('');
	const [lblUFDoctoCivil, setlblUFDoctoCivil] = useState('');
	const [lblNacionalidade, setlblNacionalidade] = useState('');
	const [lblIdNacionalidade, setlblIdNacionalidade] = useState(null);
	const [lblNomePaisOrigem, setlblNomePaisOrigem] = useState(null);
	const [lblDataEntradaPais, setlblDataEntradaPais] = useState('');
	const [lblNumVistoConfere, setlblNumVistoConfere] = useState('');
	const [lblNomeCidadeNascido, setlblNomeCidadeNascido] = useState('');
	const [lblUFMunNascto, setlblUFMunNascto] = useState('');
	const [lblDataEmissaoDoctoCivil, setlblDataEmissaoDoctoCivil] = useState(
		''
	);
	const [lblCPF, setlblCPF] = useState('');
	const [lblCep, setlblCep] = useState('');
	const [lblLogradouro, setlblLogradouro] = useState('');
	const [lblNumero, setlblNumero] = useState('');
	const [lblBairro, setlblBairro] = useState('');
	const [lblNomeCidade, setlblNomeCidade] = useState('');
	const [lblUFCidade, setlblUFCidade] = useState('');
	const [lblComplemento, setlblComplemento] = useState('');
	const [lblAreaLogradouro, setlblAreaLogradouro] = useState(1);
	const [
		lblLocalizacaoDiferenciada,
		setlblLocalizacaoDiferenciada,
	] = useState(4);
	const [contactUser, setContactUser] = useState([]);
	const [filhos, setFilhos] = useState([]);
	const [cargosEmEscolas, setCargosEmEscolas] = useState([]);
	const [lblLogin, setlblLogin] = useState('');
	const [lblIdCorRaca, setlblIdCorRaca] = useState(null);
	const [lblIdSexo, setlblIdSexo] = useState(null);
	const [lblCodPaisOrigem, setlblCodPaisOrigem] = useState(null);
	const [lblIdCidadeNascido, setlblIdCidadeNascido] = useState(null);
	const [lblIdAreaLogradouro, setlblIdAreaLogradouro] = useState(null);
	const [lblIdUFMunNascto, setlblIdUFMunNascto] = useState(null);
	const [
		lblIdLocalizacaoDiferenciada,
		setlblIdLocalizacaoDiferenciada,
	] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	async function loadUser() {
		setlblHabilitarAtualizacao(true);
		const {
			data: { error, message, data },
		} = await api.get('/usuario/informacao');
		if (error === false) {
			setlblLogin(data.usuario.login);
			setlblNomeUsuario(data.usuario.nome_usuario);
			setlblDataNascimento(data.usuario.data_nascimento);
			setlblEmail(data.usuario.email);
			setlblCorRaca(data.usuario.desc_cor_raca);
			setlblIdCorRaca(data.usuario.id_cor_raca);
			setlblIdSexo(data.usuario.id_sexo);
			setlblSexo(data.usuario.desc_sexo);
			setlblNumDoctoCivil(data.usuario.rg);
			setlblDigitoDoctoCivil(data.usuario.digito);
			setlblUFDoctoCivil(data.usuario.documento_uf);
			setlblCPF(data.usuario.cpf);
			setlblCep(data.usuario.cep);
			setlblLogradouro(data.usuario.logradouro);
			setlblNumero(data.usuario.numero);
			setlblComplemento(data.usuario.complemento_endereco);
			setlblBairro(data.usuario.bairro);
			setlblNomeCidade(data.usuario.cidade);
			setlblNumVistoConfere(data.usuario.num_visto_confere);
			setlblUFCidade(data.usuario.estado);
			setlblNomePaisOrigem(data.usuario.nome_pais);
			setlblNacionalidade(data.usuario.desc_nacionalidade);
			setlblIdNacionalidade(data.usuario.id_nacionalidade);
			setlblDataEntradaPais(data.usuario.data_entrada_pais);
			setlblNomeCidadeNascido(data.usuario.nome_cidade_natal);
			setlblUFMunNascto(data.usuario.sigla_uf_natal);
			setContactUser(data.contato);
			setFilhos(data.filhos);
			setCargosEmEscolas(data.tipoUsuario);
			setlblAreaLogradouro(data.usuario.desc_area);
			setlblLocalizacaoDiferenciada(data.usuario.desc_localizacao);
			setlblIdLocalizacaoDiferenciada(
				data.usuario.localizacao_id_localizacao
			);
			setlblIdAreaLogradouro(
				data.usuario.area_logradouro_id_area_logradouro
			);
			setlblDataEmissaoDoctoCivil(
				data.usuario.data_emissao_documento_civil
			);
			setlblCodPaisOrigem(data.usuario.cod_pais);
			setlblIdCidadeNascido(data.usuario.cidade_natal);
			setlblIdUFMunNascto(data.usuario.estado_natal);
			setIsLoading(false);
		} else {
			swal('ERRO!', `${message}`, 'error');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}

	useEffect(() => {
		loadUser();
	}, []);

	async function handleAtualizar() {
		setlblHabilitarAtualizacao(lblHabilitarAtualizacao !== true);
	}

	async function handleChangePassWord(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CHANGE_PASSWORD
		);
	}

	async function handleChangeEmail(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = {
				idUsuario: idUser,
				email: lblEmail,
			};
			await api.post('/contato/atualizar/email', data).then(response => {
				if (!response.data.error) {
					swal('SUCESSO!', `${response.data.message}`, 'success');
				} else {
					swal('Ops!', `${response.data.message}`, 'warning');
				}
				loadUser();
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div>
					<div className="card mt-3">
						<div className="card-header">
							<div className="row">
								<div className="col-12 col-md-11 col-lg-11 form-group">
									<h4>Dados do Usuário</h4>
								</div>
								{userActualType !== 9 ? (
									<Tooltip
										title="Alterar Dados do Usuário."
										placement="top-start"
									>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<button
												className="buttonEdit"
												type="button"
											>
												<FaEdit
													onClick={handleAtualizar}
												/>
											</button>
										</div>
									</Tooltip>
								) : null}
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Nome Usuário</b>
									</p>
									<p>{lblNomeUsuario}</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Data Nascimento</b>
									</p>
									<p>{lblDataNascimento}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Cor/Raça</b>
									</p>
									<p>{lblCorRaca}</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Gênero</b>
									</p>
									<p>{lblSexo}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Nacionalidade</b>
									</p>
									<p>{lblNacionalidade}</p>
								</div>
							</div>
						</div>
					</div>
					{parseInt(lblIdNacionalidade) === 1 ? (
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Município de Nascimento</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Estado</b>
											<p>{lblUFMunNascto}</p>
										</p>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Cidade</b>
										</p>
										<p>{lblNomeCidadeNascido}</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Nacionalidade</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>País</b>
											</p>
											<p>{lblNomePaisOrigem}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Data de Entrada no País</b>
											</p>
											<p>{lblDataEntradaPais}</p>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Número do Visto</b>
											</p>
											<p>{lblNumVistoConfere}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<p>Dados de acesso no Sistema</p>
						</div>
						<form onSubmit={handleChangeEmail}>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										{userActualType === 8 ? (
											<>
												<p>
													<b>Login</b>
												</p>
												<input
													type="text"
													name="lblLogin"
													id="lblLogin"
													className="form-control"
													placeholder="Digite o E-mail"
													value={lblCPF}
													disabled
												/>
											</>
										) : (
											<>
												<p>
													<b>Login</b>
												</p>
												<p>{lblLogin}</p>
											</>
										)}
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>E-mail</b>
										</p>
										<input
											type="email"
											name="lblEmail"
											id="lblEmail"
											className="form-control"
											placeholder="Digite o E-mail"
											value={lblEmail}
											required
											onChange={event =>
												setlblEmail(event.target.value)
											}
										/>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="d-flex justify-content-between">
									<button
										className="btn btn-primary"
										type="submit"
									>
										Atualizar E-mail
									</button>
									<button
										className="btn btn-primary"
										type="button"
										onClick={handleChangePassWord}
									>
										Mudar Senha
									</button>
								</div>
							</div>
						</form>
					</div>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Documentos</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-3 form-group">
									<b>Número de Documento Civil</b>
									{lblNumDoctoCivil ? (
										<p>{lblNumDoctoCivil}</p>
									) : (
										<p>Usuário não tem essa informação</p>
									)}
								</div>
								<div className="col-12 col-md-6 col-lg-1 form-group">
									{lblDigitoDoctoCivil ? (
										<p>{lblDigitoDoctoCivil}</p>
									) : null}
								</div>
								<div className="col-12 col-md-6 col-lg-3 form-group">
									<b>CPF do Usuário:</b>
									<div className="input-group mb-4">
										{lblCPF ? (
											<p>{lblCPF}</p>
										) : (
											<p>
												Usuário não tem essa informação
											</p>
										)}
									</div>
								</div>
								<div className="col-12 col-md-6 col-lg-5 form-group">
									<b>Estado do Documento</b>
									{lblUFDoctoCivil ? (
										<SelectUf
											disabled
											valueSelect={lblUFDoctoCivil}
											setUf={setlblUFDoctoCivil}
										/>
									) : (
										<p>Usuário não tem essa informação</p>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-3 form-group">
									<b>Data da Emissão do Documento</b>
									{lblDataEmissaoDoctoCivil ? (
										<p>{lblDataEmissaoDoctoCivil}</p>
									) : (
										<p>Usuário não tem essa informação</p>
									)}
								</div>
							</div>
						</div>
					</div>
					{cargosEmEscolas.length ? (
						<InfoCardSchool
							employeesSchool={cargosEmEscolas}
							disabled
						/>
					) : null}
					{filhos.length ? (
						<CardStudentSupervisor
							responsibleForStudents={filhos}
						/>
					) : null}
					<CardUserContacts
						userContacts={contactUser}
						lblDisable={lblHabilitarAtualizacao}
						idUser={idUser}
					/>
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Endereço</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Cep</b>
									</p>
									<div className="input-group mb-3">
										<p>{lblCep}</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Logradouro</b>
									</p>
									<p>{lblLogradouro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Número</b>
									</p>
									<p>{lblNumero}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Complemento</b>
									</p>
									<p>{lblComplemento}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Bairro</b>
									</p>
									<p>{lblBairro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Cidade</b>
									</p>
									<p>{lblNomeCidade}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>UF</b>
									</p>
									<p>{lblUFCidade}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Área do logradouro</b>
									</p>
									<p>{lblAreaLogradouro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Localização Diferenciada</b>
									</p>
									<p>{lblLocalizacaoDiferenciada}</p>
								</div>
							</div>
						</div>
						{lblHabilitarAtualizacao === false && (
							<div className="card-footer">
								<button
									className="btn btn-primary"
									type="submit"
								>
									Atualizar
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
