/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FaEye, FaPencilAlt } from 'react-icons/fa';
import api from '../../services/api';
import Breadcrumb from '../Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import MapStudents from '../Map/Georreferencia/Students/MapStudents';
import Table from '../Table';
import RouteParams from '../../routes/data/RouteParams';
import SelectSerieAno from '../Selects/SelectSerieAno';
import { useFunctionalities } from '../Contenxt/Functionalities';

export default function ListStudentsGeneric() {
	const {
		functionalities: { functionalities },
	} = useFunctionalities();
	const [updateStudents, setUpdateStudents] = useState(false);
	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 23
			);
			if (existe) setUpdateStudents(true);
			else setUpdateStudents(false);
		}
	}, [functionalities]);

	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const [students, setStudents] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [school, setSchool] = useState([]);
	// novas const para o filtro
	const [studentSex, setStudentSex] = useState('');

	const [schoolID, setSchoolID] = useState('');
	const [turma, setTurma] = useState('');
	const [neceTrans, setNeceTrans] = useState('');
	const [neceEspe, setNeceEspe] = useState('');
	const [serieID, setSerieID] = useState('');
	const [filter, setFilter] = useState(true);
	const [coordenadas, setCoordenadas] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista de Alunos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	async function handleSeachStudents() {
		setIsLoading(true);
		const data = {
			schoolID,
			serieID,
			studentSex,
			turma,
			neceTrans,
			neceEspe,
		};
		const response = await api.post('/aluno/escola/lista', data);
		if (!response.data.error) {
			setStudents(response.data.data.listStudents);
			setCoordenadas(response.data.data.coordenadas);
		} else {
			setStudents([]);
			setCoordenadas([]);
		}
		setIsLoading(false);
	}

	function OptionsButton({ student }) {
		function viewNavigation() {
			const idUsuario = student.id_usuario;
			const routeState = {};
			routeState[
				RouteParams.LEVEL_06_ROUTES.STUDENT_INFO.USER_ID
			] = idUsuario;
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.STUDENT_INFO,
				routeState
			);
		}

		function editNavigation() {
			const idUsuario = student.id_usuario;
			const state = {};
			state[
				RouteParams.LEVEL_05_ROUTES.PROFILE_STUDENT.USER_ID
			] = idUsuario;
			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).PROFILE_STUDENT,
				state,
			});
		}

		return (
			<>
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<button
								type="button"
								aria-hidden
								data-toggle="tooltip"
								title="Ver Motorista"
								className="buttonViewColor"
								onClick={() => viewNavigation(student)}
							>
								<FaEye fill="#ffffff" />
							</button>
						</div>
						{updateStudents && (
							<div className="col-12 col-md-6 col-lg-6">
								<button
									type="button"
									aria-hidden
									data-toggle="tooltip"
									title="Editar monitor"
									className="buttonAddColor"
									onClick={() => editNavigation(student)}
								>
									<FaPencilAlt fill="#ffffff" />
								</button>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}

	useEffect(() => {
		if (
			schoolID !== '' ||
			turma !== '' ||
			serieID !== '' ||
			neceTrans !== '' ||
			neceEspe !== ''
		)
			setFilter(false);
		else setFilter(true);
	}, [schoolID, turma, serieID, neceTrans, neceEspe]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setSchoolID(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<SelectSerieAno
								setIdSerieAno={setSerieID}
								valueSelect={serieID}
							/>
						</div>
						{/* implementação de sexo aqui */}
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Sexo</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={studentSex}
								onChange={event =>
									setStudentSex(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="Masculino">Masculino</option>
								<option value="Feminino">Feminino</option>
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Turma</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={turma}
								onChange={event => setTurma(event.target.value)}
							>
								<option value="">Escolha uma opção</option>
								<option value="A">A</option>
								<option value="B">B</option>
								<option value="C">C</option>
								<option value="D">D</option>
								<option value="F">F</option>
								<option value="G">G</option>
								<option value="H">H</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Necessidade de Transporte</b>
							</label>
							<select
								id="school"
								value={neceTrans}
								className="form-control"
								onChange={event =>
									setNeceTrans(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="0">Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Necessidades Especiais</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={neceEspe}
								onChange={event =>
									setNeceEspe(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="0">Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
					</div>
					<button
						onClick={handleSeachStudents}
						className="btn btn-primary"
						disabled={filter}
						type="button"
						id="button-addon2"
					>
						Filtrar
					</button>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							{students.length ? (
								<div className="text-size-table">
									<Table
										header={[
											'RA',
											'Nome Aluno',
											'Data de Nascimento',
											'Escola',
											'Série',
											'',
										]}
										body={students.map(student => {
											return [
												student.num_ra,
												student.nome_usuario,
												student.data_nascimento,
												student.desc_nome_escola,
												`${student.desc_serie_ano} - ${student.desc_tipo_ensino}`,
												<OptionsButton
													student={student}
												/>,
											];
										})}
									/>
								</div>
							) : null}
						</>
					)}
				</div>
			</div>
			<MapStudents coordenadas={coordenadas} />
		</>
	);
}
