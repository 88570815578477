import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import swal from 'sweetalert';
import ReactInputMask from 'react-input-mask';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Breadcrumb from '../../../components/Breadcrumb';
import api from '../../../services/api';
import RouteParams from '../../../routes/data/RouteParams';
import ScrollToTop from '../../../components/buttom/ScrollToTop';

export default function Index() {
	const history = useHistory();
	const location = useLocation();
	const userActualType = useSelector(state => state.userType.actualType);
	const [codRenavam, setCodRenavam] = useState('');
	const [placa, setPlaca] = useState('');
	const [modelo, setModelo] = useState('');
	const [proprietario, setProprietario] = useState('');
	const [cpfProprietario, setCpfProprietario] = useState('');
	const [chassi, setChassi] = useState('');
	const [idVehicle, setIdVehicle] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_VEHICLE,
			namePage: 'Veículos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atualiza Veículo',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function loadVehicle() {
		setIsLoading(true);
		if (location.state) {
			const idVehicleParams =
				location.state.params[
					RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE
				];
			const { data } = await api.get('/transporte/veiculo/info-veiculo', {
				params: {
					idVeiculo: idVehicleParams,
				},
			});
			if (data.error === false) {
				setIdVehicle(idVehicleParams);
				setCodRenavam(data.data.infoVehicle.cod_renavam);
				setPlaca(data.data.infoVehicle.placa);
				setModelo(data.data.infoVehicle.modelo);
				setProprietario(data.data.infoVehicle.proprietario);
				setCpfProprietario(data.data.infoVehicle.cpf_proprietario);
				setChassi(data.data.infoVehicle.chassi);
			} else {
				setIdVehicle('');
				setCodRenavam('');
				setPlaca('');
				setModelo('');
				setProprietario('');
				setCpfProprietario('');
				setChassi('');
			}
		} else
			swal({ icon: 'error', text: 'Escolha um veículo' }).then(event => {
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_VEHICLE
				);
			});
		setIsLoading(false);
	}

	useEffect(() => {
		loadVehicle();
	}, []);

	async function handleUpdateVehicle() {
		setIsLoading(true);
		try {
			const { data } = await api.post(
				'/transporte/veiculo/atualizar-veiculo',
				{
					idVeiculo: idVehicle,
					codRenavam,
					placa,
					modelo,
					proprietario,
					cpfProprietario,
					chassi,
				}
			);
			if (data.error === false)
				swal({
					icon: 'success',
					text: data.message,
				});
			else swal({ icon: 'error', text: data.message });
		} catch (error) {
			swal('Houve um erro ao atualizar o veículo.');
		}
		setIsLoading(false);
	}

	async function handleGoBack(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).LIST_VEHICLE
		);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<form onSubmit={handleUpdateVehicle}>
					<div className="card mt-3">
						<div className="card-header form-group">
							<h4>Cadastro de Veículos</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="addVehicle_renavam">
										<b>Cod. Renavam</b>
									</label>
									<input
										type="text"
										className="form-control"
										required
										id="addVehicle_renavam"
										placeholder="Renavam do veículo"
										value={codRenavam}
										onChange={event =>
											setCodRenavam(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="addVehicle_placa">
										<b>Placa</b>
									</label>
									<input
										type="text"
										required
										className="form-control"
										id="addVehicle_placa"
										placeholder="Placa do veículo"
										value={placa}
										onChange={event =>
											setPlaca(event.target.value)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="addVehicle_modelo">
										<b>Modelo</b>
									</label>
									<input
										type="text"
										required
										className="form-control"
										id="addVehicle_modelo"
										placeholder="Ex: Gol 1.8, 4 portas"
										value={modelo}
										onChange={event =>
											setModelo(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="addVehicle_proprietario">
										<b>Proprietário</b>
									</label>
									<input
										type="text"
										className="form-control"
										id="addVehicle_proprietario"
										placeholder="Nome do proprietário"
										value={proprietario}
										onChange={event =>
											setProprietario(event.target.value)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="addVehicle_cpf">
										<b>CPF Proprietário</b>
									</label>
									<ReactInputMask
										mask="999.999.999-99"
										size={16}
										type="text"
										name="cpfProprietario"
										id="addVehicle_cpf"
										className="form-control"
										placeholder="CPF do Usuário"
										value={cpfProprietario}
										onChange={event =>
											setCpfProprietario(
												event.target.value
											)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group text-left">
									<label htmlFor="addVehicle_chassi">
										<b>Chassi</b>
									</label>
									<input
										type="text"
										className="form-control"
										id="addVehicle_chassi"
										placeholder="Chassi"
										value={chassi}
										onChange={event =>
											setChassi(event.target.value)
										}
									/>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-lg-4 col-md-4 col-12 form-group">
									<button
										className="btn buttonCustomSemec"
										type="submit"
									>
										Atauliza
									</button>
								</div>
								<div className="col-lg-4 col-md-4 col-12 form-group">
									<button
										className="btn buttonCustomCancelSemec"
										type="button"
										onClick={handleGoBack}
									>
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</>
	);
}
