import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectSexo({ setSexo, valueSelect, disabled }) {
	const [sexo, setSexos] = useState([]);

	useEffect(() => {
		async function loadSexo() {
			const response = await api.get('/tabelas/sexo');
			setSexos(response.data.data);
		}
		loadSexo();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(sexo) {
		setSexo(sexo);
	}
	const sexoList = sexo.map(sex => (
		<option value={sex.id_sexo} key={sex.id_sexo}>
			{sex.desc_sexo}
		</option>
	));
	return (
		<>
			<select
				required="required"
				className="form-control"
				name="lblIdSexo"
				id="lblIdSexo"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Escolha o Genero</option>
				{sexoList}
			</select>
		</>
	);
}
