import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FaSearch } from 'react-icons/fa';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';
import Table from '../../../components/Table';
import SelectEscola from '../../../components/Selects/SelectEscola';

export default function() {
	const [subjects, setSubjects] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [schoolId, setSchoolId] = useState(null);

	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Disciplinas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	async function handleSearch(event) {
		event.preventDefault();
		setIsLoading(true);

		const { data } = await api.get('/professor/listardisciplinas', {
			params: {
				escolaID: schoolId,
			},
		});
		if (data.error === false) setSubjects(data.data);
		else {
			swal({ icon: 'warning', text: data.message });
			setSubjects([]);
		}
		setIsLoading(false);
	}
	useEffect(() => {
		(async function() {
			setIsLoading(true);
			const { data } = await api.get('/professor/listardisciplinas', {
				params: {
					escolaID: schoolId,
				},
			});
			if (data.error === false) setSubjects(data.data);
			else {
				swal({ icon: 'warning', text: data.message });
				setSubjects([]);
			}
			setIsLoading(false);
		})();
	}, []);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					<form onSubmit={handleSearch}>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6">
								<div className="form-group">
									<SelectEscola
										required={false}
										setIdSchool={setSchoolId}
										value={schoolId}
										disabled={false}
										label="Escolas"
									/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-12 form-group">
								<br />
								<button
									type="submit"
									className="btn btn-block btn-success mt-2"
								>
									<FaSearch className="searchIcon" />
									Buscar
								</button>
							</div>
						</div>
					</form>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<Table
							header={['Disciplina', 'Escola']}
							body={subjects.map(subject => {
								return [
									subject.nome_disciplina,
									subject.desc_nome_escola,
								];
							})}
						/>
					)}
				</div>
			</div>
		</>
	);
}
