/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga';
import parseJwt from './helpers/parseJwt';
import { UserTypeActions } from './redux/ducks/userTypeReducer';
import Bookloading from './components/Loading/Bookloading';
import Navigation from './routes/Navigation';
import LoginRoutes from './routes/LoginRoutes';
import SwitchUser from './routes/components/SwitchUser';
import { useJwt } from 'react-jwt';

export default function() {
	const location = useLocation();
	const userType = useSelector(state => state.userType);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const token = localStorage.getItem('@token');
	const { decodedToken } = useJwt(token);

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);

	useEffect(() => {
		if (userType.types.length !== 0) {
			setLoading(false);
			return;
		}
		if (token === null) {
			setLoading(false);
			return;
		}
		const ids = parseJwt(token).tipo_usuario;
		dispatch(UserTypeActions.updateUserTypeIds(ids));

		if (ids.length === 1) {
			dispatch(UserTypeActions.updateActualTypeId(ids[0]));
			if (!localStorage.getItem('@actualTypeId'))
				localStorage.setItem('@actualTypeId', ids[0]);
		} else if (
			localStorage.getItem('@actualTypeId') &&
			ids.findIndex(
				item => item === localStorage.getItem('@actualTypeId')
			)
		)
			dispatch(
				UserTypeActions.updateActualTypeId(
					+localStorage.getItem('@actualTypeId')
				)
			);
	}, []);

	useEffect(() => {
		if (userType.types.length === 0) setLoading(false);
	}, [userType]);

	if (loading)
		return (
			<Bookloading
				isLoading
				style={{ width: '50%', marginLeft: '25%' }}
			/>
		);
	else if (userType.actualType !== null || userType.types.length === 1)
		if (decodedToken && token && decodedToken.exp < Date.now() / 1000) {
			return <LoginRoutes />;
		} else {
			return (
				<Navigation
					userTypeId={userType.actualType || userType.types[0]}
				/>
			);
		}
	else if (userType.types.length === 0) return <LoginRoutes />;
	else return <SwitchUser userTypes={userType.types} />;
}
