import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Routes from '../../../routes/data/Routes';
import api from '../../../services/api';

export default function CreateSupplies() {
	const userActualType = useSelector(u => u.userType.actualType);
	const [isLoading, setIsLoading] = useState(false);
	const [materialNome, setMaterialNome] = useState('');
	const [description, setDescription] = useState('');
	const [quantity, setQuantity] = useState(null);
	const [value, setValue] = useState(null);
	const [defect, setDefect] = useState(null);
	const [total, setTotal] = useState(null);
	const [assetTag, setAssetTag] = useState(null);

	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_SUPPLIES,
			namePage: 'Lista de Materiais',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cadastrar Material de Insumo',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		const dataUpdate = {
			nomeMaterial: materialNome,
			descricao: description,
			quantidade: quantity,
			valor: value,
			defeito: defect,
			etiquetaPatrimonial: assetTag,
		};
		const { data } = await api.post(
			'/material-de-escritorio/create-material',
			dataUpdate
		);
		if (data.error === true) swal({ icon: 'warning', text: data.message });
		else {
			swal({ icon: 'success', text: data.message });
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_SUPPLIES
			);
		}
		setIsLoading(false);
	}
	useEffect(() => {
		setTotal(value * quantity);
	}, [value, quantity]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							Cadastrar Material de Insumo
						</div>
					</div>
				</div>
				<div className="card-body">
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<form onSubmit={handleSubmit}>
							<div className="row form-group">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Nome do Material de Insumo</b>
									</p>
									<input
										type="text"
										name="materialNome"
										id="materialNome"
										required
										maxLengt="60"
										className="form-control"
										placeholder="Cadeiras"
										value={materialNome}
										onChange={event =>
											setMaterialNome(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Defeito</b>
									</p>
									<select
										required
										className="form-control"
										name="lblBolsaFamilia"
										id="lblBolsaFamilia"
										value={defect}
										onChange={event =>
											setDefect(event.target.value)
										}
									>
										<option value="0">Não</option>
										<option value="1">Sim</option>
									</select>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Etiqueta patrimonial</b>
									</p>
									<input
										type="text"
										name="assetTag"
										id="assetTag"
										required
										className="form-control"
										placeholder="N3XT#001"
										value={assetTag}
										onChange={event =>
											setAssetTag(
												event.target.value.toUpperCase()
											)
										}
									/>
								</div>
							</div>
							<div className="row form-group">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Quantidade</b>
									</p>
									<input
										type="number"
										name="quantity"
										id="quantity"
										required
										className="form-control"
										placeholder="200"
										value={quantity}
										onChange={event =>
											setQuantity(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Valor</b>
									</p>
									<label
										className="sr-only"
										htmlFor="inlineFormInputGroup"
									>
										150,00
									</label>
									<div className="input-group mb-2">
										<div className="input-group-prepend">
											<div className="input-group-text">
												R$
											</div>
										</div>
										<input
											type="text"
											className="form-control"
											id="inlineFormInputGroup"
											placeholder="150,00"
											value={value}
											onChange={event =>
												setValue(
													event.target.value.replace(
														',',
														'.'
													)
												)
											}
										/>
									</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Total</b>
									</p>
									R$ {total}
								</div>
							</div>
							<div className="form-group">
								<p>
									<b>Descrição</b>
								</p>
								<textarea
									required
									className="form-control"
									rows="6"
									value={description}
									onChange={event =>
										setDescription(event.target.value)
									}
								/>
							</div>
							<button type="submit" className="btn btn-primary">
								Criar
							</button>
						</form>
					)}
				</div>
			</div>
		</>
	);
}
