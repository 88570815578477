import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import './styleCharts.css';

export default function Charts({ title, quantities, colors, labelsArray }) {
	const chart = {
		options: {
			scales: {
				yAxes: [
					{
						ticks: {
							callback(value) {
								if (!(value % 10))
									// return '$' + value + 'k'
									return value;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					label(item, data) {
						const label =
							data.datasets[item.datasetIndex].label || '';
						const { yLabel } = item;
						let content = '';
						if (data.datasets.length > 1) content += label;

						content += yLabel;
						return content;
					},
				},
			},
		},
		data: {
			labels: labelsArray,
			datasets: [
				{
					label: title,
					data: quantities,
					backgroundColor: colors,
				},
			],
		},
	};

	return (
		<div className="card">
			<div className="card-body">
				<span className="tittle-graphics">{title}</span>
				<Bar data={chart.data} options={chart.options} />
			</div>
		</div>
	);
}
