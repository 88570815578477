import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

import { FaPlay, FaTrash } from 'react-icons/fa';
import api from '../../services/api';

import './styles.css';

function VideoCard({
	content,
	atualiza = null,
	library = null,
	hasModule = null,
	student = null,
}) {


	async function handleDeleteVideo() {

		const id_video = content.url_video;
		await api.delete(`/biblioteca/delete-video/${content.id_biblioteca_video}`, {
			data: { id_video },
		});
		atualiza();
	}

	async function handleDeleteVideoModule() {
		await api.delete(`/atribuicao/deleta-video/${content.id}`);
		atualiza();
	}

	return (
		<div className="contentVideo">
			<div className="titleDescri">
				<h5>{content.nome}</h5>
				<span>{content.descricao}</span>
			</div>
			<div className="buttonGroup">
				<button
					type="button"
					data-toggle="modal"
					data-target="#exampleModal"
				>
					<FaPlay />
				</button>

				<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">{content.nome}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<Vimeo
									color="#007bff"
									video={content.url_video}
									responsive
									height={300}
								/>
							</div>

						</div>
					</div>
				</div>

				{library ? (
					student ? null : (
						<button type="button" onClick={handleDeleteVideo}>
							<FaTrash />
						</button>
					)
				) : null}
				{hasModule ? (
					student ? null : (
						<button type="button" onClick={handleDeleteVideoModule}>
							<FaTrash />
						</button>
					)
				) : null}
			</div>
		</div>
	);
}

export default VideoCard;
