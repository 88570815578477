import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { FaTimesCircle } from 'react-icons/fa';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import './style.css';
import { useSelector } from 'react-redux';
import LabelByPermission from '../../routes/data/LabelByPermission';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Routes from '../../routes/data/Routes';
import api from '../../services/api';
import regex from '../../helpers/regex';

export default function ShoppingList() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [shoppingList, setShoppingList] = useState([]);
	const [date, setDate] = useState(null);
	const [fetched, setFetched] = useState(false);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(userActualType).HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista de Compras',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getShoppingList(event) {
		event.preventDefault();

		if (!date)
			return swal(
				'ATENÇÃO',
				'Digite a data da lista de compras',
				'warning'
			);

		const response = await api.post(
			'cozinha-piloto/lista-de-compras/buscar',
			{
				data: date
					.split('/')
					.reverse()
					.join('-'),
			}
		);

		!response.data.error
			? setShoppingList(response.data.data ? response.data.data : [])
			: swal('ERRO!', `${response.data.message}`);

		setFetched(true);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="shoppingListContainer">
				<form
					className="dateInputsShoppingList"
					onSubmit={getShoppingList}
				>
					<div className="form-row align-items-end">
						<div className="col-12 col-md-12 col-lg-6">
							<label htmlFor="dateInputShoppingList">
								Selecione a data da lista de compra
							</label>
							<InputMask
								className="form-control"
								mask="99/99/9999"
								pattern={regex.DATE}
								onChange={event => {
									setDate(event.target.value);
								}}
							/>
						</div>
						<div className="col-12 col-md-12 col-lg-6 d-flex flex-column flex-md-row justify-content-end justify-content-lg-around">
							<button
								type="submit"
								className="btn btn-success mx-0 mx-md-3 my-3 my-md-3 my-lg-0"
							>
								Buscar lista de compras
							</button>

							<Link
								to={
									Routes.LOGGED_ROUTES(
										RouteByPermission[userActualType]
									).SHOPPING_LIST_ADD
								}
								className="addNewListButton my-md-3 my-lg-0"
							>
								Adicionar nova lista
							</Link>
						</div>
					</div>
				</form>

				{fetched &&
					(shoppingList.length !== 0 ? (
						<div className="card shoppingListCardTable">
							<p className="shoppingListTableTitle">
								Lista de compras
							</p>

							<table className="table">
								<thead>
									<tr>
										<th scope="col">Produto</th>
										<th scope="col">Quantidade</th>
									</tr>
								</thead>
								<tbody>
									{shoppingList.map(item => (
										<tr>
											<td>{item.nome}</td>
											<td>{item.quantidade}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<div className="shoppingListNotFound">
							<FaTimesCircle size={30} />
							<p className="mt-2">
								Nenhuma lista de compras encontrada
							</p>
						</div>
					))}
			</div>
		</>
	);
}
