import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectDisciplinas({
	setDisciplina,
	valueSelect,
	disabled,
	required,
}) {
	const [disciplinas, setDisciplinas] = useState([]);
	useEffect(() => {
		(async function() {
			const response = await api.get('/tabelas/disciplina');
			if (!response.data.error)
				setDisciplinas(response.data.data);
		})();
	}, []);
	// eslint-disable-next-line no-shadow
	async function handleSubmit(idDisciplinas) {
		setDisciplina(idDisciplinas);
	}
	const disciplinaList = disciplinas.map(disciplina => (
		<option value={disciplina.id_disciplina} key={disciplina.id_disciplina}>
			{disciplina.nome_disciplina}
		</option>
	));
	return (
		<>
			<select
				disabled={disabled}
				required={required}
				className="form-control"
				name="classeId"
				id="classeId"
				value={valueSelect}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha uma Disciplina</option>
				{disciplinaList}
			</select>
		</>
	);
}
