import React from 'react';
import ListNotices from '../../components/Notices/ListNotices';
import Card from '../Dash/Level_02/Card';
import VideoLesson from '../../components/VideoLesson';

export default function Dash() {
	return (
		<>
			<Card />
			<ListNotices />
			<VideoLesson/>
		</>
	);
}
