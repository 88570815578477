import React, { createContext, useState, useContext } from 'react';

const FunctionalitiesContext = createContext();

export default function FunctionalitiesProvider({ children }) {
	const [functionalities, setFunctionalities] = useState({});

	return (
		<FunctionalitiesContext.Provider
			value={{
				functionalities,
				setFunctionalities,
			}}
		>
			{children}
		</FunctionalitiesContext.Provider>
	);
}

export function useFunctionalities() {
	const context = useContext(FunctionalitiesContext);
	if (!context)
		throw new Error(
			'useFunctionalities must be used within a FunctionalitiesProvider'
		);
	const { functionalities, setFunctionalities } = context;
	return { functionalities, setFunctionalities };
}
