import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FaRegUserCircle, FaSignOutAlt } from 'react-icons/fa';

import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import DropdownUser from '../../routes/components/DropdownUser';
import { useFunctionalities } from '../Contenxt/Functionalities';
import parseJwt from '../../helpers/parseJwt';
import './style.css';
import api from '../../services/api';
import { UserTypeActions } from '../../redux/ducks/userTypeReducer';

export default function PerfilUser() {

	const userType = useSelector(state => state.userType);
	const { setFunctionalities } = useFunctionalities();
	const [colorClassUser, setColorClassUser] = useState('');
	const [colorClassUserHeader, setColorClassUserHeader] = useState('');
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const history = useHistory();
	const { name } = tokenUsuario;

	const dispatch = useDispatch();
	function logoutUser(event) {
		event.preventDefault();
		(async function () {
			await api.post('/logout');
			setFunctionalities([]);
			dispatch(UserTypeActions.updateActualTypeId(null));
			dispatch(UserTypeActions.updateUserTypeIds([]));
			history.push(Routes.LOGIN);
			localStorage.clear();
		})();
	}
	useEffect(() => {
		if (userType.actualType === 1 || userType.actualType === 2) {
			setColorClassUser('background-profile-adm');
			setColorClassUserHeader('item-header-profile-adm');
		} else if (
			userType.actualType === 3 ||
			userType.actualType === 4 ||
			userType.actualType === 5
		) {
			setColorClassUserHeader('item-header-profile-school');
			setColorClassUser('background-profile-school');
		} else if (userType.actualType === 6 || userType.actualType === 7) {
			setColorClassUserHeader('item-header-profile-teacher');
			setColorClassUser('background-profile-teacher');
		} else if (userType.actualType === 8) {
			setColorClassUserHeader('item-header-profile-parents');
			setColorClassUser('background-profile-parents');
		} else if (userType.actualType === 9) {
			setColorClassUserHeader('item-header-profile-students');
			setColorClassUser('background-profile-students');
		} else {
			setColorClassUser('background-profile-others');
			setColorClassUserHeader('item-header-profile-others');
		}
	}, [userType]);

	return (
		<>
			<div className="dropdown d-flex flex-column justify-content-end">
				<Link
					to=""
					className="nav-link dropdown-toggle d-flex justify-content-end align-items-center"
					id="dropdownMenuLink"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<span className={`${colorClassUser} icon-profile`}>
								{name.toUpperCase().charAt(0)}
							</span>
						</div>
					</div>
				</Link>
				<div
					id="dropdown-menu-profile"
					className="dropdown-menu dropdown-menu-right dropdown-menu-right-profile"
					aria-labelledby="dropdownMenuLink"
				>
					<div
						className={`${colorClassUser} ${colorClassUserHeader} item-header-profile`}
					>
						<h6 className="item-title-profile">{name}</h6>
					</div>
					<div className="settings-list">
						{userType.types.length > 1 && (
							<DropdownUser userTypes={userType.types} />
						)}
						<Link
							className="dropdown-item"
							to={
								Routes.LOGGED_ROUTES(
									RouteByPermission[userType.actualType]
								).INFO_PROFILE
							}
						>
							<FaRegUserCircle /> Perfil
						</Link>
						<button
							type="button"
							className="dropdown-item"
							onClick={logoutUser}
						>
							<FaSignOutAlt /> Sair
						</button>
					</div>
				</div>
			</div>
			
		</>
	);
}
