import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaInbox } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { FiSend } from 'react-icons/fi';
import Tooltip from '@material-ui/core/Tooltip';
import InBox from './Inbox';
import SendBox from './SendBox';
import WriteCommunicated from './WriteCommunicated/WriteCommunicated';

export default function Communicated() {
	const [isLoading, setIsLoading] = useState(true);
	const [lblDisable, setlblDisable] = useState(false);
	const [lblDisableMayor, setlblDisableMayor] = useState(false);
	const [inbox, setInbox] = useState(false);
	const [sendBox, setSendBox] = useState(false);
	const actualType = useSelector(state => state.userType.actualType);

	async function loadCommunicateds() {
		setIsLoading(false);
	}

	async function handleInbox(event) {
		event.preventDefault();
		setInbox(true);
		setSendBox(false);
	}

	async function handleSendbox(event) {
		event.preventDefault();
		setInbox(false);
		setSendBox(true);
	}
	
	useEffect(() => {
		if (actualType === 8 || actualType === 9) setlblDisable(true);
		actualType === 1 ? setlblDisableMayor(true) : setlblDisableMayor(false);
		loadCommunicateds();
	}, []);

	async function handleNew(event) {
		event.preventDefault();
	}

	return (
		<>
			{isLoading === true ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item">
								{lblDisableMayor === false ? (
									<Tooltip
										title="Caixa de Comunicados."
										placement="top-start"
									>
										<a
											className={`nav-link ${
												lblDisableMayor === false
													? 'active'
													: ''
											}`}
											id="home-tab"
											data-toggle="tab"
											href="#home"
											onClick={handleInbox}
											role="tab"
											aria-controls="home"
											aria-selected="true"
										>
											<FaInbox id="FaInbox" />{' '}
											<small>Recebidos </small>
										</a>
									</Tooltip>
								) : null}
							</li>
							<li className="nav-item ">
								{lblDisable === false ? (
									<Tooltip
										title="Comunicados Enviados."
										placement="top-start"
									>
										<a
											className={`nav-link ${
												lblDisableMayor === true
													? 'active'
													: ''
											}`}
											id="profile-tab"
											data-toggle="tab"
											href="#perfil"
											role="tab"
											aria-controls="profile"
											aria-selected="false"
											onClick={handleSendbox}
										>
											<MdEmail id="MdEmail" />{' '}
											<small>Enviados</small>
										</a>
									</Tooltip>
								) : null}
							</li>
							<li className="nav-item">
								{lblDisable === false ? (
									<Tooltip
										title="Escrever Novo Comunicado."
										placement="top-start"
									>
										<a
											className="nav-link"
											id="contact-tab"
											data-toggle="tab"
											href="#contato"
											role="tab"
											aria-controls="contact"
											aria-selected="false"
											onClick={handleNew}
										>
											<FiSend id="FiSend" />{' '}
											<small>Mandar</small>
										</a>
									</Tooltip>
								) : null}
							</li>
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="home"
								role="tabpanel"
								aria-labelledby="home-tab"
							>
								<InBox inbox={inbox} />
							</div>
							<div
								className="tab-pane fade"
								id="perfil"
								role="tabpanel"
								aria-labelledby="profile-tab"
							>
								<SendBox sendBox={sendBox} />
							</div>
							<div
								className="tab-pane fade"
								id="contato"
								role="tabpanel"
								aria-labelledby="contact-tab"
							>
								<WriteCommunicated />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
