import React from 'react';

export default function Footer() {
	return (
		<footer className="footer">
			<p className="footer-text text-center">
				&copy; Next Tecnologia - {new Date().getFullYear()}
			</p>
		</footer>
	);
}
