import React from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../../components/Panel';
import Footer from '../../../components/Footer';
import FormRouteStudents from '../../../components/Map/Students/FormRouteStudentsDirection';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function Employee() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Rota do Transporte Escolar - Somente ida',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	
	return <FormRouteStudents breadCrumbItems={breadCrumbItems} />;
}
