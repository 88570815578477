import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaTimesCircle } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';

export default function ListDriver() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Motoristas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [drivers, setDrivers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	async function loadDriver() {
		setIsLoading(true);
		const response = await api.get('/transporte/motorista/lista-motorista');
		if (!response.data.error) setDrivers(response.data.data);
		else {
			setDrivers([]);
			swal(response.data.message);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadDriver();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						{drivers ? (
							<Table
								header={['Nome']}
								body={drivers.map(driver => {
									return [driver.users.nome_usuario];
								})}
							/>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhum motorista encontrado
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
