/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import swal from 'sweetalert';
import { FaEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import SelectOrgaoExpedidor from '../Selects/SelectOrgaoExpedidor';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import parseJwt from '../../helpers/parseJwt';
import './style.css';

export default function Documents({
	idUsuario = null,
	tittle = 'Documentos',
	editable = true,
}) {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = idUsuario || tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);

	const [lblNumDoctoCivil, setlblNumDoctoCivil] = useState('');
	const [lblDigitoDoctoCivil, setlblDigitoDoctoCivil] = useState('');
	const [lblUFDoctoCivil, setlblUFDoctoCivil] = useState('');
	const [lblEstado, setlblEstado] = useState('');
	const [lblDataEmissaoDoctoCivil, setlblDataEmissaoDoctoCivil] = useState(
		''
	);
	const [lblCPF, setlblCPF] = useState('');
	const [
		lblHabilitarAtualizacaoDocumentos,
		setlblHabilitarAtualizacaoDocumentos,
	] = useState(true);
	const history = useHistory();

	async function loadUser() {
		const {
			data: { error, message, data },
		} = await api.get('/usuario/informacao', {
			params: {
				idUser: idUsuario,
			},
		});
		if (error === false) {
			setlblNumDoctoCivil(data.usuario.rg);
			setlblDigitoDoctoCivil(data.usuario.digito);
			setlblUFDoctoCivil(data.usuario.documento_uf);
			setlblCPF(data.usuario.cpf);
			setlblEstado(data.usuario.documento_uf);
			setlblDataEmissaoDoctoCivil(
				data.usuario.data_emissao_documento_civil
			);
		} else {
			swal('ERRO!', `${message}`, 'error');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}

	useEffect(() => {
		loadUser();
	}, []);

	async function handleAtualizarDocumentos() {
		setlblHabilitarAtualizacaoDocumentos(
			lblHabilitarAtualizacaoDocumentos !== true
		);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		try {
			const data = {
				idUsuario: idUser,
				lblCPF,
				lblNumDocumentoCivil: lblNumDoctoCivil,
				lblDigitoDocumento: lblDigitoDoctoCivil,
				lblIdUfDocumento: lblUFDoctoCivil,
				lblDataEmissao: lblDataEmissaoDoctoCivil,
			};
			await api
				.post('/usuario/atualiza/documentos', data)
				.then(response => {
					if (response.data.error) {
						swal('Ops!', `${response.data.message}`, 'warning');
					} else {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						handleAtualizarDocumentos();
						loadUser();
					}
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className="card mt-3">
					<div className="card-header form-group">
						<h4>{tittle}</h4>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-3 form-group">
								<b>Número de RG</b>
								{lblNumDoctoCivil ||
								lblHabilitarAtualizacaoDocumentos === false ? (
									<>
										{editable ? (
											<InputMask
												disabled={
													lblHabilitarAtualizacaoDocumentos
												}
												mask="99.999.999"
												type="text"
												name="lblNumDoctoCivil"
												id="lblNumDoctoCivil"
												className="form-control"
												placeholder="Número de RG"
												value={lblNumDoctoCivil}
												onChange={event =>
													setlblNumDoctoCivil(
														event.target.value
													)
												}
											/>
										) : (
											<p>{lblNumDoctoCivil}</p>
										)}
									</>
								) : (
									<p>Usuário não tem essa informação</p>
								)}
							</div>
							<div className="col-12 col-md-6 col-lg-1 form-group d-flex align-items-center">
								{lblDigitoDoctoCivil ||
								lblHabilitarAtualizacaoDocumentos === false ? (
									<>
										{editable ? (
											<InputMask
												disabled={
													lblHabilitarAtualizacaoDocumentos
												}
												mask="99"
												type="text"
												name="lblDigitoDoctoCivil"
												id="lblDigitoDoctoCivil"
												className="form-control"
												placeholder="99"
												value={lblDigitoDoctoCivil}
												onChange={event =>
													setlblDigitoDoctoCivil(
														event.target.value
													)
												}
											/>
										) : (
											<p>{lblDigitoDoctoCivil}</p>
										)}
									</>
								) : null}
							</div>
							<div className="col-12 col-md-6 col-lg-3 form-group">
								<b>CPF do Usuário:</b>
								<div className="input-group mb-4">
									{lblCPF ||
									lblHabilitarAtualizacaoDocumentos ===
										false ? (
										<>
											{editable ? (
												<InputMask
													mask="999.999.999-99"
													disabled={
														lblHabilitarAtualizacaoDocumentos
													}
													size={16}
													type="text"
													name="lblCPF"
													id="lblCPF"
													className="form-control"
													placeholder="CPF do Usuário"
													value={lblCPF}
													onChange={event =>
														setlblCPF(
															event.target.value
														)
													}
												/>
											) : (
												<p>{lblCPF}</p>
											)}
										</>
									) : (
										<p>Usuário não tem essa informação</p>
									)}
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-5 form-group">
								<b>Orgão Expedidor</b>
								{lblUFDoctoCivil ||
								lblHabilitarAtualizacaoDocumentos === false ? (
									<>
										{editable ? (
											<SelectOrgaoExpedidor
												disabled={
													lblHabilitarAtualizacaoDocumentos
												}
												valueSelect={lblUFDoctoCivil}
												setUf={setlblUFDoctoCivil}
											/>
										) : (
											<p>{lblEstado}</p>
										)}
									</>
								) : (
									<p>Usuário não tem essa informação</p>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-3 form-group">
								<b>Data da Emissão do Documento</b>
								{lblDataEmissaoDoctoCivil ||
								lblHabilitarAtualizacaoDocumentos === false ? (
									<>
										{editable ? (
											<InputMask
												disabled={
													lblHabilitarAtualizacaoDocumentos
												}
												mask="99/99/9999"
												name="lblDataEmissaoDoctoCivil"
												id="lblDataEmissaoDoctoCivil"
												className="form-control"
												placeholder="Data da Emissão Documento"
												value={lblDataEmissaoDoctoCivil}
												onChange={event =>
													setlblDataEmissaoDoctoCivil(
														event.target.value
													)
												}
											/>
										) : (
											<p>{lblDataEmissaoDoctoCivil}</p>
										)}
									</>
								) : (
									<p>Usuário não tem essa informação</p>
								)}
							</div>
						</div>
					</div>
					{editable && userActualType !== 9 ? (
						<div className="card-footer">
							<div className="row d-flex align-items-center">
								<div className="col-12 col-md-11 col-lg-11 d-flex justify-content-end">
									<Tooltip
										title="Alterar Dados do Usuário."
										placement="top-start"
									>
										<button
											className="buttonEdit button-profile-edit"
											type="button"
											onClick={handleAtualizarDocumentos}
										>
											<FaEdit /> Editar
										</button>
									</Tooltip>
								</div>
								<div className="col-12 col-md-1 col-lg-1 d-flex justify-content-end">
									<button
										className="btn btn-primary"
										type="button"
										onClick={handleSubmit}
										disabled={
											lblHabilitarAtualizacaoDocumentos
										}
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</form>
		</>
	);
}
