import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FaEye, FaSearch } from 'react-icons/fa';
import api from '../../../services/api';
import Panel from '../../../components/Panel';
import Footer from '../../../components/Footer';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';
import parseJwt from '../../../helpers/parseJwt';
import RouteParams from '../../../routes/data/RouteParams';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COURSES,
			namePage: 'Atribuição',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [schoolTeacher, setSchoolTeacher] = useState([]);
	const [schoolIDTeacher, setSchoolIDTeacher] = useState(null);
	const [subjectsTeacher, setSubjectsTeacher] = useState([]);
	const [subjectIDTeacher, setSubjectIDTeacher] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [classTeacher, setClassTeacher] = useState([]);
	const [classIDTeacher, setClassIDTeacher] = useState(null);
	const [disabledClass, setDisabledClass] = useState(true);
	const [attributionTeacher, setAttributionTeacher] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		(async function() {
			const response = await api.get('/professor/listarescolas');
			if (!response.data.error) setSchoolTeacher(response.data.data);
			else setSchoolTeacher([]);
		})();
	}, []);

	useEffect(() => {
		(async function() {
			if (schoolIDTeacher) {
				const response = await api.get('/professor/listardisciplinas', {
					params: {
						escolaID: schoolIDTeacher,
					},
				});
				if (response.data.error) {
					setSubjectsTeacher([]);
					setClassTeacher([]);
					setDisabledSubjects(true);
					setSubjectIDTeacher(null);
					setDisabledClass(true);
				} else {
					setDisabledSubjects(false);
					setSubjectsTeacher(response.data.data);
				}
			} else {
				setSubjectsTeacher([]);
				setClassTeacher([]);
				setDisabledSubjects(true);
				setSubjectIDTeacher(null);
				setDisabledClass(true);
			}
		})();
	}, [schoolIDTeacher]);

	useEffect(() => {
		(async function() {
			if (schoolIDTeacher && subjectIDTeacher) {
				const response = await api.get('/professor/listarturmas', {
					params: {
						escolaID: schoolIDTeacher,
						disciplinaID: subjectIDTeacher,
					},
				});
				if (response.data.error) {
					setClassTeacher([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClassTeacher(response.data.data);
				}
			} else {
				setClassTeacher([]);
				setDisabledClass(true);
			}
		})();
	}, [subjectIDTeacher]);

	function searchSchool() {
		(async function() {
			const token = localStorage.getItem('@token');
			const idUser = parseJwt(token).sub;
			const dataPost = {
				idUser,
				idSchool: schoolIDTeacher,
				idSubject: subjectIDTeacher,
				idClasse: classIDTeacher,
				year: null,
			};
			const response = await api.post(
				'/atribuicao/professor/lista-atribuicao',
				dataPost
			);
			if (!response.data.error) setAttributionTeacher(response.data.data);
			else setAttributionTeacher([]);
		})();
	}

	useEffect(() => {
		setIsLoading(true);
		searchSchool();
		setIsLoading(false);
	}, []);

	function OptionsButton({ attributionId }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_03_ROUTES.ATTRIBUTION.ATTRIBUTION_ID
			] = attributionId;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_ATTRIBUTION,
				state: params,
			});
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Atribuição"
					className="buttonViewColor"
					onClick={() => viewNavigation(attributionId)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-lg-4">
							<div className="form-group">
								<label htmlFor="schoolTeacher">
									<b>Escola</b>
								</label>
								<select
									className="form-control"
									name="schoolTeacher"
									id="schoolTeacher"
									onChange={event =>
										setSchoolIDTeacher(event.target.value)
									}
								>
									<option value="">Escolha a Escola</option>
									{schoolTeacher.map(schools => (
										<option
											key={schools.id_escola}
											value={schools.id_escola}
										>
											{schools.desc_nome_escola}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-lg-4">
							<div className="form-group">
								<label htmlFor="subjectTeacher">
									<b>Disciplina</b>
								</label>
								<select
									className="form-control"
									name="subjectTeacher"
									id="subjectTeacher"
									onChange={event =>
										setSubjectIDTeacher(event.target.value)
									}
									disabled={disabledSubjects}
								>
									<option value="">
										Escolha a disciplina
									</option>
									{subjectsTeacher.map(subjects => (
										<option
											key={subjects.id_disciplina}
											value={subjects.id_disciplina}
										>
											{subjects.nome_disciplina}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-lg-4">
							<div className="form-group">
								<label htmlFor="classTeacher">
									<b>Série/Turma</b>
								</label>
								<select
									id="classTeacher"
									className="form-control"
									onChange={event =>
										setClassIDTeacher(event.target.value)
									}
									disabled={disabledClass}
								>
									<option value="">Escolha a série</option>
									{classTeacher.map(classes => (
										<option
											key={classes.id_classe}
											value={classes.id_classe}
										>
											{classes.desc_serie_ano} -{' '}
											{classes.turma}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col-lg-6 col-md-10 col-12 form-group">
							<button
								type="button"
								className="btn btn-block btn-success"
								onClick={searchSchool}
							>
								<FaSearch className="searchIcon" />
								Buscar
							</button>
						</div>
					</div>

					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							{attributionTeacher.length ? (
								<Table
									header={[
										'Disciplina',
										'Escola',
										'Série/Ano',
										'',
									]}
									body={attributionTeacher.map(
										attribution => {
											return [
												attribution.nome_disciplina,
												attribution.desc_nome_escola,
												`${attribution.desc_serie_ano} ${attribution.desc_tipo_ensino} - ${attribution.turma}`,
												<OptionsButton
													attributionId={
														attribution.id_atribuicao
													}
												/>,
											];
										}
									)}
								/>
							) : (
								<div>
									<p>
										<b>Não tem Atribuções.</b>
									</p>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}
