import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import MapSchools from '../../components/Map/Georreferencia/Schools/MapSchools';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';

export default function ListSchool() {
	const userActualType = useSelector(state => state.userType.actualType);
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista de Escolas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblSchool, setlblSchool] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const history = useHistory();

	async function loadSchool() {
		const { data } = await api.get('/escola/rede-ensino/lista-escolas');
		if (data.error) {
			setlblSchool([]);
			swal({ icon: 'warning', text: data.message, title: 'Ops!' });
		} else setlblSchool(data.data);
		setIsLoading(false);
	}

	useEffect(() => {
		loadSchool();
	}, []);

	function OptionsButton({ CodEscola }) {
		function viewNavigation() {
			const state = {};
			state[
				RouteParams.LEVEL_06_ROUTES.SCHOOL_INFO.SCHOOL_CODE
			] = CodEscola;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.SCHOOL_INFO,
				state
			);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(CodEscola)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<div className="card">
						<div className="card-body">
							<Table
								header={['Cod. Escola', 'Escola', 'Rede', '']}
								body={lblSchool.map(school => {
									return [
										school.cod_escola,
										school.desc_nome_escola,
										school.nome_rede_ensino,
										<OptionsButton
											CodEscola={school.cod_escola}
										/>,
									];
								})}
							/>
						</div>
					</div>
					<MapSchools />
				</>
			)}
		</>
	);
}
