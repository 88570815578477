/* eslint-disable radix */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import './style.css';

const Pagination = ({ postsPerPage, totalPosts, paginate, page }) => {
	const pageNumbers = [];
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(5);
	for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++)
		pageNumbers.push(i);

	useEffect(() => {
		(async function() {
			setStartIndex(0);
			setEndIndex(5);
			paginate(page);
		})();
	}, [postsPerPage]);

	async function handlePagination(e) {
		paginate(e);
	}
	async function next() {
		paginate(page + 1);
		if (page + 1 > endIndex) {
			setStartIndex(startIndex + 5);
			setEndIndex(endIndex + 5);
		}
	}
	async function nextPage() {
		if (parseInt(page) + 5 >= pageNumbers[pageNumbers.length - 1])
			paginate(pageNumbers[pageNumbers.length - 1]);
		else paginate(parseInt(page) + 5);
		setStartIndex(startIndex + 5);
		setEndIndex(endIndex + 5);
	}
	async function previous() {
		paginate(parseInt(page) - 1);
		if (parseInt(page) - 1 <= startIndex) {
			setStartIndex(startIndex - 5);
			setEndIndex(endIndex - 5);
		}
	}
	async function previousPage() {
		paginate(parseInt(page) - 5);
		setStartIndex(startIndex - 5);
		setEndIndex(endIndex - 5);
	}
	const listPage = pageNumbers.map(number => (
		<li key={number} className="page-item ">
			<a onClick={() => paginate(number)} href="#" className="page-link">
				{number}
			</a>
		</li>
	));
	const activeListPage = listPage.slice(startIndex, endIndex).map(list => {
		return page === list.key ? (
			<li key={list.key} className="page-item active ">
				<a
					onClick={() => handlePagination(list.key)}
					href="#"
					className="page-link"
				>
					{list.key}
				</a>
			</li>
		) : (
			<li key={list.key} className="page-item ">
				<a
					onClick={() => handlePagination(list.key)}
					href="#"
					className="page-link"
				>
					{list.key}
				</a>
			</li>
		);
	});
	return (
		<>
			<div className="table-responsive">
				{pageNumbers.length === 1 && (
					<nav aria-label="Page navigation">
						<ul className="pagination pagination-sm ">
							<li className="page-item disabled">
								<a
									className="page-link"
									href="#"
									aria-label="Previous"
								>
									<span aria-hidden="true">&laquo;</span>
									<span className="sr-only">Previous</span>
								</a>
							</li>
							{activeListPage}
							<li className="page-item disabled">
								<a
									className="page-link"
									href="#"
									aria-label="Next"
								>
									<span aria-hidden="true">&raquo;</span>
									<span className="sr-only">Next</span>
								</a>
							</li>
						</ul>
					</nav>
				)}
				{pageNumbers.length > 1 && (
					<nav aria-label="Page navigation">
						<ul className="pagination pagination-sm ">
							{startIndex < 5 ? (
								<li className="page-item disabled">
									<a
										className="page-link"
										href="#"
										aria-label="Previous"
										onClick={() => previousPage()}
									>
										<span aria-hidden="true">&laquo;</span>
										<span className="sr-only">
											Previous
										</span>
									</a>
								</li>
							) : (
								<li className="page-item">
									<a
										className="page-link"
										href="#"
										aria-label="Previous"
										onClick={() => previousPage()}
									>
										<span aria-hidden="true">&laquo;</span>
										<span className="sr-only">
											Previous
										</span>
									</a>
								</li>
							)}
							{pageNumbers[0] === page ? (
								<li className="page-item disabled">
									<a
										className="page-link"
										href="#"
										onClick={() => previous()}
									>
										Anterior
									</a>
								</li>
							) : (
								<li className="page-item">
									<a
										className="page-link"
										href="#"
										onClick={() => previous()}
									>
										Anterior
									</a>
								</li>
							)}
							{activeListPage}
							{pageNumbers[pageNumbers.length - 1] === page ? (
								<li className="page-item disabled">
									<a
										className="page-link"
										href="#"
										onClick={() => next()}
									>
										Próxima
									</a>
								</li>
							) : (
								<li className="page-item">
									<a
										className="page-link"
										href="#"
										onClick={() => next()}
									>
										Próxima
									</a>
								</li>
							)}
							{pageNumbers[pageNumbers.length - 1] < endIndex ? (
								<li className="page-item disabled">
									<a
										className="page-link"
										href="#"
										aria-label="Next"
										onClick={() => nextPage()}
									>
										<span aria-hidden="true">&raquo;</span>
										<span className="sr-only">Next</span>
									</a>
								</li>
							) : (
								<li className="page-item">
									<a
										className="page-link"
										href="#"
										aria-label="Next"
										onClick={() => nextPage()}
									>
										<span aria-hidden="true">&raquo;</span>
										<span className="sr-only">Next</span>
									</a>
								</li>
							)}
						</ul>
					</nav>
				)}
			</div>
		</>
	);
};

export default Pagination;
