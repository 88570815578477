import React from 'react';
import Notbook from '../../assets/img/notebook.png'
import './styleStudents.css';

export default function index() {
	return (
		<section>
			<div className="page-home">
				<div className="center">
					<img src={Notbook} alt="notebook com o simbolo da SEMEC"/>
				</div>
				<p className="text-blue-home text-center">
					<b>Seja bem-vindo a plataforma da Semec!</b>
					<br/>
					Sua gerenciadora de escolas online
				</p>
			</div>
		</section>
	);
}
