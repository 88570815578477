import React, { useState } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';
import SelectSerieAno from './SelectSerieAno';
import MediaClasse from './MediaClasses';

export default function InfoClass( ) {
	const [idSerieAno, setIdSerieAno] = useState(26);
	const [classesGraphic, setClassesGraphic] = useState([]);
	const [yearStart, setYearStart] = useState(null);
	const [yearEnd, setYearEnd] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	async function loadClasse() {
		setIsLoading(true);
		const dataPost = {
			yearStart,
			yearEnd,
			idSerieAno,
		};
		const { data } = await api.post(
			'/classes/media-classe-serie',
			dataPost
		);
		if (data.error === false) setClassesGraphic(data.data);
		else setClassesGraphic([]);
		setIsLoading(false);
	}

	async function handleSearchClass(e) {
		e.preventDefault();
		if (yearStart <= yearEnd) loadClasse();
		else
			swal({
				icon: 'warning',
				text: 'Ano final não pode ser maior que ano inicial',
			});
	}

	const selectYears = [];

	for (let i = 2018; i <= new Date().getFullYear(); i += 1)
		selectYears.push(<option value={i}>{i}</option>);

	return (
		<>
			<div className="card mt-3 w-100">
				<div className="card-header form-group">
					<h5 className="form-group">
						<b>Desempenho das Classes:</b>
					</h5>
				</div>
				<div className="card-body">
					<form onSubmit={handleSearchClass}>
						<div className="row form-group">
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<b>Ano inicio</b>
								<select
									required
									className="form-control"
									id="lblSelectAnoList"
									value={yearStart}
									onChange={event =>
										setYearStart(event.target.value)
									}
								>
									<option value="">Escolha um Ano</option>
									{selectYears}
								</select>
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<b>Ano final</b>
								<select
									required
									className="form-control"
									id="lblSelectAnoList"
									value={yearEnd}
									onChange={event =>
										setYearEnd(event.target.value)
									}
								>
									<option value="">Escolha um Ano</option>
									{selectYears}
								</select>
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<b>Série</b>
								<SelectSerieAno
									required
									value={idSerieAno}
									setIdSerieAno={setIdSerieAno}
								/>
							</div>
						</div>
						<div className="row justify-content-between">
							<div className="col-lg-4 col-md-5 col-7 form-group">
								<button
									className="btn btn-primary btn-block"
									type="submit"
								>
									Buscar
								</button>
							</div>
							<div className="col-lg-4 col-md-5 col-7 form-group">
								<button
									className="btn btn-warning btn-block"
									type="button"
									onClick={() => setClassesGraphic([])}
								>
									Limpar
								</button>
							</div>
						</div>
					</form>
					<hr />
					{isLoading === true ? (
						<div className="row pt-md-5 mt-md-3 mb-5">
							<div className="lds-dual-ring" />
						</div>
					) : (
						<div>
							{classesGraphic.length ? (
								<MediaClasse count={classesGraphic} />
							) : null}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
