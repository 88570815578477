import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../../../services/api';
import Breadcrumb from '../../../../components/Breadcrumb';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';

export default function FormStudents() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.STUDENTS,
			namePage: 'Lista Alunos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PROFILE_STUDENT,
			namePage: 'Informação do aluno',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Transferência do aluno',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblNumeroRA, setlblNumeroRA] = useState('');
	const [lblDigitoRA, setlblDigitoRA] = useState('');
	const [lblUfRA, setlblUfRA] = useState('');
	const [lblAnoLetivo, setlblAnoLetivo] = useState('');
	const [lblCodEscola, setlblCodEscola] = useState('');
	const [lblFase, setlblFase] = useState('');
	const [lblInteresseIntegral, setlblInteresseIntegral] = useState('');
	const [lblInteresseEspanhol, setlblInteresseEspanhol] = useState('');
	const [lblNecesAtendNoturno, setlblNecesAtendNoturno] = useState('');
	const [lblMotivo, setlblMotivo] = useState('');
	const [lblIdSerieAno, selblIdSerieAno] = useState('');
	const [lblNumClass, setlblNumClass] = useState('');
	const [lblNomeAluno, setNomeAluno] = useState('');
	const [lblLogin, setlblLogin] = useState('');
	const [lblSenha, setlblSenha] = useState('');

	useEffect(() => {
		if (localStorage.getItem('@dataStudents')) {
			const data = JSON.parse(
				atob(localStorage.getItem('@dataStudents'))
			);
			// eslint-disable-next-line func-names
			(async function() {
				setlblNumeroRA(data.NumRa);
				setlblDigitoRA(data.DigitoRa);
				setlblUfRA(data.UfRa);
				setlblAnoLetivo(data.AnoLetivo);
				setlblCodEscola(data.CodEscola);
				selblIdSerieAno(data.SerieAno);
				setlblNumClass(data.NumClass);
				setNomeAluno(data.NomeUser);
			})();
		} else
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
	}, []);

	async function handleCancel(event) {
		event.preventDefault();
		localStorage.removeItem('@dataStudents');
		localStorage.removeItem('@idClasse');
		history.goBack();
	}
	async function handleSubmit(event) {
		try {
			event.preventDefault();
			const data = new FormData();
			data.append('lblNumeroRA', lblNumeroRA);
			data.append('lblDigitoRA', lblDigitoRA);
			data.append('lblUfRA', lblUfRA);
			data.append('lblAnoLetivo', lblAnoLetivo);
			data.append('lblCodEscola', lblCodEscola);
			data.append('lblFase', lblFase);
			data.append('lblInteresseIntegral', lblInteresseIntegral);
			data.append('lblInteresseEspanhol', lblInteresseEspanhol);
			data.append('lblNecesAtendNoturno', lblNecesAtendNoturno);
			data.append('lblMotivo', lblMotivo);
			data.append('lblIdSerieAno', lblIdSerieAno);
			data.append('lblNumClass', lblNumClass);
			data.append('lblLogin', lblLogin);
			data.append('lblSenha', lblSenha);
			await api
				.post('/aluno/perfil/atualiza/transferencia/escola', data)
				.then(response => {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					localStorage.removeItem('@dataStudents');
					localStorage.removeItem('@idClasse');
					localStorage.removeItem('@idStudents');
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.STUDENTS
					);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			<div className="card">
				<div className="card-header">
					<b>Formulário de Transferência do Aluno</b>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>Tipo de Transferência:</p>
						</div>
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<select
								required
								className="form-control"
								name="lblFase"
								id="lblFase"
								value={lblFase}
								onChange={event =>
									setlblFase(event.target.value)
								}
							>
								<option> Escolha uma Opção</option>
								<option value="0">
									Inscrição de Alunos por Transferência
								</option>
								<option value="8">
									Inscrição de Alunos por Deslocamento
								</option>
								<option value="9">
									Inscrição por Intenção de Transferência
								</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-3 col-lg-3">
							<p>Interesse Integral: </p>
						</div>
						<div className="col-12 col-md-3 col-lg-3">
							<select
								className="form-control"
								name="lblInteresseIntegral"
								id="lblInteresseIntegral"
								value={lblInteresseIntegral}
								onChange={event =>
									setlblInteresseIntegral(event.target.value)
								}
							>
								<option> Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
						<div className="col-12 col-md-3 col-lg-3">
							<p>Interesse Espanhol: </p>
						</div>
						<div className="col-12 col-md-3 col-lg-3">
							<select
								className="form-control"
								name="lblInteresseEspanhol"
								id="lblInteresseEspanhol"
								value={lblInteresseEspanhol}
								onChange={event =>
									setlblInteresseEspanhol(event.target.value)
								}
							>
								<option> Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<p>Necessidade de Matrícula Noturna: </p>
						</div>
						<div className="col-12 col-md-6 col-lg-6">
							<select
								className="form-control"
								name="lblNecesAtendNoturno"
								id="lblNecesAtendNoturno"
								value={lblNecesAtendNoturno}
								onChange={event =>
									setlblNecesAtendNoturno(event.target.value)
								}
							>
								<option> Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<p>Motivo Transferência: </p>
						</div>
						<div className="col-12 col-md-6 col-lg-6">
							<select
								required
								className="form-control"
								name="lblMotivo"
								id="lblMotivo"
								value={lblMotivo}
								onChange={event =>
									setlblMotivo(event.target.value)
								}
							>
								<option value="1">Mudança de Residência</option>
								<option value="2">
									Proximidade local trabalho pais e/ou horário
									trabalho aluno
								</option>
								<option value="3">
									Endereço dos familiares
								</option>
								<option value="4">Interesse do aluno</option>
							</select>
						</div>
					</div>
				</div>
				<div className="card-footer">
					<div className="row">
						<div className="col-12 col-md-10 col-lg-10">
							<button
								type="submit"
								className="btn btn-danger"
								onClick={handleCancel}
							>
								Cancelar
							</button>
						</div>
						<div className="col-12 col-md-2 col-lg-2">
							<button
								type="submit"
								className="btn btn-primary"
								data-toggle="modal"
								data-target="#modalConfirmar"
							>
								Confirmar
							</button>
						</div>
					</div>
				</div>
				{/* <!-- Modal --> */}
				<div
					className="modal fade"
					id="modalConfirmar"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="exampleModalLabel"
								>
									Transferencia do Aluno
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Fechar"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>
									Você está para transferir Aluno:{' '}
									<b>
										{lblNomeAluno.charAt(0) +
											lblNomeAluno
												.slice(1)
												.toLowerCase()}{' '}
									</b>
									, da Escola, isso siginifica que aluno
									deixará de ser matriculado nesta escola.
								</p>
								<p>
									Caso tenha certeza desta ação, Por Favor
									confirme o usuário.
								</p>
								<div className="row justify-content-center">
									<div className="form-group col-10">
										<label htmlFor="loginEmail">
											E-mail
										</label>
										<input
											type="text"
											name="loginEmail"
											id="loginEmail"
											placeholder="Digite seu e-mail"
											className="form-control"
											onChange={event =>
												setlblLogin(event.target.value)
											}
											required
										/>
									</div>
								</div>
								<div className="row justify-content-center">
									<div className="form-group col-10">
										<label htmlFor="passwordLogin">
											Senha
										</label>
										<input
											type="password"
											name="passwordLogin"
											id="passwordLogin"
											placeholder="Digite a sua senha"
											className="form-control"
											onChange={event =>
												setlblSenha(event.target.value)
											}
											required
										/>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal"
								>
									Fechar
								</button>
								<button
									type="submit"
									className="btn btn-warning"
									onClick={handleSubmit}
									data-dismiss="modal"
								>
									Transferir
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
