// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_updateButton__gT34V {\r\n\tbackground-color: transparent;\r\n\tcolor: #1c3d64;\r\n\tborder: none;\r\n\tcursor: pointer;\r\n\tdisplay: inline-flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tpadding: 0.5rem;\r\n\tmargin-left: 0.5rem;\r\n\tborder-radius: 0.25rem;\r\n\tfont-size: 1.5rem;\r\n\ttransition: 0.3s ease-in-out;\r\n}\r\n\r\n.styles_updateButton__gT34V:hover {\r\n\tbackground-color:#0069d9;\r\n\tcolor: #fff;\r\n}\r\n", ""]);
// Exports
exports.locals = {
	"updateButton": "styles_updateButton__gT34V"
};
module.exports = exports;
