import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb';
import Footer from '../../../components/Footer';
import SelectClasses from '../../../components/Selects/SelectClasses';
import SelectDisciplinas from '../../../components/Selects/SelectDisciplinas';
import SelectEscola from '../../../components/Selects/SelectEscola';
import SelectSerieAno from '../../../components/Selects/SelectSerieAno';
import Table from '../../../components/Table';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import api from '../../../services/api';
import Panel from '../../../components/Panel';

export default function Index() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);
	const [isLoading, setIsLoading] = useState(true);
	const [idClasse, setIdClasse] = useState(null);
	const [idSerieAno, setSerieAno] = useState(null);
	const [idSubject, setIdSubject] = useState(null);
	const [idSchool, setIdSchool] = useState(null);
	const [listAttribution, setListAttribution] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Arquivos das Aulas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleSearch() {
		const dataPost = {
			classe: idClasse,
			idSerieAno,
			idDisciplina: idSubject,
			escolaID: idSchool,
		};
		const { data } = await api.post(
			'/atribuicao/lista-atribuicao',
			dataPost
		);
		if (data.error === false) setListAttribution(data.data.atribuicao);
		else {
			swal({ title: 'Ops!!', icon: 'warning', text: data.message });
			setListAttribution([]);
		}
	}

	async function loadAttribution() {
		setIsLoading(true);
		const { data } = await api.post('/atribuicao/lista-atribuicao');
		if (data.error === false) setListAttribution(data.data.atribuicao);
		else {
			swal({ title: 'Ops!!', icon: 'warning', text: data.message });
			setListAttribution([]);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadAttribution();
	}, []);

	function OptionsButton({ idAttribution }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_06_ROUTES.ATTRIBUTION.ATTRIBUTION_ID
			] = idAttribution;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_ATTRIBUTION,
				state: params,
			});
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(idAttribution)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Panel />
			<main>
				<div className="container-fluid">
					<div className="row painel justify-content-center">
						<div className="col-xl-11 col-lg-11 col-md-12">
							<Breadcrumb breadCrumbItems={breadCrumbItems} />
							<div className="card mt-3">
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4">
											<div className="form-group">
												<SelectEscola
													required={false}
													setIdSchool={setIdSchool}
													value={idSchool}
													disabled={false}
													label="Escolas"
												/>
											</div>
										</div>
										<div className="col-12 col-md-4 col-lg-4">
											<div className="form-group">
												<label htmlFor="subjectsSchool">
													<b>Série/Ano</b>
												</label>
												<SelectSerieAno
													setIdSerieAno={setSerieAno}
													valueSelect={idSerieAno}
													idShcool={idSchool}
												/>
											</div>
										</div>
										<div className="col-12 col-md-4 col-lg-4">
											<div className="form-group">
												<label htmlFor="subjectsSchool">
													<b>Classe</b>
												</label>
												<SelectClasses
													setClasse={setIdClasse}
													valueSelect={idClasse}
													schoolId={idSchool}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4">
											<div className="form-group">
												<label htmlFor="subjectsSchool">
													<b>Disciplinas</b>
												</label>
												<SelectDisciplinas
													required={false}
													setDisciplina={setIdSubject}
													valueSelect={idSubject}
													disabled={false}
												/>
											</div>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<button
											onClick={handleSearch}
											className="btn btn-primary"
											type="button"
											id="button-addon2"
										>
											Filtrar
										</button>
										<Link
											to={
												Routes.LOGGED_ROUTES(
													RouteByPermission[
														userActualType
													]
												).ADD_FILES_ATTRIBUTION
											}
										>
											<button
												className="btn btn-primary"
												type="button"
												id="button-addon2"
											>
												Novo Arquivo
											</button>
										</Link>
									</div>
									<hr />
									{isLoading === true ? (
										<div className="lds-dual-ring" />
									) : (
										<div className="text-size-table">
											<Table
												header={[
													'Escola',
													'Classe',
													'Disciplina',
													'Professor(a)',
													'Professor(a) substituto',
													'',
												]}
												body={listAttribution.map(
													attribution => {
														return [
															attribution.desc_nome_escola,
															`${attribution.desc_serie_ano} - ${attribution.desc_tipo_ensino} - ${attribution.turma}`,
															attribution.nome_disciplina,
															attribution.nome_usuario
																? attribution.nome_usuario
																: 'Não há informação',
															attribution.nome_professor_substituto
																? attribution.nome_professor_substituto
																: 'Não há informação',
															<OptionsButton
																idAttribution={
																	attribution.id_atribuicao
																}
															/>,
														];
													}
												)}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}
