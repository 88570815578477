import React from 'react';

export default function SelectComponent({
	setValue,
	valueSelcted,
	array = [],
	disabled = false,
	required = false,
	label = 'Selecionar',
	fistOption = 'Todas',
	theSameLine = false,
	optionAll = true,
}) {
	async function handleSubmit(value) {
		setValue(value);
	}
	const list = array.map(U => (
		<option value={U.id} key={U.id}>
			{U.name}
		</option>
	));
	return theSameLine ? (
		<div className={`selects ${theSameLine ? 'row' : ''}`}>
			<div className="col-md-4 col-lg-4 col-12">
				<label htmlFor="school">{label}</label>
			</div>
			<div className="col-md-8 col-lg-8 col-12">
				<select
					required={required}
					className="form-control"
					id="idSchool"
					value={valueSelcted}
					disabled={disabled}
					onChange={event => handleSubmit(event.target.value)}
				>
					{optionAll && <option value="">{fistOption}</option>}
					{list}
				</select>
			</div>
		</div>
	) : (
		<div className="selects">
			<div>
				<label htmlFor="school">{label}</label>
			</div>
			<div>
				<select
					required={required}
					className="form-control"
					id="idSchool"
					value={valueSelcted}
					disabled={disabled}
					onChange={event => handleSubmit(event.target.value)}
				>
					{optionAll && <option value="">{fistOption}</option>}
					{list}
				</select>
			</div>
		</div>
	);
}
