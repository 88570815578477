/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Panel from '../../../components/Panel';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import Footer from '../../../components/Footer';
import SelectSchool from './SelectSchool';
import Breadcrumb from './Breadcrumb';
import AddOccurrence from './AddOccurrence';
import api from '../../../services/api';
import formatDate from '../../../helpers/formatDate';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';

export default function Ocurrences() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const styles = {
		adicionatOcorrenciaButton: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
			paddingBottom: '15px',
		},
	};
	const [schoolId, setSchoolId] = useState(null);
	const [occurrences, setOccurrences] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			try {
				const response = await api.get(
					`/ocorrencia/selecionar-por-escola?escola_id=${schoolId}`
				);
				if (!response.data.error) {
					const data = response.data.data.map(item => {
						return {
							id: item.id_ocorrencia,
							assunto: item.assunto,
							data: formatDate(item.data),
							relator: item.usuario,
						};
					});
					setOccurrences(data);
				} else setOccurrences([]);
			} catch (error) {
				console.log(`error: ${error}`);
			}
			setIsLoading(false);
		})();
	}, [schoolId]);

	async function loadOccurrences() {
		setIsLoading(true);
		try {
			const response = await api.get(
				`/ocorrencia/selecionar-por-escola?escola_id=${schoolId}`
			);
			if (!response.data.error) {
				const data = response.data.data.map(item => {
					return {
						id: item.id_ocorrencia,
						assunto: item.assunto,
						data: formatDate(item.data),
						relator: item.usuario,
					};
				});
				setOccurrences(data);
			} else setOccurrences([]);
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	function OptionsButton({ occurrence }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_05_ROUTES.MESSAGES.OCCURRENCE
			] = occurrence;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.MESSAGES,
				params
			);
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(occurrence)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb />
			<div className="row">
				<div className="col-lg-8 col-md-8 col-12 form-group">
					<SelectSchool
						schoolId={schoolId}
						setSchoolId={setSchoolId}
					/>
				</div>
				<div
					className="col-lg-4 col-md-4 col-12"
					style={styles.adicionatOcorrenciaButton}
				>
					{schoolId && (
						<AddOccurrence
							schoolId={schoolId}
							loadOccurrences={loadOccurrences}
						/>
					)}
				</div>
			</div>

			{schoolId &&
				(isLoading ? (
					<div className="lds-dual-ring" />
				) : occurrences.length === 0 ? (
					<div className="row painel justify-content-center">
						<div className="col-xl-11 col-lg-11 col-md-12">
							<div className="card">
								<div className="card-body">
									<p>Não há ocorrências nessa escola.</p>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="row painel justify-content-center">
						<div className="col-xl-11 col-lg-11 col-md-12">
							<div className="card">
								<div className="card-body">
									<Table
										header={[
											'#',
											'Assunto',
											'Data',
											'Relator',
											'',
										]}
										body={occurrences.map(occurrence => {
											return [
												occurrence.id,
												occurrence.assunto,
												occurrence.data,
												occurrence.relator,
												<OptionsButton
													occurrence={occurrence}
												/>,
											];
										})}
									/>
								</div>
							</div>
						</div>
					</div>
				))}
		</>
	);
}
