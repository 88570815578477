import React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function ModalDelete({
	schoolInfo,
	setSchoolInfo,
	teachingType
}) {
	async function removeType(id){
		try {
			const { data } = await api.delete(`/escola/tipo-ensino/${id}`);
			if (!('data' in data)) throw data;
			if (data.data.error) throw data.data.message;
			setSchoolInfo(data.data);
			swal('SUCESSO!', data.message, 'success');
		} catch (error) {
			swal('Ops!', error, 'warning');
		}
	}

	async function addType(){
		const select = document.getElementById('teachingType');
		const selected = [...select.options]
			.filter(option => option.selected)
			.map(option => option.value);

		try {
			const { data } = await api.post('/escola/tipo-ensino', {
				id_escola: schoolInfo.id_escola,
				id_series: selected
			});

			if (!('data' in data)) throw data;
			if (data.data.error) throw data.data.message;
			setSchoolInfo(data.data);
			swal('SUCESSO!', data.message, 'success');
		} catch (error) {
			swal('Ops!', error, 'warning');
		}
	}
	return (
		<div
			className="modal fade"
			id="modalEditType"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<form>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Editar Tipo de Ensino
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Fechar"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12 form-group">
									<p>
										<small>
											<b>Ensinos atuais:</b>
										</small>
									</p>
								</div>
							</div>
							{schoolInfo.tipo_de_ensino && (
								<div className="row">
									<div className="col-12 col-md-12 col-lg-12 form-group">
										<p>
											<small>
												<b>Remover:</b>
												<div className="container">
													{schoolInfo.tipo_de_ensino.map(
														item => (
															<>
																<div
																	className="row"
																	key={
																		item.id_escola_has_serie
																	}
																>
																	<div className="col-6 col-md-6 col-lg-6 d-flex align-items-end">
																		{
																			item.desc_serie_ano
																		}
																	</div>
																	<div className="col-6 col-md-6 col-lg-6">
																		<button
																			className="btn btn-danger"
																			type="button"
																			onClick={() =>
																				removeType(
																					item.id_escola_has_serie
																				)
																			}
																		>
																			<FaMinus fill="#ffffff" />
																		</button>
																	</div>
																</div>
																<hr />
															</>
														)
													)}
												</div>
											</small>
										</p>
									</div>
								</div>
							)}
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<p>
										<small>
											<b>Adicionar:</b>
										</small>
									</p>
								</div>
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<select
										id="teachingType"
										className="form-select"
										style={{
											width: '100%'
										}}
										multiple
									>
										{teachingType.map(U => (
											<option value={U.id} key={U.id}>
												{U.name} -{' '}
												{U.name_type_teaching}
											</option>
										))}
									</select>
								</div>
								<div className="col-6 col-md-6 col-lg-6 form-group">
									<button
										className="btn btn-danger"
										type="button"
										onClick={() => addType()}
									>
										<FaPlus fill="#ffffff" />
									</button>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
