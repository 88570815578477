/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router';
import { FaInfoCircle } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../services/api';
import ChartStudents from './ChartStudents';
import Breadcrumb from '../../../../components/Breadcrumb';
import RouteParams from '../../../../routes/data/RouteParams';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';

export default function() {
	const { state } = useLocation();
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_SERIES,
			namePage: 'Lista Séries',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação da série',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [classes, setClasses] = useState([]);
	const [count, setCount] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// eslint-disable-next-line func-names
		if (state)
			(async function() {
				const message = await api.get(
					`/series/lista/info/${
						state[RouteParams.LEVEL_05_ROUTES.INFO_SERIES.SERIES_ID]
					}`
				);
				if (message.data.error === false) {
					setIsLoading(false);
					setClasses(message.data.data.classe);
					setCount(message.data.data.count);
				} else swal('ERRO!', `${message.data.message}`, 'error');
			})();
		else {
			swal('ATENÇÃO!', 'Escolha uma Série', 'warning');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_SERIES
			);
		}
	}, []);
	async function handleRedirect(e) {
		const params = {};
		params[RouteParams.LEVEL_05_ROUTES.INFO_CLASS.CLASS_NUMBER] = e;
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).INFO_CLASS,
			params
		);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div>
					<div className="card">
						<div className="card-header">
							<h5>
								<b>Sobre a Série</b>
							</h5>
						</div>
						<div className="card-body">
							<div className="mt-2 col-12 col-md-12 col-lg-12 form-group">
								<p>Total de Aluno por Série:</p>
							</div>
							<div className="row d-flex justify-content-center text-center">
								<div className="col-xl-10 col-lg-9 col-sm-8 p-2">
									<ChartStudents classe={count} />
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="row">
						{classes.map(classe => (
							<div
								className="col-12 col-lg-6"
								key={classe.id_classe}
							>
								<div className="card">
									<div className="card-header">
										<div className="row">
											<div className="col-12 col-md-10 col-lg-10 form-group">
												<b>
													Classe:{' '}
													{classe.turma.toUpperCase()}
												</b>
											</div>
											<div className="col-12 col-md-2 col-lg-2 form-group">
												<Tooltip
													title="Mais Informação"
													placement="top-start"
												>
													<button
														className="buttonEdit"
														type="button"
													>
														<FaInfoCircle
															onClick={() =>
																handleRedirect(
																	classe.num_classe
																)
															}
														/>
													</button>
												</Tooltip>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													Turno: {classe.desc_turno}
												</p>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>Sala: {classe.num_sala}</p>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													Horário Inicial:{' '}
													{classe.horario_inicio}
												</p>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													Horário Final:{' '}
													{classe.horario_fim}
												</p>
											</div>
										</div>
									</div>
								</div>
								<br />
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
}
