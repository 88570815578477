import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import ScrollToTop from '../../components/buttom/ScrollToTop';

export default function Attendance() {
	const [content, setContent] = useState([]);
	const [subject, setSubject] = useState([]);
	const [subjectID, setIDSubject] = useState();
	const [btnBlock, setBtnBlock] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		(async function() {
			const response = await api.get('/aluno/disciplinas');
			if (!response.data.error) setSubject(response.data.data);
			else setSubject([]);
		})();
	}, []);
	useEffect(() => {
		if (subjectID) setBtnBlock(false);
	}, [subjectID]);

	async function searchContent(event) {
		event.preventDefault();
		(async function() {
			const response = await api.get('/aluno/conteudos', {
				params: {
					disciplinaID: subjectID,
				},
			});
			setContent(response.data.data);
		})();
	}

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ATTENDANCE,
			namePage: 'Aulas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="row justify-content-center">
				<div className="col-lg-6 col-12 form-group">
					<select
						required
						className="form-control"
						id="selectSubject"
						onChange={event => setIDSubject(event.target.value)}
					>
						<option value="">Escolha a disciplina</option>
						{subject.map(subjects => (
							<option
								key={subjects.id_disciplina}
								value={subjects.id_disciplina}
							>
								{subjects.nome_disciplina}
							</option>
						))}
					</select>
				</div>
				<div className="col-lg-6 col-12 form-group">
					<button
						type="submit"
						className="btn btn-primary btn-block"
						onClick={searchContent}
						disabled={btnBlock}
					>
						Buscar
					</button>
				</div>
			</div>

			<div className="row justify-content-center">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row justify-content-center">
								<div className="col-lg-1 col-md-1 col-2">
									<div className="legend" />
								</div>
								<div className="col-lg-2 col-md-2 col-5">
									<p>Faltou</p>
								</div>
							</div>

							<div className="table-responsive">
								<table className="table table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="contentSubject"
											>
												Conteúdo ministrado
											</th>
											<th
												scope="col"
												className="contentSubject"
											>
												Data
											</th>
										</tr>
									</thead>
									<tbody>
										{content ? (
											content.map(contentAttendance => (
												<tr
													key={contentAttendance.id}
													className={
														contentAttendance.falta
															? 'table-danger'
															: 'table-light'
													}
												>
													<td className="contentSubject">
														{
															contentAttendance.conteudo_aula
														}
													</td>
													<td className="contentSubject">
														{
															contentAttendance.dia_letivo
														}
													</td>
												</tr>
											))
										) : (
											<p>Não tem Conteúdos</p>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
