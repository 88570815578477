import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import '../style.css';

export default function InfoSchool() {
	const location = useLocation();
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COORDINATOR,
			namePage: 'Lista de Coordenadores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação Coordenador',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [inputFields, setInputFields] = useState([{ Classe: '' }]);
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState([]);
	const [classes, setClasses] = useState([]);
	const [classesCoordinator, setClassesCoordinator] = useState([]);
	const [contato, setContato] = useState([]);

	async function listClasses() {
		const response = await api.get('/classes/lista', {
			params: {
				coordinator: true,
			},
		});
		if (!response.data.error) setClasses(response.data.data);
	}

	useEffect(() => {
		(async function() {
			if (location.state === undefined) history.goBack();
			else {
				setUser(
					location.state[
						RouteParams.LEVEL_05_ROUTES.INFO_COORDINATOR.INFO_USER
					]
				);
				setClassesCoordinator(
					location.state[
						RouteParams.LEVEL_05_ROUTES.INFO_COORDINATOR
							.LIST_CLASSES
					]
				);
				setContato(
					location.state[
						RouteParams.LEVEL_05_ROUTES.INFO_COORDINATOR
							.LIST_CONTATOS
					]
				);
			}
			listClasses();
			setIsLoading(false);
		})();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ Classe: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'Classe')
			values[index].Classe = event.target.value;
		setInputFields(values);
	};
	async function removerClasses(e) {
		const updateClasse = classesCoordinator.filter(
			classe => classe.id_classe !== e.id_classe
		);
		setClassesCoordinator(updateClasse);
		try {
			await api
				.post('/coordenador/remover-classes', {
					userId: user.id_usuario,
					classeId: [e.id_classe],
				})
				.then(response => {
					swal('SUCESSO!', `${response.data.message}`, 'success');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		listClasses();
	}
	async function handleSubmit(e) {
		e.preventDefault();
		try {
			await api
				.post('/coordenador/registro-coordenador', {
					userId: user.id_usuario,
					classeId: inputFields,
				})
				.then(response => {
					if (response.data.data)
						setClassesCoordinator(response.data.data);

					swal('SUCESSO!', `${response.data.message}`, 'success');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		listClasses();
	}

	async function handleDeleteCoordinator(e) {
		e.preventDefault();
		try {
			await api
				.post('/coordenador/remover-coordenador', {
					userId: user.id_usuario,
					classeId: inputFields,
				})
				.then(response => {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.HOME
					);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		listClasses();
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<Breadcrumb breadCrumbItems={breadCrumbItems} />
						{isLoading === true && (
							<div className="lds-dual-ring" />
						)}
						{isLoading === false && (
							<div>
								<div className="card mt-3">
									<div className="card-header">
										<div className="form-group">
											<h5>
												<p className="textoCapitalize">
													{user.nome_usuario.charAt(
														0
													) +
														user.nome_usuario
															.slice(1)
															.toLowerCase()}
												</p>
											</h5>
										</div>
										<div className="submit-button form-group">
											<button
												className="btn btn-danger mr-2"
												type="submit"
												onClick={
													handleDeleteCoordinator
												}
											>
												Desativar Coordenador
											</button>
										</div>
									</div>
									<div className="card-body">
										<div>
											<p>
												<b>Contato:</b>
											</p>
										</div>
										<div className="contato">
											{contato.length > 0 ? (
												contato.map(value => (
													<div
														className="row"
														key={
															value.id_contato_has_usuario
														}
													>
														<div className="col-12 col-md-3 col-lg-3 form-group">
															<b>Telefone:</b>
														</div>
														<div className="col-12 col-md-6 col-lg-6 form-group">
															<p>
																(0
																{
																	value.ddd_numero
																}
																)
																{
																	value.tel_numero
																}
															</p>
														</div>
													</div>
												))
											) : (
												<div className="row">
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p>Sem Registro </p>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<br />
								<div className="card">
									<div className="card-header">
										Classes Coordenadas:
									</div>
									<div className="card-body">
										{classesCoordinator.map(classe => (
											<>
												<div className="row">
													<div className="mt-2 form-group col-12 col-lg-6 col-md-6">
														<p>
															<b>Classe:</b>{' '}
															{
																classe.desc_serie_ano
															}{' '}
															{
																classe.desc_tipo_ensino
															}{' '}
															<b>Turma: </b>
															{classe.turma}
														</p>
													</div>
													<div className="form-group col-sm-6">
														<button
															className="btn btn-warning"
															type="button"
															onClick={() =>
																removerClasses(
																	classe
																)
															}
														>
															Remover
														</button>
													</div>
												</div>
												<hr />
											</>
										))}

										<form onSubmit={handleSubmit}>
											<div className="row">
												{inputFields.map(
													(inputField, index) => (
														<Fragment
															// eslint-disable-next-line react/no-array-index-key
															key={`${inputField}~${index}`}
														>
															<div className="form-group col-12 col-lg-8 col-md-8">
																<label htmlFor="Classe">
																	Classe
																</label>
																<select
																	required
																	name="Classe"
																	className="form-control"
																	id="lblIdClasse"
																	onChange={event =>
																		handleInputChange(
																			index,
																			event
																		)
																	}
																>
																	<option>
																		Escolha
																		uma
																		Classe
																	</option>
																	{classes.map(
																		classe => (
																			<option
																				value={
																					classe.id_classe
																				}
																				key={
																					classe.id_classe
																				}
																			>
																				{
																					classe.desc_serie_ano
																				}{' '}
																				{
																					classe.desc_tipo_ensino
																				}{' '}
																				{
																					classe.turma
																				}
																			</option>
																		)
																	)}
																</select>
															</div>
															<div className="form-group col-sm-2">
																{inputFields.length >
																	1 && (
																	<button
																		className="btn btn-link"
																		type="button"
																		onClick={() =>
																			handleRemoveFields(
																				index
																			)
																		}
																	>
																		-
																	</button>
																)}
																<button
																	className="btn btn-link"
																	type="button"
																	onClick={() =>
																		handleAddFields()
																	}
																>
																	+
																</button>
															</div>
														</Fragment>
													)
												)}
											</div>
											<div className="submit-button">
												<button
													className="btn btn-primary mr-2"
													type="submit"
												>
													Adicionar Classe
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
