import React from 'react';
import formatDate from '../../../helpers/formatDate';

export default function Message({ message }) {
	const styles = {
		card: {
			boxShadow: '2px 2px 5px #888',
		},
	};
	return (
		<div className="row painel justify-content-center">
			<div className="col-xl-11 col-lg-11 col-md-12">
				<div className="card my-2" style={styles.card}>
					<div className="card-body">
						<p>
							<b>Data:</b> {formatDate(message.data)}
						</p>
						<p>
							<b>Mensagem:</b> {message.mensagem}
						</p>
						<p>
							<b>Relator:</b> {message.usuario}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
