export default [
	null,
	'/prefeito',
	'/secretaria-de-educacao',
	'/diretorensino',
	'/diretor',
	'/secretaria',
	'/coordenador',
	'/professor',
	'/responsavel',
	'/alunos',
	'/cozinha-piloto',
	'/nutricionista',
	'/supervisor-transporte',
	'/motorista',
	'/monitor',
	'/diretoria-administrativa',
	'/divisao-administrativa',
	'/divisao-planejamento-e-orcamento',
	'/divisao-ti',
	'/diretoria-supervisao-pedagogica',
	'/divisao-educaçao-infantil',
	'/divisao-educaçao-fundamental',
	'/divisao-educacao-especial',
	'/diretoria-suprimentos',
	'/divisao-alimentacao-escolar',
	'/divisao-transporte-escolar',
	'/divisao-patrimonio',
	'/divisao-suprimentos',
	'/administracao-creches',
];
