/* eslint-disable no-useless-catch */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FaEye } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import SelectSerieAno from '../../components/Selects/SelectSerieAno';
import SelectDisciplinas from '../../components/Selects/SelectDisciplinas';
import RouteParams from '../../routes/data/RouteParams';

export default function ListTeacher() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista Professores',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [teachers, setTeacher] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [school, setSchool] = useState([]);
	const [anoLetivo, setAnoLetivo] = useState(new Date().getFullYear());
	const [escolaID, setEscolaId] = useState(null);
	const [idSerieAno, setIdSerieAno] = useState(null);
	const [turma, setTurma] = useState(null);
	const [idDisciplina, setIdDisciplina] = useState(null);

	async function loadEscola() {
		const { data } = await api.get('/escola/rede-ensino/lista-escolas');
		if (data.error) setSchool([]);
		else setSchool(data.data);
	}

	async function handleSearch() {
		setIsLoading(true);
		const data = {
			escolaID,
			idSerieAno,
			turma,
			idDisciplina,
			anoLetivo,
		};
		const response = await api.post('/professor/lista', data);
		if (!response.data.error) setTeacher(response.data.data);
		else {
			setTeacher([]);
			swal({
				icon: 'warning',
				text: response.data.data,
				title: 'Não há professores cadastrados nessa escola!',
			});
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadEscola();
		handleSearch();
	}, []);

	function OptionsButton({ idEmployer }) {
		function viewNavigation() {
			const params = {};
			params[
				RouteParams.LEVEL_06_ROUTES.INFO_EMPLOYEE.USER_ID
			] = idEmployer;

			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_EMPLOYEE,
				state: params,
			});
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Usuário"
					className="buttonViewColor"
					onClick={() => viewNavigation(idEmployer)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setEscolaId(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Disciplina</b>
							</label>
							<SelectDisciplinas
								setDisciplina={setIdDisciplina}
								valueSelect={idDisciplina}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<SelectSerieAno
								setIdSerieAno={setIdSerieAno}
								valueSelect={idSerieAno}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Turma</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={turma}
								onChange={event => setTurma(event.target.value)}
							>
								<option value="">Escolha uma opção</option>
								<option value="A">A</option>
								<option value="B">B</option>
								<option value="C">C</option>
								<option value="D">D</option>
								<option value="F">F</option>
								<option value="G">G</option>
								<option value="H">H</option>
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Ano Letivo</b>
							</label>
							<input
								type="text"
								maxLength={4}
								minLength={4}
								className="form-control"
								id="add_description"
								placeholder="Ano letivo"
								value={anoLetivo}
								onChange={event =>
									setAnoLetivo(event.target.value)
								}
							/>
						</div>
					</div>
					<button
						onClick={handleSearch}
						className="btn btn-primary"
						type="button"
						id="button-addon2"
					>
						Filtrar
					</button>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<>
							{teachers.length === 0 ? (
								<p>
									Não há professores cadastrados nessa escola.
								</p>
							) : (
								<Table
									header={[
										'Nome Professor',
										'Disciplina',
										'Escola',
										'Serie/Ano',
										'Ano',
										'',
									]}
									body={teachers.map(teacher => {
										return [
											teacher.nome_usuario,
											teacher.nome_disciplina
												? teacher.nome_disciplina
												: 'Não foi atribuída para nenhuma disciplina',
											teacher.desc_nome_escola,
											teacher.desc_serie_ano
												? `${teacher.desc_serie_ano}, ${teacher.desc_tipo_ensino} - ${teacher.turma}`
												: 'Não foi atribuída para nenhuma classe',
											teacher.ano_letivo,
											<OptionsButton
												idEmployer={teacher.id_usuario}
											/>,
										];
									})}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}
