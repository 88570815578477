// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/add_green.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/close_red.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".coordinator-board {\r\n\tmargin-top: 3%;\r\n\tmax-height: 400px;\r\n\toverflow-y: scroll;\r\n\tpadding-right: 20px;\r\n}\r\ntd.details-control {\r\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\r\n\tbackground-size: 15%;\r\n\tcursor: pointer;\r\n\r\n}\r\ntr.shown td.details-control {\r\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center center;\r\n\tbackground-size: 15%;\r\n}", ""]);
// Exports
module.exports = exports;
