import React, { useState, useEffect } from 'react';
import { FcInfo } from 'react-icons/fc';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import RouteByPermission from '../../routes/data/RouteByPermission';
import RouteParams from '../../routes/data/RouteParams';
import Routes from '../../routes/data/Routes';
import api from '../../services/api';

export default function CardAttribution({
	idStudent = '',
	tittle = 'Disciplinas',
}) {
	const userActualType = useSelector(state => state.userType.actualType);
	const [attributions, setAttributions] = useState([]);
	const history = useHistory();

	useEffect(() => {
		(async function() {
			const response = await api.get(`/aluno/disciplinas/${idStudent}`);
			if (!response.data.error)
				setAttributions(response.data.data);
			else attributions([]);
		})();
	}, []);

	function handleChangePage(value) {
		const params = {};
		params[RouteParams.COMMON_LEVELS.ATTRIBUTION.ATTRIBUTION_ID] = value;

		history.push({
			pathname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ATTRIBUTION,
			state: params,
		});
	}
	return (
		<div className="card mt-3">
			<div className="card-header">
				<h6>{tittle}</h6>
			</div>
			<div className="card-body">
				<div className="row">
					{attributions.map(attribution => (
						<div className="col-12 col-md-6 col-lg-6">
							<div className="card">
								<div className="card-header">
									<div className="row">
										<div className="col-9 col-md-10 col-lg-10">
											<h6>
												{attribution.nome_disciplina}
											</h6>
										</div>
										<div className="col-3 col-md-2 col-lg-2">
											<button
												className="btn btn-link"
												onClick={() =>
													handleChangePage(
														attribution.id_atribuicao
													)
												}
											>
												<FcInfo />
											</button>
										</div>
									</div>
								</div>

								<div className="card-body">
									{attribution.nome_usuario}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
