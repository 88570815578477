import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaSchool, FaUserGraduate, FaClipboardList } from 'react-icons/fa';
import HeaderDash from '../../../components/HeaderDash';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';

export default function CardsTeacher() {
	const [schools, setSchoolQt] = useState([]);
	const [subjects, setSubjectsQt] = useState([]);
	const [students, setStudentsQt] = useState([]);

	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		async function loadTeacherDetails() {
			const response = await api.get('/professor');
			if (response.data.data) {
				setSchoolQt(response.data.data.qtdEscola);
				setSubjectsQt(response.data.data.qtdDisciplina);
				setStudentsQt(response.data.data.qtdAlunos);
			}
		}
		loadTeacherDetails();
	});
	const cardsTeacher = [
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Escolas',
			headerIcon: <FaSchool />,
			headerNumber: `${schools}`,
			iconBackGroundColor: 'bg-light-blue',
			iconTextColor: 'text-blue',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).SCHOOLS,
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Alunos',
			headerIcon: <FaUserGraduate />,
			headerNumber: `${students}`,
			iconBackGroundColor: 'bg-light-yellow',
			iconTextColor: 'text-orange',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).STUDENTS,
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Disciplinas',
			headerIcon: <FaClipboardList />,
			headerNumber: `${subjects}`,
			iconBackGroundColor: 'bg-light-purple',
			iconTextColor: 'text-purple',
			headerLinkName: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).COURSES,
		},
	];
	return <HeaderDash headerItems={cardsTeacher} />;
}
