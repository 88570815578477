/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Panel from '../../../components/Panel';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Footer from '../../../components/Footer';
import Bookloading from '../../../components/Loading/Bookloading';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function Index({ history }) {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CORRECT_ATTENDANCE,
			namePage: 'Corrigir falta',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [studentsAttendance, setStudentsAttendance] = useState([]);
	const [correctMissStudentPresent, setCorrectMissStudentPresent] = useState(
		[]
	);
	const [subjects, setSubjects] = useState([]);
	const [schools, setSchools] = useState([]);
	const [subjectsContent, setSubjectContent] = useState([]);
	const [schoolId, setSchoolId] = useState(null);
	const [subjectId, setSubjectId] = useState(null);
	const [schoolDay, setSchoolDay] = useState(null);
	const [schoolDayId, setSchoolDayId] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledContent, setDisabledContent] = useState(true);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [disabledBtnStudents, setDisabledBtnStudents] = useState(true);
	const [classes, setClasses] = useState([]);
	const [classId, setClassId] = useState(null);
	const [disabledClass, setDisabledClass] = useState(true);
	const [isVisible, setIsVisible] = useState(false);
	const [searchStudent, setSearchStudent] = useState(false);
	const [lblConteudo, setlblConteudo] = useState('');

	const options = {
		year: 'numeric',
		month: 'numeric',
		day: '2-digit',
		timeZone: 'UTC',
	};

	useEffect(() => {
		(async function() {
			const dataPost = {
				typeUser: userActualType,
			};
			const response = await api.post('/usuario/escola', dataPost);
			if (!response.data.error) setSchools(response.data.data);
			else {
				swal('Ops!', `${response.data.message}`, 'warning');
				setSchools([]);
			}
		})();
	}, []);

	useEffect(() => {
		if (schoolId)
			(async function() {
				const dataPost = {
					schoolId: schoolId,
					typeUser: userActualType,
				};
				const response = await api.post(
					'/usuario/escola/classe',
					dataPost
				);
				if (response.data.error) {
					swal('Ops!', `${response.data.message}`, 'warning');
					setClasses([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClasses(response.data.data);
				}
			})();
	}, [schoolId]);

	useEffect(() => {
		(async function() {
			if (classId) {
				const dataPost = {
					classId,
					schoolId: schoolId,
					typeUser: userActualType,
				};
				const response = await api.post(
					'/usuario/escola/classe/disciplinas',
					dataPost
				);
				if (response.data.error) {
					swal('Ops!', `${response.data.message}`, 'warning');
					setSubjects([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjects(response.data.data);
				}
			}
		})();
	}, [classId]);

	useEffect(() => {
		(async function() {
			if (subjectId && schoolId && classId) {
				const response = await api.get('/usuario/conteudos-aulas', {
					params: {
						subjectId,
						schoolId,
						classId,
					},
				});
				if (response.data.error) {
					swal('Ops!', `${response.data.message}`, 'warning');
					setSubjectContent([]);
					setDisabledContent(true);
					setSchoolDayId(0);
				} else {
					setDisabledContent(false);
					setSubjectContent(response.data.data);
				}
			}
		})();
	}, [subjectId]);

	useEffect(() => {
		if (schoolDayId > 0 && classId > 0 && subjectId > 0)
			setDisabledBtn(false);
		else {
			setDisabledBtn(true);
			setDisabledBtnStudents(true);
			setStudentsAttendance([]);
			setlblConteudo('');
			setSearchStudent(false);
		}
	}, [schoolDayId, classId, subjectId]);

	useEffect(() => {
		(async function() {
			setDisabledBtnStudents(true);
		})();
	}, [schoolDayId]);

	async function searchStudents() {
		setTimeout(setIsVisible(true), 3000);
		(async function() {
			const response = await api.get('/usuario/alunos/faltantes', {
				params: {
					schoolId,
					subjectId,
					classId,
					schoolDayId,
				},
			});
			setIsVisible(false);
			setSearchStudent(true);
			if (!response.data.error) {
				setDisabledBtnStudents(false);
				setStudentsAttendance(response.data.data.chamada);
				setSchoolDay(response.data.data.dia);
				setlblConteudo(response.data.data.conteudo);
			} else {
				swal('Ops!', `${response.data.message}`, 'warning');
				setDisabledBtnStudents(true);
				setStudentsAttendance([]);
			}
		})();
	}

	async function checkBoxPresents(e) {
		if (correctMissStudentPresent.indexOf(e) > -1) {
			const index = correctMissStudentPresent.indexOf(e);
			if (index !== -1) {
				correctMissStudentPresent.splice(index, 1);
				setCorrectMissStudentPresent(correctMissStudentPresent);
			}
		} else correctMissStudentPresent.push(e);
	}

	async function correctAttendanceStudent(event) {
		event.preventDefault();
		const data = {
			studentsIds: correctMissStudentPresent,
			content: lblConteudo,
			schoolDayId,
			subjectId,
			classId,
		};
		const response = await api.post(
			'/usuario/alunos/corrigir-chamada',
			data
		);
		if (!response.data.error)
			setTimeout(() => swal('Chamada atualizada'), 500);
		else swal('Ops!', `${response.data.message}`, 'warning');
		setCorrectMissStudentPresent([]);
		window.scrollTo(0, 0);
		searchStudents();
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mb-4">
				<div className="card-body">
					<div className="row mb-3">
						<div className="col-12 col-lg-3 form-group">
							<label htmlFor="schools">
								<b>Escola</b>
							</label>
							<select
								className="form-control"
								name="schools"
								id="schools"
								onChange={event =>
									setSchoolId(event.target.value)
								}
							>
								<option value="0">Escolha a Escola</option>
								{schools.map(schools => (
									<option
										key={schools.id_escola}
										value={schools.id_escola}
									>
										{schools.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-3 form-group">
							<label htmlFor="classes">
								<b>Série/Turma</b>
							</label>
							<select
								id="classes"
								className="form-control"
								onChange={event =>
									setClassId(event.target.value)
								}
								disabled={disabledClass}
							>
								<option value="0">Escolha a série</option>
								{classes.map(classes => (
									<option
										key={classes.id_classe}
										value={classes.id_classe}
									>
										{classes.desc_serie_ano} -{' '}
										{classes.turma}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-3 form-group">
							<label htmlFor="subjectTeacher">
								<b>Disciplina</b>
							</label>
							<select
								className="form-control"
								name="subjectTeacher"
								id="subjectTeacher"
								onChange={event =>
									setSubjectId(event.target.value)
								}
								disabled={disabledSubjects}
							>
								<option value="0">Escolha a disciplina</option>
								{subjects.map(subjects => (
									<option
										key={subjects.id_disciplina}
										value={subjects.id_disciplina}
									>
										{subjects.nome_disciplina}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-3 form-group">
							<label htmlFor="subjectContent">
								<b>Dia da Aula</b>
							</label>
							<select
								className="form-control"
								name="subjectContent"
								id="subjectContent"
								onChange={event =>
									setSchoolDayId(event.target.value)
								}
								disabled={disabledContent}
							>
								<option value="0">Escolha o dia</option>
								{subjectsContent.map(content => (
									<option
										key={content.id_aula}
										value={content.id_aula}
									>
										{content.dia_letivo}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col-lg-6 col-md-10 col-12 form-group">
							<button
								type="button"
								className="btn btn-block btn-success"
								onClick={searchStudents}
								disabled={disabledBtn}
							>
								<FaSearch className="searchIcon" />
								Buscar Alunos
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="card">
				<form onSubmit={correctAttendanceStudent}>
					<div className="card-body">
						<div className="row justify-content-center">
							<div className="col-lg-1 col-md-1 col-2">
								<div className="legend" />
							</div>
							<div className="col-lg-2 col-md-2 col-5">
								<p>Faltou</p>
							</div>
						</div>
						{isVisible === true && (
							<Bookloading
								isLoading
								style={{
									marginTop: '-250px',
								}}
							/>
						)}
						{isVisible === false && (
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12">
									<h6>Chamada</h6>
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
												<tr>
													<th scope="col">Aluno</th>
													<th
														scope="col"
														className="text-center"
													>
														{schoolDay
															? new Date(
																	schoolDay
															  ).toLocaleDateString(
																	'pt-BR',
																	options
															  )
															: null}
													</th>
												</tr>
											</thead>

											<tbody className="tableListStudent">
												{studentsAttendance.map(
													listStudent => (
														<Tooltip
															title="Corrigir a presença do Aluno."
															placement="top-start"
														>
															<tr
																key={
																	listStudent.id_usuario
																}
																className={
																	listStudent.falta
																		? 'table-danger'
																		: 'table-light'
																}
															>
																<th>
																	{
																		listStudent.nome_usuario
																	}
																</th>
																<th className="text-center">
																	<input
																		type="checkbox"
																		name="studentMiss"
																		id="studentMiss"
																		value={
																			listStudent.id_usuario
																		}
																		onChange={event =>
																			checkBoxPresents(
																				event
																					.target
																					.value
																			)
																		}
																	/>
																</th>
															</tr>
														</Tooltip>
													)
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						)}
						{searchStudent === true ? (
							<div className="form-group">
								<p>
									<b>Conteúdo dado em sala de Aula:</b>
								</p>
								<textarea
									required
									className="form-control"
									id="text-conteudo"
									rows="3"
									value={lblConteudo}
									onChange={event =>
										setlblConteudo(event.target.value)
									}
								/>
							</div>
						) : null}
						<div className="row text-center justify-content-center">
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-block btn-danger"
									disabled={disabledBtnStudents}
								>
									Corrigir
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
