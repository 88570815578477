import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import RouteParams from '../../../routes/data/RouteParams';
import Table from '../../../components/Table';

export default function ListClass() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista Classes',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [classes, setClasses] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			const message = await api.get('/classes/lista');
			if (message.data.error === false) {
				setIsLoading(false);
				setClasses(message.data.data);
			} else swal('ERRO!', `${message.data.message}`, 'error');
		})();
	}, []);

	function OptionsButton({ numClasse }) {
		function viewNavigation() {
			const number = numClasse;
			const state = {};
			state[RouteParams.LEVEL_05_ROUTES.INFO_CLASS.CLASS_NUMBER] = number;
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.INFO_CLASS,
				state
			);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(numClasse)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<Table
							header={[
								'Cod Classe',
								'Turma',
								'Série',
								'Ano Letivo',
								'',
							]}
							body={classes.map(classe => {
								return [
									classe.num_classe,
									classe.turma,
									classe.desc_serie_ano,
									classe.ano_letivo,
									<OptionsButton
										numClasse={classe.num_classe}
									/>,
								];
							})}
						/>
					</div>
				</div>
			)}
		</>
	);
}
