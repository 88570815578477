import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaTimesCircle, FaPencilAlt, FaTrashAlt, FaEye } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';

export default function ListDriver() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Motoristas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [drivers, setDrivers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	async function loadDriver() {
		setIsLoading(true);
		const response = await api.get('/transporte/motorista/lista-motorista');
		if (!response.data.error) setDrivers(response.data.data);
		else {
			setDrivers([]);
			swal(response.data.message);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadDriver();
	}, []);

	function OptionsButton({ driver }) {
		function viewNavigation() {
			const params = {};

			params[RouteParams.LEVEL_08_ROUTES.EDIT_DRIVER.DRIVER] = driver;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.INFO_DRIVER,
				{ params }
			);
		}
		function editNavigation() {
			const params = {};

			params[RouteParams.LEVEL_08_ROUTES.EDIT_DRIVER.DRIVER] = driver;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.UPDATE_DRIVER,
				{ params }
			);
		}

		async function handleDeactivated(e) {
			swal({
				title: 'Você tem certeza?',
				text: `Você está para desativar o motorista ${e.users.nome_usuario}`,
				icon: 'warning',
				buttons: ['Cancelar', 'Confirmar'],
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const data = { idDriver: e.users.id_usuario };
						const {
							data: { message },
						} = await api.post(
							'/transporte/motorista/desativa-motorista',
							data
						);
						swal('SUCESSO!', `${message}`, 'success').then(
							value => {
								if (value) loadDriver();
							}
						);
					})();
				else swal('Ação cancelada');
			});
		}

		return (
			<>
				<div className="d-flex justify-content-center">
					<button
						type="button"
						aria-hidden
						data-toggle="tooltip"
						title="Ver Motorista"
						className="buttonViewColor"
						onClick={() => viewNavigation(driver)}
					>
						<FaEye fill="#ffffff" />
					</button>
					<button
						type="button"
						aria-hidden
						data-toggle="tooltip"
						title="Editar Motorista"
						className="buttonAddColor"
						onClick={() => editNavigation(driver)}
					>
						<FaPencilAlt fill="#ffffff" />
					</button>
					<button
						type="button"
						aria-hidden
						data-toggle="tooltip"
						className="buttonDelete"
						title="Desativar Motorista"
						onClick={() => handleDeactivated(driver)}
					>
						<FaTrashAlt fill="#ffffff" />
					</button>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<Breadcrumb breadCrumbItems={breadCrumbItems} />
						{isLoading === true && (
							<div className="lds-dual-ring" />
						)}
						{isLoading === false && (
							<div className="card">
								<div className="card-body">
									<div className="d-flex flex-row-reverse">
										<Link
											to={
												Routes.LOGGED_ROUTES(
													RouteByPermission[
														userActualType
													]
												).ADD_DRIVER
											}
										>
											<button
												className="btn buttonCustomSemec"
												type="button"
												id="button"
											>
												Cadastrar Novo Motorista
											</button>
										</Link>
									</div>
									{drivers ? (
										<Table
											header={['Nome', '']}
											body={drivers.map(driver => {
												return [
													driver.users.nome_usuario,
													<OptionsButton
														driver={driver}
													/>,
												];
											})}
										/>
									) : (
										<div className="productsNotFound">
											<FaTimesCircle size={30} />
											<p className="mt-2">
												Nenhum motorista encontrado
											</p>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
