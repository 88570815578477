/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import api from '../../../services/api';
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css';

const MapboxGLMap = () => {
	mapboxgl.accessToken =
		'pk.eyJ1IjoibWJ4c29sdXRpb25zIiwiYSI6ImNqeWtiM250dzBjcGMzbnQ1NXpkZml6YjUifQ.EiJnPlDOMMlG5wr93SxVYQ';
	const [MapIcons, setMapIcons] = useState([]);

	useEffect(() => {
		(async function() {
			const response = await api.get('/rotas/mapa-cidade', {
				params: {
					filtro: 'todos',
				},
			});
			if (!response.data.error) setMapIcons(response.data.data);
		})();
	}, []);

	useEffect(() => {
		const filterGroup = document.getElementById('filter-group');
		const stores = {
			type: 'FeatureCollection',
			features: MapIcons,
		};
		const map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [-44.9695124, -22.5839855],
			zoom: 13,
		});

		map.on('load', () => {
			map.resize();
			map.addSource('stores', {
				type: 'geojson',
				data: stores,
			});

			stores.features.forEach(function(feature) {
				const symbol = feature.properties.icon;
				const name = feature.properties.label;
				const layerID = `poi-${name}`;

				if (!map.getLayer(layerID)) {
					map.addLayer({
						id: layerID,
						type: 'symbol',
						source: 'stores',
						layout: {
							'icon-image': `${symbol}-15`,
							'icon-allow-overlap': true,
						},
						filter: ['==', 'icon', symbol],
					});

					const input = document.createElement('input');
					input.type = 'checkbox';
					input.id = layerID;
					input.checked = true;
					filterGroup.appendChild(input);

					const label = document.createElement('label');
					label.setAttribute('for', layerID);
					label.textContent = name;
					filterGroup.appendChild(label);

					input.addEventListener('change', function(e) {
						map.setLayoutProperty(
							layerID,
							'visibility',
							e.target.checked ? 'visible' : 'none'
						);
					});
				}
			});
		});
	}, [MapIcons]);

	return (
		<>
			<div className="card" id="card-mapa-escolas">
				<div className="card-header">
					<h4>Mapa da Cidade</h4>
				</div>
				<div id="map" />
				<nav id="filter-group" className="filter-group" />
			</div>
		</>
	);
};

export default MapboxGLMap;
