import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../../services/api';
import Breadcrumb from '../../../../components/Breadcrumb';
import RouteParams from '../../../../routes/data/RouteParams';
import Table from '../../../../components/Table';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';

export default function UpdateMonitorHasStudents() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const location = useLocation();
	const [monitor, setMonitor] = useState('');
	const [idsRaStudentsToAdd, setIdsRaStudentsToAdd] = useState([]);
	const [idsRaStudentsToRemove, setIdsRaStudentsToRemove] = useState([]);
	const [listStudents, setListStudents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const params = {};
	params[RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR] = monitor;

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_MONITOR,
			namePage: 'Monitores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: {
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).LIST_MONITOR_HAS_STUDENTS,
				state: { params },
			},
			namePage: 'Lista de alunos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atualizar Lista de alunos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			if (location.state) {
				const infoUser =
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_MONITOR.MONITOR
					];
				setMonitor(infoUser);
				const { data } = await api.get(
					'/transporte/monitor/lista-alunos',
					{
						params: {
							monitorId: infoUser.id_usuario,
						},
					}
				);
				if (data.error === false) {
					setListStudents(data.data);
					data.data.map(item =>
						idsRaStudentsToAdd.push(item.id_registro_aluno)
					);
				} else swal({ icon: 'warning', text: data.message });
			} else
				swal({ icon: 'warning', text: 'Escolha um monitor' }).then(
					value => {
						history.push(
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_MONITOR
						);
					}
				);
			const { data } = await api.get('/aluno/lista');
			if (data.error === false) setListStudents(data.data);
			else swal({ icon: 'warning', text: data.message });
			setIsLoading(false);
		})();
	}, []);

	function OptionsButtonTeacher({ idStudent }) {
		function viewNavigation() {
			setIdsRaStudentsToAdd([...idsRaStudentsToAdd, idStudent]);
			const filteredAry = idsRaStudentsToRemove.filter(
				e => e !== idStudent
			);
			setIdsRaStudentsToRemove(filteredAry);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-primary"
					onClick={() => viewNavigation(idStudent)}
				>
					Selecionar
				</button>
			</>
		);
	}

	function OptionsButtonStudentMarkOff({ idStudent }) {
		function markOffStudent() {
			const filteredAry = idsRaStudentsToAdd.filter(e => e !== idStudent);
			setIdsRaStudentsToAdd(filteredAry);
			setIdsRaStudentsToRemove([...idsRaStudentsToRemove, idStudent]);
		}
		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="btn btn-warning"
					onClick={() => markOffStudent(idStudent)}
				>
					Desmarcar
				</button>
			</>
		);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = {
				raIdsToRemove: idsRaStudentsToRemove,
				raIdsToAdd: idsRaStudentsToAdd,
				monitorId: monitor.id_usuario,
			};
			await api
				.post('/transporte/monitor/atualizar-lista', data)
				.then(response => {
					if (!response.data.error)
						swal({
							icon: 'success',
							text: response.data.message,
						}).then(value => {
							history.push(
								Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).LIST_MONITOR_HAS_STUDENTS,
								{ params }
							);
						});
					else swal({ icon: 'error', text: response.data.message });
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card mt-3">
					<form onSubmit={handleSubmit}>
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Atualizar Lista</h4>
						</div>
						<div className="card-body">
							<p>
								<b>Monitor:</b> {monitor.nome_usuario}
							</p>
							{listStudents ? (
								<Table
									header={['Nome', 'RA', '']}
									body={listStudents.map(student => {
										return [
											student.nome_usuario,
											student.num_ra,
											idsRaStudentsToAdd.indexOf(
												student.id_registro_aluno
											) > -1 ? (
												<OptionsButtonStudentMarkOff
													idStudent={
														student.id_registro_aluno
													}
												/>
											) : (
												<OptionsButtonTeacher
													idStudent={
														student.id_registro_aluno
													}
												/>
											),
										];
									})}
								/>
							) : (
								<div className="productsNotFound">
									<FaTimesCircle size={30} />
									<p className="mt-2">
										Nenhum aluno encontrado
									</p>
								</div>
							)}
						</div>
						<div className="card-footer">
							<div className="row justify-content-start">
								<div className="col-lg-4 col-md-5 col-7 form-group">
									<button
										className="btn btn-primary btn-block"
										type="submit"
									>
										Atualizar
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			)}
		</>
	);
}
