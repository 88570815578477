// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/bg-school.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".background_page_form {\r\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n\tbackground-size: cover;\r\n}\r\n.logoSchool {\r\n\tmax-width: 100%;\r\n}\r\n#login-card {\r\n\tmargin-top: 9%;\r\n\tmargin-bottom: 3.4%;\r\n}\r\n#signup {\r\n\tcolor: #fff;\r\n\tfont-weight: bold;\r\n\tfont-size: 1.2em;\r\n\ttext-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;\r\n}\r\n#createaccount {\r\n\tmargin-left: 0.5em;\r\n}\r\n.errorLogin {\r\n\tfont-size: 1.2em;\r\n\tcolor: #ff0000;\r\n\tfont-weight: 500;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
