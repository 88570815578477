import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import api from '../../../services/api';
import formatDate from '../../../helpers/formatDate';
import Routes from '../../../routes/data/Routes';
import InputRadio from '../../InputRadio';
import ValidationArray from '../../../data/Validation';
import SelectComponent from '../../Selects/SelectComponent';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import YesOrNo from '../../../data/YesOrNo';
import env from '../../../env';

export default function FormProtocolUpdateSchool({ protocol, setProtocols }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const [nomeResponsavel, setNomeResponsavel] = useState(
		protocol.nome_responsavel
	);
	const [dataNascimento, setDataNascimento] = useState(
		protocol.data_nascimento
	);
	const [logradouro, setLogradouro] = useState(protocol.logradouro);
	const [bairro, setBairro] = useState(protocol.bairro);
	const [complemento, setComplemento] = useState(protocol.complemento);
	const [numCasa, setNumCasa] = useState(protocol.numero);
	const [cep, setCep] = useState(protocol.cep);
	const [escolas, setEscolas] = useState([]);
	const [typesOfTeachings, setTypesOfTeachings] = useState([]);
	const [idTypeOfTeaching, setIdTypeOfTeaching] = useState(
		protocol.serie_ano_id
	);
	const [idEscola, setIdEscola] = useState(protocol.creche_id);
	const [telefone, setTelefone] = useState(protocol.numero_contato);
	const [observacao, setObservacao] = useState('');
	const [observacoes, setObservacoes] = useState(
		protocol.observacoes_vagas_creches
	);
	const [nomeAluno, setNomeAluno] = useState(protocol.nome_aluno);
	const [email, setEmail] = useState(protocol.email);
	const [validado, setValidado] = useState(protocol.validado);
	const [pcd, setPCD] = useState(protocol.pcd);
	const [fulltime, setFulltime] = useState(protocol.integral);
	const [pcdFile, setPCDFile] = useState(protocol.nome_arquivo);
	const urlFile = `${env.API_WEB}/pcd/comprovantes-pcd/${protocol.nome_arquivo}`;
	const [loading, setLoading] = useState(false);

	async function getSchool() {
		const response = await api.get('/escolas/ElementarySchool');
		const schoolsArray = response.data.data.map(e => {
			return { ...e, name: e.name.replace('ESCOLA MUNICIPAL', '') };
		});
		setEscolas(schoolsArray);
	}

	async function getTypesOfTeachings() {
		const response = await api.get('/tabelas/tipos-ensino', {
			params: {
				idEscola,
				typesOfTeaching: 'elementarySchool',
			},
		});
		setTypesOfTeachings(response.data.data);
	}

	function backToLisgetElementarySchoolVacancies() {
		const url = Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
			.CHILDHOOD_EDUCATION_VACANCIES;
		history.push(url);
	}

	useEffect(() => {
		console.log(protocol);
		getSchool();
		getTypesOfTeachings();
	}, [idEscola, idTypeOfTeaching]);

	const listObservation = observacoes.map(U => (
		<div key={U.id}>
			{formatDate(U.created_at, false)} - {U.observacao}
		</div>
	));

	async function handleFormSubmit(e) {
		e.preventDefault();
		setLoading(true);
		let file = null;
		const userEdit = new FormData();
		const files = document.querySelector('#filePicker');
		if (files) file = files.files[0] ?? pcdFile;

		userEdit.append('nome_responsavel', nomeResponsavel);
		userEdit.append('nome_aluno', nomeAluno);
		userEdit.append('data_nascimento', dataNascimento);
		userEdit.append('pcd', pcd ?? 0);
		userEdit.append('file', file);
		userEdit.append('logradouro', logradouro);
		userEdit.append('numero_endereco', numCasa);
		userEdit.append('bairro', bairro);
		userEdit.append('complemento', complemento);
		userEdit.append('cep', cep);
		userEdit.append('numero_contato', telefone);
		userEdit.append('email', email);
		userEdit.append('validado', validado);
		userEdit.append('id_escola', idEscola);
		userEdit.append('observacao', observacao);
		userEdit.append('id_serie', idTypeOfTeaching);
		userEdit.append('integral', fulltime);

		const { data } = await api.post(
			`/vagas-em-escolas/${protocol.id}`,
			userEdit,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		if (data.error) {
			setLoading(false);
			swal({ icon: 'warning', text: data.message, title: 'Ops!' });
		} else {
			setObservacao('');
			setLoading(false);
			setProtocols(data.data[0]);
			setIdEscola(data.data[0].creche_id);
			setObservacoes(data.data[0].observacoes_vagas_creches);
			swal({ icon: 'success', text: data.message, title: 'SUCESSO!' });
		}
	}

	return (
		<form
			className="mt-4"
			onSubmit={event => {
				handleFormSubmit(event);
			}}
		>
			<div className="form-group row">
				<div className="col-12 col-sm-6">
					<label htmlFor="updateProtocol">Nome da Criança:</label>
					<input
						type="text"
						className="form-control"
						value={nomeAluno}
						onChange={e => {
							setNomeAluno(e.target.value);
						}}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<label htmlFor="updateProtocol">
						Data de Nascimento da Criança:
					</label>
					<input
						type="date"
						className="form-control"
						value={dataNascimento}
						onChange={e => {
							setDataNascimento(e.target.value);
						}}
					/>
				</div>
				<div className="col-12 mt-3">
					<label htmlFor="updateProtocol">Nome do Responsável:</label>
					<input
						type="text"
						className="form-control"
						value={nomeResponsavel}
						onChange={e => {
							setNomeResponsavel(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="form-group row mt-4">
				<div className="col-12 col-sm-4">
					<label htmlFor="updateProtocol">Rua:</label>
					<input
						type="text"
						className="form-control"
						value={logradouro}
						onChange={e => {
							setLogradouro(e.target.value);
						}}
					/>
				</div>
				<div className="col-12 col-sm-4">
					<label htmlFor="updateProtocol">Bairro:</label>
					<input
						type="text"
						className="form-control"
						value={bairro}
						onChange={e => {
							setBairro(e.target.value);
						}}
					/>
				</div>
				<div className="col-12 col-sm-2">
					<label htmlFor="updateProtocol">Número:</label>
					<input
						type="text"
						className="form-control"
						value={numCasa}
						onChange={e => {
							setNumCasa(e.target.value);
						}}
					/>
				</div>
				<div className="col-12 col-sm-2">
					<label htmlFor="updateProtocol">Complemento:</label>
					<input
						type="text"
						className="form-control"
						value={complemento}
						onChange={e => {
							setComplemento(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="form-group row mt-4">
				<div className="col-12 col-sm-4">
					<label htmlFor="updateProtocol">CEP:</label>
					<input
						type="text"
						className="form-control"
						value={cep}
						onChange={e => {
							setCep(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="mt-4">
				<SelectComponent
					setValue={setIdEscola}
					valueSelcted={idEscola}
					array={escolas}
					label="Escolas:"
					theSameLine={false}
					optionAll={false}
				/>
			</div>
			<div className="mt-4">
				<SelectComponent
					setValue={setIdTypeOfTeaching}
					valueSelcted={idTypeOfTeaching}
					array={typesOfTeachings}
					label="Turma:"
					theSameLine={false}
					optionAll={false}
				/>
			</div>
			<div className="form-group row mt-4">
				<div className="col-12 col-sm-4">
					<label htmlFor="updateProtocol">E-mail:</label>
					<input
						type="text"
						className="form-control"
						value={email}
						onChange={e => {
							setEmail(e.target.value);
						}}
					/>
				</div>
				<div className="col-12 col-sm-4">
					<label htmlFor="updateProtocol">Telefone:</label>
					<input
						type="text"
						className="form-control"
						value={telefone}
						onChange={e => {
							setTelefone(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="form-group row mt-4">
				<div className="col-12 col-sm-6">
					<InputRadio
						setValue={setFulltime}
						valueSelcted={fulltime}
						array={YesOrNo}
						label="Escola integral:"
						theSameLine
						optionAll={false}
					/>
				</div>
			</div>
			<hr />
			<div className="form-group row mt-4">
				<div className="col-12 col-sm-6">
					<InputRadio
						setValue={setPCD}
						valueSelcted={pcd}
						array={YesOrNo}
						label="PCD:"
						theSameLine
						optionAll={false}
					/>
				</div>
			</div>
			{pcd == true ? (
				<div className="form-group row mt-4">
					<div className="col-12">
						<label>{pcdFile}</label>
						<div className="d-flex justify-content-between">
							<a href={urlFile}>Download</a>
							{/* TODO separar o style daqui e colocar no button css */}
							<div className="button-file">
								<label htmlFor="filePicker">
									Trocar Arquivo
								</label>
								<input
									type="file"
									id="filePicker"
									onChange={e => {
										setPCDFile(e.target.value);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<hr />
			<div className="form-group row mt-4">
				<div className="col-12 col-sm-6">
					<InputRadio
						setValue={setValidado}
						valueSelcted={validado}
						array={ValidationArray}
						label="Validação:"
						theSameLine
						optionAll={false}
					/>
				</div>
			</div>
			<hr />
			<div className="form-group row mt-4">
				<div className="col-12">
					<label htmlFor="updateProtocol">Observação:</label>
					<textarea
						className="form-control"
						rows="3"
						onChange={e => {
							setObservacao(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="form-group row mt-4">
				<div className="col-12">
					<label htmlFor="updateProtocol">
						Histórico de observações:
					</label>
					<div className="list-observation">{listObservation}</div>
				</div>
			</div>
			<div className="form-group row text-right ">
				<div className="col-9">
					<button
						onClick={() => backToLisgetElementarySchoolVacancies()}
						className="btn buttonCustomSemecBack"
					>
						Voltar
					</button>
				</div>
				<div className="col-3">
					<button
						type="submit"
						className="btn buttonCustomSemec"
						disabled={!!loading}
					>
						Salvar alterações
					</button>
				</div>
			</div>
		</form>
	);
}
