import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import SelectDrivers from '../../../components/Selects/SelectDrivers';
import Breadcrumb from '../../../components/Breadcrumb';
import CardMonitor from './CardMonitor';
import CardStudents from './CardStudents';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function UpdateRouteTransport() {
	const history = useHistory();
	const location = useLocation();
	const userActualType = useSelector(state => state.userType.actualType);

	const [isLoading, setIsLoading] = useState(true);
	const [idRota, setIdRota] = useState([]);
	const [alunosIds, setAlunoIds] = useState([]);
	const [motoristaId, setMotoristaId] = useState();
	const [monitoresIds, setMonitoresIds] = useState([]);
	const [kmIda, setKmIda] = useState();
	const [kmVolta, setKmVolta] = useState();
	const [horaIniIda, setHoraIniIda] = useState();
	const [horaFimIda, setHoraFimIda] = useState();
	const [horaIniVolta, setHoraIniVolta] = useState();
	const [horaFimVolta, setHoraFimVolta] = useState();
	const [dataTransporte, setDataTransporte] = useState('');
	const [ida, setIda] = useState(false);
	const [volta, setVolta] = useState(false);
	const [idTurno, setIdTurno] = useState();
	const [monitors, setMonitors] = useState([]);
	const [students, setStudents] = useState([]);
	const [groupMonitor, setGroupMonitor] = useState([]);
	const [groupStudents, setGroupStudents] = useState([]);
	const [disabledButton, setDisabledButton] = useState(false);
	const [studentsToRemove, setStudentsToRemove] = useState([]);
	const [monitorsToRemove, setMonitorsToRemove] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_ROUTE_TRANSPORT,
			namePage: 'Rotas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Atualizar Rota',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = {
				idRota,
				alunosIds,
				motoristaId,
				monitoresIds,
				kmIda,
				kmVolta,
				horaIniIda,
				horaFimIda,
				horaIniVolta,
				horaFimVolta,
				dataTransporte,
				ida,
				volta,
				idTurno,
				studentsToRemove,
				monitorsToRemove,
			};
			await api
				.post('/transporte/rota/atualizar-rota', data)
				.then(response => {
					if (!response.data.error) {
						swal(response.data.message);
						history.goBack();
					} else swal(response.data.message);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		(async function() {
			setIsLoading(true);

			if (location.state) {
				const idRoute =
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_TRANSPORT.ID_TRANSPORT
					];
				setIdRota(
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.EDIT_TRANSPORT.ID_TRANSPORT
					]
				);
				const { data } = await api.get(
					'/transporte/rota/informacao-rota',
					{
						params: {
							idRoute,
						},
					}
				);
				if (data.error === false) {
					setMotoristaId(
						data.data.driver.user.id_usuario_has_veiculo
					);
					setKmIda(data.data.driver.user.quilometragem_ida);
					setKmVolta(data.data.driver.user.quilometragem_volta);
					setHoraIniIda(data.data.driver.user.horario_inicio_ida);
					setHoraFimIda(data.data.driver.user.horario_fim_ida);
					setHoraIniVolta(data.data.driver.user.horario_inicio_volta);
					setHoraFimVolta(data.data.driver.user.horario_fim_volta);
					setDataTransporte(data.data.driver.user.dia_transporte);
					setIda(data.data.driver.user.ida === 1);
					setVolta(data.data.driver.user.volta === 1);
					setIdTurno(data.data.driver.user.id_turno);
					const listMonitor = data.data.monitor.map(
						monitor => monitor.user.id_usuario
					);
					setMonitors(listMonitor);
					setGroupMonitor(data.data.monitor);

					const list = data.data.students.map(
						student => student.user.id_usuario
					);
					setStudents(list);
					setGroupStudents(data.data.students);
				}
			} else {
				swal({
					icon: 'Warning',
					text: 'Escolha uma rota para acessar essa página.',
				});
				history.goBack();
			}
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		if (
			(students.length === 0 && alunosIds.length === 0) ||
			(monitors.length === 0 && monitoresIds.length === 0)
		)
			setDisabledButton(true);
		else setDisabledButton(false);
	}, [students, alunosIds, monitors, monitoresIds]);

	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<Breadcrumb breadCrumbItems={breadCrumbItems} />
						{isLoading === true ? (
							<div className="lds-dual-ring" />
						) : (
							<form onSubmit={handleSubmit}>
								<div className="card mt-3">
									<div className="card-header col-12 col-md-12 col-lg-12 form-group">
										<h4>Motorista e Monitor</h4>
									</div>
									<div className="card-body">
										<div className="form-group">
											<p>
												<b>Motorista</b>
											</p>
											<SelectDrivers
												disabled={false}
												valueSelect={motoristaId}
												setDriver={setMotoristaId}
											/>
										</div>
										<div className="row">
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Quilometragem Ida</b>
												</p>
												<input
													type="text"
													name="kmVolta"
													id="kmVolta"
													className="form-control"
													placeholder="Ex: 100.00"
													value={kmVolta}
													onChange={event =>
														setKmVolta(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Quilometragem Volta</b>
												</p>
												<input
													type="text"
													name="kmIda"
													id="kmIda"
													className="form-control"
													placeholder="Ex: 100.00"
													value={kmIda}
													onChange={event =>
														setKmIda(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3">
												<p>
													<b>Ida/Volta</b>
												</p>
												<div className="row">
													<div className="col-12 col-md-6 col-lg-6 form-group">
														<b>Ida: </b>
														<input
															checked={ida}
															type="checkbox"
															onChange={() =>
																setIda(!ida)
															}
														/>
													</div>
													<div className="col-12 col-md-6 col-lg-6 form-group">
														<b>Volta: </b>
														<input
															checked={volta}
															type="checkbox"
															onChange={() =>
																setVolta(!volta)
															}
														/>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-3 col-lg-3">
												<p>
													<b>Turno</b>
												</p>
												<select
													required
													className="form-control"
													name="idTurno"
													id="idTurno"
													value={idTurno}
													onChange={event =>
														setIdTurno(
															event.target.value
														)
													}
												>
													<option value="">
														Escolha um Turno
													</option>
													<option value="1">
														Manhã
													</option>
													<option value="2">
														Intermediário
													</option>
													<option value="3">
														Tarde
													</option>
													<option value="4">
														Vespertino
													</option>
													<option value="5">
														Noite
													</option>
													<option value="6">
														Integral
													</option>
												</select>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Hora inicio ida</b>
												</p>
												<input
													type="text"
													name="horaIniIda"
													id="horaIniIda"
													className="form-control"
													placeholder="Ex: 06:00"
													value={horaIniIda}
													onChange={event =>
														setHoraIniIda(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Hora fim ida</b>
												</p>
												<input
													type="text"
													name="horaFimIda"
													id="horaFimIda"
													className="form-control"
													placeholder="Ex: 12:00"
													value={horaFimIda}
													onChange={event =>
														setHoraFimIda(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Hora inicio volta</b>
												</p>
												<input
													type="text"
													name="horaIniVolta"
													id="horaIniVolta"
													className="form-control"
													placeholder="Ex: 06:00"
													value={horaIniVolta}
													onChange={event =>
														setHoraIniVolta(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Hora fim volta</b>
												</p>
												<input
													type="text"
													name="horaFimVolta"
													id="horaFimVolta"
													className="form-control"
													placeholder="Ex: 12:00"
													value={horaFimVolta}
													onChange={event =>
														setHoraFimVolta(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row form-group">
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>
														Data para do Tansporte
													</b>
												</p>
												<InputMask
													mask="99/99/9999"
													required
													size={8}
													type="text"
													name="dataTransporte"
													id="dataTransporte"
													className="form-control"
													placeholder="Data Transporte"
													value={dataTransporte}
													onChange={event =>
														setDataTransporte(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="card mt-3">
									<div className="card-header form-group">
										<h4>Monitor</h4>
									</div>
									<div className="card-body">
										<CardMonitor
											setMonitorsToRemove={
												setMonitorsToRemove
											}
											groupMonitor={groupMonitor}
											monitors={monitors}
											setMonitors={setMonitors}
											setMonitorToAdd={setMonitoresIds}
										/>
									</div>
								</div>
								<div className="card mt-3">
									<div className="card-header form-group">
										<h4>Alunos</h4>
									</div>
									<div className="card-body">
										<CardStudents
											setStudentsToRemove={
												setStudentsToRemove
											}
											groupStudents={groupStudents}
											students={students}
											setStudents={setStudents}
											setStudentsToAdd={setAlunoIds}
										/>
									</div>
									<div className="card-footer">
										<div className="row justify-content-start">
											<div className="col-lg-4 col-md-5 col-7 form-group">
												<button
													disabled={disabledButton}
													className="btn btn-primary btn-block"
													type="submit"
												>
													Atualizar
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
