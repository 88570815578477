/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Breadcrumb from '../Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import CardUserContacts from '../Contact/CardUserContacts';
import parseJwt from '../../helpers/parseJwt';
import InfoCardSchool from '../CardsEmployees/InfoCardSchool';
import CardStudentSupervisor from './CardStudentSupervisor';
import BasicData from '../Cards/BasicData';
import DataAccessSystem from '../Cards/DataAccessSystem';
import Documents from '../Cards/Documents';
import Address from '../Cards/Address';
import './style.css';

export default function FormStudents({ idUsuario = null }) {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informações do Usuário',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [contactUser, setContactUser] = useState([]);
	const [filhos, setFilhos] = useState([]);
	const [cargosEmEscolas, setCargosEmEscolas] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	async function loadUser() {
		const {
			data: { error, message, data },
		} = await api.get('/usuario/informacao', {
			params: {
				idUser: idUsuario,
			},
		});
		if (error === false) {
			setContactUser(data.contato);
			setFilhos(data.filhos);
			setCargosEmEscolas(data.tipoUsuario);
		} else {
			swal('ERRO!', `${message}`, 'error');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}
	useEffect(() => {
		setIsLoading(true);
		loadUser();
		setIsLoading(false);
	}, [idUsuario]);
	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<Breadcrumb breadCrumbItems={breadCrumbItems} />
						{isLoading === true ? (
							<div className="lds-dual-ring" />
						) : (
							<>
								<BasicData
									idUsuario={idUsuario}
									tittle="Dados do Usuário"
									editable
								/>
								<DataAccessSystem
									idUsuario={idUsuario}
									tiitle="Dados de Acesso no Sistema"
									editable
								/>
								<Documents idUsuario={idUsuario} />
								{cargosEmEscolas.length ? (
									<InfoCardSchool
										employeesSchool={cargosEmEscolas}
										disabled
										idUsuario={idUsuario}
									/>
								) : null}
								{filhos.length ? (
									<CardStudentSupervisor
										responsibleForStudents={filhos}
									/>
								) : null}
								<CardUserContacts
									userContacts={contactUser}
									idUsuario={idUsuario}
								/>
								<Address idUsuario={idUsuario} />
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
