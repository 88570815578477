import React from 'react';
import Panel from '../../../components/Panel';
import ReadInBoxComunicated from '../../../components/Communicated/ReadCommunicated/ReadInBoxComunicated';
import Footer from '../../../components/Footer';
import Breadcrumb from '../../../components/Breadcrumb';
import { useSelector } from 'react-redux';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COMMUNICATED,
			namePage: 'Comunicados',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Visualizar Comunicado Recebido',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<ReadInBoxComunicated />
		</>
	);
}
