import React from 'react';
import { FcInfo } from 'react-icons/fc';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import RouteParams from '../../routes/data/RouteParams';

export default function GradeTable({ grade, isLoading }) {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Boletim',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	function handleChangePage(value) {
		const params = {};
		params[RouteParams.COMMON_LEVELS.ATTRIBUTION.ATTRIBUTION_ID] = value;

		history.push({
			pathname: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ATTRIBUTION,
			state: params,
		});
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<div className="card">
					<div className="card-body table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Disciplina</th>
									<th scope="col">1° Bimestre</th>
									<th scope="col">2° Bimestre</th>
									<th scope="col">3° Bimestre</th>
									<th scope="col">4° Bimestre</th>
									<th scope="col">Nota final</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody>
								{grade &&
									grade.map(element => (
										<tr>
											<td>{element.nome_disciplina}</td>
											<td>
												{element.nota_bimestral1
													? element.nota_bimestral1
													: '--'}
											</td>
											<td>
												{element.nota_bimestral2
													? element.nota_bimestral2
													: '--'}
											</td>
											<td>
												{element.nota_bimestral3
													? element.nota_bimestral3
													: '--'}
											</td>
											<td>
												{element.nota_bimestral4
													? element.nota_bimestral4
													: '--'}
											</td>

											<td>
												{element.nota_media_final
													? element.nota_media_final
													: '--'}
											</td>
											<td>
												<button
													className="btn btn-link"
													onClick={() =>
														handleChangePage(
															element.id_atribuicao
														)
													}
												>
													<FcInfo />
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</>
	);
}
