export default {
	COMMON_LEVELS: {
		PROGRAMMATIC_CONTENT_INFO: {
			CONTENT_ID: 'idContent',
		},
		READ_NOTICES: {
			NOTICE_ID: 'idNotice',
		},
		EDIT_NOTICES: {
			NOTICE_ID: 'idNotice',
		},
		READ_COMMUNICATED_SEND: {
			MESSAGE: 'message',
		},
		READ_COMMUNICATED_RECEIVE: {
			MESSAGE: 'message',
		},
		ATTRIBUTION: {
			ATTRIBUTION_ID: 'idAttribution',
		},
		ADD_COMMUNICATED_MESSAGE: {
			COMMUNICATED: 'communicatedMessage',
		},
		CLOCK_POINT: {
			USER_ID: 'idUser',
		},
	},
	LEVEL_03_ROUTES: {
		REPORT_CARD: {
			STUDENT_ID: 'idStudent',
			SUBJECT_ID_TEACHER: 'subjectIDTeacher',
		},
		ATTRIBUTION: {
			ATTRIBUTION_ID: 'idAttribution',
		},
	},
	LEVEL_04_ROUTES: {
		INFO_STUDENTS: {
			ID_USUARIO: 'idUsuario',
		},
	},
	LEVEL_05_ROUTES: {
		PROFILE_STUDENT: {
			USER_ID: 'idUsuario',
		},
		PROFILE_TEACHER: {
			USER_ID: 'idUsuario',
		},
		INFO_SERIES: {
			SERIES_ID: 'idSerie',
		},
		INFO_CLASS: {
			CLASS_NUMBER: 'numClasse',
		},
		MESSAGES: {
			OCCURRENCE: 'ocorrenciaId',
		},
		INFO_COORDINATOR: {
			INFO_USER: 'infoUser',
			LIST_CLASSES: 'listClasses',
			LIST_CONTATOS: 'listContatos',
		},
		EDIT_DRIVER: {
			DRIVER: 'driver',
		},
		VIEW_TICKET: {
			TICKET_ID: 'ticket',
		},
		INFO_SUPPLICES: {
			SUPPLICES_ID: 'idSupplices',
		},
	},
	LEVEL_06_ROUTES: {
		SCHOOL_INFO: {
			SCHOOL_CODE: 'CodEscola',
		},
		STUDENT_INFO: {
			USER_ID: 'idUsuario',
		},
		INFO_EMPLOYEE: {
			USER_ID: 'idUser',
			PROTOCOL: 'protocol',
		},
		INFO_SUPPLICES: {
			SUPPLICES_ID: 'idSupplices',
		},
		ATTRIBUTION: {
			ATTRIBUTION_ID: 'idAttribution',
		},
	},
	LEVEL_07_ROUTES: {
		EDIT_PRODUCTS: {
			PRODUCT: 'product',
		},
		EDIT_PRODUCTS_ADD: {
			PRODUCT: 'product',
		},
		EDIT_PRODUCTS_REMOVE: {
			PRODUCT: 'product',
		},
		EDIT_PROVIDERS: {
			PROVIDER: 'provider',
		},
	},
	LEVEL_08_ROUTES: {
		EDIT_DRIVER: {
			DRIVER: 'driver',
		},
		EDIT_MONITOR: {
			MONITOR: 'monitor',
		},
		EDIT_TRANSPORT: {
			ID_TRANSPORT: 'transport',
		},
		EDIT_VEHICLE: {
			ID_VEHICLE: 'vehicle',
		},
		MAINTENANCE_INFO: {
			INFO: 'info',
		},
	},
};
