import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import api from '../../../../services/api';

const ModalReviseQuestions = ({ student, atualiza = null, idAvaliacao,index}) => {
	const [resultadoQuestao, setResultadoQuestao] = useState(
		student.respostas.map(item => item)
	);

	const [resultadoFinal, setResultadoFinal] = useState({});

	const handleOnChange = (event, index = null, id = null) => {
		const resultado = {
			...resultadoFinal,
			[resultadoQuestao[index].id]: event.target.value,
		};

		setResultadoFinal(resultado);
	};

	const handleSubmitResultQuestions = async (event) => {
		resultadoFinal.id_avaliacao = [idAvaliacao];
		try {
			await api.patch('/avaliacao/correcao-questoes', resultadoFinal);
		} catch (error) {
			throw error;
		}
		atualiza(true);
		window.location.reload();
	};

	const maxPunctuation = pontuacao => {
		const array = [];
		let total = 0;
		for (let cont = 1; cont <= pontuacao; cont++) {
			const sum = 0.5;

			if (array.length == 0) {
				array.push(0);
				array.push(sum);
				array.push(cont);
				total = cont + sum;
				array.push(total);
			} else {
				array.push(cont);
				total = cont + sum;
				array.push(total);
			}
		}
		array.splice(-1);
		return array;
	};

	return (
		<>
			<div id="corrigirQuestaoProval">
				<span>{student.nome_usuario}</span>
				<button
					type="button"
					className="btn btn-success"
					data-toggle="modal"
					data-toggle="modal"
					data-target={`#ModalLong${index}`}
				>
					Corrigir respostas
				</button>
			</div>

			<div
				className="modal fade"
				id={`ModalLong${index}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby="#ModalReviseQuestions"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5
								className="modal-title"
								id="ModalReviseQuestions"
							>
								Avalie as questões
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body ">
							{student.respostas.map((resposta, index) => {
								let pontuacao = maxPunctuation(resposta.peso);

								return (
									<div
										className="reviseQuestionContainer"
										key={index}
									>
										<div className="reviseQuestionHeader">
											<strong>{resposta.titulo}</strong>
											<span>{resposta.descricao}</span>
										</div>

										<div className="reviseQuestionBottom">
											<label>
												<strong>
													Resposta do aluno:
												</strong>
												<span>{resposta.resposta}</span>
											</label>
											<label>
												<div className="form-group">
													<label for="exampleFormControlSelect1">
														Example select
													</label>
													<select
														className="form-control"
														id="exampleFormControlSelect1"
														onChange={event =>
															handleOnChange(
																event,
																index,
																resposta.id
															)
														}
													>
														{pontuacao.map(
															value => (
																<option
																	value={
																		value
																	}
																>
																	{value}
																</option>
															)
														)}
													</select>
												</div>
											</label>
										</div>
									</div>
								);
							})}
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
							<button
								data-dismiss="modal"
								type="button"
								className="btn btn-primary"
								onClick={handleSubmitResultQuestions}
							>
								Salvar
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalReviseQuestions;
