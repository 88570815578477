import React, { useState } from 'react';
import semecLogo from '../../../assets/img/semec_prefeitura.png';
import api from '../../../services/api';
import Routes from '../../../routes/data/Routes';

export default function UpdatePassword({ history }) {
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [errorPassword, setErrorPassword] = useState('');

	const userID = localStorage.getItem('userID');
	async function handleUpdatePassword(event) {
		event.preventDefault();
		const response = await api.post('/atualizasenha', {
			senhaNova: newPassword,
			confirmaSenha: confirmNewPassword,
			idUsuario: userID,
		});
		if (!response.data.error) {
			setErrorPassword(response.data.message);
			localStorage.clear();
			setTimeout(function() {
				history.push(Routes.LOGIN);
			}, 3000);
		} else setErrorPassword(response.data.message);
	}
	return (
		<>
			<div className="background_page_form">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-lg-7 col-md-9">
							<div className="card" id="login-card">
								<div className="card-body">
									<div className="row justify-content-center text-center">
										<div className="col-12">
											<img
												src={semecLogo}
												className="logoSchool"
												alt="Logo secretaria de educação"
											/>
										</div>
									</div>
									<form
										className="login_page_form"
										onSubmit={handleUpdatePassword}
									>
										<div className="row justify-content-center">
											<div className="form-group col-10">
												<label htmlFor="newPassword">
													Nova Senha
												</label>
												<input
													type="password"
													name="newPassword"
													id="newPassword"
													placeholder="Digite sua nova senha"
													className="form-control"
													onChange={event =>
														setNewPassword(
															event.target.value
														)
													}
													required
												/>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="form-group col-10">
												<label htmlFor="confirmPassword">
													Confirmar nova senha
												</label>
												<input
													type="password"
													name="confirmPassword"
													id="confirmPassword"
													placeholder="Digite novamente sua senha"
													className="form-control"
													onChange={event =>
														setConfirmNewPassword(
															event.target.value
														)
													}
													required
												/>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="col-10 form-group">
												<span className="errorLogin">
													{errorPassword}
												</span>
											</div>
										</div>
										<div className="row justify-content-center text-center">
											<div className="col-10 form-group">
												<button
													type="submit"
													className="btn btn-primary btn-block"
													id="updatePasswordBtn"
												>
													Atualizar
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
