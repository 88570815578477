import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Breadcrumb from '../../../../components/Breadcrumb';
import Footer from '../../../../components/Footer';
import SelectClasses from '../../../../components/Selects/SelectClasses';
import SelectDisciplinas from '../../../../components/Selects/SelectDisciplinas';
import SelectSerieAno from '../../../../components/Selects/SelectSerieAno';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import Routes from '../../../../routes/data/Routes';
import api from '../../../../services/api';
import Panel from '../../../../components/Panel';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [idSerie, setIdSerie] = useState(null);
	const [idSubject, setIdSubject] = useState(null);
	const [idClasse, setIdClasse] = useState(null);
	const [tittle, setTittle] = useState('');
	const [description, setDescription] = useState('');
	const files = useRef(null);
	const [disableButton, setDisableButton] = useState(false);
	const [buttonMessage, setButtonMessage] = useState('Adicionar');

	async function handleAddModules(event) {
		event.preventDefault();
		setButtonMessage('Carregando...');
		let textMessage = null;
		if (idSerie && idSubject && idClasse)
			textMessage = 'Você tem certeza dessa ação? \n';
		else textMessage = 'Você não escolheu uma: \n';
		if (!idSerie)
			textMessage = `${textMessage}* Série/Ano - o arquivo será adicionado em todas séries;\n`;
		if (!idSubject)
			textMessage = `${textMessage}* Disciplina - o arquivo será adicionado em todas disciplinas;\n`;
		if (!idClasse)
			textMessage = `${textMessage}* Classe - o arquivo será adicionado em todas classes;\n`;

		swal({
			title: 'Cuidado',
			icon: 'warning',
			text: textMessage,
			buttons: true,
			dangerMode: true,
		}).then(willDelete => {
			if (willDelete)
				(async function() {
					setDisableButton(true);
					try {
						const data = new FormData();
						data.append('tittle', tittle);
						data.append('description', description);
						data.append('idSerie', idSerie);
						data.append('idSubject', idSubject);
						data.append('idClasse', idClasse);
						Object.values(files.current.files).map(file =>
							data.append(Math.random(), file)
						);
						const response = await api.post(
							'/atribuicao/criar-modulos',
							data
						);

						if (!('data' in response)) throw response;
						if (response.data.error) throw response.data;

						swal('SUCESSO!', response.data.message, 'success');
						setTittle('');
						setDescription('');
						const dataTransfer = new DataTransfer();
						files.current.files = dataTransfer.files;
					} catch (error) {
						console.log(`error: ${error}`);
					}
					setDisableButton(false);
				})();
			else swal('Ação cancelada');
		});
		setButtonMessage('Adicionar');
	}

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.ATTRIBUTION,
			namePage: 'Atribuições',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar Arquivos das Aulas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<form onSubmit={handleAddModules}>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-4 col-lg-4">
								<div className="form-group">
									<label htmlFor="subjectsSchool">
										<b>Série/Ano</b>
									</label>
									<SelectSerieAno
										setIdSerieAno={setIdSerie}
										valueSelect={idSerie}
									/>
								</div>
							</div>
							<div className="col-12 col-md-4 col-lg-4">
								<div className="form-group">
									<label htmlFor="subjectsSchool">
										<b>Disciplinas</b>
									</label>
									<SelectDisciplinas
										setDisciplina={setIdSubject}
										valueSelect={idSubject}
									/>
								</div>
							</div>
							<div className="col-12 col-md-4 col-lg-4">
								<div className="form-group">
									<label htmlFor="subjectsSchool">
										<b>Classe</b>
									</label>
									<SelectClasses
										setClasse={setIdClasse}
										valueSelect={idClasse}
									/>
								</div>
							</div>
						</div>
						<hr />
						<div className="form-group text-left">
							<label htmlFor="add_tittle">
								<b>Assunto:</b>
							</label>
							<input
								type="text"
								className="form-control"
								id="add_tittle"
								placeholder="Assunto"
								value={tittle}
								required
								onChange={event =>
									setTittle(event.target.value)
								}
							/>
						</div>
						<div className="form-group text-left">
							<label htmlFor="add_description">
								<b>Descrição:</b>
							</label>
							<input
								required
								type="text"
								className="form-control"
								id="add_description"
								placeholder="Descrição"
								value={description}
								onChange={event =>
									setDescription(event.target.value)
								}
							/>
						</div>
						<div className="form-group text-left">
							<p>
								<b>Arquivos</b>
							</p>
							<input
								required
								type="file"
								className="form-control-file"
								ref={files}
								multiple
							/>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6">
								<button
									type="button"
									className="btn btn-danger"
									data-dismiss="modal"
								>
									Cancelar
								</button>
							</div>
							<div className="col-12 col-md-6 col-lg-6">
								<button
									type="submit"
									className="btn btn-primary btn-block"
									disabled={disableButton}
								>
									{buttonMessage}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
