import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import SelectKindSchool from './SelectKindSchool';
import SelectKindTeacher from './SelectKindTeacher';
import SelectKindCoordinator from './SelectKindCoordinator';

export default function ListClass() {
	const [lblKindUser, setlblKindUser] = useState([]);
	const [lblSubject, setlblSubject] = useState('');
	const [lblCommunicated, setlblCommunicated] = useState('');
	const [lblTarget, setlblTarget] = useState([]);
	const files = useRef(null);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [sendLoading, setSendLoading] = useState(false);
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		setlblKindUser(userActualType);
	}, []);
	useEffect(() => {
		if (lblTarget > 0 && lblCommunicated) setDisabledBtn(false);
		else if (lblKindUser == 2 && lblCommunicated) setDisabledBtn(false);
		else setDisabledBtn(true);
	}, [lblTarget, lblCommunicated]);
	async function handleCancel(event) {
		event.preventDefault();
		history.goBack();
	}
	async function handleSubmit(event) {
		event.preventDefault();
		setSendLoading(true);
		try {
			const data = new FormData();

			data.append('lblSubject', lblSubject);
			data.append('lblCommunicated', lblCommunicated);
			data.append('lblTipoUsuarioLogado', userActualType);
			data.append(
				'lblTarget',
				lblKindUser == 2 || lblKindUser == 3 ? 5 : lblTarget
			);
			Object.values(files.current.files).map(file =>
				data.append(Math.random(), file)
			);

			const response = await api.post('/comunicados/enviar', data);

			if (response.data.error)
				swal('Ops!', response.data.message, 'warning');
			else {
				swal('SUCESSO!','success');
				setlblTarget([]);
				setlblSubject('');
				setlblCommunicated('');
			}
			setSendLoading(false);
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			{sendLoading === true && <div className="lds-dual-ring" />}
			{sendLoading === false && (
				<div className="container">
					<br />
					<form onSubmit={handleSubmit}>
						{userActualType != 2 ? (
							<div className="row">
								<div className="col-12 form-group">
									<p>
										<b>Destinatário</b>
									</p>
									{userActualType == 3 ||
									userActualType == 4 ||
									userActualType == 5 ? (
										<div>
											<SelectKindSchool
												setTipoUsuario={setlblTarget}
											/>
										</div>
									) : null}
									{userActualType == 7 && (
										<SelectKindTeacher
											setAlunos={setlblTarget}
										/>
									)}
									{userActualType == 6 && (
										<SelectKindCoordinator
											setAlunos={setlblTarget}
										/>
									)}
								</div>
							</div>
						) : null}
						<div className="row">
							<div className="col-12 form-group">
								<p>
									<b>Assunto</b>
								</p>
								<input
									type="text"
									max="60"
									name="lblSubject"
									id="lblSubject"
									className="form-control"
									placeholder="Assunto"
									value={lblSubject}
									required
									onChange={event =>
										setlblSubject(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 form-group">
								<p>
									<b>Comunicado</b>
								</p>
								<textarea
									className="form-control"
									value={lblCommunicated}
									onChange={event =>
										setlblCommunicated(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 form-group">
								<p>
									<b>Imagens</b>
								</p>
								<input
									type="file"
									className="form-control-file"
									ref={files}
									multiple
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-12 form-group">
								<button
									type="submit"
									className="btn btn-danger btn-block"
									onClick={handleCancel}
								>
									Cancelar
								</button>
							</div>
							<div className="col-lg-6 col-md-6 col-12 form-group">
								<button
									type="submit"
									className="btn btn-primary btn-block"
									data-toggle="modal"
									data-target="#modalConfirmar"
									disabled={disabledBtn}
								>
									Confirmar
								</button>
							</div>
						</div>
					</form>
				</div>
			)}
		</>
	);
}
