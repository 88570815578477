import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { FaSchool, FaUserGraduate } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import InfoClassAluno from '../../../components/ClassStudent/InfoClassAluno';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import RouteParams from '../../../routes/data/RouteParams';

export default function FormStudents() {
	const { state } = useLocation();
	const location = useLocation();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.STUDENTS,
			namePage: 'Lista Alunos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação do aluno',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblStudents, setlblStudents] = useState([]);
	const [lblContatos, setlblContatos] = useState([]);
	const [lblRaAluno, setlblRaAluno] = useState([]);
	const [lblCidadeNascimento, setlblCidadeNascimento] = useState([]);
	const [lblNecessidades, setlblNecessidades] = useState([]);
	const [lblDocumentos, setlblDocumentos] = useState([]);
	const [lblCuidador, setlblCuidador] = useState([]);
	const [lblMobilidade, setlblMobilidade] = useState([]);
	const [lblProfiSaude, setlblProfiSaude] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		(async function() {
			const {
				data: {
					error,
					message,
					data: {
						aluno,
						contatos,
						RaAluno,
						cidadeNascimento,
						necessidades,
						documentos,
						cuidador,
						mobilidade,
						profiSaude,
					},
				},
			} = await api.get(
				`/aluno/perfil/${
					state[RouteParams.LEVEL_06_ROUTES.STUDENT_INFO.USER_ID]
				}`
			);
			if (error === false) {
				setlblStudents(aluno);
				setlblContatos(contatos);
				setlblRaAluno(RaAluno);
				setlblCidadeNascimento(cidadeNascimento);
				setlblNecessidades(necessidades);
				setlblDocumentos(documentos);
				setlblCuidador(cuidador);
				setlblMobilidade(mobilidade);
				setlblProfiSaude(profiSaude);
			} else {
				swal('ERRO!', `${message}`, 'error');
				history.goBack();
			}
			setIsLoading(false);
		})();
	}, []);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div>
					<div className="card mt-3">
						<div className="card-header">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<h5>
										<b>Sobre o Aluno</b>
									</h5>
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<h1>
										<FaUserGraduate />
									</h1>
								</div>
								<div className="mt-4 col-12 col-md-11 col-lg-11 form-group">
									<p className="textoCapitalize">
										{lblStudents.nome_usuario.charAt(0) +
											lblStudents.nome_usuario
												.slice(1)
												.toLowerCase()}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-1 col-md-1 col-lg-1 form-group">
									<h1>
										<FaSchool />
									</h1>
								</div>
								<div className="mt-4 col-11 col-md-11 col-lg-11 form-group">
									<p className="textoCapitalize">
										{lblStudents.desc_nome_escola.charAt(
											0
										) +
											lblStudents.desc_nome_escola
												.slice(1)
												.toLowerCase()}
									</p>
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<p className="bolLabel">RA:</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>{lblRaAluno.num_ra}</p>
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p className="bolLabel">Digito RA:</p>
								</div>
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<p>{lblRaAluno.digito_ra}</p>
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p className="bolLabel">UF RA:</p>
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p>{lblRaAluno.sigla_uf}</p>
								</div>
							</div>
						</div>
					</div>
					<div>
						<InfoClassAluno idStudent={lblStudents.id_usuario} />
					</div>
					<div className="card mt-3">
						<div className="card-header">
							<div className="col-12 col-md-12 col-lg-12 form-group">
								<h4>Dados Básicos</h4>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Nome Aluno(a)</b>
									<p className="textoCapitalize">
										{lblStudents.nome_usuario.charAt(0) +
											lblStudents.nome_usuario
												.slice(1)
												.toLowerCase()}
									</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Data Nascimento</b>
									<p>{lblStudents.data_nascimento}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Nome Mãe</b>
									<p>{lblStudents.nome_mae}</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Nome Pai</b>
									<p>
										{lblStudents.nome_pai
											? lblStudents.nome_pai
											: 'Não Informado'}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Nome Social</b>
									<p>
										{lblStudents.nome_social
											? lblStudents.nome_social
											: 'Não Informado'}
									</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Nome Afetivo</b>
									<p>
										{lblStudents.nome_afetivo
											? lblStudents.nome_afetivo
											: 'Não Informado'}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<b>E-mail</b>
									<p>
										{lblStudents.email
											? lblStudents.email
											: 'Não Informado'}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<b>Cor/Raça:</b>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>{lblStudents.desc_cor_raca}</p>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<b>Gênero:</b>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>{lblStudents.desc_sexo}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<b>Bolsa Familia:</b>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									{lblStudents.bolsa_familia === 1 ? (
										<p>SIM</p>
									) : (
										<p>NÃO</p>
									)}
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<b>Quilombola:</b>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									{lblStudents.quilombola === 1 ? (
										<p>SIM</p>
									) : (
										<p>NÃO</p>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<b>Nacionalidade:</b>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>{lblStudents.desc_nacionalidade}</p>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<b>Necessidades Especiais:</b>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									{lblStudents.necessidade_id_necessidade !==
									null ? (
										<p>SIM</p>
									) : (
										<p>NÃO</p>
									)}
								</div>
							</div>
						</div>
					</div>
					{lblStudents.id_nacionalidade !== 1 && (
						<div>
							<div className="card mt-3">
								<div className="card-header col-12 col-md-12 col-lg-12 form-group">
									<h4>Nacionalidade</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<b>País</b>
											<p>{lblStudents.nome_pais}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<b>Data de Entrada no País</b>
											<p>
												{lblStudents.data_entrada_pais}
											</p>
										</div>
									</div>
								</div>
							</div>
							<br />
						</div>
					)}
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Município de Nascimento</h4>
						</div>
						<div className="row card-body">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<b>Cidade</b>
								<p>{lblCidadeNascimento.nome_cidade}</p>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<b>Estado</b>
								<p>{lblCidadeNascimento.sigla_uf}</p>
							</div>
						</div>
					</div>
					{lblStudents.necessidade_id_necessidade !== null ? (
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<p>Necessidades Especiais</p>
							</div>
							<div className="card-body">
								<div className="container">
									<ul>
										{lblNecessidades.map(necessidade => (
											<li key={necessidade}>
												{necessidade}
											</li>
										))}
									</ul>
								</div>

								{lblMobilidade ? (
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>Mobilidade Reduziada:</p>
											<p>SIM</p>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<b>Tipo de Duração: </b>
											<p>
												{
													lblMobilidade.desc_tipo_duracao
												}
											</p>
										</div>
									</div>
								) : null}
								{lblCuidador ? (
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<b>Cuidador: </b>
											<p>SIM</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<b>Tipo de Duração: </b>
											<p>
												{lblCuidador.desc_tipo_duracao}
											</p>
										</div>
									</div>
								) : null}
								{lblProfiSaude ? (
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>Profissional da Saude:</p>
											<p>SIM</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<b>Tipo de Duração: </b>
											<p>
												{
													lblProfiSaude.desc_tipo_duracao
												}
											</p>
										</div>
									</div>
								) : null}
							</div>
						</div>
					) : null}
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Recurso de Avaliação</h4>
						</div>
						<div className="card-body">
							<div className="row">
								{lblStudents.nenhum === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Nenhum:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Nenhum:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.auxilio_leitor === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Aux. Leitor:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Aux. Leitor:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.auxilio_transcricao === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Aux. Transcrição:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Aux. Transcrição:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.guia_interprete === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Guia Intérprete:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Guia Intérprete:</b> NÃO
										</p>
									</div>
								)}
							</div>
							<div className="row">
								{lblStudents.interprete_libras === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Intérprete Libras:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Intérprete Libras:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.leitura_labial === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Leitura Labial:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Leitura Labial:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.prova_braile === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Prova Braile:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Prova Braile:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.prova_video_libras === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Prova Video Libras:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Prova Video Libras:</b> NÃO
										</p>
									</div>
								)}
							</div>
							<div className="row">
								{lblStudents.cd_audio_def_visua === '1' ? (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Cd Audio-Visual:</b> SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Cd Audio-Visual:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.prova_lingua_portuguesa === '1' ? (
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Prova de Lingua Portuguesa:</b>{' '}
											SIM
										</p>
									</div>
								) : (
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Prova de Lingua Portuguesa:</b>{' '}
											NÃO
										</p>
									</div>
								)}
								{lblStudents.prova_ampliada_id_prova_ampliada ===
									1 && (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Fonte Prova:</b> NÃO
										</p>
									</div>
								)}
								{lblStudents.prova_ampliada_id_prova_ampliada ===
									2 && (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Fonte Prova:</b> 16
										</p>
									</div>
								)}
								{lblStudents.prova_ampliada_id_prova_ampliada ===
									3 && (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Fonte Prova:</b> 20
										</p>
									</div>
								)}
								{lblStudents.prova_ampliada_id_prova_ampliada ===
									4 && (
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Fonte Prova:</b> 24
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Documentos</h4>
						</div>
						<div className="card-body">
							<div className="row">
								{lblDocumentos.cod_inep ? (
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Código do INEP/MEC: </b>
											{lblDocumentos.cod_inep}
										</p>
									</div>
								) : null}
								{lblDocumentos.cod_justificativa ? (
									<div className="col-12 col-md-12 col-lg-12 form-group">
										<p>
											<b>Justificativa: </b>{' '}
											{lblDocumentos.desc_justificativa}
										</p>
									</div>
								) : null}
							</div>
						</div>
					</div>
					{lblDocumentos.num_documento_civil !== null ||
					lblDocumentos.cpf !== null ? (
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Documentos Básicos</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Número de Documento Civil</b>
										</p>
										<p>
											{lblDocumentos.num_documento_civil}
										</p>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Digito do documento civil</b>
										</p>
										<p>
											{
												lblDocumentos.digito_documento_civil
											}
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>
												Número de Identificação social
											</b>
										</p>
										<p>{lblDocumentos.num_nis}</p>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>CPF do Aluno</b>{' '}
										</p>
										<p>{lblDocumentos.cpf}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Data da Emissão do Documento</b>
										</p>
										<p>
											{
												lblDocumentos.data_emissao_certidao
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					) : null}
					{lblDocumentos.certidao_antiga_id_certidao_antiga && (
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Certidão Antiga</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Nº. da Certidão de Nascimento</b>
										</p>
										<p>{lblDocumentos.num_certidao}</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Número do livro de Registro</b>
										</p>
										<p>{lblDocumentos.num_livro_reg}</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Folha do livro de Registro</b>
										</p>
										<p>{lblDocumentos.folha_reg_num}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Nome do Município do Cartório</b>
										</p>
										<p>{lblDocumentos.nome_num_comarca}</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>
												Distrito do Município do
												Cartório
											</b>
										</p>
										<p>{lblDocumentos.distrito_nasc}</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>UF do Cartório</b>
										</p>
										<p>{lblDocumentos.uf_comarca}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Data da Certidão</b>
										</p>
										<p>
											{
												lblDocumentos.data_emissao_certidao
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					)}
					{lblDocumentos.certidao_nova_id_certidao_nova === null ||
						(lblDocumentos.certidao_nova_id_certidao_nova === 2 && (
							<div className="card mt-3">
								<div className="card-header col-12 col-md-12 col-lg-12 form-group">
									<h4>Certidão Nova</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Código do Cartório</b>
											<p>{lblDocumentos.cert_matr01}</p>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Código do acervo</b>
											<p>{lblDocumentos.cert_matr02}</p>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Serviço de Reg. Civil</b>
											<p>{lblDocumentos.cert_matr03}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Tipo do Livro</b>
											<p>{lblDocumentos.cert_matr04}</p>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Número do Livro</b>
											<p>{lblDocumentos.cert_matr05}</p>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Número do Livro</b>
											<p>{lblDocumentos.cert_matr06}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Número da Folha</b>
											<p>{lblDocumentos.cert_matr07}</p>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Número do Termo</b>
											<p>{lblDocumentos.cert_matr08}</p>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<b>Dígito de Controle</b>
											<p>{lblDocumentos.cert_matr09}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<b>Data da Certidão</b>
											<p>
												{
													lblDocumentos.data_emissao_certidao
												}
											</p>
										</div>
									</div>
								</div>
							</div>
						))}
					<div className="card mt-3">
						<div className="card-header col-12 col-md-12 col-lg-12 form-group">
							<h4>Endereço</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>CEP</b>
									<p>{lblStudents.cep}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Logradouro</b>
									<p>{lblStudents.logradouro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Número</b>
									<p>{lblStudents.numero}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Complemento</b>
									<p>{lblStudents.complemento}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Bairro</b>
									<p>{lblStudents.bairro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Cidade</b>
									<p>{lblStudents.nome_cidade}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Uf</b>
									<p>{lblStudents.sigla_uf}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Área do logradouro</b>
									<p>
										{lblStudents.desc_area
											? lblStudents.desc_area
											: 'Não Informado'}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Localização Diferenciada</b>
									<p>
										{lblStudents.desc_localizacao
											? lblStudents.desc_localizacao
											: 'Não Informado'}
									</p>
								</div>
							</div>
						</div>
					</div>
					{lblContatos.length > 0 && (
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Contato</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-12 col-lg-12 form-group">
										{lblContatos.map(contato => (
											<div
												className="contato row"
												key={contato.id_contato}
											>
												<div className="col-12 col-md-3 col-lg-3 form-group">
													<p className="bolLabel">
														<b>Telefone:</b>
													</p>
												</div>
												<div className="col-12 col-md-3 col-lg-3 form-group">
													<p>
														(0
														{contato.ddd_numero})
														{contato.tel_numero}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}
