import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import Footer from '../../../../components/Footer';
import Panel from '../../../../components/Panel';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import Breadcrumb from '../../../../components/Breadcrumb';
import RouteParams from '../../../../routes/data/RouteParams';
import ScrollToTop from '../../../../components/buttom/ScrollToTop';

export default function Index() {
	const history = useHistory();
	const location = useLocation();

	const [maintenance, setMaintenance] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [idVehicle, setidVehicle] = useState();
	const userActualType = useSelector(state => state.userType.actualType);

	const params = {};
	params[RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE] = idVehicle;

	async function handleGoBack(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.INFO_VEHICLE,
			{ params }
		);
	}

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			if (location.state) {
				setMaintenance(
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.MAINTENANCE_INFO.INFO
					]
				);
				setidVehicle(
					location.state.params[
						RouteParams.LEVEL_08_ROUTES.MAINTENANCE_INFO.INFO
					].id_veiculo
				);
			} else
				swal({
					icon: 'error',
					text:
						'Erro ao encontrar a manutenção, você será redirecionado para a tela de lista de veículos',
				}).then(event => {
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.INFO_VEHICLE,
						{ params }
					);
				});
			setIsLoading(false);
		})();
	}, []);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_VEHICLE,
			namePage: 'Veículos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: {
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_VEHICLE,
				state: { params },
			},
			namePage: 'Informação do Veículo',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Manutenção',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card mt-3">
					<div className="card-header form-group">
						<h4>Cadastro de Manutenção</h4>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Entrada na Manutenção</b>
								</p>
								{maintenance.entrada_manutencao}
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Saída da Manutenção</b>
								</p>
								{maintenance.saida_manutencao}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Local da Manutenção</b>
								</p>
								{maintenance.local_manutencao}
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Responsável</b>
								</p>
								{maintenance.responsavel}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Quilometragem entrada</b>
								</p>
								{maintenance.quilometragem_entrada}
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Quilometragem Saída</b>
								</p>
								{maintenance.quilometragem_saida}
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-3 col-lg-3 form-group text-left">
								<p>
									<b>Peça nova</b>
								</p>
								{maintenance.peca_nova === 1 ? 'Sim' : 'Não'}
							</div>
							<div className="col-12 col-md-3 col-lg-3 form-group text-left">
								<p>
									<b>Valor Manutenção</b>
								</p>
								R$ {maintenance.valor_manutencao}
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group text-left">
								<p>
									<b>Tipo de Serviço</b>
								</p>
								{maintenance.servico_mecanico}
							</div>
						</div>
						<div className="form-group">
							<p>
								<b>Descrição</b>
							</p>
							{maintenance.descricao}
						</div>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-12 form-group">
								<button
									className="btn buttonCustomSemec"
									type="button"
									onClick={handleGoBack}
								>
									Voltar
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
