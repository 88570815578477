import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import swal from 'sweetalert';
import { FaTimesCircle } from 'react-icons/fa';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import api from '../../services/api';
import './style.css';
import LabelByPermission from '../../routes/data/LabelByPermission';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

$.DataTable = require('datatables.net-dt');

export default function Tickets() {
	const [tickets, setTickets] = useState([]);
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Fichas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getTickets() {
		const response = await api.get('/cozinha-piloto/fichas');

		!response.data.error
			? setTickets(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	function buildTicketsTable(ticketsData) {
		$('#ticketsTable').DataTable({
			data: ticketsData,
			responsive: true,
			destroy: true,
			columns: [
				{ data: 'numero' },
				{ data: 'recurso' },
				{ data: 'elemento_despesa' },
				{
					data: data => {
						return `R$ ${data.valor}`;
					},
				},
				{
					data: data => {
						return `R$ ${data.saldo}`;
					},
				},
			],
			language: {
				url:
					'//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json',
			},
		});
	}

	useEffect(() => {
		getTickets();
	}, []);

	useEffect(() => {
		if (!tickets) return;
		if (tickets.length === 0) return;
		buildTicketsTable(tickets);
	}, [tickets]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{tickets ? (
				<div className="ticketsCardTable card table-responsive">
					<table className="table ticketsTable" id="ticketsTable">
						<thead>
							<tr>
								<th scope="col">Número</th>
								<th scope="col">Recurso</th>
								<th scope="col">Elemento de Despesa</th>
								<th scope="col">Valor</th>
								<th scope="col">Saldo</th>
							</tr>
						</thead>
					</table>
				</div>
			) : (
				<div className="ticketsNotFound">
					<FaTimesCircle size={30} />
					<p className="mt-2">Nenhuma ficha encontrada</p>
				</div>
			)}
		</>
	);
}
