import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import cep from 'cep-promise';
import { FaUserGraduate, FaEdit, FaSearch } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import SelectPais from '../../../components/Selects/SelectPais';
import SelectUf from '../../../components/Selects/SelectUf';
import SelectSexo from '../../../components/Selects/SelectSexo';
import SelectCorRaca from '../../../components/Selects/SelectCorRaca';
import SelectTipoTelefone from '../../../components/Selects/SelectTipoTelefone';
import SelectQuilombola from '../../../components/Selects/SelectQuilombola';
import Breadcrumb from '../../../components/Breadcrumb';
import InfoClassAluno from '../../../components/ClassStudent/InfoClassAluno';
import FormControlNecessidade from '../../../components/Forms/FormNecessidade/FormControlNecessidades';
import ReportCard from './ReportCard';
import Routes from '../../../routes/data/Routes';
import RouteParams from '../../../routes/data/RouteParams';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function FormStudents() {
	const { state } = useLocation();
	const userActualType = useSelector(
		reduxState => reduxState.userType.actualType
	);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.STUDENTS,
			namePage: 'Lista Alunos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação do aluno',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [lblSubject, setlblSubject] = useState('');
	const [lblTarget, setlblTarget] = useState('');
	const files = useRef(null);
	const [isLoadingCommunicated, setSendLoading] = useState(false);
	const [lblCommunicated, setlblCommunicated] = useState('');
	const [lblHabilitarAtualizacao, setlblHabilitarAtualizacao] = useState(
		true
	);
	const [lblFalecido, setlblFalecido] = useState('');
	const [lblDataFalecido, setlblDataFalecido] = useState('');
	const [certidaoAntiga, setcertidaoAntiga] = useState('');
	const [certidaoNova, setcertidaoNova] = useState('');
	const [siglaUfDocumentos, setSiglaUfDocumentos] = useState('');
	const [lblContatos, setlblContatos] = useState([]);
	const [lblNumeroRA, setlblNumeroRA] = useState(0);
	const [lblDigitoRA, setlblDigitoRA] = useState(0);
	const [lblUfRA, setlblUfRA] = useState('');
	const [lblNomeAluno, setlblNomeAluno] = useState('');
	const [lblNomeMae, setlblNomeMae] = useState('');
	const [lblNomePai, setlblNomePai] = useState('');
	const [lblNomeSocial, setlblNomeSocial] = useState('');
	const [lblNomeAfetivo, setlblNomeAfetivo] = useState('');
	const [lblDataNascimento, setlblDataNascimento] = useState('');
	const [lblIdCorRaca, setlblIdCorRaca] = useState('');
	const [lblIdSexo, setlblSexo] = useState('');
	const [lblBolsaFamilia, setlblBolsaFamilia] = useState('');
	const [lblQuilombola, setlblQuilombola] = useState('');
	const [lblEmail, setlblEmail] = useState('');
	const [lblIdNacionalidade, setlblIdNacionalidade] = useState(1);
	const [lblCodPaisOrigem, setlblCodPaisOrigem] = useState(76);
	const [lblDataEntradaPais, setlblDataEntradaPais] = useState('');
	const [lblNomeMunNascto, setlblNomeMunNascto] = useState('');
	const [lblIdUFMunNascto, setlblIdUFMunNascto] = useState('');
	const [lblNomeNecessidade, setlblNomeNecessidade] = useState([]);
	const [lblMobilidadeReduzida, setlblMobilidadeReduzida] = useState(0);
	const [
		lblIdTipoMobilidadeReduzida,
		setlblIdTipoMobilidadeReduzida,
	] = useState('');
	const [lblCuidador, setlblCuidador] = useState(0);
	const [lblIdTipoCuidador, setlblIdTipoCuidador] = useState('');
	const [lblProfSaude, setlblProfSaude] = useState(0);
	const [lblIdTipoProfSaude, setlblIdTipoProfSaude] = useState('');
	const [lblNenhum, setlblNenhum] = useState('');
	const [lblAuxilioLeitor, setlblAuxilioLeitor] = useState('');
	const [lblAuxilioTranscricao, setlblAuxilioTranscricao] = useState('');
	const [lblGuiaInterprete, setlblGuiaInterprete] = useState('');
	const [lblInterpreteLibras, setlblInterpreteLibras] = useState('');
	const [lblLeituraLabial, setlblLeituraLabial] = useState('');
	const [lblProvaBraile, setlblProvaBraile] = useState('');
	const [lblProvaVideoLibras, setlblProvaVideoLibras] = useState('');
	const [lblCdAudioDefVisual, setlblCdAudioDefVisual] = useState('');
	const [lblProvaLinguaPortuguesa, setlblProvaLinguaPortuguesa] = useState(
		''
	);
	const [lblIdFonteProva, setlblIdFonteProva] = useState(1);
	const [lblNumDoctoCivil, setlblNumDoctoCivil] = useState('');
	const [lblDigitoDoctoCivil, setlblDigitoDoctoCivil] = useState('');
	const [lblUFDoctoCivil, setlblUFDoctoCivil] = useState('');
	const [lblDataEmissaoDoctoCivil, setlblDataEmissaoDoctoCivil] = useState(
		''
	);
	const [lblNumNIS, setlblNumNIS] = useState('');
	const [lblCodigoINEP, setlblCodigoINEP] = useState('');
	const [lblCPF, setlblCPF] = useState('');
	const [
		lblIdJustificativaDocumentos,
		setlblIdJustificativaDocumentos,
	] = useState('');

	const [lblNumCertidao, setlblNumCertidao] = useState('');
	const [lblLivro, setlblLivro] = useState('');
	const [lblFolha, setlblFolha] = useState('');
	const [lblDistritoCertidao, setlblDistritoCertidao] = useState('');
	const [lblMunicipioComarca, setlblMunicipioComarca] = useState('');
	const [lblUFComarca, setlblUFComarca] = useState('');
	const [lblCertMatr01, setlblCertMatr01] = useState('');
	const [lblCertMatr02, setlblCertMatr02] = useState('');
	const [lblCertMatr03, setlblCertMatr03] = useState('');
	const [lblCertMatr04, setlblCertMatr04] = useState('');
	const [lblCertMatr05, setlblCertMatr05] = useState('');
	const [lblCertMatr06, setlblCertMatr06] = useState('');
	const [lblCertMatr07, setlblCertMatr07] = useState('');
	const [lblCertMatr08, setlblCertMatr08] = useState('');
	const [lblCertMatr09, setlblCertMatr09] = useState('');
	const [lblDataEmissaoCertidao, setlblDataEmissaoCertidao] = useState('');
	const [lblCep, setlblCep] = useState('');
	const [lblLogradouro, setlblLogradouro] = useState('');
	const [lblNumero, setlblNumero] = useState('');
	const [lblBairro, setlblBairro] = useState('');
	const [lblNomeCidade, setlblNomeCidade] = useState('');
	const [lblUFCidade, setlblUFCidade] = useState('');
	const [lblComplemento, setlblComplemento] = useState('');
	const [lblIdAreaLogradouro, setlblIdAreaLogradouro] = useState(1);
	const [
		lblIdLocalizacaoDiferenciada,
		setlblIdLocalizacaoDiferenciada,
	] = useState(4);
	const [lblIdTipoTelefone, setlblIdTipoTelefone] = useState('');
	const [lblDDD, setlblDDD] = useState('');
	const [lblTelefone, setlblTelefone] = useState('');
	const [lblComplementoTel, setlblComplementoTel] = useState('');
	const [lblSms, setlblSms] = useState('');
	const [lblNecessidadesEspeciais, setlblNecessidadesEspeciais] = useState(0);
	const [codEscola, setCodEscola] = useState(0);
	const [classeID, setClasseID] = useState(0);
	const [numClasse, setNumClasse] = useState(0);
	const [anoLetivo, setAnoLetivo] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [lblFase, setlblFase] = useState();
	const history = useHistory();
	const [cepNotFound, setCepNotFound] = useState(true);
	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	async function changePageTranfer(event) {
		event.preventDefault();
		try {
			const data = new FormData();
			data.append('lblNumeroRA', lblNumeroRA);
			data.append('lblDigitoRA', lblDigitoRA);
			data.append('lblUfRA', lblUfRA);
			data.append('lblAnoLetivo', anoLetivo);
			data.append('lblCodEscola', codEscola);
			data.append('lblFase', lblFase);
			data.append('lblNumClass', numClasse);
			await api
				.post('/aluno/perfil/atualiza/cancela/matricula/escola', data)
				.then(response => {
					swal('SUCESSO!', `${response.data.message}`, 'success');
					localStorage.removeItem('@dataStudents');
					localStorage.removeItem('@idClasse');
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.STUDENTS
					);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	async function handleCEP(e) {
		e.preventDefault();
		cep(lblCep)
			.then(dataPostCode => {
				setlblBairro(dataPostCode.neighborhood);
				setlblUFCidade(dataPostCode.state);
				setlblLogradouro(dataPostCode.street);
				setlblNomeCidade(dataPostCode.city);
				setCepNotFound(true);
			})
			.catch(error => {
				console.log(`error: ${error}`);
				setCepNotFound(false);
				swal('ATENÇÃO', 'Cep não encontrado', 'warning');
			});
	}

	useEffect(() => {
		if (state)
			// eslint-disable-next-line func-names
			(async function() {
				const {
					data: {
						error,
						message,
						data: {
							aluno,
							contatos,
							RaAluno,
							cidadeNascimento,
							necessidades,
							documentos,
							cuidador,
							mobilidade,
							profiSaude,
						},
					},
				} = await api.get(
					`/aluno/perfil/${
						state[
							RouteParams.LEVEL_05_ROUTES.PROFILE_STUDENT.USER_ID
						]
					}`
				);
				if (error === false) {
					if (typeof contatos !== 'undefined')
						setlblContatos(contatos);
					setIsLoading(false);
					setlblTarget(aluno.id_usuario);
					setCodEscola(aluno.cod_escola);
					setClasseID(aluno.id_classe);
					setNumClasse(aluno.num_classe);
					setAnoLetivo(aluno.ano_letivo);
					setlblNomeAluno(aluno.nome_usuario);
					setlblDataNascimento(aluno.data_nascimento);
					setlblNomeMae(aluno.nome_mae);
					setlblFalecido(aluno.falecido);
					setlblDataFalecido(aluno.data_falecimento);
					if (aluno.nome_pai === null || aluno.nome_pai === '')
						setlblNomePai('');
					else setlblNomePai(aluno.nome_pai);
					setlblIdCorRaca(aluno.id_cor_raca);
					setlblSexo(aluno.id_sexo);
					setlblBolsaFamilia(aluno.bolsa_familia);
					setlblQuilombola(aluno.quilombola);
					setlblIdNacionalidade(aluno.id_nacionalidade);
					setlblCodPaisOrigem(aluno.id_pais);
					setlblNomeMunNascto(cidadeNascimento.nome_cidade);
					setlblIdUFMunNascto(cidadeNascimento.id_uf);
					setlblNenhum(aluno.nenhum);
					setlblAuxilioLeitor(aluno.auxilio_leitor);
					setlblAuxilioTranscricao(aluno.auxilio_transcricao);
					setlblGuiaInterprete(aluno.guia_interprete);
					setlblInterpreteLibras(aluno.interprete_libras);
					setlblLeituraLabial(aluno.leitura_labial);
					setlblProvaBraile(aluno.prova_braile);
					setlblProvaVideoLibras(aluno.prova_video_libras);
					setlblCdAudioDefVisual(aluno.cd_audio_def_visual);
					setlblProvaLinguaPortuguesa(aluno.prova_lingua_portuguesa);
					setlblIdFonteProva(aluno.prova_ampliada_id_prova_ampliada);
					// Necessidades epeciais
					if (necessidades.length > 0) {
						setlblNomeNecessidade(necessidades);
						setlblNecessidadesEspeciais(2);
					} else setlblNecessidadesEspeciais(1);
					if (cuidador !== null) {
						setlblCuidador(cuidador.id_cuidador);
						setlblIdTipoCuidador(
							cuidador.tipo_duracao_id_tipo_duracao
						);
					} else {
						setlblCuidador('');
						setlblIdTipoCuidador('');
					}
					if (mobilidade !== null) {
						setlblMobilidadeReduzida(mobilidade.id_mobilidade);
						setlblIdTipoMobilidadeReduzida(
							mobilidade.tipo_duracao_id_tipo_duracao
						);
					} else {
						setlblMobilidadeReduzida('');
						setlblIdTipoMobilidadeReduzida('');
					}
					if (profiSaude !== null) {
						setlblProfSaude(profiSaude.id_profi_saude);
						setlblIdTipoProfSaude(
							profiSaude.tipo_duracao_id_tipo_duracao
						);
					} else {
						setlblProfSaude('');
						setlblIdTipoProfSaude('');
					}
					// Documentos
					if (documentos.id_justificativa_doc !== null)
						setlblIdJustificativaDocumentos(
							documentos.id_justificativa_doc
						);
					else setlblIdJustificativaDocumentos('');
					setlblCodigoINEP(documentos.cod_inep);
					if (
						documentos.num_documento_civil === null ||
						documentos.num_documento_civil === ''
					)
						setlblNumDoctoCivil('');
					else setlblNumDoctoCivil(documentos.num_documento_civil);
					if (
						documentos.sigla_uf === null ||
						documentos.sigla_uf === ''
					)
						setSiglaUfDocumentos('');
					else setSiglaUfDocumentos(documentos.sigla_uf);
					if (
						documentos.digito_documento_civil === null ||
						documentos.digito_documento_civil === ''
					)
						setlblDigitoDoctoCivil('');
					else
						setlblDigitoDoctoCivil(
							documentos.digito_documento_civil
						);
					if (
						documentos.uf_id_uf === null ||
						documentos.uf_id_uf === ''
					)
						setlblUFDoctoCivil('');
					else setlblUFDoctoCivil(documentos.id_uf);
					if (
						documentos.data_emissao_documento_civil === null ||
						documentos.data_emissao_documento_civil === ''
					)
						setlblDataEmissaoDoctoCivil('');
					else
						setlblDataEmissaoDoctoCivil(
							documentos.data_emissao_documento_civil
						);
					if (
						documentos.data_emissao_certidao === null ||
						documentos.data_emissao_certidao === ''
					)
						setlblDataEmissaoCertidao('');
					else
						setlblDataEmissaoCertidao(
							documentos.data_emissao_certidao
						);
					if (
						documentos.num_nis === null ||
						documentos.num_nis === ''
					)
						setlblNumNIS('');
					else setlblNumNIS(documentos.num_nis);
					if (documentos.cpf === null || documentos.cpf === '')
						setlblCPF('');
					else setlblCPF(documentos.cpf);

					setcertidaoAntiga(
						documentos.certidao_antiga_id_certidao_antiga
					);
					setcertidaoNova(documentos.certidao_nova_id_certidao_nova);
					// Certidao Antiga
					if (
						documentos.num_certidao === null ||
						documentos.num_certidao === 0
					)
						setlblNumCertidao('');
					else setlblNumCertidao(documentos.num_certidao);
					if (
						documentos.num_livro_reg === null ||
						documentos.num_livro_reg === 0
					)
						setlblLivro('');
					else setlblLivro(documentos.num_livro_reg);
					if (
						documentos.folha_reg_num === null ||
						documentos.folha_reg_num === 0
					)
						setlblFolha('');
					else setlblFolha(documentos.folha_reg_num);
					if (
						documentos.distrito_nasc === null ||
						documentos.distrito_nasc === 0
					)
						setlblDistritoCertidao('');
					else setlblDistritoCertidao(documentos.distrito_nasc);
					if (
						documentos.nome_num_comarca === null ||
						documentos.nome_num_comarca === 0
					)
						setlblMunicipioComarca('');
					else setlblMunicipioComarca(documentos.nome_num_comarca);
					if (
						documentos.uf_comarca === null ||
						documentos.uf_comarca === 0
					)
						setlblUFComarca('');
					else setlblUFComarca(documentos.uf_comarca);

					if (
						documentos.cert_matr01 === 0 ||
						documentos.cert_matr01 === null
					)
						setlblCertMatr01('');
					else setlblCertMatr01(documentos.cert_matr01);
					if (
						documentos.cert_matr02 === 0 ||
						documentos.cert_matr02 === null
					)
						setlblCertMatr02('');
					else setlblCertMatr02(documentos.cert_matr02);
					if (
						documentos.cert_matr03 === 0 ||
						documentos.cert_matr03 === null
					)
						setlblCertMatr03('');
					else setlblCertMatr03(documentos.cert_matr03);
					if (
						documentos.cert_matr04 === 0 ||
						documentos.cert_matr04 === null
					)
						setlblCertMatr04('');
					else setlblCertMatr04(documentos.cert_matr04);
					if (
						documentos.cert_matr05 === 0 ||
						documentos.cert_matr05 === null
					)
						setlblCertMatr05('');
					else setlblCertMatr05(documentos.cert_matr05);
					if (
						documentos.cert_matr06 === 0 ||
						documentos.cert_matr06 === null
					)
						setlblCertMatr06('');
					else setlblCertMatr06(documentos.cert_matr06);
					if (
						documentos.cert_matr07 === 0 ||
						documentos.cert_matr07 === null
					)
						setlblCertMatr07('');
					else setlblCertMatr07(documentos.cert_matr07);
					if (
						documentos.cert_matr08 === 0 ||
						documentos.cert_matr08 === null
					)
						setlblCertMatr08('');
					else setlblCertMatr08(documentos.cert_matr08);
					if (
						documentos.cert_matr09 === 0 ||
						documentos.cert_matr09 === null
					)
						setlblCertMatr09('');
					else setlblCertMatr09(documentos.cert_matr09);
					// Endereco
					setlblCep(aluno.cep);
					setlblLogradouro(aluno.logradouro);
					setlblNumero(aluno.numero);
					setlblBairro(aluno.bairro);
					setlblNomeCidade(aluno.nome_cidade);
					setlblUFCidade(aluno.sigla_uf);
					if (aluno.complemento !== null)
						setlblComplemento(aluno.complemento);
					else setlblComplemento('');
					if (aluno.area_logradouro_id_area_logradouro !== null)
						setlblIdAreaLogradouro(
							aluno.area_logradouro_id_area_logradouro
						);
					else setlblIdAreaLogradouro(0);
					if (aluno.localizacao_id_localizacao !== null)
						setlblIdLocalizacaoDiferenciada(
							aluno.localizacao_id_localizacao
						);
					else setlblIdLocalizacaoDiferenciada(4);
					setlblNumeroRA(RaAluno.num_ra);
					setlblDigitoRA(RaAluno.digito_ra);
					setlblUfRA(RaAluno.sigla_uf);
				} else {
					swal('ERRO!', `${message}`, 'error');
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.STUDENTS
					);
				}
			})();
		else {
			swal('Escolha um Aluno');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType]).STUDENTS
			);
		}
	}, []);

	async function handleComunicar(event) {
		event.preventDefault();
		setSendLoading(true);
		try {
			const data = new FormData();
			data.append('lblSubject', lblSubject);
			data.append('lblCommunicated', lblCommunicated);
			data.append('lblTipoUsuarioLogado', userActualType);
			data.append('lblTarget', lblTarget);
			Object.values(files.current.files).map(file =>
				data.append(Math.random(), file)
			);

			const response = await api.post('/comunicados/enviar/aluno', data);

			if (!('data' in response)) throw response;
			if (response.data.error) throw response.data;

			swal('SUCESSO!', response.data.message, 'success');
			setSendLoading(false);
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	async function handleAtualizar() {
		setlblHabilitarAtualizacao(lblHabilitarAtualizacao !== true);
	}

	async function desativarAluno() {
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CLAIM_DEATH_STUDENTS
		);
	}

	async function handleSubmit(event) {
		const id = state[RouteParams.LEVEL_05_ROUTES.PROFILE_STUDENT.USER_ID];
		setIsLoading(true);
		try {
			event.preventDefault();
			const data = new FormData();
			data.append('lblIdAluno', id);
			data.append('lblNumeroRA', lblNumeroRA);
			data.append('lblDigitoRA', lblDigitoRA);
			data.append('lblUfRA', lblUfRA);
			data.append('lblNomeAluno', lblNomeAluno);
			data.append('lblNomeMae', lblNomeMae);
			data.append('lblNomePai', lblNomePai);
			data.append('lblNomeSocial', lblNomeSocial);
			data.append('lblNomeAfetivo', lblNomeAfetivo);
			data.append('lblDataNascimento', lblDataNascimento);
			data.append('lblDataFalecido', lblDataFalecido);
			data.append('lblFalecido', lblFalecido);
			data.append('lblIdCorRaca', lblIdCorRaca);
			data.append('lblIdSexo', lblIdSexo);
			data.append('lblBolsaFamilia', lblBolsaFamilia);
			data.append('lblQuilombola', lblQuilombola);
			data.append('lblEmail', lblEmail);
			data.append('lblIdNacionalidade', lblIdNacionalidade);
			data.append('lblDataEntradaPais', lblDataEntradaPais);
			data.append('lblCodPaisOrigem', lblCodPaisOrigem);
			data.append('lblNomeMunNascto', lblNomeMunNascto);
			data.append('lblIdUFMunNascto', lblIdUFMunNascto);
			data.append('lblNomeNecessidade', lblNomeNecessidade);
			data.append('lblMobilidadeReduzida', lblMobilidadeReduzida);
			data.append(
				'lblIdTipoMobilidadeReduzida',
				lblIdTipoMobilidadeReduzida
			);
			data.append('lblCuidador', lblCuidador);
			data.append('lblIdTipoCuidador', lblIdTipoCuidador);
			data.append('lblProfSaude', lblProfSaude);
			data.append('lblIdTipoProfSaude', lblIdTipoProfSaude);
			data.append('lblNenhum', lblNenhum);
			data.append('lblAuxilioLeitor', lblAuxilioLeitor);
			data.append('lblAuxilioTranscricao', lblAuxilioTranscricao);
			data.append('lblGuiaInterprete', lblGuiaInterprete);
			data.append('lblInterpreteLibras', lblInterpreteLibras);
			data.append('lblLeituraLabial', lblLeituraLabial);
			data.append('lblProvaBraile', lblProvaBraile);
			data.append('lblProvaVideoLibras', lblProvaVideoLibras);
			data.append('lblCdAudioDefVisual', lblCdAudioDefVisual);
			data.append('lblProvaLinguaPortuguesa', lblProvaLinguaPortuguesa);
			data.append('lblIdFonteProva', lblIdFonteProva);
			data.append('lblNumDoctoCivil', lblNumDoctoCivil);
			data.append('lblDigitoDoctoCivil', lblDigitoDoctoCivil);
			data.append('lblUFDoctoCivil', lblUFDoctoCivil);
			data.append('lblDataEmissaoDoctoCivil', lblDataEmissaoDoctoCivil);
			data.append('lblDataEmissaoCertidao', lblDataEmissaoCertidao);
			data.append('lblNumNIS', lblNumNIS);
			data.append('lblCodigoINEP', lblCodigoINEP);
			data.append('lblCPF', lblCPF);
			data.append(
				'lblIdJustificativaDocumentos',
				lblIdJustificativaDocumentos
			);
			data.append('lblNumCertidao', lblNumCertidao);
			data.append('lblLivro', lblLivro);
			data.append('lblFolha', lblFolha);
			data.append('lblDistritoCertidao', lblDistritoCertidao);
			data.append('lblMunicipioComarca', lblMunicipioComarca);
			data.append('lblUFComarca', lblUFComarca);
			data.append('lblCertMatr01', lblCertMatr01);
			data.append('lblCertMatr02', lblCertMatr02);
			data.append('lblCertMatr03', lblCertMatr03);
			data.append('lblCertMatr04', lblCertMatr04);
			data.append('lblCertMatr05', lblCertMatr05);
			data.append('lblCertMatr06', lblCertMatr06);
			data.append('lblCertMatr07', lblCertMatr07);
			data.append('lblCertMatr08', lblCertMatr08);
			data.append('lblCertMatr09', lblCertMatr09);
			data.append('lblLogradouro', lblLogradouro);
			data.append('lblNumero', lblNumero);
			data.append('lblBairro', lblBairro);
			data.append('lblNomeCidade', lblNomeCidade);
			data.append('lblUFCidade', lblUFCidade);
			data.append('lblComplemento', lblComplemento);
			data.append('lblCep', lblCep);
			data.append('lblIdAreaLogradouro', lblIdAreaLogradouro);
			data.append(
				'lblIdLocalizacaoDiferenciada',
				lblIdLocalizacaoDiferenciada
			);
			data.append('lblIdTipoTelefone', lblIdTipoTelefone);
			data.append('lblDDD', lblDDD);
			data.append('lblTelefone', lblTelefone);
			data.append('lblComplementoTel', lblComplementoTel);
			data.append('lblSms', lblSms);
			await api.post('/aluno/perfil/atualiza', data).then(response => {
				if (response.data.error) throw response.data.message;
				swal('SUCESSO!', `${response.data.message}`, 'success');
			});
		} catch (error) {
			swal('Ops!', `${error}`, 'warning');
		}
		setIsLoading(false);
	}

	async function handleGenerateRegistration() {
		setIsLoading(true);
		const students = {
			RaAluno: lblNumeroRA,
			lblDigitoRa: lblDigitoRA,
			lblSiglaUfra: lblUfRA,
			lblDataNasimento: lblDataNascimento,
		};

		const { data } = await api.post('/aluno/declara-matricula', students);
		if (data.error === false)
			window.open(data.data, '_blank', 'noopener noreferrer');
		else swal({ icon: 'warning', text: 'Erro ao gerar matricula' });
		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div>
					<div className="card mt-3">
						<div className="card-header">
							<div className="row">
								<div className="col-12 col-md-11 col-lg-11 form-group">
									<h5>
										<b>Sobre o Aluno</b>
									</h5>
								</div>
								<div className="col-12 col-md-1 col-lg-1 form-group">
									{userActualType === 5 ? (
										<button
											className="buttonEdit"
											type="button"
										>
											<FaEdit onClick={handleAtualizar} />
										</button>
									) : null}
								</div>
							</div>
							{lblHabilitarAtualizacao === false && (
								<div className="row">
									<div className="col-12 col-md-9 col-lg-9 form-group">
										<Tooltip
											title="Alegar falecimento do aluno"
											placement="top-start"
										>
											<button
												className="btn btn-danger"
												type="button"
												onClick={desativarAluno}
											>
												Falecimento do Aluno
											</button>
										</Tooltip>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<Tooltip
											title="Tranferência de Aluno para Outra Escola"
											placement="top-start"
										>
											<button
												className="btn btn-primary"
												type="submit"
												data-toggle="modal"
												data-target="#modalCancelarMatricula"
											>
												Cancelar Matrícula
											</button>
										</Tooltip>
									</div>
								</div>
							)}
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<h1>
										<FaUserGraduate />
									</h1>
								</div>
								<div className="mt-4 col-12 col-md-11 col-lg-11 form-group">
									<div>
										<p className="textoCapitalize">
											{lblNomeAluno.charAt(0) +
												lblNomeAluno
													.slice(1)
													.toLowerCase()}
										</p>
									</div>
								</div>
							</div>
							<div className="row d-flex justify-content-between container">
								<Tooltip
									title="Escrever um Comunicado para este Aluno"
									placement="top-start"
								>
									<button
										className="btn btn-primary"
										type="submit"
										data-toggle="modal"
										data-target="#modalComunicar"
									>
										Comunicado
									</button>
								</Tooltip>

								<Tooltip
									title="Gerar arquivo em pdf da matricula do aluno"
									placement="top-start"
								>
									<button
										className="btn btn-primary"
										type="submit"
										onClick={handleGenerateRegistration}
									>
										Gerar Matricula
									</button>
								</Tooltip>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<p className="bolLabel">
										<b>RA:</b>
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>{lblNumeroRA}</p>
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p className="bolLabel">
										<b>Digito RA:</b>
									</p>
								</div>
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<p>{lblDigitoRA}</p>
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p className="bolLabel">
										<b>UF RA:</b>
									</p>
								</div>
								<div className="col-12 col-md-2 col-lg-2 form-group">
									<p>{lblUfRA}</p>
								</div>
							</div>
						</div>
					</div>
					<InfoClassAluno idStudent={lblTarget} />
					<ReportCard idStudent={lblTarget} classeID={classeID} />
					<form onSubmit={handleSubmit}>
						<div className="card mt-3">
							<div className="card-header">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<h4>Dados Básicos</h4>
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome Aluno</b>
										</p>
										<input
											onInput={toInputUppercase}
											disabled
											required
											name="lblNomeAluno"
											id="lblNomeAluno"
											className="form-control"
											placeholder="Digite o nome do Aluno"
											value={lblNomeAluno}
											onChange={event =>
												setlblNomeAluno(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Data Nascimento</b>
										</p>
										<InputMask
											mask="99/99/9999"
											disabled
											name="lblDataNascimento"
											id="lblDataNascimento"
											className="form-control"
											placeholder="Data Nascimento"
											value={lblDataNascimento}
											onChange={event =>
												setlblDataNascimento(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome Mãe</b>
										</p>
										<input
											disabled
											onInput={toInputUppercase}
											name="lblNomeMae"
											id="lblNomeMae"
											className="form-control"
											placeholder="Digite o nome da Mãe"
											value={lblNomeMae}
											onChange={event =>
												setlblNomeMae(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome Pai</b>
										</p>
										<input
											onInput={toInputUppercase}
											disabled={lblHabilitarAtualizacao}
											name="lblNomePai"
											id="lblNomePai"
											className="form-control"
											placeholder="Digite o nome do Pai"
											value={lblNomePai}
											onChange={event =>
												setlblNomePai(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome Social</b>
										</p>
										<input
											onInput={toInputUppercase}
											disabled={lblHabilitarAtualizacao}
											name="lblNomeSocial"
											id="lblNomeSocial"
											className="form-control"
											placeholder="Digite o nome Social do Aluno"
											value={lblNomeSocial}
											onChange={event =>
												setlblNomeSocial(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>
											<b>Nome Afetivo</b>
										</p>
										<input
											onInput={toInputUppercase}
											disabled={lblHabilitarAtualizacao}
											name="lblNomeAfetivo"
											id="lblNomeAfetivo"
											className="form-control"
											placeholder="Digite o nome Afetivo"
											value={lblNomeAfetivo}
											onChange={event =>
												setlblNomeAfetivo(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Cor/Raça:</b>
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<SelectCorRaca
											setCorRaca={setlblIdCorRaca}
											valueSelect={lblIdCorRaca}
											disabled={lblHabilitarAtualizacao}
										/>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Genero:</b>
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<SelectSexo
											setSexo={setlblSexo}
											valueSelect={lblIdSexo}
											disabled={lblHabilitarAtualizacao}
										/>
									</div>
								</div>
								<div className="row">
									{/* <div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Bolsa Familia:</b>
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<SelectBolsaFamilia
											setBolsaFamilia={setlblBolsaFamilia}
											valueSelect={lblBolsaFamilia}
											disabled={lblHabilitarAtualizacao}
										/>
									</div> */}
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Quilombola:</b>
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<SelectQuilombola
											setQuilombola={setlblQuilombola}
											valueSelect={lblQuilombola}
											disabled={lblHabilitarAtualizacao}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Nacionalidade:</b>
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<select
											required
											disabled
											className="form-control"
											name="lblIdNacionalidade"
											id="lblIdNacionalidade"
											value={lblIdNacionalidade}
											onChange={event =>
												setlblIdNacionalidade(
													event.target.value
												)
											}
										>
											<option value="1">
												Brasileiro
											</option>
											<option value="2">
												Estrangeiro
											</option>
											<option value="3">
												Brasileiro Nascido no Exterior
											</option>
										</select>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<p>
											<b>Necessidades Especiais:</b>
										</p>
									</div>
									<div className="col-12 col-md-3 col-lg-3 form-group">
										<select
											required
											value={lblNecessidadesEspeciais}
											disabled={lblHabilitarAtualizacao}
											className="form-control"
											name="lblNecessidadesEspeciais"
											id="lblNecessidadesEspeciais"
											onChange={event =>
												setlblNecessidadesEspeciais(
													event.target.value
												)
											}
										>
											<option value={1}>Não</option>
											<option value={2}>Sim</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						{lblIdNacionalidade !== 1 && (
							<div>
								<div className="card mt-3">
									<div className="card-header col-12 col-md-12 col-lg-12 form-group">
										<h4>Nacionalidade</h4>
										<hr />
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													<b>País</b>
												</p>
												<SelectPais
													value={lblCodPaisOrigem}
													setCodPais={
														setlblCodPaisOrigem
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>
													<b>
														Data de Entrada no País
													</b>
												</p>
												<input
													name="lblDataEntradaPais"
													id="lblDataEntradaPais"
													className="form-control"
													placeholder="Data de Entrada no País"
													value={lblDataEntradaPais}
													onChange={event =>
														setlblDataEntradaPais(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Município de Nascimento</b>
								</p>
							</div>
							<div className="row card-body">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Cidade</b>
									</p>
									<input
										required
										disabled={lblHabilitarAtualizacao}
										onInput={toInputUppercase}
										name="lblNomeMunNascto"
										id="lblNomeMunNascto"
										className="form-control"
										placeholder="Digite o nome do Município "
										value={lblNomeMunNascto}
										onChange={event =>
											setlblNomeMunNascto(
												event.target.value
											)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Estado</b>
									</p>
									<SelectUf
										setUf={setlblIdUFMunNascto}
										valueSelect={lblIdUFMunNascto}
										disabled={lblHabilitarAtualizacao}
									/>
								</div>
							</div>
						</div>

						{lblNecessidadesEspeciais === 2 && (
							<div className="card mt-3">
								<div className="row">
									<div className="card-header col-12 col-md-12 col-lg-12 form-group">
										<p>
											<b>Necessidades Especiais</b>
										</p>
									</div>
								</div>
								<div className="card-body">
									<FormControlNecessidade
										habilitar={lblHabilitarAtualizacao}
										lblNomeNecessidade={lblNomeNecessidade}
										setlblNomeNecessidade={
											setlblNomeNecessidade
										}
									/>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Mobilidade Reduziada:</b>
											</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<select
												className="form-control"
												name="lblMobilidadeReduzida"
												id="lblMobilidadeReduzida"
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblMobilidadeReduzida(
														event.target.value
													)
												}
											>
												<option value="0">Não</option>
												<option value="1">Sim</option>
											</select>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Tipo de Duração:</b>
											</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<select
												className="form-control"
												name="lblIdTipoMobilidadeReduzida"
												id="lblIdTipoMobilidadeReduzida"
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblIdTipoMobilidadeReduzida(
														event.target.value
													)
												}
											>
												<option>N/P</option>
												<option value="1">
													Permanente
												</option>
												<option value="2">
													Temporario
												</option>
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Cuidador:</b>
											</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<select
												disabled={
													lblHabilitarAtualizacao
												}
												className="form-control"
												name="lblCuidador"
												id="lblCuidador"
												value={lblCuidador}
												onChange={event =>
													setlblCuidador(
														event.target.value
													)
												}
											>
												<option value="0">Não</option>
												<option value="1">Sim</option>
											</select>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Tipo de Duração:</b>
											</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<select
												className="form-control"
												name="lblIdTipoCuidador"
												id="lblIdTipoCuidador"
												value={lblIdTipoCuidador}
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblIdTipoCuidador(
														event.target.value
													)
												}
											>
												<option>N/P</option>
												<option value="1">
													Permanente
												</option>
												<option value="2">
													Temporario
												</option>
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Profissional da Saude:</b>
											</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<select
												className="form-control"
												name="lblProfSaude"
												id="lblProfSaude"
												value={lblProfSaude}
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblProfSaude(
														event.target.value
													)
												}
											>
												<option value="0">Não</option>
												<option value="1">Sim</option>
											</select>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>
												<b>Tipo de Duração:</b>
											</p>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<select
												className="form-control"
												name="lblIdTipoProfSaude"
												id="lblIdTipoProfSaude"
												value={lblIdTipoProfSaude}
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblIdTipoProfSaude(
														event.target.value
													)
												}
											>
												<option>N/P</option>
												<option value="1">
													Permanente
												</option>
												<option value="2">
													Temporario
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Recurso de Avaliação</b>
								</p>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Nenhum:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											className="form-control"
											name="lblNenhum"
											id="lblNenhum"
											value={lblNenhum}
											onChange={event =>
												setlblNenhum(event.target.value)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Aux. Leitor:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											className="form-control"
											name="lblAuxilioLeitor"
											id="lblAuxilioLeitor"
											value={lblAuxilioLeitor}
											onChange={event =>
												setlblAuxilioLeitor(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Aux. Transcrição:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											className="form-control"
											name="lblAuxilioTranscricao"
											id="lblAuxilioTranscricao"
											value={lblAuxilioTranscricao}
											onChange={event =>
												setlblAuxilioTranscricao(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Guia Intérprete</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblGuiaInterprete}
											className="form-control"
											name="lblGuiaInterprete"
											id="lblGuiaInterprete"
											onChange={event =>
												setlblGuiaInterprete(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Intérprete Libras:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblInterpreteLibras}
											className="form-control"
											name="lblInterpreteLibras"
											id="lblInterpreteLibras"
											onChange={event =>
												setlblInterpreteLibras(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Leitura Labial:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblLeituraLabial}
											className="form-control"
											name="lblLeituraLabial"
											id="lblLeituraLabial"
											onChange={event =>
												setlblLeituraLabial(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Prova Braile:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblProvaBraile}
											className="form-control"
											name="lblProvaBraile"
											id="lblProvaBraile"
											onChange={event =>
												setlblProvaBraile(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Prova Video Libras:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblProvaVideoLibras}
											className="form-control"
											name="lblProvaVideoLibras"
											id="lblProvaVideoLibras"
											onChange={event =>
												setlblProvaVideoLibras(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Cd Audio-Visual:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblCdAudioDefVisual}
											className="form-control"
											name="lblCdAudioDefVisual"
											id="lblCdAudioDefVisual"
											onChange={event =>
												setlblCdAudioDefVisual(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Prova de Lingua Portuguesa:</b>
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblProvaLinguaPortuguesa}
											className="form-control"
											name="lblProvaLinguaPortuguesa"
											id="lblProvaLinguaPortuguesa"
											onChange={event =>
												setlblProvaLinguaPortuguesa(
													event.target.value
												)
											}
										>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>
											<b>Fonte Prova:</b>
										</p>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<select
											disabled={lblHabilitarAtualizacao}
											value={lblIdFonteProva}
											className="form-control"
											name="lblIdFonteProva"
											id="lblIdFonteProva"
											onChange={event =>
												setlblIdFonteProva(
													event.target.value
												)
											}
										>
											<option value="1">N/P</option>
											<option value="2">16</option>
											<option value="3">20</option>
											<option value="4">24</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Documentos</b>
								</p>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Código do INEP/MEC</b>
										</p>
										<input
											maxLength="12"
											minLength="12"
											name="lblCodigoINEP"
											id="lblCodigoINEP"
											className="form-control"
											placeholder="INEP/MEC"
											disabled
											value={lblCodigoINEP}
											onChange={event =>
												setlblCodigoINEP(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-12 col-lg-12 form-group">
										<select
											disabled={lblHabilitarAtualizacao}
											value={lblIdJustificativaDocumentos}
											className="form-control"
											name="lblIdJustificativaDocumentos"
											id="lblIdJustificativaDocumentos"
											onChange={event =>
												setlblIdJustificativaDocumentos(
													event.target.value
												)
											}
										>
											<option>Não Informado</option>
											<option value="1">
												O (A) aluno não possui os
												documentos pessoais solicitados
											</option>
											<option value="2">
												A Escola não dispõe ou não
												recebeu os documentos pessoais
												do (a) aluno (a)
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="card mt-3">
								<div className="card-header col-12 col-md-12 col-lg-12 form-group">
									<p>
										<b>Documentos Básicos</b>
									</p>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Número de Documento Civil</b>
											</p>
											<input
												maxLength="14"
												minLength="14"
												name="lblNumDoctoCivil"
												id="lblNumDoctoCivil"
												className="form-control"
												placeholder="RG ou RNE"
												disabled={
													lblHabilitarAtualizacao
												}
												value={lblNumDoctoCivil}
												onChange={event =>
													setlblNumDoctoCivil(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Digito do documento civil</b>
											</p>
											<input
												maxLength="2"
												minLength="2"
												name="lblDigitoDoctoCivil"
												id="lblDigitoDoctoCivil"
												className="form-control"
												placeholder="Obrigatório caso seja RG"
												disabled={
													lblHabilitarAtualizacao
												}
												value={lblDigitoDoctoCivil}
												onChange={event =>
													setlblDigitoDoctoCivil(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Estado do Documento</b>
											</p>
											{!siglaUfDocumentos &&
												lblHabilitarAtualizacao ===
													true && (
													<p className="mt-3">
														Sem Estado
													</p>
												)}
											{siglaUfDocumentos &&
												lblNumDoctoCivil && (
													<p>{siglaUfDocumentos}</p>
												)}
											{siglaUfDocumentos === '' &&
												lblHabilitarAtualizacao ===
													false && (
													<SelectUf
														setUf={
															setlblUFDoctoCivil
														}
													/>
												)}
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>
													Número de Identificação
													social
												</b>
											</p>
											<input
												maxLength="11"
												minLength="11"
												name="lblNumNIS"
												id="lblNumNIS"
												className="form-control"
												placeholder="Número NIS"
												disabled={
													lblHabilitarAtualizacao
												}
												value={lblNumNIS}
												onChange={event =>
													setlblNumNIS(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>CPF do Aluno</b>
											</p>
											<input
												maxLength="11"
												minLength="11"
												name="lblCPF"
												id="lblCPF"
												className="form-control"
												placeholder="CPF do Aluno"
												value={lblCPF}
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblCPF(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>
													Data da Emissão do Documento
												</b>
											</p>
											<InputMask
												mask="99/99/9999"
												name="lblDataEmissaoDoctoCivil"
												id="lblDataEmissaoDoctoCivil"
												className="form-control"
												placeholder="Data Emissão"
												value={lblDataEmissaoDoctoCivil}
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblDataEmissaoDoctoCivil(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{certidaoAntiga && (
							<div>
								<div className="card mt-3">
									<div className="card-header col-12 col-md-12 col-lg-12 form-group">
										<p>
											<b>Certidão Antiga</b>
										</p>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>
														Nº. da Certidão de
														Nascimento
													</b>
												</p>
												<input
													name="lblNumCertidao"
													id="lblNumCertidao"
													className="form-control"
													placeholder="Nº. Certidão de Nascimento (999999)"
													value={lblNumCertidao}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblNumCertidao(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>
														Número do livro de
														Registro
													</b>
												</p>
												<input
													maxLength="4"
													minLength="4"
													name="lblLivro"
													id="lblLivro"
													className="form-control"
													placeholder="Nº. do livro de registro (XXXX)"
													value={lblLivro}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblLivro(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>
														Folha do livro de
														Registro
													</b>
												</p>
												<input
													maxLength="4"
													minLength="4"
													name="lblFolha"
													id="lblFolha"
													className="form-control"
													placeholder="Folha do livro de registro (XXXXXX)"
													value={lblFolha}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblFolha(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>
														Nome do Município do
														Cartório
													</b>
												</p>
												<input
													onInput={toInputUppercase}
													name="lblMunicipioComarca"
													id="lblMunicipioComarca"
													className="form-control"
													placeholder="Ex: Cruzeiro, Lorena ..."
													value={lblMunicipioComarca}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblMunicipioComarca(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>
														Distrito do Município do
														Cartório
													</b>
												</p>
												<input
													onInput={toInputUppercase}
													name="lblDistritoCertidao"
													id="lblDistritoCertidao"
													className="form-control"
													placeholder="Ex: Cruzeiro, Lorena ..."
													value={lblDistritoCertidao}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblDistritoCertidao(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>UF do Cartório</b>
												</p>
												<input
													maxLength="2"
													minLength="2"
													name="lblUFComarca"
													id="lblUFComarca"
													className="form-control"
													placeholder="Ex: SP, RJ ..."
													value={lblUFComarca}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblUFComarca(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Data da Certidão</b>
												</p>
												<InputMask
													mask="99/99/9999"
													name="lblDataEmissaoCertidao"
													id="lblDataEmissaoCertidao"
													className="form-control"
													placeholder="DD/MM/AAAA"
													value={
														lblDataEmissaoCertidao
													}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblDataEmissaoCertidao(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{certidaoNova !== null && certidaoNova !== 2 && (
							<div>
								<div className="card mt-3">
									<div className="card-header col-12 col-md-12 col-lg-12 form-group">
										<p>
											<b>Certidão Nova</b>
										</p>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Código do Cartório</b>
												</p>
												<input
													maxLength="6"
													minLength="6"
													name="lblCertMatr01"
													id="lblCertMatr01"
													className="form-control"
													placeholder="Código do Cartório (999999)"
													value={lblCertMatr01}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr01(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Código do acervo</b>
												</p>
												<input
													maxLength="2"
													minLength="2"
													name="lblCertMatr02"
													id="lblCertMatr02"
													className="form-control"
													placeholder="Código do acervo (99)"
													value={lblCertMatr02}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr02(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Serviço de Reg. Civil</b>
												</p>
												<input
													maxLength="4"
													minLength="4"
													name="lblCertMatr03"
													id="lblCertMatr03"
													className="form-control"
													placeholder="Ano do registro(9999)"
													value={lblCertMatr03}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr03(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Ano do registro</b>
												</p>
												<input
													maxLength="1"
													name="lblCertMatr04"
													id="lblCertMatr04"
													className="form-control"
													placeholder="Ano do registro (9)"
													value={lblCertMatr04}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr04(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Tipo do Livro</b>
												</p>
												<input
													maxLength="6"
													minLength="6"
													name="lblCertMatr05"
													id="lblCertMatr05"
													className="form-control"
													placeholder="Tipo do Livro (9)"
													value={lblCertMatr05}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr05(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Número do Livro</b>
												</p>
												<input
													maxLength="5"
													name="lblCertMatr06"
													id="lblCertMatr06"
													className="form-control"
													placeholder="Número do Livro (99999)"
													value={lblCertMatr06}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr06(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Número da Folha</b>
												</p>
												<input
													maxLength="3"
													name="lblCertMatr07"
													id="lblCertMatr07"
													className="form-control"
													placeholder="Número da Folha (999)"
													value={lblCertMatr07}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr07(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Número do Termo</b>
												</p>
												<input
													maxLength="7"
													minLength="7"
													name="lblCertMatr08"
													id="lblCertMatr08"
													className="form-control"
													placeholder="Número do Termo (9999999)"
													value={lblCertMatr08}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr08(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Dígito de Controle</b>
												</p>
												<input
													maxLength="2"
													name="lblCertMatr09"
													id="lblCertMatr09"
													className="form-control"
													placeholder="Dígito de Controle (99)"
													value={lblCertMatr09}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblCertMatr09(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>
													<b>Data da Certidão</b>
												</p>
												<InputMask
													mask="99/99/9999"
													name="lblDataEmissaoCertidao"
													id="lblDataEmissaoCertidao"
													className="form-control"
													placeholder="DD/MM/AAAA"
													value={
														lblDataEmissaoCertidao
													}
													disabled={
														lblHabilitarAtualizacao
													}
													onChange={event =>
														setlblDataEmissaoCertidao(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Endereço</b>
								</p>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Cep</b>
										</p>
										<div className="input-group mb-3">
											<InputMask
												mask="99999-999"
												required
												name="lblCep"
												id="lblCep"
												className="form-control"
												placeholder=""
												value={lblCep}
												disabled={
													lblHabilitarAtualizacao
												}
												onChange={event =>
													setlblCep(
														event.target.value
													)
												}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-success btn-block"
													type="button"
													onClick={handleCEP}
													disabled={
														lblHabilitarAtualizacao
													}
												>
													<FaSearch />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Logradouro</b>
										</p>
										<input
											onInput={toInputUppercase}
											required
											disabled={cepNotFound}
											name="lblLogradouro"
											id="lblLogradouro"
											className="form-control"
											placeholder="Digite o Logradouro"
											value={lblLogradouro}
											onChange={event =>
												setlblLogradouro(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Número</b>
										</p>
										<input
											required
											name="lblNumero"
											id="lblNumero"
											className="form-control"
											placeholder="Ex: SN, 123"
											value={lblNumero}
											disabled={lblHabilitarAtualizacao}
											onChange={event =>
												setlblNumero(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Complemento</b>
										</p>
										<input
											required
											disabled={lblHabilitarAtualizacao}
											onInput={toInputUppercase}
											name="lblComplemento"
											id="lblComplemento"
											className="form-control"
											placeholder="Ex: Casa, Apto..."
											value={lblComplemento}
											onChange={event =>
												setlblComplemento(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Bairro</b>
										</p>
										<input
											required
											disabled={cepNotFound}
											onInput={toInputUppercase}
											name="lblBairro"
											id="lblBairro"
											className="form-control"
											placeholder="Digite o Bairro"
											value={lblBairro}
											onChange={event =>
												setlblBairro(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Cidade</b>
										</p>
										<input
											required
											disabled={cepNotFound}
											onInput={toInputUppercase}
											name="lblNomeCidade"
											id="lblNomeCidade"
											className="form-control"
											placeholder="Digite a Cidade"
											value={lblNomeCidade}
											onChange={event =>
												setlblNomeCidade(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>UF</b>
										</p>
										<input
											required
											disabled={cepNotFound}
											maxLength="2"
											name="lblUFCidade"
											id="lblUFCidade"
											className="form-control"
											placeholder="Digite o UF"
											value={lblUFCidade}
											onChange={event =>
												setlblUFCidade(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Área do logradouro</b>
										</p>
										<select
											className="form-control"
											name="lblIdAreaLogradouro"
											id="lblIdAreaLogradouro"
											disabled={lblHabilitarAtualizacao}
											value={lblIdAreaLogradouro}
											onChange={event =>
												setlblIdAreaLogradouro(
													event.target.value
												)
											}
										>
											<option value="1">Urbana</option>
											<option value="2">Rural</option>
										</select>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Localização Diferenciada</b>
										</p>
										<select
											required
											disabled={lblHabilitarAtualizacao}
											value={lblIdLocalizacaoDiferenciada}
											className="form-control"
											name="lblIdLocalizacaoDiferenciada"
											id="lblIdLocalizacaoDiferenciada"
											onChange={event =>
												setlblIdLocalizacaoDiferenciada(
													event.target.value
												)
											}
										>
											<option value="1">
												Área de assentamento
											</option>
											<option value="2">
												Terra indígena
											</option>
											<option value="3">
												Área onde se localizada em
												Comunidade remanescente de
												Quilombos
											</option>
											<option value="4">
												Não está localizado em área de
												localização diferenciada
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Contato</b>
								</p>
							</div>
							<div className="card-body">
								<div className="row">
									{lblHabilitarAtualizacao === true && (
										<div className="col-12 col-md-12 col-lg-12 form-group">
											{lblContatos.map(contato => (
												<div
													className="contato row"
													key={contato.id_contato}
												>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p className="bolLabel">
															Telefone:
														</p>
													</div>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p>
															(0
															{contato.ddd_numero}
															)
															{contato.tel_numero}
														</p>
													</div>
												</div>
											))}
										</div>
									)}
									{lblHabilitarAtualizacao === false && (
										<div className="col-12 col-md-12 col-lg-12 form-group">
											{lblContatos !== [] && (
												<div className="row">
													<div className="col-12 col-md-4 col-lg-4 form-group">
														<p>
															<b>
																Tipos de
																Telefone
															</b>
														</p>
														<SelectTipoTelefone
															setTipoTelefone={
																setlblIdTipoTelefone
															}
														/>
													</div>
													<div className="col-12 col-md-2 col-lg-2 form-group">
														<p>
															<b>DDD</b>
														</p>
														<input
															minLength="2"
															maxLength="2"
															name="lblDDD"
															id="lblDDD"
															className="form-control"
															placeholder="00"
															onChange={event =>
																setlblDDD(
																	event.target
																		.value
																)
															}
														/>
													</div>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p>
															<b>Telefone</b>
														</p>
														<input
															minLength="8"
															maxLength="9"
															name="lblTelefone"
															id="lblTelefone"
															className="form-control"
															placeholder="11111111"
															onChange={event =>
																setlblTelefone(
																	event.target
																		.value
																)
															}
														/>
													</div>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p>
															<b>
																Deixar Recados?
															</b>
														</p>
														<select
															className="form-control"
															name="lblSms"
															id="lblSms"
															onChange={event =>
																setlblSms(
																	event.target
																		.value
																)
															}
														>
															<option>
																Recados
															</option>
															<option value="NÃO">
																Não
															</option>
															<option value="SIM">
																Sim
															</option>
														</select>
													</div>
													<div className="col-12 col-md-6 col-lg-6 form-group">
														<p>
															<b>Complemento</b>
														</p>
														<input
															name="lblComplementoTel"
															id="lblComplementoTel"
															className="form-control"
															placeholder="Mais Informação"
															onChange={event =>
																setlblComplementoTel(
																	event.target
																		.value
																)
															}
														/>
													</div>
												</div>
											)}
											{lblContatos.map(contato => (
												<div className="row">
													<div className="col-12 col-md-4 col-lg-4 form-group">
														<p>
															<b>
																Tipos de
																Telefone
															</b>
														</p>
														<SelectTipoTelefone
															value={
																contato.tipo_telefone
															}
															setTipoTelefone={
																setlblIdTipoTelefone
															}
														/>
													</div>
													<div className="col-12 col-md-2 col-lg-2 form-group">
														<p>
															<b>DDD</b>
														</p>
														<input
															minLength="2"
															maxLength="2"
															name="lblDDD"
															id="lblDDD"
															className="form-control"
															placeholder="00"
															value={
																contato.ddd_numero
															}
															onChange={event =>
																setlblDDD(
																	event.target
																		.value
																)
															}
														/>
													</div>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p>
															<b>Telefone</b>
														</p>
														<input
															minLength="8"
															maxLength="9"
															name="lblTelefone"
															id="lblTelefone"
															className="form-control"
															placeholder="11111111"
															value={
																contato.tel_numero
															}
															onChange={event =>
																setlblTelefone(
																	event.target
																		.value
																)
															}
														/>
													</div>
													<div className="col-12 col-md-3 col-lg-3 form-group">
														<p>
															<b>
																Deixar Recados?
															</b>
														</p>
														<select
															className="form-control"
															name="lblSms"
															id="lblSms"
															value={contato.sms}
															onChange={event =>
																setlblSms(
																	event.target
																		.value
																)
															}
														>
															<option>
																Recados
															</option>
															<option value="NÃO">
																Não
															</option>
															<option value="SIM">
																Sim
															</option>
														</select>
													</div>
													<div className="col-12 col-md-6 col-lg-6 form-group">
														<p>
															<b>
																Complemento
																Telefone
															</b>
														</p>
														<input
															name="lblComplementoTel"
															id="lblComplementoTel"
															className="form-control"
															placeholder="Mais Informação"
															value={
																contato.complemento
															}
															onChange={event =>
																setlblComplementoTel(
																	event.target
																		.value
																)
															}
														/>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
								<div className="row">
									<div className="col-12 col-md-12 col-lg-12 form-group">
										<p>
											<b>E-mail</b>
										</p>
										<input
											type="email"
											name="lblEmail"
											id="lblEmail"
											className="form-control"
											placeholder="Digite o E-mail"
											value={lblEmail}
											disabled={lblHabilitarAtualizacao}
											onChange={event =>
												setlblEmail(event.target.value)
											}
										/>
									</div>
								</div>
								{lblHabilitarAtualizacao === false && (
									<button
										className="btn btn-primary"
										type="submit"
									>
										Atualizar
									</button>
								)}
							</div>
						</div>
					</form>
				</div>
			)}
			{/* <!-- Modal --> */}
			<div
				className="modal fade"
				id="modalCancelarMatricula"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Cancelar Matrícula
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Fechar"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div>
								<p>
									Você está para cancelar a matrícula do
									Aluno:{' '}
									<b>
										{lblNomeAluno.charAt(0) +
											lblNomeAluno
												.slice(1)
												.toLowerCase()}{' '}
									</b>
									, da Escola, isso siginifica que aluno
									deixará de ser matriculado nesta escola.
								</p>
								<p>
									Caso tenha certeza desta ação, Por Favor
									confirme o usuário.
								</p>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>Tipo de Transferência:</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<select
										required
										className="form-control"
										name="lblFase"
										id="lblFase"
										value={lblFase}
										onChange={event =>
											setlblFase(event.target.value)
										}
									>
										<option> Escolha uma Opção</option>
										<option value="0">
											Inscrição de Alunos por
											Transferência
										</option>
										<option value="8">
											Inscrição de Alunos por Deslocamento
										</option>
										<option value="9">
											Inscrição por Intenção de
											Transferência
										</option>
									</select>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								id="btnTransferirCloser"
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
							<button
								id="btnTransferir"
								type="button"
								className="btn btn-warning"
								onClick={changePageTranfer}
								data-dismiss="modal"
							>
								Cacelar Matrícula
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Modal --> */}
			<div
				className="modal fade"
				id="modalComunicar"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<form onSubmit={handleComunicar}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							{isLoadingCommunicated === true && (
								<div className="lds-dual-ring" />
							)}
							{isLoadingCommunicated === false && (
								<>
									<div className="modal-header">
										<h5
											className="modal-title"
											id="exampleModalLabel"
										>
											Comunicar Aluno
										</h5>
										<button
											type="button"
											className="close"
											data-dismiss="modal"
											aria-label="Fechar"
										>
											<span aria-hidden="true">
												&times;
											</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>Assunto</p>
												<input
													type="text"
													max="60"
													name="lblSubject"
													id="lblSubject"
													className="form-control"
													placeholder="Assunto"
													value={lblSubject}
													required
													onChange={event =>
														setlblSubject(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12 form-group">
												<p>Comunicado</p>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12 form-group">
												<textarea
													required
													className="form-control"
													value={lblCommunicated}
													onChange={event =>
														setlblCommunicated(
															event.target.value
														)
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12 form-group">
												<p>Imagens:</p>
											</div>
										</div>
										<div className="row">
											<div className="col-12 form-group">
												<input
													type="file"
													className="form-control-file"
													ref={files}
													multiple
												/>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button
											type="button"
											className="btn btn-secondary"
											data-dismiss="modal"
										>
											Fechar
										</button>
										<button
											type="submit"
											className="btn btn-warning"
										>
											Comunicar
										</button>
									</div>
								</>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
