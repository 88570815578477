import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import swal from 'sweetalert';
import { FaTimesCircle } from 'react-icons/fa';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import './style.css';
import { useSelector } from 'react-redux';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

$.DataTable = require('datatables.net-dt');

export default function ProvidersInputs() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [inputs, setInputs] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Registros',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Entradas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getInputs() {
		const response = await api.get('/cozinha-piloto/registros/entradas');

		!response.data.error
			? setInputs(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	function buildInputsTable(inputsData) {
		$('#recordsInputsTable').DataTable({
			data: inputsData,
			destroy: true,
			responsive: true,
			columns: [
				{ data: 'responsavel' },
				{ data: 'produto' },
				{ data: 'quantidade' },
				{
					data: data => {
						return `R$ ${data.saldo}`;
					},
				},
				{ data: 'fornecedor' },
			],
			language: {
				url:
					'//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json',
			},
		});
	}

	useEffect(() => {
		getInputs();
	}, []);

	useEffect(() => {
		if (!inputs) return;
		if (inputs.length === 0) return;
		buildInputsTable(inputs);
	}, [inputs]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{inputs ? (
				<div className="recordsInputsCardTable card table-responsive">
					<table
						className="table recordsInputsTable"
						id="recordsInputsTable"
					>
						<thead>
							<tr>
								<th scope="col">Responsável</th>
								<th scope="col">Produto</th>
								<th scope="col">Quantidade</th>
								<th scope="col">Saldo</th>
								<th scope="col">Fornecedor</th>
							</tr>
						</thead>
					</table>
				</div>
			) : (
				<div className="recordsInputsNotFound">
					<FaTimesCircle size={30} />
					<p className="mt-2">Nenhum registro encontrado</p>
				</div>
			)}
		</>
	);
}
