import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb';
import { useFunctionalities } from '../../../components/Contenxt/Functionalities';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Routes from '../../../routes/data/Routes';
import api from '../../../services/api';

export default function ViewSupplies() {
	const { state } = useLocation();
	const userActualType = useSelector(u => u.userType.actualType);
	const [isLoading, setIsLoading] = useState(true);
	const [material, setMaterial] = useState([]);
	const [materialNome, setMaterialNome] = useState('');
	const [description, setDescription] = useState('');
	const [quantity, setQuantity] = useState(null);
	const [value, setValue] = useState(null);
	const [defect, setDefect] = useState(null);
	const history = useHistory();

	const {
		functionalities: { functionalities },
	} = useFunctionalities();
	const [updateMaterial, setUpdateMaterial] = useState(false);
	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 75
			);
			if (existe) setUpdateMaterial(true);
			else setUpdateMaterial(false);
		}
	}, [functionalities]);

	async function loadSupplies() {
		setIsLoading(true);
		if (state.idSupplices) {
			const { data } = await api.get(
				'/material-de-escritorio/view-material',
				{
					params: {
						idMaterial: state.idSupplices,
					},
				}
			);
			if (data.error === true)
				swal({ icon: 'warning', text: data.message });
			else {
				setMaterial(data.data);
				setMaterialNome(data.data.nome_material);
				setDescription(data.data.descricao);
				setQuantity(data.data.quantidade);
				setValue(data.data.valor);
				setDefect(data.data.defeito);
			}
		} else {
			swal('ATENÇÃO!', 'Escolha um material de insumo', 'warning');
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_SUPPLIES
			);
		}
		setIsLoading(false);
	}

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_SUPPLIES,
			namePage: 'Lista de Materiais',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Materiail de Insumo',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleDelete() {
		const dataDelete = {
			idMaterial: state.idSupplices,
		};
		const { data } = await api.post(
			'/material-de-escritorio/delete-material',
			dataDelete
		);
		if (data.error === true) swal({ icon: 'warning', text: data.message });
		else {
			swal({ icon: 'success', text: data.message });
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.LIST_SUPPLIES
			);
		}
	}

	async function handleSubmit(event) {
		event.preventDefault();
		const dataUpdate = {
			idMaterial: state.idSupplices,
			nomeMaterial: materialNome,
			descricao: description,
			quantidade: quantity,
			valor: value,
			defeito: defect,
		};
		const { data } = await api.post(
			'/material-de-escritorio/update-material',
			dataUpdate
		);
		if (data.error === true) swal({ icon: 'warning', text: data.message });
		else {
			swal({ icon: 'success', text: data.message });
			loadSupplies();
		}
	}

	useEffect(() => {
		loadSupplies();
	}, [state]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">
					<div className="row">
						<div className="col-12 col-md-10 col-lg-10">
							Material de Insumo
						</div>
						{updateMaterial && (
							<div className="col-12 col-md-2 col-lg-2">
								<button
									type="button"
									className="btn btn-danger"
									onClick={handleDelete}
								>
									Apagar
								</button>
							</div>
						)}
					</div>
				</div>
				<div className="card-body">
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<form onSubmit={handleSubmit}>
							<div className="row form-group">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Nome do Material de Insumo</b>
									</p>
									{updateMaterial ? (
										<>
											<input
												type="text"
												name="materialNome"
												id="materialNome"
												required
												maxLengt="60"
												className="form-control"
												placeholder="Cadeiras"
												value={materialNome}
												onChange={event =>
													setMaterialNome(
														event.target.value
													)
												}
											/>
										</>
									) : (
										<>{materialNome}</>
									)}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Escola</b>
									</p>
									{material.desc_nome_escola}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Defeito</b>
									</p>
									{updateMaterial ? (
										<>
											<select
												required
												className="form-control"
												name="lblBolsaFamilia"
												id="lblBolsaFamilia"
												value={
													defect === 'SIM' ? '1' : '0'
												}
												onChange={event =>
													setDefect(
														event.target.value
													)
												}
											>
												<option value="0">Não</option>
												<option value="1">Sim</option>
											</select>
										</>
									) : (
										<>{defect}</>
									)}
								</div>
							</div>
							<div className="row form-group">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Quantidade</b>
									</p>
									{updateMaterial ? (
										<>
											<input
												type="number"
												name="quantity"
												id="quantity"
												required
												className="form-control"
												placeholder="200"
												value={quantity}
												onChange={event =>
													setQuantity(
														event.target.value
													)
												}
											/>
										</>
									) : (
										<>{quantity}</>
									)}
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Valor</b>
									</p>
									<label
										className="sr-only"
										htmlFor="inlineFormInputGroup"
									>
										150,00
									</label>
									<div className="input-group mb-2">
										<div className="input-group-prepend">
											<div className="input-group-text">
												R$
											</div>
										</div>
										<input
											type="text"
											className="form-control"
											id="inlineFormInputGroup"
											placeholder="150,00"
											disabled={!updateMaterial}
											value={value}
											onChange={event =>
												setValue(
													event.target.value.replace(
														',',
														'.'
													)
												)
											}
										/>
									</div>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Total</b>
									</p>
									R$ {material.valor * material.quantidade}
								</div>
							</div>
							<div className="form-group">
								<p>
									<b>Descrição</b>
								</p>
								<textarea
									className="form-control"
									rows="6"
									disabled={!updateMaterial}
									value={description}
									onChange={event =>
										setDescription(event.target.value)
									}
								/>
							</div>
							{updateMaterial && (
								<button
									type="submit"
									className="btn btn-warning"
								>
									Atualizar
								</button>
							)}
						</form>
					)}
				</div>
			</div>
		</>
	);
}
