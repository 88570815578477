import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './styles/bootstrap.min.css';
import App from './App';


import env from './env';

ReactGA.initialize([
	{
		trackingId: env.GA_API,
		debug: false,
		gaOptions: { cookieDomain: 'auto' },
	},
]);

ReactDOM.render(<App />, document.getElementById('root'));
