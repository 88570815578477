import React from 'react';
import PrivateRouter from './PrivateRouter';
import Home from '../pages/Home';
import Dash from '../pages/Dash';
import SubjectsContents from '../pages/Subjects';
import School from '../pages/School';
import SchoolInfo from '../pages/School/Info';
import Students from '../pages/Students';
import SubscriptionStudents from '../pages/Students/SubscriptionStudents';
import InscriberStudents from '../pages/Students/InscriberStudents';
import ClaimDeathStudents from '../pages/Students/ClaimDeathStudents';
import TransferStudent from '../pages/Students/TransferStudent';
import StudentsInfo from '../pages/Students/Info';
import Teachers from '../pages/Teachers';
import Parents from '../pages/Parents';
import Communicated from '../pages/Communicated';
import WriteCommunicated from '../pages/Communicated/WriteCommunicated';
import ReadCommunicatedInbox from '../pages/Communicated/ReadCommunicatedInbox';
import ReadCommunicatedSendBox from '../pages/Communicated/ReadCommunicatedSendBox';
import AddCommunicatedMessage from '../pages/Communicated/AddCommunicatedMessage';
import TransportOnlyGoing from '../pages/Transport/OnlyGoing';
import TransportGoingBack from '../pages/Transport/GoingBack';
import ListEmployee from '../pages/Employees/ListEmployee';
import RegisterEmployees from '../pages/Employees/RegisterEmployees';
import InfoEmployees from '../pages/Employees/InfoEmployees';
import FilesAtrtibution from '../pages/Attribution/FilesAttribution';
import EditFilesAttribution from '../pages/Attribution/FilesAttribution/EditFilesAttribution';
import AddFilesAttribution from '../pages/Attribution/FilesAttribution/AddFilesAttribution';
import InfoProfile from '../pages/Profile/InfoProfile';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Profile/ChangeContact';
import Driver from '../pages/Transport/Driver';
import Monitor from '../pages/Transport/Monitor';
import StudentItinerary from '../pages/Transport/StudentItinerary';
import ListAttribution from '../pages/Attribution';
import Ticket from '../pages/Ticket';
import ViewTicket from '../pages/Ticket/View';
import ListSupplies from '../pages/OfficeSupplies';
import Protocols from '../pages/Protocols';
import ProtocolsDetails from '../pages/Protocols/Details';
import ProtocolsDetailsSchool from '../pages/Protocols/DetailsSchools';
import ProtocolsReport from '../pages/Protocols/Report';
import ViewSupplies from '../pages/OfficeSupplies/View';
import Routes from './data/Routes';
import StudentsProtocols from '../pages/Protocols/Students Protocols';
// TODO olhar esse cara import PasswordEmployeer from '../pages/Employees/PasswordEmployeer';
// TODO olhar esse cara import infoContent from '../pages/SubjectsContents/infoContent';

export default function Level_06_Routes({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<>
			<PrivateRouter
				userTypes={[1, 2, 28]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Home}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.DASH}
				exact
				component={Dash}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT}
				exact
				component={SubjectsContents}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.FILES_ATTRIBUTION}
				exact
				component={FilesAtrtibution}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.ADD_FILES_ATTRIBUTION}
				exact
				component={AddFilesAttribution}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.INFO_ATTRIBUTION}
				exact
				component={EditFilesAttribution}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.SCHOOLS}
				exact
				component={School}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.SCHOOL_INFO}
				exact
				component={SchoolInfo}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.STUDENTS}
				exact
				component={Students}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.STUDENT_INFO}
				exact
				component={StudentsInfo}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.TEACHERS}
				exact
				component={Teachers}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.PARENTS}
				exact
				component={Parents}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.COMMUNICATED}
				exact
				component={Communicated}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.WRITE_COMMUNICATED}
				exact
				component={WriteCommunicated}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_RECEIVE}
				exact
				component={ReadCommunicatedInbox}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_SEND}
				exact
				component={ReadCommunicatedSendBox}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.ADD_COMMUNICATED_MESSAGE}
				exact
				component={AddCommunicatedMessage}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.STUDENT_ITINERARY}
				exact
				component={StudentItinerary}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.TRANSPORT_ONLY_GOING}
				exact
				component={TransportOnlyGoing}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.TRANSPORT_GOING_BACK}
				exact
				component={TransportGoingBack}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.LIST_EMPLOYEE}
				exact
				component={ListEmployee}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.REGISTER_EMPLOYEE}
				exact
				component={RegisterEmployees}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				path={LOGGED_ROUTES.INFO_EMPLOYEE}
				exact
				component={InfoEmployees}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[1, 2]}
				exact
				path={LOGGED_ROUTES.LIST_DRIVER}
				component={Driver}
			/>
			<PrivateRouter
				userTypes={[2]}
				exact
				path={LOGGED_ROUTES.LIST_MONITOR}
				component={Monitor}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.SUBSCRIPTION_STUDENTS}
				exact
				component={SubscriptionStudents}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.INSCRIBER_STUDENT}
				exact
				component={InscriberStudents}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.CLAIM_DEATH_STUDENTS}
				exact
				component={ClaimDeathStudents}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.TRANSFER_STUDENT}
				exact
				component={TransferStudent}
			/>
			<PrivateRouter
				userTypes={[2]}
				path={LOGGED_ROUTES.LIST_ATTRIBUTION}
				exact
				component={ListAttribution}
			/>
			<PrivateRouter
				userTypes={[2]}
				exact
				path={LOGGED_ROUTES.LIST_TICKETS}
				component={Ticket}
			/>
			<PrivateRouter
				userTypes={[2]}
				exact
				path={LOGGED_ROUTES.VIEW_TICKET}
				component={ViewTicket}
			/>
			<PrivateRouter
				userTypes={[2]}
				exact
				path={LOGGED_ROUTES.LIST_SUPPLIES}
				component={ListSupplies}
			/>
			<PrivateRouter
				userTypes={[2]}
				exact
				path={LOGGED_ROUTES.VIEW_SUPPLIES}
				component={ViewSupplies}
			/>
			<PrivateRouter
				userTypes={[1, 2, 28]}
				exact
				path={LOGGED_ROUTES.NURSERY_VACANCIES}
				component={Protocols}
			/>
			<PrivateRouter
				userTypes={[1, 2, 28]}
				exact
				path={LOGGED_ROUTES.CHILDHOOD_EDUCATION_VACANCIES}
				component={StudentsProtocols}
			/>
			<PrivateRouter
				userTypes={[1, 2, 28]}
				exact
				path={`${LOGGED_ROUTES.NURSERY_VACANCIES}/:protocolo`}
				component={ProtocolsDetails}
			/>
			<PrivateRouter
				userTypes={[1, 2, 28]}
				exact
				path={`${LOGGED_ROUTES.CHILDHOOD_EDUCATION_VACANCIES}/:protocolo`}
				component={ProtocolsDetailsSchool}
			/>
			<PrivateRouter
				userTypes={[1, 2, 28]}
				exact
				path={LOGGED_ROUTES.NURSERY_VACANCIES_REPORT}
				component={ProtocolsReport}
			/>
		</>
	);
}
