import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import Panel from '../../../components/Panel';
import Breadcrumb from '../../../components/Breadcrumb';
import Footer from '../../../components/Footer';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import api from '../../../services/api';
import Routes from '../../../routes/data/Routes';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import Calendar from '../components/Calendar';
import { CalendarActions } from '../../../redux/ducks/calendarReducer';

export default function MenuEdit() {
	const userActualType = useSelector(state => state.userType.actualType);
	const options = useSelector(state => state.calendar);
	const [informations, setInformations] = useState([]);
	const [date, setDate] = useState(null);
	const [tmpBuildMatrix, setTmpBuildMatrix] = useState(true);
	const [mealWeekDay, setMealWeekDay] = useState([]);
	const [schools, setSchools] = useState([]);
	const [school, setSchool] = useState(null);
	const [periods, setPeriods] = useState([]);
	const [period, setPeriod] = useState(null);
	const dispatch = useDispatch();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cardápio',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Editar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	function buildMatrix() {
		const column = 5;
		const mealsPeriod = {
			1: ['Café', 'Almoço'],
			2: ['Almoço', 'Lanche'],
			3: ['Jantar', 'Lanche'],
			4: ['Café', 'Almoço', 'Lanche'],
		};

		if (period)
			mealsPeriod[period].map((_, x) => {
				for (let y = 0; y < column; y++)
					dispatch(
						CalendarActions.updateCalendarItem(
							informations[x][y],
							x,
							y
						)
					);
			});
	}

	async function getPeriods() {
		const response = await api.get('/nutricionista/periodo-refeicoes');

		response.data
			? setPeriods(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function getSchools() {
		const response = await api.get('/tabelas/lista-escolas');

		response.data
			? setSchools(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function getMenu(event) {
		event.preventDefault();

		if (!date)
			return swal('ATENÇÃO', 'Digite a data do cardápio', 'warning');

		const response = await api.get('nutricionista/cardapios/buscar', {
			params: {
				data: date
					.split('/')
					.reverse()
					.join('-'),
				id_escola: school,
				periodo: period,
			},
		});

		if (!response.data.error) {
			if (!response.data.data)
				return swal('ATENÇÃO', 'Cardápio não encontrado', 'warning');

			setMealWeekDay(response.data.data[1]);
			setInformations(response.data.data.splice(2));
			setTmpBuildMatrix(false);
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function updateMenu() {
		const response = await api.post('nutricionista/cardapios/atualizar', {
			opcoes: options,
			ids_dia_semana_refeicao: mealWeekDay,
			periodo: period,
		});

		!response.data.error
			? swal('SUCESSO!', 'Cardápio atualizado com sucesso', 'success')
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	useEffect(() => {
		buildMatrix();
	}, [informations]);

	useEffect(() => () => dispatch(CalendarActions.clearCalendar()), []);

	useEffect(() => {
		getSchools();
		getPeriods();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={getMenu}>
				<div className="form-row d-flex align-items-end">
					<div className="col-12 col-md-6">
						<label htmlFor="dateInputMenu">
							Selecione a escola
						</label>

						<select
							id="schoolInputMenu"
							className="form-control"
							value={school}
							onChange={item => setSchool(item.target.value)}
						>
							<option selected disabled />
							{schools.map(item => (
								<option value={item.id}>{item.name}</option>
							))}
						</select>
					</div>
					<div className="col-12 col-md-6">
						<label htmlFor="dateInputMenu">
							Selecione o período
						</label>

						<select
							id="periodInputMenu"
							className="form-control"
							value={period}
							onChange={item => setPeriod(item.target.value)}
						>
							<option selected disabled />
							{periods.map(item => (
								<option value={item.id_periodo_refeicao}>
									{item.periodo}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="form-row align-items-end mt-3">
					<div className="col-12 col-md-10">
						<label htmlFor="dateInputMenu">
							Selecione a data do cardápio
						</label>

						<InputMask
							id="dateInputMenu"
							className="form-control"
							mask="99/99/9999"
							value={date}
							onChange={item => setDate(item.target.value)}
						/>
					</div>
					<div className="col-12 col-md-2 mt-4 d-flex justify-content-end">
						<button type="submit" className="btn btn-primary">
							Buscar Cardápio
						</button>
					</div>
				</div>
			</form>

			{informations.length !== 0 ? (
				<>
					<Calendar period={period} isEditable />

					<div className="d-flex justify-content-end">
						<button
							type="button"
							className="btn btn-success"
							onClick={updateMenu}
						>
							Salvar Cardápio
						</button>
					</div>
				</>
			) : null}
		</>
	);
}
