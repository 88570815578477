import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';
import Table from '../../../components/Table';
import parseJwt from '../../../helpers/parseJwt';

export default function ListMonitorHasStudents() {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const { name } = tokenUsuario;
	const [isLoading, setIsLoading] = useState(true);
	const [listStudents, setListStudents] = useState([]);

	async function loadStudents() {
		setIsLoading(true);
		const { data } = await api.get('/transporte/monitor/lista-alunos');
		if (data.error === false) setListStudents(data.data);
		else {
			setListStudents([]);
			swal({ icon: 'warning', text: data.message });
		}
		setIsLoading(false);
	}
	useEffect(() => {
		loadStudents();
	}, []);

	return (
		<>
			<div className="mt-6">
				{isLoading === true ? (
					<div className="row pt-md-5 mt-md-3 mb-5">
						<div className="lds-dual-ring" />
					</div>
				) : (
					<div className="pt-md-5 mt-md-3">
						<div className="card mt-5">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Lista de alunos monitorados</h4>
							</div>
							{listStudents.length ? (
								<>
									<div className="card-body">
										<div className="d-flex justify-content-between">
											<div className="form-group">
												<b>Monitor:</b> {name}
											</div>
										</div>
										<Table
											header={['Nome', 'RA']}
											body={listStudents.map(student => {
												return [
													student.nome_aluno,
													student.num_ra,
												];
											})}
										/>
									</div>
								</>
							) : (
								<>
									<div className="card-body">
										<p>
											<b>Monitor:</b> {name}
										</p>
										Monitor não tem alunos para serem
										monitorados durante o transporte.
									</div>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
}
