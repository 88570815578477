import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import api from '../../../services/api';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import Panel from '../../../components/Panel';
import Breadcrumb from '../../../components/Breadcrumb';
import Footer from '../../../components/Footer';

export default function FormParent() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState(null);
	const files = useRef(null);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_TICKETS,
			namePage: 'Lista de Chamados',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Criar Chamado',
			breadCrumbClass: 'breadcrumb-item',
		},
	];

	async function handleSubmit(event) {
		event.preventDefault();

		try {
			const data = new FormData();
			data.append('title', title);
			data.append('description', description);
			Object.values(files.current.files).map(file =>
				data.append(Math.random(), file)
			);
			const response = await api.post('/ticket/criar-ticket', data);

			if (!response.data.error) {
				swal('SUCESSO!', `${response.data.message}`, 'success');
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_TICKETS
				);
			} else swal('ERRO!', `${response.data.message}`, 'error');
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card bg-white rounded mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Criar Chamado</h4>
				</div>
				<div className="card-body">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12 form-group">
								<p>
									<b>Titulo</b>
								</p>
								<input
									required
									maxLength={100}
									type="text"
									name="tittle"
									id="tittle"
									className="form-control"
									placeholder="Titulo Notícia"
									value={title}
									onChange={event =>
										setTitle(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="form-group write-notices">
							<p>
								<b>Descrição</b>
							</p>
							<textarea
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="6"
								onChange={event =>
									setDescription(event.target.value)
								}
							/>
						</div>
						<div className="row">
							<div className="col-12 form-group">
								<p>
									<b>Arquivo</b>
								</p>
								<input
									type="file"
									className="form-control-file"
									ref={files}
									multiple
								/>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">
							Confirmar
						</button>
					</form>
				</div>
			</div>
		</>
	);
}
