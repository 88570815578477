import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { FaPlus, FaMinus } from 'react-icons/fa';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import './style.css';

export default function Index() {
	const [classes, setClasses] = useState([]);
	const [users, setUsers] = useState([]);
	const [userId, setUserId] = useState(null);
	const [inputFields, setInputFields] = useState([{ Classe: '' }]);
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.COORDINATOR,
			namePage: 'Lista de Coordenadores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar Coordenador',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	useEffect(() => {
		(async function() {
			const { data } = await api.get('/funcionarios', {
				params: {
					userTypeId: localStorage.getItem('@actualTypeId'),
				},
			});
			if (data.data.length) setUsers(data.data);
			else
				swal(
					'ATENÇÃO',
					'Escola não tem funcionários cadastrados. Entre em contato com o Secretária para adicionar um novo funcionário.',
					'warning'
				);
		})();
	}, []);

	useEffect(() => {
		(async function() {
			const response = await api.get('/classes/lista', {
				params: {
					coordinator: true,
				},
			});
			if (!response.data.error) setClasses(response.data.data);
		})();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ Classe: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'Classe')
			values[index].Classe = event.target.value;
		setInputFields(values);
	};

	async function handleSubmit(e) {
		e.preventDefault();
		if (userId === null) swal('Campos Vazios');
		try {
			await api
				.post('/coordenador/registro-coordenador', {
					userId,
					classeId: inputFields,
				})
				.then(response => {
					if (!response.data.error) {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						history.push(
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).COORDINATOR
						);
					} else swal('Ops!', `${response.data.message}`, 'warning');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">Cadastro Coordenadores</div>
				<div className="card-body">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="form-group col-8">
								<label htmlFor="employeer">
									Profissionais Cadastrados
								</label>
								<select
									required
									id="employeer"
									className="form-control"
									onChange={event =>
										setUserId(event.target.value)
									}
								>
									<option value="">
										Escolha um Profissional
									</option>
									{users.map(user => (
										<option
											key={user.id_usuario}
											value={user.id_usuario}
										>
											{user.nome_usuario}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="row">
							{inputFields.map((inputField, index) => (
								<Fragment
									// eslint-disable-next-line react/no-array-index-key
									key={`${inputField}~${index}`}
								>
									<div className="form-group col-12 col-lg-8 col-md-8">
										<label htmlFor="Classe">Classe</label>
										<select
											required
											name="Classe"
											className="form-control"
											id="lblIdClasse"
											onChange={event =>
												handleInputChange(index, event)
											}
										>
											<option value="">
												Escolha uma classe
											</option>
											{classes.map(classe => (
												<option
													value={classe.id_classe}
													key={classe.id_classe}
												>
													{classe.desc_serie_ano}{' '}
													{classe.desc_tipo_ensino}{' '}
													{classe.turma}
												</option>
											))}
										</select>
									</div>
									<div className="form-group col-sm-2 d-flex justify-content-around align-items-end">
										{inputFields.length > 1 && (
											<button
												className="btn btn-danger formCoordinatorAddRemoveButtons"
												type="button"
												onClick={() =>
													handleRemoveFields(index)
												}
											>
												<FaMinus fill="#ffffff" />
											</button>
										)}
										<button
											className="btn btn-success formCoordinatorAddRemoveButtons"
											type="button"
											onClick={() => handleAddFields()}
										>
											<FaPlus fill="#ffffff" />
										</button>
									</div>
								</Fragment>
							))}
						</div>
						<div className="submit-button">
							<button
								className="btn btn-primary mr-2"
								type="submit"
							>
								Cadastrar Coordenador
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
