import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import SelectEscola from '../../../components/Selects/SelectEscola';
import SelectClasses from '../../../components/Selects/SelectClasses';

export default function InsertStudent() {
	const history = useHistory();
	const breadCrumbItems = [
		{
			linkPage: '/secretaria',
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Transferir Aluno entre Escolas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [classe, setClasse] = useState([]);
	const [idSchool, setIdSchool] = useState(null);
	const [lblIdAluno, setlblIdAluno] = useState(null);
	const [lblLogin, setlblLogin] = useState('');
	const [lblSenha, setlblSenha] = useState('');
	const [lblNomeMae, setlblNomeMae] = useState('');
	const [lblNomeAluno, setlblNomeAluno] = useState('');
	const [lblDataNascimentoAluno, setlblDataNascimentoAluno] = useState('');
	const [lblRaAluno, setlblRaAluno] = useState('');
	const [errorVerify, setErrorVerify] = useState('');
	const [visibleClasses, setVisibleClasses] = useState(false);
	const [lblIdSerieAno, setlblIdSerieAno] = useState(null);
	const [disabledButtom, setDisabledButtom] = useState(true);
	const [editableStudent, setEditableStudent] = useState(false);
	const [disableVerify, setDisableVerify] = useState(true);
	const [idSchoolDestiny, setIdSchoolDestiny] = useState(null);
	const [lblIdClassesDestino, setlblIdClassesDestino] = useState(null);

	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	async function handleVerify(event) {
		event.preventDefault();
		const { data } = await api.get('/aluno/verifica-aluno', {
			params: {
				lblNomeAluno,
				lblDataNascimentoAluno,
				lblNomeMae,
				escolaID: idSchool,
			},
		});
		if (!data.error) {
			setClasse(data.data.classe);
			setlblIdSerieAno(data.data.classe.id_serie_ano);
			setlblIdAluno(data.data.aluno.id_usuario);
			setlblRaAluno(data.data.aluno.num_ra);
			setErrorVerify('');
			setVisibleClasses(true);
			setEditableStudent(true);
		} else setErrorVerify(data.message);
	}

	async function handleCancel(event) {
		event.preventDefault();
		history.goBack();
	}

	useEffect(() => {
		(async function() {
			if (
				lblNomeAluno !== '' &&
				lblDataNascimentoAluno !== '' &&
				lblNomeMae !== '' &&
				idSchool
			)
				setDisableVerify(false);
			else setDisableVerify(true);
		})();
	}, [lblNomeAluno, lblDataNascimentoAluno, lblNomeMae, idSchool]);

	async function handleChangeStudent() {
		setDisabledButtom(true);
		setVisibleClasses(false);
		setEditableStudent(false);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		try {
			event.preventDefault();
			const data = new FormData();
			data.append('idAluno', lblIdAluno);
			data.append('lblIdSerieAno', lblIdSerieAno);
			data.append('lblIdClassesDestino', lblIdClassesDestino);
			data.append('lblLogin', lblLogin);
			data.append('lblSenha', lblSenha);
			await api
				.post('/aluno/perfil/atualiza/transferencia/classe', data)
				.then(response => {
					if (!response.data.error)
						swal('Ops!', `${response.data.message}`, 'warning');
					else {
						setlblLogin('');
						setlblSenha('');
						setlblNomeMae('');
						setlblNomeAluno('');
						setlblRaAluno('');
						handleChangeStudent();
						swal('SUCESSO!', `${response.data.message}`, 'success');
					}
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	useEffect(() => {
		(async function() {
			if (idSchoolDestiny && lblIdClassesDestino)
				setDisabledButtom(false);
			else setDisabledButtom(true);
		})();
	}, [idSchoolDestiny, lblIdClassesDestino]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">
					<b>Transferir Aluno entre Escolas</b>
				</div>
				<form onSubmit={handleVerify}>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Nome Aluno</b>
								</p>
								<input
									onInput={toInputUppercase}
									required
									disabled={editableStudent}
									type="text"
									name="lblNomeAluno"
									id="lblNomeAluno"
									className="form-control"
									placeholder="Digite o nome do Aluno"
									value={lblNomeAluno}
									onChange={event =>
										setlblNomeAluno(event.target.value)
									}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Data Nascimento Aluno</b>
								</p>
								<InputMask
									mask="99/99/9999"
									required
									disabled={editableStudent}
									type="text"
									name="lblDataNascimentoAluno"
									id="lblDataNascimentoAluno"
									className="form-control"
									placeholder="Data Nascimento do Aluno"
									value={lblDataNascimentoAluno}
									onChange={event =>
										setlblDataNascimentoAluno(
											event.target.value
										)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Nome Mãe do Aluno</b>
								</p>
								<input
									onInput={toInputUppercase}
									required
									disabled={editableStudent}
									type="text"
									name="lblNomeMae"
									id="lblNomeMae"
									className="form-control"
									placeholder="Nome da Mãe do Aluno"
									value={lblNomeMae}
									onChange={event =>
										setlblNomeMae(event.target.value)
									}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<SelectEscola
									setIdSchool={setIdSchool}
									value={idSchool}
									required
									disabled={editableStudent}
									label="Escolas"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Registro do Aluno</b>
								</p>
								<input
									disabled
									type="text"
									name="lblRaAluno"
									id="lblRaAluno"
									className="form-control"
									placeholder="Registro Aluno"
									value={lblRaAluno}
									onChange={event =>
										setlblRaAluno(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-12">
								<span className="errorLogin">
									<small>{errorVerify}</small>
								</span>
							</div>
						</div>
					</div>
					<div className="card-footer">
						<div className="row justify-content-start">
							<div className="col-lg-4 col-md-5 col-7 form-group">
								{editableStudent === false ? (
									<button
										disabled={disableVerify}
										className="btn btn-primary btn-block"
										type="submit"
									>
										Verificar
									</button>
								) : (
									<button
										onClick={handleChangeStudent}
										className="btn btn-primary btn-block"
										type="button"
										id="button-addon2"
									>
										Mudar
									</button>
								)}
							</div>
						</div>
					</div>
				</form>
			</div>
			{visibleClasses === true ? (
				<div className="card mt-3">
					<div className="card-header">
						<p>Dados das Classes</p>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-2 col-lg-2 form-group">
								<b>Classe Atual:</b>
							</div>
							<div className="col-12 col-md-4 col-lg-4 form-group">
								{classe.desc_serie_ano}
								{' - '}
								{classe.desc_tipo_ensino}
							</div>
							<div className="col-12 col-md-1 col-lg-1 form-group">
								<b>Turma:</b>
							</div>
							<div className="col-12 col-md-1 col-lg-1 form-group">
								{classe.turma}
							</div>

							<div className="col-12 col-md-2 col-lg-2 form-group">
								<b>Turno:</b>
							</div>
							<div className="col-12 col-md-2 col-lg-2 form-group">
								{classe.desc_turno}
							</div>
						</div>
						<hr />
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Escola Destino</b>
								</p>
								<SelectEscola
									setIdSchool={setIdSchoolDestiny}
									value={idSchoolDestiny}
									required
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<p>
									<b>Classe Destino</b>
								</p>
								<SelectClasses
									setClasse={setlblIdClassesDestino}
									valueSelect={lblIdClassesDestino}
									required
									schoolId={idSchoolDestiny}
								/>
							</div>
						</div>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-6 col-md-10 col-lg-10">
								<button
									type="submit"
									className="btn btn-danger"
									onClick={handleCancel}
								>
									Cancelar
								</button>
							</div>
							<div className="col-6 col-md-2 col-lg-2">
								<button
									disabled={disabledButtom}
									type="submit"
									className="btn btn-primary"
									data-toggle="modal"
									data-target="#modalConfirmar"
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}

			<div
				className="modal fade"
				id="modalConfirmar"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<form onSubmit={handleSubmit}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="exampleModalLabel"
								>
									Tranferencia do Aluno
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Fechar"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>
									Você está para Inscrever um aluno novo na
									classe, isso siginifica que aluno irá fazer
									parte desta Classe.
								</p>
								<p>
									Caso tenha certeza desta ação, Por Favor
									confirme o usuário.
								</p>
								<div className="row justify-content-center">
									<div className="form-group col-10">
										<label htmlFor="loginEmail">
											E-mail
										</label>
										<input
											type="text"
											name="loginEmail"
											id="loginEmail"
											placeholder="Digite seu e-mail"
											className="form-control"
											onChange={event =>
												setlblLogin(event.target.value)
											}
											required
										/>
									</div>
								</div>
								<div className="row justify-content-center">
									<div className="form-group col-10">
										<label htmlFor="passwordLogin">
											Senha
										</label>
										<input
											type="password"
											name="passwordLogin"
											id="passwordLogin"
											placeholder="Digite a sua senha"
											className="form-control"
											onChange={event =>
												setlblSenha(event.target.value)
											}
											required
										/>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal"
								>
									Fechar
								</button>
								<button
									type="submit"
									className="btn btn-warning"
								>
									Tranferir
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
