import React from 'react';

export default function Day() {
	let today = new Date();
	const day = String(today.getDate()).padStart(2, '0');
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const year = String(today.getFullYear());
	today = `${day}/${month}/${year}`;

	return <>{today}</>;
}
