import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../Breadcrumb';
import InfoCardSchool from './InfoCardSchool';
import RouteParams from '../../routes/data/RouteParams';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import BasicData from '../Cards/BasicData';
import DataAccessSystem from '../Cards/DataAccessSystem';
import Documents from '../Cards/Documents';
import CardUserContacts from '../Contact/CardUserContacts';
import Address from '../Cards/Address';

export default function ProfileEmployees() {
	const history = useHistory();
	const location = useLocation();
	const [idFuncionario, setIdFuncionario] = useState(null);
	const userActualType = useSelector(state => state.userType.actualType);
	const [lblDisable, setlblDisable] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [typeUserSchool, setTypeUserSchool] = useState([]);
	const [userContacts, setUserContacts] = useState([]);
	const editable = userActualType === 2;

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_TEACHERS,
			namePage: 'Lista de Professores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informações do Funcionário',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			if (location.state)
				setIdFuncionario(
					location.state[
						RouteParams.LEVEL_06_ROUTES.INFO_EMPLOYEE.USER_ID
					]
				);
			else {
				swal('ATENÇÃO', 'Escolha um funcionário', 'warning');
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_EMPLOYEE
				);
			}
		})();
	});

	async function loadEmployeer() {
		const { data } = await api.get('/funcionarios/informacao', {
			params: {
				idUser: idFuncionario,
			},
		});
		if (data.error === false) {
			setlblDisable(true);
			setUserContacts(data.data.contato);
			setTypeUserSchool(data.data.tipoUsuario);
		} else {
			swal('ERRO!', `${data.message}`, 'error');
			history.goBack();
		}
		setIsLoading(false);
	}

	useEffect(() => {
		setIsLoading(true);
		if (idFuncionario) loadEmployeer();
	}, [idFuncionario]);

	async function handleDeactivated(e) {
		const data = new FormData();
		data.append('idUser', e);
		const {
			data: { message },
		} = await api.post('/funcionarios/desativa', data);
		swal('SUCESSO!', `${message}`, 'success');
		history.goBack();
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading && <div className="lds-dual-ring" />}
			{isLoading === false && (
				<>
					<BasicData
						idUsuario={idFuncionario}
						tittle="Dados Funcionário"
						editable={editable}
					/>
					<DataAccessSystem
						idUsuario={idFuncionario}
						tittle="Dados de Acesso no sistema do Funcionário"
						editable={editable}
					/>
					{typeUserSchool.length > 0 ? (
						<InfoCardSchool
							employeesSchool={typeUserSchool}
							disabled={lblDisable}
						/>
					) : null}
					<Documents
						idUsuario={idFuncionario}
						tittle="Documentos Funcionário"
						editable={editable}
					/>
					<CardUserContacts
						userContacts={userContacts}
						idUsuario={idFuncionario}
						editable={editable}
					/>
					<Address
						idUsuario={idFuncionario}
						editable={editable}
						tittle="Endereço do Funcionário"
					/>
				</>
			)}
		</>
	);
}
