import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import swal from 'sweetalert';
import { FaTimesCircle } from 'react-icons/fa';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import api from '../../services/api';
import './style.css';
import { useSelector } from 'react-redux';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import LabelByPermission from '../../routes/data/LabelByPermission';

$.DataTable = require('datatables.net-dt');

export default function Numeration() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [numeration, setNumeration] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Numeração',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getNumeration() {
		const response = await api.get('/cozinha-piloto/numeracao');

		!response.data.error
			? setNumeration(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	function buildNumerationTable(numerationData) {
		$('#numerationTable').DataTable({
			data: numeration,
			responsive: true,
			destroy: true,
			columns: [
				{
					data: data => {
						return `${data.data
							.split('-')
							.reverse()
							.join('/')}`;
					},
					visible: false,
				},
				{ data: 'numero' },
				{ data: 'recurso' },
				{
					data: data => {
						return `${data.conta} - ${data.descricao}`;
					},
				},
				{ data: 'movimento_fixado' },
				{ data: 'movimento_alteracoes' },
				{ data: 'movimento_pre_empenhado' },
				{ data: 'movimento_empenhado' },
				{ data: 'movimento_saldo' },
				{ data: 'movimento_contigenciado' },
				{ data: 'movimento_disponivel' },
			],
			language: {
				url:
					'//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json',
			},
		});
	}

	useEffect(() => {
		getNumeration();
	}, []);

	useEffect(() => {
		if (!numeration) return;
		if (numeration.length === 0) return;
		buildNumerationTable(numeration);
	}, [numeration]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{numeration ? (
				<div className="table-responsive card numerationCardTable">
					<table
						className="table numerationTable"
						id="numerationTable"
					>
						<thead>
							<tr>
								<th scope="col" />
								<th scope="col" className="text-center">
									Ficha
								</th>
								<th scope="col" className="text-center">
									Recurso
								</th>
								<th scope="col" className="text-center">
									Elemento Despesa
								</th>
								<th
									scope="col"
									colSpan="7"
									className="text-center"
								>
									Movimento
								</th>
							</tr>
							<tr>
								<th scope="col" />
								<th scope="col">Número</th>
								<th scope="col">Fonte de Recurso</th>
								<th scope="col">Conta</th>
								<th scope="col">Fixado</th>
								<th scope="col">Alterações</th>
								<th scope="col">Pré-Empenhado</th>
								<th scope="col">Empenhado</th>
								<th scope="col">Saldo</th>
								<th scope="col">Contigenciado</th>
								<th scope="col">Disponível</th>
							</tr>
						</thead>
					</table>
				</div>
			) : (
				<div className="numerationNotFound">
					<FaTimesCircle size={30} />
					<p className="mt-2">Nenhuma numeração encontrada</p>
				</div>
			)}
		</>
	);
}
