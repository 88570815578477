import React from 'react';

export default function CardStudentSupervisor({ responsibleForStudents }) {
	return (
		<div className="card mt-3">
			<div className="card-header">Responsável pelo(s) Aluno(s)</div>
			<div className="card-body">
				{responsibleForStudents.map(student => (
					<>
						<div className="row">
							<div className="mt-2 form-group col-12 col-lg-10 col-md-10">
								<p>
									<b>Aluno(a): </b>
									{student.nome} {' - '}
									<b>Escola: </b>
									{student.escola}
								</p>
							</div>
						</div>
						<hr />
					</>
				))}
			</div>
		</div>
	);
}
