import React, { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { RiSubtractFill } from 'react-icons/ri';
import './styles.css';

const letras = ['a', 'b', 'c', 'd', 'e'];

const Alternative = ({ saveQuestion }) => {
	const [inputFields, setInputField] = useState([{ titulo: '' }]);

	const [tituloQuestao, SetTituloQuestao] = useState('');
	const [descricaoQuestao, SetDescricaoQuestao] = useState('');
	const [pesoQuestao, SetPesoQuestao] = useState('');
	const [certaIndex, setCertaIndex] = useState('');

	const handleChangeInput = (event, index) => {
		const values = [...inputFields];

		values[index][event.target.name] = event.target.value;
		setInputField(values);
	};

	const handleSubitmit = (event) => {
		event.preventDefault();
		const newInputField = inputFields;

		newInputField.forEach((item, index) => {
			item['alternativas'] = letras[index];
		});
		
		newInputField[certaIndex]['certa'] = 1;
		const data = {
			title: tituloQuestao,
			descricao: descricaoQuestao,
			peso: pesoQuestao,
			alternativa: newInputField,
		};
		
		saveQuestion(data);
		setInputField([{ titulo: '' }]);
		SetTituloQuestao('');
		SetDescricaoQuestao('');
		SetPesoQuestao('');
	};

	const handleAddFields = () => {
		if (inputFields.length <= 4) {
			setInputField([...inputFields, { titulo: '' }]);
		} else {
			return;
		}
	};

	const handleRemoveFields = index => {
		if (index == 0) {
			return;
		} else {
			const values = [...inputFields];
			values.splice(index, 1);
			setInputField(values);
		}
	};

	return (
		<>
			<div className="card mt-3">
				<div className="card-body">
					<form onSubmit={(event)=>handleSubitmit(event)}>
						<div className="titleDescription">
							<label for="inputTituloQuestao">
								Titulo da questão
								<input
									className="form-control"
									id="inputTituloQuestao"
									type="text"
									value={tituloQuestao}
									name="Titulo"
									type="text"
									required
									onChange={event =>
										SetTituloQuestao(event.target.value)
									}
								/>
							</label>
							<label for="inputDescricao">
								Descrição
								<input
									className="form-control"
									id="inputDescricao"
									type="text"
									name="Descricao"
									type="text"
									required
									value={descricaoQuestao}
									onChange={event =>
										SetDescricaoQuestao(event.target.value)
									}
								/>
							</label>

							<label for="inputPeso">
								Peso
								<input
									className="form-control"
									id="inputPeso"
									type="number"
									name="Peso"
									required
									value={pesoQuestao}
									onChange={event =>
										SetPesoQuestao(event.target.value)
									}
								/>
							</label>
						</div>

						<hr />

						<label for="inputTitulo">Crie as alternativas</label>
						{inputFields.map((inputField, index) => (
							<div className="groupAlternatives" key={index}>
								<div className="inputAlternatives">
									<section>
										<span>{letras[index]})</span>
										<input
											className="form-control"
											id="inputTitulo"
											type="text"
											name="titulo"
											required
											placeholder="Titulo"
											value={inputField.titulo}
											onChange={event =>
												handleChangeInput(event, index)
											}
										/>
									</section>
									<div
										className="form-check"
										id="checkAlternativeCorret"
									>
										<input
											type="radio"
											name="certa"
											value={1}
											onChange={event =>
												setCertaIndex(index)
											}
											id={`customRadioInline${index + 3}`}
										/>
										<label
											className="form-check-label"
											for={`customRadioInline${index +
												3}`}
										>
											Resposta correta
										</label>
									</div>
								</div>

								<div className="buttonGroup">
									<button type="button">
										<RiSubtractFill
											onClick={() =>
												handleRemoveFields(index)
											}
										/>
									</button>

									<button type="button">
										<BsPlus
											onClick={() => handleAddFields()}
										/>
									</button>
								</div>
							</div>
						))}

						<button
							type="submit"
							id="salvarButton"
							className="btn btn-primary"
						>
							Adicionar questão
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default Alternative;
