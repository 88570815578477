import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function MediaClasse({ count }) {
	const chart = {
		data: {
			datasets: [
				{
					label: 'Disciplinas',
					data: count.map(item => item.medias),
					backgroundColor: [
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
						'rgba(29,227,242,0.8)',
					],
				},
			],
			labels: count.length
				? count.map(item => item.nome_disciplina)
				: ['Sem Notas'],
		},
	};

	return (
		<>
			<Bar data={chart.data} />
		</>
	);
}
