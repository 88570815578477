import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import RouteParams from '../../routes/data/RouteParams';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import './style.css';

export default function MessageDropDown() {
	const [notification, setNotification] = useState([]);
	const userActualType = useSelector(state => state.userType.actualType);

	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZone: 'UTC',
	};

	useEffect(() => {
		(async function() {
			const response = await api.get('/comunicados/notificacao', {
				params: {
					TypeId: localStorage.getItem('@actualTypeId'),
				},
			});
			if (!response.data.error)
				setNotification(response.data.data.comunicados);
		})();
	}, []);

	const navbarNotification = notification.map(item => [
		{
			id_comunicado: item.id_comunicado,
			id_comunicado_has_usuario: item.id_comunicado_has_usuario,
			comunicado: item.comunicado,
			assunto: item.assunto,
			updated_at: item.updated_at,
			arquivos: item.arquivos,
			mensagens: item.mensagens,
			notificationLink: Routes.LOGGED_ROUTES(
				RouteByPermission[userActualType]
			).READ_COMMUNICATED_RECEIVE,
		},
	]);

	async function handleVisto(e) {
		try {
			await api.get('/comunicados/atualiza-visto', {
				params: {
					idCommunicatedHasUsuario: e,
				},
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<div className="dropdown d-flex flex-column justify-content-end">
				<Link
					to=""
					className="nav-link dropdown-toggle d-flex justify-content-end align-items-center"
					id="dropdownMenuLink"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<span className="text-muted">
						<FaBell />
					</span>
					{navbarNotification.length > 0 ? (
						<span className="badgeNotification">
							{navbarNotification.length}
						</span>
					) : null}
				</Link>
				<div
					className="dropdown-menu dropdown-menu-right dropdown-menu-right-message"
					aria-labelledby="dropdownMenuLink"
				>
					<Link
						to={
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).COMMUNICATED
						}
					>
						<div className="item-header">
							<h6 className="item-title">
								Ver Comunicados({navbarNotification.length})
							</h6>
						</div>
					</Link>
					<div className="settings-list">
						{Array.isArray(navbarNotification) &&
						navbarNotification.length ? (
							<div className="exist-comunicated">
								{navbarNotification.map(item => (
									<Link
										onClick={() =>
											handleVisto(
												item[0]
													.id_comunicado_has_usuario
											)
										}
										to={() => {
											const params = {};
											// eslint-disable-next-line prefer-destructuring
											params[
												RouteParams.COMMON_LEVELS.READ_COMMUNICATED_RECEIVE.MESSAGE
											] = item[0];

											return {
												pathname:
													item[0].notificationLink,
												state: params,
											};
										}}
									>
										<div
											key={
												item[0]
													.id_comunicado_has_usuario
											}
											className="dropdown-item-notification container"
										>
											<span className="label-notification">
												{item[0].assunto}
											</span>
											<br />
											<small>
												{new Date(
													item[0].updated_at
												).toLocaleDateString(
													'pt-BR',
													options
												)}
											</small>
										</div>
									</Link>
								))}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
