import React, { useState, useEffect } from 'react';
import api from '../../services/api';

export default function FormStudents({ idStudent }) {
	const [isLoading, setIsLoading] = useState(true);
	const [classe, setClasse] = useState([]);
	const [noClasse, setNoClasse] = useState([]);

	useEffect(() => {
		(async function() {
			setIsLoading(true);
			const classeApi = await api.get('/classes/classe-aluno', {
				params: {
					idUsuario: idStudent,
				},
			});
			if (classeApi.data.error === false) setClasse(classeApi.data.data);
			else setNoClasse(classeApi.data.message);
		})();
		setIsLoading(false);
	}, [idStudent]);

	return (
		<>
			<div className="card mt-3">
				<div className="card-header">
					<div className="col-12 col-md-12 col-lg-12 form-group">
						<h4>Dados Acadêmicos</h4>
					</div>
				</div>
				{isLoading === true ? (
					<div className="lds-dual-ring" />
				) : (
					<>
						{noClasse && (
							<div className="card-body">
								<span className="badge badge-info">
									{noClasse}
								</span>
							</div>
						)}
						{classe && (
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-8 col-lg-8 form-group">
										<p>
											<b>Série:</b>{' '}
											{classe.desc_serie_ano}
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Turma:</b> {classe.turma}
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-8 col-lg-8 form-group">
										<p>
											<b>Tipo de Ensino:</b>{' '}
											{classe.desc_tipo_ensino}
										</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Sala:</b> {classe.num_sala}
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Turno:</b> {classe.desc_turno}
										</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
}
