import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../services/api';
import SelectTipoTelefone from '../Selects/SelectTipoTelefone';
import Breadcrumb from '../Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import parseJwt from '../../helpers/parseJwt';

export default function UpdateContact({ lblDisable, idUsuario = null }) {
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = idUsuario ? idUsuario : tokenUsuario.sub;

	const userActualType = useSelector(user => user.userType.actualType);
	const history = useHistory();
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.INFO_PROFILE,
			namePage: 'Informação do Usuário',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Contato',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblIdTipoTelefone, setlblIdTipoTelefone] = useState('');
	const [lblDDDTel, setlblDDDTel] = useState('');
	const [lblNumTelefone, setlblNumTelefone] = useState('');
	const [lblComplementoTel, setlblComplementoTel] = useState('');
	const [contacts, setContacts] = useState([]);
	const [lblSms, setlblSms] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async function() {
			const {
				data: { error, message, data },
			} = await api.get('/usuario/informacao', {
				params: {
					idUser: idUsuario,
				},
			});
			if (error === false) {
				setContacts(data.contato);
				setIsLoading(false);
			} else {
				swal('ERRO!', `${message}`, 'error');
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.STUDENTS
				);
			}
		})();
	}, []);

	async function handleDeletNumber(e) {
		const contact = new FormData();
		contact.append('idContatoHasUsuario', e);
		const { data } = await api.post('/contato/desativar', contact);
		swal('SUCESSO!', `${data.message}`, 'success');
		const updateClasse = contacts.filter(
			c => c.id_contato_has_usuario !== e
		);
		setContacts(updateClasse);
		contacts.filter(c => c.id_contato_has_usuario !== e);
	}

	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	async function handleNewNumber(event) {
		event.preventDefault();
		try {
			const data = new FormData();
			data.append('idUser', idUser);
			data.append('lblIdTipoTelefone', lblIdTipoTelefone);
			data.append('lblDDDTel', lblDDDTel);
			data.append('lblNumTelefone', lblNumTelefone);
			data.append('lblComplementoTel', lblComplementoTel);
			data.append('lblSms', lblSms);
			await api.post('/contato/cadastrar', data).then(response => {
				if (!response.data.error) {
					swal('Novo número cadastrado');
					setContacts(response.data.data);
				} else swal('ERRO!', `${response.data.message}`, 'error');
			});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card mt-3">
					<div className="card-header form-group">
						<h4>Contato</h4>
					</div>
					<div className="card-body">
						{contacts.length > 0 ? (
							contacts.map(contact => (
								<>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Telefone: </b>(
												{contact.ddd_numero}){' '}
												{contact.tel_numero}
											</p>
										</div>
										{contact.complemento ? (
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>
													<b>Complemento: </b>
													{contact.complemento}
												</p>
											</div>
										) : null}
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Deixar Recados: </b>
												{contact.sms}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<button
												className="btn btn-danger btn-block"
												type="button"
												onClick={() =>
													handleDeletNumber(
														contact.id_contato_has_usuario
													)
												}
											>
												Apagar
											</button>
										</div>
									</div>
									<hr />
								</>
							))
						) : (
							<div className="col-12 col-md-4 col-lg-4 form-group">
								<p>Não há registro no Sistema</p>
							</div>
						)}
						<form onSubmit={handleNewNumber}>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>Tipos de Telefone</p>
									<SelectTipoTelefone
										disabled={lblDisable}
										valueSelect={lblIdTipoTelefone}
										setTipoTelefone={setlblIdTipoTelefone}
									/>
								</div>
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<p>
										<b>DDD</b>
									</p>
									<input
										disabled={lblDisable}
										required
										type="text"
										minLength="2"
										maxLength="2"
										name="lblDDDTel"
										id="lblDDDTel"
										className="form-control"
										placeholder="00"
										value={lblDDDTel}
										onChange={event =>
											setlblDDDTel(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<p>
										<b>Telefone</b>
									</p>
									<input
										disabled={lblDisable}
										required
										minLength="8"
										maxLength="9"
										type="text"
										name="lblNumTelefone"
										id="lblNumTelefone"
										className="form-control"
										placeholder="11111111"
										value={lblNumTelefone}
										onChange={event =>
											setlblNumTelefone(
												event.target.value
											)
										}
									/>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<p>
										<b>Deixar Recados?</b>
									</p>
									<select
										disabled={lblDisable}
										className="form-control"
										name="lblSms"
										id="lblSms"
										value={lblSms}
										onChange={event =>
											setlblSms(event.target.value)
										}
									>
										<option>Recados</option>
										<option value="NÃO">Não</option>
										<option value="SIM">Sim</option>
									</select>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Complemento</b>
									</p>
									<input
										onInput={toInputUppercase}
										disabled={lblDisable}
										type="text"
										name="lblComplementoTel"
										id="lblComplementoTel"
										className="form-control"
										placeholder="Digite o outro número"
										value={lblComplementoTel}
										onChange={event =>
											setlblComplementoTel(
												event.target.value
											)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-3 col-lg-3 form-group">
									<button
										className="btn btn-primary btn-block"
										type="button"
										onClick={event =>
											handleNewNumber(event)
										}
									>
										Adicionar Novo
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	);
}
