import React, { useState, useEffect } from 'react';
import * as ReactIcon from 'react-icons/fa';
import { useSelector } from 'react-redux';
import HeaderDash from '../../components/HeaderDash';
import ChartBar from './Chart/ChartBar';
import ChartLinear from './Chart/ChartLinear';
import MapSchool from '../../components/Map/Georreferencia/MapTown';
import Colors from './Data/colors';
import InputRadio from '../../components/InputRadio';
import SelectComponent from '../../components/Selects/SelectComponent';
import genderArray from '../../data/Sexo';
import yesOrNo from '../../data/YesOrNo';
import colorRace from '../../data/CorRaca';
import '../../styles/dash.css';
import api from '../../services/api';

export default function Card() {
	const userTypeId = useSelector(state => state.userType.actualType);

	const [gender, setGender] = useState('');
	const [schools, setSchools] = useState([]);
	const [idSchool, setIdSchool] = useState('');
	const [transport, setTransport] = useState('');
	const [socialName, setSocialName] = useState('');
	const [race, setRace] = useState('');
	const [typesOfTeachings, setTypesOfTeachings] = useState([]);
	const [idTypeOfTeaching, setIdTypeOfTeaching] = useState('');
	const [classesSchool, setClassesSchool] = useState([]);
	const [idClassesSchool, setIdClassesSchool] = useState('');
	const [neighborhoods, setNeighborhoods] = useState([]);
	const [nameNeighborhood, setNameNeighborhood] = useState('');
	const [dataGraphic, setDataGraphic] = useState([]);

	const headerCards = [
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Professores',
			headerIcon: <ReactIcon.FaChalkboardTeacher />,
			headerNumber: 100,
			iconBackGroundColor: 'bg-light-yellow',
			iconTextColor: 'text-orange',
			headerLinkName: 'teste',
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Escolas',
			headerIcon: <ReactIcon.FaSchool />,
			headerNumber: 100,
			iconBackGroundColor: 'bg-light-blue',
			iconTextColor: 'text-blue',
			headerLinkName: 'teste',
		},
		{
			headerColSize: 'col-lg-4 col-md-10 col-12 pb-2',
			headerLabel: 'Alunos',
			headerIcon: <ReactIcon.FaUserGraduate />,
			headerNumber: 100,
			iconBackGroundColor: 'bg-light-purple',
			iconTextColor: 'text-purple',
			headerLinkName: 'teste',
		},
	];

	async function getSchool() {
		const response = await api.get('/tabelas/lista-escolas');
		setSchools(response.data.data);
	}

	async function getTypesOfTeachings() {
		const response = await api.get('/tabelas/tipos-ensino', {
			params: {
				idSchool,
			},
		});
		setTypesOfTeachings(response.data.data);
	}

	async function getClasses() {
		const response = await api.get('/tabelas/turmas-escola', {
			params: {
				idSchool,
				idTypeOfTeaching,
			},
		});
		setClassesSchool(response.data.data);
	}

	async function getNeighborhoods() {
		const { data } = await api.get('/tabelas/bairros/');
		if (data.data.length > 0) {
			setNeighborhoods(
				data.data.map(d => {
					return {
						id: d.name,
						name: d.name,
					};
				})
			);
		}
	}

	async function getData() {
		const response = await api.get('/dash/tipos-ensino', {
			params: {
				gender,
				idSchool,
				transport,
				socialName,
				race,
				idTypeOfTeaching,
				idClassesSchool,
				userTypeId,
			},
		});
		setDataGraphic(response.data.data);
	}

	useEffect(() => {
		getSchool();
		getTypesOfTeachings();
		getClasses();
		getNeighborhoods();
	}, [idSchool, idTypeOfTeaching, idClassesSchool]);

	return (
		<>
			<div className="filter row">
				<div className="col-11">
					<ReactIcon.FaPlus />
				</div>
				<div className="col-1 button-size">
					<button
						className="btn buttonFilter"
						data-toggle="collapse"
						href="#collapseFilter"
						role="button"
						aria-expanded="false"
						aria-controls="collapseFilter"
					>
						Filtro
					</button>
				</div>
				<div className="col-12 collapse" id="collapseFilter">
					<hr />
					<div className="row">
						<div className="col-md-6 col-12">
							<InputRadio
								setValue={setGender}
								valueSelcted={gender}
								array={genderArray}
								label="Sexo:"
								theSameLine
								optionAll
								fistOption="Todos"
							/>
						</div>
						<div className="col-md-6 col-12">
							<InputRadio
								setValue={setTransport}
								valueSelcted={transport}
								array={yesOrNo}
								label="Utiliza transporte:"
								theSameLine
								optionAll
								fistOption="Todos"
							/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6 col-12">
							<InputRadio
								setValue={setSocialName}
								valueSelcted={socialName}
								array={yesOrNo}
								label="Utiliza Nome Social:"
								theSameLine
								optionAll
								fistOption="Todos"
							/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6 col-12">
							<SelectComponent
								setValue={setIdSchool}
								valueSelcted={idSchool}
								array={schools}
								label="Escolas:"
								theSameLine
							/>
						</div>
						<div className="col-md-6 col-12">
							<SelectComponent
								setValue={setRace}
								valueSelcted={race}
								array={colorRace}
								label="Raça / Cor:"
								theSameLine
							/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6 col-12">
							<SelectComponent
								setValue={setIdTypeOfTeaching}
								valueSelcted={idTypeOfTeaching}
								array={typesOfTeachings}
								label="Tipo de Ensino:"
								theSameLine
							/>
						</div>
						<div className="col-md-6 col-12">
							<SelectComponent
								setValue={setIdClassesSchool}
								valueSelcted={idClassesSchool}
								array={classesSchool}
								label="Classe: "
								theSameLine
							/>
						</div>
					</div>
					<div className="mt-3 mb-3">
						<SelectComponent
							setValue={setNameNeighborhood}
							valueSelcted={nameNeighborhood}
							array={neighborhoods}
							label="Bairro: "
							theSameLine={false}
						/>
					</div>
				</div>
			</div>
			<HeaderDash headerItems={headerCards} />
			<div className="row mt-3">
				<div className="col-6">
					<ChartBar
						title="Alunos por Escola"
						quantities={[10, 50, 100]}
						colors={Colors}
						labelsArray={['Escola X', 'Escola Y', 'Escola Z']}
					/>
				</div>
				<div className="col-6">
					<ChartBar
						title="Alunos por Bairro"
						quantities={[10, 50, 100]}
						colors={Colors}
						labelsArray={['Bairro X', 'Bairro Y', 'Bairro Z']}
					/>
				</div>
			</div>
			<div className="mt-3">
				<ChartLinear
					title="Alunos em Matriculados"
					quantities={[10, 23, 5]}
					labelsArray={['Jan', 'Fev', 'Mar']}
				/>
			</div>
			<div className="mt-3 mb-3">
				<MapSchool />
			</div>
		</>
	);
}
