import React from 'react';
import CardsCoordenator from './CardsCoordenator';
import ListNotices from '../../../components/Notices/ListNotices';
import VideoLesson from '../../../components/VideoLesson';

export default function Dash() {
	return (
		<main>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<CardsCoordenator />
						<ListNotices />
						<VideoLesson/>
					</div>
				</div>
			</div>
		</main>
	);
}
