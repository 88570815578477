import axios from 'axios';

import env from '../env';
import parseJwt from '../../src/helpers/parseJwt';

const api = axios.create({
	baseURL: env.USE_TEST_API ? env.API_TEST_URL : env.API_URL,
});

api.interceptors.request.use(async config => {
	const token = localStorage.getItem('@token');
	const refreshToken = localStorage.getItem('@refreshToken');
	const tokenUsuario = token != null && parseJwt(token);

	if (refreshToken && token) {
		config.headers.Authorization =
			tokenUsuario.exp > Date.now() / 1000
				? `Bearer ${token}`
				: `Bearer ${refreshToken}`;
	}
	return config;
});

export default api;
