import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import FormProtocolUpdate from '../../../components/Forms/FormProtocolUpdate';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Breadcrumb from '../../../components/Breadcrumb';

export default function Protocols() {
	const { protocolo } = useParams();
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();
	const [infoProtocol, setInfoProtocol] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.NURSERY_VACANCIES,
			namePage: 'Vagas em Creches',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Detalhes',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	useEffect(() => {
		(async function() {
			const { data } = await api.get(`/vagas-em-creches/${protocolo}`);
			if (data.error) {
				setInfoProtocol([]);
				swal({
					icon: 'warning',
					text: data.message,
					title: 'Ops!',
				}).then(value => {
					history.push(
						Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
							.NURSERY_VACANCIES
					);
				});
			} else setInfoProtocol(data.data[0]);
			setIsLoading(false);
		})();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<h5 className="mt-3">
						<b>Protocolo: {infoProtocol.protocolo}</b>
					</h5>
					<div className="card mt-3">
						<div className="container">
							<FormProtocolUpdate
								protocol={infoProtocol}
								setProtocols={setInfoProtocol}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}
