import React from 'react';
import { FaPlay } from 'react-icons/fa';
// import { Container } from './styles';

function VideoLesson() {
    const userType = localStorage.getItem('@actualTypeId')

    const videos = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [{ "name": "Pagina inicial", "link": "https://vimeo.com/586870813" },
        { "name": "Avaliação", "link": "https://vimeo.com/586870681" },
        { "name": "Alunos", "link": "https://vimeo.com/586870516" },
        { "name": "Atribuição", "link": "https://vimeo.com/586870598" },
        { "name": "Comunicados", "link": "https://vimeo.com/586870737" },
        { "name": "Disciplinas", "link": "https://vimeo.com/586870775" }],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],

    ];
    return (
        <>
            <div id="accordion" className="mt-3">
                <div className="card ">
                    <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                            <button className="btn btn-link" style={{ fontSize: "18px" , textDecoration: "none"}} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Video Aulas
        					</button>
                        </h5>
                    </div>

                    <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            <ul className="list-group">
                                {
                                    videos[parseInt(userType)] != null ?
                                        videos[parseInt(userType)].map((value) =>
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                {value.name}
                                                <a href={value.link} target="_blank">
                                                    <FaPlay />
                                                </a>
                                            </li>
                                        ) : <div className="badges-datas-eventos">
                                                Nenhum video disponível
											</div>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoLesson;