import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import semecLogo from '../../assets/img/semec_prefeitura.png';
import api from '../../services/api';
import './style.css';
import parseJwt from '../../helpers/parseJwt';
import { UserTypeActions } from '../../redux/ducks/userTypeReducer';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorLogin, setErrorLogin] = useState('');
	const [disableButton, setDisableButton] = useState(false);
	const [buttonLogin, setButtonLogin] = useState('Entrar');
	const history = useHistory();

	const dispatch = useDispatch();
	function handleLogin(event) {
		setButtonLogin('Carregando...');
		setDisableButton(true);
		event.preventDefault();
		setErrorLogin('');
		(async function() {
			const response = await api.post('/login', {
				login: email,
				senha: password,
			});
			if (!response.data.error) {
				localStorage.setItem('@refreshToken', response.data.data.refreshToken);
				localStorage.setItem('@token', response.data.data.token);
				const tokenUsuario = parseJwt(response.data.data.token);
				dispatch(
					UserTypeActions.updateUserTypeIds(tokenUsuario.tipo_usuario)
				);
				if (tokenUsuario.tipo_usuario.length === 1) {
					localStorage.setItem(
						'@actualTypeId',
						tokenUsuario.tipo_usuario[0]
					);
					dispatch(
						UserTypeActions.updateActualTypeId(
							tokenUsuario.tipo_usuario[0]
						)
					);
					history.push(
						Routes.LOGGED_ROUTES(
							RouteByPermission[tokenUsuario.tipo_usuario[0]]
						).HOME
					);
				}
			} else if (response.data.message) {
				swal('ATENÇÃO', `${response.data.message}`, 'warning');
				setErrorLogin(response.data.message);
			}
			setButtonLogin('Entrar');
			setDisableButton(false);
		})();
	}

	return (
		<>
			<div className="background_page_form">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-lg-7 col-md-9">
							<div className="card" id="login-card">
								<div className="card-body">
									<div className="row justify-content-center text-center">
										<div className="col-12">
											<img
												src={semecLogo}
												className="logoSchool"
												alt="Logo from school"
											/>
										</div>
									</div>
									<form
										className="login_page_form"
										onSubmit={handleLogin}
									>
										<div className="row justify-content-center">
											<div className="form-group col-10">
												<label htmlFor="loginEmail">
													Login
												</label>
												<input
													type="text"
													name="loginEmail"
													id="loginEmail"
													placeholder="Digite seu login"
													className="form-control"
													onChange={event =>
														setEmail(
															event.target.value
														)
													}
													required
												/>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="form-group col-10">
												<label htmlFor="passwordLogin">
													Senha
												</label>
												<input
													type="password"
													name="passwordLogin"
													id="passwordLogin"
													placeholder="Digite a sua senha"
													className="form-control"
													onChange={event =>
														setPassword(
															event.target.value
														)
													}
													required
												/>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="form-group col-10">
												<span className="errorLogin">
													{errorLogin}
												</span>
											</div>
										</div>
										<div className="row justify-content-center text-center">
											<div className="col-10 form-group">
												<button
													type="submit"
													disabled={disableButton}
													className="btn btn-primary btn-block"
													id="loginBtn"
												>
													{buttonLogin}
												</button>
											</div>
										</div>
									</form>
									<div className="row justify-content-center text-center">
										<div className="col-10 form-group">
											<Link
												to={Routes.FORGOT_PASSWORD}
												id="forgotpassword"
											>
												Esqueceu Senha? Clique Aqui.
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
