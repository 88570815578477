import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaTimesCircle } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';

function OptionsButton({ setChangeSchool }) {
	function changeSchool() {
		swal(
			'Cuidado',
			'Caso você escolher outra escola, o responsável estará aceitando arcar com os custos do transporte.',
			'warning'
		);
		swal({
			title: 'Cuidado?',
			text:
				'Caso você escolher outra escola, o responsável estará aceitando arcar com os custos do transporte.',
			icon: 'warning',
			buttons: ['Cancelar', 'Confirmar'],
			dangerMode: true,
		}).then(willDelete => {
			if (willDelete) setChangeSchool(true);
			else swal('Ação cancelada');
		});
	}

	return (
		<button
			aria-hidden
			data-toggle="tooltip"
			title="Trocar de Escola"
			type="button"
			className="btn buttonCustomCancelSemec form-control"
			onClick={() => changeSchool()}
		>
			Trocar
		</button>
	);
}

function CheckboxElement({ idSchool, setIdSchool }) {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checked === true) {
			setChecked(false);
			return setIdSchool(idSchool);
		}
	}, [checked]);

	return (
		<input
			required
			type="radio"
			name="groupSchool"
			onClick={() => setChecked(!checked)}
		/>
	);
}

export default function() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Matricular Aluno na Escola',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const [lblNomeAluno, setlblNomeAluno] = useState('');
	const [lblNomeMae, setlblNomeMae] = useState('');
	const [lblDataNasimento, setlblDataNasimento] = useState('');
	const [lblDataInicioMatricula, setlblDataInicioMatricula] = useState('');
	const [lblClasse, setlblClasse] = useState([]);
	const [lblIdClasse, setlblIdClasse] = useState(null);
	const [changeRA, setChangeRA] = useState(false);
	const [VisibleInputs, setVisibleInputs] = useState(false);
	const [schoolsNear, setSchoolsNear] = useState([]);
	const [schools, setSchools] = useState([]);
	const [idSchool, setIdSchool] = useState(null);
	const [disableFormStudent, setDisableFormStudent] = useState(false);
	const [editableFormStudent, setEditableFormStudent] = useState(false);
	const [textButton, setTextButton] = useState('Não sei o RA');
	const [changeSchool, setChangeSchool] = useState(false);
	const [editableCpf, setEditableCpf] = useState(false);
	const [confirmButton, setConfirmButton] = useState(true);
	const [RaAluno, setRaAluno] = useState('');

	async function handleChangeRA() {
		setChangeRA(false);
		setEditableCpf(false);
		setlblIdClasse(null);
		setSchoolsNear([]);
		setlblClasse([]);
	}

	async function handleChangeStudent(event) {
		event.preventDefault();
		setSchoolsNear([]);
		setlblClasse([]);
		setChangeSchool(false);
		setEditableFormStudent(false);
		setDisableFormStudent(false);
	}

	async function handleChangeSearch(event) {
		event.preventDefault();
		setVisibleInputs(VisibleInputs !== true);
		setTextButton(VisibleInputs !== true ? 'Digitar RA' : 'Não sei o RA');
		setChangeSchool(false);
		setEditableFormStudent(false);
		setDisableFormStudent(false);
		setIdSchool(null);
		setlblIdClasse(null);
		handleChangeRA();
		setlblNomeAluno('');
		setlblNomeMae('');
		setlblDataNasimento('');
		setRaAluno('');
	}

	async function handleExistStudentsRA(event) {
		event.preventDefault();
		setlblIdClasse(null);
		const lengthRa = RaAluno.replace(/[-_.+()\s]/g, '');
		if (RaAluno && lengthRa.length >= 12) {
			const { data } = await api.post('/aluno/escolas-proximas', {
				RaAluno,
			});
			if (data.error === true) swal('Ops!', data.message, 'warning');
			else {
				setEditableCpf(true);
				setChangeRA(true);
				setSchoolsNear(data.data);
			}
		} else swal('ATENÇÃO', 'RA inválido.', 'warning');
	}

	async function handleExistStudents(event) {
		event.preventDefault();
		setlblIdClasse(null);
		const inputs = {
			nomeMae: lblNomeMae,
			nomeUsuario: lblNomeAluno,
			dataNascimento: lblDataNasimento,
			RaAluno: null,
		};
		const { data } = await api.post('/aluno/escolas-proximas', inputs);
		if (data.error === true) swal('Ops!', data.message, 'warning');
		else {
			setDisableFormStudent(true);
			setEditableFormStudent(true);
			setSchoolsNear(data.data);
		}
	}

	async function handleCancel(event) {
		event.preventDefault();
		history.goBack();
	}

	async function handleSubmit(event) {
		event.preventDefault();
		if (
			(lblNomeAluno === '' ||
				lblNomeMae === '' ||
				lblDataNasimento === '') &&
			RaAluno === ''
		)
			return swal({ icon: 'error', text: 'Campos vazios' });
		try {
			const data = new FormData();
			data.append('lblNomeAluno', lblNomeAluno);
			data.append('lblNomeMae', lblNomeMae);
			data.append('lblDataNasimento', lblDataNasimento);
			data.append('lblDataInicioMatricula', lblDataInicioMatricula);
			data.append('lblIdClasse', lblIdClasse);
			data.append('idEscola', idSchool);
			data.append('RaAluno', RaAluno);

			await api
				.post('/aluno/perfil/atualiza/matricular/escola', data)
				.then(response => {
					if (!response.data.error)
						swal('SUCESSO!', `${response.data.message}`, 'success');
					else swal('Cuidado', `${response.data.message}`, 'warning');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	async function handleChangeSchool(event) {
		event.preventDefault();
		setlblIdClasse(null);
		setChangeSchool(false);
	}

	const classeList = lblClasse.map(classe => (
		<option value={classe.id_classe} key={classe.id_classe}>
			{classe.desc_tipo_ensino}: {classe.desc_serie_ano} - {classe.turma}
		</option>
	));

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchools([]);
			else setSchools(data.data);
		})();
	}, []);

	useEffect(() => {
		if (idSchool)
			(async function() {
				const response = await api.get('/classes/lista', {
					params: {
						schoolId: idSchool,
					},
				});
				if (!response.data.error) setlblClasse(response.data.data);
				else swal({ icon: 'warning', text: response.data.message });
			})();
	}, [idSchool]);

	useEffect(() => {
		const data = lblDataInicioMatricula.replace(/[-_.+()\s]/g, '');
		if (lblIdClasse && data !== '') setConfirmButton(false);
	}, [lblIdClasse, lblDataInicioMatricula]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">
					<p>
						<b>Matricular Aluno</b>
					</p>
					<h6>
						Esse método tem como finalidade a criação de matriculas
						de continuidade (rematricula), efetivação de inscriçõese
						definições(matricula antecipada) e matriculas nos demais
						tipos de ensino (educação infantil, ensino
						profissionalizante, atividade complementar, etc..)
					</h6>
				</div>
				<div className="card-body">
					{VisibleInputs ? (
						<form onSubmit={handleExistStudents}>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Nome Aluno</b>
									</p>
									<input
										disabled={disableFormStudent}
										type="text"
										name="lblNomeAluno"
										id="lblNomeAluno"
										className="form-control"
										placeholder="Nome Aluno"
										required
										onChange={event =>
											setlblNomeAluno(event.target.value)
										}
									/>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Nome Mãe</b>
									</p>
									<input
										disabled={disableFormStudent}
										type="text"
										name="lblNomeMae"
										id="lblNomeMae"
										className="form-control"
										placeholder="Nome Mãe"
										required
										onChange={event =>
											setlblNomeMae(event.target.value)
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<p>
										<b>Data Nascimento</b>
									</p>
									<InputMask
										disabled={disableFormStudent}
										mask="99/99/9999"
										required
										type="text"
										name="lblDataNasimento"
										id="lblDataNasimento"
										className="form-control"
										placeholder="Data Nascimento"
										value={lblDataNasimento}
										onChange={event =>
											setlblDataNasimento(
												event.target.value
											)
										}
									/>
								</div>
								<div className="align-self-end col-12 col-md-6 col-lg-6 form-group">
									<div />
									{editableFormStudent === false ? (
										<button
											className="btn btn-primary"
											type="submit"
											id="button-addon2"
										>
											Consultar
										</button>
									) : (
										<button
											onClick={handleChangeStudent}
											className="btn btn-primary form-control"
											type="button"
											id="button-addon2"
										>
											Mudar
										</button>
									)}
								</div>
							</div>
						</form>
					) : (
						<div className="row">
							<div className="col-12 col-md-6 col-lg-6 form-group">
								<InputMask
									mask="999999999999"
									disabled={changeRA}
									type="text"
									name="RaAluno"
									id="RaAluno"
									className="form-control"
									placeholder="RA do Aluno"
									value={RaAluno}
									onChange={event =>
										setRaAluno(event.target.value)
									}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-6 form-group">
								{editableCpf === false ? (
									<button
										onClick={handleExistStudentsRA}
										className="btn btn-primary"
										type="button"
										id="button-addon2"
									>
										Consultar
									</button>
								) : (
									<button
										onClick={handleChangeRA}
										className="btn btn-primary form-control"
										type="button"
										id="button-addon2"
									>
										Mudar
									</button>
								)}
							</div>
						</div>
					)}
					<button
						onClick={handleChangeSearch}
						className="btn btn-warning form-group"
						type="button"
						id="button-addon2"
					>
						{textButton}
					</button>
				</div>
			</div>
			<form className="mb-3" onSubmit={handleSubmit}>
				<div className="card mt-3">
					<div className="card-header col-12 col-md-12 col-lg-12 form-group">
						<h4>Escola</h4>
					</div>
					<div className="card-body">
						{schoolsNear.length ? (
							<>
								{changeSchool ? (
									<div className="row">
										<div className="col-lg-8 col-12 form-group">
											<p htmlFor="school">
												<b>Escola</b>
											</p>
											<select
												required
												id="school"
												className="form-control"
												onChange={event =>
													setIdSchool(
														event.target.value
													)
												}
											>
												<option value="">
													Escolha a escola
												</option>
												{schools.map(schoolItem => (
													<option
														key={
															schoolItem.id_escola
														}
														value={
															schoolItem.id_escola
														}
													>
														{
															schoolItem.desc_nome_escola
														}
													</option>
												))}
											</select>
										</div>
										<div className="align-self-end col-lg-4 col-12 form-group">
											<button
												onClick={handleChangeSchool}
												className="btn btn-primary"
												type="button"
											>
												Escolas Próximas
											</button>
										</div>
									</div>
								) : (
									<div className="table-responsive">
										<Table
											header={[
												'',
												'Nome',
												'Endereço',
												'Distancia',
												'',
											]}
											body={schoolsNear.map(school => {
												return [
													<CheckboxElement
														idSchool={
															school.id_escola
														}
														setIdSchool={
															setIdSchool
														}
													/>,
													school.desc_nome_escola,
													`${school.logradouro}, ${school.numero} - ${school.bairro}, ${school.nome_cidade} ${school.cep}`,
													school.distancia,
													<OptionsButton
														setChangeSchool={
															setChangeSchool
														}
													/>,
												];
											})}
										/>
									</div>
								)}
								<div className="mt-3">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Classe</b>
											</p>
											<select
												required
												className="form-control"
												name="lblIdClasse"
												id="lblIdClasse"
												value={lblIdClasse}
												onChange={event =>
													setlblIdClasse(
														event.target.value
													)
												}
											>
												<option value="">
													Escolha uma Classe
												</option>
												{classeList}
											</select>
										</div>
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>
												<b>Data Matricula</b>
											</p>
											<InputMask
												mask="99/99/9999"
												required
												type="text"
												name="lblDataInicioMatricula"
												id="lblDataInicioMatricula"
												className="form-control"
												placeholder="Data Matricula"
												value={lblDataInicioMatricula}
												onChange={event =>
													setlblDataInicioMatricula(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhuma escola encontrada.
								</p>
							</div>
						)}
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-12 col-md-10 col-lg-10">
								<button
									type="submit"
									className="btn btn-danger"
									onClick={handleCancel}
								>
									Cancelar
								</button>
							</div>
							<div className="col-12 col-md-2 col-lg-2">
								<button
									disabled={confirmButton}
									type="submit"
									className="btn btn-primary"
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	);
}
