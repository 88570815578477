import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import CalendarItem from '../CalendarItem';
import './style.css';
import api from '../../../../services/api';

export default function({ period, isEditable = true, isAddMenu = false }) {
	const [meals, setMeals] = useState([]);
	const [datasheet, setDatasheet] = useState([]);
	const mealsPeriod = {
		1: ['Café', 'Almoço'],
		2: ['Almoço', 'Lanche'],
		3: ['Jantar', 'Lanche'],
		4: ['Café', 'Almoço', 'Lanche'],
	};

	const weekDays = [
		'Segunda-Feira',
		'Terça-Feira',
		'Quarta-Feira',
		'Quinta-Feira',
		'Sexta-Feira',
	];

	async function getMeals() {
		const response = await api.get('nutricionista/cardapios');

		!response.data.error
			? setMeals(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function getDatasheet() {
		const response = await api.get(
			'nutricionista/cardapios/fichas-tecnicas'
		);

		!response.data.error
			? setDatasheet(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	useEffect(() => {
		if (isEditable) getMeals();
		else getDatasheet();
	}, []);

	return (
		<>
			<div className="calendarContainer card">
				<div className="table-responsive">
					<table className="table">
						<thead>
							<tr>
								<th scope="col" />
								{weekDays.map(item => (
									<th scope="col">{item}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{mealsPeriod[period].map((meal, line) => {
								return (
									<tr>
										<th scope="row">{meal}</th>
										{weekDays.map((_, column) => (
											<td>
												<CalendarItem
													line={line}
													column={column}
													isEditable={isEditable}
													isAddMenu={isAddMenu}
													datasheet={
														!isEditable
															? datasheet
															: null
													}
													meals={
														isEditable
															? meals
															: null
													}
												/>
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
