import React from 'react';

// eslint-disable-next-line react/prop-types
export default function SelectQuilombola({ setQuilombola, valueSelect, disabled }) {

	// eslint-disable-next-line no-shadow
	async function handleSubmit(e) {
		setQuilombola(e);
	}
	return (
		<>
			<select
                required
                className="form-control"
                name="lblQuilombola"
                id="lblQuilombola"
                value={valueSelect}
				disabled={disabled}
                onChange={event =>
                    handleSubmit(event.target.value)
                }
            >
                <option>Não</option>
                <option value="1">Sim</option>
            </select>
		</>
	);
}
