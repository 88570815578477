import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectEscolaRede({
	setIdSchool,
	valueSelect,
	disabled,
	required,
}) {
	const [schools, setSchools] = useState([]);

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if(data.error) setSchools([]);
			else setSchools(data.data);
		})();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(idSchool) {
		setIdSchool(idSchool);
	}
	const ListSchool = schools.map(U => (
		<option value={U.id_escola} key={U.id_escola}>
			{U.desc_nome_escola}
		</option>
	));
	return (
		<>
			<select
				required={required}
				className="form-control"
				id="idSchool"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha uma Escola</option>
				{ListSchool}
			</select>
		</>
	);
}
