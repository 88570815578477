import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectSerieAno({ setIdSerieAno, valueSelect }) {
	const [seriesano, setSeriesAno] = useState([]);

	useEffect(() => {
		(async function() {
			const response = await api.post('/tabelas/filtro/serie-ano');
			setSeriesAno(response.data.data);
		})();
	}, []);

	async function handleSubmit(serie) {
		setIdSerieAno(serie);
	}
	const SereiAnoList =seriesano && seriesano.map(p => (
		<option value={p.id_serie_ano} key={p.id_serie_ano}>
			{p.desc_serie_ano} {p.desc_tipo_ensino}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblSelectAnoList"
				value={valueSelect}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha uma Serie/Ano</option>
				{SereiAnoList}
			</select>
		</>
	);
}
