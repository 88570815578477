import React from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';

import './styles.css';

import { FaTrash } from 'react-icons/fa';

function ViewEvaluation({
	avaliacao,
	atualiza = null,
	student = null,
}) {
	const userActualType = useSelector(state => state.userType.actualType);

	async function handleDeleteVideoModule() {
		await api.delete(
			`/avaliacao/delete-avaliacao-modulo/${avaliacao.modulo_id}/${avaliacao.avaliacao_id}`
		);
		atualiza();
	}

	return (
		<>
			<div className="contentVideo">
				<div className="titleDescri">
					{student ? (
						<Link
							to={{
								pathname: Routes.LOGGED_ROUTES(
									RouteByPermission[userActualType]
								).EVALUATION,
								state: {
									id: avaliacao.avaliacao_id,
									fim: avaliacao.fim,
									moduloId:avaliacao.modulo_id
								},
							}}
						>
							<h5>{avaliacao.titulo}</h5>
							<span>{avaliacao.descricao}</span>
							<section>
								<span><b>Inicio:</b>{avaliacao.inicio && avaliacao.inicio.substr(
									0,
									10
								)
									.split(
										'-'
									)
									.reverse()
									.join(
										'/'
									)}
								</span>
								<span><b>Fim:</b>{avaliacao.fim && avaliacao.fim.substr(
									0,
									10
								)
									.split(
										'-'
									)
									.reverse()
									.join(
										'/'
									)}</span>
							</section>
						</Link>
					) : (
							<>
								<h5>{avaliacao.titulo}</h5>
								<span>{avaliacao.descricao}</span>
								<section>
									<span><b>Inicio:</b>{avaliacao.inicio && avaliacao.inicio.substr(
										0,
										10
									)
										.split(
											'-'
										)
										.reverse()
										.join(
											'/'
										)}
									</span>
									<span><b>Fim:</b>{avaliacao.fim && avaliacao.fim.substr(
										0,
										10
									)
										.split(
											'-'
										)
										.reverse()
										.join(
											'/'
										)}</span>
								</section>
							</>
						)}
				</div>
				<div className="buttonGroup">
					{student ? null : (
						<button type="button" onClick={handleDeleteVideoModule}>
							<FaTrash />
						</button>
					)}
				</div>
			</div>
		</>
	);
}

export default ViewEvaluation;
