import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import './styles.css';

function Question({ questao, respostaDefault, salvarResposta = null }) {
	const { descricao, titulo, peso, numero_questao } = questao;

	const handleSalvarRespostaTexto = respostaTexto => {
		salvarResposta(respostaTexto);
	};

	const handleSalvarRespostaOption = alternativas => {
		salvarResposta(alternativas);
	};

	return (
		<div className="bodyAvaliacao">
			<div className="infoQuestao">
				<strong>
					Questão <b>{numero_questao}</b>
					{questao.resultado > 0 ? <FaCheck color="green" /> : null}
				</strong>

				<strong>
					Peso <b>{peso}</b>
				</strong>
			</div>
			<div className="card" id="questaoContent">
				<div className="card-body">
					<h3>{titulo}</h3>
					<h5>{descricao}</h5>
					{questao &&
						(questao.alternativa ? (
							questao.alternativa.length ? (
								<QuestionOption
									questao={questao}
									respostaDefault={respostaDefault}
									salvarResposta={handleSalvarRespostaOption}
								/>
							) : (
								<QuestionText
									questao={questao}
									respostaDefault={respostaDefault}
									salvarResposta={handleSalvarRespostaTexto}
								/>
							)
						) : (
							<ShowQuestionWithAnswer questao={questao} />
						))}
				</div>
			</div>
		</div>
	);
}

export default Question;

function ShowQuestionWithAnswer({ questao }) {
	return (
		<div className="wrapperAlternatives">
			<strong>
				<b>resposta:</b>
				{questao.resposta}
			</strong>
		</div>
	);
}

function QuestionText({ questao, respostaDefault, salvarResposta = null }) {
	const [respostaTexto, setRespostaTexto] = useState('');

	useEffect(() => {
		setRespostaTexto(respostaDefault || '');
	}, [respostaDefault]);

	const handleOnChange = (event, index = null) => {
		const novoTexto = event.target.value;

		setRespostaTexto(novoTexto);
		salvarResposta(novoTexto);
	};

	return (
		<div className="wrapperAlternatives">
			<textarea
				name="resposta"
				className="form-control"
				value={respostaTexto}
				onChange={handleOnChange}
			/>
		</div>
	);
}

function QuestionOption({ questao, respostaDefault, salvarResposta = null }) {
	const [alternativas, setAlternativas] = useState({});
	const [certaID, setCertaId] = useState(0);

	useEffect(() => {
		setAlternativas(respostaDefault || {});
	}, [respostaDefault]);

	const handleOnChange = (idAlternativa, isChecked) => {
		setCertaId(idAlternativa);
		const newAlternativas = {
			[idAlternativa]: isChecked /*true|false*/,
		};
		setAlternativas(newAlternativas);

		salvarResposta({ [idAlternativa]: isChecked });
	};

	return (
		<div className="wrapperAlternatives">
			{questao.alternativa.map((alternativa, index) => (
				<div className="form-check" id="checkAlternativeCorret">
					<input
						type="radio"
						name="certa"
						value={1}
						onChange={event =>
							handleOnChange(
								alternativa.id_alternativa,
								event.target.checked
							)
						}
						id={`customRadioInline${index + 3}`}
					/>
					<label
						className="form-check-label"
						for={`customRadioInline${index + 3}`}
					>
						<label className="form-check-label" htmlFor="certa">
							{alternativa.descricao}
						</label>
					</label>
				</div>
			))}
		</div>
	);
}
