import React, { useState } from 'react';
import semecLogo from '../../../assets/img/semec_prefeitura.png';
import api from '../../../services/api';
import Routes from "../../../routes/data/Routes";

export default function VerifyCode({ history }) {
	const [code, setCode] = useState('');
	const [errorCode, setErrorCode] = useState('');

	async function handleVerifyCode(event) {
		event.preventDefault();
		const response = await api.post('/verificacodigo', {
			codigo: code,
		});
		if (!response.data.error) {
			const userID = response.data.data;
			localStorage.setItem('userID', userID);
			history.push(Routes.UPDATE_PASSWORD);
		} else setErrorCode(response.data.message);
	}
	return (
		<>
			<div className="background_page_form">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-lg-7 col-md-9 mt-5">
							<div className="card" id="login-card">
								<div className="card-body">
									<div className="row justify-content-center text-center">
										<div className="col-12">
											<img
												src={semecLogo}
												className="logoSchool"
												alt="Logo from school"
											/>
										</div>
									</div>
									<form
										className="login_page_form"
										onSubmit={handleVerifyCode}
									>
										<div className="row justify-content-center">
											<div className="form-group col-12 col-lg-10">
												<label htmlFor="codeEmail">
													Código de recuperação
												</label>
												<input
													type="text"
													name="codeEmail"
													id="codeEmail"
													placeholder="Digite o código enviado"
													className="form-control"
													onChange={event =>
														setCode(
															event.target.value
														)
													}
													required
												/>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="col-10 form-group">
												<span className="errorLogin">
													{errorCode}
												</span>
											</div>
										</div>
										<div className="row justify-content-center text-center">
											<div className="col-12 col-lg-10 form-group">
												<button
													type="submit"
													className="btn btn-primary btn-block"
													id="verifyCodeBtn"
												>
													Verificar
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
