import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectPais({ setCodPais, valueSelect, disabled }) {
	const [paises, setPais] = useState([]);
	useEffect(() => {
		async function loadPais() {
			const response = await api.get('/tabelas/pais');
			setPais(response.data.data);
		}
		loadPais();
	}, []);

	async function handleSubmit(pais) {
		setCodPais(pais);
	}
	const PaisList = paises.map(p => (
		<option value={p.cod_pais} key={p.cod_pais}>
			{p.nome_pais}
		</option>
	));
	return (
		<>
			<select
				required
				className="form-control"
				id="lblIdPais"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Escolha um Pais</option>
				{PaisList}
			</select>
		</>
	);
}
