import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FaSchool } from 'react-icons/fa';
import { Tooltip } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import RouteParams from '../../../routes/data/RouteParams';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import ModalTypeOfTeaching from '../../../components/Modal/ModalTypeOfTeaching';
import ModalComunicated from '../../../components/Modal/ModalComunicated';

export default function InfoSchool() {
	const { state } = useLocation();
	const userActualType = useSelector(t => t.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.SCHOOLS,
			namePage: 'Lista de Escolas',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Informação da Escola',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	const history = useHistory();
	const [schoolInfo, setSchoolInfo] = useState([]);
	const [teachingType, setTeachingType] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async function() {
			if (state) {
				const { data } = await api.get('/escola/info/cod', {
					params: {
						CodEscola:
							state[
								RouteParams.LEVEL_06_ROUTES.SCHOOL_INFO
									.SCHOOL_CODE
							],
					},
				});
				if (data.data) {
					setIsLoading(false);
					setSchoolInfo(data.data);
				} else {
					swal('Não foi encontrado');
					history.goBack();
				}

				const response = await api.get('/tabelas/tipo-ensino');
				if (response.data.data) {
					setIsLoading(false);
					setTeachingType(response.data.data);
				}
			} else {
				swal('Erro ao encontrar código da escola');
				history.goBack();
			}
		})();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<div className="card">
						<div className="card-header">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<h5>
										<b>Sobre a Escola</b>
									</h5>
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-1 col-lg-1 form-group">
									<h1>
										<FaSchool />
									</h1>
								</div>
								<div className="mt-4 col-12 col-md-11 col-lg-11 form-group">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											{schoolInfo.desc_nome_escola && (
												<p className="textoCapitalize">
													{schoolInfo.desc_nome_escola.charAt(
														0
													) +
														schoolInfo.desc_nome_escola
															.slice(1)
															.toLowerCase()}
												</p>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{userActualType === 1 ? null : (
							<div className="card-footer">
								<Tooltip
									title="Escrever um Comunicado para esta Escola"
									placement="top-start"
								>
									<button
										className="btn btn-primary"
										type="submit"
										data-toggle="modal"
										data-target="#modalComunicated"
									>
										Comunicado
									</button>
								</Tooltip>
							</div>
						)}
					</div>
					<br />
					<div className="card">
						<div className="card-header form-group">
							<h4>Dados Básicos</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Nome Diretoria</b>
									<p>{schoolInfo.desc_nome_diretoria}</p>
								</div>
								<div className="col-12 col-md-6 col-lg-6 form-group">
									<b>Rede Ensino</b>
									<p>{schoolInfo.nome_rede_ensino}</p>
								</div>
							</div>
							{schoolInfo.tipo_de_ensino && (
								<>
									<hr />
									<b>Tipo de Ensino</b>
									<ul className="container">
										{schoolInfo.tipo_de_ensino.map(item => (
											<li key={item.id_serie_ano}>
												{item.desc_serie_ano}
											</li>
										))}
									</ul>
								</>
							)}
						</div>
						{userActualType === 1 ? null : (
							<div className="card-footer">
								<Tooltip
									title="Editar o tipo de ensino da escola"
									placement="top-start"
								>
									<button
										className="btn btn-primary"
										type="submit"
										data-toggle="modal"
										data-target="#modalEditType"
									>
										Editar Tipo de Ensino
									</button>
								</Tooltip>
							</div>
						)}
					</div>
					<br />
					<div className="card">
						<div className="card-header">
							<h4>Endereço</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>CEP</b>
									<p>{schoolInfo.cep}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Tipo Logradouro</b>
									<p>{schoolInfo.tipo_logradouro}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Logradouro</b>
									<p>{schoolInfo.logradouro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Número</b>
									<p>{schoolInfo.numero}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Complemento</b>
									<p>
										{schoolInfo.complemento
											? schoolInfo.complemento
											: 'Complemento não informado'}
									</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Bairro</b>
									<p>{schoolInfo.bairro}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Cidade</b>
									<p>{schoolInfo.nome_cidade}</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>UF</b>
									<p>{schoolInfo.sigla_uf}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Área do logradouro</b>
									<p>
										{schoolInfo.desc_area
											? schoolInfo.desc_area
											: 'Área do logradouro não informado'}
									</p>
								</div>
								<div className="col-12 col-md-4 col-lg-4 form-group">
									<b>Localização Diferenciada</b>
									<p>
										{schoolInfo.desc_localizacao
											? schoolInfo.desc_localizacao
											: 'Localização não informado'}
									</p>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="card">
						<div className="card-header">
							<div className="col-12 col-md-12 col-lg-12 form-group">
								<b>Contato</b>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12 form-group">
									<div className="contato row">
										<div className="col-12 col-md-2 col-lg-2 form-group">
											<b>Telefone:</b>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												({schoolInfo.ddd_numero}){' '}
												{schoolInfo.tel_numero}
											</p>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<b>E-mail:</b>
										</div>
										<div className="col-12 col-md-5 col-lg-5 form-group">
											<p>
												{schoolInfo.email ? (
													<>{schoolInfo.email}</>
												) : (
													'Sem e-mail cadastrado'
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<ModalComunicated
				userActualType={userActualType}
				schoolInfo={schoolInfo}
			/>
			<ModalTypeOfTeaching
				schoolInfo={schoolInfo}
				setSchoolInfo={setSchoolInfo}
				teachingType={teachingType}
			/>
		</>
	);
}
