import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import cep from 'cep-promise';
import InputMask from 'react-input-mask';
import { FaSearch } from 'react-icons/fa';
import api from '../../../services/api';
import SelectPais from '../../../components/Selects/SelectPais';
import SelectCidade from '../../../components/Selects/SelectCidade';
import SelectUf from '../../../components/Selects/SelectUf';
import SelectSexo from '../../../components/Selects/SelectSexo';
import SelectCorRaca from '../../../components/Selects/SelectCorRaca';
import SelectTipoTelefone from '../../../components/Selects/SelectTipoTelefone';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import CardUserContacts from '../../../components/Contact/CardUserContacts';
import SelectParentesco from '../../../components/Selects/SelectParentesco';

export default function() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.LIST_PARENTS,
			namePage: 'Responsáveis',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Cadastro Responsável',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [lblNomeResponsavel, setlblNomeResponsavel] = useState('');
	const [
		lblDataNascimentoResponsavel,
		setlblDataNascimentoResponsavel,
	] = useState('');
	const [lblEmail, setlblEmail] = useState('');
	const [lblParentesco, setlblParentesco] = useState('');
	const [lblIdCorRaca, setlblIdCorRaca] = useState('');
	const [lblIdSexo, setlblSexo] = useState('');
	const [lblIdNacionalidade, setlblIdNacionalidade] = useState('1');
	const [lblCodPaisOrigem, setlblCodPaisOrigem] = useState(76);
	const [lblDataEntradaPais, setlblDataEntradaPais] = useState('');
	const [lblIdCidadeNascido, setlblIdCidadeNascido] = useState('');
	const [lblIdUFMunNascto, setlblIdUFMunNascto] = useState('');
	const [lblCpfResponsavel, setlblCpfResponsavel] = useState('');
	const [lblNumDocumentoCivil, setlblNumDocumentoCivil] = useState('');
	const [lblDigitoDocumento, setlblDigitoDocumento] = useState('');
	const [lblDataEmissao, setlblDataEmissao] = useState('');
	const [lblNumVistoConfere, setlblNumVistoConfere] = useState('');
	const [lblIdUfDocumento, setlblIdUfDocumento] = useState('');
	const [lblCep, setlblCep] = useState('');
	const [lblNumCasa, setlblNumCasa] = useState('');
	const [lblComplementoEndereco, setlblComplementoEndereco] = useState('');
	const [lblLogradouro, setlblLogradouro] = useState('');
	const [lblBairro, setlblBairro] = useState('');
	const [lblNomeCidade, setlblNomeCidade] = useState('');
	const [lblSiglaUfCidade, setlblSiglaUfCidade] = useState('');
	const [lblIdAreaLogradouro, setlblIdAreaLogradouro] = useState(1);
	const [lblIdLocalizacao, setlblIdLocalizacao] = useState(4);
	const [lblIdTipoTelefone, setlblIdTipoTelefone] = useState('');
	const [lblDDDTel, setlblDDDTel] = useState('');
	const [lblNumTelefone, setlblNumTelefone] = useState('');
	const [lblComplementoTel, setlblComplementoTel] = useState('');
	const [lblSms, setlblSms] = useState('');
	const [lblNomeMae, setlblNomeMae] = useState('');
	const [lblNomeAluno, setlblNomeAluno] = useState('');
	const [lblDataNascimentoAluno, setlblDataNascimentoAluno] = useState('');
	const [lblDisable, setlblDisable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setisVisible] = useState(false);
	const [lblRaAluno, setlblRaAluno] = useState('');
	const [lblDisableButton, setlblDisableButton] = useState(true);
	const [errorVerify, setErrorVerify] = useState('');
	const [changeCPF, setChangeCPF] = useState(false);
	const [editableCpf, setEditableCpf] = useState(false);
	const [lblContato, setlblContato] = useState([]);
	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};
	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			setlblDisableButton(true);
		})();
	}, [lblNomeMae, lblNomeAluno, lblDataNascimentoAluno]);
	async function handleChangeCPF(event) {
		event.preventDefault();
		setIsLoading(false);
		setisVisible(false);
		setChangeCPF(false);
		setEditableCpf(false);
		setlblCpfResponsavel('');
	}
	async function handleExistCPF(event) {
		event.preventDefault();
		const lengthCpf = lblCpfResponsavel.replace(/[-_.+()\s]/g, '');
		if (lblCpfResponsavel && lengthCpf.length >= 11) {
			setIsLoading(true);
			setisVisible(true);
			setEditableCpf(true);
			setChangeCPF(true);
			const { data } = await api.get('/usuario/verifica-cpf-usuario', {
				params: {
					lblCpf: lblCpfResponsavel,
				},
			});
			if (data.error === false) {
				swal('Informações do Usuário encontrado no Sistema');
				setlblDisable(true);
				setlblNomeResponsavel(data.data.usuario.responsavel);
				setlblDataNascimentoResponsavel(data.data.usuario.nascimento);
				setlblEmail(data.data.usuario.email);
				setlblIdCorRaca(data.data.usuario.id_cor_raca);
				setlblSexo(data.data.usuario.id_sexo);
				setlblIdNacionalidade(data.data.usuario.id_nacionalidade);
				setlblCodPaisOrigem(data.data.usuario.cod_pais);
				setlblDataEntradaPais(data.data.usuario.data_entrada_pais);
				setlblIdCidadeNascido(data.data.usuario.cidade_natal);
				setlblIdUFMunNascto(data.data.usuario.estado_natal);
				setlblNumDocumentoCivil(data.data.usuario.rg);
				setlblDigitoDocumento(data.data.usuario.digito);
				setlblDataEmissao(
					data.data.usuario.data_emissao_documento_civil
				);
				setlblNumVistoConfere(data.data.usuario.num_visto_confere);
				setlblIdUfDocumento(data.data.usuario.documento_uf);
				setlblCep(data.data.usuario.cep);
				setlblNumCasa(data.data.usuario.numero);
				setlblComplementoEndereco(
					data.data.usuario.complemento_endereco
				);
				setlblLogradouro(data.data.usuario.logradouro);
				setlblBairro(data.data.usuario.bairro);
				setlblNomeCidade(data.data.usuario.cidade);
				setlblSiglaUfCidade(data.data.usuario.estado);
				setlblIdAreaLogradouro(
					data.data.usuario.area_logradouro_id_area_logradouro
				);
				setlblIdLocalizacao(
					data.data.usuario.localizacao_id_localizacao
				);
				setlblContato(data.data.contato);
			}
			setIsLoading(false);
		} else swal('ATENÇÃO', 'CPF inválido.', 'warning');
	}
	async function verifyStudent(event) {
		event.preventDefault();
		const { data } = await api.get('/aluno/verifica-aluno', {
			params: {
				lblNomeAluno,
				lblDataNascimentoAluno,
				lblNomeMae,
			},
		});
		if (data.error === false) {
			setlblRaAluno(data.data.aluno.num_ra);
			setlblDisableButton(false);
			setEditableCpf(true);
			setErrorVerify('');
		} else setErrorVerify(data.message);
	}
	const [cepNotFound, setCepNotFound] = useState(true);

	function handleCEP(event) {
		event.preventDefault();
		cep(lblCep)
			.then(dataPostCode => {
				setlblBairro(dataPostCode.neighborhood);
				setlblNomeCidade(dataPostCode.city);
				setlblLogradouro(dataPostCode.street);
				setlblSiglaUfCidade(dataPostCode.state);
				setCepNotFound(true);
			})
			.catch(() => {
				setCepNotFound(false);
				swal('ATENÇÃO', 'Cep não encontrado', 'warning');
				setlblBairro('');
				setlblNomeCidade('');
				setlblLogradouro('');
				setlblSiglaUfCidade('');
			});
	}
	async function handleSubmit(event) {
		try {
			event.preventDefault();
			const data = new FormData();
			data.append('lblNomeResponsavel', lblNomeResponsavel);
			data.append(
				'lblDataNascimentoResponsavel',
				lblDataNascimentoResponsavel
			);
			data.append('lblIdCorRaca', lblIdCorRaca);
			data.append('lblIdSexo', lblIdSexo);
			data.append('lblEmail', lblEmail);
			data.append('lblParentesco', lblParentesco);
			data.append('lblNumVistoConfere', lblNumVistoConfere);
			data.append('lblIdNacionalidade', lblIdNacionalidade);
			data.append('lblDataEntradaPais', lblDataEntradaPais);
			data.append('lblCodPaisOrigem', lblCodPaisOrigem);
			data.append('lblIdCidadeNascido', lblIdCidadeNascido);
			data.append('lblIdUFMunNascto', lblIdUFMunNascto);
			data.append('lblCpfResponsavel', lblCpfResponsavel);
			data.append('lblNumDocumentoCivil', lblNumDocumentoCivil);
			data.append('lblDigitoDocumento', lblDigitoDocumento);
			data.append('lblIdUfDocumento', lblIdUfDocumento);
			data.append('lblDataEmissao', lblDataEmissao);
			data.append('lblLogradouro', lblLogradouro);
			data.append('lblNumCasa', lblNumCasa);
			data.append('lblBairro', lblBairro);
			data.append('lblNomeCidade', lblNomeCidade);
			data.append('lblSiglaUfCidade', lblSiglaUfCidade);
			data.append('lblComplementoEndereco', lblComplementoEndereco);
			data.append('lblCep', lblCep);
			data.append('lblIdAreaLogradouro', lblIdAreaLogradouro);
			data.append('lblIdLocalizacao', lblIdLocalizacao);
			data.append('lblIdTipoTelefone', lblIdTipoTelefone);
			data.append('lblDDDTel', lblDDDTel);
			data.append('lblNumTelefone', lblNumTelefone);
			data.append('lblComplementoTel', lblComplementoTel);
			data.append('lblSms', lblSms);
			data.append('lblNomeMae', lblNomeMae);
			data.append('lblNomeAluno', lblNomeAluno);
			data.append('lblDataNascimentoAluno', lblDataNascimentoAluno);
			data.append('lblRaAluno', lblRaAluno);
			await api
				.post('/responsavel/cadastro/responsavel', data)
				.then(response => {
					if (!response.data.error) {
						swal('SUCESSO!', `${response.data.message}`, 'success');
						history.push(
							Routes.LOGGED_ROUTES(
								RouteByPermission[userActualType]
							).LIST_PARENTS
						);
					} else swal('ERRO!', `${response.data.message}`, 'error');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			<div className="card mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Consulte o CPF</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-3 col-lg-3 mt-2 form-group">
							<span>CPF do Responsável : </span>
						</div>
						<div className="col-12 col-md-8 col-lg-8 form-group">
							<div className="input-group mb-4">
								<InputMask
									mask="999.999.999-99"
									required
									disabled={changeCPF}
									size={16}
									type="text"
									name="lblCpfResponsavel"
									id="lblCpfResponsavel"
									className="form-control"
									placeholder="CPF do Responsável"
									value={lblCpfResponsavel}
									onChange={event =>
										setlblCpfResponsavel(event.target.value)
									}
								/>

								<div className="input-group-append">
									{editableCpf === false ? (
										<button
											onClick={handleExistCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Consultar
										</button>
									) : (
										<button
											onClick={handleChangeCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Mudar
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				{isLoading && isVisible && <div className="lds-dual-ring" />}
				{isLoading === false && isVisible && (
					<>
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Dados Aluno</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Nome Aluno</p>
										<input
											onInput={toInputUppercase}
											required
											type="text"
											name="lblNomeAluno"
											id="lblNomeAluno"
											className="form-control"
											placeholder="Digite o nome do Aluno"
											value={lblNomeAluno}
											onChange={event =>
												setlblNomeAluno(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Data Nascimento Aluno</p>
										<InputMask
											mask="99/99/9999"
											required
											type="text"
											name="lblDataNascimentoAluno"
											id="lblDataNascimentoAluno"
											className="form-control"
											placeholder="Data Nascimento do Aluno"
											value={lblDataNascimentoAluno}
											onChange={event =>
												setlblDataNascimentoAluno(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Nome Mãe do Aluno</p>
										<input
											onInput={toInputUppercase}
											type="text"
											name="lblNomeMae"
											id="lblNomeMae"
											className="form-control"
											placeholder="Nome da Mãe do Aluno"
											value={lblNomeMae}
											onChange={event =>
												setlblNomeMae(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Registro do Aluno</p>
										<input
											disabled
											type="text"
											name="lblRaAluno"
											id="lblRaAluno"
											className="form-control"
											placeholder="Registro Aluno"
											value={lblRaAluno}
											onChange={event =>
												setlblRaAluno(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-12">
										<span className="errorLogin">
											<small>{errorVerify}</small>
										</span>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="row justify-content-start">
									<div className="col-lg-4 col-md-5 col-7 form-group">
										<button
											onClick={verifyStudent}
											className="btn btn-primary btn-block"
											type="button"
										>
											Verificar
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Dados Básicos</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Nome Responsável</p>
										<input
											disabled={lblDisable}
											onInput={toInputUppercase}
											required
											type="text"
											name="lblNomeResponsavel"
											id="lblNomeResponsavel"
											className="form-control"
											placeholder="Digite o nome do Responsável"
											value={lblNomeResponsavel}
											onChange={event =>
												setlblNomeResponsavel(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Data Nascimento</p>
										<InputMask
											disabled={lblDisable}
											mask="99/99/9999"
											required
											type="text"
											name="lblDataNascimentoResponsavel"
											id="lblDataNascimentoResponsavel"
											className="form-control"
											placeholder="Data Nascimento do Responsável"
											value={lblDataNascimentoResponsavel}
											onChange={event =>
												setlblDataNascimentoResponsavel(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Grau de Parentesco</p>
										<SelectParentesco
											setParentesco={setlblParentesco}
											valueSelect={lblParentesco}
											required
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>E-mail</p>
										<input
											disabled={lblDisable}
											type="text"
											name="lblEmail"
											id="lblEmail"
											className="form-control"
											placeholder="Digite o E-mail"
											value={lblEmail}
											onChange={event =>
												setlblEmail(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>Cor/Raça:</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<SelectCorRaca
											disabled={lblDisable}
											valueSelect={lblIdCorRaca}
											setCorRaca={setlblIdCorRaca}
										/>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>Gênero:</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<SelectSexo
											valueSelect={lblIdSexo}
											disabled={lblDisable}
											setSexo={setlblSexo}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>Nacionalidade:</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<select
											required
											disabled={lblDisable}
											className="form-control"
											name="lblIdNacionalidade"
											id="lblIdNacionalidade"
											onChange={event =>
												setlblIdNacionalidade(
													event.target.value
												)
											}
										>
											<option value="1">
												Brasileiro
											</option>
											<option value="2">
												Estrangeiro
											</option>
											<option value="3">
												Brasileiro Nascido no Exterior
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						{lblIdNacionalidade !== '1' ? (
							<div>
								<div className="card mt-3">
									<div className="card-header form-group">
										<h4>Nacionalidade</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>País</p>
												<SelectPais
													disabled={lblDisable}
													valueSelect={
														lblCodPaisOrigem
													}
													setCodPais={
														setlblCodPaisOrigem
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>Data de Entrada no País</p>
												<InputMask
													disabled={lblDisable}
													mask="99/99/9999"
													required
													type="text"
													name="lblDataEntradaPais"
													id="lblDataEntradaPais"
													className="form-control"
													placeholder="Data Nascimento do Responsável"
													value={lblDataEntradaPais}
													onChange={event =>
														setlblDataEntradaPais(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>Número do Visto</p>
												<input
													disabled={lblDisable}
													maxLength="11"
													minLength="11"
													type="text"
													name="lblNumVistoConfere"
													id="lblNumVistoConfere"
													className="form-control"
													placeholder="Visto do Responsável"
													value={lblNumVistoConfere}
													onChange={event =>
														setlblNumVistoConfere(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : null}

						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Município de Nascimento</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Estado</p>
										<SelectUf
											required
											disabled={lblDisable}
											valueSelect={lblIdUFMunNascto}
											setUf={setlblIdUFMunNascto}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Cidade</p>
										<SelectCidade
											required
											disabled={lblDisable}
											ufCidade={lblIdUFMunNascto}
											valueSelect={lblIdCidadeNascido}
											setCidade={setlblIdCidadeNascido}
										/>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Documentos</h4>
								</div>
								<div className="card-body">
									<p>Número de Documento Civil</p>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<InputMask
												disabled={lblDisable}
												mask="99.999.999"
												required
												type="text"
												name="lblNumDocumentoCivil"
												id="lblNumDocumentoCivil"
												className="form-control"
												placeholder="RG ou RN"
												value={lblNumDocumentoCivil}
												onChange={event =>
													setlblNumDocumentoCivil(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<input
												disabled={lblDisable}
												maxLength="2"
												minLength="1"
												type="text"
												name="lblDigitoDocumento"
												id="lblDigitoDocumento"
												className="form-control"
												placeholder="XX"
												value={lblDigitoDocumento}
												onChange={event =>
													setlblDigitoDocumento(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>Estado do Documento</p>
											<SelectUf
												disabled={lblDisable}
												valueSelect={lblIdUfDocumento}
												setUf={setlblIdUfDocumento}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>Data da Emissão do Documento</p>
											<InputMask
												disabled={lblDisable}
												mask="99/99/9999"
												name="lblDataEmissao"
												id="lblDataEmissao"
												className="form-control"
												placeholder="Data da Emissão"
												value={lblDataEmissao}
												onChange={event =>
													setlblDataEmissao(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{lblContato.length ? (
							<CardUserContacts
								userContacts={lblContato}
								lblDisable
							/>
						) : (
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Contato</h4>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>Tipos de Telefone</p>
											<SelectTipoTelefone
												valueSelect={lblIdTipoTelefone}
												setTipoTelefone={
													setlblIdTipoTelefone
												}
											/>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<p>DDD</p>
											<input
												required
												type="text"
												minLength="2"
												maxLength="2"
												name="lblDDDTel"
												id="lblDDDTel"
												className="form-control"
												placeholder="00"
												value={lblDDDTel}
												onChange={event =>
													setlblDDDTel(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<p>Telefone</p>
											<input
												required
												minLength="8"
												maxLength="9"
												type="text"
												name="lblNumTelefone"
												id="lblNumTelefone"
												className="form-control"
												placeholder="11111111"
												value={lblNumTelefone}
												onChange={event =>
													setlblNumTelefone(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>Deixar Recados?</p>
											<select
												className="form-control"
												name="lblSms"
												id="lblSms"
												value={lblSms}
												onChange={event =>
													setlblSms(
														event.target.value
													)
												}
											>
												<option>Recados</option>
												<option value="NÃO">Não</option>
												<option value="SIM">Sim</option>
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6 form-group">
											<p>Complemento</p>
											<input
												type="text"
												name="lblComplementoTel"
												id="lblComplementoTel"
												className="form-control"
												placeholder="Digite o outro número"
												value={lblComplementoTel}
												onChange={event =>
													setlblComplementoTel(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Endereço</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-7 col-lg-5 form-group">
										<p>CEP</p>
										<div className="input-group mb-3">
											<InputMask
												disabled={lblDisable}
												mask="99999-999"
												required
												type="text"
												name="lblCep"
												id="lblCep"
												className="form-control"
												placeholder="XXXXX-XXX"
												value={lblCep}
												onChange={event =>
													setlblCep(
														event.target.value
													)
												}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-block btn-success"
													type="button"
													onClick={handleCEP}
												>
													<FaSearch />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 form-group">
										<p>Logradouro</p>
										<input
											onInput={toInputUppercase}
											required
											disabled={cepNotFound}
											type="text"
											name="lblLogradouro"
											id="lblLogradouro"
											className="form-control"
											placeholder="Digite o Logradouro"
											value={lblLogradouro}
											onChange={event =>
												setlblLogradouro(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Número</p>
										<input
											disabled={lblDisable}
											required
											type="text"
											name="lblNumCasa"
											id="lblNumCasa"
											className="form-control"
											placeholder="Ex: SN, 123"
											value={lblNumCasa}
											onChange={event =>
												setlblNumCasa(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Complemento</p>
										<input
											disabled={lblDisable}
											required
											onInput={toInputUppercase}
											type="text"
											name="lblComplementoEndereco"
											id="lblComplementoEndereco"
											className="form-control"
											placeholder="Ex: Casa, Apto..."
											value={lblComplementoEndereco}
											onChange={event =>
												setlblComplementoEndereco(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Bairro</p>
										<input
											required
											disabled={cepNotFound}
											onInput={toInputUppercase}
											type="text"
											name="lblBairro"
											id="lblBairro"
											className="form-control"
											placeholder="Digite o Bairro"
											value={lblBairro}
											onChange={event =>
												setlblBairro(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-8 col-lg-8 form-group">
										<p>Cidade</p>
										<input
											required
											disabled={cepNotFound}
											onInput={toInputUppercase}
											type="text"
											name="lblNomeCidade"
											id="lblNomeCidade"
											className="form-control"
											placeholder="Digite a Cidade"
											value={lblNomeCidade}
											onChange={event =>
												setlblNomeCidade(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>UF</p>
										<input
											required
											disabled={cepNotFound}
											maxLength="2"
											type="text"
											name="lblSiglaUfCidade"
											id="lblSiglaUfCidade"
											className="form-control"
											placeholder="Digite o UF"
											value={lblSiglaUfCidade}
											onChange={event =>
												setlblSiglaUfCidade(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Área do logradouro</p>
										<select
											disabled={lblDisable}
											className="form-control"
											name="lblIdAreaLogradouro"
											id="lblIdAreaLogradouro"
											value={lblIdAreaLogradouro}
											onChange={event =>
												setlblIdAreaLogradouro(
													event.target.value
												)
											}
										>
											<option value="1">Urbana</option>
											<option value="2">Rural</option>
										</select>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Localização Diferenciada</p>
										<select
											disabled={lblDisable}
											required
											value={lblIdLocalizacao}
											className="form-control"
											name="lblIdLocalizacao"
											id="lblIdLocalizacao"
											onChange={event =>
												setlblIdLocalizacao(
													event.target.value
												)
											}
										>
											<option value="1">
												Área de assentamento
											</option>
											<option value="2">
												Terra indígena
											</option>
											<option value="3">
												Área onde se localizada em
												Comunidade remanescente de
												Quilombos
											</option>
											<option value="4">
												Não está localizado em área de
												localização diferenciada
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="row justify-content-start">
									<div className="col-lg-4 col-md-5 col-7 form-group">
										<button
											disabled={lblDisableButton}
											className="btn btn-primary btn-block"
											type="submit"
										>
											Cadastrar
										</button>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</form>
		</>
	);
}
