import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import Table from '../../../components/Table';
import RouteParams from '../../../routes/data/RouteParams';

export default function ListSerires() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Lista Séries',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [series, setSeries] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			const message = await api.get('/series/lista/escola');
			if (message.data.error === false) {
				setIsLoading(false);
				setSeries(message.data.data);
			} else {
				swal('ERRO!', `${message.data.message}`, 'error');
				history.goBack();
			}
		})();
	}, []);

	function OptionsButton({ serie }) {
		function viewNavigation() {
			const idSerie = serie.id_serie_ano;
			const state = {};
			state[RouteParams.LEVEL_05_ROUTES.INFO_SERIES.SERIES_ID] = idSerie;
			history.push({
				pathname: Routes.LOGGED_ROUTES(
					RouteByPermission[userActualType]
				).INFO_SERIES,
				state,
			});
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(serie)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<Table
							header={['Série', 'Ensino', '']}
							body={series.map(serie => {
								return [
									serie.desc_serie_ano,
									serie.desc_tipo_ensino,
									<OptionsButton serie={serie} />,
								];
							})}
						/>
					</div>
				</div>
			)}
		</>
	);
}
