import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaSearch, FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Breadcrumb from '../Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteParams from '../../routes/data/RouteParams';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../Table';

export default function ListStudents() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumb = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.STUDENTS,
			namePage: 'Lista de Alunos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [students, setStudents] = useState([]);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [subjectsTeacher, setSubjects] = useState([]);
	const [subjectId, setSubjectId] = useState(null);
	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledClass, setDisabledClass] = useState(true);
	const [schools, setSchools] = useState([]);
	const [schoolId, setSchoolId] = useState(null);
	const [classes, setClasses] = useState([]);
	const [classId, setClassId] = useState(null);

	useEffect(() => {
		(async function() {
			const dataPost = {
				typeUser: userActualType,
			};
			const response = await api.post('/usuario/escola', dataPost);
			if (!response.data.error) setSchools(response.data.data);
			else setSchools([]);
		})();
	}, []);

	useEffect(() => {
		if (schoolId)
			(async function() {
				const dataPost = {
					schoolId,
					typeUser: localStorage.getItem('@actualTypeId'),
				};
				const response = await api.post(
					'/usuario/escola/classe',
					dataPost
				);
				if (response.data.error) {
					setClasses([]);
					setDisabledClass(true);
				} else {
					setDisabledClass(false);
					setClasses(response.data.data);
				}
			})();
	}, [schoolId]);

	useEffect(() => {
		(async function() {
			if (classId) {
				const dataPost = {
					classId,
					schoolId,
					typeUser: localStorage.getItem('@actualTypeId'),
				};
				const response = await api.post(
					'/usuario/escola/classe/disciplinas',
					dataPost
				);
				if (response.data.error) {
					setSubjects([]);
					setDisabledSubjects(true);
				} else {
					setDisabledSubjects(false);
					setSubjects(response.data.data);
				}
			}
		})();
	}, [classId]);

	useEffect(() => {
		if (subjectId) setDisabledBtn(false);
		else setDisabledBtn(true);
	}, [subjectId]);

	async function searchStudents(event) {
		event.preventDefault();
		(async function() {
			const dataPost = {
				classId,
				schoolId,
				subjectId,
				typeUser: localStorage.getItem('@actualTypeId'),
			};
			const response = await api.post(
				'/usuario/escola/classe/disciplinas/alunos',
				dataPost
			);
			if (!response.data.error) setStudents(response.data.data);
			else setStudents([]);
		})();
	}

	function OptionsButton({ student }) {
		function viewNavigation() {
			const params = {};
			params[RouteParams.LEVEL_03_ROUTES.REPORT_CARD.STUDENT_ID] =
				student.id_usuario;
			params[
				RouteParams.LEVEL_03_ROUTES.REPORT_CARD.SUBJECT_ID_TEACHER
			] = subjectId;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.REPORT_CARD,
				params
			);
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Motorista"
					className="buttonViewColor"
					onClick={() => viewNavigation(student)}
				>
					<FaEye fill="#ffffff" />
				</button>
			</>
		);
	}
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumb} />
			<div className="card mb-4">
				<div className="card-body">
					<div className="row mb-3">
						<div className="col-12 col-lg-4">
							<div className="form-group">
								<label htmlFor="schools">
									<b>Escola</b>
								</label>
								<select
									className="form-control"
									name="schools"
									id="schools"
									onChange={event =>
										setSchoolId(event.target.value)
									}
								>
									<option value="0">Escolha a Escola</option>
									{schools.map(schools => (
										<option
											key={schools.id_escola}
											value={schools.id_escola}
										>
											{schools.desc_nome_escola}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-lg-4">
							<div className="form-group">
								<label htmlFor="classes">
									<b>Série/Turma</b>
								</label>
								<select
									id="classes"
									className="form-control"
									onChange={event =>
										setClassId(event.target.value)
									}
									disabled={disabledClass}
								>
									<option value="0">Escolha a série</option>
									{classes.map(classe => (
										<option
											key={classe.id_classe}
											value={classe.id_classe}
										>
											{classe.desc_serie_ano} -{' '}
											{classe.turma}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-lg-4">
							<div className="form-group">
								<label htmlFor="subjectTeacher">
									<b>Disciplina</b>
								</label>
								<select
									className="form-control"
									name="subjectTeacher"
									id="subjectTeacher"
									onChange={event =>
										setSubjectId(event.target.value)
									}
									disabled={disabledSubjects}
								>
									<option value="">
										Escolha a disciplina
									</option>
									{subjectsTeacher.map(subjects => (
										<option
											key={subjects.id_disciplina}
											value={subjects.id_disciplina}
										>
											{subjects.nome_disciplina}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className="row justify-content-center text-center">
						<div className="col-lg-6 col-md-10 col-12 form-group">
							<button
								type="button"
								className="btn btn-block btn-success"
								onClick={searchStudents}
								disabled={disabledBtn}
							>
								<FaSearch className="searchIcon" />
								Buscar Alunos
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<Table
						header={['RA', 'Aluno', 'Escola', 'Série/Ano', '']}
						body={students.map(student => {
							return [
								student.num_ra,
								student.nome_usuario,
								student.desc_nome_escola,
								`${student.desc_nome_escola} ${student.desc_tipo_ensino} - ${student.turma}`,
								<OptionsButton student={student} />,
							];
						})}
					/>
				</div>
			</div>
		</>
	);
}
