import React, { useEffect, useState } from 'react';
import { FaCheck, FaEdit, FaTrash } from 'react-icons/fa';

import './styles.css';

function CardQuestions({ questions, id, peso, editQuestion, deleteQuestion }) {
	const [edit, setEdit] = useState(false);
	const [titulo, setTitulo] = useState('');
	const [descricao, setDescricao] = useState('');
	const [pesoQuestao, setPeso] = useState('');
	const [certaIndex, setCertaIndex] = useState('');
	const [alternativas, setAlternativas] = useState(
		questions.alternativa
			? questions.alternativa.map(item => ({
				alternativas: item.alternativas,
				titulo: item.titulo,
				certa: item.certa,
			}))
			: null
	);

	useEffect(() => {
		peso(parseInt(questions.peso));
		setTitulo(questions.title);
		setDescricao(questions.descricao);
		setPeso(questions.peso);
	}, []);

	const handleChangeInput = (event, index) => {
		const values = [...alternativas];
		values[index][event.target.name] = event.target.value;
		setAlternativas(values);
	};

	const handleSubitmit = () => {
		const alternativaCerta = alternativas;

		alternativaCerta.filter((item) => delete item.certa)
		alternativaCerta[certaIndex]['certa'] = 1;

		setEdit(false);
		let data = {};
		questions.alternativa
			? (data = {
				title: titulo,
				descricao: descricao,
				peso: pesoQuestao,
				alternativa: alternativaCerta,
			})
			: (data = {
				title: titulo,
				descricao: descricao,
				peso: pesoQuestao,
			});

		editQuestion(data, id);
	};

	return (
		<>
			<div className="questionCard">
				{edit ? (
					<div className="questionEdit">
						<div className="inputGroupp">
							<label htmlFor="avaliacao">
								<h5>Título</h5>
								<input
									className="form-control"
									type="text"
									value={titulo}
									onChange={event =>
										setTitulo(event.target.value)
									}
									required
								/>
							</label>

							<label htmlFor="avaliacao">
								<h5>Descrição</h5>
								<input
									className="form-control"
									type="text"
									name="Descrição"
									value={descricao}
									onChange={event =>
										setDescricao(event.target.value)
									}
									required
								/>
							</label>

							<label htmlFor="avaliacao">
								<h5>Peso</h5>
								<input
									className="form-control"
									type="text"
									name="peso"
									value={pesoQuestao}
									onChange={event =>
										setPeso(event.target.value)
									}
									required
								/>
							</label>
						</div>
						<ul>
							{alternativas
								? alternativas.map((item, index) => (
									// {item.titulo}
									<div className="alternativasGroup">
										<li key={index}>
											{item.alternativas})
											</li>
										<div className="alternativasInput">
											<input
												className="form-control"
												type="text"
												name="titulo"
												value={item.titulo}
												onChange={event =>
													handleChangeInput(
														event,
														index
													)
												}
												required
											/>
											<div
												className="form-check"
												id="checkAlternativeCorret"
											>
												<input
													type="radio"
													name="certa"
													value={1}
													checked={item.certa ? true : null}
													onChange={event =>
														setCertaIndex(index)
													}
													id={`customRadioInline${index + 4}`}
												/>
												<label
													className="form-check-label"
													for={`customRadioInline${index +
														3}`}
												>
													Resposta correta
										</label>
											</div>
										</div>

										{/* {item.certa == '1' ? <FaCheck className="icon" color="green" /> : null} */}
									</div>
								))
								: null}
						</ul>

						<button
							type="button"
							id="salvarButton"
							onClick={handleSubitmit}
							className="btn btn-primary"
						>
							Salvar
						</button>
					</div>
				) : (
						<div className="questionBody">
							<h5>
								{' '}
								{id + 1}) {questions.title}
							</h5>
							<h6>{questions.descricao}</h6>
							<ul>
								{questions.alternativa
									? questions.alternativa.map((item, index) => (
										<li key={index}>
											{item.alternativas}) {item.titulo}{' '}
											{item.certa ? (
												<FaCheck
													className="icon"
													color="green"
												/>
											) : null}
										</li>
									))
									: null}
							</ul>
						</div>
					)}
				<div className="bottonItens">
					{edit ? null : <strong> Peso: {questions.peso}</strong>}
					<div className="icons">
						<FaEdit onClick={() => setEdit(edit ? false : true)} />
						<FaTrash onClick={() => deleteQuestion(id)} />
					</div>
				</div>
			</div>
			<hr />
		</>
	);
}

export default CardQuestions;
