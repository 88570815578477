import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';
import parseJwt from '../../../helpers/parseJwt';

export default function Profile() {
	const [student, setStudent] = useState({});
	const tokenUsuario = localStorage.getItem('@token');
	const [contactStudent, setContactStudent] = useState([]);
	const idUsuario = parseJwt(tokenUsuario);

	useEffect(() => {
		(async function() {
			const response = await api.get(`/aluno/perfil/${idUsuario.sub}`);
			if (!response.data.error) {
				setStudent(response.data.data.aluno);
				setContactStudent(response.data.data.contatos[0]);
				if (response.data.data.contatos[0])
					setContactStudent(response.data.data.contatos[0]);
				else setContactStudent(null);
			} else swal('ERRO!', `${response.data.message}`, 'error');
		})();
	}, []);
	return (
		<>
			<div className="card">
				<div className="card-body">
					<p className="detailsStudentCard">
						Nome: <span>{student.nome_usuario}</span>
					</p>
					<p className="detailsStudentCard">
						Série: <span>{student.desc_serie_ano}</span>
					</p>
					<p className="detailsStudentCard">
						Endereço:{' '}
						<span>
							{student.logradouro}, {student.numero},
							{student.bairro}, {student.nome_cidade} -{' '}
							{student.sigla_uf}
						</span>
					</p>
					<p className="detailsStudentCard">
						Contato:{' '}
						{contactStudent ? (
							<span>
								({contactStudent.ddd_numero}){' '}
								{contactStudent.tel_numero}
							</span>
						) : (
							'Contato não cadastrado'
						)}
					</p>
					<p className="detailsStudentCard">
						Escola: <span>{student.desc_nome_escola}</span>
					</p>
				</div>
			</div>
		</>
	);
}
