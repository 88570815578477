export default [
	null,
	'Prefeito(A)',
	'Secretário(A) de Educação',
	'Diretor(a) de Ensino',
	'Diretor(a) da Unidade',
	'SECRETÁRIO(A)',
	'COORDENADOR(A)',
	'PROFESSOR(A)',
	'RESPONSÁVEL',
	'ALUNO(A)',
	'COZINHA PILOTO',
	'NUTRICIONISTA',
	'SUPERVISOR TRANSPORTE',
	'MOTORISTA',
	'MONITOR',
	'Diretoria Administrativa',
	'Divisão de Gestão Administrativa',
	'Divisão de Planejamento e Orçamento',
	'Divisão de TI',
	'Diretoria de Supervisão Pedagógica',
	'Divisão de Educação Infantil',
	'Divisão de Educação Fundamental',
	'Divisão de Educação Especial',
	'Diretoria de Suprimentos',
	'Divisão de Alimentação Escolar',
	'Divisão de Transporte Escolar',
	'Divisão de Patrimônio',
	'Divisão de Suprimentos',
	'Administrador(a) das Creches'
];
