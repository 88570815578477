import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import api from '../../services/api';
import './style.css';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Breadcrumb from '../Breadcrumb';
// import TextArea from '../TextArea/TextAreaQuil';

export default function FormParent() {
	const history = useHistory();
	const [lblTituloNoticia, setlblTituloNoticia] = useState('');
	const [lblDescricao, setlblDescricao] = useState(null);
	const [lblDisibled, setlblDisibled] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.NOTICES,
			namePage: 'Cadastro de Notícias',
			breadCrumbClass: 'breadcrumb-item',
		},
	];

	async function handleSubmit(event) {
		event.preventDefault();

		try {
			const response = await api.post('/noticias/cadastro', {
				lblTituloNoticia,
				lblDescricao,
			});

			if (!response.data.error) {
				swal('SUCESSO!', `${response.data.message}`, 'success');
				history.push(
					Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
						.LIST_NOTICES
				);
			} else swal('ERRO!', `${response.data.message}`, 'error');
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}
	useEffect(() => {
		if (lblDescricao === '<p><br></p>') setlblDisibled(true);
		else setlblDisibled(false);
	}, [lblDescricao]);
	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card bg-white rounded mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Criar Notícia</h4>
				</div>
				<div className="card-body">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12 form-group">
								<p>Titulo Notícia</p>
								<input
									required
									maxLength={100}
									type="text"
									name="lblTituloNoticia"
									id="lblTituloNoticia"
									className="form-control"
									placeholder="Titulo Notícia"
									value={lblTituloNoticia}
									onChange={event =>
										setlblTituloNoticia(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="form-group write-notices">
							<p>Descrição Notícia</p>
							<textarea
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="6"
								onChange={event =>
									setlblDescricao(event.target.value)
								}
							/>
							{/* <TextArea setlblText={setlblDescricao} /> */}
						</div>
						<div className="form-group">
							<button
								type="submit"
								className="btn btn-primary"
								disabled={lblDisibled}
							>
								Confirmar
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
