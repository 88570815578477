import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { UserTypeActions } from '../../../redux/ducks/userTypeReducer';
import UserTypeArray from '../../../data/UserTypeArray';
import Routes from '../../data/Routes';
import RouteByPermission from '../../data/RouteByPermission';

export default function SwitchUser({ userTypes }) {
	const dispatch = useDispatch();
	const history = useHistory();

	function chooseUserType(name) {
		const id = UserTypeArray.findIndex(item => item === name);
		localStorage.setItem('@actualTypeId', id);
		dispatch(UserTypeActions.updateActualTypeId(id));
		history.push(Routes.LOGGED_ROUTES(RouteByPermission[id]).HOME);
	}
	return (
		<>
			<div className="container-fluid">
				<div className="row d-flex align-items-center justify-content-center">
					<div className="col-lg-6 col-md-8 col-12 form-group">
						<div className="card shadow" id="cardSwitchUser">
							<div className="card-title pt-3">
								<h3 className="text-center">Eu sou...</h3>
							</div>
							<div className="card-body">
								<div className="row justify-content-center">
									<div className="col-8">
										{userTypes.map(userType => (
											<button
												type="button"
												key={UserTypeArray[userType]}
												className="btn btn-outline-primary btn-block"
												onClick={() =>
													chooseUserType(
														UserTypeArray[userType]
													)
												}
											>
												{UserTypeArray[userType]}
											</button>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

SwitchUser.propTypes = {
	userTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
};
