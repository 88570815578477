import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';
import swal from 'sweetalert';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import Table from '../../../components/Table';
import SelectEscolaRede from '../../../components/Selects/SelectEscolaRede';
import SelectDisciplinas from '../../../components/Selects/SelectDisciplinas';
import SelectSerieAno from '../../../components/Selects/SelectSerieAno';
import SelectClasses from '../../../components/Selects/SelectClasses';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function ReclassifyStudent() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [listStudents, setListStudents] = useState([]);
	const [disciplinaId, setDisciplinaId] = useState(null);
	const [schoolId, setSchoolId] = useState(null);
	const [classeId, setClasseId] = useState(null);
	const [serieId, setSerieId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const breadCrumbItems = [
		{
			linkPage: '/secretaria',
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Alunos com notas baixas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function handleSeachStudents() {
		setIsLoading(true);
		const dataPost = {
			disciplinaId,
			classeId,
			serieId,
		};
		const { data } = await api.post('/recuperacao/', dataPost);
		if (data.error === false) setListStudents(data.data);
		else {
			swal({ icon: 'warning', text: data.message });
			setListStudents([]);
		}
		setIsLoading(false);
	}

	function OptionsButton({ student }) {
		const [justification, setJustification] = useState();
		const [idUser] = useState(student.id_usuario);
		const [notes] = useState(student.notas);
		const [name] = useState(student.nome_usuario);
		const [idClass] = useState(student.id_classe);

		async function handleSubmitReclassify(event) {
			event.preventDefault();
			const dataPost = {
				idClass,
				justification,
				studentId: idUser,
				subjectsIds: notes.map(n => n.id_disciplina),
			};
			const { data } = await api.post(
				'/recuperacao/adicionar-lista-recuperacao',
				dataPost
			);
			if (data.error === false) {
				swal({ icon: 'success', text: data.message });
				document.querySelector('#closeModalReclassify').click();
				handleSeachStudents();
			} else swal({ icon: 'error', text: data.message });
		}
		return (
			<>
				<div classeName="row">
					<div className="col-12 col-md-6 col-lg-6">
						<button
							title="Ver notas"
							type="button"
							id="button"
							className="btn buttonViewColor"
							data-toggle="modal"
							data-target={`#notasModal${idUser}`}
						>
							<FaEye fill="#ffffff" />
						</button>
					</div>
					<div className="col-12 col-md-6 col-lg-6">
						<button
							title="Colocar em lista de Reprovados"
							type="button"
							id="button"
							className="btn buttonDelete"
							data-toggle="modal"
							data-target={`#reclassifyStudent${idUser}`}
						>
							<MdReportProblem fill="#ffffff" />
						</button>
					</div>
				</div>
				<div
					className="modal fade bd-example-modal-xl"
					id={`notasModal${idUser}`}
					tabIndex="-1"
					role="dialog"
					aria-labelledby="notasModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-xl" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="notasModalLabel"
								>
									Notas do Aluno(a): {name}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{notes.length ? (
									<Table
										header={[
											'Disciplina',
											'Nota',
											'Frequência (%)',
										]}
										body={notes.map(nota => {
											return [
												nota.nome_disciplina,
												nota.nota_media_final,
												`${nota.frequencia}%`,
											];
										})}
										fileName={name}
									/>
								) : (
									<p>Aluno não tem notas no sistema ainda.</p>
								)}
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade bd-example-modal-xl"
					id={`reclassifyStudent${idUser}`}
					tabIndex="-1"
					role="dialog"
					aria-labelledby="reclassifyStudentLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-xl" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="reclassifyStudentLabel"
								>
									Reprovar Aluno(a): {name}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form onSubmit={handleSubmitReclassify}>
								<div className="modal-body">
									<div className="form-group text-left">
										<label htmlFor="addVehicle_chassi">
											<b>Justificativa:</b>
										</label>
										<textarea
											rows="4"
											cols="50"
											type="text"
											className="form-control"
											value={justification}
											onChange={event =>
												setJustification(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="modal-footer">
									<div className="col-12 col-md-6 col-lg-6">
										<button
											id="closeModalReclassify"
											type="button"
											className="btn btn-secondary form-control"
											data-dismiss="modal"
										>
											Fechar
										</button>
									</div>
									<div className="col-12 col-md-6 col-lg-6">
										<button
											type="submit"
											className="btn btn-primary btn-block form-control"
										>
											confirmar
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}

	useEffect(() => {
		(async function() {
			const { data } = await api.post('/recuperacao/');
			if (data.error === false) setListStudents(data.data);
			else swal({ icon: 'warning', text: data.message });
		})();
	}, []);

	useEffect(() => {
		handleSeachStudents();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header">
					Lista de Alunos com notas e frequêcias baixas
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Escola</b>
							</label>
							<SelectEscolaRede
								setIdSchool={setSchoolId}
								valueSelect={schoolId}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<SelectSerieAno
								setIdSerieAno={setSerieId}
								valueSelect={serieId}
								idShcool={schoolId}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Classe</b>
							</label>
							<SelectClasses
								setClasse={setClasseId}
								valueSelect={classeId}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Disciplinas</b>
							</label>
							<SelectDisciplinas
								setDisciplina={setDisciplinaId}
								valueSelect={disciplinaId}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<button
								onClick={handleSeachStudents}
								className="btn btn-primary"
								type="button"
								id="button-addon2"
							>
								Filtrar
							</button>
						</div>
						<div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end">
							<Link
								to={
									Routes.LOGGED_ROUTES(
										RouteByPermission[userActualType]
									).LIST_RECLASSIFY_STUDENT
								}
							>
								<button
									onClick={handleSeachStudents}
									className="btn btn-primary"
									type="button"
									id="button-addon2"
								>
									Lista de alunos reprovados
								</button>
							</Link>
						</div>
					</div>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<div className="text-size-table">
							<Table
								header={[
									'RA',
									'Nome Aluno',
									'Notas Baixas',
									'Frequencias Baixa',
									'Série/Classe',
									'',
								]}
								body={listStudents.map(student => {
									return [
										student.num_ra,
										student.nome_usuario,
										student.notas_baixas,
										student.frequencias_baixas,
										`${student.desc_serie_ano} ${student.desc_tipo_ensino} - ${student.turma}`,
										<OptionsButton student={student} />,
									];
								})}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
