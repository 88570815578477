import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import swal from 'sweetalert';
import { FaTimesCircle } from 'react-icons/fa';
import Panel from '../../components/Panel';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import api from '../../services/api';
import './style.css';
import { useSelector } from 'react-redux';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import LabelByPermission from '../../routes/data/LabelByPermission';

$.DataTable = require('datatables.net-dt');

export default function Commitments() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [commitments, setCommitments] = useState([]);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Empenhos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getCommitments() {
		const response = await api.get('/cozinha-piloto/empenhos');

		!response.data.error
			? setCommitments(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	function buildCommitmentsTable(commitmentsData) {
		$('#commitmentsTable').DataTable({
			data: commitmentsData,
			destroy: true,
			responsive: true,
			columns: [
				{ data: 'id_empenho' },
				{ data: 'numero' },
				{ data: 'nome' },
				{
					data: data => {
						return `R$ ${data.valor}`;
					},
				},
			],
			language: {
				url:
					'//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json',
			},
		});
	}

	useEffect(() => {
		getCommitments();
	}, []);

	useEffect(() => {
		if (!commitments) return;
		if (commitments.length === 0) return;
		buildCommitmentsTable(commitments);
	}, [commitments]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{commitments ? (
				<div className="commitmentsCardTable card table-responsive">
					<table
						className="table commitmentsTable"
						id="commitmentsTable"
					>
						<thead>
							<tr>
								<th scope="col">Número do Empenho</th>
								<th scope="col">Ficha Utilizada</th>
								<th scope="col">Empresa</th>
								<th scope="col">Valor</th>
							</tr>
						</thead>
					</table>
				</div>
			) : (
				<div className="commitmentsNotFound">
					<FaTimesCircle size={30} />
					<p className="mt-2">Nenhum empenho encontrado</p>
				</div>
			)}
		</>
	);
}
