import React, { useState, useRef, Fragment } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { FcEmptyTrash } from 'react-icons/fc';
import Select from 'react-select';
import api from '../../../services/api';

import env from '../../../env';
import VideoCard from '../../VideoCard';
import ViewEvaluation from '../../CardEvaluation';
import { Tooltip } from '@material-ui/core';

function ModuleCard({
	modules = null,
	videos = null,
	avaliacoes = null,
	loadAttibution = null,
	student = null,
}) {


	const [libaryOptions, setLibraryOptions] = useState({});
	const [avaliacoesOptions, setAvaliacoesOptions] = useState({});

	const files = useRef([React.createRef(), React.createRef()]);

	const options = [
		videos
			? videos.map(video => {
				return {
					value: video.id_biblioteca_video,
					label: video.nome,
				};
			})
			: null,
	];


	async function insertFile(idModuleAttribution, index, avaliacoes) {

		const data = new FormData();

		data.append('idModuleAttribution', idModuleAttribution);

		if (files.length > 0) {

			const newFiles = files.current[index].current;
			Object.values(newFiles.files).map(file =>
				data.append(Math.random(), file));
		}


		if (Object.keys(libaryOptions).length) {
			const value = libaryOptions.map(item => item.value);
			data.append('videos', value);
		}
		if (avaliacoes.length) {

			data.append('avaliacoes', JSON.stringify(avaliacoes));
		}


		const response = await api.post('/atribuicao/insere-arquivo', data);

		if (response.data.error) {
			swal('SUCESSO!', response.data.message, 'warning');
		} {
			swal('SUCESSO!', 'Dados enviados com sucesso', 'success');
		}
		const dataTransfer = new DataTransfer();
		files.current[index].current.files = dataTransfer.files;
		setLibraryOptions({});
		loadAttibution();


	}

	return (
		<>
			<div className="card-body">
				{modules.map((module, index) => (
					<ModuleCollapse
						key={index}
						module={module}
						loadAttibution={() => loadAttibution()}
						student={student}
						files={files}
						index={index}
						avaliacoes={avaliacoes}
						setLibraryOptions={setLibraryOptions}
						options={options}
						insertFile={insertFile}
					/>
				))}
			</div>
		</>
	);
}
export default ModuleCard;


const ModuleCollapse = ({
	module,
	loadAttibution = null,
	student = null,
	files = null,
	index = null,
	setLibraryOptions = null,
	avaliacoes = null,
	options = null,
	insertFile = null,
}) => {

	const [inputFields, setInputFields] = useState([{ prova: '', inicio: '', fim: '' }]);


	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		values[index][event.target.name] = event.target.value;
		setInputFields(values);
	};

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ prova: '', inicio: '', fim: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
	};

	function handleDeleteModule(idModulesAttribution) {
		swal({
			title: 'Você tem certeza?',
			text: 'Você está para deletar um módulo, tem certeza dessa ação!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then(willDelete => {
			if (willDelete)
				(async function () {
					const { data } = await api.post(
						'/atribuicao/desativar-modulo',
						{
							idModulesAttribution,
						}
					);
					if (data.error)
						swal(data.message, {
							icon: 'warning',
							title: 'Ops!',
						});
					else
						swal(data.message, {
							icon: 'success',
							title: 'Sucesso!',
						});
					loadAttibution();
				})();
			else
				swal({
					title: 'Sucesso!',
					text: 'Ação cancelada',
					icon: 'success',
				});
		});
	}
	function handleDeleteFile(idFileHasModule) {
		swal({
			title: 'Você tem certeza?',
			text: 'Você está para deletar um arquivo, tem certeza dessa ação!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then(willDelete => {
			if (willDelete)
				(async function () {
					const { data } = await api.post(
						'/atribuicao/desativar-arquivo',
						{
							idFileHasModule,
						}
					);
					if (data.error)
						swal(data.message, {
							icon: 'warning',
							title: 'Ops!',
						});
					else
						swal(data.message, {
							icon: 'success',
							title: 'Sucesso!',
						});
					loadAttibution();
				})();
			else
				swal({
					title: 'Sucesso!',
					text: 'Ação cancelada',
					icon: 'success',
				});
		});
	}
	return (
		<div className="col-12 col-md-12 col-lg-12 form-group">
			<div id="accordion">
				<div className="card">
					<div className="card-header" id="headingOne">
						<div className="row">
							<div className="col-9 col-lg-11 col-md-11 text-attribution-tittle">
								<button
									className="btn btn-link"
									data-toggle="collapse"
									data-target={`#collapse${module.id_atribuicao_has_modulo}`}
									aria-expanded="true"
									aria-controls="collapseOne"
								>
									{module.titulo}
								</button>
							</div>

							{student ? null : (
								<div className="col-3 col-lg-1 col-md-1 icon-attribution-tittle">
									<button
										className="btn btn-link button-plus-attribution"
										onClick={() =>
											handleDeleteModule(
												module.id_atribuicao_has_modulo
											)
										}
									>
										<FcEmptyTrash />
									</button>
								</div>
							)}
						</div>
					</div>
					<div
						id={`collapse${module.id_atribuicao_has_modulo}`}
						className="collapse"
						aria-labelledby="headingOne"
						data-parent="#accordion"
					>
						<div className="card-body">
							{module.avaliacoes.length == 0 ? null : (
								<h5>Avaliacões:</h5>
							)}
							{module.avaliacoes ? (
								module.avaliacoes.map(avaliacao => {
									return (
										<>{
											new Date() >= new Date(avaliacao.inicio) ?
												<ViewEvaluation
													atualiza={() =>
														loadAttibution()
													}
													avaliacao={avaliacao}
													student={student}

												/>
												:
												null

										}

										</>
									);
								})
							) : (
								<h1>Nao tem avaliacao</h1>
							)}
						</div>
						<div className="card-body">
							{module.videos.length == 0 ? null : (
								<h5>Videos:</h5>
							)}
							{module.videos.length ? (
								<>
									{module.videos.map(value => {
										return (
											<VideoCard
												key={value.id}
												content={value}
												atualiza={() =>
													loadAttibution()
												}
												hasModule
											/>
										);
									})}
								</>
							) : (
								<p>Não tem videos.</p>
							)}
						</div>
						<hr />
						<div className="card-body">
							{module.files.length ? (
								<>
									{module.files.map(file => (
										<ul>
											<li className="arquivos">
												<a
													href={`${env.SITE_URL
														}/file/atribuicao-arquivo/download?file=${file.nome
														}_${file.codigo}.${file.extensao
														}&token=${localStorage.getItem(
															'@token'
														)}`}
													rel="noreferrer"
													target="_blank"
													className="arquivo-link"
												>
													Download
												</a>
												&nbsp;-&nbsp;
												<a
													href={`${env.SITE_URL
														}/file/atribuicao-arquivo?file=${file.nome
														}_${file.codigo}.${file.extensao
														}&token=${localStorage.getItem(
															'@token'
														)}`}
													rel="noreferrer"
													target="_blank"
													className="arquivo-link"
												>
													{file.nome}.{file.extensao}
												</a>
												&nbsp;-&nbsp;
												{student ? null : (
													<button
														className="btn btn-link"
														title="Apagar arquivo"
														onClick={() =>
															handleDeleteFile(
																file.id_arquivo_has_modulo
															)
														}
													>
														Apagar arquivo
													</button>
												)}
											</li>
										</ul>
									))}
								</>
							) : (
								<p>Não tem arquivos.</p>
							)}
						</div>
						{student ? null : (
							<div className="card-footer">
								<div className="row">
									<div className="col-12 col-lg-6 col-md-6">
										<div className="form-group text-left">
											<label htmlFor="files">
												<b>Novos Arquivos:</b>
											</label>
											<input
												type="file"
												className="form-control-file"
												ref={files.current[index]}
												multiple
											/>
										</div>

									</div>
									{/* <div className="col-12 col-lg-6 col-md-6">
										<div className="form-group text-left">
											<label htmlFor="add_description">
												<b>Biblioteca:</b>
											</label>
											<Select
												onChange={(event) => setLibraryOptions(event)}
												isMulti
												options={options[0]}
											/>
										</div>


									</div> */}


								</div>
								{inputFields.map(
									(inputField, index) => (
										<div className="row" key={`${inputField}~${index}`}>
											<div className="form-group col-12 col-lg-3 col-md-3">
												<label htmlFor="RA">
													<b>Prova</b>
												</label>
												<select
													name="prova"
													className="form-control"
													id="lblIdStudents"
													onChange={event =>
														handleInputChange(
															index,
															event
														)
													}
												>
													<option>
														Escolha uma prova
															</option>
													{avaliacoes.map(
														item => (
															<option
																value={
																	item.id_avaliacao
																}
																key={
																	item.id_avaliacao
																}
															>
																{
																	item.titulo
																}
															</option>
														)
													)}
												</select>
											</div>
											<div className="form-group col-12 col-lg-3 col-md-3">
												<label htmlFor="Addres">
													<b>Inicio</b>
												</label>
												<Tooltip
													title="ex: 22/10/2021 19:00."
													placement="top-start"
												>

													<InputMask
														mask="99/99/9999 99:99"
														required
														type="text"
														name="inicio"
														id="lblDataEntradaPais"
														className="form-control"
														placeholder="Data de inicio"
														value={inputField.inicio}
														onChange={event =>
															handleInputChange(
																index,
																event
															)
														}
													/>
												</Tooltip>


											</div>
											<div className="form-group col-12 col-lg-3 col-md-3">
												<label htmlFor="Addres">
													<b>Fim</b>
												</label>
												<Tooltip
													title="ex: 22/10/2021 19:00"
													placement="top-start"
												>
													<InputMask
														mask="99/99/9999 99:99"
														required
														type="text"
														name="fim"
														id="lblDataEntradaPais"
														className="form-control"
														placeholder="Data da finalização"
														value={inputField.fim}
														onChange={event =>
															handleInputChange(
																index,
																event
															)
														}
													/>
												</Tooltip>
											</div>
											<div className="form-group col-sm-2 d-flex justify-content-around align-items-end">
												{inputFields.length >
													1 && (
														<button
															className="btn btn-danger formRouteStudentsAddRemoveButtons"
															type="button"
															onClick={() =>
																handleRemoveFields(
																	index
																)
															}
														>
															<FaMinus fill="#ffffff" />
														</button>
													)}
												{inputFields.length <=
													30 && (
														<button
															className="btn btn-success formRouteStudentsAddRemoveButtons"
															type="button"
															onClick={() =>
																handleAddFields()
															}
														>
															<FaPlus fill="#ffffff" />
														</button>
													)}
											</div>
										</div>
									)
								)}

								<button
									type="submit"
									className="btn btn-primary"
									onClick={() =>
										insertFile(
											module.id_atribuicao_has_modulo,
											index,
											inputFields
										)
									}
								>
									Atualizar
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
