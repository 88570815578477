import React, { useState } from 'react';
import api from '../../../../services/api';

const ModalDoneQuestions = ({ student,index }) => {
	// console.log("STUDENTE",student);
	return (
		<>
			<div id="corrigirQuestaoProval">
				<span>{student.nome_usuario}</span>
				<button
					type="button"
					className="btn btn-success"
					data-toggle="modal"
					data-toggle="modal"
					data-target={`#ModalDoneQuestions${index}`}
				>
					Respostas
				</button>
			</div>

			<div
				className="modal fade"
				id={`ModalDoneQuestions${index}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLongTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5
								className="modal-title"
								id="exampleModalLongTitle"
							>
								Resposta do aluno
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{student.respostas.map((resposta, index) => {
								// console.log("RESPOSTAS",resposta);
								return (
									<div
										className="reviseQuestionContainer"
										key={index}
									>
										<div className="reviseQuestionHeader">
											<strong>{resposta.titulo}</strong>
											<span>{resposta.descricao}</span>
										</div>

										<div className="reviseQuestionBottom">
											<label>
												<strong>Resposta:</strong>
												<span>{resposta.resposta}</span>
											</label>
											<label>
												<strong>Pontuação:</strong>
												<strong
													style={{
														color:
															resposta.resultado >
															0
																? 'green'
																: 'red',
													}}
												>
													{resposta.resultado}
												</strong>
											</label>
										</div>
									</div>
								);
							})}
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalDoneQuestions;
