import React, { useEffect, useState, Fragment } from 'react';
import swal from 'sweetalert';
import mapboxgl from 'mapbox-gl';
import { FaMinus, FaPlus } from 'react-icons/fa';
import api from '../../../services/api';
import Breadcrumb from '../../Breadcrumb';
import MapStudents from './MapStudents';
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css';

export default function FormRouteStudents({ breadCrumbItems }) {
	const [startPoint, setStartPoint] = useState([]);
	const [coordStudents, setCoordStudents] = useState([]);
	const [endPoint, setEndPoint] = useState([]);
	const [school, setSchool] = useState([]);
	const [students, setStudents] = useState([]);
	const [inputFields, setInputFields] = useState([{ RA: '', Address: '' }]);
	const [startAddress, setStartAddress] = useState(null);
	const [schoolID, setSchoolID] = useState(null);
	const [visibleMap, setVisibleMap] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	mapboxgl.accessToken =
		'pk.eyJ1IjoibWJ4c29sdXRpb25zIiwiYSI6ImNqeWtiM250dzBjcGMzbnQ1NXpkZml6YjUifQ.EiJnPlDOMMlG5wr93SxVYQ';

	useEffect(() => {
		setSchoolID(null);
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	useEffect(() => {
		if (schoolID)
			(async function() {
				const response = await api.get('/aluno/escola/lista', {
					params: {
						schoolID,
					},
				});
				if (!response.data.error) setStudents(response.data.data);
				else {
					setStudents([]);
					swal('ERRO!', `${response.data.message}`, 'error');
				}
			})();
	}, [schoolID]);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ RA: '', Address: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'RA') values[index].RA = event.target.value;
		else values[index].Address = event.target.value;
		setInputFields(values);
	};

	async function handleSubmit(e) {
		e.preventDefault();
		setIsLoading(true);
		setVisibleMap(false);
		try {
			await api
				.get('/rotas/necessidade-transporte', {
					params: {
						startAddress,
						schoolID,
						inputFields,
					},
				})
				.then(response => {
					if (!response.data.error) {
						setEndPoint(response.data.data.endPoint);
						setCoordStudents(response.data.data.coordStudents);
						setStartPoint(response.data.data.startPoint);
						setVisibleMap(true);
					} else
						swal(
							'ERRO!',
							'Houve um erro para localizar os Alunos',
							'error'
						);
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-header">Rota do Transporte Escolar</div>
				<div className="card-body">
					<form onSubmit={handleSubmit}>
						<div className="form-row">
							<div className="form-group col-12 col-lg-6 col-md-6">
								<label htmlFor="Addres">
									<b>Saída</b>
								</label>
								<input
									required
									type="text"
									className="form-control"
									id="Addres"
									name="Addres"
									placeholder="Rua, SN - Bairro, Cidade - UF, CEP"
									value={startAddress}
									onChange={event =>
										setStartAddress(event.target.value)
									}
								/>
							</div>
							<div className="form-group col-12 col-lg-6 col-md-6">
								<label htmlFor="school">
									<b>Destino</b>
								</label>
								<select
									id="school"
									className="form-control"
									onChange={event =>
										setSchoolID(event.target.value)
									}
								>
									<option value="">Escolha a escola</option>
									{school.map(schoolItem => (
										<option
											key={schoolItem.id_escola}
											value={schoolItem.id_escola}
										>
											{schoolItem.desc_nome_escola}
										</option>
									))}
								</select>
							</div>
							{inputFields.map((inputField, index) => (
								<Fragment
									// eslint-disable-next-line react/no-array-index-key
									key={`${inputField}~${index}`}
								>
									<div className="form-group col-12 col-lg-4 col-md-4">
										<label htmlFor="RA">
											<b>RA</b>
										</label>
										<select
											name="RA"
											className="form-control"
											id="lblIdStudents"
											onChange={event =>
												handleInputChange(index, event)
											}
										>
											<option>Escolha um Aluno</option>
											{students.map(usuario => (
												<option
													value={usuario.id_usuario}
													key={usuario.id_usuario}
												>
													{usuario.num_ra} -{' '}
													{usuario.nome_usuario}
												</option>
											))}
										</select>
									</div>
									<div className="form-group col-12 col-lg-6 col-md-6">
										<label htmlFor="Addres">
											<b>Endereço</b>
										</label>
										<input
											required
											type="text"
											className="form-control"
											id="Addres"
											name="Addres"
											placeholder="Rua, SN - Bairro, Cidade - UF, CEP"
											value={inputField.Addres}
											onChange={event =>
												handleInputChange(index, event)
											}
										/>
									</div>
									<div className="form-group col-sm-2 d-flex justify-content-around align-items-end">
										{inputFields.length > 1 && (
											<button
												className="btn btn-danger formRouteStudentsAddRemoveButtons"
												type="button"
												onClick={() =>
													handleRemoveFields(index)
												}
											>
												<FaMinus fill="#ffffff" />
											</button>
										)}
										{inputFields.length <= 30 && (
											<button
												className="btn btn-success formRouteStudentsAddRemoveButtons"
												type="button"
												onClick={() =>
													handleAddFields()
												}
											>
												<FaPlus fill="#ffffff" />
											</button>
										)}
									</div>
								</Fragment>
							))}
						</div>
						<div className="submit-button">
							<button
								className="btn btn-primary mr-2"
								type="submit"
							>
								Traçar Rota
							</button>
						</div>
					</form>
				</div>
			</div>
			{isLoading === true && visibleMap === false && (
				<div className="lds-dual-ring" />
			)}
			{isLoading === false && visibleMap === true && (
				<MapStudents
					coordStudents={coordStudents}
					endPoint={endPoint}
					startPoint={startPoint}
				/>
			)}
		</>
	);
}
