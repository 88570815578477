import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	FaTimesCircle,
	FaPencilAlt,
	FaTrashAlt,
	FaEye,
	FaExchangeAlt,
} from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import RouteParams from '../../routes/data/RouteParams';

export default function ListVehicle() {
	const userActualType = useSelector(state => state.userType.actualType);
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Veículos',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [vehicles, setVehicles] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	async function loadVehicle() {
		setIsLoading(true);
		const response = await api.get('/transporte/veiculo/lista-veiculo');
		if (!response.data.error) setVehicles(response.data.data);
		else {
			setVehicles([]);
			swal(response.data.message);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		loadVehicle();
	}, []);

	function OptionsButton({ vehicle }) {
		function viewNavigation() {
			const params = {};

			params[RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE] =
				vehicle.id_veiculo;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.INFO_VEHICLE,
				{ params }
			);
		}

		function editNavigation() {
			const params = {};

			params[RouteParams.LEVEL_08_ROUTES.EDIT_VEHICLE.ID_VEHICLE] =
				vehicle.id_veiculo;

			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.UPDATE_VEHICLE,
				{ params }
			);
		}

		async function handleDeactivated(e) {
			swal({
				title: 'Você tem certeza?',
				text: `Você está para desativar o veículo da placa: ${e.placa}`,
				icon: 'warning',
				buttons: ['Cancelar', 'Confirmar'],
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const data = { idVeiculo: e.id_veiculo };
						const {
							data: { message },
						} = await api.post(
							'/transporte/veiculo/desativa-veiculo',
							data
						);
						swal('SUCESSO!', `${message}`, 'success').then(
							value => {
								if (value) loadVehicle();
							}
						);
					})();
				else swal('Ação cancelada');
			});
		}

		async function handleActivated(e) {
			swal({
				title: 'Você tem certeza?',
				text: `Você está para Reativar o veículo da placa: ${e.placa}`,
				icon: 'warning',
				buttons: ['Cancelar', 'Confirmar'],
				dangerMode: true,
			}).then(willDelete => {
				if (willDelete)
					(async function() {
						const data = { idVeiculo: e.id_veiculo };
						const {
							data: { message },
						} = await api.post(
							'/transporte/veiculo/ativar-veiculo',
							data
						);
						swal('SUCESSO!', `${message}`, 'success').then(
							value => {
								if (value) loadVehicle();
							}
						);
					})();
				else swal('Ação cancelada');
			});
		}

		return (
			<>
				<button
					type="button"
					aria-hidden
					data-toggle="tooltip"
					title="Ver Veículo"
					className="buttonViewColor"
					onClick={() => viewNavigation(vehicle)}
				>
					<FaEye fill="#ffffff" />
				</button>
				<button
					type="button"
					disabled={vehicle.ativo === 0}
					aria-hidden
					data-toggle="tooltip"
					title="Editar Veículo"
					className="buttonAddColor"
					onClick={() => editNavigation(vehicle)}
				>
					<FaPencilAlt fill="#ffffff" />
				</button>
				{vehicle.ativo === 1 ? (
					<button
						type="button"
						className="buttonDelete"
						aria-hidden
						data-toggle="tooltip"
						title="Desativar Veículo"
						onClick={() => handleDeactivated(vehicle)}
					>
						<FaTrashAlt fill="#ffffff" />
					</button>
				) : (
					<button
						type="button"
						className="buttonDelete"
						aria-hidden
						data-toggle="tooltip"
						title="Reativar Veículo"
						onClick={() => handleActivated(vehicle)}
					>
						<FaExchangeAlt fill="#ffffff" />
					</button>
				)}
			</>
		);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<div className="card">
					<div className="card-body">
						<div className="d-flex flex-row-reverse">
							<Link
								to={
									Routes.LOGGED_ROUTES(
										RouteByPermission[userActualType]
									).ADD_VEHICLE
								}
							>
								<button
									className="btn buttonCustomSemec"
									type="button"
									id="button"
								>
									Cadastrar novo veículo
								</button>
							</Link>
						</div>
						{vehicles ? (
							<Table
								header={['Placa', 'Modelo', 'Ativo', '']}
								body={vehicles.map(vehicle => {
									return [
										vehicle.placa,
										vehicle.modelo,
										vehicle.ativo === 0 ? 'Não' : 'Sim',
										<OptionsButton vehicle={vehicle} />,
									];
								})}
							/>
						) : (
							<div className="productsNotFound">
								<FaTimesCircle size={30} />
								<p className="mt-2">
									Nenhum veículo encontrado
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}
