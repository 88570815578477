import React from 'react';
import { FaEdit } from 'react-icons/fa';
import styles from './styles.module.css';

export default function BtnUpdate({ protocol, link }) {
	function viewNavigation() {
		const url = `${link}/${protocol}`;

		window.open(url, '_blank');
	}

	return (
		<>
			<button
				className={styles.updateButton}
				onClick={() => viewNavigation(protocol)}
			>
				<FaEdit />
			</button>
		</>
	);
}
