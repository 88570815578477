import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRouter from './PrivateRouter';
import Level_04 from '../pages/Home/HomeLevel04';
import ListStudents from '../pages/Students';
import infoStudents from '../pages/Students/Info';
import ReportCard from '../pages/Students/ReportCard';
import FailList from '../pages/Students/FailList';
import ClassRegister from '../pages/Students/ClassRegister';
import CorrectAttendance from '../pages/Students/CorrectAttendance';
import School from '../pages/School';
import SchoolInfo from '../pages/School/Info';
import Communicated from '../pages/Communicated';
import WriteCommunicated from '../pages/Communicated/WriteCommunicated';
import ReadCommunicatedSendBox from '../pages/Communicated/ReadCommunicatedSendBox';
import ReadCommunicatedInbox from '../pages/Communicated/ReadCommunicatedInbox';
import AddCommunicatedMessage from '../pages/Communicated/AddCommunicatedMessage';
import SubjectsContents from '../pages/Subjects';
import ReadNotices from '../pages/Notices';
import InfoProfile from '../pages/Profile/InfoProfile';
import ChangePassWord from '../pages/Profile/ChangePassWord';
import ChangeContact from '../pages/Profile/ChangeContact';
import Occurrences from '../pages/Occurrences/Occurrences';
import Messages from '../pages/Occurrences/Messages';
import ListClasses from '../pages/Classes';
import Grades from '../pages/Grade/NotesCoordinator';
import PostNotes from '../pages/Grade/PostNotes';
import Routes from './data/Routes';

export default function RoutesCoordinator({ match: { url } }) {
	const LOGGED_ROUTES = Routes.LOGGED_ROUTES(url);

	return (
		<Switch>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.HOME}
				exact
				component={Level_04}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.STUDENTS}
				exact
				component={ListStudents}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.INFO_STUDENTS}
				exact
				component={infoStudents}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.REPORT_CARD}
				exact
				component={ReportCard}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.SCHOOLS}
				exact
				component={School}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.SCHOOL_INFO}
				exact
				component={SchoolInfo}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.COMMUNICATED}
				exact
				component={Communicated}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.WRITE_COMMUNICATED}
				component={WriteCommunicated}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.READ_COMMUNICATED_SEND}
				component={ReadCommunicatedSendBox}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.READ_COMMUNICATED_RECEIVE}
				exact
				component={ReadCommunicatedInbox}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.ADD_COMMUNICATED_MESSAGE}
				exact
				component={AddCommunicatedMessage}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.PROGRAMMATIC_CONTENT}
				component={SubjectsContents}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.READ_NOTICES}
				exact
				component={ReadNotices}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.CORRECT_ATTENDANCE}
				exact
				component={CorrectAttendance}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.REPROVED_LIST}
				component={FailList}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.STUDENT_ATTENDANCE}
				component={ClassRegister}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.INFO_PROFILE}
				component={InfoProfile}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.CHANGE_PASSWORD}
				component={ChangePassWord}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.OCCURRENCES}
				exact
				component={Occurrences}
			/>
			<PrivateRouter
				userTypes={[6]}
				path={LOGGED_ROUTES.MESSAGES}
				exact
				component={Messages}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.CHANGE_CONTACT}
				component={ChangeContact}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.LIST_CLASSES_COORDINATOR}
				component={ListClasses}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.GRADE}
				component={Grades}
			/>
			<PrivateRouter
				userTypes={[6]}
				exact
				path={LOGGED_ROUTES.POSTNOTES}
				component={PostNotes}
			/>
		</Switch>
	);
}
