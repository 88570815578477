import React, { useState } from 'react';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import api from '../../../services/api';

export default function ButtonAddVehicle({ loadVehicle }) {
	const [codRenavam, setCodRenavam] = useState('');
	const [placa, setPlaca] = useState('');
	const [modelo, setModelo] = useState('');
	const [proprietario, setProprietario] = useState('');
	const [cpfProprietario, setCpfProprietario] = useState('');
	const [chassi, setChassi] = useState('');

	const [disableButton, setDisableButton] = useState(false);
	const [buttonMessage, setButtonMessage] = useState('Adicionar');

	async function handleAddVehicle() {
		setButtonMessage('Carregando...');
		setDisableButton(true);
		try {
			const { data } = await api.post(
				'/transporte/veiculo/criar-veiculo',
				{
					codRenavam,
					placa,
					modelo,
					proprietario,
					cpfProprietario,
					chassi,
				}
			);
			if (data.error === false) {
				await loadVehicle();
				setCodRenavam('');
				setPlaca('');
				setModelo('');
				setProprietario('');
				setCpfProprietario('');
				setChassi('');
				swal({
					icon: 'success',
					text: 'Veículo cadastrado corretamente.',
				});
			} else swal({ icon: 'error', text: data.message });
		} catch (error) {
			swal('Houve um erro ao adicionar a veículo.');
		}
		setButtonMessage('Adicionar');
		setDisableButton(false);
	}

	return (
		<>
			<button
				className="btn buttonCustomSemec"
				type="button"
				id="button"
				data-toggle="modal"
				data-target="#addVehicle"
			>
				Adicionar Veículo
			</button>
			<div className="modal fade" id="addVehicle">
				<div
					className="modal-dialog modal-dialog-scrollable"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Adicionar Veículo</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group text-left">
								<label htmlFor="addVehicle_renavam">
									Cod. Renavam:
								</label>
								<input
									type="text"
									className="form-control"
									id="addVehicle_renavam"
									placeholder="Renavam do veículo"
									value={codRenavam}
									onChange={event =>
										setCodRenavam(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="addVehicle_placa">Placa:</label>
								<input
									type="text"
									className="form-control"
									id="addVehicle_placa"
									placeholder="Placa do veículo"
									value={placa}
									onChange={event =>
										setPlaca(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="addVehicle_modelo">
									Modelo:
								</label>
								<input
									type="text"
									className="form-control"
									id="addVehicle_modelo"
									placeholder="Ex: Gol 1.8, 4 portas"
									value={modelo}
									onChange={event =>
										setModelo(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="addVehicle_proprietario">
									Proprietário:
								</label>
								<input
									type="text"
									className="form-control"
									id="addVehicle_proprietario"
									placeholder="Nome do proprietário"
									value={proprietario}
									onChange={event =>
										setProprietario(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="addVehicle_cpf">
									CPF Proprietário:
								</label>
								<InputMask
									mask="999.999.999-99"
									size={16}
									type="text"
									name="cpfProprietario"
									id="addVehicle_cpf"
									className="form-control"
									placeholder="CPF do Usuário"
									value={cpfProprietario}
									onChange={event =>
										setCpfProprietario(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="addVehicle_chassi">
									Chassi:
								</label>
								<input
									type="text"
									className="form-control"
									id="addVehicle_chassi"
									placeholder="Chassi"
									value={chassi}
									onChange={event =>
										setChassi(event.target.value)
									}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
							{codRenavam === '' ||
							placa === '' ||
							modelo === '' ? (
								<button
									type="button"
									className="btn btn-disabled btn-block"
									disabled
								>
									{buttonMessage}
								</button>
							) : (
								<button
									type="button"
									onClick={handleAddVehicle}
									disabled={disableButton}
									className="btn btn-primary btn-block"
								>
									{buttonMessage}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
