import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import './style.css';
import RouteParams from '../../../routes/data/RouteParams';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import regex from '../../../helpers/regex';

export default function Edit() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [product, setProduct] = useState({});
	const [providers, setProviders] = useState([]);
	const location = useLocation();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PRODUCTS,
			namePage: 'Produtos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Editar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function editProduct(event) {
		event.preventDefault();

		const response = await api.post('/cozinha-piloto/produtos/editar', {
			id_produto: product.id_produto,
			nome: product.nome,
			preco: product.preco,
			quantidade: product.quantidade,
			per_capita: product.per_capita,
			id_fornecedor: product.id_fornecedor,
		});

		!response.data.error
			? swal('SUCESSO!', 'Produto editado com sucesso', 'success')
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function getProviders() {
		const response = await api.get('/cozinha-piloto/fornecedores');

		!response.data.error
			? setProviders(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	useEffect(() => {
		if (location.state)
			setProduct(
				location.state.params[
					RouteParams.LEVEL_07_ROUTES.EDIT_PRODUCTS.PRODUCT
				]
			);
		getProviders();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={editProduct}>
				<div className="form-group">
					<label
						htmlFor="nameInputKitchenProductEdit"
						className="labelInputTitle"
					>
						Nome
					</label>

					<input
						id="nameInputKitchenProductEdit"
						type="text"
						required
						className="form-control"
						value={product.nome}
						pattern={regex.ONLY_LETTERS}
						onChange={event =>
							setProduct({
								...product,
								nome: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="priceInputKitchenProductEdit"
						className="labelInputTitle"
					>
						Preço
					</label>

					<input
						id="priceInputKitchenProductEdit"
						type="text"
						className="form-control"
						required
						value={product.preco}
						pattern={regex.PRICE}
						onChange={event =>
							setProduct({
								...product,
								preco: event.target.value.replace(',', '.'),
							})
						}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="percapitaInputKitchenProductEdit"
						className="labelInputTitle"
					>
						Per Capita em
						{` (${product.per_capita_unidade})`}
					</label>
					<input
						id="percapitaInputKitchenProductEdit"
						type="text"
						required
						className="form-control"
						pattern={regex.ONLY_NUMBERS}
						value={product.per_capita}
						onChange={event =>
							setProduct({
								...product,
								per_capita: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label
						htmlFor="providersInputKitchenProductEdit"
						className="labelInputTitle"
					>
						Fornecedor
					</label>
					<select
						required
						className="form-control"
						id="providersInputKitchenAddProduct"
						value={product.id_fornecedor}
						onChange={item =>
							setProduct({
								...product,
								id_fornecedor: +item.target.value,
							})
						}
					>
						{providers
							? providers.map(item => (
									<option value={item.id_fornecedor}>
										{item.nome}
									</option>
							  ))
							: ''}
					</select>
				</div>
				<button type="submit" className="btn btn-primary">
					Alterar
				</button>
			</form>
		</>
	);
}
