import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router';
import api from '../../services/api';
import Breadcrumb from '../../components/Breadcrumb';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import Table from '../../components/Table';
import SelectSerieAno from '../../components/Selects/SelectSerieAno';
import { useFunctionalities } from '../../components/Contenxt/Functionalities';

export default function ListParents() {
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);
	const [isLoading, setIsLoading] = useState(true);
	const [school, setSchool] = useState([]);
	const [schoolID, setSchoolID] = useState(null);
	const [turma, setTurma] = useState(null);
	const [neceTrans, setNeceTrans] = useState(null);
	const [neceEspe, setNeceEspe] = useState(null);
	const [acesso, setAcesso] = useState(null);
	const [serieID, setSerieID] = useState(null);
	const [parents, setParents] = useState([]);

	const {
		functionalities: { functionalities },
	} = useFunctionalities();
	const [updateParents, setUpdateParents] = useState(false);

	useEffect(() => {
		if (functionalities && functionalities.length) {
			const existe = functionalities.find(
				x => x.id_funcionalidade === 86
			);
			if (existe) setUpdateParents(true);
			else setUpdateParents(false);
		}
	}, [functionalities]);
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Responsáveis',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			const response = await api.get('/responsavel/lista-responsaveis');
			if (!response.data.error) setParents(response.data.data);
			else {
				setParents([]);
				swal('ERRO!', `${response.data.message}`, 'error');
			}
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if (data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	async function handleRegister(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.REGISTER_PARENTS
		);
	}
	async function handleDisassociate(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.DISASSOCIATE_PARENT
		);
	}

	async function handleSeachStudents() {
		setIsLoading(true);
		const data = {
			schoolID,
			serieID,
			turma,
			neceTrans,
			neceEspe,
			acesso,
		};
		const response = await api.post(
			'/responsavel/lista-responsaveis',
			data
		);
		if (!response.data.error) setParents(response.data.data);
		else setParents([]);

		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card">
				<div className="card-body">
					{updateParents && (
						<>
							<div className="row d-flex justify-content-between">
								<div className="col-12 col-md-5 col-lg-5 ">
									<Tooltip
										title="Cadastrar Responvável no sistema."
										placement="top-start"
									>
										<button
											onClick={handleRegister}
											className="btn btn-primary btn-block"
											type="submit"
										>
											Cadastrar Responsável
										</button>
									</Tooltip>
								</div>
								<div className="col-12 col-md-5 col-lg-5">
									<Tooltip
										title="Desassociar Responsável de um aluno."
										placement="top-start"
									>
										<button
											onClick={handleDisassociate}
											className="btn btn-primary btn-block"
											type="submit"
										>
											Desassociar Responsável
										</button>
									</Tooltip>
								</div>
							</div>
							<hr />
						</>
					)}

					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Escola</b>
							</label>
							<select
								id="school"
								className="form-control"
								onChange={event =>
									setSchoolID(event.target.value)
								}
							>
								<option value="">Escolha a escola</option>
								{school.map(schoolItem => (
									<option
										key={schoolItem.id_escola}
										value={schoolItem.id_escola}
									>
										{schoolItem.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Série</b>
							</label>
							<SelectSerieAno
								setIdSerieAno={setSerieID}
								valueSelect={serieID}
							/>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Turma</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={turma}
								onChange={event => setTurma(event.target.value)}
							>
								<option value="">Escolha uma opção</option>
								<option value="A">A</option>
								<option value="B">B</option>
								<option value="C">C</option>
								<option value="D">D</option>
								<option value="F">F</option>
								<option value="G">G</option>
								<option value="H">H</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="school">
								<b>Necessidade de Transporte</b>
							</label>
							<select
								id="school"
								value={neceTrans}
								className="form-control"
								onChange={event =>
									setNeceTrans(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="0">Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Necessidades Especiais</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={neceEspe}
								onChange={event =>
									setNeceEspe(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="0">Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
						<div className="col-md-4 col-lg-4 col-12 form-group">
							<label htmlFor="class">
								<b>Acesso no Sistema</b>
							</label>
							<select
								id="class"
								className="form-control"
								value={acesso}
								onChange={event =>
									setAcesso(event.target.value)
								}
							>
								<option value="">Escolha uma opção</option>
								<option value="0">Não</option>
								<option value="1">Sim</option>
							</select>
						</div>
					</div>
					<button
						onClick={handleSeachStudents}
						className="btn btn-primary"
						type="button"
						id="button-addon2"
					>
						Filtrar
					</button>
					<hr />
					{isLoading === true ? (
						<div className="lds-dual-ring" />
					) : (
						<Table
							header={[
								'Responsável',
								'Aluno',
								'Acesso ao Sistema',
							]}
							body={parents.map(parent => {
								return [
									parent.responsavel,
									parent.filho,
									parent.acesso,
								];
							})}
						/>
					)}
				</div>
			</div>
		</>
	);
}
