import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import Panel from '../../components/Panel';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import api from '../../services/api';
import parseJwt from '../../helpers/parseJwt';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import ScrollToTop from '../../components/buttom/ScrollToTop';

export default function ReportCard() {
	const [subjects, setSubjects] = useState([]);
	const [attendance, setAttendance] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const tokenUsuario = localStorage.getItem('@token');
	const idUsuario = parseJwt(tokenUsuario);
	const userActualType = useSelector(state => state.userType.actualType);
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Boletim',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function loadBoletim() {
		const { data } = await api.get('/aluno/boletim', {
			params: {
				alunoID: idUsuario.sub,
			},
		});
		if (data.error === true) {
			swal({ icon: 'Warning', text: data.message });
			setSubjects([]);
		} else setSubjects(data.data.nota);
	}

	async function loadFrequencia() {
		setIsLoading(true);
		const year = new Date().getFullYear();
		const { data } = await api.get('/aluno/frequenciabimestre', {
			params: {
				dataInicio1: `${year}-02-03`,
				dataFim1: `${year}-04-03`,
				dataInicio2: `${year}-04-04`,
				dataFim2: `${year}-06-30`,
				dataInicio3: `${year}-08-01`,
				dataFim3: `${year}-10-02`,
				dataInicio4: `${year}-10-03`,
				dataFim4: `${year}-12-23`,
			},
		});
		if (data.error === true) {
			swal({ icon: 'Warning', text: data.message });
			setSubjects([]);
		} else setAttendance(Object.entries(data.data));
		setIsLoading(false);
	}

	useEffect(() => {
		loadBoletim();
		loadFrequencia();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />

			{isLoading ? (
				<div className="lds-dual-ring" />
			) : (
				<>
					<div className="row">
						<div className="col-12 form-group">
							<div className="card">
								<div className="card-header">Notas</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
												<tr>
													<th scope="col">
														Disciplina
													</th>
													<th scope="col">1º Bim</th>
													<th scope="col">2º Bim</th>
													<th scope="col">3º Bim</th>
													<th scope="col">4º Bim</th>
													<th scope="col">Média</th>
												</tr>
											</thead>
											<tbody>
												{subjects.map(subject => (
													<tr
														key={
															subject.id_disciplina
														}
													>
														<th scope="row">
															{
																subject.nome_disciplina
															}
														</th>
														<td>
															{
																subject.nota_bimestral1
															}
														</td>
														<td>
															{
																subject.nota_bimestral2
															}
														</td>
														<td>
															{
																subject.nota_bimestral3
															}
														</td>
														<td>
															{
																subject.nota_bimestral4
															}
														</td>
														<td>
															{
																subject.nota_media_final
															}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 form-group">
							<div className="card">
								<div className="card-header">Faltas</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
												<tr>
													<th scope="col">
														Disciplina
													</th>
													<th scope="col">1º Bim</th>
													<th scope="col">2º Bim</th>
													<th scope="col">3º Bim</th>
													<th scope="col">4º Bim</th>
													<th scope="col">Total</th>
												</tr>
											</thead>
											<tbody>
												{attendance.map(frequency => (
													<tr
														key={
															frequency.id_disciplina
														}
													>
														<th scope="row">
															{frequency[0]}
														</th>
														<td>
															{frequency[1].b1 ||
																0}
														</td>
														<td>
															{frequency[1].b2 ||
																0}
														</td>
														<td>
															{frequency[1].b3 ||
																0}
														</td>
														<td>
															{frequency[1].b4 ||
																0}
														</td>
														<td>
															{frequency[1]
																.total || 0}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
