import React from 'react';

// eslint-disable-next-line react/prop-types
export default function SelectCursoNovoTec({
	setCursoNovoTec,
	valueSelect,
	disabled,
}) {
	// eslint-disable-next-line no-shadow
	async function handleSubmit(e) {
		setCursoNovoTec(e);
	}
	return (
		<>
			<select
				required
				className="form-control"
				name="lblCursoNovoTec"
				id="lblCursoNovoTec"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option>Não</option>
				<option value="1">Administração</option>
				<option value="2">Logística</option>
				<option value="3">Marketing</option>
				<option value="4">Recursos Humanos</option>
				<option value="5">Informática</option>
				<option value="6">Informática para Internet</option>
				<option value="7">Redes de Computadores</option>
				<option value="8">Desenvolvimento de Sistemas</option>
			</select>
		</>
	);
}
