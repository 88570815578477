import React, { useState, Fragment, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function CardMonitor({
	groupMonitor,
	monitors,
	setMonitors,
	setMonitorsToRemove,
	setMonitorToAdd,
}) {
	const [listMonitors, setListMonitor] = useState([]);
	const [arrayToRemoveMonitor, setArrayToRemoveMonitor] = useState([]);
	const [newGroupMonitor, setGroupMonitor] = useState(groupMonitor);
	const [arrayMonitor, setArrayMonitor] = useState([]);
	const [inputFields, setInputFields] = useState([{ idMonitor: '' }]);

	function loadMonitors() {
		api.get('/transporte/monitor/lista-monitor')
			.then(response => response.data.data)
			.then(setListMonitor)
			.catch(() => setListMonitor([]));
	}

	useEffect(() => {
		loadMonitors();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ idMonitor: '' });
		setInputFields(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		const arrayValues = [...arrayMonitor];
		values.splice(index, 1);
		arrayValues.splice(index, 1);
		setArrayMonitor(arrayValues);
		setInputFields(values);
		setMonitorToAdd(arrayValues);
	};

	const handleInputChange = (index, event) => {
		const data = [...arrayMonitor];
		if (event.target.value !== '') data[index] = event.target.value;
		else data.splice(index, 1);
		setArrayMonitor(data);
		setMonitorToAdd(data);
		const values = [...inputFields];
		if (event.target.name === 'idMonitor')
			values[index].idMonitor = event.target.value;
		setInputFields(values);
	};

	async function removeMonitor(e) {
		const updateEmployees = newGroupMonitor.filter(
			s => s.user.id_usuario !== e
		);
		const updateMonitor = monitors.filter(s => s !== e);

		if (!updateEmployees.length)
			swal('ATENÇÃO', 'Não tem monitor no Transporte.', 'warning');
		setArrayToRemoveMonitor([...arrayToRemoveMonitor, e]);
		setMonitorsToRemove([...arrayToRemoveMonitor, e]);
		setGroupMonitor(updateEmployees);
		setMonitors(updateMonitor);
	}

	return (
		<>
			{newGroupMonitor.map(monitor => (
				<>
					<div className="row">
						<div className="mt-2 form-group col-12 col-lg-10 col-md-10">
							<p>
								<b>Monnitor: </b>
								{monitor.user.nome_usuario}
							</p>
						</div>
						<div className="form-group col-12 col-lg-2 col-md-2">
							<button
								className="btn btn-warning"
								type="button"
								onClick={() =>
									removeMonitor(monitor.user.id_usuario)
								}
							>
								Remover
							</button>
						</div>
					</div>
					<hr />
				</>
			))}
			<div className="row">
				{inputFields.map((inputField, index) => (
					<Fragment
						// eslint-disable-next-line react/no-array-index-key
						key={`${inputField}~${index}`}
					>
						<div className="form-group col-10 col-lg-10 col-md-10">
							<select
								name="idMonitor"
								className="form-control"
								id="idMonitor"
								onChange={event =>
									handleInputChange(index, event)
								}
							>
								<option value="">Escolha um Monitor</option>
								{listMonitors
									? listMonitors.map(monitor => (
											<option
												key={monitor.users.id_usuario}
												value={monitor.users.id_usuario}
											>
												Monitor:{' '}
												{monitor.users.nome_usuario}
											</option>
									  ))
									: null}
							</select>
						</div>
						<div className="form-group col-1 col-lg-1 col-md-1">
							{inputFields.length > 1 && (
								<button
									className="btn btn-danger formRouteStudentsAddRemoveButtons"
									type="button"
									onClick={() => handleRemoveFields(index)}
								>
									<FaMinus fill="#ffffff" />
								</button>
							)}
						</div>
						<div className="form-group col-1 col-lg-1 col-md-1">
							<button
								className="btn btn-success formRouteStudentsAddRemoveButtons"
								type="button"
								onClick={() => handleAddFields()}
							>
								<FaPlus fill="#ffffff" />
							</button>
						</div>
					</Fragment>
				))}
			</div>
		</>
	);
}
