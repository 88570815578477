import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import cep from 'cep-promise';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Footer from '../../../components/Footer';
import Panel from '../../../components/Panel';
import api from '../../../services/api';
import Breadcrumb from '../../../components/Breadcrumb';
import './style.css';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';
import LabelByPermission from '../../../routes/data/LabelByPermission';
import RouteParams from '../../../routes/data/RouteParams';
import regex from '../../../helpers/regex';

export default function Edit() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [provider, setProvider] = useState({});
	const [fetching, setFetching] = useState(false);
	const [blockInputAddress, setBlockInputAddress] = useState(true);
	const location = useLocation();
	const history = useHistory();

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PROVIDERS,
			namePage: 'Fornecedores',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Editar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function editProvider(event) {
		event.preventDefault();

		const response = await api.post('/cozinha-piloto/fornecedores/editar', {
			id_fornecedor: provider.id_fornecedor,
			id_contato: provider.id_contato,
			id_categoria: provider.id_categoria,
			id_dados_bancarios: provider.id_dados_bancarios,
			id_cep: provider.id_cep,
			id_endereco: provider.id_endereco,
			nome: provider.nome,
			cpf: provider.cpf,
			numero_dap: provider.numero_dap,
			cnpj: provider.cnpj,
			ddd_numero: provider.ddd_numero,
			tel_numero: provider.tel_numero,
			banco: provider.banco,
			conta: provider.numero_conta,
			agencia: provider.numero_agencia,
			cep: provider.cep,
			logradouro: provider.logradouro,
			complemento: provider.complemento,
			estado: provider.estado,
			cidade: provider.cidade,
			bairro: provider.bairro,
			numero_casa: provider.numero_casa,
		});

		if (!response.data.error) {
			const params = {};
			params[
				RouteParams.LEVEL_07_ROUTES.EDIT_PROVIDERS.PROVIDER
			] = provider;
			history.replace({ state: { params } });
			swal('SUCESSO!', 'Fornecedor editado com sucesso', 'success');
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function fetchCEP(event) {
		event.preventDefault();

		setFetching(true);

		try {
			const response = await cep(provider.cep);

			setProvider({
				...provider,
				cidade: response.city,
				bairro: response.neighborhood,
				logradouro: response.street,
				estado: response.state,
			});
		} catch (error) {
			swal('', 'CEP não encontrado', 'warning');
			setBlockInputAddress(false);
		}

		setFetching(false);
	}

	async function getCategoryOptions() {
		const response = await api.get(
			'/cozinha-piloto/fornecedores/categorias'
		);

		!response.data.error
			? setCategoryOptions(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	useEffect(() => {
		if (location.state)
			setProvider(
				location.state.params[
					RouteParams.LEVEL_07_ROUTES.EDIT_PROVIDERS.PROVIDER
				]
			);
		getCategoryOptions();
	}, []);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={editProvider}>
				<div className="form-group">
					<label htmlFor="nameInputKitchenProvidersEdit">Nome</label>
					<input
						id="nameInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_LETTERS}
						value={provider.nome}
						onChange={event =>
							setProvider({
								...provider,
								nome: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="cpfInputKitchenProvidersEdit">CPF</label>
					<InputMask
						id="cpfInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.CPF}
						mask="999.999.999-99"
						value={provider.cpf}
						onChange={event =>
							setProvider({
								...provider,
								cpf: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="dapNumberInputKitchenProvidersEdit">
						Número DAP
					</label>
					<input
						id="dapNumberInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_NUMBERS}
						value={provider.numero_dap}
						onChange={event =>
							setProvider({
								...provider,
								numero_dap: +event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="cnpjInputKithenProvidersEdit">CNPJ</label>
					<InputMask
						id="cnpjInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.CNPJ}
						mask="99.999.999/9999-99"
						value={provider.cnpj}
						onChange={event =>
							setProvider({
								...provider,
								cnpj: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="categoryInputKitchenProvidersEdit">
						Categoria
					</label>
					<select
						id="categoryInputKitchenProvidersEdit"
						className="form-control addProviderFormSelect"
						required
						value={provider.id_categoria}
						onChange={item =>
							setProvider({
								...provider,
								id_categoria: +item.target.value,
							})
						}
					>
						{categoryOptions
							? categoryOptions.map(item => (
									<option value={item.id_categoria}>
										{item.nome}
									</option>
							  ))
							: ''}
					</select>
				</div>
				<div className="form-row my-3 d-flex align-items-end">
					<div className="col-4">
						<label htmlFor="telephoneInputKitchenProvidersEdit">
							DDD do Telefone
						</label>
						<input
							id="telephoneInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							pattern={regex.TELEPHONE_DDD}
							value={provider.ddd_numero}
							onChange={event =>
								setProvider({
									...provider,
									ddd_numero: event.target.value,
								})
							}
						/>
					</div>
					<div className="col-8">
						<label htmlFor="telephoneInputKitchenProvidersEdit">
							Telefone
						</label>
						<input
							id="telephoneInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							pattern={regex.TELEPHONE}
							value={provider.tel_numero}
							onChange={event =>
								setProvider({
									...provider,
									tel_numero: event.target.value,
								})
							}
						/>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="bankInputKitchenProvidersEdit">Banco</label>
					<input
						id="bankInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_LETTERS}
						value={provider.banco}
						onChange={event =>
							setProvider({
								...provider,
								banco: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="accountInputKitchenProvidersEdit">
						Conta
					</label>
					<input
						id="accountInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.BANK_ACCOUNT}
						value={provider.numero_conta}
						onChange={event =>
							setProvider({
								...provider,
								numero_conta: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="agencyInputKitchenProvidersEdit">
						Agência
					</label>
					<input
						id="agencyInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						pattern={regex.ONLY_NUMBERS}
						value={provider.numero_agencia}
						onChange={event =>
							setProvider({
								...provider,
								numero_agencia: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-row my-3 align-items-end">
					<div className="col-12 col-md-10">
						<label htmlFor="cepInputKitchenProvidersEdit">
							CEP
						</label>
						<InputMask
							id="cepInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							pattern={regex.CEP}
							mask="99.999-999"
							value={provider.cep}
							onChange={event =>
								setProvider({
									...provider,
									cep: event.target.value,
								})
							}
						/>
					</div>
					{!fetching ? (
						<div className="col-12 col-md-2 mt-4 d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-primary"
								onClick={fetchCEP}
							>
								Preencher Campos
							</button>
						</div>
					) : (
						<div className="col-12 col-md-2 mt-4 d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-primary"
								disabled
							>
								Carregando...
							</button>
						</div>
					)}
				</div>
				<div className="form-row my-3">
					<div className="col-8">
						<label htmlFor="complementInputKitchenProvidersEdit">
							Complemento
						</label>
						<input
							id="complementInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							pattern={regex.LETTERS_AND_NUMBERS}
							value={provider.complemento}
							onChange={event =>
								setProvider({
									...provider,
									complemento: event.target.value,
								})
							}
						/>
					</div>
					<div className="col-4">
						<label htmlFor="homeNumberInputKitchenProvidersEdit">
							Número
						</label>
						<input
							id="homeNumberInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							pattern={regex.HOUSE_NUMBER}
							value={provider.numero_casa}
							onChange={event =>
								setProvider({
									...provider,
									numero_casa: event.target.value,
								})
							}
						/>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="streetInputKitchenProvidersEdit">
						Logradouro
					</label>
					<input
						id="streetInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						disabled={blockInputAddress}
						pattern={regex.LETTERS_AND_NUMBERS}
						value={provider.logradouro}
						onChange={event =>
							setProvider({
								...provider,
								logradouro: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="neighborhoodInputKitchenProvidersEdit">
						Bairro
					</label>
					<input
						id="neighborhoodInputKitchenProvidersEdit"
						type="text"
						className="form-control"
						required
						disabled={blockInputAddress}
						pattern={regex.ALL}
						value={provider.bairro}
						onChange={event =>
							setProvider({
								...provider,
								bairro: event.target.value,
							})
						}
					/>
				</div>
				<div className="form-row my-3">
					<div className="col-6 col-sm-10">
						<label htmlFor="homeNumberInputKitchenProvidersEdit">
							Cidade
						</label>
						<input
							id="homeNumberInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							disabled={blockInputAddress}
							pattern={regex.ONLY_LETTERS}
							value={provider.cidade}
							onChange={event =>
								setProvider({
									...provider,
									cidade: event.target.value,
								})
							}
						/>
					</div>
					<div className="col-6 col-sm-2">
						<label htmlFor="stateInputKitchenProvidersEdit">
							Estado
						</label>
						<input
							id="stateInputKitchenProvidersEdit"
							type="text"
							className="form-control"
							required
							pattern={regex.STATE}
							value={provider.estado}
							onChange={event =>
								setProvider({
									...provider,
									estado: event.target.value,
								})
							}
						/>
					</div>
				</div>
				<button type="submit" className="btn btn-primary">
					Alterar
				</button>
			</form>
		</>
	);
}
