import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import Footer from '../../../../components/Footer';
import Panel from '../../../../components/Panel';
import api from '../../../../services/api';
import Breadcrumb from '../../../../components/Breadcrumb';
import './style.css';
import RouteParams from '../../../../routes/data/RouteParams';
import LabelByPermission from '../../../../routes/data/LabelByPermission';
import Routes from '../../../../routes/data/Routes';
import RouteByPermission from '../../../../routes/data/RouteByPermission';
import regex from '../../../../helpers/regex';

$.DataTable = require('datatables.net-dt');

export default function Edit() {
	const userActualType = useSelector(state => state.userType.actualType);
	const [records, setRecords] = useState([]);
	const [product, setProduct] = useState({});
	const [quantityToAdd, setQuantityToAdd] = useState(null);
	const location = useLocation();
	const history = useHistory();
	const quantityInputElement = useRef(null);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: LabelByPermission[userActualType],
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PRODUCTS,
			namePage: 'Produtos',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.PRODUCTS,
			namePage: 'Editar',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Adicionar',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];

	async function getRecords() {
		const response = await api.get('/cozinha-piloto/registros');

		!response.data.error
			? setRecords(response.data.data)
			: swal('ERRO!', `${response.data.message}`, 'error');
	}

	async function editProduct(event) {
		event.preventDefault();

		const response = await api.post(
			'/cozinha-piloto/produtos/editar/adicionar',
			{
				quantidade: product.quantidade,
				id_produto: product.id_produto,
				id_fornecedor: product.id_fornecedor,
				preco: product.preco,
				quantidade_para_adicionar: quantityToAdd,
			}
		);

		if (!response.data.error) {
			setProduct({
				...product,
				quantidade: product.quantidade + quantityToAdd,
			});
			const params = {};
			params[RouteParams.LEVEL_07_ROUTES.EDIT_PRODUCTS_ADD.PRODUCT] = {
				...product,
				quantidade: product.quantidade + quantityToAdd,
			};
			history.push(
				Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
					.EDIT_PRODUCTS_ADD,
				{ params }
			);
			swal('SUCESSO!', 'Produto editado com sucesso', 'success');
			setQuantityToAdd(null);
			quantityInputElement.current.value = '';
		} else swal('ERRO!', `${response.data.message}`, 'error');
	}

	function buildDatatable(recordsData) {
		$('#productsRecordsEditTable').DataTable({
			data: recordsData,
			order: [[0, 'desc']],
			destroy: true,
			responsive: true,
			columns: [
				{ data: 'tipo' },
				{ data: 'responsavel' },
				{ data: 'produto' },
				{ data: 'quantidade' },
				{ data: data => `R$ ${data.saldo}` },
				{ data: 'fornecedor' },
			],
			language: {
				url:
					'//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json',
			},
		});
	}

	useEffect(() => {
		if (location.state)
			setProduct(
				location.state.params[
					RouteParams.LEVEL_07_ROUTES.EDIT_PRODUCTS_ADD.PRODUCT
				]
			);
		else history.goBack();
		getRecords();
	}, []);

	useEffect(() => {
		buildDatatable(records);
	}, [records]);

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<form onSubmit={editProduct}>
				<div className="form-group">
					<label htmlFor="nameInputProductsEditAdd">Produto</label>
					<input
						id="nameInputProductsEditAdd"
						type="text"
						className="form-control"
						value={product ? product.nome : null}
						readOnly
					/>
				</div>
				<div className="form-group">
					<label htmlFor="providerInputProductsEditAdd">
						Fornecedor
					</label>
					<input
						id="providerInputProductsEditAdd"
						type="text"
						className="form-control"
						value={product ? product.fornecedor : null}
						readOnly
					/>
				</div>
				<div className="form-group">
					<label htmlFor="priceInputProductsEditAdd">Preço</label>
					<input
						id="priceInputProductsEditAdd"
						type="text"
						className="form-control"
						value={
							product
								? new Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
								  }).format(product.preco)
								: null
						}
						readOnly
					/>
				</div>
				<div className="form-group">
					<label htmlFor="totalPriceInputProductsEditAdd">
						Per Capita
					</label>
					<input
						id="totalPriceInputProductsEditAdd"
						type="text"
						className="form-control"
						value={
							product
								? `${product.per_capita} ${product.per_capita_unidade}`
								: null
						}
						readOnly
					/>
				</div>
				<div className="form-group">
					<label htmlFor="actualQuantityInputProductsEditAdd">
						Quantidade em Estoque
					</label>
					<input
						id="actualQuantityInputProductsEditAdd"
						type="text"
						className="form-control"
						value={product.quantidade}
						readOnly
					/>
				</div>
				<div className="form-group">
					<label htmlFor="quantityToAddInputProductsEditAdd">
						Quantidade para Adicionar ao Estoque
					</label>
					<input
						ref={quantityInputElement}
						id="quantityToAddInputProductsEditAdd"
						type="text"
						required
						className="form-control"
						pattern={regex.ONLY_NUMBERS}
						value={quantityToAdd}
						onChange={event =>
							setQuantityToAdd(+event.target.value)
						}
					/>
				</div>
				<button type="submit" className="btn btn-primary">
					Alterar
				</button>
			</form>

			<div className="productsRecordsCardTable card table-responsive">
				<div className="productsRecordsCardTableTitle">
					Últimos registros
				</div>
				<table
					className="table productsRecordsEditTable"
					id="productsRecordsEditTable"
				>
					<thead>
						<tr>
							<th scope="col">Tipo</th>
							<th scope="col">Responsável</th>
							<th scope="col">Produto</th>
							<th scope="col">Quantidade</th>
							<th scope="col">Saldo</th>
							<th scope="col">Fornecedor</th>
						</tr>
					</thead>
				</table>
			</div>
		</>
	);
}
