import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function ChartBar({ count }) {
	const chart = {
		data: {
			datasets: [
				{
					label: 'Alunos',
					data: [
						count.atual,
						count.matriculados,
						count.evadidos,
						count.nCompare,
						count.transferidos,
						count.remanejados,
						count.cessados,
						count.reclassificados,
						count.outros,
					],
					backgroundColor: [
						'rgba(161,164,230,0.9)',
						'rgba(114,165,237,0.93)',
						'rgba(114,193,214,0.8)',
						'rgba(114,237,217,0.7)',
						'rgba(135,230,178,0.7)',
						'rgba(215,83,48,0.7)',
						'rgba(148,218,208,0.7)',
						'rgba(135,141,204,0.7)',
						'rgba(83,141,132,0.7)',
					],
				},
			],
			labels: [
				'Ativos',
				'Matriculados',
				'Abandono',
				'Não Comparecimento',
				'Transferidos',
				'Remanejados',
				'Cessada',
				'Reclassificados',
				'Outros',
			],
		},
	};
	return (
		<>
			<Bar data={chart.data} />
		</>
	);
}
