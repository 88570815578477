import React, { useState } from 'react';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function AddOccurrence({ schoolId, loadOccurrences }) {
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [disableButton, setDisableButton] = useState(false);
	const [buttonMessage, setButtonMessage] = useState('Adicionar');

	async function sendNewOccurrence() {
		setButtonMessage('Carregando...');
		setDisableButton(true);
		try {
			await api.post('/ocorrencia/registrar', {
				escola_id: schoolId,
				mensagem: message,
				assunto: subject,
			});

			await loadOccurrences();
			setSubject('');
			setMessage('');
			swal('Ocorrência salva corretamente.');
		} catch (error) {
			swal('Houve um erro ao adicionar a ocorrência.');
		}
		setButtonMessage('Adicionar');
		setDisableButton(false);
	}

	return (
		<>
			<button
				type="button"
				className="btn btn-primary btn-block"
				data-toggle="modal"
				data-target="#addOccurrenceModal"
			>
				Adicionar Ocorrência
			</button>

			<div className="modal fade" id="addOccurrenceModal">
				<div
					className="modal-dialog modal-dialog-scrollable"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">
								Adicionar Ocorrência
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body">
							<div className="form-group text-left">
								<label htmlFor="addOccurrenceModal_subject">
									Assunto:
								</label>
								<input
									type="text"
									className="form-control"
									id="addOccurrenceModal_subject"
									value={subject}
									onChange={event =>
										setSubject(event.target.value)
									}
								/>
							</div>
							<div className="form-group text-left">
								<label htmlFor="addOccurrenceModal_message">
									Mensagem:
								</label>
								<textarea
									className="form-control"
									id="addOccurrenceModal_message"
									rows="8"
									value={message}
									onChange={event =>
										setMessage(event.target.value)
									}
								/>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								Fechar
							</button>
							{message === '' || subject === '' ? (
								<button
									type="button"
									className="btn btn-disabled btn-block"
									disabled
								>
									{buttonMessage}
								</button>
							) : (
								<button
									type="button"
									disabled={disableButton}
									className="btn btn-primary btn-block"
									onClick={sendNewOccurrence}
								>
									{buttonMessage}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
