import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { FaEdit } from 'react-icons/fa';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import parseJwt from '../../helpers/parseJwt';

export default function CardUserContacts({
	userContacts,
	idUsuario = null,
	editable = true,
	tittle = 'Contato',
}) {
	const history = useHistory();
	const token = localStorage.getItem('@token');
	const tokenUsuario = parseJwt(token);
	const idUser = idUsuario || tokenUsuario.sub;
	const userActualType = useSelector(user => user.userType.actualType);
	const [contacts, setContacts] = useState([]);

	async function handleChangeContact(event) {
		event.preventDefault();
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.CHANGE_CONTACT,
			idUser
		);
	}
	useEffect(() => {
		setContacts(userContacts);
	}, [userContacts]);

	return (
		<>
			<div className="card mt-3">
				<div className="card-header form-group">
					<h4>Contato</h4>
				</div>
				<div className="card-body">
					{contacts.length > 0 ? (
						contacts.map(contact => (
							<>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Telefone: </b>(
											{contact.ddd_numero}){' '}
											{contact.tel_numero}
										</p>
									</div>
									{contact.complemento ? (
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>
												<b>Complemento: </b>
												{contact.complemento}
											</p>
										</div>
									) : null}
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>
											<b>Deixar Recados: </b>
											{contact.sms}
										</p>
									</div>
								</div>

								<hr />
							</>
						))
					) : (
						<div className="col-12 col-md-4 col-lg-4 form-group">
							<p>Não há registro no Sistema</p>
						</div>
					)}
				</div>
				{editable && userActualType !== 9 ? (
					<div className="card-footer">
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
								<Tooltip
									title="Alterar Dados do Usuário."
									placement="top-start"
								>
									<button
										className="buttonEdit button-profile-edit"
										type="button"
										onClick={handleChangeContact}
									>
										<FaEdit /> Editar
									</button>
								</Tooltip>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
}
