import React, { useState, Fragment, useEffect } from 'react';
import swal from 'sweetalert';
import api from '../../services/api';

export default function InfoCardSchool({
	employeesSchool,
	setInputField,
	disabled,
}) {
	const [school, setSchool] = useState([]);
	const [employees, setEmployees] = useState(employeesSchool);
	const [inputFields, setInputFields] = useState([
		{ escola: '', tipoUsuarios: '' },
	]);

	useEffect(() => {
		(async function() {
			const { data } = await api.get('/escola/rede-ensino/lista-escolas');
			if(data.error) setSchool([]);
			else setSchool(data.data);
		})();
	}, []);

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ escola: '', tipoUsuarios: '' });
		setInputFields(values);
		setInputField(values);
	};

	const handleRemoveFields = index => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
		setInputField(values);
	};

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === 'escola')
			values[index].escola = event.target.value;
		else values[index].tipoUsuarios = event.target.value;
		setInputFields(values);
		setInputField(values);
	};
	async function removeEmployees(e) {
		const updateEmployees = employees.filter(
			s => s.id_usuario_has_escola !== e
		);
		setEmployees(updateEmployees);
		if (!updateEmployees.length)
			swal('ATENÇÃO', 'Usuário sem cargo', 'warning');
		try {
			await api
				.post('/funcionarios/desativa/cargo', {
					idUsuarioHasEscola: e,
				})
				.then(response => {
					swal('ATENÇÃO', `${response.data.message}`, 'warning');
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
	}

	return (
		<div className="card mt-3">
			<div className="card-header">Serviços cadastrados</div>
			<div className="card-body">
				{employees.map(employee => (
					<>
						<div className="row">
							<div className="mt-2 form-group col-12 col-lg-10 col-md-10">
								<p>
									<b>Serviços: </b>
									{employee.tipo_usuario} {' - '}
									<b>Escola: </b>
									{employee.desc_nome_escola}
								</p>
							</div>
							{disabled === false && (
								<div className="form-group col-12 col-lg-2 col-md-2">
									<button
										className="btn btn-warning"
										type="button"
										onClick={() =>
											removeEmployees(
												employee.id_usuario_has_escola
											)
										}
									>
										Remover
									</button>
								</div>
							)}
						</div>
						<hr />
					</>
				))}
				{disabled === false ? (
					<>
						<div className="row">
							{inputFields.map((inputField, index) => (
								<Fragment
									// eslint-disable-next-line react/no-array-index-key
									key={`${inputField}~${index}`}
								>
									<div className="form-group col-12 col-lg-4 col-md-4">
										<label htmlFor="escola">
											<b>Escolas</b>
										</label>
										<select
											name="escola"
											className="form-control"
											id="schoolID"
											onChange={event =>
												handleInputChange(index, event)
											}
										>
											<option>Escolha uma Escola</option>
											{school.map(schoolItem => (
												<option
													key={schoolItem.id_escola}
													value={schoolItem.id_escola}
												>
													{
														schoolItem.desc_nome_escola
													}
												</option>
											))}
										</select>
									</div>
									<div className="form-group col-12 col-lg-6 col-md-6">
										<label htmlFor="tipoUsuario">
											<b>Tipo Usuario</b>
										</label>
										<select
											name="tipoUsuario"
											className="form-control"
											id="tipoUsuario"
											onChange={event =>
												handleInputChange(index, event)
											}
										>
											<option>Escolha um Cargo</option>
											<option value={7}>Professor</option>
											<option value={6}>
												Coordenador
											</option>
										</select>
									</div>
									<div className="form-group col-sm-2">
										{inputFields.length > 1 && (
											<button
												className="btn btn-link"
												type="button"
												onClick={() =>
													handleRemoveFields(index)
												}
											>
												-
											</button>
										)}
										<button
											className="btn btn-link"
											type="button"
											onClick={() => handleAddFields()}
										>
											+
										</button>
									</div>
								</Fragment>
							))}
						</div>
					</>
				) : null}
			</div>
		</div>
	);
}
