import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function SelectSchool({ schoolId, setSchoolId }) {
	const [schools, setSchools] = useState([]);
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		api.get('/escola/por-usuario', {
			params: {
				typeUserId: userActualType,
			},
		})
			.then(({ data }) => {
				setSchools(data.data);
			})
			.catch(() => swal('Ocorreu um erro ao selecionar as escolas.'));
	}, []);

	return (
		<>
			<label htmlFor="exampleFormControlSelect1">
				Escolha uma escola
			</label>
			<select
				className="form-control"
				id="exampleFormControlSelect1"
				value={schoolId}
				defaultValue={0}
				onChange={event => setSchoolId(event.target.value)}
			>
				<option selected value={0}>
					{schools.length === 0
						? 'Carregando...'
						: 'Escolha uma Escola'}
				</option>
				{schools.map(school => (
					<option key={school.id_escola} value={school.id_escola}>
						{school.desc_nome_escola}
					</option>
				))}
			</select>
		</>
	);
}
