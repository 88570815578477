import React, { useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import cep from 'cep-promise';
import InputMask from 'react-input-mask';
import { FaSearch } from 'react-icons/fa';
import api from '../../../services/api';
import SelectUf from '../../../components/Selects/SelectUf';
import SelectSexo from '../../../components/Selects/SelectSexo';
import SelectCorRaca from '../../../components/Selects/SelectCorRaca';
import SelectTipoTelefone from '../../../components/Selects/SelectTipoTelefone';
import Breadcrumb from '../../../components/Breadcrumb';

export default function FormMonitor({ breadCrumbItems }) {
	const history = useHistory();

	const [Email, setEmail] = useState('');
	const [IdCorRaca, setIdCorRaca] = useState('');
	const [IdSexo, setSexo] = useState('');
	const [IdNacionalidade, setIdNacionalidade] = useState('1');
	const [CodPaisOrigem, setCodPaisOrigem] = useState(76);
	const [DataEntradaPais, setDataEntradaPais] = useState('');
	const [IdCidadeNascido, setIdCidadeNascido] = useState('');
	const [IdUFMunNascto, setIdUFMunNascto] = useState('');
	const [CpfFuncionario, setCpfFuncionario] = useState('');
	const [NumDocumentoCivil, setNumDocumentoCivil] = useState('');
	const [DigitoDocumento, setDigitoDocumento] = useState('');
	const [DataEmissao, setDataEmissao] = useState('');
	const [NumVistoConfere, setNumVistoConfere] = useState('');
	const [IdUfDocumento, setIdUfDocumento] = useState('');
	const [Cep, setCep] = useState('');
	const [NumCasa, setNumCasa] = useState('');
	const [ComplementoEndereco, setComplementoEndereco] = useState('');
	const [Logradouro, setLogradouro] = useState('');
	const [Bairro, setBairro] = useState('');
	const [NomeCidade, setNomeCidade] = useState('');
	const [SiglaUfCidade, setSiglaUfCidade] = useState('');
	const [IdAreaLogradouro, setIdAreaLogradouro] = useState(1);
	const [IdLocalizacao, setIdLocalizacao] = useState(4);
	const [IdTipoTelefone, setIdTipoTelefone] = useState('');
	const [DDDTel, setDDDTel] = useState('');
	const [NumTelefone, setNumTelefone] = useState('');
	const [ComplementoTel, setComplementoTel] = useState('');
	const [Sms, setSms] = useState('');
	const [Contato, setContato] = useState([]);
	const [Disable, setDisable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setisVisible] = useState(false);
	const [NomeFuncionario, setNomeFuncionario] = useState('');
	const [disableAddress, setDisableAddress] = useState(true);
	const [DataNascimentoFuncionario, setDataNascimentoFuncionario] = useState(
		''
	);
	const [changeCPF, setChangeCPF] = useState(false);
	const [editableCpf, setEditableCpf] = useState(false);
	const toInputUppercase = e => {
		e.target.value = `${e.target.value}`.toUpperCase();
	};

	async function clearInput() {
		setNomeFuncionario('');
		setDataNascimentoFuncionario('');
		setEmail('');
		setIdCorRaca('');
		setSexo('');
		setIdNacionalidade('1');
		setCodPaisOrigem(76);
		setDataEntradaPais('');
		setIdCidadeNascido('');
		setIdUFMunNascto('');
		setNumDocumentoCivil('');
		setDigitoDocumento('');
		setDataEmissao('');
		setNumVistoConfere('');
		setIdUfDocumento('');
		setCep('');
		setNumCasa('');
		setComplementoEndereco('');
		setLogradouro('');
		setBairro('');
		setNomeCidade('');
		setSiglaUfCidade();
		setIdAreaLogradouro(1);
		setIdLocalizacao(4);
		setIdTipoTelefone('');
		setDDDTel('');
		setNumTelefone('');
		setComplementoTel('');
		setSms('');
		setContato([]);
	}

	async function handleChangeCPF(event) {
		event.preventDefault();
		setIsLoading(false);
		setisVisible(false);
		setChangeCPF(false);
		setEditableCpf(false);
		setCpfFuncionario('');
		clearInput();
	}
	async function handleExistCPF(event) {
		event.preventDefault();
		const lengthCpf = CpfFuncionario.replace(/[-_.+()\s]/g, '');
		if (CpfFuncionario && lengthCpf.length >= 11) {
			setIsLoading(true);
			setisVisible(true);
			setEditableCpf(true);
			setChangeCPF(true);
			const { data } = await api.get('/usuario/verifica-cpf-usuario', {
				params: {
					lblCpf: CpfFuncionario,
				},
			});
			if (data.error === false) {
				setDisable(true);
				swal('Informações do Usuário com esse CPF foram encontrados');
				setNomeFuncionario(data.data.usuario.responsavel);
				setDataNascimentoFuncionario(data.data.usuario.nascimento);
				setEmail(data.data.usuario.email);
				setIdCorRaca(data.data.usuario.id_cor_raca);
				setSexo(data.data.usuario.id_sexo);
				setIdNacionalidade(data.data.usuario.id_nacionalidade);
				setCodPaisOrigem(data.data.usuario.cod_pais);
				setDataEntradaPais(data.data.usuario.data_entrada_pais);
				setIdCidadeNascido(data.data.usuario.cidade_natal);
				setIdUFMunNascto(data.data.usuario.estado_natal);
				setNumDocumentoCivil(data.data.usuario.rg);
				setDigitoDocumento(data.data.usuario.digito);
				setDataEmissao(data.data.usuario.data_emissao_documento_civil);
				setNumVistoConfere(data.data.usuario.num_visto_confere);
				setIdUfDocumento(data.data.usuario.documento_uf);
				setCep(data.data.usuario.cep);
				setNumCasa(data.data.usuario.numero);
				setComplementoEndereco(data.data.usuario.complemento_endereco);
				setLogradouro(data.data.usuario.logradouro);
				setBairro(data.data.usuario.bairro);
				setNomeCidade(data.data.usuario.cidade);
				setSiglaUfCidade(data.data.usuario.estado);
				setIdAreaLogradouro(
					data.data.usuario.area_logradouro_id_area_logradouro
				);
				setIdLocalizacao(data.data.usuario.localizacao_id_localizacao);
				setContato(data.data.contato);
			} else {
				setDisable(false);
				swal(data.message);
				clearInput();
			}
			setIsLoading(false);
		} else swal({ icon: 'warning', text: 'CPF inválido.' });
	}

	function handleCEP(event) {
		event.preventDefault();
		cep(Cep)
			.then(dataPostCode => {
				if (
					dataPostCode.neighborhood === '' ||
					dataPostCode.city === '' ||
					dataPostCode.street === '' ||
					dataPostCode.state === ''
				)
					setDisableAddress(false);
				else setDisableAddress(true);
				setBairro(dataPostCode.neighborhood);
				setNomeCidade(dataPostCode.city);
				setLogradouro(dataPostCode.street);
				setSiglaUfCidade(dataPostCode.state);
			})
			.catch(error => {
				console.log(`error: ${error}`);
				swal('Cep não encontrado');
				setDisableAddress(false);
				setBairro('');
				setNomeCidade('');
				setLogradouro('');
				setSiglaUfCidade('');
			});
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			const data = {
				NomeFuncionario,
				DataNascimentoFuncionario,
				IdCorRaca,
				IdSexo,
				Email,
				NumVistoConfere,
				IdNacionalidade,
				DataEntradaPais,
				CodPaisOrigem,
				IdCidadeNascido,
				IdUFMunNascto,
				CpfFuncionario,
				NumDocumentoCivil,
				DigitoDocumento,
				IdUfDocumento,
				DataEmissao,
				Logradouro,
				NumCasa,
				Bairro,
				NomeCidade,
				SiglaUfCidade,
				ComplementoEndereco,
				Cep,
				IdAreaLogradouro,
				IdLocalizacao,
				IdTipoTelefone,
				DDDTel,
				NumTelefone,
				ComplementoTel,
				Sms,
				TipoUsuario: 14,
				Contatos: Contato,
			};
			await api
				.post('/transporte/monitor/criar-monitor', data)
				.then(response => {
					if (!response.data.error) {
						clearInput();
						swal({ icon: 'success', text: response.data.message });
						history.goBack();
					} else swal({ icon: 'error', text: response.data.message });
				});
		} catch (error) {
			console.log(`error: ${error}`);
		}
		setIsLoading(false);
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mt-3">
				<div className="card-header col-12 col-md-12 col-lg-12 form-group">
					<h4>Consulte o CPF</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6 form-group">
							<p>CPF do Monitor: </p>
							<div className="input-group">
								<InputMask
									mask="999.999.999-99"
									required
									disabled={changeCPF}
									size={16}
									type="text"
									name="CpfFuncionario"
									id="CpfFuncionario"
									className="form-control"
									placeholder="CPF do Monitor"
									value={CpfFuncionario}
									onChange={event =>
										setCpfFuncionario(event.target.value)
									}
								/>
								<div className="input-group-append">
									{editableCpf === false ? (
										<button
											onClick={handleExistCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Consultar
										</button>
									) : (
										<button
											onClick={handleChangeCPF}
											className="btn btn-outline-secondary"
											type="button"
											id="button-addon2"
										>
											Mudar
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				{isLoading === true && isVisible === true && (
					<div className="lds-dual-ring" />
				)}
				{isLoading === false && isVisible === true && (
					<>
						<div className="card mt-3">
							<div className="card-header col-12 col-md-12 col-lg-12 form-group">
								<h4>Dados do Funcionário</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Nome do Funcionário:</p>
										<div className="input-group mb-4">
											<input
												onInput={toInputUppercase}
												disabled={Disable}
												required
												type="text"
												name="NomeFuncionario"
												id="NomeFuncionario"
												className="form-control"
												placeholder="Nome do Funcionário"
												value={NomeFuncionario}
												onChange={event =>
													setNomeFuncionario(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Data de aniversário: </p>
										<div className="input-group mb-4">
											<InputMask
												mask="99/99/9999"
												disabled={Disable}
												required
												size={8}
												type="text"
												name="DataNascimentoFuncionario"
												id="DataNascimentoFuncionario"
												className="form-control"
												placeholder="Data Nascimento"
												value={
													DataNascimentoFuncionario
												}
												onChange={event =>
													setDataNascimentoFuncionario(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>E-mail</p>
										<input
											disabled={Disable}
											type="text"
											name="Email"
											id="Email"
											className="form-control"
											placeholder="Digite o E-mail"
											value={Email}
											onChange={event =>
												setEmail(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Cor/Raça</p>
										<SelectCorRaca
											disabled={Disable}
											valueSelect={IdCorRaca}
											setCorRaca={setIdCorRaca}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>Gênero</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<SelectSexo
											valueSelect={IdSexo}
											disabled={Disable}
											setSexo={setSexo}
										/>
									</div>
									<div className="col-12 col-md-2 col-lg-2 form-group">
										<p>Nacionalidade</p>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<select
											required
											disabled={Disable}
											className="form-control"
											name="IdNacionalidade"
											id="IdNacionalidade"
											onChange={event =>
												setIdNacionalidade(
													event.target.value
												)
											}
										>
											<option value="1">
												Brasileiro
											</option>
											<option value="2">
												Estrangeiro
											</option>
											<option value="3">
												Brasileiro Nascido no Exterior
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="card mt-3">
								<div className="card-header form-group">
									<h4>Documentos</h4>
								</div>
								<div className="card-body">
									<p>Número de Documento Civil</p>
									<div className="row">
										<div className="col-12 col-md-3 col-lg-3 form-group">
											<InputMask
												disabled={Disable}
												mask="99.999.999"
												required
												type="text"
												name="NumDocumentoCivil"
												id="NumDocumentoCivil"
												className="form-control"
												placeholder="RG ou RN"
												value={NumDocumentoCivil}
												onChange={event =>
													setNumDocumentoCivil(
														event.target.value
													)
												}
											/>
										</div>
										<div className="col-12 col-md-1 col-lg-1 form-group">
											<input
												disabled={Disable}
												maxLength="2"
												minLength="1"
												type="text"
												name="DigitoDocumento"
												id="DigitoDocumento"
												className="form-control"
												placeholder="XX"
												value={DigitoDocumento}
												onChange={event =>
													setDigitoDocumento(
														event.target.value
													)
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>Estado do Documento</p>
											<SelectUf
												disabled={Disable}
												valueSelect={IdUfDocumento}
												setUf={setIdUfDocumento}
											/>
										</div>
										<div className="col-12 col-md-4 col-lg-4 form-group">
											<p>Data da Emissão do Documento</p>
											<InputMask
												disabled={Disable}
												mask="99/99/9999"
												name="DataEmissao"
												id="DataEmissao"
												className="form-control"
												placeholder="Data da Emissão do
												Documento"
												value={DataEmissao}
												onChange={event =>
													setDataEmissao(
														event.target.value
													)
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Contato</h4>
							</div>
							<div className="card-body">
								{Contato.length > 0
									? Contato.map(contact => (
											<>
												<div className="row">
													<div className="col-12 col-md-4 col-lg-4 form-group">
														<p>
															<b>Telefone: </b>(
															{contact.ddd_numero}
															){' '}
															{contact.tel_numero}
														</p>
													</div>
													{contact.complemento ? (
														<div className="col-12 col-md-4 col-lg-4 form-group">
															<p>
																<b>
																	Complemento:{' '}
																</b>
																{
																	contact.complemento
																}
															</p>
														</div>
													) : null}
													<div className="col-12 col-md-4 col-lg-4 form-group">
														<p>
															<b>
																Deixar Recados:{' '}
															</b>
															{contact.sms}
														</p>
													</div>
												</div>
												<hr />
											</>
									  ))
									: null}
								{Disable === false ? (
									<>
										<div className="row">
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>Tipos de Telefone</p>
												<SelectTipoTelefone
													disabled={Disable}
													valueSelect={IdTipoTelefone}
													setTipoTelefone={
														setIdTipoTelefone
													}
												/>
											</div>
											<div className="col-12 col-md-1 col-lg-1 form-group">
												<p>DDD</p>
												<input
													disabled={Disable}
													required
													type="text"
													minLength="2"
													maxLength="2"
													name="lblDDDTel"
													id="lblDDDTel"
													className="form-control"
													placeholder="00"
													value={DDDTel}
													onChange={event =>
														setDDDTel(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-3 col-lg-3 form-group">
												<p>Telefone</p>
												<input
													disabled={Disable}
													required
													minLength="8"
													maxLength="9"
													type="text"
													name="lblNumTelefone"
													id="lblNumTelefone"
													className="form-control"
													placeholder="11111111"
													value={NumTelefone}
													onChange={event =>
														setNumTelefone(
															event.target.value
														)
													}
												/>
											</div>
											<div className="col-12 col-md-4 col-lg-4 form-group">
												<p>Deixar Recados?</p>
												<select
													disabled={Disable}
													className="form-control"
													name="lblSms"
													id="lblSms"
													value={Sms}
													onChange={event =>
														setSms(
															event.target.value
														)
													}
												>
													<option>Recados</option>
													<option value="NÃO">
														Não
													</option>
													<option value="SIM">
														Sim
													</option>
												</select>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6 form-group">
												<p>Complemento</p>
												<input
													onInput={toInputUppercase}
													disabled={Disable}
													type="text"
													name="lblComplementoTel"
													id="lblComplementoTel"
													className="form-control"
													placeholder="Digite o outro número"
													value={ComplementoTel}
													onChange={event =>
														setComplementoTel(
															event.target.value
														)
													}
												/>
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
						<div className="card mt-3">
							<div className="card-header form-group">
								<h4>Endereço</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-12 col-md-7 col-lg-5 form-group">
										<p>CEP</p>
										<div className="input-group mb-3">
											<InputMask
												disabled={Disable}
												mask="99999-999"
												required
												type="text"
												name="Cep"
												id="Cep"
												className="form-control"
												placeholder="XXXXX-XXX"
												value={Cep}
												onChange={event =>
													setCep(event.target.value)
												}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-block btn-success"
													type="button"
													onClick={handleCEP}
												>
													<FaSearch />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 form-group">
										<p>Logradouro</p>
										<input
											onInput={toInputUppercase}
											required
											disabled={disableAddress}
											type="text"
											name="Logradouro"
											id="Logradouro"
											className="form-control"
											placeholder="Digite o Logradouro"
											value={Logradouro}
											onChange={event =>
												setLogradouro(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Número</p>
										<input
											disabled={Disable}
											required
											type="text"
											name="NumCasa"
											id="NumCasa"
											className="form-control"
											placeholder="Ex: SN, 123"
											value={NumCasa}
											onChange={event =>
												setNumCasa(event.target.value)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Complemento</p>
										<input
											disabled={Disable}
											required
											onInput={toInputUppercase}
											type="text"
											name="ComplementoEndereco"
											id="ComplementoEndereco"
											className="form-control"
											placeholder="Ex: Casa, Apto..."
											value={ComplementoEndereco}
											onChange={event =>
												setComplementoEndereco(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>Bairro</p>
										<input
											required
											disabled={disableAddress}
											onInput={toInputUppercase}
											type="text"
											name="Bairro"
											id="Bairro"
											className="form-control"
											placeholder="Digite o Bairro"
											value={Bairro}
											onChange={event =>
												setBairro(event.target.value)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-8 col-lg-8 form-group">
										<p>Cidade</p>
										<input
											required
											disabled={disableAddress}
											onInput={toInputUppercase}
											type="text"
											name="NomeCidade"
											id="NomeCidade"
											className="form-control"
											placeholder="Digite a Cidade"
											value={NomeCidade}
											onChange={event =>
												setNomeCidade(
													event.target.value
												)
											}
										/>
									</div>
									<div className="col-12 col-md-4 col-lg-4 form-group">
										<p>UF</p>
										<input
											required
											disabled={disableAddress}
											maxLength="2"
											type="text"
											name="SiglaUfCidade"
											id="SiglaUfCidade"
											className="form-control"
											placeholder="Digite o UF"
											value={SiglaUfCidade}
											onChange={event =>
												setSiglaUfCidade(
													event.target.value
												)
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Área do logradouro</p>
										<select
											disabled={Disable}
											className="form-control"
											name="IdAreaLogradouro"
											id="IdAreaLogradouro"
											value={IdAreaLogradouro}
											onChange={event =>
												setIdAreaLogradouro(
													event.target.value
												)
											}
										>
											<option value="1">Urbana</option>
											<option value="2">Rural</option>
										</select>
									</div>
									<div className="col-12 col-md-6 col-lg-6 form-group">
										<p>Localização Diferenciada</p>
										<select
											disabled={Disable}
											required
											value={IdLocalizacao}
											className="form-control"
											name="IdLocalizacao"
											id="IdLocalizacao"
											onChange={event =>
												setIdLocalizacao(
													event.target.value
												)
											}
										>
											<option value="1">
												Área de assentamento
											</option>
											<option value="2">
												Terra indígena
											</option>
											<option value="3">
												Área onde se localizada em
												Comunidade remanescente de
												Quilombos
											</option>
											<option value="4">
												Não está localizado em área de
												localização diferenciada
											</option>
										</select>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="row justify-content-start">
									<div className="col-lg-4 col-md-5 col-7 form-group">
										<button
											className="btn btn-primary btn-block"
											type="submit"
										>
											Cadastrar
										</button>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</form>
		</>
	);
}
