import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CalendarActions } from '../../../../redux/ducks/calendarReducer';

export default function({
	line,
	column,
	isEditable = true,
	meals = [],
	datasheet = [],
	isAddMenu = false,
}) {
	const dispatch = useDispatch();
	const options = useSelector(state => state.calendar);

	useEffect(() => {
		if (options.length === 0) return;

		if (isEditable && !isAddMenu)
			setTimeout(() => {
				const selectCalendarItem = document.getElementById(
					`selectCalendarItem${line}${column}`
				);

				selectCalendarItem.value = options[line][column];
			}, 1000);
	}, [options]);

	return (
		<>
			{isEditable ? (
				<select
					id={`selectCalendarItem${line}${column}`}
					className="form-control"
					value={!options.length === 0 ? options[line][column] : null}
					onChange={item => {
						dispatch(
							CalendarActions.updateCalendarItem(
								+item.target.value,
								line,
								column
							)
						);
					}}
				>
					<option selected disabled />
					{meals
						? meals.map(item => (
								<option value={item.id_ficha_tecnica}>
									{item.receita}
								</option>
						  ))
						: ''}
				</select>
			) : (
				datasheet.map(item =>
					item.id_ficha_tecnica == options[line][column] ? (
						<span>{item.receita}</span>
					) : null
				)
			)}
		</>
	);
}
