import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Panel from '../../../components/Panel';
import ScrollToTop from '../../../components/buttom/ScrollToTop';
import Footer from '../../../components/Footer';
import Breadcrumb from './Breadcrumb';
import api from '../../../services/api';
import Message from './Message';
import AddMessage from './AddMessage';
import Bookloading from '../../../components/Loading/Bookloading';
import RouteParams from '../../../routes/data/RouteParams';

export default function Messages({ location: { state } }) {
	const [messages, setMessages] = useState([]);
	const [occurrence, setOccurrence] = useState();

	function loadMessages() {
		setMessages([]);
		api.get(`/ocorrencia/mensagens?ocorrencia_id=${occurrence.id}`)
			.then(response => response.data.data)
			.then(setMessages)
			.catch(() => swal('Houve um erro ao selecionar as mensagens.'));
	}

	useEffect(() => {
		if (state)
			setOccurrence(
				state[RouteParams.LEVEL_05_ROUTES.MESSAGES.OCCURRENCE]
			);
	}, [state]);

	useEffect(() => {
		if (occurrence) loadMessages();
	}, [occurrence]);

	return (
		<>
			<Breadcrumb />
			{occurrence ? (
				<div className="col-xl-11 col-lg-11 col-md-12 text-center">
					<p>
						<b>Assunto:</b> {occurrence.assunto}
					</p>
					<p>
						<b>Data:</b> {occurrence.data}
					</p>
					<p>
						<b>Relator:</b> {occurrence.relator}
					</p>

					<AddMessage
						occurrenceId={occurrence.id}
						loadMessages={loadMessages}
					/>

					<hr />
				</div>
			) : null}
			{messages.length === 0 ? (
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12 text-center">
						<Bookloading
							isLoading
							style={{ width: '30%', marginLeft: '35%' }}
						/>
					</div>
				</div>
			) : (
				messages.map(message => <Message message={message} />)
			)}
		</>
	);
}
