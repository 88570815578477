import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../components/Breadcrumb';
import api from '../../services/api';
import Routes from '../../routes/data/Routes';
import RouteByPermission from '../../routes/data/RouteByPermission';
import './style.css';

export default function FaultsTable() {
	const [faultsStudents, setFaults] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userActualType = useSelector(state => state.userType.actualType);

	useEffect(() => {
		(async function() {
			const response = await api.get('/responsavel/faltas');
			if (!response.data.error) {
				setFaults(response.data.data);
				setIsLoading(false);
			} else {
				setFaults([]);
				setIsLoading(false);
			}
		})();
	}, []);
	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: '#',
			namePage: 'Faltas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	return (
		<>
			<div className="container-fluid">
				<div className="row painel justify-content-center">
					<div className="col-xl-11 col-lg-11 col-md-12">
						<Breadcrumb breadCrumbItems={breadCrumbItems} />
						{isLoading === true && (
							<div className="lds-dual-ring" />
						)}
						{isLoading === false && (
							<div className="card">
								{faultsStudents.length ? (
									<div className="card-body table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th scope="col">
														Disciplina
													</th>
													<th scope="col">Dia</th>
													<th scope="col">
														Contéudo
													</th>
												</tr>
											</thead>
											<tbody>
												{faultsStudents.map(element => (
													<tr>
														<td>
															{element.disciplina}
														</td>
														<td>{element.dia}</td>
														<td>
															{element.conteudo}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								) : (
									<div className="card-body">
										<p>
											<b>Aluno Sem falta</b>
										</p>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
