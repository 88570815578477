import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

export default function SelectKindCoordinator({ setAlunos }) {
	const [schoolCoordinator, setSchoolCoordinator] = useState([]);
	const [schoolIDTeacher, setSchoolIDTeacher] = useState(null);
	const [classTeacher, setClassTeacher] = useState([]);
	const [disabledClass, setDisabledClass] = useState(true);

	useEffect(() => {
		async function loadSchool() {
			const response = await api.get('/escola/por-usuario', {
				params: {
					typeUserId: localStorage.getItem('@actualTypeId'),
				},
			});
			if (!response.data.error)
				setSchoolCoordinator(response.data.data);
			else setSchoolCoordinator([]);
		}
		loadSchool();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line func-names
		(async function() {
			const response = await api.get('/coordenador/lista/turmas', {
				params: {
					schoolID: schoolIDTeacher,
				},
			});
			if (response.data.error) {
				setDisabledClass(true);
				setClassTeacher([]);
			} else {
				setClassTeacher(response.data.data);
				setDisabledClass(false);
			}
		})();
	}, [schoolIDTeacher]);

	async function handleSerie(e) {
		setAlunos(e);
	}
	return (
		<>
			<div className="row">
				<div className="col-12 col-md-12 col-lg-5">
					<div className="form-group">
						<label htmlFor="schoolCoordinator">Escola</label>
						<select
							className="form-control"
							name="schoolCoordinator"
							id="schoolCoordinator"
							onChange={event =>
								setSchoolIDTeacher(event.target.value)
							}
						>
							<option value="0">Escolha a Escola</option>
							{schoolCoordinator.map(schools => (
								<option
									key={schools.id_escola}
									value={schools.id_escola}
								>
									{schools.desc_nome_escola}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12 col-md-12 col-lg-3">
					<div className="form-group">
						<label htmlFor="classTeacher">Série/Turma</label>
						<select
							id="classTeacher"
							className="form-control"
							onChange={event => handleSerie(event.target.value)}
							disabled={disabledClass}
						>
							<option value="0">Escolha a série</option>
							{classTeacher.map(classes => (
								<option
									key={classes.id_classe}
									value={classes.id_classe}
								>
									{classes.desc_serie_ano} - {classes.turma}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</>
	);
}
