import React, { useEffect, useState } from 'react';
import api from '../../services/api';

// eslint-disable-next-line react/prop-types
export default function SelectEscola({
	setIdSchool,
	value,
	disabled = false,
	required = false,
	label = 'Escola',
	theSameLine = false
}) {
	const [schools, setSchools] = useState([]);

	useEffect(() => {
		(async function() {
			const response = await api.get('/tabelas/lista-escolas');
			setSchools(response.data.data);
		})();
	}, []);

	// eslint-disable-next-line no-shadow
	async function handleSubmit(idSchool) {
		setIdSchool(idSchool);
	}
	const ListSchool = schools.map(U => (
		<option value={U.id} key={U.id}>
			{U.name}
		</option>
	));
	return (
		<div className={`selects ${theSameLine ? 'row' : ''}`}>
			<div className="col-md-3 col-lg-3 col-12">
				<label htmlFor="school">
					<b>{label}</b>
				</label>
			</div>
			<div className="col-md-8 col-lg-8 col-12">
				<select
					required={required}
					className="form-control"
					id="idSchool"
					value={value}
					disabled={disabled}
					onChange={event => handleSubmit(event.target.value)}
				>
					<option value="">Todas Escolas</option>
					{ListSchool}
				</select>
			</div>
		</div>
	);
}
