/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Main from './Main';


function SendAnswer(props) {
	return (
		<Main
			avaliacaoId={props.location.state.id}
			fim={props.location.state.fim}
			moduloId={props.location.state.moduloId}
		/>
	);
}

export default SendAnswer;
