export default [
	'#318CE7',
    '#0093AF',
    '#333399',
    '#126180',
    '#7BB661',
    '#4F55DB',
    '#333498',
    '#252937',
    '#DB482D',
    '#B4638F',
    '#B284BE',
    '#3B7A57',
    '#009AC1',
    '#4B6F44',
    '#007FFF',
    '#2E5894',
];