import React from 'react';

// eslint-disable-next-line react/prop-types
export default function SelectParentesco({
	setParentesco,
	valueSelect,
	disabled,
	required,
}) {
	// eslint-disable-next-line no-shadow
	async function handleSubmit(e) {
		setParentesco(e);
	}
	return (
		<>
			<select
				required={required}
				className="form-control"
				name="parentesco"
				id="parentesco"
				value={valueSelect}
				disabled={disabled}
				onChange={event => handleSubmit(event.target.value)}
			>
				<option value="">Escolha um parentesco</option>
				<option value="Pai">Pai</option>
				<option value="Mãe ">Mãe</option>
				<option value="Avô">Avô</option>
				<option value="Avó">Avó</option>
				<option value="Bisavô">Bisavô</option>
				<option value="bisavó">Bisavó</option>
				<option value="Irmão">Irmão</option>
				<option value="Irmã">Irmã</option>
				<option value="Tio">Tio</option>
				<option value="Tia">Tia</option>
				<option value="Outros">Outros</option>
			</select>
		</>
	);
}
