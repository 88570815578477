import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

export default function HeaderDash({ headerItems }) {
	return (
		<div className="row  mt-3 justify-content-center">
			{headerItems.map(
				({
					headerName,
					headerLabel,
					headerIcon,
					headerNumber,
					headerColSize,
					headerLinkName,
					iconBackGroundColor,
					iconTextColor,
				}) => (
					<div className={headerColSize} key={headerName}>
						<div className="card">
							<div className="card-body">
								<Link to={headerLinkName}>
									<div className="row">
										<div className="col-lg-3 col-5 headerDashCards">
											<div
												className={iconBackGroundColor}
											>
												<div className={iconTextColor}>
													{headerIcon}
												</div>
											</div>
										</div>
										<div className="col-lg-9 col-7">
											<div className="content">
												<p className="item-title">
													{headerLabel}
												</p>
												<p className="item-number">
													{headerNumber}
												</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				)
			)}
		</div>
	);
}
