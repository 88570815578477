/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import Panel from '../../../components/Panel';
import Footer from '../../../components/Footer';
import Breadcrumb from '../../../components/Breadcrumb';
import Routes from '../../../routes/data/Routes';
import RouteByPermission from '../../../routes/data/RouteByPermission';

export default function Index() {
	const userActualType = useSelector(state => state.userType.actualType);

	const breadCrumbItems = [
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.HOME,
			namePage: 'Home',
			breadCrumbClass: 'breadcrumb-item',
		},
		{
			linkPage: Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.GRADE,
			namePage: 'Notas',
			breadCrumbClass: 'breadcrumb-item active',
		},
	];
	const [students, setStudents] = useState([]);

	const [disabledSubjects, setDisabledSubjects] = useState(true);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [disabledClass, setDisabledClass] = useState(true);
	const [disabledGrades, setDisabledGrades] = useState(true);
	const [gradeAverage, setGradeAverage] = useState({});
	const [grades, setGrades] = useState({});

	const [schoolCoordinator, setSchoolCoordinator] = useState([]);
	const [schoolIdCoordinator, setSchoolIdCoordinator] = useState(null);
	const [subjectsCoordinator, setSubjectsCoordinator] = useState([]);
	const [classCoordinator, setClassCoordinator] = useState([]);
	const [classIdCoordinator, setClassIdCoordinator] = useState(null);
	const [subjectIdCoordinator, setSubjectIdCoordinator] = useState(null);

	useEffect(() => {
		(async function() {
			const response = await api.get('/escola/por-usuario', {
				params: {
					typeUserId: localStorage.getItem('@actualTypeId'),
				},
			});
			if (response.data.error === false)
				setSchoolCoordinator(response.data.data);
			else setSchoolCoordinator([]);
		})();
	}, []);

	useEffect(() => {
		(async function() {
			const response = await api.get('/coordenador/lista/turmas', {
				params: {
					schoolID: schoolIdCoordinator,
				},
			});
			if (response.data.error === true) {
				setClassCoordinator([]);
				setDisabledClass(true);
			} else {
				setDisabledClass(false);
				setClassCoordinator(response.data.data);
			}
		})();
	}, [schoolIdCoordinator]);

	useEffect(() => {
		(async function() {
			const response = await api.get(
				'/coordenador/lista/turmas/disciplinas',
				{
					params: {
						classID: classIdCoordinator,
					},
				}
			);
			if (response.data.error === true) {
				setSubjectsCoordinator([]);
				setDisabledSubjects(true);
			} else {
				setDisabledSubjects(false);
				setSubjectsCoordinator(response.data.data);
			}
		})();
	}, [classIdCoordinator]);

	useEffect(() => {
		if (schoolIdCoordinator && classIdCoordinator && subjectIdCoordinator)
			setDisabledBtn(false);
		else {
			setDisabledBtn(true);
			setDisabledGrades(true);
			setGradeAverage({});
			setGrades({});
			setStudents([]);
		}
	}, [schoolIdCoordinator, classIdCoordinator, subjectIdCoordinator]);

	async function searchStudents(event) {
		event.preventDefault();
		(async function() {
			const dataPost = {
				classId: classIdCoordinator,
				schoolId: schoolIdCoordinator,
				subjectId: subjectIdCoordinator,
				typeUser: localStorage.getItem('@actualTypeId'),
			};
			const response = await api.post(
				'/usuario/escola/classe/disciplinas/alunos',
				dataPost
			);
			if (!response.data.error) {
				setDisabledGrades(false);
				setStudents(response.data.data);
			} else {
				setDisabledGrades(true);
				setStudents([]);
			}
		})();
		(async function() {
			const response = await api.get('/professor/consultarnotas', {
				params: {
					escolaID: schoolIdCoordinator,
					disciplinaID: subjectIdCoordinator,
					classeID: classIdCoordinator,
				},
			});
			const actualGrades = {};
			const actualGradeAvareges = {};
			for (const aluno of response.data.data) {
				actualGrades[aluno.id_usuario.toString()] = {};

				actualGrades[aluno.id_usuario.toString()]['1'] =
					aluno.nota_bimestral1;
				actualGrades[aluno.id_usuario.toString()]['2'] =
					aluno.nota_bimestral2;
				actualGrades[aluno.id_usuario.toString()]['3'] =
					aluno.nota_bimestral3;
				actualGrades[aluno.id_usuario.toString()]['4'] =
					aluno.nota_bimestral4;
				actualGrades[aluno.id_usuario.toString()]['5'] =
					aluno.editar_nota_bimestral1;
				actualGrades[aluno.id_usuario.toString()]['6'] =
					aluno.editar_nota_bimestral2;
				actualGrades[aluno.id_usuario.toString()]['7'] =
					aluno.editar_nota_bimestral3;
				actualGrades[aluno.id_usuario.toString()]['8'] =
					aluno.editar_nota_bimestral4;

				actualGradeAvareges[aluno.id_usuario.toString()] =
					aluno.nota_media_final;
			}
			setGrades({ ...actualGrades });
			setGradeAverage({ ...actualGradeAvareges });
		})();
	}

	return (
		<>
			<Breadcrumb breadCrumbItems={breadCrumbItems} />
			<div className="card mb-4">
				<div className="card-body">
					<div className="row mb-3">
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="schoolCoordinator">
								<b>Escola</b>
							</label>
							<select
								className="form-control"
								name="schoolCoordinator"
								id="schoolCoordinator"
								onChange={event =>
									setSchoolIdCoordinator(event.target.value)
								}
							>
								<option value="0">Escolha a Escola</option>
								{schoolCoordinator.map(schools => (
									<option
										key={schools.id_escola}
										value={schools.id_escola}
									>
										{schools.desc_nome_escola}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="classCoordinator">
								<b>Série/Turma</b>
							</label>
							<select
								id="classCoordinator"
								className="form-control"
								onChange={event =>
									setClassIdCoordinator(event.target.value)
								}
								disabled={disabledClass}
							>
								<option value="0">Escolha a série</option>
								{classCoordinator.map(classes => (
									<option
										key={classes.id_classe}
										value={classes.id_classe}
									>
										{classes.desc_serie_ano} -{' '}
										{classes.turma}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-lg-4 form-group">
							<label htmlFor="subjectTeacher">
								<b>Disciplina</b>
							</label>
							<select
								className="form-control"
								name="subjectTeacher"
								id="subjectTeacher"
								onChange={event =>
									setSubjectIdCoordinator(event.target.value)
								}
								disabled={disabledSubjects}
							>
								<option value="0">Escolha a disciplina</option>
								{subjectsCoordinator.map(subjects => (
									<option
										key={subjects.id_disciplina}
										value={subjects.id_disciplina}
									>
										{subjects.nome_disciplina}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<div className="row justify-content-center text-center">
					<div className="col-6 form-group">
						<button
							type="button"
							className="btn btn-block btn-success"
							onClick={searchStudents}
							disabled={disabledBtn}
						>
							<FaSearch className="searchIcon" />
							Buscar
						</button>
					</div>
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="text-center">
										Aluno
									</th>
									<th scope="col" className="text-center">
										1º Bimestre
									</th>
									<th scope="col" className="text-center">
										2º Bimestre
									</th>
									<th scope="col" className="text-center">
										3º Bimestre
									</th>
									<th scope="col" className="text-center">
										4º Bimestre
									</th>
									<th scope="col" className="text-center">
										Média
									</th>
								</tr>
							</thead>
							<tbody>
								{students.map(student => (
									<tr key={student.id_usuario}>
										<th>{student.nome_usuario}</th>

										<th className="text-center">
											<input
												type="text"
												disabled
												className="form-control input-grade"
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['1'] || null
														: null
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="text"
												className="form-control input-grade"
												disabled
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['2'] || null
														: null
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="text"
												disabled
												className="form-control input-grade"
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['3'] || null
														: null
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="text"
												disabled
												className="form-control input-grade"
												value={
													grades[student.id_usuario]
														? grades[
																student
																	.id_usuario
														  ]['4'] || null
														: null
												}
											/>
										</th>
										<th className="text-center">
											<input
												type="text"
												className="form-control input-grade"
												value={
													gradeAverage[
														student.id_usuario
													]
												}
												disabled
											/>
										</th>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
