import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useJwt } from "react-jwt";
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import api from '../../services/api';
import './style.css';
import Routes from '../../routes/data/Routes';
import RouteParams from '../../routes/data/RouteParams';
import RouteByPermission from '../../routes/data/RouteByPermission';

export default function ListNotices() {

	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	};
	const history = useHistory();
	const userActualType = useSelector(state => state.userType.actualType);
	const [noticias, setlblNoticias] = useState([]);
	const { isExpired } = useJwt(localStorage.getItem('@token'));


	useEffect(() => {
		(async function() {
			const message = await api.get('/noticias/lista-noticias');
			if (message.data.error === false) setlblNoticias(message.data.data);
		})();
	}, []);

	async function handleReadNotice(e) {
		const params = {};
		params[RouteParams.COMMON_LEVELS.READ_NOTICES.NOTICE_ID] = e;
		history.push(
			Routes.LOGGED_ROUTES(RouteByPermission[userActualType])
				.READ_NOTICES,
			params
		);
	}
	return (
		<>
			<div className="row justify-content-center mt-3">
				<div className="col-lg-12 col-md-10 col-12">
					<div className="card">
						<div className="card-header">
							<h6>Notícias</h6>
						</div>
						<div className="card-body notice-board">
							{noticias.length > 0 ? (
								noticias.map(element => (
									<div
										className="cards-notices"
										key={element.id_noticia}
									>
										<div className="row">
											<div className="col-6 col-md-6 col-lg-6">
												{element.updated_at ? (
													<div className="badges-datas-eventos">
														{element.updated_at}
													</div>
												) : (
													<div className="badges-datas-eventos">
														Sem Data.
													</div>
												)}
											</div>
											<div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end">
												<button
													className="badges-ler-noticias"
													type="button"
													onClick={() =>
														handleReadNotice(
															element.id_noticia
														)
													}
												>
													<span>Ler Notícia</span>
												</button>
											</div>
										</div>
										<div className="descricao-noticia">
											<span>
												{element.titulo_noticia}
											</span>
										</div>
										<div className="autores-noticias">
											<small>
												Escola:{' '}
												{element.desc_nome_escola.charAt(
													0
												) +
													element.desc_nome_escola
														.slice(1)
														.toLowerCase()}{' '}
											</small>
										</div>
										<hr />
									</div>
								))
							) : (
								<div className="badges-datas-eventos">
									Sem Notícia
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
